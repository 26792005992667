import {
  mergeStyleSets,
  mergeStyles,
  IStyle,
  IProcessedStyleSet,
  IDetailsListStyles,
  ISearchBoxStyles,
  FontWeights,
  IButtonStyles,
  IBasePickerStyles,
  FontSizes,
  NeutralColors,
  DefaultPalette,
  IStackStyles,
  IScrollablePaneStyles,
  IDetailsColumnStyles,
} from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import {
  XXL_BREAK_POINT,
  XL_BREAK_POINT,
  LG_BREAK_POINT,
  MD_BREAK_POINT,
  SM_BREAK_POINT,
  XSM_BREAK_POINT,
  MOBILE_NAV_MAX_WIDTH_BREAKPOINT,
  VIEWPORT_HEIGHT,
  NAV_HEIGHT,
  DEFAULT_NAV_MIN_WIDTH_BREAKPOINT,
} from '../constants/SiteConstants';

interface ISiteHeroContentStyle {
  largeHeroHeader: IStyle;
  content: IStyle;
  actionItemsWrapper: IStyle;
}

export const MessagingColors = {
  warning: '#fff4ce',
  warningIcon: NeutralColors.gray130,
  severeWarning: '#fed9cc',
  severeWarningIcon: '#d73b02',
  errorBlock: '#fde7e9',
  errorBlockIcon: '#c5262c',
  success: '#dff6dd',
  successIcon: '#107c10',
  defaultBackground: NeutralColors.gray20,
  defaultIcon: NeutralColors.gray130,
};

export const rowClasses = mergeStyleSets({
  medFont: {
    fontSize: FontSizes.size14,
  },
});

export const applicationWrapper = mergeStyles({
  display: 'flex',
  height: VIEWPORT_HEIGHT,
  overflowY: 'auto',
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      height: 'auto',
    },
  },
});

export const errorLabelStyles = {
  root: {
    fontWeight: '400',
    fontSize: FontSizes.size12,
    margin: '5px 0px 0px',
  },
};

export const accordionFilterHeaderStyles = {
  root: {
    textAlign: 'left',
    width: '100%',
    height: 50,
    fontWeight: '600',
    fontSize: FontSizes.size16,
  },
};

export const getSortableColumnHeaderStyles = (isSorted: boolean): Partial<IDetailsColumnStyles> => {
  return {
    root: {
      background: isSorted ? NeutralColors.gray20 : 'inherit',
      paddingTop: 0,
      selectors: {
        '.ms-DetailsHeader-cellName': {
          '&.is-actionable': {
            cursor: 'pointer',
          },
        },
        '.is-icon-visible': {
          fontWeight: FontWeights.semibold,
        },
      },
    },
  };
};

export const lockedStyledDetailsList: Partial<IDetailsListStyles> = {
  root: {
    selectors: {
      '.ms-DetailsHeader': { paddingTop: 0 },
      '.ms-DetailsHeader-cell:first-child, .ms-DetailsRow-cellCheck, .ms-DetailsRow-cell:nth-of-type(1), .ms-DetailsHeader-cell:nth-of-type(2)':
        {
          position: 'sticky',
          inset: '0',
          backgroundColor: 'white',
          zIndex: '1',
          marginTop: '0 !important',
        },
      '.ms-DetailsRow-fields .ms-DetailsRow-cell:nth-of-type(1), .ms-DetailsHeader-cell:nth-of-type(2)':
        {
          left: 48,
        },
      '.ms-DetailsRow-fields .ms-DetailsRow-cell:nth-of-type(1)': {
        padding: '7px 13px 7px 7px',
        borderRight: '1px solid #eee',
      },
    },
  },
  focusZone: {
    root: {
      minHeight: 'auto',
    },
    selectors: {
      '.ms-DetailsRow-cell': {
        padding: '0 12px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      },
      '.ms-DetailsRow-cellCheck': { height: 'auto', padding: 0 },
      '.ms-DetailsRow-fields': {},
    },
  },
};

export const resetScrollablePanelStyles: Partial<IScrollablePaneStyles> = {
  root: { position: 'relative' },
  contentContainer: { position: 'relative' },
};

export const defaultListContainerStyles: IStackStyles = {
  root: { marginTop: 10, position: 'relative', flexGrow: 1 },
};

export const desktopOnlyScrollable: Partial<IScrollablePaneStyles> = {
  root: {
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        position: 'relative',
      },
    },
  },
  contentContainer: {
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        position: 'relative',
      },
    },
  },
};
export const leftNavContainer = mergeStyles({
  display: 'flex',
});
export const defaultNavWrapper = mergeStyles({
  display: 'flex',
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      display: 'none !important',
    },
  },
});
export const mobileNavWrapper = mergeStyles({
  display: 'flex',
});
export const mobileNavTriggerWrapper = mergeStyles({
  position: 'fixed',
  top: 48,
  fontSize: FontSizes.size16,
  left: 0,
  width: '100%',
  borderBottom: '1px solid #C6C6C6',
  paddingBottom: 8,
  paddingTop: 8,
  backgroundColor: NeutralColors.white,
  zIndex: 1,
  selectors: {
    [DEFAULT_NAV_MIN_WIDTH_BREAKPOINT]: {
      display: 'none !important',
    },
  },
});
export const mobileNavTrigger = mergeStyles({
  marginLeft: 8,
});
export const mobileStickyNav = {
  root: {
    flex: 1,
    position: 'fixed',
    height: NAV_HEIGHT,
    zIndex: 2,
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        marginTop: 48,
      },
    },
  } as IStyle,
};
export const mobileStickyHeader = mergeStyles({
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      position: 'fixed',
      width: '100%',
      zIndex: 2,
      height: '48px',
    },
  },
});
export const pivotHeaderPivotMenu = {
  root: {
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        display: 'none',
      },
    },
  } as IStyle,
};
export const pivotHeaderCommandButtonMenu = {
  root: {
    display: 'none',
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        display: 'flex',
      },
    },
  } as IStyle,
};

const mobileContentContainerMargins = {
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      marginTop: 96,
      marginRight: 0,
      marginLeft: 0,
    },
  },
};
const mobileContentMargins = {
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      marginTop: 0,
      marginRight: 15,
      marginLeft: 15,
    },
  },
};
const contentMarginStyles = mergeStyleSets({
  mainContent: {
    marginTop: 20,
    marginBottom: 38,
    marginLeft: 40,
    marginRight: 40,
    minWidth: 0,
    selectors: mobileContentMargins.selectors,
  },
  mainContentWrapper: {
    padding: '15px 25px',
    backgroundColor: NeutralColors.white,
  },
});
export const mainContentContainer = mergeStyles({
  flex: '1 1 auto',
  minWidth: 0,
  marginLeft: '48px',
  overflowY: 'auto',
  selectors: mobileContentContainerMargins.selectors,
});

export const mainContentContainerExtended = mergeStyles({
  marginLeft: '280px',
  flex: '1 1 auto',
  minWidth: 0,
  overflowY: 'auto',
  selectors: mobileContentContainerMargins.selectors,
});
export const bodyContentContainer = mergeStyles(contentMarginStyles.mainContent);
export const bodyContentWrapper = mergeStyles(contentMarginStyles.mainContentWrapper);
export const cardTitleWrapperStyles = mergeStyles({
  padding: '0 25px',
  selectors: {
    'h3, h2': {
      margin: 0,
      padding: '10px 0',
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold,
    },
  },
});
export const cardWrapperStyles = mergeStyles({
  padding: '15px 0',
  background: NeutralColors.white,
});
export const cardContentWrapperStyles = mergeStyles({ padding: '0 25px' });
export const centeredWrapper = mergeStyles({
  alignItems: 'center',
  textAlign: 'center',
});

export const verticalCenterStack = mergeStyles({
  flexDirection: 'column',
  height: VIEWPORT_HEIGHT,
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
});

export const datePickerFocused = mergeStyles({
  selectors: {
    '.ms-TextField-fieldGroup': {
      borderColor: CoherenceTheme.palette.themePrimary,
    },
  },
});
/**
 * Error page styles
 */
export const siteHeroContent = (): IProcessedStyleSet<ISiteHeroContentStyle> => {
  return mergeStyleSets({
    largeHeroHeader: {
      marginTop: 0,
      marginBottom: 48,
      lineHeight: '124%',
      selectors: {
        [XXL_BREAK_POINT]: FontSizes.size68,
        [XL_BREAK_POINT]: FontSizes.size42,
        [LG_BREAK_POINT]: FontSizes.size32,
        [MD_BREAK_POINT]: FontSizes.size28,
        [SM_BREAK_POINT]: FontSizes.size20,
        [XSM_BREAK_POINT]: FontSizes.size18,
      },
    },
    content: {
      marginBottom: 0,
    },
    actionItemsWrapper: {
      marginTop: 32,
      selectors: {
        button: {
          margin: 6,
        },
      },
    },
  });
};
export const styledDetailsList: IDetailsListStyles = {
  root: {},
  contentWrapper: {
    selectors: {},
  },
  headerWrapper: {
    selectors: {
      '.ms-DetailsHeader': {
        paddingTop: 0,
      },
      '.ms-DetailsHeader-cell:hover': {
        background: '#fff',
      },
      '.ms-DetailsHeader-cellTitle': {
        padding: '0 25px',
      },
      '.ms-DetailsHeader-cellName': {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.regular,
        color: CoherenceTheme.palette.neutralSecondary,
      },
    },
  },
  focusZone: {
    display: 'block',
    selectors: {
      '.ms-DetailsRow-fields': {
        alignItems: 'center',
      },

      '.ms-DetailsRow-cell': {
        padding: '20px 25px',
      },

      '.is-row-header': {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size14,
      },
    },
  },
};

export const styledDetailsListCompact: Partial<IDetailsListStyles> = {
  root: { '.ms-DetailsHeader': { paddingTop: 0 } },
  focusZone: {
    selectors: {
      '.ms-DetailsRow-cell': {
        padding: '0 12px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      },
      '.ms-DetailsRow-cellCheck': { height: 'auto', padding: 0 },
      '.ms-DetailsRow-fields': {},
    },
  },
};

export const processDetails: IDetailsListStyles = {
  root: {},
  contentWrapper: {
    selectors: {},
  },
  headerWrapper: {
    selectors: {
      '.ms-DetailsHeader': {
        paddingTop: 0,
        borderBottom: 'none',
        height: 20,
      },
      '.ms-DetailsHeader-cell': {
        height: 20,
        lineHeight: 20,
      },

      '.ms-DetailsHeader-cell:hover': {
        background: '#fff',
      },
      '.ms-DetailsHeader-cellTitle': {
        padding: '0 20px 0 0',
        height: 20,
      },
      '.ms-DetailsHeader-cellName': {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.regular,
        color: '#977373',
      },
    },
  },
  focusZone: {
    display: 'block',
    selectors: {
      '.ms-DetailsRow': {
        borderBottom: 0,
      },
      '.ms-DetailsRow-fields': {
        fontSize: FontSizes.size14,
        alignItems: 'center',
      },
      '.ms-DetailsRow-fields:hover': {
        background: 'white',
      },
      '.ms-DetailsRow-cell': {
        padding: '0 20px 0 0',
        minHeight: 0,
      },
    },
  },
};
export const searchBoxStyles: ISearchBoxStyles = {
  root: {
    background: CoherenceTheme.palette.neutralLight,
    borderWidth: 0,
  },
  field: {
    border: 'none',
  },
};
/**
 * Styled dropdown command button
 */
export const styledDropdownCommandButton: IButtonStyles = {
  root: {
    background: CoherenceTheme.palette.white,
    color: CoherenceTheme.palette.black,
    fontWeight: FontWeights.semibold,
    border: 'none',
  },
  rootPressed: {
    color: CoherenceTheme.palette.black,
  },
  rootHovered: {
    background: CoherenceTheme.palette.neutralQuaternaryAlt,
    color: CoherenceTheme.palette.black,
  },
  rootExpanded: {
    background: CoherenceTheme.palette.neutralTertiaryAlt,
    color: CoherenceTheme.palette.black,
  },
};

export const labelStyles = {
  root: { color: NeutralColors.gray200, fontWeight: FontWeights.regular, fontSize: 12 },
};
export const processTemplateGateRuleLabelStyles = {
  root: {
    color: NeutralColors.gray200,
    background: MessagingColors.warning,
    fontWeight: FontWeights.regular,
    padding: 10,
    fontSize: FontSizes.size14,
  },
};
export const processTemplateMilestoneRulesSpan = mergeStyles({
  color: NeutralColors.gray200,
  background: MessagingColors.warning,
  fontWeight: FontWeights.regular,
  padding: 10,
  fontSize: FontSizes.size14,
  display: 'inline-block',
});
export const labelPrimaryStyles = {
  root: { color: CoherenceTheme.palette.themePrimary, fontWeight: FontWeights.bold },
};

export const milestoneLiStyle = mergeStyles({
  listStyleType: 'none',
  paddingLeft: '5px',
  marginBottom: '10px',
});

export const overdueMilestoneDateStyle = mergeStyles({
  borderLeft: `solid 3px ${MessagingColors.errorBlockIcon}`,
  paddingLeft: '10px',
});

export const upcomingMilestoneDateStyle = mergeStyles({
  borderLeft: `solid 3px ${DefaultPalette.blue}`,
  paddingLeft: '10px',
});
export enum SvgColor {
  lightBlue = '#0078d4',
  darkBlue = '#002ad4',
  lightRed = '#ED705B',
  darkRed = '#C23A41',
}

export const CoherencePanelStyles = {
  headerButtonsContainer: {
    padding: ' 2px 0',
  },
  subComponentStyles: {},
  header: {
    fontSize: FontSizes.size24,
    padding: '0 40px',
  },
  // TODO: Check if we can remove this the next time we upgrade coherence
  root: {
    margin: 0,
    padding: '10px 40px',
  },
  body: {
    margin: 0,
    padding: '24px 40px',
  },
  footer: {
    borderTop: '2px solid #f5f5f5',
    margin: '0px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    padding: '24px 48px',
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        padding: '10px 20px',
      },
    },
  },
};

export const panelFooterStyle = {
  footer: { backgroundColor: NeutralColors.white },
  header: { backgroundColor: NeutralColors.white },
};

export const countryPickerStyles: IBasePickerStyles = {
  root: {
    marginTop: '5px',
  },
  input: {},
  text: { border: '1px solid rgb(23, 23, 23)' },
  screenReaderText: {},
  itemsWrapper: {},
};

export const pickerErrorMessage = {
  marginTop: `0px`,
};

export const separatorStyles = {
  content: { backgroundColor: NeutralColors.gray10 },
};

export const fixButtonHoverStyle = {
  root: { marginRight: 0 },
  rootHovered: { border: 'none' },
};

export const filterWrapperStyles = {
  root: {
    width: 300,
  },
};
