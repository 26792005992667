import { useQuery } from '@apollo/client';
import { ITag } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { GetCompaniesByCode } from '../../../utils/statApi/CompaniesApi';
import { ICompanyBase } from '../../../utils/types/ICompany';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import CompanyPicker from '../../companyGroups/detail/CompanyGroupCompanyPicker';

interface IProcessSearchCompanyPickerProps {
  initialSelectedCodes: Array<string>;
  onCompanyUpdate: (items: ITag[]) => void;
}
const ProcessSearchCompanyPicker = (props: IProcessSearchCompanyPickerProps): JSX.Element => {
  const [initialSelectedItems, setInitialSelectedItems] = useState<ITag[]>();
  const { initialSelectedCodes, onCompanyUpdate } = props;
  // no chache needed so that browsing back to this page rerenders the selected company
  const { data, loading, error } = useQuery(GetCompaniesByCode, {
    fetchPolicy: 'no-cache',
    variables: { companyCodes: initialSelectedCodes },
  });
  const mapCompaniesToTags = (companies: ICompanyBase[]): ITag[] => {
    const companiesTags = companies.map((company) => {
      return {
        key: company.id.toString(),
        name: `${company.companyCode} - ${company.entityLegalName}`,
      };
    });
    return companiesTags;
  };
  useEffect(() => {
    if (!loading && data && data.companiesByCode) {
      setInitialSelectedItems(mapCompaniesToTags(data.companiesByCode));
    }
  }, [data]);
  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      <CompanyPicker
        selectedItems={initialSelectedItems}
        setSelectedItems={onCompanyUpdate}
        label="Companies"
      />
    </>
  );
};
export default ProcessSearchCompanyPicker;
