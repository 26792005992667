import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';
import GET_ACQUISITION_QUERY from '../../utils/statApi/AcquisitionApi';
import GET_COMPANY_CREATION_REQUESTS, {
  EDIT_COMPANY_CREATION_REQUEST,
  NEW_COMPANY_CREATION_REQUEST,
} from '../../utils/statApi/CompanyCreationApi';
import ActionButtons from '../common/ActionButtons';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import NewCompanyForm from './NewCompanyForm';
import ICompanyCreationRequest, {
  CompanyCreationStatus,
  ICompanyCreationRequestDetails,
} from './types';

interface INewCompanyPanelProps {
  companyCreationRequest?: ICompanyCreationRequestDetails;
  closePanel: () => void;
}

const NewCompanyPanel = ({
  closePanel,
  companyCreationRequest,
}: INewCompanyPanelProps): JSX.Element => {
  const companyCreationRequestForm = useForm();
  const { handleSubmit } = companyCreationRequestForm;

  const title = companyCreationRequest?.id !== undefined ? 'Edit ' : 'Create New ';
  const queryString =
    companyCreationRequest?.id !== undefined
      ? EDIT_COMPANY_CREATION_REQUEST
      : NEW_COMPANY_CREATION_REQUEST;

  const [createCompanyRequest, { loading, error }] = useMutation(queryString, {
    refetchQueries: [
      {
        query: GET_COMPANY_CREATION_REQUESTS,
        variables: {
          statusIds: [
            CompanyCreationStatus.RequestStarted,
            CompanyCreationStatus.CompanyCodeRequested,
            CompanyCreationStatus.PendingSubledgerInfo,
            CompanyCreationStatus.PendingStatToolSetup,
          ],
          requestorIds: [],
          acquisitionIds: [],
        },
      },
      {
        query: GET_ACQUISITION_QUERY,
      },
    ],
    onCompleted: () => {
      closePanel();
    },
  });

  const handleSaveClick = (formData: ICompanyCreationRequest): void => {
    const acquisition =
      formData.isAcquisition !== 'true'
        ? null
        : {
            id: parseInt(formData.acquisition.key.toString(), 10),
            fieldName: formData.acquisition.text,
          };

    createCompanyRequest({
      variables: {
        companyCreationRequestInput: {
          id: companyCreationRequest?.id,
          companyName: formData.companyName
            ? formData.companyName.trim().replace(/\s\s+/g, ' ')
            : null,
          companyCode:
            companyCreationRequest?.companyCode || parseInt(formData?.companyCode?.toString(), 10),
          isAcquisition: formData.isAcquisition === 'true',
          isConfidential: formData.isConfidential === 'true',
          acquisition,
          dateOfAcquisition: formData.dateOfAcquisition,
          entityType: {
            id: formData.entityType.id,
            fieldName: formData.entityType.fieldName,
          },
          businessNatureOfEntity: formData.businessNatureOfEntity,
          financialType: {
            id: formData.financialType.id,
            name: formData.financialType.fieldName,
          },
          davaDealLeadGraphGUID: formData.davaDealLead?.graphGUID,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={`${title} Company Request`}
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Company Request Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel={`${title} Company Request`}
          saveTitle={`${title} Company Request`}
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <NewCompanyForm
          form={companyCreationRequestForm}
          companyCreationRequest={companyCreationRequest}
        />
      </form>
    </CoherencePanel>
  );
};

export default NewCompanyPanel;
