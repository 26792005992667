import { useQuery } from '@apollo/client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed } from 'powerbi-client';
import { BackgroundType, FilterType, IBasicFilter, TokenType } from 'powerbi-models';
import React from 'react';
import { mergeStyles } from '@fluentui/react';
import { GET_REPORT, IEmbeddedReportData } from '../../utils/powerBiApi/powerBiApi';
import { StatUser } from '../../utils/statApi/UsersApi';
import UserContext from '../../utils/authorization/UserContext';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';

const reportStyle = mergeStyles({
  height: '720px',
  selectors: {
    iframe: {
      border: 'none',
    },
  },
});

interface IEmbeddedReportProps {
  reportId: string;
}

const EmbeddedReport = (props: IEmbeddedReportProps): JSX.Element => {
  const { reportId } = props;
  const { data, loading, error } = useQuery(GET_REPORT, { variables: { reportId } });
  const user: StatUser = React.useContext(UserContext);

  const reportData: IEmbeddedReportData = data?.report;
  const authorizedCompanyCodes: string[] = user?.userRoles?.map((ur) => ur.companyCode);

  const isAdmin: boolean = authorizedCompanyCodes.some((cc) => cc === null);
  /** We need to map the name of the report with the name of data table
   * as set in the powerbi report file
   */
  const reportTableMapping = {
    'Compliance Status': 'Compliance Report',
    Steerco: 'Steerco Query',
    'Expedite Power App': 'Compliance Report',
    'Company Info and Contacts': 'Company Info And Contacts Report',
    'Entity Creation': 'Entity Creation Data',
    'AGM Report': 'AGM Report',
    'Dividends Report': 'Dividends',
  } as {
    [key: string]: string;
  };
  const tableName = reportTableMapping[reportData?.name] || '';
  const companyCodeFilter: IBasicFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: tableName,
      column: 'Company Code',
    },
    operator: 'In',
    values: authorizedCompanyCodes,
    filterType: FilterType.Basic,
    displaySettings: { isHiddenInViewMode: true },
  };
  const getCurrentFinancialYear = (): string => {
    let financialYear = '';
    const today = new Date();
    if (today.getMonth() + 1 < 7) {
      financialYear = `FY${today.getFullYear().toString().slice(-2)}`;
    } else {
      financialYear = `FY${(today.getFullYear() + 1).toString().slice(-2)}`;
    }
    return financialYear;
  };
  const fiscalYearFilter: IBasicFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: tableName,
      column: 'Filing Due - Fiscal Year',
    },
    operator: 'In',
    values: [getCurrentFinancialYear()],
    filterType: FilterType.Basic,
  };
  const filters = isAdmin ? [fiscalYearFilter] : [companyCodeFilter, fiscalYearFilter];
  return (
    <>
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading the report from Power Bi"
      />

      {!loading && !error && (
        <PowerBIEmbed
          embedConfig={{
            id: reportData?.id,
            embedUrl: reportData?.embedUrl,
            accessToken: reportData?.token,
            type: 'report',
            tokenType: TokenType.Embed,
            filters,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: true,
                },
              },
              background: BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              ['loaded', () => {}],
              ['rendered', () => {}],
              ['error', () => {}],
            ])
          }
          cssClassName={reportStyle}
          getEmbeddedComponent={(embedObject: Embed) => {
            embedObject.setComponentTitle(reportData?.name);
          }}
        />
      )}
    </>
  );
};
export default EmbeddedReport;
