import React from 'react';
import { IconButton } from '@fluentui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getParentUriSegment } from '../../utils/Helpers';

interface IBackButtonProps {
  ariaLabel: string;
  children: React.ReactNode;
  backDepth?: number;
  goToUrl?: string;
}

const BackButton = ({ ariaLabel, children, backDepth, goToUrl }: IBackButtonProps): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch();

  const retrieveUrl = (depth = 1) => {
    if (goToUrl) {
      return goToUrl;
    }
    let newUrl = match.url;
    for (let i = 0; i < depth; i += 1) {
      newUrl = getParentUriSegment(newUrl);
    }
    return newUrl;
  };

  return (
    <>
      <IconButton
        iconProps={{ iconName: 'Back' }}
        styles={{ root: { marginRight: 10 } }}
        title={ariaLabel}
        ariaLabel={ariaLabel}
        onClick={(): void => {
          history.push(retrieveUrl(backDepth));
        }}
      />
      {children}
    </>
  );
};

export default BackButton;
