import React from 'react';
import {
  DocumentCard,
  DocumentCardType,
  DocumentCardPreview,
  DocumentCardDetails,
  Shimmer,
  ShimmerElementType,
} from '@fluentui/react';
import { fileSelectorItemWrapper } from './fileSelectorStyles';
import previewPropsUsingIcon from './fileSelector.config';

const FileSelectorShimmer = (): JSX.Element => {
  const firstRowShimmer = [{ type: ShimmerElementType.line, height: 24 }];
  const thirdRowShimmer = [
    { type: ShimmerElementType.circle, height: 24 },
    { type: ShimmerElementType.gap, width: '2%' },
    { type: ShimmerElementType.line, height: 24, width: '50%' },
    { type: ShimmerElementType.gap, width: '33%' },
    { type: ShimmerElementType.line, height: 24, width: '7%' },
    { type: ShimmerElementType.gap, width: '2%' },
  ];

  return (
    <DocumentCard
      aria-label="Loading..."
      type={DocumentCardType.compact}
      styles={fileSelectorItemWrapper}
    >
      <DocumentCardPreview {...previewPropsUsingIcon(false, 'Loading', 'ProgressRingDots')} />
      <DocumentCardDetails>
        <div style={{ width: 350, padding: '8px 16px 8px 16px' }}>
          <Shimmer shimmerElements={firstRowShimmer} />
        </div>

        <div style={{ padding: '8px 16px 8px 16px' }}>
          <Shimmer width="25%" />
        </div>

        <div style={{ padding: '0px 16px 8px 16px' }}>
          <Shimmer shimmerElements={thirdRowShimmer} />
        </div>
      </DocumentCardDetails>
    </DocumentCard>
  );
};

export default FileSelectorShimmer;
