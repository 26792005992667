import { mergeStyles, FontSizes, FontWeights, DefaultPalette } from '@fluentui/react';
import { MessagingColors } from '../../../../app/common/styles/CommonStyleObjects';

export const iconClass = mergeStyles({
  fontSize: FontSizes.large,
  fontWeight: FontWeights.bold,
  color: MessagingColors.warningIcon,
});

export const warningClass = mergeStyles({
  backgroundColor: DefaultPalette.blue,
  border: `1px solid ${DefaultPalette.blue}`,
  color: 'white',
  padding: '3px',
  marginRight: 5,
});
export const completeClass = mergeStyles({
  padding: '3px',
  marginRight: 5,
  color: 'white',
  backgroundColor: MessagingColors.successIcon,
  border: `1px solid ${MessagingColors.successIcon}`,
});

export const paddedIconClass = mergeStyles({
  padding: '3px',
  marginRight: 5,
});
