import React, { ReactNode } from 'react';
import { Stack, Separator, mergeStyles, FontSizes, NeutralColors } from '@fluentui/react';

interface IInsightCardProps {
  caption: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mainText: any;
  svgComponent?: ReactNode;
  footerLink?: ReactNode;
}

const InsightCard = ({
  caption,
  mainText,
  svgComponent,
  footerLink,
}: IInsightCardProps): JSX.Element => {
  const innerCardStyle = mergeStyles({ padding: '10px', minHeight: '220px' });
  const cardBodyStyle = mergeStyles({ margin: '10px 10px 20px 10px' });
  const outterCardStyle = mergeStyles({
    minHeight: '250px',
    width: '430px',
    background: NeutralColors.white,
  });
  const cardFooterStyle = mergeStyles({ padding: '7px 20px 20px 20px' });
  const cardHeaderStyle = mergeStyles({
    fontSize: FontSizes.size18,
    padding: 0,
    margin: '10px 10px 20px 10px',
  });
  return (
    <Stack className={`ms-depth-4 ${outterCardStyle}`}>
      <Stack className={innerCardStyle} grow>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item>
            <h2 className={cardHeaderStyle}>{caption}</h2>
          </Stack.Item>
          <Stack.Item align="end" disableShrink>
            {svgComponent}
          </Stack.Item>
        </Stack>
        <Stack disableShrink className={cardBodyStyle}>
          {mainText}
        </Stack>
      </Stack>
      {footerLink && (
        <Stack verticalAlign="end">
          <Separator />
          <div className={cardFooterStyle}>{footerLink}</div>
        </Stack>
      )}
    </Stack>
  );
};

export default InsightCard;
