import { DocumentNode, useMutation } from '@apollo/client';
import { DefaultButton, IContextualMenuProps, Persona, Stack, TextField } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import hasAccess from '../../utils/authorization/authorizationCheck';
import UserContext from '../../utils/authorization/UserContext';
import { IDocument } from '../../utils/statApi/DocumentApi';
import { IReviewDocumentInput, IReviewDocumentsInput } from '../../utils/statApi/ReviewApi';
import { StatUser } from '../../utils/statApi/UsersApi';
import { IReview } from '../../utils/types/IReview';
import CanAccess from '../common/canAccess/CanAccess';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import FileInputTextbox from '../common/formFields/fileUpload/FileInputTextbox';
import RejectButtonMode from './RejectButtonMode';
import ReviewApprovals from './ReviewApprovals';
import ReviewComments from './ReviewComments';
import ReviewInputMode from './ReviewInputMode';

interface IReviewComponentProps {
  review: IReview;
  approveMutation: DocumentNode;
  rejectMutation: DocumentNode;
  loading: boolean;
  createCommentMutation: DocumentNode;
  attachDocumentMutation: DocumentNode;
  rejectButtonMode?: RejectButtonMode;
  disableApproveButton?: boolean;
  approverWarningMessage?: string;
  displayOnly?: boolean;
}

const ReviewComponent = ({
  review,
  approveMutation,
  rejectMutation,
  loading,
  createCommentMutation,
  attachDocumentMutation,
  rejectButtonMode,
  disableApproveButton,
  approverWarningMessage,
  displayOnly,
}: IReviewComponentProps): JSX.Element => {
  const { errors, control, handleSubmit, getValues, setValue, clearErrors } = useForm();
  const user: StatUser = useContext(UserContext);
  const { permissions } = user;
  const hasCommentAccess = hasAccess(permissions, 'Review:Comment');
  const hasDocumentAccess = hasAccess(permissions, 'Review:Document');
  const [inputMode, setInputMode] = useState(ReviewInputMode.Comment);
  const [documentInputValue, setDocumentInputValue] = useState('');
  const [fileValidationError, setFileValidationError] = useState(null);

  const [createComment, { loading: commentMutationLoading, error: commentMutationError }] =
    useMutation(createCommentMutation);

  const [attachDocument, { loading: documentMutationLoading, error: documentMutationError }] =
    useMutation(attachDocumentMutation);

  const addComment = (): void => {
    if (!hasCommentAccess) {
      return;
    }

    const commentText = getValues('comment');
    createComment({
      variables: {
        reviewId: review.id,
        comment: commentText,
      },
    });

    setValue('comment', '');
  };

  const addDocument = (): void => {
    if (!hasDocumentAccess) {
      return;
    }
    const reviewDoc = getValues('document');

    const reviewDocs: IReviewDocumentsInput = {
      reviewId: review.id,
      reviewDocuments: [
        {
          id: -1,
          reviewId: review.id,
          reviewDto: null,
          title: reviewDoc.title,
          url: reviewDoc.url,
          uploadDate: reviewDoc.uploadDate,
          uploader: null,
          isDeleted: reviewDoc.isDeleted,
        } as IReviewDocumentInput,
      ],
    };

    attachDocument({
      variables: {
        reviewId: review.id,
        reviewDocuments: reviewDocs,
      },
    });

    setDocumentInputValue('');
  };

  const commentButtonProps: IContextualMenuProps = {
    items: [
      {
        key: 'addComment',
        text: 'Add Comment',
        iconProps: { iconName: 'Comment' },
        onClick: () => {
          setInputMode(ReviewInputMode.Comment);
        },
      },
      {
        key: 'attachDocument',
        text: 'Attach Document',
        iconProps: { iconName: 'Document' },
        onClick: () => {
          setInputMode(ReviewInputMode.Document);
        },
      },
    ],
  };

  const setDocumentOnForm = async (files: IDocument[]): Promise<void> => {
    setValue('document', files[0]);
    clearErrors('document');
  };

  const personaStyles = { root: { flexBasis: 0, width: '50px' } };
  const flexOneStyle = { root: { flex: 1 } };
  const attachDocumentInputStyle = { flex: 1 };
  const reviewApprovalWrapperStyles = { flexBasis: 0, width: '500px' };
  const reviewTimelineWrapperStyles = { flex: 3 };
  const addCommentWrapperStyles = { padding: 15 };

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
      <section style={reviewTimelineWrapperStyles}>
        <h3>Review Timeline</h3>
        {!displayOnly && (
          <div className="ms-depth-8" style={addCommentWrapperStyles}>
            <form
              onSubmit={handleSubmit(
                inputMode === ReviewInputMode.Comment ? addComment : addDocument,
              )}
            >
              <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                <Persona styles={personaStyles} className="ms-hiddenLgDown" />

                <LoadingErrorMessage
                  loading={documentMutationLoading}
                  error={documentMutationError}
                  actionName="attaching a document to the review"
                />

                <LoadingErrorMessage
                  loading={commentMutationLoading}
                  error={commentMutationError}
                  actionName="adding a comment to the review"
                />

                {inputMode === ReviewInputMode.Comment && (
                  <Controller
                    control={control}
                    name="comment"
                    as={TextField}
                    placeholder="Add Comment"
                    defaultValue=""
                    disabled={!hasCommentAccess}
                    styles={flexOneStyle}
                    errorMessage={errors?.comment?.message}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please add a comment',
                      },
                    }}
                  />
                )}
                {inputMode === ReviewInputMode.Document && (
                  <div style={attachDocumentInputStyle}>
                    <Controller
                      name="document"
                      control={control}
                      render={({ onChange, onBlur, value, name }): React.ReactElement => (
                        <FileInputTextbox
                          inputValue={documentInputValue}
                          setInputValue={setDocumentInputValue}
                          onFileUploadComplete={setDocumentOnForm}
                          errorMessage={errors?.document?.message || fileValidationError}
                          setValidationError={setFileValidationError}
                          multipleFiles={false}
                        />
                      )}
                      rules={{
                        required: 'Please select a document',
                      }}
                      required
                    />
                  </div>
                )}

                <CanAccess requestedAction="Review:Comment">
                  <div
                    style={{
                      marginBottom: errors?.document?.message || fileValidationError ? 20 : 0,
                    }}
                  >
                    <DefaultButton
                      disabled={commentMutationLoading || documentMutationLoading}
                      split
                      splitButtonAriaLabel="See 2 options"
                      menuProps={commentButtonProps}
                      onClick={handleSubmit(
                        inputMode === ReviewInputMode.Comment ? addComment : addDocument,
                      )}
                      text={
                        inputMode === ReviewInputMode.Comment ? 'Add Comment' : 'Attach Document'
                      }
                    />
                  </div>
                </CanAccess>
              </Stack>
            </form>
          </div>
        )}
        <br />
        <div>
          <ReviewComments reviewComments={review?.reviewComments} loading={loading} />
        </div>
      </section>
      <section style={reviewApprovalWrapperStyles}>
        {!displayOnly && (
          <ReviewApprovals
            review={review}
            approveMutation={approveMutation}
            rejectMutation={rejectMutation}
            reviewApprovals={review?.reviewApprovals}
            loading={loading}
            rejectButtonMode={rejectButtonMode}
            disableApproveButton={disableApproveButton}
            approverWarningMessage={approverWarningMessage}
          />
        )}
      </section>
    </Stack>
  );
};

export default ReviewComponent;
