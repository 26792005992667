import { useQuery } from '@apollo/client';
import {
  ChoiceGroup,
  IComboBoxOption,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Stack,
  TextField,
} from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, FieldValues, UseFormMethods, useWatch } from 'react-hook-form';
import { GetControllersAreFTECheck } from '../../../../utils/statApi/CompaniesApi';
import GET_ENTITY_STATUSES_QUERY, {
  GET_LIFECYCLE_STATUSES_QUERY,
} from '../../../../utils/statApi/EntityStatusApi';
import GET_ENTITY_TYPES_QUERY from '../../../../utils/statApi/EntityTypeApi';
import { yesNoOptions } from '../../../../utils/types/CommonDropdownOptions';
import {
  ICompanyBusinessNature,
  ILifecycleStatus,
} from '../../../../utils/types/ICompanyBusinessNature';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import AcquisitionSelector from '../../../companyCreation/AcquisitionSelector';

interface ICompanyBusinessNatureFormProps {
  form: UseFormMethods<FieldValues>;
  data: ICompanyBusinessNature;
  companyCode: string;
}

const CompanyBusinessNatureForm = ({
  form,
  data,
  companyCode,
}: ICompanyBusinessNatureFormProps): JSX.Element => {
  const { control, errors: formErrors, setValue, watch, getValues } = form;
  const [controllerMustBeFTEMessage, setControllerMustBeFTEMessage] = useState<boolean>(
    data?.controllerMustBeFte,
  );
  const [hasEntityStatusChanged, setHasEntityStatusChanged] = useState<boolean>(false);
  const { loading: loadingFTEValidator, data: dataFTEValidator } = useQuery<{
    controllersAreFTECheck: { areControllersFTE: boolean };
  }>(GetControllersAreFTECheck, {
    fetchPolicy: 'network-only',
    variables: { companyCode },
  });

  const onAcquisitionChange = (value: IComboBoxOption): void => {
    setValue('acquisition', value);
  };

  const ACTIVE = 'Active';
  const TERMINATED = 'Terminated';

  const watchIsAcquisition = watch('isAcquisition', data?.isAcquisition);

  const watchEntityStatus = useWatch({
    control,
    name: 'entityStatus',
    defaultValue: data?.entityStatus,
  });

  const onEntityTypeChange = (value: IDropdownOption): void => {
    setValue('entityType', value);
  };

  const onLifecycleStatusChange = (value: IDropdownOption): void => {
    setValue('lifecycleStatus', value);
  };

  const onEntityStatusChange = (value: IDropdownOption): void => {
    setValue('entityStatus', value);
    setHasEntityStatusChanged(getValues('entityStatus')?.id !== data?.entityStatus?.id);
    setValue('lifecycleStatus', null);
  };

  const isActiveOrTerminated =
    watchEntityStatus?.fieldName === ACTIVE || watchEntityStatus?.fieldName === TERMINATED;

  const EntityTypeDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors: etErrors, handleChange, valueKey, outputKey } = props;
    const { loading, data: entityTypesData } = useQuery(GET_ENTITY_TYPES_QUERY);
    const { entityTypes } = !loading && entityTypesData ? entityTypesData : [];
    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        outputKey={outputKey}
        label={label}
        required={required}
        errors={etErrors}
        dropdownOptionsData={entityTypes}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };
  const EntityStatusDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors, handleChange, valueKey, outputKey } = props;
    const { loading, data: entityStatusData } = useQuery(GET_ENTITY_STATUSES_QUERY);
    const { entityStatuses } = !loading && entityStatusData ? entityStatusData : [];

    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        label={label}
        required={required}
        outputKey={outputKey}
        dropdownOptionsData={entityStatuses}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  const LifecycleStatusDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, errors, handleChange, valueKey, outputKey } = props;
    const { loading, data: lifecycleStatusData } = useQuery(GET_LIFECYCLE_STATUSES_QUERY);
    const { lifecycleStatuses } = !loading && lifecycleStatusData ? lifecycleStatusData : [];

    const entityStatusId = watchEntityStatus?.id;
    const updatedLifecycleStatuses = lifecycleStatuses?.map(
      (lifecycleStatus: ILifecycleStatus) => ({
        ...lifecycleStatus,
        isSelectable: lifecycleStatus.entityStatusId === entityStatusId,
      }),
    );

    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        label={label}
        outputKey={outputKey}
        dropdownOptionsData={updatedLifecycleStatuses}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  const validateAcquisition = (): string => {
    if (!getValues('acquisition')?.text?.trim() && watchIsAcquisition) {
      return 'Please enter or select an acquisition';
    }
    return null;
  };
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        name="entityType"
        label="Entity Type"
        valueKey="entityType"
        control={control}
        defaultValue={data?.entityType}
        render={(props: { value: IDropdownReference }) => {
          return (
            <EntityTypeDropdown
              label="Entity Type"
              valueKey="entityType"
              required
              errors={formErrors}
              handleChange={onEntityTypeChange}
              value={props.value}
            />
          );
        }}
        rules={{
          required: 'Please select an entity type',
        }}
      />
      <Controller
        name="isAcquisition"
        control={control}
        render={() => {
          return (
            <ChoiceGroup
              id="isAcquisition"
              label="Is Associated With Acquisition"
              options={yesNoOptions}
              defaultSelectedKey={data?.isAcquisition ? 'true' : 'false'}
              required
              onChange={(e, newValue): void => {
                setValue('isAcquisition', newValue.key === 'true');
              }}
            />
          );
        }}
      />
      <Controller
        name="acquisition"
        defaultValue={data?.acquisition}
        control={control}
        render={() => {
          return (
            <AcquisitionSelector
              label="Acquisition Name"
              disabled={!watchIsAcquisition}
              onChange={onAcquisitionChange}
              required={watchIsAcquisition}
              errorMessage={watchIsAcquisition ? formErrors?.acquisition?.message : ''}
              defaultValue={data?.acquisition}
            />
          );
        }}
        rules={{
          validate: validateAcquisition,
        }}
      />

      <Controller
        name="dateOfAcquisition"
        control={control}
        disabled={!watchIsAcquisition}
        defaultValue={data?.dateOfAcquisition}
        required={watchIsAcquisition}
        rules={
          watchIsAcquisition
            ? {
                required: 'Please select an acquisition date',
              }
            : {}
        }
        render={({ value }): React.ReactElement => (
          <div role="form">
            <UtcDatePicker
              label="Date of Acquisition"
              isRequired={watchIsAcquisition}
              disabled={!watchIsAcquisition}
              allowTextInput
              disableAutoFocus={false}
              value={value}
              textField={{
                name: 'dateOfAcquisition',
                errorMessage: formErrors?.dateOfAcquisition?.message,
              }}
              onSelectDate={(utcDateString: string): void => {
                setValue('dateOfAcquisition', utcDateString);
              }}
            />
          </div>
        )}
      />

      <Controller
        name="entityStatus"
        label="Legal Status"
        valueKey="entityStatus"
        control={control}
        defaultValue={data?.entityStatus}
        render={(props: { value: IDropdownReference }) => {
          return (
            <EntityStatusDropdown
              label="Entity Status"
              valueKey="entityStatus"
              required
              errors={formErrors}
              handleChange={onEntityStatusChange}
              value={props.value}
            />
          );
        }}
        rules={{
          required: 'Please select an entity status',
        }}
      />
      <Controller
        name="lifecycleStatus"
        label="Lifecycle Status"
        valueKey="lifecycleStatus"
        control={control}
        defaultValue={data?.lifecycleStatus}
        render={(props: { value: IDropdownReference }) => {
          return (
            <LifecycleStatusDropdown
              label="Lifecycle Status"
              valueKey="lifecycleStatus"
              required={isActiveOrTerminated}
              errors={formErrors}
              handleChange={onLifecycleStatusChange}
              value={props.value}
            />
          );
        }}
        rules={
          isActiveOrTerminated
            ? {
                required: 'Please select a Lifecycle Status',
              }
            : {}
        }
      />

      <Controller
        name="effectiveDateOfStatusChange"
        control={control}
        defaultValue={data?.effectiveDateOfStatusChange}
        render={({ value }): React.ReactElement => (
          <div role="form">
            <UtcDatePicker
              label="Effective Date of Status Change"
              isRequired={hasEntityStatusChanged}
              allowTextInput
              disableAutoFocus={false}
              value={value}
              textField={{
                name: 'effectiveDateOfStatusChange',
                errorMessage: formErrors?.effectiveDateOfStatusChange?.message,
              }}
              onSelectDate={(utcDateString: string): void => {
                setValue('effectiveDateOfStatusChange', utcDateString);
              }}
            />
          </div>
        )}
        rules={
          hasEntityStatusChanged
            ? {
                required: 'Please select an Effective Date of Status Change',
              }
            : {}
        }
      />

      <Controller
        as={TextField}
        id="businessNatureOfEntity"
        name="businessNatureOfEntity"
        label="Business Nature of Entity"
        control={control}
        defaultValue={data?.businessNatureOfEntity}
        multiline
        required
        rules={{
          required: 'Business Nature of Entity is required',
        }}
        errorMessage={formErrors?.businessNatureOfEntity?.message}
      />
      {!loadingFTEValidator &&
        !dataFTEValidator?.controllersAreFTECheck.areControllersFTE &&
        controllerMustBeFTEMessage && (
          <MessageBar messageBarType={MessageBarType.warning}>
            One or more controllers are not FTE. You may still save this change.
          </MessageBar>
        )}
      <Controller
        name="controllerMustBeFte"
        defaultValue={data?.controllerMustBeFte?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <ChoiceGroup
              id="controllerMustBeFte"
              name="controllerMustBeFte"
              label="Controller Must Be FTE?"
              options={yesNoOptions}
              defaultSelectedKey={data?.controllerMustBeFte?.toString().toLowerCase()}
              onChange={(e, newValue): void => {
                const enabled = newValue.key.toString() === 'true';
                setValue('controllerMustBeFte', enabled);
                setControllerMustBeFTEMessage(enabled);
              }}
              required
            />
          );
        }}
      />
    </Stack>
  );
};

export default CompanyBusinessNatureForm;
