import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_PROGRESS,
  GET_COMPANY_CREATION_REQUEST_DETAILS,
  ADD_COMPANY_CREATION_REQUEST_COMPANY_CODE,
  UPDATE_COMPANY_CREATION_REQUEST_COMPANY_CODE,
} from '../../utils/statApi/CompanyCreationApi';
import ActionButtons from '../common/ActionButtons';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import CompanyCodeForm from './CompanyCodeForm';
import { ICompanyCode } from './details/types';
import { CompanyCreationStatus, ICompanyCreationRequestDetails } from './types';

interface ICompanyCodePanelProps {
  companyCreationRequestData: ICompanyCreationRequestDetails;
  closePanel: () => void;
}

const CompanyCodePanel = ({
  closePanel,
  companyCreationRequestData,
}: ICompanyCodePanelProps): JSX.Element => {
  const form = useForm<ICompanyCode>({
    defaultValues: {
      ...companyCreationRequestData,
    },
  });
  const { handleSubmit } = form;

  const refetchQueries = [
    {
      query: GET_COMPANY_CREATION_REQUEST_DETAILS,
      variables: {
        companyCreationId: companyCreationRequestData.id,
      },
    },
    {
      query: GET_COMPANY_CREATION_PROGRESS,
      variables: {
        companyCreationId: companyCreationRequestData.id,
      },
    },
  ];

  const [
    addCompanyRequestCompanyCode,
    { loading: loadingAddCompanyCode, error: errorAddCompanyCode },
  ] = useMutation(ADD_COMPANY_CREATION_REQUEST_COMPANY_CODE, {
    refetchQueries,
    onCompleted: () => {
      closePanel();
    },
  });

  const [
    editCompanyRequestCompanyCode,
    { loading: loadingEditCompanyCode, error: errorEditCompanyCode },
  ] = useMutation(UPDATE_COMPANY_CREATION_REQUEST_COMPANY_CODE, {
    refetchQueries,
    onCompleted: () => {
      closePanel();
    },
  });

  const handleOnSaveClick = (formData: ICompanyCode): void => {
    const companyCodeInt = parseInt(formData?.companyCode.toString(), 10);
    if (
      companyCreationRequestData?.companyCode &&
      companyCreationRequestData?.statusId !== CompanyCreationStatus.Canceled &&
      companyCreationRequestData?.statusId !== CompanyCreationStatus.Complete
    ) {
      editCompanyRequestCompanyCode({
        variables: {
          companyRequestId: companyCreationRequestData.id,
          companyCode: companyCodeInt,
        },
      });
    } else {
      addCompanyRequestCompanyCode({
        variables: {
          companyRequestId: companyCreationRequestData.id,
          companyCode: companyCodeInt, // new company code
        },
      });
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Company Code"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Company Code Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loadingAddCompanyCode || loadingEditCompanyCode}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Company Code"
          saveTitle="Save Company Code"
          cancelLabel="Cancel Company Code"
          cancelTitle="Cancel Company Code"
        />
      )}
    >
      <form>
        <LoadingErrorMessage
          error={errorAddCompanyCode || errorEditCompanyCode}
          loading={loadingAddCompanyCode || loadingEditCompanyCode}
        />
        <CompanyCodeForm form={form} companyCreationRequestData={companyCreationRequestData} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyCodePanel;
