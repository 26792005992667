import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';

import { ICompanyGroup } from '../../../utils/types/ICompanyGroups';
import ActionButtons from '../../common/ActionButtons';
import CompanyGroupForm from './CompanyGroupForm';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

interface ICompanyGroupEditPanel {
  onSaveClick: ({
    id,
    name,
    description,
    companyGroupsStatUsers,
    companyGroupCompanies,
  }: ICompanyGroup) => void;
  children: JSX.Element;
  companyGroup?: ICompanyGroup;
  closePanel: () => void;
  mutationLoading: boolean;
}

const CompanyGroupEditPanel: React.FunctionComponent<ICompanyGroupEditPanel> = ({
  closePanel,
  onSaveClick,
  companyGroup,
  children,
  mutationLoading,
}: ICompanyGroupEditPanel) => {
  const companyGroupValidationForm = useForm();
  const { errors, handleSubmit } = companyGroupValidationForm;

  const handleSaveClick = (companyGroupData: ICompanyGroup): void => {
    const companyGroupSaveData = {
      id: companyGroup.id,
      name: companyGroupData.name,
      description: companyGroupData.description,
      companyGroupsStatUsers: companyGroupData.companyGroupsStatUsers,
      companyGroupCompanies: companyGroupData.companyGroupCompanies,
    };
    if (!Object.keys(errors).length) {
      onSaveClick(companyGroupSaveData);
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Save Company Group"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Company Group Edit"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Company Group"
          saveTitle="Save Company Group"
          cancelLabel="Cancel Company Group Edit"
          cancelTitle="Cancel Company Group Edit"
        />
      )}
    >
      {children}
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <CompanyGroupForm companyGroup={companyGroup} form={companyGroupValidationForm} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyGroupEditPanel;
