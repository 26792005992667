import React, { FunctionComponent, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { Stack, IconButton, Shimmer } from '@fluentui/react';
import {
  COMPANY_GROUP_DETAILS_QUERY as query,
  EDIT_COMPANY_GROUP_MUTATION,
} from '../../../utils/statApi/CompanyGroupsApi';
import CompanyGroupUsersPicker from './CompanyGroupUsersPicker';
import CompanyGroupCompanyList from './CompanyGroupCompanyList';
import { ICompanyGroup } from '../../../utils/types/ICompanyGroups';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import Subheader from '../../common/headers/Subheader';
import { CompanyGroupEditPanel } from '..';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import CanAccess from '../../common/canAccess/CanAccess';
import { ICompanyGroupIdParams } from '../../common/ParamTypes';

const CompanyGroupDetail: FunctionComponent = () => {
  const { id } = useParams<ICompanyGroupIdParams>();
  const intId = parseInt(id, 10);
  const { loading, error, data } = useQuery(query, {
    variables: { id: intId },
  });
  const [editMode, setEditMode] = useState(false);
  const { companyGroupDetails } = data || {};
  const history = useHistory();
  const onMutationSuccess = (): void => {
    setEditMode(false);
  };
  const [updateCompanyGroup, { loading: mutationLoading, error: mutationError }] = useMutation(
    EDIT_COMPANY_GROUP_MUTATION,
    {
      onCompleted: onMutationSuccess,
    },
  );

  const handleSaveClick = ({
    name,
    description,
    companyGroupsStatUsers,
    companyGroupCompanies,
  }: ICompanyGroup): void => {
    const companyGroup = {
      id: intId,
      name,
      description,
      companyGroupsStatUsers: companyGroupsStatUsers.map((user) => {
        return {
          alias: user.alias,
          email: user.email,
          firstName: user.firstName,
          graphId: user.graphId,
          lastName: user.lastName,
          name: user.name,
          personnelNumber: user.personnelNumber,
        };
      }),
      companyGroupCompanies: companyGroupCompanies.map((company) => {
        return {
          companyCode: company.companyCode,
          companyId: company.companyId,
          entityLegalName: company.entityLegalName,
        };
      }),
    };
    updateCompanyGroup({
      variables: {
        companyGroup,
      },
    });
  };

  const renderHeader = (): JSX.Element => (
    <Stack horizontal>
      <h1>
        <IconButton
          iconProps={{ iconName: 'Back' }}
          styles={{ root: { marginRight: 10 } }}
          title="Back to company groups list"
          ariaLabel="Back to company groups list"
          onClick={(): void => {
            history.push(`/companygroups/`);
          }}
        />
        {companyGroupDetails?.name}
      </h1>
      {companyGroupDetails?.name && (
        <CanAccess requestedAction="CompanyGroup:Edit">
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            title="Edit Company Group"
            ariaLabel="Edit Company Group"
            onClick={(): void => {
              setEditMode(!editMode);
            }}
          />
        </CanAccess>
      )}
    </Stack>
  );
  const renderSubtitle = (): JSX.Element => {
    if (companyGroupDetails?.description) {
      return <Subheader title={companyGroupDetails?.description} />;
    }
    return null;
  };
  return (
    <>
      <FullWidthHeader title={renderHeader} subtitle={renderSubtitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <LoadingErrorMessage
            loading={loading}
            error={error}
            actionName="loading the company group data"
          />
          {loading && !error && (
            <>
              <Shimmer
                styles={{
                  root: { marginBottom: '10px' },
                }}
              />
              <Shimmer
                styles={{
                  root: { marginBottom: '10px' },
                }}
              />
              <Shimmer
                styles={{
                  root: { marginBottom: '10px' },
                }}
              />
            </>
          )}
          <Stack>
            {companyGroupDetails && (
              <>
                <div style={{ marginBottom: 20 }}>
                  <CompanyGroupUsersPicker
                    isDisabled
                    selectedItems={companyGroupDetails.companyGroupsStatUsers}
                    ariaLabelId="peoplePickerDetail"
                  />
                </div>

                <CompanyGroupCompanyList companies={companyGroupDetails.companyGroupCompanies} />
              </>
            )}
            {editMode && (
              <CompanyGroupEditPanel
                mutationLoading={mutationLoading}
                onSaveClick={handleSaveClick}
                companyGroup={companyGroupDetails}
                closePanel={(): void => {
                  setEditMode(!editMode);
                }}
              >
                <LoadingErrorMessage
                  loading={mutationLoading}
                  error={mutationError}
                  actionName="updating the company group data"
                />
              </CompanyGroupEditPanel>
            )}
          </Stack>
        </div>
      </div>
    </>
  );
};

export default CompanyGroupDetail;
