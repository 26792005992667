import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GET_COMPANY_CREATION_DOCUMENTS,
  GET_COMPANY_CREATION_REQUEST_DETAILS,
  UPDATE_COMPANY_CREATION_DOCUMENTS,
} from '../../../../utils/statApi/CompanyCreationApi';
import { ICompanyCreationParams } from '../../../common/ParamTypes';
import DocumentsPage from '../../../common/documents/DocumentsPage';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { CompanyCreationStatus } from '../../types';

const DocumentsPageWrapper = (): JSX.Element => {
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationRequestId = parseInt(id, 10);

  const {
    data: companyCreationData,
    loading,
    error,
  } = useQuery(GET_COMPANY_CREATION_REQUEST_DETAILS, {
    variables: {
      companyCreationId: parseInt(id, 10),
    },
  });

  const isRequestReadOnly =
    companyCreationData?.companyCreationRequestDetails?.statusId ===
      CompanyCreationStatus.Canceled ||
    companyCreationData?.companyCreationRequestDetails?.statusId === CompanyCreationStatus.Complete;

  return (
    <>
      <LoadingErrorMessage error={error} loading={loading} />
      {!loading && !error && (
        <DocumentsPage
          parentId={companyCreationRequestId}
          query={GET_COMPANY_CREATION_DOCUMENTS}
          mutation={UPDATE_COMPANY_CREATION_DOCUMENTS}
          dataProperty="companyCreationDocuments"
          editPolicy="CompanyCreation:Edit"
          isReadOnly={isRequestReadOnly}
        />
      )}
    </>
  );
};

export default DocumentsPageWrapper;
