import {
  IDropdownOption,
  Stack,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  ProgressIndicator,
} from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  topSuggestedCurrencyCodes,
  savedDividendCurrency,
  suggestedCurrencyCodesHeaderOption,
  allCurrencyCodesHeaderOption,
} from './CurrencyCodeDropdown.config';
import { currencyComboboxStyles, headerOptionStyle } from './CurrencyCodeDropdownStyles';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import {
  GET_DEFAULT_CURRENCY_CODES_QUERY,
  GET_FOREX_CURRENCY_CODES_QUERY,
} from '../../../utils/statApi/CurrencyApi';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';

interface ICurrencyCodeDropdownProps {
  propertyName: string;
  useDefaultCurrenciesForCompanyId?: number;
  handleChange: (newValue: string, valueKey: string) => void;
  defaultValue: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  label?: string | JSX.Element;
  width?: number;
}
const CurrencyCodeDropdown = (props: ICurrencyCodeDropdownProps): JSX.Element => {
  const {
    handleChange,
    defaultValue: savedCurrencyCode,
    propertyName,
    required,
    disabled,
    errorMessage,
    useDefaultCurrenciesForCompanyId,
    label,
    width,
  } = props;
  const isCustomLabel = label && typeof label !== 'string';
  const containerWidth = width || 200;

  const [selectedItem, setSelectedItem] = useState(null);
  const [getDefaultCurrencies, { loading, data, error }] = useLazyQuery(
    GET_DEFAULT_CURRENCY_CODES_QUERY,
  );

  useEffect(() => {
    if (useDefaultCurrenciesForCompanyId) {
      getDefaultCurrencies({ variables: { companyId: useDefaultCurrenciesForCompanyId } });
    }
  }, []);

  const { data: forexCurrencyData, loading: forexCurrencyLoading } = useQuery(
    GET_FOREX_CURRENCY_CODES_QUERY,
  );

  const defaultCompanyCurrencyCodes = data?.defaultCurrencyCodes || [];

  const allForexCurrencyCodes = forexCurrencyData?.currencyCodes || [];

  const loadingCurrencies: boolean = loading || forexCurrencyLoading;

  /**
   * Maps DropdownReference array to DropdownOption array
   */
  const mapToDropdownOptions = (currCodes: IDropdownReference[]): IDropdownOption[] => {
    const result = currCodes
      ? currCodes.map((currencyCode) => {
          return { key: currencyCode.id, text: currencyCode.fieldName };
        })
      : null;
    return result;
  };

  /**
   * Returns
   * 1) current saved currency code (if any),
   * 2) any currency codes saved in the Company's record (e.g. Management Currency, Statutory Currency), and
   * 3) the top suggested currencies in the Stat tool
   */
  const suggestedCurrencyCodes = (): IDropdownOption[] => {
    const companyCurrencyCodes = defaultCompanyCurrencyCodes
      ? mapToDropdownOptions(defaultCompanyCurrencyCodes).concat(topSuggestedCurrencyCodes)
      : topSuggestedCurrencyCodes;

    return savedCurrencyCode
      ? savedDividendCurrency(savedCurrencyCode).concat(companyCurrencyCodes)
      : companyCurrencyCodes;
  };

  /**
   * Returns filtered currencyCodes, removing duplicate currency codes
   */
  const filteredCurrencyCodes = (currencyCodes: IDropdownOption[]): IDropdownOption[] => {
    return currencyCodes.reduce(
      (
        uniqueCurrencyCodes: IDropdownOption[],
        currentCurrencyCode: { key: string; text: string },
      ) => {
        const duplicateCurrencyCode = uniqueCurrencyCodes.find(
          (item) => item.key === currentCurrencyCode.key,
        );
        if (!duplicateCurrencyCode && currentCurrencyCode.text !== '-- ') {
          return uniqueCurrencyCodes.concat([currentCurrencyCode]);
        }
        return uniqueCurrencyCodes;
      },
      [],
    );
  };

  const currencyCodeOptions = filteredCurrencyCodes(
    suggestedCurrencyCodesHeaderOption.concat(
      suggestedCurrencyCodes().concat(
        allCurrencyCodesHeaderOption.concat(mapToDropdownOptions(allForexCurrencyCodes)),
      ),
    ),
  );

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <span
        style={
          option.key === 'allCodesHeader' || option.key === 'suggestedCodesHeader'
            ? headerOptionStyle
            : null
        }
        role="option"
        aria-selected="true"
        tabIndex={option.index}
      >
        <span>{option.text}</span>
      </span>
    );
  };

  const onChangeCurrencyOptions = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
  ): void => {
    if (option.key !== 'moreCodes') {
      setSelectedItem(option.text);
      handleChange(option.text, `${propertyName}`);
    } else {
      setSelectedItem(null);
    }
  };

  const styles = width
    ? {
        container: { width: containerWidth },
        callout: { width: containerWidth },
        optionsContainerWrapper: {
          maxHeight: 200,
        },
      }
    : currencyComboboxStyles;

  return (
    <>
      <LoadingErrorMessage error={error} loading={loadingCurrencies} />
      {loadingCurrencies && (
        <ProgressIndicator
          label="Loading currencies"
          description="Fetching currency data from source"
        />
      )}
      {!loadingCurrencies && allForexCurrencyCodes && (
        <Stack tokens={{ childrenGap: 5 }}>
          <>
            {isCustomLabel && label}
            <ComboBox
              label={isCustomLabel ? undefined : (label as string) || 'Currency'}
              selectedKey={selectedItem || `suggested_${savedCurrencyCode}`}
              onChange={onChangeCurrencyOptions}
              options={currencyCodeOptions}
              styles={styles}
              onRenderOption={onRenderOption}
              errorMessage={errorMessage}
              required={required}
              disabled={disabled}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default CurrencyCodeDropdown;
