import React from 'react';
import { TextField, Stack } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import IAnnualFilingRequirementsFormProps from './IAnnualFilingRequirementsFormProps';
import GET_ACCOUNTING_STANDARDS_QUERY from '../../../../utils/statApi/AccountingStandardsApi';

const AccountingStandardsForm = ({
  annualFilingRequirements,
  form,
}: IAnnualFilingRequirementsFormProps): JSX.Element => {
  const { control, errors, setValue } = form;

  const AccountingStandardsDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, errors: accErrors, handleChange, valueKey, outputKey, required } = props;
    const { loading: accLoading, data: accData } = useQuery(GET_ACCOUNTING_STANDARDS_QUERY);
    const { accountingStandards } = !accLoading && accData ? accData : [];

    return (
      <QueryBasedDropdown
        required={required}
        value={value as IDropdownReference}
        label={label}
        outputKey={outputKey}
        dropdownOptionsData={accountingStandards}
        errors={accErrors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        render={(props) => (
          <AccountingStandardsDropdown
            label="Accounting Standards"
            valueKey="accountingStandardsUsed"
            value={props.value}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleChange={(newValue: any, valueKey: string): void => {
              setValue('accountingStandardsUsed', newValue);
            }}
            errors={errors}
          />
        )}
        name="accountingStandardsUsed"
        control={control}
        defaultValue={annualFilingRequirements.accountingStandardsUsed}
        value={annualFilingRequirements.accountingStandardsUsed}
      />
      <Controller
        as={TextField}
        label="Accounting Standards Comments"
        id="accountingStandardsComments"
        name="accountingStandardsComments"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.accountingStandardsComments}
        errorMessage={errors?.accountingStandardsComments?.message}
      />
      <Controller
        as={TextField}
        label="IFRS Type"
        id="ifrsType"
        name="ifrsType"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.ifrsType}
        errorMessage={errors?.ifrsType?.message}
        rules={{
          maxLength: {
            value: 250,
            message: 'Input is greater than the maximum character limit of 250.',
          },
        }}
      />
      <Controller
        as={TextField}
        label="IFRS Special Elections"
        id="ifrsSpecialElections"
        name="ifrsSpecialElections"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.ifrsSpecialElections}
        errorMessage={errors?.ifrsSpecialElections?.message}
      />
    </Stack>
  );
};

export default AccountingStandardsForm;
