import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { IDividendsReviewSearchState } from './IDividendsReviewSearchResults';
import { DIVIDENDS_REVIEW_LIST_QUERY } from '../../../../utils/statApi/CompanyFiscalYearsApi';
import getColumns from './DividendsReviewList.config';
import { getTotalPageCount } from '../../../common/DetailsLists/pagination/DetailsListPaginationBanner';
import { IPaginationMetadataRefs } from '../../../common/DetailsLists/pagination/IPaginationMetadata';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import FilterCallout from '../../../common/callout/FilterCallout';
import { bodyContentContainer } from '../../../../app/common/styles/CommonStyleObjects';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import ShimmeredDetailsListWrapper from '../../../common/DetailsLists/ShimmeredDetailsListWrapper';
import DetailListPaginationBannerRefs from '../../../common/DetailsLists/pagination/DetailsListPaginationBannerRefs';
import DividendsReviewFilter from './DividendsReviewFilter';
import { IPaginationSearch } from '../../../processes/processSearch/IProcessSearchResult';

const initialState: IDividendsReviewSearchState = {
  companyCodes: [],
  statuses: [],
  countryCodes: [],
  companyAreaCodes: [],
};

const DividendsReviewListPage = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [searchState, setSearchState] = useState<IDividendsReviewSearchState>(initialState);
  const pageCount = React.useRef<number>(0);
  const selectedPage = React.useRef<number>(1);
  const defaultPageSize = React.useRef<number>(25);
  const [companyIdMessage, setCompanyIdMessage] = useState<string>();

  const [fetchDividendsReviews, { data, loading, error }] = useLazyQuery(
    DIVIDENDS_REVIEW_LIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        dividendsReviewSearchDto: {
          companyCodes: searchState.companyCodes,
          statuses: searchState.statuses,
          countryCodes: searchState.countryCodes,
          companyAreaCodes: searchState.companyAreaCodes,
        },
        paginationDto: {
          currentPage: selectedPage.current,
          pageSize: defaultPageSize.current,
        },
      },
    },
  );

  const dividendsReviews = data?.dividendsReviewList.items || [];
  const totalRecordCount: number = data?.dividendsReviewList?.totalCount;

  const queryString = new URLSearchParams(useLocation().search);
  useEffect(() => {
    const stateWithParsedQueryStrings = {
      companyCodes: queryString.getAll('companyCodes'),
      statuses: queryString.getAll('statuses'),
      countryCodes: queryString.getAll('countryCodes'),
      companyAreaCodes: queryString.getAll('companyAreaCodes'),
    } as IDividendsReviewSearchState;

    setSearchState(stateWithParsedQueryStrings);

    const pageSize = queryString.get('pageSize') ?? defaultPageSize.current;
    const currentPage = queryString.get('currentPage') ?? 1;

    selectedPage.current = parseInt(currentPage.toString(), 10);
    defaultPageSize.current = parseInt(pageSize.toString(), 10);

    const companyId = queryString.get('companyIds');
    if (companyId) {
      setCompanyIdMessage(
        `The link you are using is outdated. Please refresh your link by reselecting the filters.`,
      );
    } else {
      setCompanyIdMessage(null);
    }

    fetchDividendsReviews({
      variables: {
        dividendsReviewSearchDto: {
          companyCodes: stateWithParsedQueryStrings.companyCodes,
          statuses: stateWithParsedQueryStrings.statuses,
          countryCodes: stateWithParsedQueryStrings.countryCodes,
          companyAreaCodes: stateWithParsedQueryStrings.companyAreaCodes,
        },
        paginationDto: {
          currentPage: selectedPage.current,
          pageSize: defaultPageSize.current,
        },
      },
    });
  }, [location]);

  const resetPagination = (): void => {
    selectedPage.current = 1;
  };

  const onUpdateUrl = (
    newState: IDividendsReviewSearchState,
    paginationData: IPaginationSearch,
  ) => {
    const newUrlObject = new URLSearchParams();
    // handle array parameters
    Object.entries(newState).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        if (v.length > 0) {
          v.forEach((value) => {
            newUrlObject.append(k as string, value);
          });
        }
      }
    });

    newUrlObject.append('pageSize', paginationData?.pageSize?.toString());
    newUrlObject.append('currentPage', paginationData?.currentPage?.toString());

    history.replace(location.pathname.concat(`?${newUrlObject.toString()}`));
  };

  const updateStateResetPagination = (newState: IDividendsReviewSearchState) => {
    setSearchState(newState);
    resetPagination();
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(newState, paginationData);
  };

  const columns = getColumns();

  const renderHeader = (): JSX.Element => <h1>Dividends Reviews</h1>;

  const onResetFiltersClick = (): void => {
    const emptyState = initialState;
    setSearchState(emptyState);
    resetPagination();
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(emptyState, paginationData);
  };

  const onPageSizeChange = (newPageSize: string | number): void => {
    defaultPageSize.current = newPageSize as number;
    selectedPage.current = 1;

    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };

  const onPageChange = (
    startItemIndex: number,
    endItemIndex: number,
    newPageNumber: number,
  ): void => {
    selectedPage.current = newPageNumber;
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };

  pageCount.current = getTotalPageCount(totalRecordCount, defaultPageSize.current);

  const paginationMetadata: IPaginationMetadataRefs = {
    pageCountRef: pageCount,
    selectedPageRef: selectedPage,
    pageSizeRef: defaultPageSize,
    onPageChange,
    onPageSizeChange,
  };

  const CompanyIdMessageBar = (): JSX.Element | null => {
    if (companyIdMessage) {
      return (
        <MessageBar messageBarType={MessageBarType.info}>
          <span>{companyIdMessage}</span>
        </MessageBar>
      );
    }
    return null;
  };

  const NoResultsMessageBar = (): JSX.Element => {
    if (dividendsReviews?.length === 0 && !loading) {
      return (
        <MessageBar messageBarType={MessageBarType.info}>
          <span>No dividends reviews found for current filters</span>
        </MessageBar>
      );
    }
    return <></>;
  };
  return (
    <>
      <CompanyIdMessageBar />
      <FullWidthHeader
        title={renderHeader}
        subtitle={() => {
          return (
            <Stack horizontalAlign="end">
              <FilterCallout triggerButtonId="DividendsReviewFilter" triggerIconName="Filter">
                <DividendsReviewFilter
                  onResetFiltersClick={onResetFiltersClick}
                  filterState={searchState}
                  onUpdateState={updateStateResetPagination}
                />
              </FilterCallout>
            </Stack>
          );
        }}
      />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <LoadingErrorMessage loading={loading} error={error} />
        <NoResultsMessageBar />
        <ShimmeredDetailsListWrapper
          gridAriaLabelOverride="Dividends Reviews"
          items={dividendsReviews}
          enableShimmer={loading}
          singularListLabel="Dividends Reviews"
          pluralListLabel="Dividends Reviews"
          columns={columns}
        />
        {paginationMetadata && <DetailListPaginationBannerRefs {...paginationMetadata} />}
      </div>
    </>
  );
};

export default DividendsReviewListPage;
