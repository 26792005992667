import { IDropdownOption, ITag, Label, TextField } from '@fluentui/react';
import React, { FormEvent, useState } from 'react';
import { Controller, FieldValues, UseFormMethods } from 'react-hook-form';
import { IAddress, IAddressCharacterLimits } from '../../../utils/types/IAddress';
import ICountry from '../../../utils/types/ICountry';
import { DoubleRequiredMarker, RequiredMarker } from '../../common/formFields/RequiredMarker';
import CountryPicker from '../../countries/CountryPicker';
import StateProvinceRegionDropDown from './StateProvinceRegionDropDown';

interface IAddressFields {
  form: UseFormMethods<FieldValues>;
  data: IAddress;
  fieldName: string;
  disabled?: boolean;
  onlyRequireCountry?: boolean;
  displayDoubleRequiredMarker: boolean;
  characterLimits: IAddressCharacterLimits;
}

const AddressFields = ({
  form,
  data,
  fieldName,
  disabled,
  displayDoubleRequiredMarker,
  onlyRequireCountry,
  characterLimits,
}: IAddressFields): JSX.Element => {
  const { control, errors, setValue, trigger, formState } = form;
  const initialSelectedCountry = data?.country
    ? [{ key: data?.country?.id, text: data?.country?.shortName, name: data?.country?.shortName }]
    : null;
  const [selectedCountry, setSelectedCountry] = useState<ITag[]>(initialSelectedCountry);

  const onCountryUpdate = (items: ITag[]): void => {
    const countries = items.map((item) => {
      const newObject: ICountry = {
        id: item.key.toString(),
        shortName: item.name,
      };
      return newObject;
    });
    setSelectedCountry(items);
    setValue(`${fieldName}.country`, countries.length ? countries[0] : null);
    setValue(`${fieldName}.stateProvinceRegion`, null);
    if (formState.isSubmitted) {
      trigger();
    }
  };

  const onStateProvinceRegionUpdate = (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    setValue(`${fieldName}.stateProvinceRegion`, { id: option?.key, name: option?.text });
  };

  const renderRequiredMarkers = (): JSX.Element => {
    return displayDoubleRequiredMarker ? <DoubleRequiredMarker /> : <RequiredMarker />;
  };

  const customLabelStyles = { paddingBottom: 0 };

  return (
    <div role="form">
      <Controller
        name={`${fieldName}.country`}
        control={control}
        defaultValue={initialSelectedCountry}
        required
        rules={{
          required: {
            value: onlyRequireCountry,
            message: 'Please enter a country',
          },
        }}
        render={(): React.ReactElement => (
          <CountryPicker
            itemLimit={1}
            label={
              <Label style={customLabelStyles}>
                Country
                {renderRequiredMarkers()}
              </Label>
            }
            selectedItems={selectedCountry}
            setSelectedItems={onCountryUpdate}
            isDisabled={disabled}
            errorMessage={errors[fieldName]?.country?.message}
          />
        )}
      />
      <Controller
        name={`${fieldName}.stateProvinceRegion`}
        control={control}
        defaultValue={data?.stateProvinceRegion}
        render={(): React.ReactElement => (
          <StateProvinceRegionDropDown
            selectedCountry={selectedCountry}
            defaultValue={data?.stateProvinceRegion?.id}
            onChange={onStateProvinceRegionUpdate}
            label={
              <Label style={customLabelStyles}>
                State/Province/Region
                {onlyRequireCountry ? null : renderRequiredMarkers()}
              </Label>
            }
            disabled={disabled}
          />
        )}
      />
      <Label style={customLabelStyles}>
        Street Number {onlyRequireCountry ? null : renderRequiredMarkers()}
      </Label>
      <Controller
        as={TextField}
        id={`${fieldName}.streetNumber`}
        name={`${fieldName}.streetNumber`}
        control={control}
        disabled={disabled}
        defaultValue={data?.streetNumber || ''}
        errorMessage={errors[fieldName]?.streetNumber?.message}
        rules={{
          maxLength: {
            value: characterLimits.streetNumber,
            message: `Street Number can only be a max of ${characterLimits.streetNumber} characters`,
          },
        }}
      />
      <Controller
        as={TextField}
        id={`${fieldName}.streetNumber2`}
        name={`${fieldName}.streetNumber2`}
        label="Street Number 2"
        control={control}
        defaultValue={data?.streetNumber2 || ''}
        disabled={disabled}
        errorMessage={errors[fieldName]?.streetNumber2?.message}
        rules={{
          maxLength: {
            value: characterLimits.streetNumber2,
            message: `Street Number 2 can only be a max of ${characterLimits.streetNumber2} characters`,
          },
        }}
      />
      <Label style={customLabelStyles}>
        City {onlyRequireCountry ? null : renderRequiredMarkers()}
      </Label>
      <Controller
        as={TextField}
        id={`${fieldName}.city`}
        name={`${fieldName}.city`}
        control={control}
        defaultValue={data?.city || ''}
        disabled={disabled}
        errorMessage={errors[fieldName]?.city?.message}
        rules={{
          maxLength: {
            value: characterLimits.city,
            message: `City can only be a max of ${characterLimits.city} characters`,
          },
        }}
      />
      <Label style={customLabelStyles}>
        Postal Code {onlyRequireCountry ? null : renderRequiredMarkers()}
      </Label>
      <Controller
        as={TextField}
        id={`${fieldName}.postalZipCode`}
        name={`${fieldName}.postalZipCode`}
        control={control}
        defaultValue={data?.postalZipCode || ''}
        disabled={disabled}
        errorMessage={errors[fieldName]?.postalZipCode?.message}
        rules={{
          maxLength: {
            value: characterLimits.postalZipCode,
            message: `Postal Zip Code can only be a max of ${characterLimits.postalZipCode} characters`,
          },
        }}
      />
    </div>
  );
};

export default AddressFields;
