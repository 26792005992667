import { useQuery } from '@apollo/client';
import { ChoiceGroup, Dropdown, IDropdownOption, Label, Stack } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { GET_IN_HOUSE_CASH_CENTER_TIERS } from '../../../utils/statApi/CompanyCreationApi';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../utils/types/IFieldsetProps';
import CurrencyCodeDropdown from '../../common/formFields/CurrencyCodeDropdown';
import QueryBasedDropdown from '../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import { RequiredMarker } from '../../common/formFields/RequiredMarker';
import RequiredFieldsNotification from '../RequiredNotification';
import { IInHouseCashCenterSetup, RequiredFieldsType } from './types';
import { yesNoOptions } from '../../../utils/types/CommonDropdownOptions';

interface IInHouseCashCenterSetupFormProps {
  form: UseFormMethods<IInHouseCashCenterSetup>;
  data: IInHouseCashCenterSetup;
}

const requiredLabelStyles = { paddingBottom: 0 };

const postingModeOptions: IDropdownOption[] = [
  { key: null, text: '--' },
  { key: 'SYSTEMATICALLY', text: 'Systematically' },
  { key: 'MANUALLY', text: 'Manually' },
];

const InHouseCashCenterSetupForm = ({
  form,
  data,
}: IInHouseCashCenterSetupFormProps): JSX.Element => {
  const { control, errors, watch, setValue } = form;

  const onIhccTierChange = (value: IDropdownOption): void => {
    setValue('inHouseCashCenterTier', value);
  };

  const IhccTierDropdown = (props: IFieldsetProps): JSX.Element => {
    const {
      value,
      label,
      required,
      disabled,
      errors: tierErrors,
      handleChange,
      valueKey,
      outputKey,
    } = props;
    const { loading, data: tierData } = useQuery(GET_IN_HOUSE_CASH_CENTER_TIERS);
    const { inHouseCashCenterTiers } = !loading && tierData ? tierData : [];
    return (
      <>
        <Label style={requiredLabelStyles}>
          {label} <RequiredMarker />
        </Label>
        <QueryBasedDropdown
          value={value as IDropdownReference}
          outputKey={outputKey}
          required={required}
          disabled={disabled}
          errors={tierErrors}
          dropdownOptionsData={inHouseCashCenterTiers}
          handleChange={handleChange}
          valueKey={valueKey}
        />
      </>
    );
  };

  const isIncludedInInHouseCashCenter = watch(
    'isIncludedInInHouseCashCenter',
    data?.isIncludedInInHouseCashCenter?.toString().toLowerCase() || 'true',
  );

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <RequiredFieldsNotification type={RequiredFieldsType.SapSetup} />
      <Controller
        name="isIncludedInInHouseCashCenter"
        defaultValue={data?.isIncludedInInHouseCashCenter?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Should this entity be included in IHCC? <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="isIncludedInInHouseCashCenter"
                name="isIncludedInInHouseCashCenter"
                options={yesNoOptions}
                defaultSelectedKey={data?.isIncludedInInHouseCashCenter?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('isIncludedInInHouseCashCenter', newValue.key);
                  if (newValue.key === 'false') {
                    setValue('inHouseCashCenterTier', null);
                    setValue('inHouseCashCenterAccountCurrency', null);
                    setValue('inHouseCashCenterPostingMode', null);
                  }
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="inHouseCashCenterTier"
        valueKey="inHouseCashCenterTier"
        defaultValue={data?.inHouseCashCenterTier}
        control={control}
        render={(props) => {
          return (
            <IhccTierDropdown
              label="IHCC Tier"
              valueKey="inHouseCashCenterTier"
              errors={errors}
              disabled={isIncludedInInHouseCashCenter !== 'true'}
              handleChange={onIhccTierChange}
              // eslint-disable-next-line react/prop-types
              value={props.value}
            />
          );
        }}
      />
      <Controller
        name="inHouseCashCenterAccountCurrency"
        control={control}
        defaultValue={data?.inHouseCashCenterAccountCurrency}
        render={(): React.ReactElement => (
          <CurrencyCodeDropdown
            label={
              <Label style={requiredLabelStyles}>
                Account Currency in IHCC Module <RequiredMarker />
              </Label>
            }
            propertyName="inHouseCashCenterAccountCurrency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('inHouseCashCenterAccountCurrency', newValue);
            }}
            disabled={isIncludedInInHouseCashCenter !== 'true'}
            defaultValue={data?.inHouseCashCenterAccountCurrency}
            width={500}
          />
        )}
      />
      <Controller
        name="inHouseCashCenterPostingMode"
        control={control}
        defaultValue={data?.inHouseCashCenterPostingMode}
        render={({ value }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Will IHCC transaction be posted systematically (via IHCC module) or manually (via
              JEM)?
              <RequiredMarker />
            </Label>
            <Dropdown
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
              ): void => {
                setValue('inHouseCashCenterPostingMode', option.key);
              }}
              disabled={isIncludedInInHouseCashCenter !== 'true'}
              options={postingModeOptions}
              selectedKey={value}
              placeholder="Select an option"
            />
          </>
        )}
      />
    </Stack>
  );
};

export default InHouseCashCenterSetupForm;
