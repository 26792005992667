import { Separator } from '@fluentui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  GET_COMPANY_OTHER_DOCUMENTS,
  UPDATE_COMPANY_DOCUMENTS,
} from '../../../utils/statApi/CompaniesApi';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import DocumentsPage from '../../common/documents/DocumentsPage';

interface ICompanyDocumentsProps {
  showDeletedFiles: boolean;
}

const CompanyDocuments = ({ showDeletedFiles }: ICompanyDocumentsProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const companyCodeId = parseInt(companyCode, 10);

  return (
    <>
      <Separator />
      <DocumentsPage
        parentId={companyCodeId}
        query={GET_COMPANY_OTHER_DOCUMENTS}
        mutation={UPDATE_COMPANY_DOCUMENTS}
        dataProperty="otherCompanyDocuments"
        showDeletedFiles={showDeletedFiles}
        editPolicy="CompanyDocuments:Upload"
        isReadOnly={false}
      />
    </>
  );
};

export default CompanyDocuments;
