import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useParams } from 'react-router-dom';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import useValidationFromServer from '../../../utils/validation/useValidationFromServer';
import VALIDATION_DATA_QUERY from '../../../utils/statApi/ValidationsApi';
import AgmStatusForm from '../detail/forms/AgmStatusForm';
import IAgmFiscalYears from '../../../utils/types/IAgmFiscalYears';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import CanAccess from '../../common/canAccess/CanAccess';
import { IProcessIdParams } from '../../common/ParamTypes';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import FiscalYearAgmStatusDueDateEditPanel from './FiscalYearAgmStatusDueDateEditPanel';

export interface IAgmStatusEditPanelProps {
  agmStatus: IAgmFiscalYears;
  closePanel: () => void;
  fiscalYearMutation: DocumentNode;
  agmMutation: DocumentNode;
  companyCode: string;
  refetch: () => void;
}

const FiscalYearAgmStatusEditPanel: React.FunctionComponent<IAgmStatusEditPanelProps> = ({
  closePanel,
  agmStatus,
  fiscalYearMutation,
  agmMutation,
  companyCode,
  refetch,
}: IAgmStatusEditPanelProps): JSX.Element => {
  const { errors, handleSubmit, handleChange } = useValidationFromServer(
    agmStatus,
    VALIDATION_DATA_QUERY,
    'agmStatus',
  );
  const { processId } = useParams<IProcessIdParams>();
  const [celaDateRequired, setCelaDateRequired] = useState(null);
  const [isDueDateEditPanelOpen, setIsDueDateEditPanelOpen] = useState(false);
  const [updateAgmFiscalYearStatus, { loading: mutationLoading, error: mutationError }] =
    useMutation(fiscalYearMutation, {
      onCompleted: closePanel,
    });

  const toggleDueDatePanel = (): void => {
    setIsDueDateEditPanelOpen(!isDueDateEditPanelOpen);
  };
  // Save AGM Status & CELA Date
  const handleOnSaveClick = (formData: IAgmFiscalYears): void => {
    if (formData.agmStatus.fieldName === 'Completed' && !formData.celaAgmStatusDate) {
      setCelaDateRequired({
        celaAgmStatusDate: {
          message: 'CELA AGM Meeting Date is required',
        },
      });
      return;
    }
    setCelaDateRequired(null);

    const agmStatusSaveData = {
      id: formData.id,
      agmStatus: {
        id: formData.agmStatus.id,
        fieldName: formData.agmStatus.fieldName,
      },
      celaAgmStatusDate: formData.celaAgmStatusDate,
    };
    const updateFiscalYearStatus = () =>
      updateAgmFiscalYearStatus({
        variables: {
          agmStatus: {
            ...agmStatusSaveData,
          },
          processId: parseInt(processId, 10),
        },
      });
    updateFiscalYearStatus();
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit AGM Status"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close AGM Status Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="CelaAgmStatus:Update">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveTitle="Save AGM Status"
            saveLabel="Save AGM Status"
            cancelTitle="Cancel AGM Status Edit"
            cancelLabel="Cancel AGM Status Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the AGM Status to the server"
        />
        <AgmStatusForm
          agmStatus={agmStatus}
          handleChange={handleChange}
          errors={{ ...errors, ...celaDateRequired }}
          handleToggleDueDatePanelOpen={toggleDueDatePanel}
        />
      </form>
      {isDueDateEditPanelOpen && (
        <FiscalYearAgmStatusDueDateEditPanel
          agmStatus={agmStatus}
          closePanel={toggleDueDatePanel}
          agmMutation={agmMutation}
          companyCode={companyCode}
          refetch={refetch}
        />
      )}
    </CoherencePanel>
  );
};
export default FiscalYearAgmStatusEditPanel;
