import { useMutation } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';
import { PanelType } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { UPDATE_CUSTOM_CONTACTS_MUTATION } from '../../../utils/statApi/ServiceContactsApi';
import ActionButtons from '../../common/ActionButtons';
import CoherencePanelWithSubheader from '../../common/CoherencePanelWithSubheader';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import ContactGroupsForm from './ContactGroupsForm';
import { ICustomContactGroup, IServiceContact, IServiceContactsUserAssignment } from './interfaces';
import { panelFooterStyle } from '../../../app/common/styles/CommonStyleObjects';

export interface IContactGroupsEditPanelProps {
  customContactGroups: ICustomContactGroup[];
  mainContacts: IServiceContactsUserAssignment;
  closePanel: () => void;
}
const ContactGroupsEditPanel = ({
  customContactGroups,
  closePanel,
  mainContacts,
}: IContactGroupsEditPanelProps): JSX.Element => {
  const customContactForm = useForm({
    defaultValues: {
      customContactGroups,
    },
  });

  const { handleSubmit } = customContactForm;

  const { companyCode } = useParams<ICompanyCodeParams>();

  const [updateCustomContacts, { loading, error }] = useMutation(UPDATE_CUSTOM_CONTACTS_MUTATION, {
    onCompleted: () => {
      closePanel();
    },
  });

  const handleOnSaveClick = (data: { customContactGroups: ICustomContactGroup[] }): void => {
    const groups = data?.customContactGroups || [];

    const mappedContactGroups: ICustomContactGroup[] = groups.map(
      (contactGroup: ICustomContactGroup) => {
        return {
          id: +contactGroup.id,
          label: contactGroup.label,
          contacts: contactGroup.contacts.map((serviceContact: IServiceContact) => {
            return {
              graphId: serviceContact.graphId,
              name: serviceContact.name || serviceContact.text,
              firstName: serviceContact.firstName,
              lastName: serviceContact.lastName,
              email: serviceContact.email,
              alias: serviceContact.alias,
              contactType: 'COMPANYUSER',
              personnelNumber: serviceContact.personnelNumber,
            };
          }),
        };
      },
    );

    updateCustomContacts({
      variables: {
        companyCode,
        contactGroups: { contactGroups: mappedContactGroups },
      },
    });
  };

  return (
    <CoherencePanelWithSubheader
      closeButtonAriaLabel="Close Contact Groups Edit Panel"
      type={PanelType.medium}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      isLightDismiss
      isFooterAtBottom
      styles={panelFooterStyle}
      headerText="Edit Contact Groups"
      onRenderFooterContent={(): JSX.Element => (
        <ActionButtons
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Contact Groups"
          saveTitle="Save Contact Groups"
          cancelLabel="Cancel Contact Groups Edit"
          cancelTitle="Cancel Contact Groups Edit"
          mutationLoading={loading}
        />
      )}
      subheader={<LoadingErrorMessage loading={loading} error={error} />}
    >
      <form>
        <ContactGroupsForm mainContacts={mainContacts} form={customContactForm} />
      </form>
    </CoherencePanelWithSubheader>
  );
};

export default ContactGroupsEditPanel;
