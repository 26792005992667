import { StackItem } from '@fluentui/react';
import React from 'react';
import momentUtc from '../../../utils/DateFormatter';
import { IDocument } from '../../../utils/statApi/DocumentApi';
import StatDateFormats from '../../../utils/types/DateFormats';
import FileSelectorItem from '../formFields/fileUpload/fileSelectorItem';

interface IDocumentProps {
  document: IDocument;
  index: number;
}

const DocumentView = ({ document, index }: IDocumentProps): JSX.Element => {
  return (
    <StackItem key={`${document.id}-${document.title}}`}>
      <FileSelectorItem
        index={index}
        user={document.uploader}
        url={document.url}
        fileName={document.title}
        uiDisabled={false}
        allowDelete={false}
        allowDownload
        allowViewInTab
        activity={`Uploaded ${momentUtc(document.uploadDate, StatDateFormats.DayBreakout, true)}`}
      />
    </StackItem>
  );
};

export default DocumentView;
