import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  UPDATE_IN_HOUSE_CASH_CENTER_SETUP,
} from '../../../utils/statApi/CompanyCreationApi';

import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import InHouseCashCenterSetupForm from './InHouseCashCenterSetupForm';
import { IInHouseCashCenterSetup } from './types';

interface IInHouseCashCenterSetupPanelProps {
  closePanel: () => void;
  data: IInHouseCashCenterSetup;
  companyCreationId: number;
}

const InHouseCashCenterSetupPanel = ({
  closePanel,
  data,
  companyCreationId,
}: IInHouseCashCenterSetupPanelProps): JSX.Element => {
  const form = useForm<IInHouseCashCenterSetup>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateInHouseCashCenterSetup, { loading, error }] = useMutation(
    UPDATE_IN_HOUSE_CASH_CENTER_SETUP,
    {
      onCompleted: closePanel,
      refetchQueries: [
        {
          query: GET_COMPANY_CREATION_COMPANY_INFO,
          variables: {
            companyCreationId,
          },
        },
        {
          query: GET_COMPANY_CREATION_PROGRESS,
          variables: {
            companyCreationId,
          },
        },
      ],
    },
  );

  const handleOnSaveClick = (formData: IInHouseCashCenterSetup): void => {
    const isIncluded =
      formData.isIncludedInInHouseCashCenter === null
        ? null
        : formData.isIncludedInInHouseCashCenter.toString() === 'true';

    updateInHouseCashCenterSetup({
      variables: {
        input: {
          id: formData.id || -1,
          companyCreationRequestId: companyCreationId,
          inHouseCashCenterTier: {
            id: formData.inHouseCashCenterTier?.id || -1,
            name: formData.inHouseCashCenterTier?.name || null,
          },
          isIncludedInInHouseCashCenter: isIncluded,
          inHouseCashCenterAccountCurrency: formData.inHouseCashCenterAccountCurrency,
          inHouseCashCenterPostingMode: formData.inHouseCashCenterPostingMode,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit IHCC Setup"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit IHCC Setup Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save IHCC Setup"
          saveTitle="Save IHCC Setup"
          cancelLabel="Cancel IHCC Setup"
          cancelTitle="Cancel IHCC Setup"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <InHouseCashCenterSetupForm form={form} data={data} />
      </form>
    </CoherencePanel>
  );
};

export default InHouseCashCenterSetupPanel;
