import { Icon, NeutralColors } from '@fluentui/react';
import React, { CSSProperties } from 'react';
import useToast from '../../utils/hooks/useToast';

const toastContainerStyle: CSSProperties = {
  position: 'absolute',
  top: 76,
  right: 45,
  zIndex: 9999,
};

const toastIconStyle: CSSProperties = { fontSize: 16, alignSelf: 'center', marginRight: 8 };
const toastIconContainerStyle: CSSProperties = { display: 'flex', paddingBottom: 4 };
const toastItemContainerStyle: CSSProperties = {
  marginBottom: 16,
  maxWidth: 320,
  background: NeutralColors.white,
};
const toastItemWrapperStyle: CSSProperties = {
  paddingTop: 12,
  paddingBottom: 12,
  marginLeft: 32,
  marginRight: 32,
};
const toastItemContentStyle: CSSProperties = { fontWeight: 400 };
const toastItemHeaderStyle: CSSProperties = { fontWeight: 600 };

const ToastContainer = (): JSX.Element => {
  const { toasts } = useToast();

  return (
    <div style={toastContainerStyle}>
      {toasts?.map((toast) => (
        <div
          key={toast?.id}
          className="ms-depth-4  ms-motion-duration-4 ms-motion-slideUpIn"
          style={toastItemContainerStyle}
        >
          <div style={toastItemWrapperStyle}>
            <div style={toastIconContainerStyle}>
              <Icon iconName={toast?.iconName || ''} style={toastIconStyle} />
              <strong style={toastItemHeaderStyle}>{toast?.header}</strong>
            </div>
            <div style={toastItemContentStyle}>{toast?.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToastContainer;
