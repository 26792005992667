import { DefaultButton, IDropdownOption, ITag, Separator, Stack } from '@fluentui/react';
import React from 'react';
import { filterWrapperStyles } from '../../../../app/common/styles/CommonStyleObjects';
import ReviewStatusDropdown from '../../../common/formFields/ReviewStatusDropdown';
import ProcessSearchCompanyPicker from '../../../processes/processSearch/ProcessSearchCompanyPicker';
import ProcessSearchCountryPicker from '../../../processes/processSearch/ProcessSearchCountryPicker';
import FilterWwAreaPicker from '../../list/FilterWwAreaPicker';
import { IDividendsReviewSearchState } from './IDividendsReviewSearchResults';

interface IDividendsReviewFilterProps {
  onResetFiltersClick: () => void;
  filterState: IDividendsReviewSearchState;
  onUpdateState: (newState: IDividendsReviewSearchState) => void;
}
const DividendsReviewFilter = (props: IDividendsReviewFilterProps): JSX.Element => {
  const { onResetFiltersClick, filterState, onUpdateState } = props;

  const { statuses, companyCodes, countryCodes, companyAreaCodes } = filterState;

  const onFieldChange = (
    propertyName: keyof IDividendsReviewSearchState,
    value: number[] | string[],
  ) => {
    const newState = { ...filterState, [propertyName]: value };
    onUpdateState(newState);
  };

  const onStatusUpdate = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    const statusesFromState = item.selected
      ? [...statuses, item.key as string]
      : statuses.filter((id) => id !== item.key);
    onFieldChange('statuses', statusesFromState);
  };

  return (
    <Stack.Item grow disableShrink styles={filterWrapperStyles}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <h2>Filter</h2>
        <DefaultButton text="Reset Filters" onClick={onResetFiltersClick} />
      </Stack>
      <Separator />
      <ReviewStatusDropdown onStatusUpdate={onStatusUpdate} selectedStatuses={statuses} />
      <Separator />
      <ProcessSearchCompanyPicker
        initialSelectedCodes={companyCodes}
        onCompanyUpdate={(items: ITag[]) => {
          onFieldChange(
            'companyCodes',
            items.map((item) => item.name.split(' ')[0].toString()),
          );
        }}
      />
      <Separator />
      <ProcessSearchCountryPicker
        initialSelectedCodes={countryCodes}
        onCountryUpdate={(items: ITag[]) => {
          onFieldChange(
            'countryCodes',
            items.map((item) => item.key.toString()),
          );
        }}
      />
      <Separator />
      <FilterWwAreaPicker
        initialSelectedCodes={companyAreaCodes}
        onRegionAreaUpdate={(items: ITag[]) => {
          onFieldChange(
            'companyAreaCodes',
            items.map((item) => item.key.toString()),
          );
        }}
      />
      <Separator />
    </Stack.Item>
  );
};
export default DividendsReviewFilter;
