import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RolesAndActionsPage from './RolesAndActionsPage';

const RoleDescriptionRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/role-description" component={RolesAndActionsPage} />
    </Switch>
  );
};

export default RoleDescriptionRoute;
