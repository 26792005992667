import { Link, Stack } from '@fluentui/react';
import React from 'react';
import GlobeSvg from '../common/svgs/GlobeSvg';
import InsightCard from './InsightCard';

const liStyle = { marginBottom: 10 };

export const legacyCompaniesCardContent: JSX.Element = (
  <Stack>
    <ul>
      <li style={liStyle}>Company &#38; service contacts information</li>
      <li style={liStyle}>Filing requirements, filing status &#38; dividend information</li>
      <li style={liStyle}>Document archive</li>
      <li style={liStyle}>Process &#38; milestone management</li>
    </ul>
  </Stack>
);

export const LegacyCompanyCard = (): JSX.Element => {
  return (
    <InsightCard
      caption="Recent Companies"
      mainText={legacyCompaniesCardContent}
      footerLink={<Link to="/companies">Go to Company &#38; Process Management</Link>}
      svgComponent={<GlobeSvg />}
    />
  );
};
export default LegacyCompanyCard;
