import React, { CSSProperties, useEffect, useState } from 'react';
import { Stack, IconButton, Separator } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import CanAccess from '../../common/canAccess/CanAccess';
import SpanWithLabel from '../../common/SpanWithLabel';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';
import {
  IFinancialStatementProcess,
  IMilestone,
  IMilestoneRule,
  MilestoneRuleType,
  ProcessType,
} from './Interfaces';
import formatCurrency from '../../../utils/CurrencyFormatter';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import DividendsLink from '../../common/DividendsLink';
import monthDayFormat from '../../../utils/MonthDayFormatter';

interface IProcessDetailSideColumnProps {
  processDetail: IFinancialStatementProcess;
  toggleProcessEditPanel: () => void;
  toggleCorpReasonForLateFilingEditPanel: () => void;
  toggleFilingViolationPanel: () => void;
  toggleReportingExclusion: () => void;
  toggleHistoryFilingViolationPanel: () => void;
  toggleDividendsPanel: () => void;
  toggleAGMEditPanel: () => void;
  isFinancialProcess: boolean;
}
const ProcessDetailSideColumn = (props: IProcessDetailSideColumnProps): JSX.Element => {
  const {
    processDetail,
    toggleProcessEditPanel,
    toggleCorpReasonForLateFilingEditPanel,
    toggleFilingViolationPanel,
    toggleHistoryFilingViolationPanel,
    toggleReportingExclusion,
    toggleDividendsPanel,
    toggleAGMEditPanel,
    isFinancialProcess,
  } = props;
  const [hasReasonForFilingViolationRuleType, setHasReasonForFilingViolationRuleType] =
    useState<boolean>(false);

  useEffect(() => {
    const areAnyRulesReasonForLateFiling = processDetail?.milestones?.some(
      (milestone: IMilestone) => {
        return milestone.milestoneRules?.some((milestoneRule: IMilestoneRule) => {
          return milestoneRule.type === MilestoneRuleType.reasonForViolation;
        });
      },
    );
    setHasReasonForFilingViolationRuleType(areAnyRulesReasonForLateFiling);
  }, []);

  const { companyCode } = useParams<ICompanyCodeParams>();

  const spanWrapperStyles = {
    root: {
      minWidth: 170,
      maxWidth: 300,
      flex: '50%',
      padding: '0',
    },
  };

  const sectionHeaderStyles: CSSProperties = { margin: 0, padding: 0, fontSize: 14 };
  const childrenGap = '10 0';

  const getFilingDueDateSubLabel = (): string => {
    if (processDetail?.filingDueDate !== processDetail?.originalFilingDueDate) {
      return `(Extended from ${momentUtc(processDetail?.originalFilingDueDate)})`;
    }
    return null;
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 30 }}>
        <div>
          <Stack horizontal horizontalAlign="space-between">
            <h3 style={sectionHeaderStyles}>Process Details</h3>
            <CanAccess requestedAction="AuditOpinion:Update">
              {isFinancialProcess && (
                <IconButton
                  title="Edit Process Details"
                  ariaLabel="Edit Process Details"
                  iconProps={{ iconName: 'Edit' }}
                  onClick={(): void => {
                    toggleProcessEditPanel();
                  }}
                />
              )}
            </CanAccess>
          </Stack>
          <Separator />
          <Stack horizontal wrap tokens={{ childrenGap }}>
            <SpanWithLabel
              stackStyles={spanWrapperStyles}
              labelText="Reporting Period End Date"
              value={momentUtc(processDetail?.reportingPeriodEndDate, StatDateFormats.DayBreakout)}
            />
            <SpanWithLabel
              stackStyles={spanWrapperStyles}
              labelText="Filing Due Date"
              subLabelText={getFilingDueDateSubLabel()}
              value={momentUtc(processDetail?.filingDueDate, StatDateFormats.DayBreakout)}
            />

            {isFinancialProcess && (
              <>
                <SpanWithLabel
                  stackStyles={spanWrapperStyles}
                  labelText="Audit Opinion"
                  value={processDetail?.auditOpinion?.fieldName}
                />

                <SpanWithLabel
                  stackStyles={spanWrapperStyles}
                  labelText="Statutory Audit Finding"
                  value={processDetail?.statutoryAuditFinding}
                />
                <SpanWithLabel
                  stackStyles={spanWrapperStyles}
                  labelText="Remediation Plan in Place"
                  value={processDetail?.remediationPlanInPlace}
                />
                <SpanWithLabel
                  stackStyles={spanWrapperStyles}
                  labelText="Expected Closure Date"
                  value={
                    processDetail?.expectedClosureDate
                      ? momentUtc(processDetail.expectedClosureDate, StatDateFormats.DayBreakout)
                      : '--'
                  }
                />
              </>
            )}
          </Stack>
        </div>
        {isFinancialProcess && (
          <>
            <div>
              <Stack horizontal horizontalAlign="space-between">
                <h3 style={sectionHeaderStyles}>Dividends</h3>
                <DividendsLink
                  dividends={processDetail?.dividends}
                  companyCode={companyCode}
                  toggleDividendsPanel={toggleDividendsPanel}
                />
              </Stack>
              <Separator />
              <Stack horizontal wrap tokens={{ childrenGap }}>
                <SpanWithLabel
                  labelText="Review Status"
                  stackStyles={spanWrapperStyles}
                  value={processDetail?.dividends?.reviewStatus}
                />
                <SpanWithLabel
                  labelText="Proposed Amount"
                  stackStyles={spanWrapperStyles}
                  value={
                    processDetail?.dividends?.proposedDividendAllowed
                      ? formatCurrency(
                          processDetail?.dividends?.currencyCode,
                          processDetail?.dividends?.proposedDividendAllowed,
                        )
                      : '--'
                  }
                />
              </Stack>
            </div>
            <div>
              <Stack horizontal horizontalAlign="space-between">
                <h3 style={sectionHeaderStyles}>AGM</h3>
                <CanAccess requestedAction="CelaAgmStatus:Update">
                  {isFinancialProcess && (
                    <IconButton
                      title="Edit AGM Details"
                      ariaLabel="Edit AGM Details"
                      iconProps={{ iconName: 'Edit' }}
                      onClick={(): void => {
                        toggleAGMEditPanel();
                      }}
                    />
                  )}
                </CanAccess>
              </Stack>
              <Separator />
              <Stack horizontal wrap tokens={{ childrenGap }}>
                <SpanWithLabel
                  stackStyles={spanWrapperStyles}
                  labelText="AGM Due Date"
                  value={monthDayFormat(
                    processDetail?.agmFiscalYear.agmDueDateMonth,
                    processDetail?.agmFiscalYear.agmDueDateDay,
                  )}
                />
                <SpanWithLabel
                  labelText="AGM Information"
                  stackStyles={spanWrapperStyles}
                  value={processDetail?.agmFiscalYear.agmInformation}
                />
                <SpanWithLabel
                  labelText="AGM Status"
                  stackStyles={spanWrapperStyles}
                  value={processDetail?.agmFiscalYear.agmStatus?.fieldName}
                />
                <SpanWithLabel
                  labelText="AGM Meeting Date"
                  stackStyles={spanWrapperStyles}
                  value={momentUtc(
                    processDetail?.agmFiscalYear.celaAgmStatusDate,
                    StatDateFormats.DayBreakout,
                    true,
                  )}
                />
              </Stack>
            </div>
          </>
        )}
        {hasReasonForFilingViolationRuleType && (
          <>
            <div>
              <Stack horizontal horizontalAlign="space-between">
                <h3 style={sectionHeaderStyles}>Reason For Filing Violation</h3>
                <Stack horizontal>
                  <CanAccess requestedAction="ReasonForFilingViolation:Edit">
                    <IconButton
                      title="Edit Reason For Filing Violation"
                      ariaLabel="Edit Reason For Filing Violation"
                      iconProps={{ iconName: 'Edit' }}
                      onClick={(): void => {
                        toggleFilingViolationPanel();
                      }}
                    />
                  </CanAccess>
                  <IconButton
                    title="View Reason For Filing Violation History"
                    ariaLabel="View Reason For Filing Violation History"
                    iconProps={{ iconName: 'History' }}
                    onClick={(): void => {
                      toggleHistoryFilingViolationPanel();
                    }}
                  />
                </Stack>
              </Stack>
              <Separator />
              <Stack tokens={{ childrenGap: 15 }}>
                <SpanWithLabel
                  labelText="Reason"
                  value={processDetail?.reasonForFilingViolation?.fieldName}
                />
                <SpanWithLabel
                  labelText="Comments"
                  value={processDetail?.reasonForFilingViolationComments}
                />
              </Stack>
            </div>
            <div>
              <Stack horizontal horizontalAlign="space-between">
                <h3 style={sectionHeaderStyles}>Corp Reason For Late Filing</h3>
                <CanAccess requestedAction="CorpReasonForFilingViolation:Edit">
                  <IconButton
                    title="Edit Corp Reason For Late Filing"
                    ariaLabel="Edit Corp Reason For Late Filing"
                    iconProps={{ iconName: 'Edit' }}
                    onClick={(): void => {
                      toggleCorpReasonForLateFilingEditPanel();
                    }}
                  />
                </CanAccess>
              </Stack>
              <Separator />
              <Stack tokens={{ childrenGap: 15 }}>
                <SpanWithLabel
                  labelText="Reason"
                  value={processDetail?.corpReasonForLateFiling?.fieldName}
                />
                <SpanWithLabel
                  labelText="Comments"
                  value={processDetail?.corpReasonForLateFilingComment}
                />
              </Stack>
            </div>
          </>
        )}
        <>
          {(processDetail?.processType === ProcessType.DirectTax ||
            processDetail?.processType === ProcessType.AnnualFinancialStatements) && (
            <div>
              <Stack horizontal horizontalAlign="space-between">
                <h3 style={sectionHeaderStyles}>Reporting Modifications</h3>
                <CanAccess requestedAction="ReasonForReportExclusion:Edit">
                  <IconButton
                    title="Edit Reporting Modifications"
                    ariaLabel="Edit Reporting Modifications"
                    iconProps={{ iconName: 'Edit' }}
                    onClick={(): void => {
                      toggleReportingExclusion();
                    }}
                  />
                </CanAccess>
              </Stack>
              <Separator />
              <Stack tokens={{ childrenGap: 15 }}>
                <SpanWithLabel
                  labelText="Exclude From Metrics And Reporting"
                  value={processDetail?.excludeFromMetrics}
                />
                <SpanWithLabel
                  labelText="Reason"
                  value={processDetail?.reasonForReportExclusion?.fieldName}
                />
                <SpanWithLabel
                  labelText="Comments"
                  value={processDetail?.reasonForReportExclusionComments}
                />
                <SpanWithLabel
                  labelText="Approved"
                  value={processDetail?.processExceptionApproved}
                />
                <SpanWithLabel
                  labelText="Approved By"
                  value={processDetail?.reportExceptionApprovedByDisplayName}
                />
                <SpanWithLabel
                  labelText="Approval Reason"
                  value={processDetail?.reasonForReportingExclusionApproval?.fieldName}
                />
                <SpanWithLabel
                  labelText="Approved Comments"
                  value={processDetail?.reportExceptionApprovalReasonComments}
                />
              </Stack>
            </div>
          )}
        </>
      </Stack>
    </>
  );
};
export default ProcessDetailSideColumn;
