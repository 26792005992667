import React from 'react';
import { IColumn } from '@fluentui/react';
import { ApolloError } from '@apollo/client';
import getColumns from './ProcessTypeList.config';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IProcessType } from '../../../utils/types/IProcessType';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import { bodyContentWrapper } from '../../../app/common/styles/CommonStyleObjects';

interface IProcessTypeListProps {
  renderProcessTypeNameLink: (item: IProcessType) => JSX.Element;
  processTypes: [];
  loading: boolean;
  error: ApolloError;
  emptyStateMessage: string;
  additionalColumns?: IColumn[];
}
const ProcessTypeList = (props: IProcessTypeListProps): JSX.Element => {
  const {
    renderProcessTypeNameLink,
    processTypes,
    loading,
    error,
    emptyStateMessage,
    additionalColumns,
  } = props;
  let columns = getColumns({
    renderProcessTypeNameLink,
  });
  if (additionalColumns) {
    columns = columns.concat(additionalColumns);
  }
  const hasProcessTypes = processTypes && processTypes.length > 0;
  return (
    <>
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading the process type list"
      />
      {(loading || hasProcessTypes) && (
        <ShimmeredDetailsListWrapper
          columns={columns}
          items={processTypes || []}
          enableShimmer={loading}
          singularListLabel="Process Type"
          pluralListLabel="Process Types"
        />
      )}
      {!loading && !hasProcessTypes && (
        <div className={bodyContentWrapper}>{emptyStateMessage}</div>
      )}
    </>
  );
};

export default ProcessTypeList;
