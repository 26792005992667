import React from 'react';
import ReactDOM from 'react-dom';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { mergeStyles, NeutralColors } from '@fluentui/react';
import AppLoader from './components/AppLoader';

/**
 * Section below is reseting some browser defaults
 * in order to get full height page layout
 */
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#app)': {
      margin: 0,
      padding: 0,
      minHeight: '100vh',
      backgroundColor: NeutralColors.gray10,
    },
    ':global(a)': {
      color: CoherenceTheme.palette.themeDark,
      textDecoration: 'none',
    },
    ':global(a):hover': {
      color: CoherenceTheme.palette.themeDarker,
    },
  },
});

ReactDOM.render(<AppLoader />, document.getElementById('app'));

if (module.hot) {
  module.hot.accept();
}
