import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { TextField } from '@fluentui/react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_ANNUAL_GENERAL_MEETING } from '../../../utils/statApi/AnnualFilingRequirementsApi';
import ActionButtons from '../../common/ActionButtons';
import CanAccess from '../../common/canAccess/CanAccess';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IMonthDay } from '../../common/formFields/monthDayPicker/IMonthDay';
import MonthDayPicker from '../../common/formFields/monthDayPicker/MonthDayPicker';
import { ICompanyCodeParams } from '../../common/ParamTypes';

interface IAnnualGeneralMeetingEditPanelProps {
  dueDateMonth: number;
  dueDateDay: number;
  information: string;
  closePanel: () => void;
}

interface IEditAGMFormData extends IMonthDay {
  information: string;
}

const AnnualGeneralMeetingEditPanel = ({
  dueDateMonth,
  dueDateDay,
  information,
  closePanel,
}: IAnnualGeneralMeetingEditPanelProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const annualGeneralMeetingForm = useForm();
  const { handleSubmit, setValue, control } = annualGeneralMeetingForm;

  const [updateAnnualGeneralMeeting, { loading, error }] = useMutation(
    UPDATE_ANNUAL_GENERAL_MEETING,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleOnSaveClick = (formData: IEditAGMFormData): void => {
    const dto = {
      dueDateDay: formData.dueDateDay,
      dueDateMonth: formData.dueDateMonth,
      information: formData.information,
    };

    updateAnnualGeneralMeeting({
      variables: {
        companyCode,
        agmUpdateDto: dto,
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Annual General Meeting"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Annual General Meeting Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="CelaAgmStatus:Update">
          <ActionButtons
            mutationLoading={loading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveLabel="Save Annual General Meeting"
            saveTitle="Save Annual General Meeting"
            cancelLabel="Cancel Annual General Meeting Edit"
            cancelTitle="Cancel Annual General Meeting Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="Saving the Annual General Meeting"
        />
        <MonthDayPicker
          dueDateDay={dueDateDay}
          dueDateMonth={dueDateMonth}
          form={annualGeneralMeetingForm}
          dayFormName="dueDateDay"
          monthFormName="dueDateMonth"
          label="Due Date"
          required
        />
        <Controller
          render={() => {
            return (
              <TextField
                label="AGM Information"
                title="AGM Information"
                defaultValue={information}
                onChange={(e, v) => {
                  setValue('information', v);
                }}
                multiline
                resizable={false}
              />
            );
          }}
          name="information"
          control={control}
        />
      </form>
    </CoherencePanel>
  );
};

export default AnnualGeneralMeetingEditPanel;
