import { gql } from '@apollo/client';

export const GET_ROLES_QUERY = gql`
  {
    role {
      id
      name
    }
  }
`;

export const GET_ROLE_ACTIONS_QUERY = gql`
  query roleActions {
    roleActions {
      id
      role {
        id
        name
        isGlobal
        showInSecurityRolesList
        description
      }
      actions {
        id
        entityName
        entityAction
        displayName
        category
        description
      }
    }
  }
`;

export const GET_ACTIONS_QUERY = gql`
  query actions {
    actions {
      id
      entityName
      entityAction
      displayName
      category
      description
      roles {
        id
        name
        isGlobal
        showInSecurityRolesList
        description
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($roleAction: RoleActionDtoInput!) {
    createRole(roleAction: $roleAction) {
      id
      role {
        id
        name
        isGlobal
        showInSecurityRolesList
      }
      actions {
        id
        entityName
        entityAction
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($roleAction: RoleActionDtoInput!) {
    updateRole(roleAction: $roleAction) {
      id
      role {
        id
        name
        isGlobal
        showInSecurityRolesList
      }
      actions {
        id
        entityName
        entityAction
      }
    }
  }
`;

export interface Role {
  id: number;
  name: string;
  isGlobal?: boolean;
  showInSecurityRolesList?: boolean;
  description?: string;
}

export interface Action {
  id: number;
  entityName: string;
  entityAction: string;
  displayName?: string;
  category?: string;
  description?: string;
  roles?: Role[];
}

export interface RoleActions {
  id: number; // This id allows apollo to avoid cache collisions, it maps to the role id
  role: Role;
  actions: Action[];
}

export default GET_ROLES_QUERY;
