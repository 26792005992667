import React from 'react';
import { Stack, StackItem } from '@fluentui/react';
import { commandBarWrapper } from '../../users/UsersStyles';
import { bodyContent } from './Headers.styles';

interface SubheaderWithCommandBarProps {
  title: () => JSX.Element;
  children?: JSX.Element;
}
const BodyHeaderWithCommandBar: React.FunctionComponent<SubheaderWithCommandBarProps> = (
  props: SubheaderWithCommandBarProps,
) => {
  const { title, children } = props;

  const Styles = bodyContent();
  return (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" wrap>
      <Stack horizontal verticalAlign="center" className={Styles.bodyContentTitle}>
        {title()}
      </Stack>
      <Stack grow horizontal verticalAlign="center" horizontalAlign="end">
        <StackItem className={commandBarWrapper}>{children}</StackItem>
      </Stack>
    </Stack>
  );
};
export default BodyHeaderWithCommandBar;
