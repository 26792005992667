import React from 'react';
import { Icon, Link, Separator, Stack, Text } from '@fluentui/react';

const helpSectionHeaderStyles = {
  root: { fontWeight: '600', paddingBottom: 10 },
};
interface IHelpSectionLink {
  href: string;
  name: string;
}
const resourcesConfig = [
  {
    href: 'https://microsoft.sharepoint.com/:p:/t/Stat/EVTP_k3jvhRKsDYAw3Zn80gBl_yEqEm8vITBPjwwCqzmeg?e=5t2SHB',
    name: 'STAT Introduction',
  },
  {
    href: 'https://aka.ms/stat/faq',
    name: 'FAQs',
  },
  {
    href: 'https://aka.ms/stat/videos',
    name: 'Training Videos',
  },

  {
    href: 'https://aka.ms/stat/resources',
    name: 'STAT Resources Sharepoint',
  },
  {
    href: 'https://aka.ms/stat/policy',
    name: 'Statutory Compliance Policy',
  },
  {
    href: 'http://go.microsoft.com/fwlink/?LinkId=518021',
    name: 'Microsoft Data Privacy Notice',
  },
] as IHelpSectionLink[];

const getExternalLink = (link: IHelpSectionLink) => {
  return (
    <Link key={link.name} target="_blank" rel="noopener noreferrer" href={link.href}>
      {link.name} <Icon iconName="NavigateExternalInline" />
    </Link>
  );
};
const HelpPanel = (): JSX.Element => {
  return (
    <Stack>
      <Text variant="mediumPlus" styles={helpSectionHeaderStyles}>
        Resources
      </Text>
      <p>Use these videos and documents to learn about STAT:</p>

      <Stack tokens={{ childrenGap: 5 }}>
        {resourcesConfig.map((link) => {
          return getExternalLink(link);
        })}
      </Stack>
      <Separator styles={{ root: { padding: '20px 0' } }} />
      <Text variant="mediumPlus" styles={helpSectionHeaderStyles}>
        Help/Support
      </Text>
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack>
          {getExternalLink({ name: 'Contact FinSup', href: 'https://aka.ms/FinSup' })}

          <p>
            General inquiries, access, error messages, process configuration, standard reporting,
            navigation, document upload.
          </p>
        </Stack>
        <Stack>
          {getExternalLink({
            name: 'Contact STATInfo',
            href: 'mailto:statinfo@microsoft.com?subject=STAT%20Question',
          })}
          <p>
            Business process questions, policy requirements (WHICH document is needed), policy
            exceptions, financial statement reviews, dividend coordination, new company creation,
            late audit status update.
          </p>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HelpPanel;
