import { INavLinkGroup, Nav, NeutralColors, Stack } from '@fluentui/react';
import React from 'react';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../../app/common/constants/SiteConstants';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';

interface IMainDetailsViewProps {
  navLinkGroups: INavLinkGroup[];
  children: JSX.Element;
  navSectionHeader?: JSX.Element;
  commandBarSection?: JSX.Element;
}
const MainDetailsView = ({
  navLinkGroups,
  children,
  navSectionHeader,
  commandBarSection,
}: IMainDetailsViewProps): JSX.Element => {
  const mainSectionStyles = {
    root: {
      width: 320,
      minWidth: 320,
      background: NeutralColors.gray10,
    },
  };
  const masterDetailNavStyles = {
    linkText: {
      color: NeutralColors.black,
    },
  };
  const detailsSectionStyles = {
    root: {
      background: NeutralColors.white,
    },
  };
  const childrenWrapperStyles = {
    root: { width: '100%', padding: 30 },
  };
  const mobileViewStyles = {
    root: {
      selectors: {
        [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
          flexFlow: 'column',
        },
      },
    },
  };

  return (
    <div className={`${bodyContentContainer}  ms-depth-4`}>
      <Stack horizontal styles={mobileViewStyles}>
        <Stack styles={mainSectionStyles}>
          {navSectionHeader}
          <Nav styles={masterDetailNavStyles} groups={navLinkGroups} />
        </Stack>
        <Stack grow styles={detailsSectionStyles}>
          {commandBarSection}
          <Stack styles={childrenWrapperStyles}>{children}</Stack>
        </Stack>
      </Stack>
    </div>
  );
};
export default MainDetailsView;
