import { useLazyQuery } from '@apollo/client';
import { IPersonaProps } from '@fluentui/react';
import React from 'react';
import searchForUsers from '../../utils/microsoftGraphApi/userSearch';
import { GetUserQuery, StatUser } from '../../utils/statApi/UsersApi';
import ErrorHandlingPeoplePicker from '../common/formFields/ErrorHandlingPeoplePicker';
import { IServiceContact } from '../companies/serviceContacts/interfaces';

interface IDavaDealLeadProps {
  label: string;
  onChange: (value: StatUser) => void;
  errorMessage?: string;
  defaultValue?: StatUser;
  disabled: boolean;
  required: boolean;
}

const DavaDealLeadPicker = ({
  errorMessage,
  onChange,
  label,
  defaultValue,
  disabled,
  required,
}: IDavaDealLeadProps): JSX.Element => {
  const [getStatUser, { data, loading }] = useLazyQuery(GetUserQuery, {
    fetchPolicy: 'no-cache',
  });
  const PICKER_RESOLVE_DELAY = 450;

  React.useEffect(() => {
    if (!loading && data && data.statUser) {
      onChange(data.statUser);
    }
  }, [data]);

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };
  const onUserSearch = (person: IServiceContact): void => {
    if (person) {
      getStatUser({ variables: { graphGUID: person?.graphId } });
    } else {
      onChange(null);
    }
  };

  const getPersona = (user: StatUser): IPersonaProps[] => {
    if (user) {
      return [
        {
          id: user?.id.toString(),
          text: user?.displayName,
        },
      ];
    }
    return [];
  };

  return (
    <div role="form">
      <ErrorHandlingPeoplePicker
        controlName="davaDealLead"
        labelText={label}
        required={required}
        disabled={disabled}
        onChange={(items?: IPersonaProps[]): void => {
          onUserSearch(items[0] as IServiceContact);
        }}
        defaultSelectedItems={getPersona(defaultValue)}
        onResolveSuggestions={onResolveSuggestions}
        itemLimit={1}
        resolveDelay={PICKER_RESOLVE_DELAY}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default DavaDealLeadPicker;
