import { Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import { GetProcessExtensionsWithPaginationQuery } from '../../../utils/statApi/ProcessExtensionApi';
import IProcessExtensionsListItem from '../../../utils/types/IProcessExtensionsListItem';
import WrappedListPage, {
  IPaginatedListPageProps,
} from '../../common/DetailsLists/pagination/PaginatedListPage';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import ListFilter from './ListFilter';
import ProcessExtensionsList from './ProcessExtensionsList';

export interface IProcessExtensionsListPageState {
  statusIds: number[];
  assignments: string[];
  processTypeIds: number[];
}

const ProcessExtensionsListPage = (): JSX.Element => {
  const [filterState, setFilterState] = useState<IProcessExtensionsListPageState>({
    statusIds: [1],
    assignments: ['APPROVER', 'OWNER'],
    processTypeIds: [],
  });

  const renderHeader = (): JSX.Element => <h1>Process Extensions</h1>;

  const wrappedListPageParams: IPaginatedListPageProps<IProcessExtensionsListItem> = {
    dataMapperKey: 'processExtensions',
    actionName: 'loading the process extensions list',
    query: GetProcessExtensionsWithPaginationQuery,
    filters: filterState,
    emptyResultMessage:
      'There are no process extension requests with the selected filters. Try selecting some different options.',
  };
  return (
    <>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="start">
        <FullWidthHeader title={renderHeader} />
        <Stack.Item styles={{ root: { marginRight: 50, marginTop: 30 } }}>
          <ListFilter filterState={filterState} setFilterState={setFilterState} />
        </Stack.Item>
      </Stack>
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        {WrappedListPage(ProcessExtensionsList, wrappedListPageParams)}
      </div>
    </>
  );
};

export default ProcessExtensionsListPage;
