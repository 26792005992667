import { IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import React, { ReactNode } from 'react';
import { MessagingColors } from '../../app/common/styles/CommonStyleObjects';

interface IListItemProps {
  title: string;
  subText: ReactNode;
  sideColor?: string;
  sideCount?: number;
}

const ListItem = ({
  title,
  subText,
  sideColor = MessagingColors.errorBlockIcon,
  sideCount,
}: IListItemProps): JSX.Element => {
  const liStyle = mergeStyles({
    listStyleType: 'none',
    borderLeft: `solid 3px ${sideColor}`,
    paddingLeft: '5px',
    marginBottom: '10px',
  });

  const numberStyle = mergeStyles({
    fontSize: '2em',
    width: '2em',
    textAlign: 'right',
  });

  const horizontalStackTokens: IStackTokens = {
    childrenGap: 10,
  };

  return (
    <li className={liStyle}>
      <Stack horizontal tokens={horizontalStackTokens}>
        {(sideCount || sideCount >= 0) && <div className={numberStyle}>{sideCount}</div>}
        <Stack>
          <div>
            <strong>{title}</strong>
          </div>
          {subText && <div>{subText}</div>}
        </Stack>
      </Stack>
    </li>
  );
};

export default ListItem;
