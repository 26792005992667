import React, { useState } from 'react';
import { ActionButton, Stack } from '@fluentui/react';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import ErrorMessage from '../../../common/errorContent/ErrorMessage';
import { IAgmStatusFormProps } from './IAgmStatusFormProps';
import AgmStatusDropdown from '../../fiscalYears/AgmStatusDropdown';
import SpanWithLabel from '../../../common/SpanWithLabel';
import monthDayFormat from '../../../../utils/MonthDayFormatter';

const AgmStatusForm = ({
  errors,
  agmStatus,
  handleChange,
  handleToggleDueDatePanelOpen,
}: IAgmStatusFormProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState(agmStatus?.celaAgmStatusDate?.toString());
  const [selectedStatus, setSelectedStatus] = useState(agmStatus?.agmStatus);
  const [invalidGateNotification, setInvalidGateNotification] = useState(false);

  const handleSelectedDate = (dateString: string): void => {
    handleChange(dateString, 'celaAgmStatusDate');
    setSelectedDate(dateString);
  };
  const handleSeletedStatus = (entityToSave: IDropdownReference, valueKey: string): void => {
    if (
      selectedStatus &&
      (selectedStatus?.fieldName === 'Completed' || selectedStatus?.fieldName === 'Not Required') &&
      entityToSave?.fieldName !== 'Completed' &&
      entityToSave?.fieldName !== 'Not Required'
    ) {
      setInvalidGateNotification(true);
    } else {
      setInvalidGateNotification(false);
    }

    setSelectedStatus(entityToSave);
    handleChange(entityToSave, valueKey);

    if (entityToSave.fieldName !== 'Completed') {
      handleSelectedDate(null);
    }
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {invalidGateNotification && (
        <ErrorMessage>
          <span>Changing to this AGM Status value will reopen the CELA AGM status gate.</span>
        </ErrorMessage>
      )}
      <AgmStatusDropdown
        label="CELA AGM Status"
        valueKey="agmStatus"
        errors={errors}
        handleChange={handleSeletedStatus}
        value={selectedStatus}
      />
      <UtcDatePicker
        label="CELA AGM Meeting Date"
        allowTextInput
        textField={{
          name: 'celaAgmStatusDate',
          errorMessage: errors?.celaAgmStatusDate?.message,
        }}
        value={selectedDate}
        onSelectDate={handleSelectedDate}
        disabled={selectedStatus?.fieldName !== 'Completed'}
        isRequired={selectedStatus?.fieldName === 'Completed'}
      />
      {/* Readonly fields */}
      <Stack tokens={{ childrenGap: 10 }}>
        <SpanWithLabel
          labelText="Due Date"
          useDefaultLabelStyling
          value={monthDayFormat(agmStatus?.agmDueDateMonth, agmStatus?.agmDueDateDay)}
        />
        <SpanWithLabel
          labelText="AGM Information"
          useDefaultLabelStyling
          value={agmStatus?.agmInformation}
        />
      </Stack>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
        <ActionButton
          iconProps={{ iconName: 'Edit' }}
          allowDisabledFocus
          onClick={(): void => {
            handleToggleDueDatePanelOpen();
          }}
        >
          Edit Due Date & Information
        </ActionButton>
      </div>
    </Stack>
  );
};

export default AgmStatusForm;
