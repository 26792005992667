import {
  Checkbox,
  DefaultButton,
  ITag,
  NeutralColors,
  Stack,
  mergeStyleSets,
  normalize,
} from '@fluentui/react';
import React from 'react';

interface IAgmTrackerStatusCounts {
  statusId: number;
  statusName: string;
  statusIndex: number;
  count: number;
}

const styles = mergeStyleSets({
  itemContent: [
    normalize,
    {
      paddingTop: 5,
      height: 40,
      borderBottom: `1px solid ${NeutralColors.gray30}`,
      overflow: 'hidden',
    },
  ],
  checkLabel: {
    label: {
      whiteSpace: 'nowrap',
    },
  },
  itemNameAndCount: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface ICheckBoxItemProps {
  statuses: IAgmTrackerStatusCounts;
  itemIsSelected: boolean;
  onCheckBoxItemChanged: (statusId: number, checked: boolean) => void;
  colorMapping: (count: number) => string;
}

const CheckBoxItem = (props: ICheckBoxItemProps) => {
  const { statuses, itemIsSelected, onCheckBoxItemChanged, colorMapping } = props;
  return (
    <div data-is-focusable className={styles.itemContent} key={statuses?.statusId}>
      <Stack
        horizontal
        grow
        styles={{
          root: {
            borderRight: `solid 3px ${colorMapping(statuses.count)}`,
          },
        }}
      >
        <Stack.Item grow={3} className={styles.itemNameAndCount}>
          <Checkbox
            label={statuses?.statusName}
            checked={itemIsSelected ?? false}
            onChange={(e, checked: boolean) => onCheckBoxItemChanged(statuses?.statusId, checked)}
            className={styles.checkLabel}
          />
        </Stack.Item>
        <Stack.Item style={{ padding: 5 }}>{statuses?.count}</Stack.Item>
      </Stack>
    </div>
  );
};

interface IAgmTrackerStatusMetricSelectorProps {
  onStatusChange: (items: ITag[], key: string, title: string) => void;
  colorMapping: (count: number) => string;
  statusMetrics: IAgmTrackerStatusCounts[];
  selectedMetrics: ITag[];
}

const AgmTrackerStatusMetricSelector = (props: IAgmTrackerStatusMetricSelectorProps) => {
  const { onStatusChange, colorMapping, statusMetrics, selectedMetrics } = props;

  const onCheckBoxItemChanged = (statusId: number, checked: boolean): void => {
    const item = statusMetrics?.find(
      (status: IAgmTrackerStatusCounts) => status.statusId === statusId,
    );
    if (item && checked) {
      const selectedItemsCopy = structuredClone(selectedMetrics) ?? [];
      selectedItemsCopy.push({ key: item.statusId, name: item.statusName });
      onStatusChange(selectedItemsCopy, 'status', 'Agm Tracker Status');
    } else if (item && !checked) {
      const selectedItemsCopy = structuredClone(selectedMetrics)?.filter(
        (status) => status.key !== item.statusId,
      );
      onStatusChange(selectedItemsCopy, 'status', 'Agm Tracker Status');
    }
  };

  const statuses = statusMetrics?.map((status) => {
    return {
      statusId: status?.statusId,
      statusName: `${status?.statusIndex} - ${status?.statusName}`,
      statusIndex: status?.statusIndex,
      count: status?.count,
    };
  });

  return (
    <>
      {statuses?.map((status) => (
        <CheckBoxItem
          key={status?.statusId}
          statuses={status}
          itemIsSelected={selectedMetrics?.map((x) => x.key).includes(status?.statusId)}
          onCheckBoxItemChanged={onCheckBoxItemChanged}
          colorMapping={colorMapping}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          padding: '16px 0',
        }}
      >
        <DefaultButton
          text="Reset Status Selections"
          onClick={() => onStatusChange([], 'status', '')}
        />
      </div>
    </>
  );
};

export default AgmTrackerStatusMetricSelector;
