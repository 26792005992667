import * as React from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import {
  TagPicker,
  ITag,
  IInputProps,
  IBasePickerSuggestionsProps,
} from '@fluentui/react/lib/Pickers';
import { Label } from '@fluentui/react';
import ICountry from '../../utils/types/ICountry';
import { GET_COUNTRIES_BY_SEARCH_INPUT } from '../../utils/statApi/CountryApi';
import {
  countryPickerStyles,
  pickerErrorMessage,
} from '../../app/common/styles/CommonStyleObjects';
import InlineFormInputErrorMessage from '../common/errorContent/InlineFormInputErrorMessage';
import { convertCountryToTag } from '../../utils/Helpers';

interface ICountryPickerProps {
  label?: JSX.Element;
  itemLimit?: number;
  isDisabled: boolean;
  selectedItems: ITag[];
  errorMessage: string;
  setSelectedItems: (countryConfigCountry: ITag[]) => void;
}

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested Countries',
  noResultsFoundText: 'No countries found',
};

const getTextFromItem = (item: ITag) => item.name;

const CountryPicker = ({
  setSelectedItems,
  selectedItems,
  isDisabled,
  errorMessage,
  label,
  itemLimit,
}: ICountryPickerProps): JSX.Element => {
  const [loadCountries] = useLazyQuery(GET_COUNTRIES_BY_SEARCH_INPUT);
  // - retrieve countries from server based on keyword input
  // - create array of returned countries
  const onResolveSuggestions = async (filterText: string): Promise<ITag[]> => {
    return loadCountries({ variables: { keyword: filterText } }).then(
      (result: QueryResult<{ countries: ICountry[] }, OperationVariables>) => {
        const countriesList: ICountry[] = result?.data?.countries;
        return countriesList?.map((country): ITag => {
          return convertCountryToTag(country);
        });
      },
    );
  };

  const addSelectedItem = (items: ITag[]): void => {
    setSelectedItems(items);
  };

  const labelText = 'Countries';
  const controlName = 'countries';
  const inputProps: IInputProps = {
    title: labelText,
    'aria-label': `${labelText} Country Picker`,
    name: controlName,
    id: `${controlName}Picker`,
    required: true,
    placeholder: 'Search Countries',
  };

  const onItemSelected = (item?: ITag): ITag | PromiseLike<ITag> => {
    const countryConfigCountry: ITag = {
      key: item.key,
      name: item.name,
    };
    return countryConfigCountry;
  };

  return (
    <>
      {label || <Label htmlFor={`${controlName}Picker`}>{labelText}</Label>}
      <TagPicker
        itemLimit={itemLimit ?? undefined}
        resolveDelay={300}
        selectedItems={selectedItems}
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Country"
        onItemSelected={onItemSelected}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={inputProps}
        onChange={addSelectedItem}
        disabled={isDisabled}
        styles={countryPickerStyles}
        onEmptyResolveSuggestions={(): ITag[] => {
          return [];
        }}
      />
      <InlineFormInputErrorMessage
        errorMessage={errorMessage}
        errorWrapperStyle={pickerErrorMessage}
      />
    </>
  );
};
export default CountryPicker;
