import { Dropdown, IDropdownOption } from '@fluentui/react';
import React, { FormEvent } from 'react';

interface IMsReportingSalesYearSelectorProps {
  defaultValue: number;
  onChange: (event: FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void;
}

const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() + 6);
const currentFiscalYear = currentDate.getUTCFullYear();
const options = [
  {
    key: currentFiscalYear,
    text: currentFiscalYear.toString(),
  },
  {
    key: currentFiscalYear + 1,
    text: (currentFiscalYear + 1).toString(),
  },
];

const MsReportingSalesYearSelector = ({
  defaultValue,
  onChange,
}: IMsReportingSalesYearSelectorProps): JSX.Element => {
  return (
    <Dropdown
      defaultSelectedKey={defaultValue}
      label="Sales Location for MS Reporting Year"
      options={options}
      calloutProps={{ calloutMaxHeight: 215 }}
      onChange={onChange}
    />
  );
};

export default MsReportingSalesYearSelector;
