import { gql } from '@apollo/client';

const GET_REASON_FOR_FILING_VIOLATION = gql`
  {
    reasonsForFilingViolation {
      id
      fieldName
      isSelectable
    }
  }
`;
export default GET_REASON_FOR_FILING_VIOLATION;
