import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useForm } from 'react-hook-form';
import {
  ValidationRules,
  ValidationObject,
  ValidationObjects,
  IValidationService,
  ValidationData,
} from './validationTypes';

function camelizeKey(key: string): string {
  if (key.length === 0) {
    return key;
  }

  if (key.length === 1) {
    return key.toLowerCase();
  }

  return `${key.substring(0, 1).toLowerCase()}${key.substring(1)}`;
}

function getValidationValue(value: string, type: string): string | RegExp {
  if (type === 'pattern') {
    return new RegExp(value);
  }
  return value;
}

export default function useValidationFromServer(
  // eslint-disable-next-line @typescript-eslint/ban-types
  formData: object,
  validationQuery: DocumentNode,
  entityType: string,
): IValidationService {
  const { register, trigger, errors, handleSubmit, setValue, reset, formState, unregister } =
    useForm({ defaultValues: { ...formData } });
  const { data, loading, error } = useQuery(validationQuery, { variables: { entityType } });

  const validationObjects: ValidationObjects = {};

  useEffect(() => {
    if (loading || error) {
      return (): void => {};
    }

    const { validationData } = data;
    validationData.forEach((validationRule: ValidationData) => {
      const { formKey, type, message, value } = validationRule;
      const camelizedKey = camelizeKey(formKey);
      const camelizedType = camelizeKey(type);
      if (!validationObjects[camelizedKey]) {
        validationObjects[camelizedKey] = {};
      }
      if (camelizedType === 'required') {
        (validationObjects[camelizedKey] as ValidationObject)[camelizedType] =
          validationRule.message || 'This field is required';
      } else if (camelizedType === 'regularExpression') {
        (validationObjects[camelizedKey] as ValidationObject).pattern = {
          value: getValidationValue(value, camelizedType),
          message,
        } as ValidationRules;
      } else {
        (validationObjects[camelizedKey] as ValidationObject)[camelizedType] = {
          value: getValidationValue(value, camelizedType),
          message,
        } as ValidationRules;
      }
    });

    Object.keys(formData).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      register({ name: key } as any, validationObjects[key]);
    });

    return (): void => {
      Object.keys(formData).forEach((key: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        unregister(key as any);
      });
    };
  }, [loading]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newValue: any, valueKey: string): void => {
    setValue(valueKey, newValue);
    if (formState.isSubmitted) {
      trigger();
    }
  };

  return {
    register,
    trigger,
    errors,
    handleSubmit,
    setValue,
    reset,
    formState,
    handleChange,
  };
}
