import { useQuery } from '@apollo/client';
import {
  CommandBar,
  ICommandBarItemProps,
  ICommandBarStyles,
  INavLink,
  INavLinkGroup,
  NeutralColors,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import hasAccess from '../../utils/authorization/authorizationCheck';
import UserContext from '../../utils/authorization/UserContext';
import { GET_REPORTS, IReport } from '../../utils/powerBiApi/powerBiApi';
import { StatUser } from '../../utils/statApi/UsersApi';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import MainDetailsView from '../common/mainDetail/MainDetailsView';
import { IPowerBIReportIdParams } from '../common/ParamTypes';
import EmbeddedReport from './EmbeddedReport';

const ReportsListPage = (): JSX.Element => {
  const history = useHistory();

  const { reportId } = useParams<IPowerBIReportIdParams>();
  const [currentReportId, setCurrentReportId] = useState<string>();

  const { data, loading, error } = useQuery(GET_REPORTS);
  const reportsList: IReport[] = data?.reports;
  const baseUrl = '/reports-dashboard';
  const hiddenReportNames = ['Stat Contacts Report'] as string[];
  const user: StatUser = React.useContext(UserContext);

  if (!hasAccess(user.permissions, 'Reports:ViewExpediteReports')) {
    hiddenReportNames.push('Expedite Power App');
  }

  useEffect(() => {
    if (reportsList && reportsList.length > 0) {
      let currentReportForUri: IReport = null;
      if (reportId) {
        [currentReportForUri] = reportsList.filter((rpt) => rpt.reportUri === reportId);
      }
      if (!reportId || !currentReportForUri) {
        history.replace(`${baseUrl}/${reportsList[0].reportUri}`);
        setCurrentReportId(reportsList[0].id);
      } else {
        setCurrentReportId(currentReportForUri.id);
      }
    }
  }, [reportsList, reportId]);

  const navCommandBarItems: ICommandBarItemProps[] = [];

  const navCommandBarStyles: ICommandBarStyles = {
    root: {
      padding: 0,
      background: NeutralColors.gray10,
    },
  };

  const getNavLinkGroups = (): INavLinkGroup[] => {
    const processTemplateNavLinks = reportsList
      ?.filter((report) => !hiddenReportNames.includes(report.name))
      .map((report: { id: string; name: string; reportUri: string }, index: number) => {
        return {
          name: report.name,
          ariaLabel: report.name,
          url: report.reportUri,
          onClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink): void => {
            ev.preventDefault();
            ev.stopPropagation();
            setCurrentReportId(report.id);
            history.push(`${baseUrl}/${report.reportUri}`);
          },
        } as INavLink;
      });

    return [{ links: processTemplateNavLinks }];
  };
  const getHeaderTitle = (): JSX.Element => {
    return <h1>Reports</h1>;
  };
  const getNavSectionHeader = (): JSX.Element => {
    return (
      <>
        {navCommandBarItems.length > 0 && (
          <CommandBar
            styles={navCommandBarStyles}
            items={navCommandBarItems}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
        )}
      </>
    );
  };

  return (
    <>
      <FullWidthHeader title={getHeaderTitle} />
      <LoadingErrorMessage error={error} loading={loading} />
      <MainDetailsView navLinkGroups={getNavLinkGroups()} navSectionHeader={getNavSectionHeader()}>
        {currentReportId && <EmbeddedReport reportId={currentReportId} />}
      </MainDetailsView>
    </>
  );
};

export default ReportsListPage;
