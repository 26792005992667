import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';
import GET_COMPANY_CREATION_REQUESTS, {
  CREATE_NEW_SHADOW_COMPANY,
} from '../../utils/statApi/CompanyCreationApi';
import ActionButtons from '../common/ActionButtons';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { SHADOW_COMPANY_NAME_SUFFIX } from './details/types';
import NewShadowCompanyForm from './NewShadowCompanyForm';
import { CompanyCreationStatus, IShadowCompanyCreationRequest } from './types';

interface INewShadowCompanyPanelProps {
  closePanel: () => void;
}

const NewShadowCompanyPanel = ({ closePanel }: INewShadowCompanyPanelProps): JSX.Element => {
  const shadowCompanyRequestForm = useForm();
  const { handleSubmit } = shadowCompanyRequestForm;

  const [createShadowCompanyRequest, { loading, error }] = useMutation(CREATE_NEW_SHADOW_COMPANY, {
    refetchQueries: [
      {
        query: GET_COMPANY_CREATION_REQUESTS,
        variables: {
          statusIds: [
            CompanyCreationStatus.RequestStarted,
            CompanyCreationStatus.CompanyCodeRequested,
            CompanyCreationStatus.PendingSubledgerInfo,
            CompanyCreationStatus.PendingStatToolSetup,
          ],
          requestorIds: [],
          acquisitionIds: [],
        },
      },
    ],
    onCompleted: () => {
      closePanel();
    },
  });

  const handleSaveClick = (formData: IShadowCompanyCreationRequest): void => {
    createShadowCompanyRequest({
      variables: {
        shadowCompanyRequestInput: {
          parentCompanyCode: formData.parentCompanyCode,
          shadowCompanyName:
            formData.shadowCompanyName.trim().replace(/\s\s+/g, ' ') + SHADOW_COMPANY_NAME_SUFFIX,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="New Shadow Company Request"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Company Request Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Create New Shadow Company Request"
          saveTitle="Create New Shadow Company Request"
          cancelLabel="Cancl New Shadow Company Request"
          cancelTitle="Cancel New Shadow Company Request"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <NewShadowCompanyForm form={shadowCompanyRequestForm} />
      </form>
    </CoherencePanel>
  );
};

export default NewShadowCompanyPanel;
