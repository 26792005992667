import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import ActionButtons from '../common/ActionButtons';
import CanAccess from '../common/canAccess/CanAccess';
import ComplianceRequirementForm from './ComplianceRequirementForm';
import {
  IComplianceRequirement,
  ICountryConfiguration,
  ICountryConfigurationQueryResult,
  ITaxRate,
} from './CountryConfiguration.config';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import {
  CREATE_COMPLIANCE_REQUIREMENT,
  GET_COUNTRY_CONFIG_DETAILS,
  UPDATE_COMPLIANCE_REQUIREMENT,
} from '../../utils/statApi/CountryApi';
import { ICountryIdParams } from '../common/ParamTypes';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';

interface ComplianceRequirementEditPanelProps {
  complianceRequirement: IComplianceRequirement;
  closePanel: () => void;
}

const ComplianceRequirementEditPanel = ({
  complianceRequirement,
  closePanel,
}: ComplianceRequirementEditPanelProps): JSX.Element => {
  const { countryId } = useParams<ICountryIdParams>();
  const complianceRequirementForm = useForm({
    defaultValues: {
      taxRates: complianceRequirement?.taxRates || [],
    },
  });
  const { errors, handleSubmit } = complianceRequirementForm;

  const [createComplianceRequirement, { loading: createLoading, error: createError }] = useMutation(
    CREATE_COMPLIANCE_REQUIREMENT,
    {
      update(cache, { data }) {
        const newComplianceRequirement = data?.createComplianceRequirement;

        const queryData = cache.readQuery<ICountryConfigurationQueryResult>({
          query: GET_COUNTRY_CONFIG_DETAILS,
          variables: { countryConfigId: parseInt(countryId, 10) },
        });

        const countryData: ICountryConfiguration = queryData?.countryConfigurationDetails;
        newComplianceRequirement.countryConfiguration = countryData;

        const allComplianceRequirements = [
          ...countryData.complianceRequirements,
          newComplianceRequirement,
        ];

        cache.writeQuery({
          query: GET_COUNTRY_CONFIG_DETAILS,
          variables: { countryConfigId: parseInt(countryId, 10) },
          data: {
            countryConfigurationDetails: {
              ...countryData,
              complianceRequirements: allComplianceRequirements,
            },
          },
        });
      },
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const [updateComplianceRequirement, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_COMPLIANCE_REQUIREMENT,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: IComplianceRequirement): void => {
    const id = parseInt(formData.id.toString(), 10) || -1;

    const isUpdate = id > 0;

    const taxRates: ITaxRate[] = formData.taxRates
      ? formData.taxRates.map((tr) => {
          return {
            id: parseInt(tr.id.toString(), 10),
            taxDescription: tr.taxDescription,
            rate: tr.rate,
            effectiveDate: tr.effectiveDate,
            taxType: {
              id: tr.taxType.id,
              fieldName: tr.taxType.fieldName,
            },
          };
        })
      : [];

    const saveData: IComplianceRequirement = {
      id,
      name: formData.name,
      comments: formData.comments,
      filingDueDateDay: formData.filingDueDateDay,
      filingDueDateMonth: formData.filingDueDateMonth,
      countryConfigurationId: parseInt(countryId, 10),
      filingFrequency: {
        id: formData.filingFrequency.id,
        fieldName: formData.filingFrequency.fieldName,
      },
      filingPreparationCompany: {
        id: formData.filingPreparationCompany.id,
        fieldName: formData.filingPreparationCompany.fieldName,
      },
      filingType: {
        id: formData.filingType.id,
        fieldName: formData.filingType.fieldName,
      },
      taxRates,
    };

    if (!Object.keys(errors).length) {
      if (isUpdate) {
        updateComplianceRequirement({
          variables: {
            complianceRequirement: saveData,
          },
        });
      } else {
        createComplianceRequirement({
          variables: {
            complianceRequirement: saveData,
          },
        });
      }
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Compliance Requirement"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      closeButtonAriaLabel="Close Compliance Requirement Panel"
      styles={CoherencePanelStyles}
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="CountryConfiguration:AddEdit">
          <ActionButtons
            mutationLoading={false}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleSaveClick)}
            saveTitle="Save Compliance Requirement"
            saveLabel="Save Compliance Requirement"
            cancelTitle="Cancel Compliance Requirement Edit"
            cancelLabel="Cancel Compliance Requirement Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <LoadingErrorMessage
          loading={createLoading}
          error={createError}
          actionName="creating the compliance requirement"
        />
        <LoadingErrorMessage
          loading={updateLoading}
          error={updateError}
          actionName="updating the compliance requirement"
        />

        <ComplianceRequirementForm
          complianceRequirement={complianceRequirement}
          form={complianceRequirementForm}
        />
      </form>
    </CoherencePanel>
  );
};

export default ComplianceRequirementEditPanel;
