import { useQuery } from '@apollo/client';
import { mergeStyles } from '@fluentui/react';
import { Embed } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { TokenType, BackgroundType } from 'powerbi-models';
import React from 'react';
import { bodyContentContainer } from '../../app/common/styles/CommonStyleObjects';
import { GET_REPORT_STAT_CONTACTS, IEmbeddedReportData } from '../../utils/powerBiApi/powerBiApi';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import FullWidthHeader from '../common/headers/FullWidthHeader';

const StatContactsNoAuthPage = (): JSX.Element => {
  const { data, loading, error } = useQuery(GET_REPORT_STAT_CONTACTS);
  const reportData: IEmbeddedReportData = data?.reportStatContacts;
  const reportStyle = mergeStyles({
    height: '720px',
    selectors: {
      iframe: {
        border: 'none',
      },
    },
  });
  const getHeaderTitle = (): JSX.Element => {
    return <h1>Stat Contacts Report</h1>;
  };
  return (
    <>
      <FullWidthHeader title={getHeaderTitle} />
      <div className={bodyContentContainer}>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="loading the report from Power Bi"
        />

        {!loading && !error && (
          <PowerBIEmbed
            embedConfig={{
              id: reportData?.id,
              embedUrl: reportData?.embedUrl,
              accessToken: reportData?.token,
              type: 'report',
              tokenType: TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: true,
                  },
                },
                background: BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                ['loaded', () => {}],
                ['rendered', () => {}],
                ['error', () => {}],
              ])
            }
            cssClassName={reportStyle}
            getEmbeddedComponent={(embedObject: Embed) => {
              embedObject.setComponentTitle(reportData?.name);
            }}
          />
        )}
      </div>
    </>
  );
};

export default StatContactsNoAuthPage;
