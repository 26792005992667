import { IDropdownOption } from '@fluentui/react';
import { StatUser } from '../../../utils/statApi/UsersApi';
import { IAgmReviewContact } from '../../../utils/types/IAgmReview';

export interface IAgmListPageSearchState {
  countryCodes: string[];
  agmProcessingTimeAllowed: string;
  celaAging: string;
  statControllerIds: number[];
  reportingPeriodEndFromDate: Date;
  reportingPeriodEndToDate: Date;
  filingDueDateFromDate: Date;
  filingDueDateToDate: Date;
  selectedReportingPeriodEndDateOption: string;
  selectedFilingDueDateOption: string;
  regionalControllerSignoffMilestoneStatuses: string[];
  sortedFieldName: string;
  isSortedDescending: string;
}

export interface IAgmListPageSearchResult {
  processId: number;
  processName: string;
  companyCode: string;
  companyName: string;
  regionalControllerSignoffDate: string;
  agmReadinessMilestoneSignoffDate: string;
  agmDueDateMonth: number;
  agmDueDateDay: number;
  reportingPeriodEndDate: string;
  filingDueDate: string;
  statController: string;
  wwArea: string;
  country: string;
  agmProcessingTimeAllowed: number;
  celaAging: number;
}

export enum AgmTrackerItemActionType {
  CelaAssigneeChange = 'CELAASSIGNEECHANGE',
  StatusChange = 'STATUSCHANGE',
  Comment = 'COMMENT',
  OwnerChange = 'OWNERCHANGE',
  OwnerChangeBySystem = 'OWNERCHANGEBYSYSTEM',
}

export interface IAgmTrackerAction {
  id: number;
  actionDate: Date;
  actionValue: string;
  changedBy?: StatUser;
  actionType: string;
  comments: string;
}

export interface IAgmTrackerItemInput {
  owner: IDropdownOption;
  celaAssignees: IAgmReviewContact[];
  status: IAgmTrackerStatus;
  agmTrackerActions: IAgmTrackerAction[];
  reasonForChange: string;
  comment: string;
  agmDate: string;
}

export interface IAgmTrackerStatus {
  id: number;
  fieldName: string;
  trackerIndex: number;
  message?: string;
}

export interface IAgmTrackerPageTrackerSearchResult {
  trackerId: number;
  processId: number;
  reviewId: number;
  agmTrackerActions: IAgmTrackerAction[];
  companyCode: string;
  companyName: string;
  reportingPeriodEndDate: string;
  filingDueDate: string;
  regionalControllerSignoffDate: string;
  agmDueDate: string;
  agmReviewId: number;
  celaAssignees: IAgmReviewContact[];
  status: IAgmTrackerStatus;
  latestStatusChangeAction: IAgmTrackerAction;
  statController: string;
  aging: number;
  totalAging: number;
  wwArea: string;
  country: string;
  owner: string;
  latestOwnerChangeAction: IAgmTrackerAction;
  latestCommentAction: IAgmTrackerAction;
  agmDate: Date;
  fiscalYearId: number;
}
