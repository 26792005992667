import { TooltipHost, IconButton } from '@fluentui/react';
import React from 'react';

interface IMessageWithTooltipProps {
  label: string;
  tooltipMessage: string;
}
const MessageWithTooltip = (props: IMessageWithTooltipProps): JSX.Element => {
  const { label, tooltipMessage } = props;
  return (
    <TooltipHost content={tooltipMessage} id={label}>
      {label}
      <IconButton
        aria-describedby={label}
        ariaLabel={tooltipMessage}
        iconProps={{ iconName: 'Info' }}
      />
    </TooltipHost>
  );
};
export default MessageWithTooltip;
