import { useQuery } from '@apollo/client';
import { ChoiceGroup, IComboBoxOption, IDropdownOption, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, FieldValues, UseFormMethods, useWatch } from 'react-hook-form';
import GET_ENTITY_TYPES_QUERY from '../../utils/statApi/EntityTypeApi';
import GET_COMPANY_CREATION_FINANCIAL_TYPES_QUERY from '../../utils/statApi/FinancialTypeApi';
import { StatUser } from '../../utils/statApi/UsersApi';
import IDropdownReference from '../../utils/types/IDropdownReference';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import InlineFormInputErrorMessage from '../common/errorContent/InlineFormInputErrorMessage';
import QueryBasedDropdown from '../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import UtcDatePicker from '../common/utcDatePicker/UtcDatePicker';
import AcquisitionSelector from './AcquisitionSelector';
import CompanyCodeInput from './CompanyCodeInput';
import DavaDealLeadPicker from './DavaDealLeadPicker';
import { FinancialType, ICompanyCreationRequestDetails } from './types';
import { yesNoOptions } from '../../utils/types/CommonDropdownOptions';

interface INewCompanyFormProps {
  form: UseFormMethods<FieldValues>;
  companyCreationRequest?: ICompanyCreationRequestDetails;
}

const NewCompanyForm = ({ form, companyCreationRequest }: INewCompanyFormProps): JSX.Element => {
  const { control, errors: formErrors, setValue, watch, getValues } = form;

  const onAcquistionChange = (value: IComboBoxOption): void => {
    setValue('acquisition', value);
  };

  const onEntityTypeChange = (value: IDropdownOption): void => {
    setValue('entityType', value);
  };

  const onFinancialTypeChange = (value: IDropdownOption): void => {
    setValue('financialType', value);
  };

  const onDavaDealLeadChange = (statUser: StatUser): void => {
    setValue('davaDealLead', statUser);
  };

  const watchIsAcquistion = watch(
    'isAcquisition',
    companyCreationRequest?.isAcquisition.toString().toLowerCase(),
  );

  const watchFinancialType = useWatch({
    control,
    name: 'financialType',
    defaultValue: undefined,
  });

  const validateAquisition = (): string => {
    if (!getValues('acquisition')?.text?.trim() && watchIsAcquistion === 'true') {
      return 'Please enter or select an acquisition';
    }
    return null;
  };
  const validateDavaDealLead = (): string => {
    if (!getValues('davaDealLead') && watchIsAcquistion === 'true') {
      return 'DAVA Deal Lead must be a current Stat user';
    }
    return null;
  };

  const EntityTypeDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors: etErrors, handleChange, valueKey, outputKey } = props;
    const { loading, data } = useQuery(GET_ENTITY_TYPES_QUERY);
    const { entityTypes } = !loading && data ? data : [];
    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        outputKey={outputKey}
        label={label}
        required={required}
        errors={etErrors}
        dropdownOptionsData={entityTypes}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  const FinancialTypeDropdown = (props: IFieldsetProps): JSX.Element => {
    const {
      value,
      label,
      required,
      disabled,
      errors: ftErrors,
      handleChange,
      valueKey,
      outputKey,
    } = props;
    const { loading, data } = useQuery(GET_COMPANY_CREATION_FINANCIAL_TYPES_QUERY);
    const { companyCreationFinancialTypes } = !loading && data ? data : [];
    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        outputKey={outputKey}
        label={label}
        required={required}
        disabled={disabled}
        errors={ftErrors}
        dropdownOptionsData={companyCreationFinancialTypes}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        as={TextField}
        id="companyName"
        name="companyName"
        label="Name"
        control={control}
        defaultValue={companyCreationRequest?.companyName}
        required
        rules={{
          required: 'Name is required',
          maxLength: {
            value: 60,
            message: 'Name can only be a max of 60 characters',
          },
        }}
        errorMessage={formErrors?.companyName?.message}
      />
      <Controller
        name="financialType"
        label="Financial Type"
        valueKey="financialType"
        defaultValue={companyCreationRequest?.financialType}
        control={control}
        render={(props) => {
          return (
            <FinancialTypeDropdown
              label="Financial Type"
              valueKey="financialType"
              required
              disabled={!!companyCreationRequest?.id}
              errors={formErrors}
              handleChange={onFinancialTypeChange}
              // eslint-disable-next-line react/prop-types
              value={props.value}
            />
          );
        }}
        rules={{
          required: 'Please select a financial type',
        }}
      />

      {watchFinancialType?.id === FinancialType.NonTransactional && (
        <CompanyCodeInput
          defaultValue={companyCreationRequest?.companyCode}
          form={form}
          codeLength={7}
          fieldName="companyCode"
          label="Company Code"
        />
      )}

      <Controller
        name="isAcquisition"
        defaultValue={companyCreationRequest?.isAcquisition.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="isAcquisition"
                label="Is Associated With Acquisition"
                options={yesNoOptions}
                defaultSelectedKey={companyCreationRequest?.isAcquisition.toString().toLowerCase()}
                required
                onChange={(e, newValue): void => {
                  setValue('isAcquisition', newValue.key);
                }}
              />
              <InlineFormInputErrorMessage errorMessage={formErrors?.isAcquisition?.message} />
            </>
          );
        }}
        rules={{
          required: 'Please select yes or no',
        }}
      />
      <Controller
        name="acquisition"
        defaultValue={companyCreationRequest?.acquisition}
        control={control}
        render={() => {
          return (
            <AcquisitionSelector
              label="Acquistion Name"
              disabled={watchIsAcquistion !== 'true'}
              onChange={onAcquistionChange}
              required={watchIsAcquistion === 'true'}
              errorMessage={formErrors?.acquisition?.message}
              defaultValue={companyCreationRequest?.acquisition}
            />
          );
        }}
        rules={{
          validate: validateAquisition,
        }}
      />
      <Controller
        name="davaDealLead"
        valueKey="davaDealLead"
        control={control}
        defaultValue={
          companyCreationRequest?.davaDealLead?.displayName
            ? companyCreationRequest?.davaDealLead
            : null
        }
        render={() => {
          return (
            <DavaDealLeadPicker
              label="DAVA Deal Lead"
              onChange={onDavaDealLeadChange}
              errorMessage={formErrors?.davaDealLead?.message}
              defaultValue={
                companyCreationRequest?.davaDealLead?.displayName
                  ? companyCreationRequest?.davaDealLead
                  : null
              }
              disabled={watchIsAcquistion !== 'true'}
              required={watchIsAcquistion === 'true'}
            />
          );
        }}
        rules={{
          validate: validateDavaDealLead,
        }}
      />
      <Controller
        name="dateOfAcquisition"
        control={control}
        defaultValue={companyCreationRequest?.dateOfAcquisition}
        required={watchIsAcquistion === 'true'}
        render={({ value }): React.ReactElement => (
          <div role="form">
            <UtcDatePicker
              label="Date of Acquisition"
              isRequired={watchIsAcquistion === 'true'}
              disabled={watchIsAcquistion !== 'true'}
              allowTextInput
              disableAutoFocus={false}
              value={value}
              onSelectDate={(utcDateString: string): void => {
                setValue('dateOfAcquisition', utcDateString);
              }}
              isRequiredErrorMessage="Please select an acquisition date"
            />
          </div>
        )}
      />
      <Controller
        name="isConfidential"
        defaultValue={companyCreationRequest?.isConfidential.toString().toLowerCase()}
        control={control}
        required
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="isConfidential"
                label="Is Confidential"
                name="isConfidential"
                defaultSelectedKey={companyCreationRequest?.isConfidential.toString().toLowerCase()}
                options={yesNoOptions}
                required
                onChange={(e, newValue): void => {
                  setValue('isConfidential', newValue.key);
                }}
              />
              <InlineFormInputErrorMessage errorMessage={formErrors?.isConfidential?.message} />
            </>
          );
        }}
        rules={{
          required: 'Please select yes or no',
        }}
      />
      <Controller
        name="entityType"
        label="Entity Type"
        valueKey="entityType"
        control={control}
        defaultValue={companyCreationRequest?.entityType}
        render={(props) => {
          return (
            <EntityTypeDropdown
              label="Entity Type"
              valueKey="entityType"
              required
              errors={formErrors}
              handleChange={onEntityTypeChange}
              // eslint-disable-next-line react/prop-types
              value={props.value}
            />
          );
        }}
        rules={{
          required: 'Please select an entity type',
        }}
      />
      <Controller
        as={TextField}
        id="businessNatureOfEntity"
        name="businessNatureOfEntity"
        label="Business Nature of Entity"
        control={control}
        defaultValue={companyCreationRequest?.businessNatureOfEntity}
        multiline
        required
        rules={{
          required: 'Business Nature of Entity is required',
        }}
        errorMessage={formErrors?.businessNatureOfEntity?.message}
      />
    </Stack>
  );
};

export default NewCompanyForm;
