import React from 'react';
import { Stack } from '@fluentui/react';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import RecentCompaniesCard from './RecentCompaniesCard';
import RecentProcessesCard from './RecentProcessesCard';
import { bodyContentContainer } from '../../app/common/styles/CommonStyleObjects';
import OverdueMilestonesCard from './OverdueMilestonesCard';
import ComingDueMilestonesCard from './ComingDueMilestonesCard';
import OpenProcessesCard from './OpenProcessesCard';

const DashboardPage: React.FunctionComponent = (): JSX.Element => {
  const renderHeader = (): JSX.Element => <h1>Home</h1>;
  return (
    <>
      <FullWidthHeader title={renderHeader} />

      <div className={`${bodyContentContainer} `}>
        <Stack horizontal wrap tokens={{ childrenGap: 30 }}>
          <OverdueMilestonesCard />
          <ComingDueMilestonesCard />
          <OpenProcessesCard />
          <RecentCompaniesCard />
          <RecentProcessesCard />
        </Stack>
      </div>
    </>
  );
};

export default DashboardPage;
