import { gql } from '@apollo/client';
import { ValidationData } from '../validation/validationTypes';
import IUserRoles from '../types/IUserRoles';

export enum GroupIdentifier {
  COMPANY = 'Company',
  COMPANYGROUP = 'Company Group',
  GLOBAL = 'Global',
}

const USER_FRAGMENT = gql`
  fragment StatUserFragment on StatUserDto {
    id
    graphGUID
    displayName
    emailAddress
    firstName
    lastName
    roleName
    roleId
    groupIdentifiers
    alias
    isActive
    lastLoginDate
    userRoles {
      id
      companyId
      companyCode
      groupIdentifier
      roleName
      roleId
      serviceContactName
    }
  }
`;

export const GetUsersWithPaginationQuery = gql`
  query StatUsers(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $alias: String
    $keyword: String
    $isInactive: Boolean
    $isInvalid: Boolean
  ) {
    statUsers(
      first: $first
      after: $after
      last: $last
      before: $before
      alias: $alias
      userSearchInput: $keyword
      isInactive: $isInactive
      isInvalid: $isInvalid
    ) {
      edges {
        node {
          id
          graphGUID
          displayName
          emailAddress
          firstName
          lastName
          roleName
          roleId
          groupIdentifiers
          alias
          isActive
          lastLoginDate
          personnelNumber
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const GetUserQuery = gql`
  query StatUser($graphGUID: String!) {
    statUser(graphGUID: $graphGUID) {
      ...StatUserFragment
      permissions
    }
  }
  ${USER_FRAGMENT}
`;
export const GetStatUsersByIds = gql`
  query StatUsersByIds($statUserIds: [Int!]) {
    statUsersByIds(statUserIds: $statUserIds) {
      id
      graphGUID
      displayName
      emailAddress
      firstName
      lastName
      roleName
      roleId
      alias
      isActive
      lastLoginDate
    }
  }
`;
export const CreateUserMutation = gql`
  mutation ($statUser: StatUserDtoInput!) {
    createStatUser(statUser: $statUser) {
      ...StatUserFragment
    }
  }
  ${USER_FRAGMENT}
`;
export const EditUserMutation = gql`
  mutation ($statUser: StatUserDtoInput!) {
    updateStatUser(statUser: $statUser) {
      ...StatUserFragment
    }
  }
  ${USER_FRAGMENT}
`;
export const DeleteUserMutation = gql`
  mutation ($graphGUID: Uuid!) {
    deleteStatUser(graphGUID: $graphGUID) {
      ...StatUserFragment
    }
  }
  ${USER_FRAGMENT}
`;
export const UpdateUserLastLoginMutation = gql`
  mutation {
    updateLastLoginDate {
      ...StatUserFragment
    }
  }
  ${USER_FRAGMENT}
`;
export interface StatUser {
  [key: string]: number | string | boolean | Array<ValidationData> | Array<IUserRoles>;
  id?: number;
  graphGUID: string;
  displayName: string;
  alias: string;
  emailAddress: string;
  roleName?: string;
  roleId: number;
  groupIdentifiers?: string;
  // eslint-disable-next-line
  permissions?: any;
  firstName: string;
  lastName: string;
  validationData?: Array<ValidationData>;
  isActive: boolean;
  userRoles?: Array<IUserRoles>;
  lastLoginDate?: string;
  personnelNumber?: string;
}
