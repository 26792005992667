import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ActionButton, FontIcon } from '@fluentui/react';
import { IMilestoneRule } from '../Interfaces';
import { warningClass, iconClass, completeClass } from './Gate.Styles';
import ProcessContext from '../ProcessContext';
import { ICompanyCodeParams } from '../../../common/ParamTypes';

interface IDocumentGateProps {
  rule: IMilestoneRule;
  milestoneId: number;
  disabled: boolean;
  toggleDividendsPanel: () => void;
}

const DividendGate = ({
  rule,
  milestoneId,
  disabled,
  toggleDividendsPanel,
}: IDocumentGateProps): JSX.Element => {
  const processDetail = useContext(ProcessContext);
  const { companyCode } = useParams<ICompanyCodeParams>();
  const buttonText = rule.isComplete
    ? 'View dividend information'
    : 'Update the dividend information';
  const linkButtonStyles = { padding: '0 4px', height: 40, display: 'flex', alignItems: 'center' };
  return (
    <>
      {processDetail?.dividends?.dividendReviewId && (
        <Link
          to={`/companies/${companyCode}/dividends-review/${processDetail?.dividends.dividendReviewId}`}
          style={linkButtonStyles}
        >
          <FontIcon
            className={`${iconClass} ${rule.isComplete ? completeClass : warningClass}`}
            iconName={rule.isComplete ? 'checkmark' : 'warning'}
          />
          {buttonText}
        </Link>
      )}
      {!processDetail?.dividends?.dividendReviewId && (
        <ActionButton onClick={toggleDividendsPanel}>
          <FontIcon
            className={`${iconClass} ${rule.isComplete ? null : warningClass}`}
            iconName={rule.isComplete ? 'statusCircleCheckmark' : 'warning'}
          />
          {buttonText}
        </ActionButton>
      )}
    </>
  );
};

export default DividendGate;
