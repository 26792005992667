import { IPersonaProps } from '@fluentui/react';
import graphApiRequest from '../ApiProvider';

interface IGraphResult {
  userPrincipalName: string;
  displayName: string;
  mail: string;
  id: string;
  givenName: string;
  surname: string;
  onPremisesExtensionAttributes: {
    extensionAttribute2: string; // used to determine if FTE,  FTE value is 50 ¯\_(ツ)_/¯
    extensionAttribute4: string; // represents the personnel number of a user
  };
}

function formatGraphFilterText(filterText: string) {
  filterText = filterText.replace(/'/g, "''");
  return filterText;
}

export default function searchForUsers(
  filterText: string,
  mustBeFte = false,
): IPersonaProps[] | Promise<IPersonaProps[]> {
  filterText = formatGraphFilterText(filterText);
  const requestBaseUrl = 'https://graph.microsoft.com/v1.0/users';
  const filterByName = `userType eq 'Member' and (startsWith(userPrincipalName, '${filterText}') or startsWith(displayName, '${filterText}'))`;
  const properties =
    'id,displayName,mail,givenName,surName,userPrincipalName,onPremisesExtensionAttributes';

  const url = `${requestBaseUrl}?$top=15&$count=true&$filter=${filterByName}&$select=${properties}`;
  return graphApiRequest(url, false)
    .then((results) => {
      return results.json();
    })
    .then((json) => {
      return json.value;
    })
    .then((array) => {
      let filteredResults = array;
      if (mustBeFte) {
        filteredResults = array.filter(
          (person: IGraphResult) =>
            person.onPremisesExtensionAttributes.extensionAttribute2 === '50',
        );
      }

      return filteredResults?.map((person: IGraphResult) => {
        const userPrincipalNameSplit = person.userPrincipalName.split('@');

        return {
          text: person.displayName,
          name: person.displayName,
          secondaryText: person.mail,
          graphId: person.id,
          firstName: person.givenName,
          lastName: person.surname,
          email: person.mail,
          alias: userPrincipalNameSplit[0],
          personnelNumber: person.onPremisesExtensionAttributes.extensionAttribute4,
        };
      });
    });
}
