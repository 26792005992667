import React, { useState, CSSProperties } from 'react';
import {
  Stack,
  IPersonaProps,
  PrimaryButton,
  TextField,
  IconButton,
  IButtonStyles,
} from '@fluentui/react';
import { Controller, useFieldArray } from 'react-hook-form';
import searchForUsers from '../../../utils/microsoftGraphApi/userSearch';
import { ICustomContactGroup, IServiceContactsUserAssignment } from './interfaces';
import ErrorHandlingPeoplePicker from '../../common/formFields/ErrorHandlingPeoplePicker';
import validateCustomContactPeoplePicker from './customContactValidator';

interface IContactGroupsFormProps {
  mainContacts: IServiceContactsUserAssignment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

const ContactGroupsForm: React.FunctionComponent<IContactGroupsFormProps> = ({
  mainContacts,
  form,
}: IContactGroupsFormProps): JSX.Element => {
  const PICKER_RESOLVE_DELAY = 450;
  const [newGroupId, setNewGroupId] = useState(-2);

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  const { errors, control, trigger, register, getValues, setValue, formState } = form;

  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'customContactGroups',
  });

  const validateLabel = (newVal: string): string => {
    const allContactGroupLabels: string[] = getValues().customContactGroups.map(
      (val: ICustomContactGroup) => val.label,
    );

    if (allContactGroupLabels.filter((label: string) => label === newVal).length > 1) {
      return 'Contact Group Names must be unique';
    }
    return null;
  };

  const createCustomFinancialContactComponent = (): void => {
    insert(0, { id: newGroupId, label: 'New Label', contacts: [] });
    setNewGroupId(newGroupId - 1);
  };

  const buttonStyles: IButtonStyles = {
    root: { position: 'absolute', right: 0, zIndex: 0.5, top: 0 },
  };

  const stackStyles: CSSProperties = { position: 'relative', marginTop: 10, padding: 15 };

  return (
    <Stack tokens={{ childrenGap: 30 }}>
      <PrimaryButton
        text="Add New Contact Group"
        iconProps={{ iconName: 'Add' }}
        styles={{ root: { marginBottom: 15 } }}
        onClick={createCustomFinancialContactComponent}
      />
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fields as any).map((group: ICustomContactGroup, index: number) => {
          const fieldName = `customContactGroups[${index}]`;
          return (
            <Stack key={group.id} style={stackStyles} className="ms-depth-8">
              <IconButton
                styles={buttonStyles}
                iconProps={{ iconName: 'Cancel' }}
                title={`Remove ${group.label}`}
                ariaLabel={`Remove ${group.label}`}
                onClick={(): void => {
                  remove(index);
                }}
              />
              {/* This is used to include the custom contact group id within the payload */}
              <input
                type="hidden"
                ref={register()}
                name={`${fieldName}.id`}
                defaultValue={group.id}
              />
              <Controller
                control={control}
                name={`${fieldName}.label`}
                defaultValue={group.label}
                render={(): React.ReactElement => (
                  <TextField
                    label="Contact Group Name"
                    errorMessage={errors?.customContactGroups?.[index]?.label?.message}
                    onBlur={(): void => {
                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                    onChange={(
                      event: React.FormEvent<HTMLInputElement>,
                      newValue: string,
                    ): void => {
                      setValue(`${fieldName}.label`, newValue?.trim());
                    }}
                    defaultValue={group.label}
                  />
                )}
                rules={{
                  minLength: {
                    value: 2,
                    message: 'Contact Group Names must be at least two characters long',
                  },
                  required: 'Contact Group Name is required',
                  validate: validateLabel,
                }}
              />
              <Controller
                name={`${fieldName}.contacts`}
                control={control}
                defaultValue={group.contacts}
                render={(): React.ReactElement => (
                  <ErrorHandlingPeoplePicker
                    controlName={`${group.label}Picker${group.id}`}
                    labelText="Contacts"
                    onChange={(items?: IPersonaProps[]): void => {
                      setValue(`${fieldName}.contacts`, items);
                      // We need to re-trigger all validation since our rules are cross-input dependent
                      trigger();
                    }}
                    errorMessage={errors?.customContactGroups?.[index]?.contacts?.message}
                    removeButtonAriaLabel="Remove Contact"
                    defaultSelectedItems={group.contacts}
                    selectedItems={getValues(`${fieldName}.contacts`)}
                    onResolveSuggestions={onResolveSuggestions}
                    resolveDelay={PICKER_RESOLVE_DELAY}
                  />
                )}
                rules={{
                  validate: (): string => {
                    return validateCustomContactPeoplePicker({
                      updatingContactGroup: getValues(`${fieldName}`),
                      contactGroups: getValues().customContactGroups.filter(
                        (cg: ICustomContactGroup) => +cg.id !== +group.id,
                      ),
                      mainContacts,
                    });
                  },
                }}
              />
            </Stack>
          );
        })
      }
    </Stack>
  );
};

export default ContactGroupsForm;
