import { IDropdownOption, Label, Stack, TextField } from '@fluentui/react';
import React, { FormEvent } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { RequiredMarker } from '../../common/formFields/RequiredMarker';
import RequiredFieldsNotification from '../RequiredNotification';
import MsReportingSalesLocationDropDown from './MsReportingSalesLocationDropDown';
import MsReportingSalesYearSelector from './MsReportingSalesYearSelector';
import { ISapSetupInfo, RequiredFieldsType } from './types';

interface ISapSetupFormProps {
  form: UseFormMethods<ISapSetupInfo>;
  data: ISapSetupInfo;
}

const SapSetupForm = ({ data, form }: ISapSetupFormProps): JSX.Element => {
  const { control, errors, setValue } = form;

  const onMsReportingSalesLocationChange = (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const value = { id: option.key, name: option.text };
    setValue('msReportingSalesLocation', value);
  };

  const onMsReportingSalesYearChange = (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    setValue('msReportingSalesYear', option.key);
  };

  const onTaxJurisdictionCodeChange = (
    event: React.FormEvent<HTMLInputElement>,
    newValue?: string,
  ) => {
    // Remove all symbols except dash (-) from input
    const cleanedInput = newValue.replace(/[^a-zA-Z0-9-]/g, '');

    setValue('taxJurisdictionCode', cleanedInput);
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <RequiredFieldsNotification type={RequiredFieldsType.SapSetup} />
      <Label style={{ paddingBottom: 0 }}>
        Tax Jurisdiction Code <RequiredMarker />
      </Label>
      <Controller
        name="taxJurisdictionCode"
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <TextField
            id="taxJurisdictionCode"
            value={value}
            onChange={onTaxJurisdictionCodeChange}
          />
        )}
        control={control}
        defaultValue={data?.taxJurisdictionCode || ''}
        errorMessage={errors?.taxJurisdictionCode?.message}
        rules={{
          maxLength: {
            value: 100,
            message: 'Tax Jurisdiction Code can only be a max of 100 characters',
          },
        }}
      />
      <Controller
        name="msReportingSalesYear"
        control={control}
        defaultValue={data?.msReportingSalesYear}
        render={(): JSX.Element => {
          return (
            <MsReportingSalesYearSelector
              defaultValue={data?.msReportingSalesYear}
              onChange={onMsReportingSalesYearChange}
            />
          );
        }}
      />
      <Controller
        name="msReportingSalesLocation"
        control={control}
        defaultValue={data?.msReportingSalesLocation}
        render={(): JSX.Element => {
          return (
            <MsReportingSalesLocationDropDown
              defaultValue={data?.msReportingSalesLocation?.id}
              onChange={onMsReportingSalesLocationChange}
            />
          );
        }}
      />
    </Stack>
  );
};

export default SapSetupForm;
