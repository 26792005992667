import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompanyDetail } from '..';
import Page from '../../common/page/Page';
import CompaniesListPage from '../list/CompaniesListPage';

const CompaniesRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Page path="/companies" exact component={CompaniesListPage} title="Companies" />
      <Route path="/companies/:companyCode" component={CompanyDetail} />
    </Switch>
  );
};

export default CompaniesRoute;
