import { useQuery } from '@apollo/client';
import { CoherenceTreeItem } from '@coherence-design-system/controls';
import { Spinner, SpinnerSize } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GET_ROLE_ACTIONS_QUERY, RoleActions } from '../../utils/statApi/RolesApi';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { IRoleIdParams } from '../common/ParamTypes';
import TreeView from '../common/TreeView';
import RoleDetail from './RoleDetail';

const RoleDescriptionPage = (): JSX.Element => {
  const history = useHistory();
  const { roleId } = useParams<IRoleIdParams>();

  const { data, loading, error } = useQuery(GET_ROLE_ACTIONS_QUERY);

  const roleActions: RoleActions[] = data?.roleActions;

  useEffect(() => {
    if (!roleId && roleActions && roleActions.length > 0) {
      history.replace(`/role-description/roles/${roleActions[0].id}`);
    }
  }, [data, loading]);

  const getCurrentRole = () => {
    const currentRole = roleActions?.find((role) => {
      return role.id.toString() === roleId;
    });

    return currentRole;
  };

  const getTreeItems = (): CoherenceTreeItem[] => {
    const items = roleActions?.map((role: RoleActions, index: number) => {
      return {
        title: role.role.name,
        id: role.role.id.toString(),
        onClick: (ev: React.MouseEvent<HTMLElement>, item: CoherenceTreeItem): void => {
          ev.preventDefault();
          ev.stopPropagation();
          history.push(`/role-description/roles/${role.id}`);
        },
      } as CoherenceTreeItem;
    });

    items.sort((a, b) => (a.title > b.title ? 1 : -1));

    const selectedRole = items?.find((role) => {
      return role.id.toString() === roleId;
    });

    if (selectedRole) {
      const selectedItem = items.find((i) => {
        return i.title === selectedRole.title;
      });

      selectedItem.isSelected = true;
    }

    return items;
  };

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} actionName="loading role details" />
      {loading && <Spinner label="Loading Role Details" size={SpinnerSize.medium} />}
      {!loading && roleActions && (
        <TreeView treeItems={getTreeItems()}>
          <RoleDetail roleActions={getCurrentRole()} />
        </TreeView>
      )}
    </>
  );
};

export default RoleDescriptionPage;
