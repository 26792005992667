import { IColumn, ColumnActionsMode } from '@fluentui/react';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';
import { IProcessType } from '../../../utils/types/IProcessType';

interface IProcessTypeListColumnParams {
  renderProcessTypeNameLink: (item: IProcessType) => JSX.Element;
}

const getColumns = ({
  renderProcessTypeNameLink,
}: IProcessTypeListColumnParams): Array<IColumn> => {
  return [
    {
      key: 'name',
      name: 'Process Type',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 300,
      isRowHeader: false,
      className: rowClasses.medFont,
      isResizable: false,
      onRender: (item: IProcessType): JSX.Element => {
        return renderProcessTypeNameLink(item);
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export default getColumns;
