import React, { useState } from 'react';
import { ActionButton, Announced, FontIcon, Spinner } from '@fluentui/react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { useLazyQuery } from '@apollo/client';
import { IMilestoneRule } from '../Interfaces';
import MilestoneDocumentUpload from './MilestoneDocumentUpload';
import MilestoneDocuments from './MilestoneDocuments';
import { warningClass, iconClass, completeClass } from './Gate.Styles';
import CanAccess from '../../../common/canAccess/CanAccess';
import { GET_MILESTONE_DOCUMENTS } from '../../../../utils/statApi/MilestoneApi';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';

interface IDocumentGateProps {
  rule: IMilestoneRule;
  milestoneId: number;
  disabled: boolean;
  isMilestoneComplete: boolean;
}

const DocumentGate = ({
  rule,
  milestoneId,
  disabled,
  isMilestoneComplete,
}: IDocumentGateProps): JSX.Element => {
  const [panelIsOpen, setIsPanelOpen] = useState(false);
  const [announcedMessage, setAnnouncedMessage] = useState('');

  const [getMilestoneDocuments, { data, loading, error }] = useLazyQuery(GET_MILESTONE_DOCUMENTS, {
    variables: { milestoneId },
    fetchPolicy: 'cache-and-network',
  });

  const documents = data?.milestoneDocumentList || [];

  const closePanel = (): void => {
    setAnnouncedMessage('');
    setIsPanelOpen(false);
  };

  const fileUploaded = (): void => {
    setAnnouncedMessage('File uploaded successfully');
    getMilestoneDocuments();
  };

  const buttonText = rule.isComplete ? 'View or edit uploaded documents' : 'Upload documents';

  return (
    <>
      <ActionButton
        onClick={(): void => {
          setIsPanelOpen(true);
          getMilestoneDocuments();
        }}
        ariaLabel={buttonText}
      >
        <FontIcon
          className={`${iconClass} ${rule.isComplete ? completeClass : warningClass}`}
          iconName={rule.isComplete ? 'checkmark' : 'warning'}
        />
        {buttonText}
      </ActionButton>
      <CoherencePanel
        closeButtonAriaLabel="Close Milestone Document Panel"
        panelSize={CoherencePanelSize.medium}
        isOpen={panelIsOpen}
        onDismiss={closePanel}
        styles={CoherencePanelStyles}
        hasCloseButton
        titleText="Milestone Documents"
      >
        {disabled && (
          <CanAccess requestedAction="MilestoneDocument:Upload">
            <MilestoneDocumentUpload milestoneId={milestoneId} onUpload={fileUploaded} />
          </CanAccess>
        )}
        <Announced message={announcedMessage} />
        <br />
        <LoadingErrorMessage loading={loading} error={error} />
        {loading && <Spinner label="Loading Documents" />}
        <MilestoneDocuments isMilestoneComplete={isMilestoneComplete} documents={documents} />
      </CoherencePanel>
    </>
  );
};

export default DocumentGate;
