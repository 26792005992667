import { mergeStyles, NeutralColors, Persona, PersonaSize, Spinner, Stack } from '@fluentui/react';
import React from 'react';
import momentUtc from '../../utils/DateFormatter';
import StatDateFormats from '../../utils/types/DateFormats';
import { IReviewComment } from '../../utils/types/IReview';
import FileSelectorItem from '../common/formFields/fileUpload/fileSelectorItem';

interface IReviewCommentsProps {
  reviewComments: Array<IReviewComment>;
  loading: boolean;
}

const ReviewComments = ({ reviewComments, loading }: IReviewCommentsProps): JSX.Element => {
  if (!reviewComments) {
    return <div>No comments have been added yet.</div>;
  }

  if (loading) {
    return <Spinner />;
  }

  const textStyles = mergeStyles({
    paddingTop: 6,
  });
  const dateTextStyles = mergeStyles({
    paddingTop: 10,
    color: NeutralColors.gray130,
  });
  const documentContainerStyles = mergeStyles({
    maxWidth: 500,
    paddingLeft: 40,
  });
  const timelineItemStyles = mergeStyles({
    height: 15,
    width: 15,
    backgroundColor: NeutralColors.gray10,
    marginLeft: -39,
    paddingBottom: 5,
    paddingTop: 5,
    marginRight: 15,
  });
  const dotStyles = mergeStyles({
    height: 15,
    width: 15,
    backgroundColor: NeutralColors.gray60,
    borderRadius: '50%',
    display: 'inline-block',
  });
  const listItemStyles = mergeStyles({
    borderLeftColor: NeutralColors.gray60,
    borderLeftWidth: 2,
    borderLeftStyle: 'solid',
    paddingLeft: 15,
    paddingBottom: 15,
  });
  const containerStyles = {
    padding: '10px 10px 10px 15px',
    backgroundColor: NeutralColors.white,
    borderColor: NeutralColors.gray30,
    borderWidth: 1,
    borderStyle: 'solid',
  };
  return (
    <Stack>
      {reviewComments.map((item, index) => {
        return (
          <div key={item.id} className={listItemStyles}>
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 5 }}
              style={containerStyles}
            >
              <div className={timelineItemStyles}>
                <span className={dotStyles} />
              </div>
              <Stack.Item grow={1}>
                <Stack horizontal wrap>
                  <Stack.Item disableShrink>
                    <Persona text={item.author.displayName} size={PersonaSize.size32} />
                  </Stack.Item>
                  <span className={textStyles}>{item.text}</span>
                </Stack>
                {item.document && (
                  <div key={item.document.url} className={documentContainerStyles}>
                    <FileSelectorItem
                      index={index}
                      user={item.document.uploader}
                      url={item.document.url}
                      fileName={item.document.title}
                      uiDisabled={false}
                      allowDelete={false}
                      allowDownload
                      allowViewInTab
                      activity={`Uploaded ${momentUtc(
                        item.document.uploadDate,
                        StatDateFormats.DayBreakout,
                      )}`}
                    />
                  </div>
                )}
              </Stack.Item>
              <Stack.Item disableShrink>
                <span className={dateTextStyles}>
                  {momentUtc(item.timeStamp, StatDateFormats.DayBreakout)}
                </span>
              </Stack.Item>
            </Stack>
          </div>
        );
      })}
    </Stack>
  );
};

export default ReviewComments;
