import { gql } from '@apollo/client';
import FILING_INFO_FRAGMENT from './FilingInfoApi';
import { REVIEW_FRAGMENT, TRACKER_FRAGMENT } from './ReviewApi';

const DIVIDEND_PAYMENT_FRAGMENT = gql`
  fragment DividendPayment on DividendPaymentDto {
    id
    dividendId
    dividendPaymentType {
      id
      fieldName
    }
    dividendType
    amount
    currencyCode
    paymentDate
    fxRate
    whRate
    whAmount
  }
`;

export const COMPANY_FISCAL_YEAR_LIST_QUERY = gql`
  query CompanyFiscalYearList($companyCode: String!) {
    companyFiscalYearList(companyCode: $companyCode) {
      id
      fiscalYear
      isInterim
      dividendAmount
      currencyCode
      agmStatus {
        id
        fieldName
      }
      agmStatusDate
      auditStatus {
        id
        fieldName
      }
      auditStatusDate
      afsProcessName
      dividendPaymentsTotals {
        dividendPaymentTotal
        currencyCode
      }
      lastMilestoneSignOffDate
    }
  }
`;

export const DIVIDENDS_FRAGMENT = gql`
  fragment Dividends on CompanyDividendsDto {
    id
    maxDividendAllowed
    proposedDividendAllowed
    noDividendDeclared
    lessDividendReason {
      id
      fieldName
    }
    comments
    declaredDate
    currencyCode
    companyId
    dividendReviewId
    reviewStatus
    dividendsFromCurrentYear
    dividendsFromRetainedEarnings
  }
`;

export const AGM_REVIEW_CONTACT_FRAGMENT = gql`
  fragment AgmReviewContact on AgmReviewContactDto {
    id
    graphId
    text: name
    name
    firstName
    lastName
    alias
    email
  }
`;

export const AGM_DETAIL_FRAGMENT = gql`
  fragment AgmDetail on AgmDetailDto {
    id
    filingDueDate
    agmReviewId
    processId
    agmDeadlineBeforeAfsDeadline
    annualGeneralMeetingDueDateMonth
    annualGeneralMeetingDueDateDay
    taxFilingDeadlineBeforeAfsDeadline
    taxFilingDeadline
    externalAfsFilingRequired
    physicalSignatureRequired
    hardCopyShippingAddress
    hardCopyShippingContactName
    hardCopyShippingPhoneNumber
    requiredToBeFiledWithAfs
    inkColorRequired
    signatureInkColor
    originalsCount
    paperSize
    otherPaperSize
    finalExternalFilingSubmitter
    otherFinalExternalFilingSubmitter
    auditorFees
    auditorFeesCurrencyCode
    auditingFirm {
      id
      fieldName
    }
    otherAuditingFirm
    auditorAppointmentPeriod
    dividendCurrencyCode
    proposedDividendAllowed
    dividendCurrentYear
    dividendRetainedEarnings
    canAfsBeExtended
    managementRepresentationLetterRequired
    additionalProcessingTimeApostille
    additionalProcessingTimeLegalization
    additionalProcessingTimeLocalSigner
    dividendsReviewId
    dividendsReviewStatus
  }
`;

const COMPANY_FISCAL_YEAR_DETAIL_FRAGMENT = gql`
  fragment CompanyFiscalYearDetail on CompanyFiscalYearDetailDto {
    id
    fiscalYear
    isInterim
    companyDividends {
      ...Dividends
    }
    taxDividends {
      id
      taxProposedDividendAmount
      taxCurrencyCode
      taxComments
    }
    agmFiscalYear {
      id
      agmStatus {
        id
        fieldName
      }
      celaAgmStatusDate
      agmDueDateDay
      agmDueDateMonth
      agmInformation
      agmCelaContacts {
        ...AgmReviewContact
      }
    }
    auditFiscalYear {
      id
      auditStatus {
        id
        fieldName
      }
      auditingFirm {
        id
        fieldName
      }
      otherAuditingFirm
      statusDate
      auditLegallyRequired
    }
    filingInfo {
      ...FilingInfo
    }
    dividendPayments {
      ...DividendPayment
    }
    afsProcess {
      id
      name
      processTypeUri
      lastMilestoneSignOffDate
    }
    agmDetail {
      ...AgmDetail
    }
  }
  ${DIVIDEND_PAYMENT_FRAGMENT}
  ${DIVIDENDS_FRAGMENT}
  ${FILING_INFO_FRAGMENT}
  ${AGM_REVIEW_CONTACT_FRAGMENT}
  ${AGM_DETAIL_FRAGMENT}
`;

export const COMPANY_FISCAL_YEAR_DETAIL_QUERY = gql`
  query CompanyFiscalYearDetail($companyCode: String!, $fiscalYearId: Int!) {
    companyFiscalYearDetail(companyCode: $companyCode, fiscalYearId: $fiscalYearId) {
      ...CompanyFiscalYearDetail
    }
  }
  ${COMPANY_FISCAL_YEAR_DETAIL_FRAGMENT}
`;

export const UPDATE_FILING_INFO_MUTATION = gql`
  mutation updateFilingInfo($companyFiscalYearId: Int!, $filingInfo: FilingInfoDtoInput!) {
    updateFilingInfo(companyFiscalYearId: $companyFiscalYearId, filingInfo: $filingInfo) {
      ...CompanyFiscalYearDetail
    }
  }
  ${COMPANY_FISCAL_YEAR_DETAIL_FRAGMENT}
`;

export const GET_DIVIDEND_REASONS_QUERY = gql`
  {
    dividendReasons {
      id
      fieldName
    }
  }
`;

export const GET_DIVIDEND_PAYMENT_TYPES_QUERY = gql`
  {
    dividendPaymentTypes {
      id
      fieldName
    }
  }
`;

export const EDIT_AUDIT_INFO_MUTATION = gql`
  mutation updateAuditFiscalYear($auditInfo: AuditFiscalYearDtoInput!, $companyCode: String!) {
    updateAuditFiscalYear(auditInfo: $auditInfo, companyCode: $companyCode) {
      id
      auditStatus {
        id
        fieldName
      }
      auditingFirm {
        id
        fieldName
      }
      otherAuditingFirm
      statusDate
      auditLegallyRequired
    }
  }
`;

export const EDIT_DIVIDENDS_MUTATION = gql`
  mutation updateDividends($dividends: CompanyDividendsDtoInput!) {
    updateDividends(dividends: $dividends) {
      ...Dividends
    }
  }
  ${DIVIDENDS_FRAGMENT}
`;

export const EDIT_DIVIDEND_PAYMENT_MUTATION = gql`
  mutation updateDividendPayment($dividendPayment: DividendPaymentDtoInput!) {
    updateDividendPayment(dividendPaymentDto: $dividendPayment) {
      ...DividendPayment
    }
  }
  ${DIVIDEND_PAYMENT_FRAGMENT}
`;

export const CREATE_DIVIDEND_PAYMENT_MUTATION = gql`
  mutation createDividendPayment($dividendPayment: DividendPaymentDtoInput!) {
    createDividendPayment(dividendPaymentDto: $dividendPayment) {
      ...DividendPayment
    }
  }
  ${DIVIDEND_PAYMENT_FRAGMENT}
`;

export const DELETE_DIVIDEND_PAYMENT_MUTATION = gql`
  mutation deleteDividendPayment($dividendPayment: DividendPaymentDtoInput!) {
    deleteDividendPayment(dividendPaymentDto: $dividendPayment) {
      ...DividendPayment
    }
  }
  ${DIVIDEND_PAYMENT_FRAGMENT}
`;

export const EDIT_TAX_DIVIDENDS_MUTATION = gql`
  mutation updateTaxDividends($dividends: TaxDividendsDtoInput!) {
    updateTaxDividends(dividends: $dividends) {
      id
      taxProposedDividendAmount
      taxCurrencyCode
      taxComments
    }
  }
`;

export const EDIT_AGM_STATUS_MUTATION = gql`
  mutation updateAgmFiscalYear($agmStatus: AgmFiscalYearInputDtoInput!) {
    updateAgmFiscalYear(agmStatus: $agmStatus) {
      agmFiscalYear {
        id
        agmStatus {
          id
          fieldName
        }
        celaAgmStatusDate
        agmDueDateDay
        agmDueDateMonth
        agmInformation
      }
      companyDividends {
        ...Dividends
      }
    }
  }
  ${DIVIDENDS_FRAGMENT}
`;

export const DIVIDENDS_REVIEW_QUERY = gql`
  query DividendReview($dividendReviewId: Int!) {
    dividendReview(dividendReviewId: $dividendReviewId) {
      dividends {
        ...Dividends
      }
      review {
        ...Review
      }
      afsProcessId
    }
  }
  ${DIVIDENDS_FRAGMENT}
  ${REVIEW_FRAGMENT}
`;

export const APPROVE_DIVIDENDS_REVIEW = gql`
  mutation approveDividendReview($reviewId: Int!, $approvalId: Int!) {
    approveDividendReview(reviewId: $reviewId, approvalId: $approvalId) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const REJECT_DIVIDENDS_REVIEW = gql`
  mutation rejectDividendReview($reviewId: Int!, $approvalId: Int!, $reason: String) {
    rejectDividendReview(reviewId: $reviewId, approvalId: $approvalId, reason: $reason) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const CREATE_DIVIDEND_REVIEW_COMMENT = gql`
  mutation createDividendReviewComment($reviewId: Int!, $comment: String!) {
    createDividendReviewComment(reviewId: $reviewId, comment: $comment) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const ATTACH_DIVIDEND_REVIEW_DOCUMENT = gql`
  mutation attachDividendReviewDocument($reviewDocuments: ReviewDocumentsDtoInput!) {
    attachDividendReviewDocument(reviewDocuments: $reviewDocuments) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const DIVIDENDS_REVIEW_LIST_QUERY = gql`
  query DividendsReviewList(
    $dividendsReviewSearchDto: DividendsReviewSearchDtoInput
    $paginationDto: PaginationDtoInput
  ) {
    dividendsReviewList(
      dividendsReviewSearchDto: $dividendsReviewSearchDto
      paginationDto: $paginationDto
    ) {
      items {
        id
        companyCode
        companyName
        processName
        reviewStatus
        taxStatusId
        treasuryStatusId
        submissionDate
        reportingPeriodEndDate
        filingDueDate
        proposedDividend
        maxDividend
        currencyCode
      }
      totalCount
      currentPage
      pageSize
    }
  }
`;

export const GET_PROCESS_AGM_DETAIL = gql`
  query ProcessAgmDetail($processId: Int!) {
    processAgmDetail(processId: $processId) {
      ...AgmDetail
    }
  }
  ${AGM_DETAIL_FRAGMENT}
`;

export const UPDATE_AGM_DETAILS = gql`
  mutation updateAgmDetails($input: AgmDetailDtoInput!, $processId: Int!) {
    updateAgmDetails(input: $input, processId: $processId) {
      ...AgmDetail
    }
  }
  ${AGM_DETAIL_FRAGMENT}
`;

export const AGM_REVIEW_LIST_QUERY = gql`
  query AgmReviewList(
    $agmReviewSearchDto: AgmReviewSearchDtoInput
    $paginationDto: PaginationDtoInput
  ) {
    agmReviewList(agmReviewSearchDto: $agmReviewSearchDto, paginationDto: $paginationDto) {
      items {
        id
        companyCode
        companyName
        reviewStatus
        celaStatusId
        submissionDate
        reportingPeriodEndDate
        regionalControllerSignoffDate
        agmDueDateMonth
        agmDueDateDay
        filingDueDate
        regionalControllerSignoffAge
        celaAssignees {
          ...AgmReviewContact
        }
      }
      totalCount
      currentPage
      pageSize
    }
  }
  ${AGM_REVIEW_CONTACT_FRAGMENT}
`;

export const AGM_REVIEW_QUERY = gql`
  query AgmReview($agmReviewId: Int!) {
    agmReview(agmReviewId: $agmReviewId) {
      agmDetail {
        ...AgmDetail
      }
      review {
        ...Review
      }
      agmReviewContacts {
        ...AgmReviewContact
      }
    }
  }
  ${AGM_DETAIL_FRAGMENT}
  ${REVIEW_FRAGMENT}
  ${AGM_REVIEW_CONTACT_FRAGMENT}
`;

export const APPROVE_AGM_REVIEW = gql`
  mutation approveAgmReview($reviewId: Int!, $approvalId: Int!) {
    approveAgmReview(reviewId: $reviewId, approvalId: $approvalId) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const REJECT_AGM_REVIEW = gql`
  mutation rejectAgmReview($reviewId: Int!, $approvalId: Int!, $reason: String) {
    rejectAgmReview(reviewId: $reviewId, approvalId: $approvalId, reason: $reason) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const CREATE_AGM_REVIEW_COMMENT = gql`
  mutation createAgmReviewComment($reviewId: Int!, $comment: String!) {
    createAgmReviewComment(reviewId: $reviewId, comment: $comment) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const ATTACH_AGM_REVIEW_DOCUMENT = gql`
  mutation attachAgmReviewDocument($reviewDocuments: ReviewDocumentsDtoInput!) {
    attachAgmReviewDocument(reviewDocuments: $reviewDocuments) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const COMPANY_DEFAULT_AGM_DETAILS_FRAGMENT = gql`
  fragment CompanyDefaultAgmDetails on CompanyDefaultAgmDetailsDto {
    id
    agmDeadlineBeforeAfsDeadline
    taxFilingDeadlineBeforeAfsDeadline
    taxFilingDeadline
    externalAfsFilingRequired
    requiredToBeFiledWithAfs
    canAfsBeExtended
    physicalSignatureRequired
    hardCopyShippingAddress
    inkColorRequired
    signatureInkColor
    originalsCount
    paperSize
    otherPaperSize
    managementRepresentationLetterRequired
    auditorFeesCurrencyCode
    additionalProcessingTimeApostille
    additionalProcessingTimeLegalization
    additionalProcessingTimeLocalSigner
    auditingFirm {
      id
      fieldName
    }
    otherAuditingFirm
  }
`;

export const COMPANY_DEFAULT_AGM_DETAILS_QUERY = gql`
  query CompanyDefaultAgmDetails($companyCode: String!) {
    companyDefaultAgmDetails(companyCode: $companyCode) {
      ...CompanyDefaultAgmDetails
    }
  }
  ${COMPANY_DEFAULT_AGM_DETAILS_FRAGMENT}
`;

export const UPDATE_AGM_REVIEW_CONTACTS = gql`
  mutation updateAgmReviewContacts($input: AgmReviewContactInputDtoInput) {
    updateAgmReviewContacts(input: $input) {
      agmDetail {
        ...AgmDetail
      }
      review {
        ...Review
      }
      agmReviewContacts {
        ...AgmReviewContact
      }
    }
  }
  ${AGM_DETAIL_FRAGMENT}
  ${REVIEW_FRAGMENT}
  ${AGM_REVIEW_CONTACT_FRAGMENT}
`;

export const GET_AGM_TRACKER_LIST_QUERY = gql`
  query AgmTrackerList(
    $agmTrackerSearchDto: AgmTrackerSearchDtoInput
    $skip: Int
    $take: Int
    $order: [AgmTrackerDtoSortInput!]
  ) {
    agmTrackerList(
      agmTrackerSearchDto: $agmTrackerSearchDto
      skip: $skip
      take: $take
      order: $order
    ) {
      items {
        ...AgmTracker
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
    agmTrackerOwnerFilteredTotals(agmTrackerSearchDto: $agmTrackerSearchDto) {
      celaOwnerCount
      controllerOwnerCount
      totalOwnerCount
    }
  }
  ${TRACKER_FRAGMENT}
`;

export const UPDATE_AGM_TRACKER_ITEM = gql`
  mutation updateAgmTrackerItem($input: AgmTrackerItemInputDtoInput!) {
    updateAgmTrackerItem(input: $input) {
      status
    }
  }
`;

export const UPDATE_AGM_TRACKER_ITEM_BULK = gql`
  mutation updateAgmTrackerItemsBulk($input: AgmTrackerItemBulkInputDtoInput!) {
    updateAgmTrackerItemsBulk(input: $input) {
      status
    }
  }
`;

export const GET_AGM_TRACKER_STATUSES_QUERY = gql`
  {
    agmTrackerStatuses {
      id
      fieldName
      trackerIndex
    }
  }
`;

export const GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY = gql`
  query AgmTrackerStatusCounts($owner: AgmTrackerOwner!) {
    agmTrackerStatusCounts(owner: $owner) {
      agingMetrics {
        agingId
        agingName
        alternativeName
        count
      }
      statusMetrics {
        statusId
        statusName
        statusIndex
        count
      }
    }
  }
`;
export default COMPANY_FISCAL_YEAR_LIST_QUERY;
