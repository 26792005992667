import React from 'react';
import { IPersonaProps } from '@fluentui/react';
import ErrorHandlingPeoplePicker from '../common/formFields/ErrorHandlingPeoplePicker';
import searchForUsers from '../../utils/microsoftGraphApi/userSearch';
import { IServiceContact } from '../companies/serviceContacts/interfaces';

interface IUserDetailsGraphSearch {
  onUserSearch: (graphGUID: IServiceContact) => void;
}

const PICKER_RESOLVE_DELAY = 450;

const UserDetailsGraphSearch = ({ onUserSearch }: IUserDetailsGraphSearch): JSX.Element => {
  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };
  return (
    <div role="form">
      <ErrorHandlingPeoplePicker
        controlName="userSearch"
        labelText="User Search"
        required
        onChange={(items?: IPersonaProps[]): void => {
          onUserSearch(items[0] as IServiceContact);
        }}
        onResolveSuggestions={onResolveSuggestions}
        itemLimit={1}
        resolveDelay={PICKER_RESOLVE_DELAY}
      />
    </div>
  );
};
export default UserDetailsGraphSearch;
