import React from 'react';
import {
  CommandButton,
  Pivot,
  PivotItem,
  IContextualMenuProps,
  IContextualMenuItem,
} from '@fluentui/react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  pivotHeaderPivotMenu,
  pivotHeaderCommandButtonMenu,
} from '../../../app/common/styles/CommonStyleObjects';
import { IPivotHeaderItems } from '../../../utils/types/IPivotHeaderItems';
import FullWidthHeader from './FullWidthHeader';

interface IPivotHeaderProps {
  baseUri: string;
  getHeaderTitle: () => JSX.Element;
  pivotHeaderItems: IPivotHeaderItems;
  ariaLabel: string;
  activeSegmentIndex?: number;
}

const PivotHeader = (props: IPivotHeaderProps): JSX.Element => {
  const { baseUri, getHeaderTitle, pivotHeaderItems, ariaLabel, activeSegmentIndex } = props;
  const location = useLocation();
  const history = useHistory();
  const uri = location.pathname;
  const pieces = uri.split('/');
  const activeBaseSegment = activeSegmentIndex ? pieces[activeSegmentIndex] : pieces[3];

  // menu onClick
  const handleLinkClick = (item: PivotItem, ev: React.MouseEvent<HTMLElement>): void => {
    ev?.preventDefault();
    ev?.stopPropagation();
    history.push(`${baseUri}/${item.props.itemKey}`);
  };

  // mobile menu onClick
  const handleLinkClickMobile = (item: IContextualMenuItem): void => {
    history.push(`${baseUri}/${item.key}`);
  };

  // get mobile menu items
  function getMobileMenuProps(onItemClick: {
    (item: IContextualMenuItem): void;
  }): IContextualMenuProps {
    const items: IContextualMenuItem[] = [];

    Object.keys(pivotHeaderItems).forEach((key) => {
      items.push({
        key,
        text: pivotHeaderItems[key].headerText,
        iconProps: { iconName: pivotHeaderItems[key].iconName },
      });
    });

    return {
      onItemClick: (ev, item): void => {
        onItemClick(item);
      },
      items,
      directionalHintFixed: true,
    };
  }

  // render subtitle menu
  // menu renders desktop (pivot) & mobile (command button)
  // and hides 1 or the other based on the current screen size
  const renderSubtitleMenu = (): JSX.Element => {
    return (
      <>
        <Pivot
          aria-label={ariaLabel}
          selectedKey={activeBaseSegment}
          onLinkClick={handleLinkClick}
          styles={pivotHeaderPivotMenu}
          headersOnly
        >
          {Object.keys(pivotHeaderItems).map((key) => {
            return (
              <PivotItem key={key} headerText={pivotHeaderItems[key]?.headerText} itemKey={key} />
            );
          })}
        </Pivot>
        <CommandButton
          ariaLabel="Select a section to navigate to"
          text={`${pivotHeaderItems[activeBaseSegment]?.headerText}`}
          menuProps={getMobileMenuProps(handleLinkClickMobile)}
          styles={pivotHeaderCommandButtonMenu}
        />
      </>
    );
  };

  return <FullWidthHeader title={getHeaderTitle} subtitle={renderSubtitleMenu} />;
};
export default PivotHeader;
