import React from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import { Spinner, SpinnerSize, Stack, Label } from '@fluentui/react';
import UnauthorizedPage from './UnauthorizedPage';
import { GetUserQuery, StatUser } from '../../utils/statApi/UsersApi';
import { verticalCenterStack } from '../../app/common/styles/CommonStyleObjects';
import ErrorPage from '../errors/ErrorPage';
import { UserProvider } from '../../utils/authorization/UserContext';

interface IAuthorizationCheckerProps {
  graphId: string;
  children: JSX.Element;
  useCardStyle?: boolean;
}

const AuthorizationChecker = (props: IAuthorizationCheckerProps): JSX.Element => {
  const { graphId, children, useCardStyle } = props;
  const {
    error,
    loading,
    data,
  }: { error?: ApolloError; loading: boolean; data: { statUser: StatUser } } = useQuery(
    GetUserQuery,
    {
      variables: { graphGUID: graphId },
    },
  );

  if (error && error.networkError) {
    // This is due to the way the networkError object is a union of a few
    // types.  When a union is used, only the common types able to be accessed
    // by code.  So we can't access the statusCode property here without some
    // trickery.  I tried a couple scenarios with typeguards, but this was the
    // only one they would get past check-types and linter errors.
    // https://github.com/apollographql/apollo-link/issues/300
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { networkError }: any = error;
    if ('statusCode' in networkError && networkError.statusCode === 401) {
      return <UnauthorizedPage useCardStyle={useCardStyle} />;
    }
  }

  if (error) {
    return <ErrorPage message={error.message} useCardStyle={useCardStyle} />;
  }

  if (loading) {
    return (
      <Stack className={useCardStyle ? '' : verticalCenterStack} horizontalAlign="center">
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Label>Loading...</Label>
          <Spinner size={SpinnerSize.large} />
        </Stack>
      </Stack>
    );
  }

  if (!loading && (!data?.statUser || !data?.statUser?.isActive)) {
    return <UnauthorizedPage useCardStyle={useCardStyle} />;
  }

  return <UserProvider value={data.statUser}>{children}</UserProvider>;
};

export default AuthorizationChecker;
