import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  COMPANY_FISCAL_YEAR_DETAIL_QUERY,
  CREATE_DIVIDEND_PAYMENT_MUTATION,
  EDIT_DIVIDEND_PAYMENT_MUTATION,
} from '../../../utils/statApi/CompanyFiscalYearsApi';
import ActionButtons from '../../common/ActionButtons';
import { IFiscalYearParams } from '../../common/ParamTypes';
import DividendPaymentForm from '../detail/forms/DividendPaymentForm';
import { IDividendPayment } from '../serviceContacts/interfaces';
import transformDividendPaymentDto from './DividendPaymentTranslator';

interface IDividendPaymentEditPanelProps {
  closePanel: () => void;
  dividendPayment: IDividendPayment;
  mode: string;
}

const DividendPaymentEditPanel = ({
  closePanel,
  dividendPayment,
  mode,
}: IDividendPaymentEditPanelProps): JSX.Element => {
  const theForm = useForm();
  const { handleSubmit } = theForm;
  const { fiscalYearId, companyCode } = useParams<IFiscalYearParams>();
  const intFiscalYear = parseInt(fiscalYearId, 10);
  const mutation =
    mode === 'Edit' ? EDIT_DIVIDEND_PAYMENT_MUTATION : CREATE_DIVIDEND_PAYMENT_MUTATION;
  const [updateDividendPayment, { loading, error }] = useMutation(mutation, {
    onCompleted: (): void => {
      closePanel();
    },
    refetchQueries: [
      {
        query: COMPANY_FISCAL_YEAR_DETAIL_QUERY,
        variables: { companyCode, fiscalYearId: intFiscalYear },
      },
    ],
  });

  const onSubmit = (dividendPaymentInput: IDividendPayment): void => {
    dividendPaymentInput.id = dividendPayment.id;
    dividendPaymentInput.dividendId = dividendPayment.dividendId;
    const submitValue = transformDividendPaymentDto(dividendPaymentInput);

    updateDividendPayment({
      variables: {
        dividendPayment: submitValue,
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={`${mode} Dividend Payment`}
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel={`Close Dividend Payment ${mode} Panel`}
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(onSubmit)}
          saveLabel="Save Dividend Payment"
          saveTitle="Save Dividend Payment"
          cancelLabel={`Cancel Dividend Payment ${mode}`}
          cancelTitle={`Cancel Dividend Payment ${mode}`}
        />
      )}
    >
      <DividendPaymentForm
        mutationLoading={loading}
        mutationError={error}
        dividendPayment={dividendPayment}
        onSubmit={onSubmit}
        theForm={theForm}
      />
    </CoherencePanel>
  );
};

export default DividendPaymentEditPanel;
