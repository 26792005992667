import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ActionButton, DetailsListLayoutMode, Shimmer } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { IProcessTemplateTypeParams } from '../../../common/ParamTypes';
import BackButton from '../../../common/BackButton';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../../app/common/styles/CommonStyleObjects';
import BodyHeaderWithCommandBar from '../../../common/headers/BodyHeaderWithCommandBar';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import { GET_PROCESS_TYPE_WITH_MILESTONE_POOL } from '../../../../utils/statApi/ProcessTypesApi';
import ShimmeredDetailsListWrapper from '../../../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IProcessType } from '../../../../utils/types/IProcessType';
import { IMilestoneTemplate } from '../../../../utils/types/IMilestoneTemplate';
import getMilestoneTemplateColumns from './ProcessTypeMilestonePool.config';
import CanAccess from '../../../common/canAccess/CanAccess';
import ManageMilestoneTemplatePanel from './ManageMilestoneTemplatePanel';
import {
  CREATE_MILESTONE_TEMPLATE,
  UPDATE_MILESTONE_TEMPLATE,
} from '../../../../utils/statApi/MilestoneTemplateApi';
import MilestoneTemplateDetailsPanel from './MilestonePoolDetailsPanel';

const ProcessTypeMilestonePool = (): JSX.Element => {
  const { processTypeId } = useParams<IProcessTemplateTypeParams>();
  interface IProcessTypeWithMilestonePool {
    processTypeWithMilestonePool: IProcessType;
  }

  const { loading, data, error } = useQuery<IProcessTypeWithMilestonePool>(
    GET_PROCESS_TYPE_WITH_MILESTONE_POOL,
    {
      variables: { processTypeId: parseInt(processTypeId, 10) },
    },
  );

  const [currentMilestone, setCurrentMilestone] = useState<IMilestoneTemplate>(null);
  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);

  const { name: processTypeName, milestoneTemplates } = (!loading &&
    data?.processTypeWithMilestonePool) || {
    processTypeWithMilestonePool: { name: '', milestoneTemplates: [] },
  };
  const getHeaderTitle = (): JSX.Element => {
    return <h1>Template Management</h1>;
  };
  const getBackButton = (): JSX.Element => {
    return (
      <BackButton backDepth={2} ariaLabel="Back to process types list">
        {loading && <Shimmer />}
        {!loading && !error && <h2>{processTypeName}</h2>}
      </BackButton>
    );
  };

  const getBodyHeaderTitle = (): JSX.Element => {
    return <h2>Milestone Pool</h2>;
  };

  return (
    <>
      <FullWidthHeader title={getHeaderTitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBackButton} />
        </div>
      </div>
      <LoadingErrorMessage error={error} loading={loading} />
      <div className={bodyContentContainer}>
        <div className="ms-depth-4">
          <div className={bodyContentWrapper}>
            <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
              <CanAccess requestedAction="MilestoneTemplate:Create">
                <ActionButton
                  iconProps={{ iconName: 'Add' }}
                  allowDisabledFocus
                  onClick={(): void => {
                    setIsCreatePanelOpen(!isCreatePanelOpen);
                  }}
                >
                  Add Milestone to Pool
                </ActionButton>
              </CanAccess>
            </BodyHeaderWithCommandBar>
            <ShimmeredDetailsListWrapper
              items={milestoneTemplates}
              layoutMode={DetailsListLayoutMode.justified}
              columns={getMilestoneTemplateColumns(
                setCurrentMilestone,
                setIsEditPanelOpen,
                setIsDetailsPanelOpen,
              )}
              enableShimmer={loading}
              pluralListLabel="Process Type Milestones Pool"
              singularListLabel="Process Type Milestone Pool"
            />
          </div>
        </div>
        {currentMilestone && isEditPanelOpen && (
          <ManageMilestoneTemplatePanel
            closePanel={() => {
              setIsEditPanelOpen(false);
            }}
            MILESTONE_TEMPLATE_MUTATION={UPDATE_MILESTONE_TEMPLATE}
            milestoneTemplate={currentMilestone}
          />
        )}
        {isCreatePanelOpen && (
          <ManageMilestoneTemplatePanel
            closePanel={() => {
              setIsCreatePanelOpen(false);
            }}
            MILESTONE_TEMPLATE_MUTATION={CREATE_MILESTONE_TEMPLATE}
          />
        )}
        {currentMilestone && isDetailsPanelOpen && (
          <MilestoneTemplateDetailsPanel
            milestoneTemplate={currentMilestone}
            closePanel={() => {
              setIsDetailsPanelOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProcessTypeMilestonePool;
