import React, { useState } from 'react';
import {
  ActionButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  PrimaryButton,
  Shimmer,
} from '@fluentui/react';
import { useHistory, useParams } from 'react-router-dom';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import PivotHeader from '../../common/headers/PivotHeader';
import { IPivotHeaderItems } from '../../../utils/types/IPivotHeaderItems';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import CanAccess from '../../common/canAccess/CanAccess';

interface IHeaderProps {
  showBackToListButton?: boolean;
  canCancelRequest?: boolean;
  headerText: string;
  onCancelClick: () => void;
  showDocumentsButton?: boolean;
}

const Header = (props: IHeaderProps): JSX.Element => {
  const { showBackToListButton, canCancelRequest, headerText, onCancelClick, showDocumentsButton } =
    props;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { id } = useParams<ICompanyCreationParams>();
  const history = useHistory();

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Are you sure',
    subText: 'Do you want to cancel this company creation request?',
  };

  const modalPropsStyles = { main: { maxWidth: 450 } };
  const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
  };

  const handleCancelRequest = () => {
    onCancelClick();
    setShowDeleteConfirmation(false);
  };

  const HeaderItems: IPivotHeaderItems = showDocumentsButton
    ? {
        overview: {
          headerText: 'Overview',
          iconName: 'ServerProcesses',
        },
        'company-information': {
          headerText: 'Company Information',
          iconName: 'ServerProcesses',
        },
        documents: {
          headerText: 'Documents',
          iconName: 'People',
        },
      }
    : {
        overview: {
          headerText: 'Overview',
          iconName: 'ServerProcesses',
        },
        'company-information': {
          headerText: 'Company Information',
          iconName: 'ServerProcesses',
        },
      };

  const renderHeaderTitle = (): JSX.Element => {
    if (headerText) {
      return (
        <h1>
          {showBackToListButton && (
            <IconButton
              iconProps={{ iconName: 'Back' }}
              styles={{ root: { marginRight: 10 } }}
              title="Back to company request list"
              ariaLabel="Back to company request list"
              onClick={(): void => {
                history.push(`/company-creation/`);
              }}
            />
          )}

          {`${headerText}`}
        </h1>
      );
    }
    return (
      <Shimmer
        styles={{
          root: { marginBottom: '10px' },
        }}
      />
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <PivotHeader
        getHeaderTitle={renderHeaderTitle}
        pivotHeaderItems={HeaderItems}
        baseUri={`/company-creation/${id}`}
        ariaLabel="Navigation for Company Creation Details"
      />
      <CanAccess requestedAction="CompanyCreation:Cancel">
        <ActionButton
          id="DisabledNewExtensionButton"
          style={{ alignSelf: 'flex-end', marginBottom: 0 }}
          className={`${bodyContentContainer}`}
          disabled={!canCancelRequest}
          iconProps={{ iconName: 'Blocked' }}
          allowDisabledFocus
          onClick={() => {
            setShowDeleteConfirmation(true);
          }}
        >
          Cancel Request
        </ActionButton>
      </CanAccess>

      {showDeleteConfirmation && (
        <Dialog
          hidden={!showDeleteConfirmation}
          onDismiss={() => {
            setShowDeleteConfirmation(false);
          }}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton onClick={handleCancelRequest} text="Yes" />
            <DefaultButton
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
};
export default Header;
