import React, { useContext } from 'react';
import {
  TooltipHost,
  TooltipDelay,
  IconButton,
  ColumnActionsMode,
  Stack,
  ITooltipHostStyles,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import StatDateFormats from '../../../../utils/types/DateFormats';
import momentUtc from '../../../../utils/DateFormatter';
import { IAgmReviewContact } from '../../../../utils/types/IAgmReview';
import { IAgmTrackerPageTrackerSearchResult } from '../../annualFilingRequirements/IAgmListPageSearchResult';
import { IColumnConfig } from '../../../common/lists/ColumnConfigHelper';
import UserContext from '../../../../utils/authorization/UserContext';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import hasAccess from '../../../../utils/authorization/authorizationCheck';

const tooltipHostStyles: Partial<ITooltipHostStyles> = {
  root: { textOverflow: 'ellipsis', overflow: 'hidden' },
};

const onRenderRegionalControllerSignoffDate = (
  item: IAgmTrackerPageTrackerSearchResult,
): JSX.Element => {
  return item.regionalControllerSignoffDate ? (
    <>
      <span>{`${momentUtc(item.regionalControllerSignoffDate, StatDateFormats.DayBreakout)}`}</span>
    </>
  ) : null;
};

const onRenderAgmDueDate = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return (
    <span>
      {item?.agmDueDate ? `${momentUtc(item.agmDueDate, StatDateFormats.DayBreakout)}` : null}
    </span>
  );
};

const onRenderFilingDueDate = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return <span>{`${momentUtc(item.filingDueDate, StatDateFormats.DayBreakout)}`}</span>;
};

const onRenderReportingPeriodEndDate = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return <span>{`${momentUtc(item.reportingPeriodEndDate, StatDateFormats.DayBreakout)}`}</span>;
};

const onRenderTotalAging = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  if (item.totalAging === null) {
    return <span />;
  }
  const totalAgingDays = Math.floor(item.totalAging / 60 / 24);
  return (
    <span>
      {totalAgingDays} {totalAgingDays === 1 ? 'day' : 'days'}
    </span>
  );
};

const onRenderAgingSinceLastChange = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  if (item.aging === null) {
    return <span />;
  }
  return (
    <span>
      {item.aging} {item.aging === 1 ? 'day' : 'days'}
    </span>
  );
};

const onRenderStatus = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return (
    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.status?.fieldName}</span>
  );
};

const onRenderStatusChangeComment = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return (
    <TooltipHost
      delay={TooltipDelay.medium}
      id={item.processId.toString()}
      key={item.processId}
      styles={tooltipHostStyles}
      tooltipProps={{
        onRenderContent: () => {
          return <span>{item.latestStatusChangeAction?.comments ?? ''}</span>;
        },
      }}
    >
      <span>{item.latestStatusChangeAction?.comments ?? ''}</span>
    </TooltipHost>
  );
};

const onRenderStatusChangeDate = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return (
    <span>
      {item.latestStatusChangeAction
        ? `${moment(item.latestStatusChangeAction.actionDate).format(StatDateFormats.DayBreakout)}`
        : ''}
    </span>
  );
};

const onRenderCelaAssignee = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  const names = item?.celaAssignees
    .map((person: IAgmReviewContact) => {
      return person?.text;
    })
    .join(', ');
  return (
    <TooltipHost
      delay={TooltipDelay.medium}
      id={item.processId.toString()}
      key={item.processId}
      styles={tooltipHostStyles}
      tooltipProps={{
        onRenderContent: () => {
          return <span>{names}</span>;
        },
      }}
    >
      <span>{names}</span>
    </TooltipHost>
  );
};

const RenderAgmReviewOrDetailsLink = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  const { agmReviewId, fiscalYearId, companyCode } = item;

  if (agmReviewId) {
    return (
      <Link to={`/companies/${companyCode}/agm-review/${agmReviewId}`} target="_self">
        AGM Details
      </Link>
    );
  }
  if (fiscalYearId) {
    return (
      <Link to={`/companies/${companyCode}/fiscal-years/${fiscalYearId}`} target="_self">
        AGM Details
      </Link>
    );
  }
  return <></>;
};

const onRenderProcessLink = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return (
    <Stack
      tokens={{ padding: 10, childrenGap: 10 }}
      styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}
    >
      <TooltipHost
        delay={TooltipDelay.medium}
        id={item.companyName}
        key={item.companyName}
        styles={tooltipHostStyles}
        tooltipProps={{
          onRenderContent: () => {
            return (
              <div>
                <strong>{`${item.companyName} - ${item.companyCode}`}</strong>
              </div>
            );
          },
        }}
      >
        <Link
          to={`/companies/${item.companyCode}/processes/annual-financial-statements/${item.processId}`}
        >
          {`${item.companyName} - ${item.companyCode}`}
        </Link>
      </TooltipHost>
      <TooltipHost
        delay={TooltipDelay.medium}
        styles={tooltipHostStyles}
        tooltipProps={{
          onRenderContent: () => {
            return (
              <div>
                <strong>AGM Details</strong>
              </div>
            );
          },
        }}
      >
        {RenderAgmReviewOrDetailsLink(item)}
      </TooltipHost>
    </Stack>
  );
};

const onRenderActionsButton = (
  item: IAgmTrackerPageTrackerSearchResult,
  toggleEditPanel: (item?: IAgmTrackerPageTrackerSearchResult) => void,
  toggleCommentsPanel: (item?: IAgmTrackerPageTrackerSearchResult) => void,
): JSX.Element => {
  return (
    <>
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        title="Edit Item"
        ariaLabel="Edit Item"
        onClick={(): void => {
          toggleEditPanel(item);
        }}
      />
      <IconButton
        iconProps={{ iconName: 'CannedChat' }}
        title="Comments"
        ariaLabel="Comments"
        onClick={(): void => {
          toggleCommentsPanel(item);
        }}
      />
    </>
  );
};

const onRenderOwner = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  let owner = '';
  if (item.owner === 'CELA') {
    owner = 'CELA';
  } else if (item.owner === 'CONTROLLER') {
    owner = 'Controller';
  }
  return <span>{owner}</span>;
};

const onRenderOwnerChangeComment = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return (
    <TooltipHost
      delay={TooltipDelay.medium}
      id={item.processId.toString()}
      key={item.processId}
      styles={tooltipHostStyles}
      tooltipProps={{
        onRenderContent: () => {
          return (
            <span>
              <strong>
                {moment(item?.latestOwnerChangeAction?.actionDate).format(
                  StatDateFormats.DaySimple,
                )}
                :{' '}
              </strong>
              {item?.latestOwnerChangeAction?.comments}
            </span>
          );
        },
      }}
    >
      {item?.latestOwnerChangeAction?.comments ? (
        <span>
          <strong>
            {moment(item?.latestOwnerChangeAction?.actionDate).format(StatDateFormats.DaySimple)}:{' '}
          </strong>
          {item?.latestOwnerChangeAction?.comments}
        </span>
      ) : (
        ''
      )}
    </TooltipHost>
  );
};

const onRenderComments = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  const commentAction = item.agmTrackerActions?.find((action) => action.actionType === 'COMMENT');

  return (
    <TooltipHost
      delay={TooltipDelay.medium}
      id={item.processId.toString()}
      key={item.processId}
      styles={tooltipHostStyles}
      tooltipProps={{
        onRenderContent: () => {
          return (
            <span>
              <strong>
                {moment(commentAction?.actionDate).format(StatDateFormats.DaySimple)}:{' '}
              </strong>
              {commentAction?.comments}
            </span>
          );
        },
      }}
    >
      {commentAction?.comments ? (
        <span>
          <strong>{moment(commentAction?.actionDate).format(StatDateFormats.DaySimple)}: </strong>
          {commentAction?.comments}
        </span>
      ) : (
        ''
      )}
    </TooltipHost>
  );
};

const onRenderCountry = (item: IAgmTrackerPageTrackerSearchResult): JSX.Element => {
  return <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.country}</span>;
};

const getAgmTrackerColumns = (
  toggleEditPanel: (item?: IAgmTrackerPageTrackerSearchResult) => void,
  toggleCommentsPanel: (item?: IAgmTrackerPageTrackerSearchResult) => void,
): Array<IColumnConfig> => {
  const user: StatUser = useContext(UserContext);

  const userHasEditAccess = hasAccess(user.permissions, 'AgmTracker:Edit');

  return [
    {
      key: 'actions',
      name: 'Actions',
      active: userHasEditAccess,
      minWidth: 55,
      maxWidth: 55,
      onRender: (item: IAgmTrackerPageTrackerSearchResult) => {
        return onRenderActionsButton(item, toggleEditPanel, toggleCommentsPanel);
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'companyName',
      name: 'Company',
      active: true,
      minWidth: 250,
      maxWidth: 250,
      onRender: onRenderProcessLink,
      isResizable: true,
    },
    {
      key: 'statController',
      fieldName: 'statController',
      active: true,
      name: 'Stat Controller',
      minWidth: 100,
      maxWidth: 140,
      isResizable: true,
      isCollapsible: true,
    },
    {
      key: 'country',
      name: 'Country',
      active: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: onRenderCountry,
      isResizable: true,
    },
    {
      key: 'reportingPeriodEndDate',
      name: 'Reporting Period End Date',
      active: true,
      minWidth: 135,
      maxWidth: 135,
      onRender: onRenderReportingPeriodEndDate,
      isResizable: true,
    },
    {
      key: 'agmDueDate',
      name: 'AGM Due Date',
      active: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: onRenderAgmDueDate,
      isResizable: true,
    },
    {
      key: 'totalAging',
      name: 'Aging',
      minWidth: 100,
      active: true,
      maxWidth: 100,
      onRender: onRenderTotalAging,
      isResizable: true,
    },
    {
      key: 'aging',
      name: 'Aging Since Change',
      minWidth: 100,
      active: true,
      maxWidth: 100,
      onRender: onRenderAgingSinceLastChange,
      isResizable: true,
    },
    {
      key: 'filingDueDate',
      name: 'Filing Due Date',
      active: true,
      minWidth: 135,
      maxWidth: 135,
      onRender: onRenderFilingDueDate,
      isResizable: true,
    },
    {
      key: 'status.fieldName',
      name: 'Status',
      minWidth: 100,
      active: true,
      maxWidth: 140,
      onRender: onRenderStatus,
      isResizable: true,
      isCollapsible: true,
    },
    {
      key: 'statusChangeComment',
      name: 'Status Change Reason',
      minWidth: 170,
      active: true,
      maxWidth: 170,
      onRender: onRenderStatusChangeComment,
      isResizable: true,
      isCollapsible: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'latestStatusChangeAction.actionDate',
      name: 'Status Change Date',
      minWidth: 135,
      active: true,
      maxWidth: 135,
      onRender: onRenderStatusChangeDate,
      isResizable: true,
      isCollapsible: true,
    },
    {
      key: 'firstCelaAssignee',
      name: 'CELA Assignee',
      active: true,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRender: onRenderCelaAssignee,
    },
    {
      key: 'owner',
      name: 'Owner',
      active: true,
      onRender: onRenderOwner,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'latestOwnerChangeAction.actionDate',
      name: 'Owner Change Reason',
      active: true,
      onRender: onRenderOwnerChangeComment,
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
    },
    {
      key: 'latestCommentAction.actionDate',
      name: 'Latest Comment',
      ariaLabel: 'Latest Comment',
      active: true,
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onRender: onRenderComments,
    },
    {
      key: 'regionalControllerSignoffDate',
      name: 'Regional Controller Signoff Date',
      active: true,
      minWidth: 135,
      maxWidth: 135,
      onRender: onRenderRegionalControllerSignoffDate,
      isResizable: true,
    },
  ];
};
export default getAgmTrackerColumns;
