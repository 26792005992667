import React, { useContext, useState } from 'react';
import { Label, Stack, TextField } from '@fluentui/react';
import { Controller, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { IProcessExtensionRequest } from '../Interfaces';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import momentUtc from '../../../../utils/DateFormatter';
import FileSelectorItem from '../../../common/formFields/fileUpload/fileSelectorItem';
import StatDateFormats from '../../../../utils/types/DateFormats';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import UserContext from '../../../../utils/authorization/UserContext';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import { GET_PROCESS_EXTENSION_REASONS } from '../../../../utils/statApi/ProcessExtensionApi';
import FileInputTextbox from '../../../common/formFields/fileUpload/FileInputTextbox';
import { IDocument } from '../../../../utils/statApi/DocumentApi';

export interface IProcessExtensionRequestFormProps {
  processExtensionRequest: IProcessExtensionRequest;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

const ProcessExtensionRequestForm = ({
  processExtensionRequest,
  form,
}: IProcessExtensionRequestFormProps): JSX.Element => {
  const [documentName, setDocumentName] = useState('');
  const [fileValidationError, setFileValidationError] = useState(null);
  const { control, errors, clearErrors, setValue, getValues, register } = form;
  const user: StatUser = useContext(UserContext);
  const requiredStyles = { color: '#A4262C' };
  const headerStyles = { marginTop: 15, marginBottom: 5 };

  const attachReviewDocuments = async (files: IDocument[]): Promise<void> => {
    const previousDocuments = getValues('reviewDocuments') ?? [];
    setValue('reviewDocuments', previousDocuments.concat(files[0]));
    clearErrors('reviewDocuments');
    setDocumentName('');
  };

  const watchExtensionReason = useWatch({
    control,
    name: 'extensionReason',
    defaultValue: processExtensionRequest.extensionReason,
  });

  const renderDocumentList = (): JSX.Element => {
    const reviewDocuments = getValues('reviewDocuments');
    return reviewDocuments ? (
      reviewDocuments
        .filter((doc: IDocument) => !doc.isDeleted)
        .map((document: IDocument, index: number) => {
          return (
            <Stack key={document.url}>
              <FileSelectorItem
                url={document.url}
                index={index}
                user={user}
                fileName={document.title}
                allowDownload
                allowViewInTab
                key={document.url}
                uiDisabled
                onRemoveFromSelected={() => {}}
                activity={`Uploaded ${moment(document.uploadDate).format(
                  StatDateFormats.DayBreakout,
                )}`}
              />
            </Stack>
          );
        })
    ) : (
      <span>There have not been any documents uploaded yet.</span>
    );
  };

  const validateComments = (newVal: string): string => {
    if (!newVal && watchExtensionReason?.fieldName === 'Other') {
      return "Comments are required when Extension Reason is 'Other'";
    }
    return null;
  };

  const ProcessExtensionReasonDropdown = (props: IFieldsetProps): JSX.Element => {
    const {
      value,
      label,
      errors: reasonErrors,
      handleChange,
      valueKey,
      outputKey,
      required,
      disabled,
    } = props;
    const { loading, data } = useQuery(GET_PROCESS_EXTENSION_REASONS);
    const { processExtensionReasons } = !loading && data ? data : [];

    return (
      <QueryBasedDropdown
        required={required}
        disabled={disabled}
        value={value as IDropdownReference}
        label={label}
        outputKey={outputKey}
        dropdownOptionsData={processExtensionReasons}
        errors={reasonErrors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack horizontal tokens={{ childrenGap: 15 }}>
        <UtcDatePicker
          label="Current Filing Due Date"
          disabled
          value={momentUtc(processExtensionRequest?.process?.filingDueDate)}
        />

        <Controller
          name="requestedDate"
          control={control}
          defaultValue={processExtensionRequest.requestedDate}
          render={({ value }): React.ReactElement => (
            <UtcDatePicker
              label="Requested Filing Due Date"
              isRequired
              allowTextInput={false}
              disableAutoFocus={false}
              value={momentUtc(value)}
              onSelectDate={(utcDateString: string): void => {
                setValue('requestedDate', utcDateString);
              }}
              minDate={moment(new Date(momentUtc(processExtensionRequest?.process?.filingDueDate)))
                .add(1, 'days')
                .toDate()}
            />
          )}
          errorMessage={errors?.requestedDate?.message}
          rules={{
            required: 'Please provide a new date',
          }}
        />
      </Stack>

      <Controller
        render={({ value }) => (
          <ProcessExtensionReasonDropdown
            label="Reason for Extension"
            valueKey="extensionReason"
            value={value}
            required
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleChange={(newValue: any, valueKey: string): void => {
              setValue('extensionReason', newValue);
            }}
            errors={errors}
          />
        )}
        name="extensionReason"
        value={processExtensionRequest.extensionReason}
        control={control}
        defaultValue={processExtensionRequest.extensionReason}
        rules={{
          required: 'Extension Reason is required',
        }}
      />

      <Controller
        as={TextField}
        id="comments"
        label="Comments"
        name="comments"
        required={watchExtensionReason?.fieldName === 'Other'}
        min={0}
        defaultValue={processExtensionRequest.comments}
        control={control}
        rules={{ validate: validateComments }}
        errorMessage={errors?.comments?.message}
      />

      <input type="hidden" ref={register()} name="id" defaultValue={processExtensionRequest.id} />
      <input
        type="hidden"
        ref={register()}
        name="processId"
        defaultValue={processExtensionRequest.process.id}
      />
      {!(processExtensionRequest?.id > 0) && (
        <>
          <Controller
            name="reviewDocuments"
            label="Documents"
            required
            control={control}
            defaultValue={null}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <>
                <Label>
                  Documents
                  <span style={requiredStyles}> *</span>
                </Label>
                <FileInputTextbox
                  inputValue={documentName}
                  setInputValue={setDocumentName}
                  onFileUploadComplete={attachReviewDocuments}
                  errorMessage={errors?.reviewDocuments?.message || fileValidationError}
                  setValidationError={setFileValidationError}
                  multipleFiles={false}
                />
              </>
            )}
            rules={{
              required: 'Documents are required',
            }}
          />
          <h3 style={headerStyles}>Files Uploaded:</h3>
          <Stack tokens={{ childrenGap: 10 }}>{renderDocumentList()}</Stack>
        </>
      )}
    </Stack>
  );
};

export default ProcessExtensionRequestForm;
