import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { SearchBox, Announced, Stack, MessageBar, MessageBarType } from '@fluentui/react';
import CompaniesList from './CompaniesList';
import {
  bodyContentContainer,
  searchBoxStyles,
} from '../../../app/common/styles/CommonStyleObjects';
import Subheader from '../../common/headers/Subheader';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import FilterCallout from '../../common/callout/FilterCallout';
import { getTotalPageCount } from '../../common/DetailsLists/pagination/DetailsListPaginationBanner';
import DetailListPaginationBannerRefs from '../../common/DetailsLists/pagination/DetailsListPaginationBannerRefs';
import { IPaginationMetadataRefs } from '../../common/DetailsLists/pagination/IPaginationMetadata';
import { GetCompaniesByFilter } from '../../../utils/statApi/CompaniesApi';
import { IPaginationSearch } from '../../processes/processSearch/IProcessSearchResult';
import CompaniesListFilter from './CompaniesListFilter';
import useDebounce from '../../../utils/hooks/UseDebounce';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';

export interface ICompaniesSearchState {
  keyword: string;
  companyCodes: string[];
  countryCodes: string[];
  entityStatusIds: number[];
  statutoryYearEndMonths: number[];
  statutoryControllerIds: number[];
  secRegionAreaCodes: string[];
}
const initialState: ICompaniesSearchState = {
  keyword: '',
  companyCodes: [],
  countryCodes: [],
  entityStatusIds: [],
  statutoryYearEndMonths: [],
  statutoryControllerIds: [],
  secRegionAreaCodes: [],
};
const CompaniesListPage = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [searchState, setSearchState] = useState<ICompaniesSearchState>(initialState);
  const pageCount = React.useRef<number>(0);
  const selectedPage = React.useRef<number>(1);
  const defaultPageSize = React.useRef<number>(25);
  const [isInputError, setIsInputError] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [companyIdMessage, setCompanyIdMessage] = useState<string>();
  const debouncedSearchTerm = useDebounce(
    searchTerm === null || searchTerm === undefined ? '' : searchTerm,
    500,
  );
  const [fetchCompanies, { data, loading, error }] = useLazyQuery(GetCompaniesByFilter, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companySearchDto: searchState,
      paginationDto: {
        currentPage: selectedPage.current,
        pageSize: defaultPageSize.current,
      },
    },
  });

  const companies = data?.companies.items || [];
  const totalRecordCount: number = data?.companies?.totalCount;
  const queryString = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const stateWithParsedQueryStrings = {
      keyword: debouncedSearchTerm,
      companyCodes: queryString.getAll('companyCodes'),
      countryCodes: queryString.getAll('countryCodes'),
      entityStatusIds: queryString.getAll('entityStatusIds').map(Number),
      statutoryYearEndMonths: queryString.getAll('statutoryYearEndMonths').map(Number),
      statutoryControllerIds: queryString.getAll('statutoryControllerIds').map(Number),
      secRegionAreaCodes: queryString.getAll('secRegionAreaCodes'),
    } as ICompaniesSearchState;

    setSearchState(stateWithParsedQueryStrings);

    const pageSize = queryString.get('pageSize') ?? defaultPageSize.current;
    const currentPage = queryString.get('currentPage') ?? 1;

    selectedPage.current = parseInt(currentPage.toString(), 10);
    defaultPageSize.current = parseInt(pageSize.toString(), 10);

    const companyId = queryString.get('companyIds');
    if (companyId) {
      setCompanyIdMessage(
        `The link you are using is outdated. Please refresh your link by reselecting the filters.`,
      );
    } else {
      setCompanyIdMessage(null);
    }

    fetchCompanies({
      variables: {
        companySearchDto: stateWithParsedQueryStrings,
        paginationDto: {
          currentPage: selectedPage.current,
          pageSize: defaultPageSize.current,
        },
      },
    });
  }, [location, debouncedSearchTerm]);

  const renderHeader = (): JSX.Element => <h1>Companies</h1>;

  const isValidSearchTerm = (value: string): boolean => value !== '' && value.length > 2;

  const handleOnSearchExecuted = (newValue: string): void => {
    if (!isValidSearchTerm(newValue)) setIsInputError(true);
  };

  const onUpdateUrl = (newState: ICompaniesSearchState, paginationData: IPaginationSearch) => {
    const newUrlObject = new URLSearchParams();

    Object.entries(newState).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        if (v.length > 0) {
          v.forEach((value) => {
            newUrlObject.append(k as string, value);
          });
        }
      }
    });

    newUrlObject.append('pageSize', paginationData?.pageSize?.toString());
    newUrlObject.append('currentPage', paginationData?.currentPage?.toString());

    history.replace(location.pathname.concat(`?${newUrlObject.toString()}`));
  };

  const resetPaginationAndUrl = (newState: ICompaniesSearchState): void => {
    selectedPage.current = 1;
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(newState, paginationData);
  };

  const onResetFiltersClick = (): void => {
    const emptyState = initialState;
    setSearchState(emptyState);
    resetPaginationAndUrl(emptyState);
  };

  const onPageSizeChange = (newPageSize: string | number): void => {
    defaultPageSize.current = newPageSize as number;
    selectedPage.current = 1;

    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };

  const onPageChange = (
    startItemIndex: number,
    endItemIndex: number,
    newPageNumber: number,
  ): void => {
    selectedPage.current = newPageNumber;
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };
  pageCount.current = getTotalPageCount(totalRecordCount, defaultPageSize.current);

  const paginationMetadata: IPaginationMetadataRefs = {
    pageCountRef: pageCount,
    selectedPageRef: selectedPage,
    pageSizeRef: defaultPageSize,
    onPageChange,
    onPageSizeChange,
  };

  const CompanyIdMessageBar = (): JSX.Element | null => {
    if (companyIdMessage) {
      return (
        <MessageBar messageBarType={MessageBarType.info}>
          <span>{companyIdMessage}</span>
        </MessageBar>
      );
    }
    return null;
  };

  const handleOnChangeSearch = (
    event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string,
  ): void => {
    setIsInputError(false);
    const searchText = newValue ? newValue.trim() : '';

    if (isValidSearchTerm(searchText)) {
      setSearchTerm(searchText);
    } else if (searchText.length === 0) {
      setSearchTerm('');
    }
    resetPaginationAndUrl({ ...searchState, keyword: searchText });
  };

  const onFieldChange = (propertyName: keyof ICompaniesSearchState, value: number[] | string[]) => {
    const newState = { ...searchState, [propertyName]: value };
    setSearchState(newState);

    resetPaginationAndUrl(newState);
  };
  const renderSubtitle = (): JSX.Element => {
    return (
      <>
        <Subheader title="Company Management">
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <SearchBox
              ariaLabel="search companies"
              styles={searchBoxStyles}
              placeholder="Search companies"
              onChange={handleOnChangeSearch}
              onSearch={handleOnSearchExecuted}
            />
            <FilterCallout triggerButtonId="CompanySearchFilter" triggerIconName="Filter">
              <CompaniesListFilter
                onResetFiltersClick={onResetFiltersClick}
                filterState={searchState}
                onFieldChange={onFieldChange}
              />
            </FilterCallout>
          </Stack>
        </Subheader>
        {searchTerm && (
          <Announced
            message={`${companies?.length} ${
              companies?.length === 1 ? 'company' : 'companies'
            } found`}
          />
        )}
      </>
    );
  };

  const messageBarText = (): string => {
    if (isInputError) {
      return 'Please include at least 3 characters in search input.';
    }
    return 'No results found. Please try again.';
  };
  const NoResultsMessageBar = (): JSX.Element => {
    if ((companies?.length === 0 && !loading) || isInputError) {
      return (
        <MessageBar messageBarType={isInputError ? MessageBarType.warning : MessageBarType.info}>
          <span>{messageBarText()}</span>
        </MessageBar>
      );
    }
    return <></>;
  };
  return (
    <>
      <CompanyIdMessageBar />
      <FullWidthHeader title={renderHeader} subtitle={renderSubtitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <LoadingErrorMessage loading={loading} error={error} actionName="loading companies list" />
        <NoResultsMessageBar />
        <CompaniesList items={companies} isLoading={loading} />
        {paginationMetadata && <DetailListPaginationBannerRefs {...paginationMetadata} />}
      </div>
    </>
  );
};

export default CompaniesListPage;
