import React from 'react';
import { Stack, Link } from '@fluentui/react';
import { copyrightStyle, privacyLinkStyle, FooterStyles } from './StatFooterStyles';

const StatFooter = (): JSX.Element => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Stack
        styles={FooterStyles}
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 86 }}
      >
        <Link
          href="http://go.microsoft.com/fwlink/?LinkId=518021"
          target="_blank"
          rel="noopener noreferrer"
          styles={privacyLinkStyle}
        >
          Microsoft Data Privacy Notice
        </Link>
        <span className={copyrightStyle}>{`© ${currentYear} Microsoft`}</span>
      </Stack>
    </footer>
  );
};
export default StatFooter;
