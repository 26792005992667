import { useQuery } from '@apollo/client';
import { ComboBox, IComboBox, IComboBoxOption, Spinner, getId } from '@fluentui/react';
import React, { useState } from 'react';
import GET_ACQUISITION_QUERY from '../../utils/statApi/AcquisitionApi';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { IAcquisition } from './types';

interface IAcquistionProps {
  label: string;
  disabled: boolean;
  onChange: (value: IComboBoxOption) => void;
  required: boolean;
  errorMessage?: string;
  defaultValue?: IAcquisition;
}

const AcquisitionSelector = ({
  label,
  disabled,
  onChange,
  required,
  errorMessage,
  defaultValue,
}: IAcquistionProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState([]);

  const { loading, error } = useQuery(GET_ACQUISITION_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setOptions(data.acquisitions);
      if (defaultValue) {
        setSelectedValue(defaultValue.key);
      }
    },
  });

  const onUpdate = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
  ): void => {
    let newValue: IComboBoxOption;
    if (option?.key) {
      newValue = option;
      setSelectedValue(option.key);
    } else if (value.trim()) {
      const newOption = { key: getId(), text: value.trim() };
      setOptions((prevOptions) => [...prevOptions, newOption]);
      setSelectedValue(newOption.key);
      newValue = newOption;
    } else {
      setSelectedValue(null);
      newValue = null;
    }

    onChange(newValue);
  };

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      {loading && <Spinner />}
      {!loading && !error && (
        <ComboBox
          selectedKey={selectedValue}
          label={label}
          disabled={disabled}
          allowFreeform
          autoComplete="on" // (╯°□°）╯︵ ┻━┻
          options={options}
          required={required}
          errorMessage={errorMessage}
          onChange={onUpdate}
        />
      )}
    </>
  );
};

export default AcquisitionSelector;
