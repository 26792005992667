import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  CREATE_PROCESS_TEMPLATE_MUTATION,
  GET_PROCESS_TEMPLATE_BY_TYPE,
} from '../../../utils/statApi/ProcessTemplateApi';

import { IProcessTemplate, IProcessTemplateInput } from '../../../utils/types/IProcessTemplate';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IProcessTemplateTypeParams } from '../../common/ParamTypes';
import ProcessTemplateEditForm from '../ProcessTemplateEditForm';

interface IProcessTemplateAddPanelProps {
  closePanel: () => void;
}

const ProcessTemplateAddPanel = ({ closePanel }: IProcessTemplateAddPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit } = form;
  const { processTypeId } = useParams<IProcessTemplateTypeParams>();

  const processTemplate = {
    name: '',
    description: '',
  } as IProcessTemplate;

  const [createProcessTemplate, { loading, error }] = useMutation(
    CREATE_PROCESS_TEMPLATE_MUTATION,
    {
      onCompleted: (): void => {
        closePanel();
      },
      refetchQueries: [
        {
          query: GET_PROCESS_TEMPLATE_BY_TYPE,
          variables: {
            processTypeId: parseInt(processTypeId, 10),
            isActiveFilter: true,
          },
        },
      ],
    },
  );

  const onSubmit = (processTemplateInput: IProcessTemplateInput): void => {
    const processTemplateInputDto: IProcessTemplateInput = {
      id: -1,
      name: processTemplateInput.name,
      description: processTemplateInput.description,
      processTypeId: parseInt(processTypeId, 10),
    };

    createProcessTemplate({
      variables: {
        processTemplateInput: processTemplateInputDto,
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Add Process Template"
      isOpen
      hasCloseButton
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Add Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(onSubmit)}
          saveTitle="Save Process Template"
          saveLabel="Save Process Template"
          cancelTitle="Cancel Process Template Add"
          cancelLabel="Cancel Process Template Add"
        />
      )}
    >
      <LoadingErrorMessage error={error} loading={loading} />
      <ProcessTemplateEditForm
        mutationLoading={loading}
        mutationError={error}
        processTemplate={processTemplate}
        onSubmit={onSubmit}
        form={form}
      />
    </CoherencePanel>
  );
};

export default ProcessTemplateAddPanel;
