import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Stack, TextField } from '@fluentui/react';
import MonthDayPicker from '../../../common/formFields/monthDayPicker/MonthDayPicker';
import hasAccess from '../../../../utils/authorization/authorizationCheck';
import { IAgmStatusDueDateAndInfoFormProps } from './IAgmStatusFormProps';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import UserContext from '../../../../utils/authorization/UserContext';

const AgmStatusDueDateAndInfoForm = ({
  agmStatus,
  form,
}: IAgmStatusDueDateAndInfoFormProps): JSX.Element => {
  const user: StatUser = useContext(UserContext);
  const { permissions } = user;
  const { errors, control } = form;

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <MonthDayPicker
        dueDateDay={agmStatus?.agmDueDateDay}
        dueDateMonth={agmStatus?.agmDueDateMonth}
        form={form}
        dayFormName="agmDueDateDay"
        monthFormName="agmDueDateMonth"
        label="Due Date"
        required
        disabled={!hasAccess(permissions, 'CelaAgmStatus:Update')}
      />
      <Controller
        as={TextField}
        id="agmInformation"
        name="agmInformation"
        label="AGM Information"
        control={control}
        multiline
        defaultValue={agmStatus?.agmInformation || ''}
        errorMessage={errors?.agmInformation?.message}
        disabled={!hasAccess(permissions, 'CelaAgmStatus:Update')}
      />
    </Stack>
  );
};

export default AgmStatusDueDateAndInfoForm;
