import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReportsListPage from './ReportsListPage';

const ReportsRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/reports-dashboard" exact component={ReportsListPage} />
      <Route path="/reports-dashboard/:reportId" component={ReportsListPage} />
    </Switch>
  );
};

export default ReportsRoute;
