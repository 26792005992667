import React, { useState } from 'react';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { DashboardCard, IDashboardFilters, IFCWFilters } from 'fcw-dashboard-components';
import { Dropdown, IDropdownOption, ITag, Stack, Toggle } from '@fluentui/react';
import StatProcessTile from './StatProcessTile';
import CanAccess from '../../common/canAccess/CanAccess';
import UnauthorizedPage from '../../authentication/UnauthorizedPage';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import ProcessSearchCompanyPicker from '../../processes/processSearch/ProcessSearchCompanyPicker';
import UtcDatePicker from '../../common/utcDatePicker/UtcDatePicker';

interface ITilePageState {
  showMyItems: boolean;
  companyCodes: string[];
  filterByPeriod?: boolean;
  dueDateRangeStart?: string;
  dueDateRangeEnd?: string;
  fiscalPeriod?: number;
  fiscalYear?: number;
}

const TilePage = (): JSX.Element => {
  const supportedFilters = [
    IFCWFilters.CompanyCodes,
    IFCWFilters.forMeOnly,
    IFCWFilters.dueDateRange,
    IFCWFilters.fiscalPeriod,
    IFCWFilters.OpsDetails,
  ];
  const [filterState, setFilterState] = useState<ITilePageState>({
    showMyItems: false,
    companyCodes: [],
    filterByPeriod: false,
    fiscalPeriod: 7,
    fiscalYear: 2023,
    dueDateRangeStart: '2023-07-01',
    dueDateRangeEnd: '2023-07-31',
  });

  const filters = {
    dueDateRangeStart: filterState.dueDateRangeStart,
    dueDateRangeEnd: filterState.dueDateRangeEnd,
    filterByPeriod: filterState.filterByPeriod,
    fiscalPeriod: filterState.fiscalPeriod,
    fiscalYear: filterState.fiscalYear,
    forMeOnly: filterState.showMyItems,
    companyCodes: filterState.companyCodes,
  } as IDashboardFilters;

  const toggleMyItems = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
    setFilterState({ ...filterState, showMyItems: value });
  };

  const onCompanyUpdate = (items: ITag[]) => {
    setFilterState({
      ...filterState,
      companyCodes: items.map((item) => item.name.split(' ')[0]),
    });
  };

  const onStartDateUpdate = (dateString: string): void => {
    setFilterState({
      ...filterState,
      dueDateRangeStart: dateString,
    });
  };

  const onEndDateUpdate = (dateString: string): void => {
    setFilterState({
      ...filterState,
      dueDateRangeEnd: dateString,
    });
  };

  const toggleFilterByPeriod = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
    setFilterState({ ...filterState, filterByPeriod: value });
  };

  const onFiscalPeriodUpdate = (e: React.FormEvent<HTMLDivElement>, newValue: IDropdownOption) => {
    setFilterState({ ...filterState, fiscalPeriod: newValue.key as number });
  };

  const onFiscalYearUpdate = (e: React.FormEvent<HTMLDivElement>, newValue: IDropdownOption) => {
    setFilterState({ ...filterState, fiscalYear: newValue.key as number });
  };

  const FiscalPeriodValues = [
    { key: 1, text: 'July (P1)' },
    { key: 2, text: 'August (P2)' },
    { key: 3, text: 'September (P3)' },
    { key: 4, text: 'October (P4)' },
    { key: 5, text: 'November (P5)' },
    { key: 6, text: 'December (P6)' },
    { key: 7, text: 'January (P7)' },
    { key: 8, text: 'February (P8)' },
    { key: 9, text: 'March (P9)' },
    { key: 10, text: 'April (P10)' },
    { key: 11, text: 'May (P11)' },
    { key: 12, text: 'June (P12)' },
  ];

  const FiscalYearValues = [
    { key: 2020, text: '2020' },
    { key: 2021, text: '2021' },
    { key: 2022, text: '2022' },
    { key: 2023, text: '2023' },
    { key: 2024, text: '2024' },
  ];

  return (
    <div className={bodyContentContainer}>
      <Stack verticalAlign="center" horizontalAlign="start" tokens={{ childrenGap: 10 }}>
        <Toggle label="Show My Items" inlineLabel onChange={toggleMyItems} />
        <ProcessSearchCompanyPicker
          initialSelectedCodes={filterState.companyCodes}
          onCompanyUpdate={onCompanyUpdate}
        />
        <Stack tokens={{ childrenGap: 10 }}>
          <Toggle
            label="Switch to fiscal period selector"
            inlineLabel
            onText="on"
            offText="off"
            defaultChecked={filterState.filterByPeriod}
            onChange={toggleFilterByPeriod}
          />
          {!filterState.filterByPeriod && (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
              <UtcDatePicker
                label="Due Date From"
                value={filterState.dueDateRangeStart}
                onSelectDate={onStartDateUpdate}
                allowTextInput
              />
              <UtcDatePicker
                label="Due Date To"
                value={filterState.dueDateRangeEnd}
                onSelectDate={onEndDateUpdate}
                allowTextInput
              />
            </Stack>
          )}
          {filterState.filterByPeriod && (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
              <Dropdown
                id="fiscalPeriod"
                label="Fiscal Period"
                placeholder="Select One"
                options={FiscalPeriodValues}
                required
                defaultSelectedKey={filterState.fiscalPeriod}
                onChange={onFiscalPeriodUpdate}
              />
              <Dropdown
                id="fiscalYear"
                label="Fiscal Year"
                placeholder="Select One"
                options={FiscalYearValues}
                required
                defaultSelectedKey={filterState.fiscalYear}
                onChange={onFiscalYearUpdate}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Context.Provider
        value={{
          filters,
        }}
      >
        <DashboardCard
          title="Stat"
          subTitle="Process Summary"
          filters={filters}
          supportedFilters={supportedFilters}
        >
          <CanAccess
            requestedAction="ProcessStatusSummary:View"
            notAuthorizedChildren={<UnauthorizedPage useCardStyle />}
          >
            <StatProcessTile />
          </CanAccess>
        </DashboardCard>
      </Context.Provider>
    </div>
  );
};

export default TilePage;
