import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { Link } from 'react-router-dom';
import IProcessConfig from '../../../../utils/types/IProcessConfig';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import {
  reportingPeriodEndDateMaxDate,
  filingDueDateDefaultDate,
  filingDueDateMinDate,
  reportingPeriodEndDateDefaultDate,
} from './CreateProcessConfigUtils';
import InitialReportingPeriodDropdown from './InitialReportingPeriodDropdown';

interface IInitialProcessDatesProps {
  companyCode: string;
  reportingPeriodYear: number;
  reportingPeriodEndDate: string;
  statutoryYearEnd: string;
  auditFilingDueDate: string;
  filingDueDate: string;
  setReportingPeriodYear: (newValue: number) => void;
  setReportingPeriodEndDate: (newValue: string) => void;
  setFilingDueDate: (newValue: string) => void;
  showDateRangeSelector: boolean;
  dirtyFields: DeepMap<IProcessConfig, true>;
  errors: DeepMap<IProcessConfig, FieldError>;
  control: Control<IProcessConfig>;
  isAnnualFinancialStatements: boolean;
}
const InitialProcessDates = (props: IInitialProcessDatesProps): JSX.Element => {
  const {
    companyCode,
    reportingPeriodYear,
    reportingPeriodEndDate,
    statutoryYearEnd,
    auditFilingDueDate,
    filingDueDate,
    setReportingPeriodYear,
    setReportingPeriodEndDate,
    setFilingDueDate,
    control,
    dirtyFields,
    errors,
    showDateRangeSelector,
    isAnnualFinancialStatements,
  } = props;

  const messageBarStyle = { root: { marginBottom: '5px' } };
  const missingDefaults =
    isAnnualFinancialStatements && (statutoryYearEnd === null || auditFilingDueDate === null);
  const missingBothDatesString =
    statutoryYearEnd === null && auditFilingDueDate === null ? ' and ' : '';
  const missingStatutoryString = statutoryYearEnd === null ? 'Statutory Year End' : '';
  const missingFilingDateString =
    auditFilingDueDate === null ? 'Financial Statement Filing Date' : '';
  return (
    <>
      {missingDefaults && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline styles={messageBarStyle}>
          <>
            {`Please ensure that the Company has a  ${missingStatutoryString}${missingBothDatesString}${missingFilingDateString} prior to
          creating an Annual Financial Statements process`}
            {auditFilingDueDate === null && (
              <>
                <br />
                <br />
                <Link to={`/companies/${companyCode}/annual-filing-requirements`}>
                  Add {missingFilingDateString}
                </Link>
              </>
            )}
          </>
        </MessageBar>
      )}
      {showDateRangeSelector && (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Stack.Item grow>
            <InitialReportingPeriodDropdown
              disabled={missingDefaults}
              statutoryYearEnd={statutoryYearEnd}
              auditFilingDueDate={auditFilingDueDate}
              selectedKey={reportingPeriodYear}
              handleChange={(
                newReportingPeriodEndDate: string,
                newFilingDueDate: string,
                newSelectedYear: number,
              ) => {
                setReportingPeriodYear(newSelectedYear);
                setReportingPeriodEndDate(newReportingPeriodEndDate);
                setFilingDueDate(newFilingDueDate);
              }}
            />
          </Stack.Item>
        </Stack>
      )}
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack.Item grow>
          <Controller
            name="reportingPeriodEndDate"
            control={control}
            value={reportingPeriodEndDate}
            render={({ value }): React.ReactElement => (
              <UtcDatePicker
                label="Initial Reporting Period End Date"
                isRequired
                value={value}
                onSelectDate={(utcDateString: string): void => {
                  setReportingPeriodEndDate(utcDateString);
                }}
                disabled={showDateRangeSelector || missingDefaults}
                disableAutoFocus={false}
                // Max Date = 1 Day before Filing Due Date
                maxDate={reportingPeriodEndDateMaxDate(
                  filingDueDate ||
                    (showDateRangeSelector &&
                      filingDueDateDefaultDate(auditFilingDueDate, reportingPeriodEndDate)),
                )}
              />
            )}
            errorMessage={
              dirtyFields?.reportingPeriodEndDate && errors?.reportingPeriodEndDate?.message
            }
            rules={{
              required: 'Please provide an initial reporting period due date',
            }}
          />
        </Stack.Item>
        <Stack.Item grow>
          <Controller
            name="filingDueDate"
            control={control}
            value={filingDueDate}
            render={({ value }): React.ReactElement => (
              <UtcDatePicker
                label="Initial Filing Due Date"
                isRequired
                value={value}
                onSelectDate={(utcDateString: string): void => {
                  setFilingDueDate(utcDateString);
                }}
                disableAutoFocus={false}
                disabled={showDateRangeSelector || missingDefaults}
                // Min Date = 1 Day after Reporting Period End Date
                minDate={filingDueDateMinDate(
                  reportingPeriodEndDate ||
                    (showDateRangeSelector && reportingPeriodEndDateDefaultDate(statutoryYearEnd)),
                )}
              />
            )}
            errorMessage={dirtyFields?.filingDueDate && errors?.filingDueDate?.message}
            rules={{
              required: 'Please provide an initial filing due date',
            }}
          />
        </Stack.Item>
      </Stack>
    </>
  );
};
export default InitialProcessDates;
