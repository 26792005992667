import { ITag, IconButton, Label, SharedColors, Spinner, mergeStyleSets } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { PushPaneContainer, PushPaneViewportSize } from '@coherence-design-system/push-pane';
import React, { PropsWithChildren } from 'react';
import { GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY } from '../../../../utils/statApi/CompanyFiscalYearsApi';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import AgmTrackerAgingMetricSelector from './AgmTrackerAgingMetricSelector';
import AgmTrackerStatusMetricSelector from './AgmTrackerStatusMetricSelector';

interface agmTrackerCategoryPushPane {
  onPushPaneChange: (items: ITag[], key: string, title: string) => void;
  statusSelectedItems: ITag[];
  agingSelectedItems: ITag[];
  agmOwner: string;
}

const AgmTrackerCategoryPushPane = (props: PropsWithChildren<agmTrackerCategoryPushPane>) => {
  const { onPushPaneChange, children, statusSelectedItems, agingSelectedItems, agmOwner } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const { data, loading, error } = useQuery(GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY, {
    variables: {
      owner: agmOwner,
    },
  });

  const width = { collapsed: 50, expanded: 320 };

  const getViewportSize = (): PushPaneViewportSize => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 320) return 'SM';
    if (windowWidth <= 639) return 'MD';
    return 'LG';
  };

  const colorMapping = (count: number): string => {
    switch (true) {
      case count === 0:
        return SharedColors.gray40;
      case count <= 20:
        return SharedColors.blue10;
      case count <= 40:
        return SharedColors.green20;
      case count <= 60:
        return SharedColors.yellow10;
      case count > 60:
        return SharedColors.red20;
      default:
        return SharedColors.gray40;
    }
  };

  const styles = mergeStyleSets({
    container: {
      overflow: 'auto',
      marginBottom: '50px',
    },
  });

  return (
    <PushPaneContainer
      isPushPaneOpen={isOpen}
      disableAnimation
      width={width}
      getViewportSize={getViewportSize}
    >
      <div slot="paneTitle">AGM Tracker Metrics</div>
      <div
        slot="paneContent"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10x',
          padding: '10px 0',
          width: '100%',
        }}
      >
        <LoadingErrorMessage loading={loading} error={error} />
        {loading && <Spinner label="Loading..." ariaLive="assertive" labelPosition="bottom" />}
        {!loading && !error && (
          <>
            <div className={styles.container} data-is-scrollable>
              <Label>Aging (days)</Label>
              <AgmTrackerAgingMetricSelector
                onchange={onPushPaneChange}
                agingSelectedItems={agingSelectedItems}
                agingItems={data?.agmTrackerStatusCounts?.agingMetrics}
                colorMapping={colorMapping}
              />
            </div>
            <div className={styles.container} data-is-scrollable>
              <Label>Status</Label>
              <AgmTrackerStatusMetricSelector
                onStatusChange={onPushPaneChange}
                colorMapping={colorMapping}
                statusMetrics={data?.agmTrackerStatusCounts.statusMetrics}
                selectedMetrics={statusSelectedItems}
              />
            </div>
          </>
        )}
      </div>
      <div slot="mainContent" style={{ minWidth: 0, overflowY: 'auto' }}>
        {children}
      </div>

      <div slot="toggleButton" style={{ position: 'absolute', top: 18, right: 14 }}>
        <IconButton
          iconProps={{ iconName: isOpen ? 'DoubleChevronRight' : 'DoubleChevronLeft' }}
          onClick={() => setIsOpen((prev) => !prev)}
          ariaLabel="Toggle"
          aria-expanded={isOpen}
        />
      </div>
    </PushPaneContainer>
  );
};

export default AgmTrackerCategoryPushPane;
