import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CountryConfigurationView from './CountryConfiguration';

const CountriesRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/countries" exact component={CountryConfigurationView} />
      <Route path="/countries/:countryId" component={CountryConfigurationView} />
    </Switch>
  );
};

export default CountriesRoute;
