import { useMutation } from '@apollo/client';
import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import {
  ANALYZE_DOCUMENTS,
  GET_RUNS_FOR_DOCUMENT,
} from '../../../utils/statApi/DocumentAnalysisApi';
import CanAccess from '../../common/canAccess/CanAccess';

interface IAnalyzeButtonProps {
  documentId: number;
}

const AnalyzeButton = (props: IAnalyzeButtonProps): JSX.Element => {
  const { documentId } = props;

  const [analyze, { loading }] = useMutation(ANALYZE_DOCUMENTS, {
    refetchQueries: [GET_RUNS_FOR_DOCUMENT],
  });

  const onClick = (): void => {
    analyze({
      variables: {
        createAnalysisRunsDto: {
          documentIds: [documentId],
        },
      },
    });
  };

  return (
    <CanAccess requestedAction="DocumentAnalysis:Create" notAuthorizedChildren={<div />}>
      <PrimaryButton text="Analyze" disabled={loading} onClick={onClick} />
    </CanAccess>
  );
};

export default AnalyzeButton;
