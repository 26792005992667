import React, { useEffect, useState } from 'react';
import { Stack, IconButton } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import Card from '../../common/card/Card';
import IAnnualFilingRequirements from '../../../utils/types/IAnnualFilingRequirements';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import StackColumn from '../../common/StackColumn';
import SpanWithLabel from '../../common/SpanWithLabel';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';
import AnnualFilingRequirementsFormType from '../detail/forms/AnnualFilingRequirementsFormTypeEnum';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CanAccess from '../../common/canAccess/CanAccess';
import XBRLEditPanel from './XBRLEditPanel';
import FinancialSystemsEditPanel from './FinancialSystemsEditPanel';
import FinancialStatementInformationEditPanel from './FinancialStatementFilingInformationEditPanel';
import AccountingStandardsEditPanel from './AccountingStandardsEditPanel';
import AuditInformationEditPanel from './FinancialStatementAuditInformationEditPanel';
import {
  GET_COMPANY_ANNUAL_FILING_REQUIREMENTS,
  UPDATE_COMPANY_FILING_INFO_MUTATION,
} from '../../../utils/statApi/AnnualFilingRequirementsApi';
import AnnualGeneralMeetingEditPanel from './AnnualGeneralMeetingEditPanel';
import FilingInfoCard from '../../common/FilingInfoCard';
import FilingInfoForm from '../detail/forms/FilingInfoForm';
import monthDayFormat from '../../../utils/MonthDayFormatter';
import ICompanyDefaultAgmDetails from '../../../utils/types/ICompanyDefaultAgmDetails';
import CompanyDefaultAgmDetailsPanel from '../detail/CompanyDefaultAgmDetailsPanel';
import { paperSizeDisplay } from '../../../utils/Helpers';

const AnnualFilingRequirements = (): JSX.Element => {
  const afsEditPolicy = 'AnnualFilingRequirements:Edit';

  const { companyCode } = useParams<ICompanyCodeParams>();
  const { data, error, loading, refetch } = useQuery(GET_COMPANY_ANNUAL_FILING_REQUIREMENTS, {
    variables: { companyCode },
  });
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);

  const [editForm, setCurrentEditForm] = useState<AnnualFilingRequirementsFormType>();
  const togglePanel = (): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  // below added in order to prevent refresh of entire page.
  useEffect(() => {
    refetch();
  }, []);

  const annualFilingRequirements: IAnnualFilingRequirements = data
    ? data.annualFilingRequirements
    : {};

  const defaultAgmDetails: ICompanyDefaultAgmDetails =
    annualFilingRequirements?.companyDefaultAgmDetails;

  const agmDetails: ICompanyDefaultAgmDetails = defaultAgmDetails
    ? {
        id: defaultAgmDetails.id,
        agmDeadlineBeforeAfsDeadline: defaultAgmDetails.agmDeadlineBeforeAfsDeadline,
        taxFilingDeadlineBeforeAfsDeadline: defaultAgmDetails.taxFilingDeadlineBeforeAfsDeadline,
        taxFilingDeadline: defaultAgmDetails.taxFilingDeadline,
        externalAfsFilingRequired: defaultAgmDetails.externalAfsFilingRequired,
        requiredToBeFiledWithAfs: defaultAgmDetails.requiredToBeFiledWithAfs,
        canAfsBeExtended: defaultAgmDetails.canAfsBeExtended,
        physicalSignatureRequired: defaultAgmDetails.physicalSignatureRequired,
        hardCopyShippingAddress: defaultAgmDetails.hardCopyShippingAddress,
        inkColorRequired: defaultAgmDetails.inkColorRequired,
        signatureInkColor: defaultAgmDetails.signatureInkColor,
        originalsCount: defaultAgmDetails.originalsCount,
        paperSize: defaultAgmDetails.paperSize,
        otherPaperSize: defaultAgmDetails.otherPaperSize,
        managementRepresentationLetterRequired:
          defaultAgmDetails.managementRepresentationLetterRequired,
        auditorFeesCurrencyCode: defaultAgmDetails.auditorFeesCurrencyCode,
        additionalProcessingTimeApostille: defaultAgmDetails.additionalProcessingTimeApostille,
        additionalProcessingTimeLegalization:
          defaultAgmDetails.additionalProcessingTimeLegalization,
        additionalProcessingTimeLocalSigner: defaultAgmDetails.additionalProcessingTimeLocalSigner,
        additionalProcessingTime: null,
        auditingFirm: null,
        otherAuditingFirm: null,
      }
    : null;

  const renderActionButton = (
    label: string,
    formType: AnnualFilingRequirementsFormType,
    requestedAction: string,
  ): JSX.Element => {
    return (
      <>
        <CanAccess requestedAction={requestedAction}>
          <IconButton
            title={`Edit ${label}`}
            ariaLabel={label}
            iconProps={{ iconName: 'Edit' }}
            onClick={(): void => {
              setCurrentEditForm(formType);
              togglePanel();
            }}
          />
        </CanAccess>
      </>
    );
  };

  const additionalTimeFormat = (): string => {
    let displayString = '';
    if (agmDetails?.additionalProcessingTimeApostille) {
      displayString += 'Apostille, ';
    }
    if (agmDetails?.additionalProcessingTimeLegalization) {
      displayString += 'Legalization, ';
    }
    if (agmDetails?.additionalProcessingTimeLocalSigner) {
      displayString += 'Local Signer';
    }
    return displayString.replace(/,\s*$/, '');
  };

  return (
    <div className={bodyContentContainer}>
      <LoadingErrorMessage error={error} loading={loading} />
      {annualFilingRequirements && (
        <>
          <Card
            title="Financial Statement Filing Information"
            isLoading={loading}
            headingLevel={2}
            actionButton={renderActionButton(
              'Financial Statement Filing Information',
              AnnualFilingRequirementsFormType.FinancialStatementFilingInformation,
              afsEditPolicy,
            )}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Financial Statement Type"
                  value={annualFilingRequirements.financialStatementType?.fieldName}
                />
                <SpanWithLabel
                  labelText="Physical Signature Required"
                  value={annualFilingRequirements.physicalSignatureRequired}
                />
                <SpanWithLabel labelText="FS Signer" value={annualFilingRequirements.fsSigner} />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Tax ID" value={annualFilingRequirements?.taxId} />
                <SpanWithLabel
                  labelText="Other Financial Statement Information"
                  value={annualFilingRequirements.otherFinancialStatementInfo}
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            title="Financial Systems"
            actionButton={renderActionButton(
              'Financial Systems',
              AnnualFilingRequirementsFormType.FinancialSystems,
              afsEditPolicy,
            )}
            isLoading={loading}
            headingLevel={2}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Local Account System (if not SAP)"
                  value={annualFilingRequirements.localAccountSystem}
                />
                <SpanWithLabel labelText="Head Count" value={annualFilingRequirements.headCount} />
                <SpanWithLabel
                  labelText="Local Chart Of Accounts Required"
                  value={annualFilingRequirements.localChartOfAccountsRequired}
                />
                <SpanWithLabel
                  labelText="MS Market User"
                  value={annualFilingRequirements.isMsMarketUser}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="Is Calendar Year Ledger Required"
                  value={annualFilingRequirements.calendarYearLedgerRequired}
                />
                <SpanWithLabel
                  labelText="Finance Record Location"
                  value={annualFilingRequirements.financialRecordLocation}
                />
                <SpanWithLabel
                  labelText="Local Language Requirements"
                  value={annualFilingRequirements.localLanguageRequirements}
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            title="Financial Statement Audit Information"
            isLoading={loading}
            headingLevel={2}
            actionButton={renderActionButton(
              'Financial Statement Audit Information',
              AnnualFilingRequirementsFormType.FinancialStatementAuditInformation,
              afsEditPolicy,
            )}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Financial Statement Filing Date"
                  value={monthDayFormat(
                    annualFilingRequirements?.auditFilingDateMonth,
                    annualFilingRequirements?.auditFilingDateDay,
                  )}
                />
                <SpanWithLabel
                  labelText="Audit Frequency"
                  value={annualFilingRequirements.auditFrequency?.fieldName}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="Auditing Firm"
                  value={annualFilingRequirements.auditingFirm?.fieldName}
                />
                <SpanWithLabel
                  labelText="Other Auditing Firm"
                  value={annualFilingRequirements.otherAuditingFirm}
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            title="Annual General Meeting"
            isLoading={loading}
            headingLevel={2}
            actionButton={renderActionButton(
              'Annual General Meeting',
              AnnualFilingRequirementsFormType.AnnualGeneralMeeting,
              'CelaAgmStatus:Update',
            )}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Due Date"
                  value={monthDayFormat(
                    annualFilingRequirements?.annualGeneralMeetingDueDateMonth,
                    annualFilingRequirements?.annualGeneralMeetingDueDateDay,
                  )}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="AGM Information"
                  value={annualFilingRequirements?.annualGeneralMeetingInformation}
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            title="Accounting Standards"
            isLoading={loading}
            headingLevel={2}
            actionButton={renderActionButton(
              'Accounting Standards',
              AnnualFilingRequirementsFormType.AccountingStandards,
              afsEditPolicy,
            )}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Accouting Standards:"
                  value={annualFilingRequirements.accountingStandardsUsed?.fieldName}
                />
                <SpanWithLabel
                  labelText="Accouting Standards Comments:"
                  value={annualFilingRequirements.accountingStandardsComments}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="IFRS Type:" value={annualFilingRequirements.ifrsType} />
                <SpanWithLabel
                  labelText="IFRS Special Elections:"
                  value={annualFilingRequirements.ifrsSpecialElections}
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            title="XBRL (eXtensible Business Reporting Language)"
            isLoading={loading}
            headingLevel={2}
            actionButton={renderActionButton(
              'XBRL',
              AnnualFilingRequirementsFormType.XBRL,
              afsEditPolicy,
            )}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="XBRL Required for Statutory Filings:"
                  value={annualFilingRequirements.xbrlRequiredForFilings}
                />
                <SpanWithLabel
                  labelText="Filings Required to be Filed in XBRL:"
                  value={annualFilingRequirements.filingsRequiredToBeFiledInXbrl}
                />
                <SpanWithLabel
                  labelText="Date of First XBRL Filing Requirement:"
                  value={momentUtc(
                    annualFilingRequirements.dateOfFirstXbrlFilingRequirement,
                    StatDateFormats.DayBreakout,
                    true,
                  )}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="Section of Filing Required to be Tagged:"
                  value={annualFilingRequirements.sectionOfFilingRequiredToBeTagged}
                />
                <SpanWithLabel
                  labelText="XBRL Comments:"
                  value={annualFilingRequirements.xbrlComments}
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <FilingInfoCard
            loading={loading}
            filingInfo={annualFilingRequirements?.filingInfo}
            editAction={renderActionButton(
              'Filing Info',
              AnnualFilingRequirementsFormType.FilingInfo,
              afsEditPolicy,
            )}
          />
          <br />
          <Card
            title="Default AGM Details"
            headingLevel={2}
            actionButton={renderActionButton(
              'AGM Details',
              AnnualFilingRequirementsFormType.AGMDetails,
              afsEditPolicy,
            )}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Is the AGM deadline earlier than the AFS deadline:"
                  value={agmDetails?.agmDeadlineBeforeAfsDeadline}
                />
                <SpanWithLabel
                  labelText="Is the tax filing deadline earlier than the AFS deadline:"
                  value={agmDetails?.taxFilingDeadlineBeforeAfsDeadline}
                />
                <SpanWithLabel
                  labelText="Tax Filing Deadline:"
                  value={momentUtc(
                    agmDetails?.taxFilingDeadline,
                    StatDateFormats.DayBreakout,
                    true,
                  )}
                />
                <SpanWithLabel
                  labelText="Is there a formal AFS external filing required:"
                  value={agmDetails?.externalAfsFilingRequired}
                />
                <SpanWithLabel
                  labelText="Do the AGM date and the FS date have to be aligned:"
                  value={agmDetails?.requiredToBeFiledWithAfs}
                />
                <SpanWithLabel
                  labelText="Can the AFS deadline be extended:"
                  value={agmDetails?.canAfsBeExtended}
                />
                <SpanWithLabel
                  labelText="Is the hard copy signature required for filing:"
                  value={agmDetails?.physicalSignatureRequired}
                />
                <SpanWithLabel labelText="Address:" value={agmDetails?.hardCopyShippingAddress} />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="Is there a required ink color for the signature:"
                  value={agmDetails?.inkColorRequired}
                />
                <SpanWithLabel
                  labelText="Signature Ink color:"
                  value={agmDetails?.signatureInkColor}
                />
                <SpanWithLabel
                  labelText="How many originals are required:"
                  value={agmDetails?.originalsCount}
                />
                <SpanWithLabel
                  labelText="Paper Size:"
                  value={
                    agmDetails?.otherPaperSize
                      ? `${paperSizeDisplay(agmDetails?.paperSize)} - ${agmDetails?.otherPaperSize}`
                      : paperSizeDisplay(agmDetails?.paperSize)
                  }
                />
                <SpanWithLabel
                  labelText="Is a Management Representation Letter Required:"
                  value={agmDetails?.managementRepresentationLetterRequired}
                />
                <SpanWithLabel
                  labelText="Auditor Currency:"
                  value={agmDetails?.auditorFeesCurrencyCode}
                />
                <SpanWithLabel
                  labelText="Additional Processing Time:"
                  value={additionalTimeFormat()}
                />
              </StackColumn>
            </Stack>
          </Card>

          {isEditPanelOpen &&
            editForm === AnnualFilingRequirementsFormType.FinancialStatementAuditInformation && (
              <AuditInformationEditPanel
                annualFilingRequirements={annualFilingRequirements}
                closePanel={togglePanel}
              />
            )}
          {isEditPanelOpen && editForm === AnnualFilingRequirementsFormType.FinancialSystems && (
            <FinancialSystemsEditPanel
              annualFilingRequirementsData={annualFilingRequirements}
              closePanel={togglePanel}
            />
          )}
          {isEditPanelOpen &&
            editForm === AnnualFilingRequirementsFormType.FinancialStatementFilingInformation && (
              <FinancialStatementInformationEditPanel
                annualFilingRequirements={annualFilingRequirements}
                closePanel={togglePanel}
              />
            )}
          {isEditPanelOpen && editForm === AnnualFilingRequirementsFormType.AccountingStandards && (
            <AccountingStandardsEditPanel
              annualFilingRequirements={annualFilingRequirements}
              closePanel={togglePanel}
            />
          )}
          {isEditPanelOpen && editForm === AnnualFilingRequirementsFormType.XBRL && (
            <XBRLEditPanel xbrlDetails={annualFilingRequirements} closePanel={togglePanel} />
          )}
          {isEditPanelOpen &&
            editForm === AnnualFilingRequirementsFormType.AnnualGeneralMeeting && (
              <AnnualGeneralMeetingEditPanel
                information={annualFilingRequirements.annualGeneralMeetingInformation}
                dueDateMonth={annualFilingRequirements.annualGeneralMeetingDueDateMonth}
                dueDateDay={annualFilingRequirements.annualGeneralMeetingDueDateDay}
                closePanel={togglePanel}
              />
            )}
          {isEditPanelOpen && editForm === AnnualFilingRequirementsFormType.FilingInfo && (
            <FilingInfoForm
              filingInfoData={annualFilingRequirements?.filingInfo}
              closePanel={togglePanel}
              mutation={UPDATE_COMPANY_FILING_INFO_MUTATION}
              parentId={companyCode}
              parentIdName="companyCode"
            />
          )}
          {isEditPanelOpen && editForm === AnnualFilingRequirementsFormType.AGMDetails && (
            <CompanyDefaultAgmDetailsPanel data={agmDetails} closePanel={togglePanel} />
          )}
          <br />
        </>
      )}
    </div>
  );
};
export default AnnualFilingRequirements;
