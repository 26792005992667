import {
  Checkbox,
  Dropdown,
  IconButton,
  IDropdownProps,
  IIconStyles,
  ITooltipHostStyles,
  Label,
  Stack,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Controller, DeepPartial, useForm, useWatch } from 'react-hook-form';
import hasAccess from '../../../../utils/authorization/authorizationCheck';
import UserContext from '../../../../utils/authorization/UserContext';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import IProcessConfig from '../../../../utils/types/IProcessConfig';
import FeatureFlagged from '../../../common/featureFlagged/featureFlagged';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import ComplianceRequirementPicker from '../../../countries/ComplianceRequirementPicker';
import { ProcessType } from '../../processes/Interfaces';
import { filingDueDateMinDate, reportingPeriodEndDateMaxDate } from './CreateProcessConfigUtils';

interface IEditProcessConfigDetailsFormProps {
  onValidated: (isValid: boolean) => void;
  setData: Dispatch<SetStateAction<IProcessConfig>>;
  data: IProcessConfig;
}
export const offsetTooltipHostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' },
};
export const tooltipForDueDateStyles: Partial<ITooltipHostStyles> = {
  root: { position: 'absolute', top: 0, right: 0 },
};

export const offsetDateIconStyles: Partial<IIconStyles> = {
  root: { marginLeft: 5 },
};

const EditProcessConfigDetailsForm = (props: IEditProcessConfigDetailsFormProps): JSX.Element => {
  const { data, setData, onValidated } = props;
  const [isAnnualFinancialStatement, setIsAnnualFinancialStatement] = useState(false);
  const disableUseLastDayOfMonth = data.recurrence === 'ONETIME' || data.recurrence === 'WEEKLY';
  const user: StatUser = useContext(UserContext);
  const form = useForm<IProcessConfig>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: data as IProcessConfig,
  });

  const { errors, setValue, control, formState, getValues, trigger } = form;
  const { isValid, dirtyFields } = formState;

  const watchForm: DeepPartial<IProcessConfig> = useWatch({
    control,
    defaultValue: data as IProcessConfig,
  });

  useEffect(() => {
    onValidated(isValid);
    setIsAnnualFinancialStatement(
      data?.processType?.fieldName === ProcessType.AnnualFinancialStatements,
    );
    if (isValid) {
      setData({
        ...data,
        name: getValues('name'),
        filingDueDate: getValues('filingDueDate'),
        reportingPeriodEndDate: getValues('reportingPeriodEndDate'),
        useLastDayOfMonth: getValues('useLastDayOfMonth'),
      });
    }
  }, [isValid, watchForm]);

  const getMinDate = (utcDateString: Date): Date => {
    if (utcDateString == null) {
      return null;
    }

    const utcDate = new Date(utcDateString);
    const localizedDate = new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate() + 1,
    );

    return localizedDate;
  };

  const getMinFilingDueDate = (): Date => {
    const minProcessDate = getMinDate(data?.minFilingDueDate);
    const minFilingDueDate = filingDueDateMinDate(data?.reportingPeriodEndDate);
    const latestMinDate = minProcessDate > minFilingDueDate ? minProcessDate : minFilingDueDate;
    return latestMinDate;
  };

  const onRenderLabel = (dropDownProps: IDropdownProps): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <Label>{dropDownProps.label}</Label>
        <TooltipHost
          content={`${dropDownProps.label} cannot be edited. If this needs to be changed, create a new process configuration.`}
          id="tooltip"
          styles={offsetTooltipHostStyles}
        >
          <IconButton
            aria-describedby="tooltip"
            ariaLabel="Deactivate date information"
            iconProps={{ iconName: 'Info' }}
            styles={offsetDateIconStyles}
          />
        </TooltipHost>
      </Stack>
    );
  };
  const { permissions } = user;
  const permission = isAnnualFinancialStatement
    ? 'ProcessConfiguration:EditReportingPeriodEndDateAFS'
    : 'ProcessConfiguration:EditReportingPeriodEndDate';
  const preventEditReportingPeriodEndDate = !hasAccess(permissions, permission);
  const preventEditFilingDueDate =
    isAnnualFinancialStatement &&
    !hasAccess(permissions, 'ProcessConfiguration:EditFilingDueDateAFS');
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <FeatureFlagged flagName="CountryConfiguration">
        <Stack horizontal>
          <Controller
            name="complianceRequirement"
            control={control}
            disabled
            defaultValue={[data?.complianceRequirement]}
            render={(): React.ReactElement => (
              <ComplianceRequirementPicker
                selectedItem={
                  data?.complianceRequirement
                    ? [
                        {
                          key: data?.complianceRequirement?.id,
                          name: data?.complianceRequirement?.name,
                        },
                      ]
                    : []
                }
                setSelectedItem={null}
                isDisabled
              />
            )}
          />
        </Stack>
      </FeatureFlagged>

      <Dropdown
        label="Process Type"
        options={[{ key: data?.processType?.fieldName, text: data?.processType?.fieldName }]}
        selectedKey={data?.processType?.fieldName}
        disabled
        onRenderLabel={onRenderLabel}
      />
      <Dropdown
        label="Process Template"
        options={[
          { key: data?.processTemplate?.fieldName, text: data?.processTemplate?.fieldName },
        ]}
        selectedKey={data?.processTemplate?.fieldName}
        disabled
        onRenderLabel={onRenderLabel}
      />
      <Controller
        as={TextField}
        label="Name"
        id="name"
        name="name"
        control={control}
        resizable={false}
        required
        errorMessage={dirtyFields?.name && errors?.name?.message}
        value={getValues('name')?.toString()}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ): void => {
          setValue('name', newValue, { shouldDirty: true });
        }}
        rules={{
          required: 'Name is required',
          maxLength: {
            value: 200,
            message: 'Input is greater than the maximum character limit of 200.',
          },
        }}
      />
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack.Item grow styles={{ root: { position: 'relative' } }}>
          <Controller
            name="reportingPeriodEndDate"
            control={control}
            defaultValue={data?.reportingPeriodEndDate}
            render={({ value }): React.ReactElement => (
              <>
                <UtcDatePicker
                  label="Next Reporting Period End Date"
                  isRequired
                  value={value}
                  minDate={getMinDate(data?.minReportingPeriodEndDate)}
                  maxDate={reportingPeriodEndDateMaxDate(data?.filingDueDate)}
                  onSelectDate={(utcDateString: string): void => {
                    setValue('reportingPeriodEndDate', utcDateString, { shouldDirty: true });
                    trigger();
                  }}
                  disabled={preventEditReportingPeriodEndDate}
                />
                {preventEditReportingPeriodEndDate && (
                  <TooltipHost
                    content="You do not have access to change the reporting period end date"
                    id="tooltip"
                    styles={tooltipForDueDateStyles}
                  >
                    <IconButton
                      aria-describedby="tooltip"
                      ariaLabel="You do not have access to change the reporting period end date"
                      iconProps={{ iconName: 'Info' }}
                      styles={offsetDateIconStyles}
                    />
                  </TooltipHost>
                )}
              </>
            )}
            errorMessage={
              dirtyFields?.reportingPeriodEndDate && errors?.reportingPeriodEndDate?.message
            }
            rules={{
              required: 'Please provide a reporting period due date',
            }}
          />
        </Stack.Item>
        <Stack.Item grow styles={{ root: { position: 'relative' } }}>
          <Controller
            name="filingDueDate"
            control={control}
            defaultValue={data?.filingDueDate}
            render={({ value }): React.ReactElement => (
              <>
                <UtcDatePicker
                  label="Next Filing Due Date"
                  isRequired
                  value={value}
                  minDate={getMinFilingDueDate()}
                  onSelectDate={(utcDateString: string): void => {
                    setValue('filingDueDate', utcDateString, { shouldDirty: true });
                    trigger();
                  }}
                  disabled={preventEditFilingDueDate}
                />

                {preventEditFilingDueDate && (
                  <TooltipHost
                    content="Only admins and STAT Info users can change the filing due date for an AFS process"
                    id="tooltip"
                    styles={tooltipForDueDateStyles}
                  >
                    <IconButton
                      aria-describedby="tooltip"
                      ariaLabel="Only admins and STAT Info users can change the filing due date for an AFS process"
                      iconProps={{ iconName: 'Info' }}
                      styles={offsetDateIconStyles}
                    />
                  </TooltipHost>
                )}
              </>
            )}
            errorMessage={dirtyFields?.filingDueDate && errors?.filingDueDate?.message}
            rules={{
              required: 'Please provide a filing due date',
            }}
          />
        </Stack.Item>
      </Stack>
      <Controller
        name="useLastDayOfMonth"
        control={control}
        defaultValue={data?.useLastDayOfMonth}
        render={({ onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="Use Last Day of Month"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              setValue('useLastDayOfMonth', checked, { shouldDirty: true });
              trigger();
            }}
            checked={value}
            name={name}
            disabled={disableUseLastDayOfMonth}
          />
        )}
      />
      <Dropdown
        label="Recurrence"
        options={[{ key: data?.recurrence, text: data?.recurrence }]}
        selectedKey={data?.recurrence}
        disabled
        onRenderLabel={onRenderLabel}
      />
    </Stack>
  );
};
export default EditProcessConfigDetailsForm;
