import { DocumentNode, useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React, { useState } from 'react';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { IDocument } from '../../../utils/statApi/DocumentApi';
import { IDocumentsList } from '../../companyCreation/types';
import ActionButtons from '../ActionButtons';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';
import DocumentsForm from './DocumentsForm';

interface IDocumentsPanelProps {
  closePanel: () => void;
  initialDocuments: IDocument[];
  parentId: number;
  documentType: string;
  maxOfOne?: boolean;
  mutation: DocumentNode;
  showDeletedFiles: boolean;
}

const deepCopy = (item: unknown) => {
  const copy = JSON.parse(JSON.stringify(item));
  return copy;
};

const DocumentsPanel = ({
  closePanel,
  initialDocuments,
  parentId,
  documentType,
  maxOfOne = false,
  mutation,
  showDeletedFiles,
}: IDocumentsPanelProps): JSX.Element => {
  const [documents, setDocuments] = useState<IDocumentsList>({
    currentDocuments: deepCopy(initialDocuments || []),
    newDocuments: [],
    removedDocuments: [],
    uploadIsInProgress: false,
    id: parentId,
  });

  const [updateCompanyCreationDocuments, { loading, error }] = useMutation(mutation, {
    onCompleted: closePanel,
  });

  const deleteDocument = (index: number): void => {
    // remove document from current
    const currentDocs = deepCopy(documents.currentDocuments);
    const removedDoc = currentDocs.splice(index, 1);

    // add to removed list if already persisted
    const removedDocs = deepCopy(documents.removedDocuments);
    if (removedDoc[0].id > 0) {
      removedDocs.push(removedDoc[0]);
    }

    // remove from added list
    let addedDocs = deepCopy(documents.newDocuments);
    addedDocs = addedDocs.filter((doc: IDocument): boolean => {
      return doc.url !== removedDoc[0].url;
    });

    setDocuments({
      ...documents,
      currentDocuments: currentDocs,
      removedDocuments: removedDocs,
      newDocuments: addedDocs,
    });
  };

  const attachDocument = async (files: IDocument[]): Promise<void> => {
    let newArray: IDocument[];
    let displayDocuments: IDocument[];
    if (maxOfOne) {
      newArray = [files[0]];
      displayDocuments = [files[0]];
    } else {
      newArray = documents.newDocuments.concat(files);
      displayDocuments = documents.currentDocuments.concat(files);
    }

    setDocuments({
      ...documents,
      currentDocuments: displayDocuments,
      newDocuments: newArray,
      uploadIsInProgress: false,
    });
  };

  const setUploadInProgress = (uploadIsInProgress: boolean): void => {
    setDocuments({
      ...documents,
      uploadIsInProgress,
    });
  };

  const saveDocumentLists = (): void => {
    const documentIdsToRemove = documents.removedDocuments.map((document: IDocument): number => {
      return document.id;
    });

    updateCompanyCreationDocuments({
      variables: {
        input: {
          parentId,
          documentType: documentType.toUpperCase(),
          documentsToAdd: documents.newDocuments,
          documentIdsToRemove,
          showDeletedFiles,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={`Edit ${documentType} Documents`}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel={`Close Edit ${documentType} Documents Panel`}
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading || documents.uploadIsInProgress}
          closePanel={closePanel}
          handleSubmit={saveDocumentLists}
          saveLabel={`Save ${documentType} Documents`}
          saveTitle={`Save ${documentType} Documents`}
          cancelLabel={`Cancel ${documentType} Documents Edit`}
          cancelTitle={`Cancel ${documentType} Documents Edit`}
        />
      )}
    >
      <form onSubmit={saveDocumentLists}>
        <LoadingErrorMessage error={error} loading={loading} />
        <DocumentsForm
          documents={documents.currentDocuments}
          attachDocument={attachDocument}
          deleteDocument={deleteDocument}
          maxOfOne={maxOfOne}
          uploadInProgress={setUploadInProgress}
        />
      </form>
    </CoherencePanel>
  );
};

export default DocumentsPanel;
