import { ChoiceGroup, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { yesNoOptions } from '../../../../utils/types/CommonDropdownOptions';
import CanAccess from '../../../common/canAccess/CanAccess';
import IAnnualFilingRequirementsFormProps from './IAnnualFilingRequirementsFormProps';

const FinancialSystemsForm = ({
  annualFilingRequirements,
  form,
}: IAnnualFilingRequirementsFormProps): JSX.Element => {
  const { control, errors } = form;
  const maxLengthRules = {
    maxLength: {
      value: 250,
      message: 'Input is greater than the maximum character limit of 250.',
    },
  };
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        as={TextField}
        label="Local Account System (if not SAP)"
        id="localAccountSystem"
        name="localAccountSystem"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.localAccountSystem}
        errorMessage={errors?.localAccountSystem?.message}
        rules={maxLengthRules}
      />
      <Controller
        as={TextField}
        label="Finance Record Location"
        id="financialRecordLocation"
        name="financialRecordLocation"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.financialRecordLocation}
        errorMessage={errors?.financialRecordLocation?.message}
        rules={maxLengthRules}
      />
      <Controller
        as={TextField}
        label="Local Language Requirements"
        id="localLanguageRequirements"
        name="localLanguageRequirements"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.localLanguageRequirements}
        errorMessage={errors?.localLanguageRequirements?.message}
        rules={maxLengthRules}
      />
      <Controller
        name="calendarYearLedgerRequired"
        control={control}
        defaultValue={annualFilingRequirements.calendarYearLedgerRequired}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <ChoiceGroup
            id="calendarYearLedgerRequired"
            label="Is Calendar Year Ledger Required"
            name={name}
            options={yesNoOptions}
            onBlur={onBlur}
            defaultSelectedKey={value?.toString()}
            onChange={(e, newValue): void => {
              onChange(newValue.key === 'true');
            }}
          />
        )}
      />
      <CanAccess requestedAction="HeadCount:Edit">
        <Controller
          name="headCount"
          control={control}
          defaultValue={annualFilingRequirements.headCount}
          render={({ onChange, onBlur, value, name }): React.ReactElement => (
            <ChoiceGroup
              id="headCount"
              label="Head Count"
              name={name}
              options={yesNoOptions}
              onBlur={onBlur}
              defaultSelectedKey={value?.toString()}
              onChange={(e, newValue): void => {
                onChange(newValue.key === 'true');
              }}
            />
          )}
        />
      </CanAccess>
      <Controller
        name="localChartOfAccountsRequired"
        control={control}
        defaultValue={annualFilingRequirements.localChartOfAccountsRequired}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <ChoiceGroup
            id="localChartOfAccountsRequired"
            label="Is Local Chart Of Accounts Required"
            name={name}
            options={yesNoOptions}
            onBlur={onBlur}
            defaultSelectedKey={value?.toString()}
            onChange={(e, newValue): void => {
              onChange(newValue.key === 'true');
            }}
          />
        )}
      />

      <Controller
        name="isMsMarketUser"
        control={control}
        defaultValue={annualFilingRequirements.isMsMarketUser}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <ChoiceGroup
            id="isMsMarketUser"
            label="Is MS Market User"
            name={name}
            options={yesNoOptions}
            onBlur={onBlur}
            defaultSelectedKey={value?.toString()}
            onChange={(e, newValue): void => {
              onChange(newValue.key === 'true');
            }}
          />
        )}
      />
    </Stack>
  );
};
export default FinancialSystemsForm;
