import { gql } from '@apollo/client';

const PROCESS_TEMPLATES_FRAGMENT = gql`
  fragment ProcessTemplates on ProcessTemplateDto {
    id
    name
    description
    processType {
      id
    }
    approvedProcessTemplateVersion {
      id
    }
  }
`;
const PROCESS_TEMPLATE_DETAIL_FRAGMENT = gql`
  fragment ProcessTemplateDetail on ProcessTemplateDetailDto {
    id
    name
    description
    approvedProcessTemplateVersionId
    processType {
      id
    }
    isActive
    templateMilestones {
      id
      name
      description
      attestationText
      ordinal
      defaultDaysOffset
      enforceDefaultAsMinDuration
      milestoneTemplateId
      signoffRoles {
        id
        name
        isGlobal
      }
      milestoneRules {
        id
        type
      }
      milestoneCategoryName
      milestoneCategoryId
    }
  }
`;
export const GET_PROCESS_TEMPLATE_BY_TYPE = gql`
  query ProcessTemplatesByType($processTypeId: Int!, $isActiveFilter: Boolean!) {
    processTemplatesByType(processTypeId: $processTypeId, isActiveFilter: $isActiveFilter) {
      id
      name
      processType {
        id
        name
      }
      isActive
      description
    }
  }
`;

export const GET_PROCESS_TEMPLATE_BY_TYPE_FOR_DROPDOWN = gql`
  query ProcessTemplatesByType($processTypeId: Int!, $isActiveFilter: Boolean!) {
    processTemplatesByType(processTypeId: $processTypeId, isActiveFilter: $isActiveFilter) {
      id
      fieldName: name
      processType {
        id
        fieldName: name
      }
    }
  }
`;
export const GET_PROCESS_TEMPLATE_VERSIONS_FOR_TEMPLATE = gql`
  query GetProcessTemplateVersions($processTemplateId: Int!) {
    processTemplateVersions(processTemplateId: $processTemplateId) {
      id
      fieldName: versionLabel
    }
  }
`;

export const GET_PROCESS_TEMPLATE_MILESTONES_BY_TYPE = gql`
  query GetProcessTemplateMilestones($processTemplateId: Int!, $companyCode: String!) {
    processTemplateMilestones(processTemplateId: $processTemplateId, companyCode: $companyCode) {
      id
      ordinal
      milestoneTemplateName
      maxDaysOffset
      defaultDaysOffset
      minimumDuration
      maximumDuration
      assigneeOptions {
        id
        name
      }
      assigneeId
      globalAssigneeId
    }
  }
`;

export const PROCESS_TEMPLATE_DETAIL_QUERY = gql`
  query ProcessTemplateDetail($processTemplateId: Int!, $processTemplateVersionId: Int) {
    processTemplateDetail(
      processTemplateId: $processTemplateId
      processTemplateVersionId: $processTemplateVersionId
    ) {
      ...ProcessTemplateDetail
    }
  }
  ${PROCESS_TEMPLATE_DETAIL_FRAGMENT}
`;

export const UPDATE_PROCESS_TEMPLATE_VERSION = gql`
  mutation createProcessTemplateVersion(
    $processTemplateVersionDto: ProcessTemplateVersionInputDtoInput!
  ) {
    createProcessTemplateVersion(processTemplateVersionDto: $processTemplateVersionDto) {
      id
      name
      description
    }
  }
`;

export const UPDATE_PROCESS_TEMPLATE_MUTATION = gql`
  mutation updateProcessTemplate($processTemplateInput: ProcessTemplateInputDtoInput!) {
    updateProcessTemplate(processTemplateInput: $processTemplateInput) {
      processTemplates {
        ...ProcessTemplates
      }
      processTemplateDetail {
        ...ProcessTemplateDetail
      }
    }
  }
  ${PROCESS_TEMPLATES_FRAGMENT}
  ${PROCESS_TEMPLATE_DETAIL_FRAGMENT}
`;

export const CREATE_PROCESS_TEMPLATE_MUTATION = gql`
  mutation createProcessTemplate($processTemplateInput: ProcessTemplateInputDtoInput!) {
    createProcessTemplate(processTemplateInput: $processTemplateInput) {
      ...ProcessTemplateDetail
    }
  }
  ${PROCESS_TEMPLATE_DETAIL_FRAGMENT}
`;

export const DEACTIVATE_PROCESS_TEMPLATE = gql`
  mutation deactivateProcessTemplate(
    $processTemplateDeactivateDto: ProcessTemplateDeactivateDtoInput
  ) {
    deactivateProcessTemplate(processTemplateDeactivateDto: $processTemplateDeactivateDto) {
      processTemplates {
        ...ProcessTemplates
      }
      processTemplateDetail {
        ...ProcessTemplateDetail
      }
    }
  }
  ${PROCESS_TEMPLATES_FRAGMENT}
  ${PROCESS_TEMPLATE_DETAIL_FRAGMENT}
`;

export const GET_PROCESS_CONFIGS_BY_TEMPLATE = gql`
  query ProcessConfigsByTemplate($processTemplateId: Int!) {
    processConfigsByTemplate(processTemplateId: $processTemplateId) {
      processConfigs {
        id
        name
        company {
          companyCode
          entityLegalName
        }
      }
      processConfigCount
      companiesCount
    }
  }
`;

export const GET_PROCESS_TEMPLATES_BY_MILESTONE_TEMPLATE = gql`
  query ProcessTemplatesByMilestoneTemplate($milestoneTemplateId: Int!) {
    processTemplatesByMilestoneTemplate(milestoneTemplateId: $milestoneTemplateId) {
      id
      name
      configCount
    }
  }
`;
