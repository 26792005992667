import { gql } from '@apollo/client';

export const GET_FOREX_CURRENCY_CODES_QUERY = gql`
  {
    currencyCodes {
      id
      fieldName
    }
  }
`;
export const GET_DEFAULT_CURRENCY_CODES_QUERY = gql`
  query DefaultCurrencyCodes($companyId: Int!) {
    defaultCurrencyCodes(companyId: $companyId) {
      id
      fieldName
    }
  }
`;
