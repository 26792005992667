import { IColumn, ColumnActionsMode } from '@fluentui/react';

interface ICompanyGroupListColumnParams {
  renderCompanyGroupNameLink: (item: { name: React.ReactNode }) => JSX.Element;
}

const getColumns = ({
  renderCompanyGroupNameLink,
}: ICompanyGroupListColumnParams): Array<IColumn> => {
  return [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 400,
      isResizable: false,
      isRowHeader: true,
      onRender: renderCompanyGroupNameLink,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 250,
      maxWidth: 400,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export default getColumns;
