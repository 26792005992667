import { IColumn, ColumnActionsMode } from '@fluentui/react';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';
import { IDividendPayment } from '../serviceContacts/interfaces';

interface IDividendPaymentListProps {
  paymentTypeColumn: (item: IDividendPayment) => JSX.Element;
  amountColumn: (item: { amount: number; currencyCode: string }) => JSX.Element;
  whAmountColumn: (item: { whAmount: number; currencyCode: string }) => JSX.Element;
  paymentDateColumn: (item: { paymentDate: Date }) => JSX.Element;
  editButtonColumn: (item: IDividendPayment) => JSX.Element;
  deleteButtonColumn: (item: IDividendPayment) => JSX.Element;
  canEdit: boolean;
  canDelete: boolean;
}

const getColumns = ({
  paymentTypeColumn,
  amountColumn,
  whAmountColumn,
  paymentDateColumn,
  editButtonColumn,
  deleteButtonColumn,
  canEdit,
  canDelete,
}: IDividendPaymentListProps): Array<IColumn> => {
  let columns: IColumn[] = [];

  if (canEdit) {
    columns.push({
      key: 'editButton',
      name: 'Edit',
      minWidth: 60,
      maxWidth: 60,
      className: rowClasses.medFont,
      isRowHeader: false,
      isPadded: false,
      onRender: editButtonColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    });
  }

  columns = columns.concat([
    {
      key: 'dividendPaymentType',
      name: 'Payment Type',
      onRender: paymentTypeColumn,
      minWidth: 175,
      maxWidth: 175,
      className: rowClasses.medFont,
      isRowHeader: false,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'dividendType',
      name: 'Dividend Type',
      fieldName: 'dividendType',
      minWidth: 75,
      maxWidth: 100,
      isRowHeader: false,
      data: 'string',
      columnActionsMode: ColumnActionsMode.disabled,
      isPadded: true,
    },
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'amount',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: false,
      data: 'number',
      isPadded: true,
      styles: {
        cellTitle: {
          justifyContent: 'flex-end',
        },
      },
      onRender: amountColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'currencyCode',
      name: 'Currency',
      fieldName: 'currencyCode',
      minWidth: 60,
      maxWidth: 60,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'paymentDate',
      name: 'Payment Date',
      fieldName: 'paymentDate',
      minWidth: 120,
      maxWidth: 125,
      isRowHeader: false,
      data: 'date',
      isPadded: true,
      onRender: paymentDateColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'fxRate',
      name: 'FX Rate',
      fieldName: 'fxRate',
      minWidth: 75,
      maxWidth: 125,
      isRowHeader: false,
      data: 'date',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'whRate',
      name: 'WH Rate',
      fieldName: 'whRate',
      minWidth: 75,
      maxWidth: 125,
      isRowHeader: false,
      data: 'date',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'whAmount',
      name: 'WH Amount',
      fieldName: 'whAmount',
      minWidth: 75,
      maxWidth: 125,
      isRowHeader: false,
      data: 'date',
      isPadded: true,
      onRender: whAmountColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]);

  if (canDelete) {
    columns.push({
      key: 'deleteButton',
      name: 'Delete',
      minWidth: 75,
      maxWidth: 75,
      className: rowClasses.medFont,
      isRowHeader: false,
      isPadded: false,
      onRender: deleteButtonColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    });
  }

  return columns;
};

export default getColumns;
