import React from 'react';
import {
  IColumn,
  ColumnActionsMode,
  Persona,
  PersonaPresence,
  Stack,
  TooltipHost,
  TooltipDelay,
  IDetailsColumnStyles,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IDividendsReviewSearchResult } from './IDividendsReviewSearchResults';
import StatDateFormats from '../../../../utils/types/DateFormats';
import momentUtc from '../../../../utils/DateFormatter';
import formatCurrency from '../../../../utils/CurrencyFormatter';
import ReviewStatus from '../../../reviews/ReviewStatus';

const onRenderCompanyCode = (item: IDividendsReviewSearchResult): JSX.Element => {
  return (
    <TooltipHost
      delay={TooltipDelay.medium}
      id={item.id.toString()}
      key={item.id}
      tooltipProps={{
        onRenderContent: () => {
          return <span>{item.processName}</span>;
        },
      }}
    >
      <Link to={`/companies/${item.companyCode}/dividends-review/${item.id}`}>
        {item.companyCode}-{item.companyName}
      </Link>
    </TooltipHost>
  );
};

const onRenderFilingDueDate = (item: IDividendsReviewSearchResult): JSX.Element => {
  return <span>{`${momentUtc(item.filingDueDate, StatDateFormats.DayBreakout)}`}</span>;
};

const onRenderReportingPeriodEndDate = (item: IDividendsReviewSearchResult): JSX.Element => {
  return <span>{`${momentUtc(item.reportingPeriodEndDate, StatDateFormats.DayBreakout)}`}</span>;
};

const onRenderSubmissionDate = (item: IDividendsReviewSearchResult): JSX.Element => {
  const today = moment().utc().startOf('day');
  const days = today.diff(moment(item.submissionDate).utc().startOf('day'), 'days');
  const string = days === 1 ? ' day old' : ' days old';
  return (
    <>
      <span>{`${momentUtc(item.submissionDate, StatDateFormats.DayBreakout)}`}</span>
      <br />
      <span> {`${days}${string}`}</span>
    </>
  );
};

const getStatusStyle = (item: number): PersonaPresence => {
  let approvedStyle = PersonaPresence.none;
  if (item === ReviewStatus.Accepted) {
    approvedStyle = PersonaPresence.online;
  } else if (item === ReviewStatus.Rejected) {
    approvedStyle = PersonaPresence.blocked;
  } else if (item === ReviewStatus.ChangesRequested) {
    approvedStyle = PersonaPresence.away;
  }

  return approvedStyle;
};

const onRenderStatus = (item: IDividendsReviewSearchResult): JSX.Element => {
  return <span>{item.reviewStatus}</span>;
};

const onRenderDividends = (item: IDividendsReviewSearchResult): JSX.Element => {
  const proposed = item.currencyCode
    ? formatCurrency(item.currencyCode, item.proposedDividend)
    : '--';
  const max = item.currencyCode ? formatCurrency(item.currencyCode, item.maxDividend) : '--';

  return (
    <span
      style={{
        display: 'block',
        textAlign: 'right',
        padding: 0,
      }}
    >
      <strong>Proposed: </strong> <span>{proposed}</span>
      <br />
      <strong>Max: </strong> <span>{max}</span>
    </span>
  );
};

const rightAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
};

const onRenderApprovals = (item: IDividendsReviewSearchResult): JSX.Element => {
  return (
    <Stack horizontal>
      <Persona
        text="Tax Dividend"
        coinSize={26}
        presence={getStatusStyle(item.taxStatusId)}
        styles={{ root: { flexBasis: '175px', width: '250px' } }}
      />
      <Persona
        text="Treasury"
        coinSize={26}
        presence={getStatusStyle(item.treasuryStatusId)}
        styles={{ root: { flexBasis: '175px', width: '250px' } }}
      />
    </Stack>
  );
};

const getColumns = (): Array<IColumn> => {
  return [
    {
      key: 'companyCode',
      name: 'Company',
      columnActionsMode: ColumnActionsMode.disabled,
      minWidth: 150,
      maxWidth: 150,
      onRender: onRenderCompanyCode,
    },
    {
      key: 'reportingPeriodEndDate',
      name: 'Reporting Period End Date',
      fieldName: 'reportingPeriodEndDate',
      minWidth: 150,
      maxWidth: 180,
      isCollapsible: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderReportingPeriodEndDate,
    },
    {
      key: 'filingDueDate',
      name: 'Filing Due Date',
      minWidth: 100,
      maxWidth: 140,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderFilingDueDate,
    },
    {
      key: 'submissionDate',
      name: 'Submission Date',
      fieldName: 'submissionDate',
      minWidth: 130,
      maxWidth: 130,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderSubmissionDate,
    },
    {
      key: 'dividendAmounts',
      name: 'Dividend Amounts',
      minWidth: 200,
      maxWidth: 250,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderDividends,
      styles: rightAlignStyle,
    },
    {
      key: 'approvals',
      name: 'Approvals',
      columnActionsMode: ColumnActionsMode.disabled,
      minWidth: 250,
      maxWidth: 275,
      onRender: onRenderApprovals,
    },
    {
      key: 'reviewStatus',
      name: 'Status',
      columnActionsMode: ColumnActionsMode.disabled,
      minWidth: 100,
      maxWidth: 150,
      onRender: onRenderStatus,
      isCollapsible: true,
    },
  ];
};

export default getColumns;
