import { IComboBox, IComboBoxOption, IDropdownOption, VirtualizedComboBox } from '@fluentui/react';
import React, { FormEvent, useState } from 'react';
import { ICompanyCreationRequestListPageState } from './CompanyCreationListPage';

interface IAcquistionProps {
  filterState: ICompanyCreationRequestListPageState;
  setFilterState: React.Dispatch<React.SetStateAction<ICompanyCreationRequestListPageState>>;
  acquisitionOptions: IDropdownOption[];
}

const AcquisitionFilter = ({
  filterState,
  setFilterState,
  acquisitionOptions,
}: IAcquistionProps): JSX.Element => {
  const [selectedKeys, setSelectedKeys] = useState<number[]>(filterState?.acquisitionIds);

  const onAcquisitionFilterChange = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption,
  ): void => {
    if (option) {
      const currentSelectedKeys = option.selected
        ? [...selectedKeys, option.key as number]
        : [...selectedKeys].filter((key) => key !== option.key);
      setSelectedKeys(currentSelectedKeys);
      setFilterState({ ...filterState, acquisitionIds: currentSelectedKeys });
    }
  };

  const formattedAcquisitionOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] =
      acquisitionOptions
        ?.map((acquisition: IDropdownOption) => ({
          key: acquisition.key,
          text: acquisition.text?.trim(),
        }))
        .sort((a: IDropdownOption, b: IDropdownOption) => a.text.localeCompare(b.text)) || [];

    options.unshift({ key: -1, text: 'None' });
    return options;
  };

  return (
    <>
      <VirtualizedComboBox
        label="Acquisitions"
        placeholder="No Filter Selected"
        selectedKey={selectedKeys}
        allowFreeform
        autoComplete="on"
        options={formattedAcquisitionOptions()}
        onChange={onAcquisitionFilterChange}
        multiSelect
      />
    </>
  );
};

export default AcquisitionFilter;
