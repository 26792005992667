import {
  Facepile,
  IFacepilePersona,
  Label,
  OverflowButtonType,
  PersonaInitialsColor,
  Stack,
} from '@fluentui/react';
import React from 'react';
import {
  labelStyles,
  processTemplateMilestoneRulesSpan,
} from '../../../../app/common/styles/CommonStyleObjects';
import {
  IProcessTemplateMilestone,
  IProcessTemplateMilestoneRule,
} from '../../../../utils/types/IProcessTemplate';
import SpanWithLabel from '../../../common/SpanWithLabel';
import { MilestoneRuleType } from '../../../companies/processes/Interfaces';
import ProcessTemplateMilestoneDrilldownInfo from './ProcessTemplateMilestoneDrilldownInfo';

interface IProcessTemplateMilestoneContentProps {
  milestone: IProcessTemplateMilestone;
}

const ProcessTemplateMilestoneContent = (
  props: IProcessTemplateMilestoneContentProps,
): JSX.Element => {
  const { milestone } = props;
  const personas: IFacepilePersona[] = milestone.signoffRoles.map(
    (signoffRole: { name: string }) => {
      return {
        personaName: signoffRole.name,
      };
    },
  );

  const renderRoleAssignment = (): JSX.Element => {
    return (
      <>
        <Label styles={labelStyles}>Sign Off Role(s)</Label>
        {personas.length > 0 && (
          <Facepile
            personas={personas}
            maxDisplayablePersonas={5}
            overflowButtonType={OverflowButtonType.descriptive}
          />
        )}
        {personas.length === 0 && (
          <Facepile
            personas={[
              {
                personaName: 'Not Assigned',
                imageInitials: 'NA',
                initialsColor: PersonaInitialsColor.warmGray,
              },
            ]}
            maxDisplayablePersonas={1}
          />
        )}
      </>
    );
  };

  const renderDurationLabels = (duration: number) => {
    const durationString = duration > 0 ? duration.toString() : '--';
    return (
      <Stack horizontal verticalAlign="baseline">
        <SpanWithLabel labelText="Duration" value={durationString} />
      </Stack>
    );
  };

  const lockdownModeText = (lockdownType: string) => {
    return `No edits to ${lockdownType} is allowed after this milestone has been signed off`;
  };

  const renderMilestoneRulesList = (milestoneRule: IProcessTemplateMilestoneRule): JSX.Element => {
    let milestoneRulesString = '';
    switch (milestoneRule.type) {
      case MilestoneRuleType.reasonForViolation:
        milestoneRulesString = 'Requires reason for filing violation';
        break;
      case MilestoneRuleType.documentUpload:
        milestoneRulesString = 'Requires upload documents';
        break;
      case MilestoneRuleType.dividendGate:
        milestoneRulesString = 'Requires dividend information';
        break;
      case MilestoneRuleType.auditOpinion:
        milestoneRulesString = 'Requires audit opinion information';
        break;
      case MilestoneRuleType.celaAgmStatus:
        milestoneRulesString = 'Requires CELA AGM Status';
        break;
      case MilestoneRuleType.agmDetail:
        milestoneRulesString = 'Requires AGM review';
        break;
      case MilestoneRuleType.agmDetailLockdownMode:
        milestoneRulesString = lockdownModeText('AGM review');
        break;
      case MilestoneRuleType.dividendLockdownMode:
        milestoneRulesString = lockdownModeText('dividends');
        break;
      case MilestoneRuleType.celaAgmStatusLockdownMode:
        milestoneRulesString = lockdownModeText('the CELA AGM status');
        break;
      case MilestoneRuleType.auditOpinionLockdownMode:
        milestoneRulesString = lockdownModeText('the audit opinion');
        break;
      default:
        milestoneRulesString = milestoneRule.type;
        break;
    }
    return (
      <li key={milestoneRule.type} style={{ listStyleType: 'none', marginBottom: 20 }}>
        <span className={processTemplateMilestoneRulesSpan}>{milestoneRulesString}</span>
      </li>
    );
  };
  return (
    <Stack wrap>
      <Stack horizontal verticalAlign="baseline" tokens={{ childrenGap: 30 }} wrap>
        <Stack.Item styles={{ root: { flex: '0 0 15%' } }}>{renderRoleAssignment()}</Stack.Item>
        <Stack.Item>{renderDurationLabels(milestone.duration)}</Stack.Item>
        <div>
          <SpanWithLabel labelText="Category" value={milestone.milestoneCategoryName} />
        </div>
        {milestone.milestoneRules && milestone.milestoneRules.length > 0 && (
          <Stack grow={1}>
            <Stack.Item align="end">
              <ul>
                {milestone.milestoneRules.map((milestoneRule: IProcessTemplateMilestoneRule) => {
                  return renderMilestoneRulesList(milestoneRule);
                })}
              </ul>
            </Stack.Item>
          </Stack>
        )}
      </Stack>
      <Stack.Item>
        <ProcessTemplateMilestoneDrilldownInfo milestone={milestone} />
      </Stack.Item>
    </Stack>
  );
};
export default ProcessTemplateMilestoneContent;
