import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { MessageBar, MessageBarType, TextField } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { addTimestampBeforeExtension } from '../../../../utils/Helpers';
import { ICompanyCodeParams } from '../../ParamTypes';
import validateFile from '../../../../utils/validation/validateFiles';
import { IDocument, IFilesPayload, UploadDocument } from '../../../../utils/statApi/DocumentApi';

enum UploadStatus {
  Ready,
  Uploading,
  Error,
  Success,
}

interface FileInputTextboxProps {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  onFileUploadComplete?: (files: IDocument[]) => void;
  errorMessage?: string;
  multipleFiles?: boolean;
  setValidationError?: Dispatch<SetStateAction<string>>;
}

const FileInputTextbox = ({
  inputValue,
  setInputValue,
  onFileUploadComplete,
  errorMessage,
  multipleFiles = true,
  setValidationError,
}: FileInputTextboxProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [fileUploadState, setFileUploadState] = useState({
    uploadStatus: UploadStatus.Ready,
    errorMessage: '',
    uploadProgress: 0,
  });
  const [statusIcon, setStatusIcon] = useState('');
  const showFileDialog = (): void => inputFileRef.current && inputFileRef.current.click();

  const onProgress = (percentage: number): void => {
    setFileUploadState({
      uploadStatus: UploadStatus.Uploading,
      uploadProgress: percentage,
      errorMessage: '',
    });
  };
  const onSuccess = (files: IDocument[]): void => {
    setStatusIcon('Accept');
    setFileUploadState({
      uploadProgress: 0,
      uploadStatus: UploadStatus.Success,
      errorMessage: '',
    });
    onFileUploadComplete(files);
    inputFileRef.current.value = null;
  };
  const onError = (serverErrorMessage: string): void => {
    setStatusIcon('Cancel');
    setFileUploadState({
      uploadProgress: 0,
      uploadStatus: UploadStatus.Error,
      errorMessage: serverErrorMessage,
    });
  };
  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValidationError(null);

    if (!event.target.files || !event.target.files.length || event.target.files.length === 0) {
      return;
    }
    let filesToUpload = Array<IFilesPayload>();
    let payloadSize = 0;
    const file = event.target.files.item(0);
    const fileNameWithTimestamp = addTimestampBeforeExtension(file.name);
    const newFileObject = {
      file,
      fileNameWithTimeStamp: fileNameWithTimestamp,
      fileName: file.name,
      fileType: file.type,
      validationErrors: validateFile(file),
    };
    filesToUpload = filesToUpload.concat([newFileObject]);
    payloadSize += file.size;

    const filesWithErrors = filesToUpload.filter(
      (fileToUpload) => fileToUpload.validationErrors && fileToUpload.validationErrors.length > 0,
    );

    if (filesWithErrors.length > 0) {
      setValidationError(filesWithErrors[0].validationErrors[0].title);
    } else {
      setInputValue(file.name);
      setStatusIcon('HourGlass');
      setFileUploadState({
        uploadProgress: 0,
        errorMessage: '',
        uploadStatus: UploadStatus.Uploading,
      });

      UploadDocument(filesToUpload, payloadSize, onProgress, onError, onSuccess, companyCode);
    }
  };

  return (
    <div className="input-file">
      <input
        style={{ display: 'none' }}
        ref={inputFileRef}
        type="file"
        multiple={multipleFiles}
        onChange={onFileInputChange}
      />
      <TextField
        placeholder="Click to upload a file"
        value={inputValue}
        readOnly
        type="string"
        resizable={false}
        onClick={showFileDialog}
        iconProps={{ iconName: statusIcon }}
        errorMessage={errorMessage}
      />
      {fileUploadState.uploadStatus === UploadStatus.Error && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline>
          {fileUploadState.errorMessage}
        </MessageBar>
      )}
    </div>
  );
};
export default FileInputTextbox;
