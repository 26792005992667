import { gql } from '@apollo/client';

const SERVICE_CONTACT_FRAGMENT = gql`
  fragment ServiceContactUser on ServiceContactDto {
    id
    graphId
    text: name
    name
    firstName
    lastName
    email
    alias
    isInvalidUser
    personnelNumber
  }
`;

const SERVICE_CONTACT_INFO_FRAGMENT = gql`
  fragment ServiceContactInfo on ServiceContactsDto {
    id
    isOneFinance
    isMcaps
    statutoryController {
      ...ServiceContactUser
    }
    regionalControllers {
      ...ServiceContactUser
    }
    treasurySupportReps {
      ...ServiceContactUser
    }
    taxSupportReps {
      ...ServiceContactUser
    }
    generalManagers {
      ...ServiceContactUser
    }
    vpPresidentDirectors {
      ...ServiceContactUser
    }
    davaDealLeads {
      ...ServiceContactUser
    }
    bpoContacts {
      ...ServiceContactUser
    }
    finOpsContacts {
      ...ServiceContactUser
    }
    customContactGroups {
      contactGroups {
        id
        label
        contacts {
          ...ServiceContactUser
        }
      }
    }
  }
  ${SERVICE_CONTACT_FRAGMENT}
`;

const GET_SERVICE_CONTACTS_QUERY = gql`
  query ServiceContacts($companyCode: String) {
    serviceContacts(companyCode: $companyCode) {
      ...ServiceContactInfo
    }
  }
  ${SERVICE_CONTACT_INFO_FRAGMENT}
`;

export const UPDATE_SERVICE_CONTACTS_MUTATION = gql`
  mutation updateServiceContacts(
    $companyCode: String!
    $serviceContacts: ServiceContactUserAssignmentsDtoInput!
  ) {
    updateServiceContactUserAssignments(
      companyCode: $companyCode
      serviceContacts: $serviceContacts
    ) {
      ...ServiceContactInfo
    }
  }
  ${SERVICE_CONTACT_INFO_FRAGMENT}
`;

export const UPDATE_CUSTOM_CONTACTS_MUTATION = gql`
  mutation updateCustomContacts(
    $companyCode: String!
    $contactGroups: CustomContactGroupsDtoInput!
  ) {
    updateCustomContactGroups(companyCode: $companyCode, contactGroups: $contactGroups) {
      ...ServiceContactInfo
    }
  }
  ${SERVICE_CONTACT_INFO_FRAGMENT}
`;

export const GET_INVALID_SERVICE_CONTACTS_QUERY = gql`
  query GetInvalidServiceContacts(
    $invalidServiceContactSearchDto: InvalidServiceContactSearchDtoInput
    $paginationDto: PaginationDtoInput
  ) {
    invalidServiceContacts(
      invalidServiceContactSearchDto: $invalidServiceContactSearchDto
      paginationDto: $paginationDto
    ) {
      items {
        graphId
        email
        name
        email
        firstName
        lastName
        alias
        contactType
        companyCode
        companySapName
        companyPhysicalCountry
        entityStatus
      }
      totalCount
      currentPage
      pageSize
    }
  }
`;

export default GET_SERVICE_CONTACTS_QUERY;
