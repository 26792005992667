import { useMutation, useQuery } from '@apollo/client';
import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import {
  CANCEL_COMPANY_CREATION_REQUEST,
  GET_COMPANY_CREATION_REQUEST_DETAILS,
} from '../../../utils/statApi/CompanyCreationApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import Page from '../../common/page/Page';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import { CompanyCreationStatus, FinancialType } from '../types';
import CompanyInformationPage from './CompanyInformationPage';
import DocumentsPageWrapper from './documentsTab/DocumentsPageWrapper';
import FunctionalCurrencyPage from './FunctionalCurrencyPage';
import Header from './Header';
import OverviewPage from './OverviewPage';

const CompanyRequestDetailPage = (): JSX.Element => {
  const { id } = useParams<ICompanyCreationParams>();
  const { data, loading, error } = useQuery(GET_COMPANY_CREATION_REQUEST_DETAILS, {
    variables: {
      companyCreationId: parseInt(id, 10),
    },
  });

  const [cancelCompanyCreationRequest, { loading: mutationLoading, error: mutationError }] =
    useMutation(CANCEL_COMPANY_CREATION_REQUEST);

  const companyCreationRequestInfo = data?.companyCreationRequestDetails;
  const companyName = companyCreationRequestInfo?.companyName;

  const isRequestReadOnly =
    companyCreationRequestInfo?.statusId === CompanyCreationStatus.Canceled ||
    companyCreationRequestInfo?.statusId === CompanyCreationStatus.Complete;

  const isRequestAssigned =
    companyCreationRequestInfo?.statusId === CompanyCreationStatus.PendingSubledgerInfo ||
    companyCreationRequestInfo?.statusId === CompanyCreationStatus.PendingStatToolSetup;

  const isNonTransactional =
    companyCreationRequestInfo?.financialType.id === FinancialType.NonTransactional;

  const isShadowCompanyRequest =
    companyCreationRequestInfo?.financialType.id === FinancialType.ShadowCompany;

  const canCancelRequest = !isRequestReadOnly && (!isRequestAssigned || isNonTransactional);

  const handleCancelRequest = () => {
    cancelCompanyCreationRequest({
      variables: {
        requestId: parseInt(id, 10),
      },
    });
  };

  return (
    <>
      <Header
        canCancelRequest={canCancelRequest}
        onCancelClick={handleCancelRequest}
        showBackToListButton
        headerText={companyName}
        showDocumentsButton={!isShadowCompanyRequest}
      />
      <LoadingErrorMessage loading={loading} error={error} />
      <LoadingErrorMessage loading={mutationLoading} error={mutationError} />
      {mutationLoading && <Spinner size={SpinnerSize.large} />}
      <Switch>
        <Page
          path="/company-creation/:id/overview"
          exact
          title="Company Creation | Overview"
          render={(): JSX.Element => {
            return (
              <OverviewPage
                loading={loading}
                companyRequestInitialInfo={companyCreationRequestInfo}
              />
            );
          }}
        />
        <Page
          path="/company-creation/:id/functional-currency"
          exact
          component={FunctionalCurrencyPage}
          title="Company Creation | Funcitonal Currency"
        />
        <Page
          path="/company-creation/:id/company-information"
          exact
          title="Company Creation | Company Info"
          render={(): JSX.Element => {
            return (
              <CompanyInformationPage companyRequestInitialInfo={companyCreationRequestInfo} />
            );
          }}
        />
        {!isShadowCompanyRequest && (
          <Page
            path="/company-creation/:id/documents"
            exact
            component={DocumentsPageWrapper}
            title="Company Creation | Documents"
          />
        )}
      </Switch>
    </>
  );
};

export default CompanyRequestDetailPage;
