import { IDividendPayment } from '../serviceContacts/interfaces';

export default function transformDividendPaymentDto(
  dividendPayment: IDividendPayment,
): IDividendPayment {
  const submitValue: IDividendPayment = {
    id: dividendPayment.id,
    dividendId: dividendPayment.dividendId,
    dividendPaymentType: {
      id: dividendPayment.dividendPaymentType.id,
      fieldName: dividendPayment.dividendPaymentType.fieldName,
    },
    dividendType: dividendPayment.dividendType.toUpperCase(),
    currencyCode: dividendPayment.currencyCode,
    amount: parseFloat(dividendPayment.amount?.toString()),
    fxRate: parseFloat(dividendPayment.fxRate?.toString()),
    whAmount: parseFloat(dividendPayment.whAmount?.toString()),
    whRate: parseFloat(dividendPayment.whRate?.toString()),
    paymentDate: dividendPayment.paymentDate,
  };

  return submitValue;
}
