import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';

interface IAgmAgingDropdownProps {
  onAgingUpdate: (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
  selectedOption: string;
  label: string;
}

const AgmAgingDropdown = (props: IAgmAgingDropdownProps): JSX.Element => {
  const { onAgingUpdate, selectedOption, label } = props;

  const agmAgingDateDiffOptions: IDropdownOption[] = [
    { key: null, text: '--' },
    { key: 'LESSTHAN30', text: 'Less than 30 days' },
    { key: 'OVER30', text: 'Over 30 days' },
    { key: 'BETWEEN30AND60', text: 'Between 30 and 60 days' },
    { key: 'BETWEEN60AND90', text: 'Between 60 and 90 days' },
    { key: 'BETWEEN90AND120', text: 'Between 90 and 120 days' },
    { key: 'OVER120', text: 'Over 120 days' },
  ];

  return (
    <Dropdown
      label={label}
      onChange={onAgingUpdate}
      selectedKey={selectedOption}
      options={agmAgingDateDiffOptions}
    />
  );
};

export default AgmAgingDropdown;
