import { ITag, TagPicker } from '@fluentui/react';
import React from 'react';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import InlineFormInputErrorMessage from '../../errorContent/InlineFormInputErrorMessage';

interface ITagPickerWrapperProps {
  singularLabel: string;
  errorMessage?: string;
  pluralLabel: string;
  selectedItems: ITag[];
  setSelectedItems: (items: ITag[]) => void;
  itemsFromSource: IDropdownReference[];
}
const TagPickerWrapper = (props: ITagPickerWrapperProps): JSX.Element => {
  const {
    singularLabel,
    pluralLabel,
    selectedItems,
    setSelectedItems,
    itemsFromSource,
    errorMessage,
  } = props;

  const removeFromArray = (item: IDropdownReference, removeItems: ITag[]): ITag[] => {
    return removeItems.filter((selectedItem) => {
      return selectedItem.key.toString().toLowerCase() === item.id.toString().toLowerCase();
    });
  };
  const onResolveSuggestions = (filterText: string, selectedItemTags: ITag[]): ITag[] => {
    const filteredItems = itemsFromSource.filter((item: IDropdownReference) => {
      return item.fieldName.toLowerCase().includes(filterText.toLowerCase());
    });
    const filteredItemsWithoutSelected = filteredItems.filter((item: IDropdownReference) => {
      const inSelectedItems = removeFromArray(item, selectedItems);
      return inSelectedItems.length === 0 ? item : false;
    });
    return filteredItemsWithoutSelected.map(({ id, fieldName }): ITag => {
      return {
        key: id,
        name: fieldName,
      };
    });
  };

  const onEmptyResolveSuggestions = (selectedItemTags: ITag[]) => {
    const filteredItems = itemsFromSource.filter((item: IDropdownReference) => {
      const inSelectedItems = removeFromArray(item, selectedItems);
      return inSelectedItems.length === 0 ? item : false;
    });
    return filteredItems.map(({ id, fieldName }): ITag => {
      return {
        key: id,
        name: fieldName,
      };
    });
  };
  const getTextFromItem = (item: ITag): string => {
    return item.name;
  };

  const onItemSelected = (item?: ITag): ITag | PromiseLike<ITag> => {
    return item;
  };
  const addSelectedItems = (items: ITag[]): void => {
    setSelectedItems(items);
  };
  const tagPickerStyles = {
    root: {
      marginTop: '0',
    },
  };
  return (
    <>
      <TagPicker
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel={`Remove ${singularLabel}`}
        pickerSuggestionsProps={{
          noResultsFoundText: 'No results found',
        }}
        inputProps={{
          id: singularLabel.trim(),
          'aria-label': `Search ${pluralLabel}`,
          placeholder: `Search ${pluralLabel}`,
        }}
        resolveDelay={300}
        selectedItems={selectedItems}
        onChange={addSelectedItems}
        onItemSelected={onItemSelected}
        getTextFromItem={getTextFromItem}
        onEmptyResolveSuggestions={onEmptyResolveSuggestions}
        styles={tagPickerStyles}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </>
  );
};
export default TagPickerWrapper;
