import { TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';

interface ICompanyCodeInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormMethods<any>;
  defaultValue: string | number;
  codeLength?: number;
  fieldName: string;
  label: string;
  readOnly?: boolean;
}

const CompanyCodeInput = ({
  form,
  defaultValue,
  codeLength,
  fieldName,
  label,
  readOnly,
}: ICompanyCodeInputProps): JSX.Element => {
  const { control, errors, setValue } = form;

  return (
    <Controller
      as={TextField}
      readOnly={readOnly}
      type="string"
      id={fieldName}
      name={fieldName}
      label={label}
      control={control}
      defaultValue={defaultValue || ''}
      required
      disabled={readOnly}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
      ): void => {
        setValue(fieldName, newValue);
      }}
      rules={{
        required: `${label} is required`,
        min: {
          value: 1,
          message: `${label} has to be a number greater than 0`,
        },
        minLength: {
          value: codeLength,
          message: `${label} must be ${codeLength} digits long`,
        },
        maxLength: {
          value: codeLength,
          message: `${label} must be ${codeLength} digits long`,
        },
        pattern: {
          value: /^[1-9][0-9]+$/i,
          message: `${label} must be a whole number that does not start with 0`,
        },
      }}
      errorMessage={errors[fieldName]?.message}
    />
  );
};

export default CompanyCodeInput;
