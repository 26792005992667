import { IColumn, Link } from '@fluentui/react';
import {
  IDashboardFilters,
  IMetricItem,
  MetricColor,
  getMetricColor,
} from 'fcw-dashboard-components';
import React from 'react';
import moment from 'moment';
import {
  IProcessStatusSummary,
  IProcessTypeSummary,
  ProcessStatusSummaryCategory,
} from './IProcessStatusSummary';

import FeatureFlags from '../../../utils/featureFlags/FeatureFlags';
import FeatureFlagContext from '../../../utils/featureFlags/FeatureFlagContext';
import {
  convertFiscalPeriodToCalendarMonth,
  convertFiscalYearToCalendarYear,
} from '../../../utils/Helpers';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';

export const getMetricItems = (processStatusSummary: IProcessStatusSummary): IMetricItem[] => {
  return [
    {
      label: 'Overdue',
      value: processStatusSummary?.countOfProcessesWithLateMilestones,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.danger),
      tooltipMessage: 'Processes with a past due milestone',
    },
    {
      label: 'Upcoming',
      value: processStatusSummary?.countOfProcessesWithUpcomingMilestones,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.warning),
      tooltipMessage: 'Processes with milestone approaching due date',
    },
    {
      label: 'On-Track',
      value: processStatusSummary?.countOfProcessesWithFutureMilestones,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.successLight),
      tooltipMessage: 'Processes with on-track milestones',
    },
    {
      label: 'Completed',
      value: processStatusSummary?.countOfCompletedProcesses,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.success),
      tooltipMessage: 'Completed filings',
    },
  ];
};

export const convertFiscalPeriodToDate = (
  period: number,
  year: number,
  getLastDayOfMonth: boolean,
): Date => {
  if (period == null || year == null) {
    return null;
  }
  const month = convertFiscalPeriodToCalendarMonth(period);
  const calendarYear = convertFiscalYearToCalendarYear(year, month);
  let day = 1;
  if (getLastDayOfMonth) {
    day = moment(new Date(calendarYear, month, 0)).daysInMonth();
  }

  return moment.utc(`${calendarYear}-${month}-${day}`).toDate();
};

export const getDate = (date: string): Date => {
  if (date == null || date === '') {
    return null;
  }
  return moment.utc(date).toDate();
};

export const getDetailContentColumns = (
  baseName: string,
  filters: IDashboardFilters,
): Array<IColumn> => {
  const featureFlags: FeatureFlags = React.useContext(FeatureFlagContext);

  const newUrlObject = new URLSearchParams();

  // Show my items query string
  newUrlObject.append('showMyItems', filters.forMeOnly.toString());

  // Milestone due date range query string
  const milestoneDueDateStartDate = filters.filterByPeriod
    ? convertFiscalPeriodToDate(filters.fiscalPeriod, filters.fiscalYear, false)
    : getDate(filters.dueDateRangeStart);
  const milestoneDueDateEndDate = filters.filterByPeriod
    ? convertFiscalPeriodToDate(filters.fiscalPeriod, filters.fiscalYear, true)
    : getDate(filters.dueDateRangeEnd);

  if (milestoneDueDateStartDate != null && milestoneDueDateEndDate != null) {
    newUrlObject.append(
      'milestoneDueDateStartDate',
      momentUtc(milestoneDueDateStartDate, StatDateFormats.Default),
    );
    newUrlObject.append(
      'milestoneDueDateEndDate',
      momentUtc(milestoneDueDateEndDate, StatDateFormats.Default),
    );
  }

  // Company codes query string
  if (filters?.companyCodes?.length > 0) {
    filters.companyCodes.forEach((value) => {
      newUrlObject.append('companyCodes', value.toString());
    });
  }

  const renderDisplayValue = (
    category: ProcessStatusSummaryCategory,
    processTypeId: number,
    opsDetailIds: (number | string)[],
    value: number,
  ): JSX.Element => {
    const urlWithCategory = new URLSearchParams(newUrlObject);
    // Process type query string
    urlWithCategory.append('processTypeIds', processTypeId.toString());

    // Process status and Ready milestone status query string
    if (category === ProcessStatusSummaryCategory.Complete) {
      urlWithCategory.append('processStatuses', category.toString());
    } else {
      urlWithCategory.append('processStatuses', 'ACTIVE');
      urlWithCategory.append('readyMilestoneStatuses', category.toString());
    }

    if (opsDetailIds?.length > 0) {
      opsDetailIds.forEach((item) => {
        urlWithCategory.append('opsDetailIds', item.toString());
      });
    }

    const displayValue = Intl.NumberFormat('en-US').format(value);

    if (featureFlags.flags.StatTileLinks) {
      return (
        <Link href={`${baseName}/processes?${urlWithCategory.toString()}`}>{displayValue}</Link>
      );
    }
    return <>{displayValue}</>;
  };

  return [
    {
      key: 'processTypeName',
      name: 'Process Types',
      fieldName: 'processTypeName',
      minWidth: 50,
      maxWidth: 150,
    },
    {
      key: 'countOfProcessesWithLateMilestones',
      name: 'Overdue',
      fieldName: 'countOfProcessesWithLateMilestones',
      minWidth: 50,
      maxWidth: 150,
      onRender: (item: IProcessTypeSummary) => {
        return renderDisplayValue(
          ProcessStatusSummaryCategory.Overdue,
          item.processTypeId,
          filters.opsOrgDetailIds,
          item.countOfProcessesWithLateMilestones,
        );
      },
    },
    {
      key: 'countOfProcessesWithUpcomingMilestones',
      name: 'Upcoming',
      fieldName: 'countOfProcessesWithUpcomingMilestones',
      minWidth: 50,
      maxWidth: 150,
      onRender: (item: IProcessTypeSummary) => {
        return renderDisplayValue(
          ProcessStatusSummaryCategory.Upcoming,
          item.processTypeId,
          filters.opsOrgDetailIds,
          item.countOfProcessesWithUpcomingMilestones,
        );
      },
    },
    {
      key: 'countOfProcessesWithFutureMilestones',
      name: 'On-Track',
      fieldName: 'countOfProcessesWithFutureMilestones',
      minWidth: 50,
      maxWidth: 150,
      onRender: (item: IProcessTypeSummary) => {
        return renderDisplayValue(
          ProcessStatusSummaryCategory.OnTrack,
          item.processTypeId,
          filters.opsOrgDetailIds,
          item.countOfProcessesWithFutureMilestones,
        );
      },
    },
    {
      key: 'countOfCompletedProcesses',
      name: 'Completed',
      fieldName: 'countOfCompletedProcesses',
      minWidth: 50,
      maxWidth: 150,
      onRender: (item: IProcessTypeSummary) => {
        return renderDisplayValue(
          ProcessStatusSummaryCategory.Complete,
          item.processTypeId,
          filters.opsOrgDetailIds,
          item.countOfCompletedProcesses,
        );
      },
    },
  ];
};
