import React, { FormEvent } from 'react';
import { IDropdownOption, IPersonaProps } from '@fluentui/react';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import ListFilters from '../../../common/lists/ListFilters';
import CompanyCollapsibleChecklist from '../../../common/formFields/CompanyCollapsibleChecklist';
import CountryCollapsibleChecklist from '../../../common/formFields/CountryCollapsibleChecklist';
import PeoplePickerCollapsibleChecklist from '../../../common/formFields/PeoplePickerCollapsibleChecklist';
import Collapsible from '../../../common/formFields/Collapsible';
import AgmAgingDropdown from '../../../common/formFields/AgmAgingDropdown';
import FiscalYearCollapsiblePicker from '../../../common/formFields/FiscalYearCollapsiblePicker';
import AgmTrackerStatusCollapsibleChecklist from '../../../common/formFields/AgmTrackerStatusCollapsibleChecklist';
import { IServiceContact } from '../../serviceContacts/interfaces';

/** move to central place */
export interface IListFiltersState {
  filterTags: IGenericFilterTag[];
  filterPanelVisible: boolean;
}
export interface IListPageFiltersProps {
  onClosePanel?: () => void;
  currentFilterValues?: IGenericFilterTag[];
  onFilterUpdate?: (formData: IGenericFilterTag[]) => void;
}
const AgmTrackerFilters = (props: IListPageFiltersProps): JSX.Element => {
  const { onClosePanel, currentFilterValues, onFilterUpdate } = props;

  const onAgingUpdate = (e: FormEvent, item: IDropdownOption) => {
    const agingFilter = currentFilterValues?.find((f) => f.dataKey === 'aging');

    if (agingFilter) {
      agingFilter.values = [{ name: item.text, key: item.key }];
    } else {
      currentFilterValues.push({
        dataKey: 'aging',
        title: 'Aging',
        values: [{ name: item.text, key: item.key }],
      });
    }

    onFilterUpdate([...currentFilterValues]);
  };

  const agingFilter = currentFilterValues?.find((f) => f.dataKey === 'aging');
  const agingSelectionOption = agingFilter?.values ? agingFilter.values[0] : null;

  const mapToUser = (people: IPersonaProps[]) => {
    return people?.map((person: IServiceContact) => {
      const persona: { name: string; graphId: string; text: string } = {
        graphId: person.key as string,
        name: person.name,
        text: person.name,
      };
      return persona;
    });
  };

  const celaAssignees = mapToUser(
    currentFilterValues?.find((f) => f.dataKey === 'assignee')?.values,
  );

  const statControllers = mapToUser(
    currentFilterValues?.find((f) => f.dataKey === 'controller')?.values,
  );

  return (
    <ListFilters
      onClosePanel={onClosePanel}
      onFilterUpdate={onFilterUpdate}
      currentFilterValues={currentFilterValues}
    >
      <FiscalYearCollapsiblePicker label="Agm Due Date" dataKey="agmDueDate" />
      <FiscalYearCollapsiblePicker label="Filing Due Date" dataKey="filingDueDate" />
      <FiscalYearCollapsiblePicker label="Reporting Period End Date" dataKey="reportingEndDate" />
      <Collapsible text="Aging">
        <AgmAgingDropdown
          label=""
          onAgingUpdate={onAgingUpdate}
          selectedOption={(agingSelectionOption?.key as string) ?? null}
        />
      </Collapsible>
      <AgmTrackerStatusCollapsibleChecklist label="Agm Tracker Status" dataKey="status" />
      <CompanyCollapsibleChecklist label="Company" dataKey="company" />
      <CountryCollapsibleChecklist label="Country" dataKey="country" />
      <PeoplePickerCollapsibleChecklist
        label="CELA Assignee"
        dataKey="assignee"
        selectedItems={celaAssignees}
      />
      <PeoplePickerCollapsibleChecklist
        label="STAT Controller"
        dataKey="controller"
        selectedItems={statControllers}
      />
    </ListFilters>
  );
};
export default AgmTrackerFilters;
