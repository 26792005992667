import { IPersonaProps, NormalPeoplePicker } from '@fluentui/react';
import React from 'react';
import Collapsible from './Collapsible';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import searchForUsers from '../../../utils/microsoftGraphApi/userSearch';

export interface IChecklistImplementationProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  label: string;
  dataKey: string;
  selectedItems: IPersonaProps[];
}

const PeoplePickerCollapsibleChecklist = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, label, dataKey, selectedItems } = props;

  const onCheckChanged = (items: { text: string; graphId: string }[]): void => {
    onSelectedItems({
      dataKey,
      title: label,
      values: items.map((item) => {
        return { name: item.text, key: item.graphId, text: item.text };
      }),
    });
  };

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  return (
    <Collapsible text={label}>
      <NormalPeoplePicker
        onResolveSuggestions={onResolveSuggestions}
        onChange={onCheckChanged}
        defaultSelectedItems={selectedItems}
      />
    </Collapsible>
  );
};

export default PeoplePickerCollapsibleChecklist;
