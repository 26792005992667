import { Stack } from '@fluentui/react';
import React from 'react';
import { FieldValues, UseFormMethods } from 'react-hook-form';
import { IAddressCharacterLimits } from '../../../../utils/types/IAddress';
import { ICompanyLegalAddress } from '../../../../utils/types/ICompanyLegalAddress';
import AddressFields from '../../../companyCreation/details/AddressFields';

interface ICompanyAddressFormProps {
  form: UseFormMethods<FieldValues>;
  data: ICompanyLegalAddress;
}

const addressCharacterLimits: IAddressCharacterLimits = {
  streetNumber: 200,
  streetNumber2: 200,
  city: 100,
  postalZipCode: 20,
};

const CompanyAddressForm = ({ form, data }: ICompanyAddressFormProps): JSX.Element => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <AddressFields
        fieldName="legallyRegisteredAddress"
        form={form}
        data={data?.legallyRegisteredAddress}
        displayDoubleRequiredMarker={false}
        onlyRequireCountry
        characterLimits={addressCharacterLimits}
      />
    </Stack>
  );
};

export default CompanyAddressForm;
