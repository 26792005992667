import * as React from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import {
  TagPicker,
  ITag,
  IInputProps,
  IBasePickerSuggestionsProps,
} from '@fluentui/react/lib/Pickers';
import { Label, Stack } from '@fluentui/react';
import { GET_COMPLIANCE_REQUIREMENTS } from '../../utils/statApi/CountryApi';
import { IComplianceRequirement } from './CountryConfiguration.config';

interface IComplianceRequirementPickerProps {
  isDisabled?: boolean;
  selectedItem: ITag[];
  setSelectedItem: (item: ITag[]) => void;
}

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested Requirements',
  noResultsFoundText: 'No requirements found',
};

const getTextFromItem = (item: ITag) => item.name;

const ComplianceRequirementPicker = ({
  setSelectedItem,
  selectedItem,
  isDisabled,
}: IComplianceRequirementPickerProps): JSX.Element => {
  const [loadComplianceRequirements] = useLazyQuery(GET_COMPLIANCE_REQUIREMENTS);
  const onResolveSuggestions = (filterText: string): Promise<ITag[]> => {
    return loadComplianceRequirements({ variables: { keyword: filterText } }).then(
      (
        result: QueryResult<
          { complianceRequirements: IComplianceRequirement[] },
          OperationVariables
        >,
      ) => {
        const complianceRequirements: IComplianceRequirement[] =
          result?.data?.complianceRequirements;
        return complianceRequirements?.map((complianceRequirement): ITag => {
          return {
            key: complianceRequirement.id.toString(),
            name: complianceRequirement.name,
          };
        });
      },
    );
  };

  const addSelectedItem = (items: ITag[]): void => {
    setSelectedItem(items);
  };

  const labelText = 'Compliance Requirement';
  const controlName = 'complianceRequirement';
  const inputProps: IInputProps = {
    title: labelText,
    'aria-label': `${labelText} Compliance Requirement Picker`,
    name: controlName,
    id: `${controlName}Picker`,
    required: true,
  };

  const onItemSelected = (item?: ITag): ITag | PromiseLike<ITag> => {
    return {
      key: item.key,
      name: item.name,
    };
  };

  return (
    <Stack>
      <Label htmlFor={`${controlName}Picker`}>{labelText}</Label>
      <TagPicker
        itemLimit={1}
        defaultSelectedItems={selectedItem}
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Compliance Requirement"
        onItemSelected={onItemSelected}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={inputProps}
        onChange={addSelectedItem}
        disabled={isDisabled}
        onEmptyResolveSuggestions={(): ITag[] => {
          return [];
        }}
      />
    </Stack>
  );
};
export default ComplianceRequirementPicker;
