import { Label, Stack } from '@fluentui/react';
import React, { useContext } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import UserContext from '../../../../utils/authorization/UserContext';
import momentUtc from '../../../../utils/DateFormatter';
import { IDocument } from '../../../../utils/statApi/DocumentApi';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import StatDateFormats from '../../../../utils/types/DateFormats';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import FileListUpload from '../../../common/formFields/fileUpload/fileSelector';
import FileSelectorItem from '../../../common/formFields/fileUpload/fileSelectorItem';
import { RequiredMarker } from '../../../common/formFields/RequiredMarker';
import RequiredFieldsNotification from '../../RequiredNotification';
import { IFunctionalCurrency, IFunctionalCurrencyDocument, RequiredFieldsType } from '../types';

interface IFunctionalCurrencyFormProps {
  form: UseFormMethods<IFunctionalCurrency>;
  data: IFunctionalCurrency;
}

const FunctionalCurrencyForm = ({ form, data }: IFunctionalCurrencyFormProps): JSX.Element => {
  const { setValue, control, watch, trigger } = form;
  const user: StatUser = useContext(UserContext);

  const uploadedDocumentWatch = watch(
    'functionalCurrencyDocument',
    data?.functionalCurrencyDocument,
  );

  const deleteDocument = (): void => {
    setValue('functionalCurrencyDocument', null);
    trigger();
  };

  const validateFileNameLength = (newVal: IFunctionalCurrencyDocument): string => {
    if (newVal && newVal.title.length > 100) {
      return 'The selected filename is over the limit of 100 characters';
    }

    return null;
  };

  const renderDocumentList = (): JSX.Element => {
    const doc = uploadedDocumentWatch;
    return doc ? (
      <Stack key={doc.url}>
        <FileSelectorItem
          url={doc.url}
          index={0}
          user={doc.uploader ? doc.uploader : user}
          fileName={doc.title}
          allowDownload
          allowViewInTab
          key={doc.url}
          uiDisabled={false}
          allowDelete
          onRemoveFromSelected={deleteDocument}
          activity={`Uploaded ${momentUtc(doc.uploadDate, StatDateFormats.DayBreakout, true)}`}
        />
      </Stack>
    ) : (
      <span>A document has not been uploaded yet.</span>
    );
  };

  const attachDocument = async (files: IDocument[]): Promise<void> => {
    setValue('functionalCurrencyDocument', files[0]);
    trigger();
  };

  const headerStyles = { marginTop: 15, marginBottom: 5 };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <RequiredFieldsNotification type={RequiredFieldsType.SapSetup} />
      <Controller
        name="functionalCurrency"
        control={control}
        defaultValue={data?.functionalCurrency}
        render={(): React.ReactElement => (
          <CurrencyCodeDropdown
            label={
              <Label style={{ paddingBottom: 0 }}>
                Functional Currency
                <RequiredMarker />
              </Label>
            }
            propertyName="functionalCurrency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('functionalCurrency', newValue);
            }}
            defaultValue={data?.functionalCurrency}
            errorMessage=""
            width={500}
          />
        )}
      />
      <Controller
        name="statutoryCurrency"
        control={control}
        defaultValue={data?.statutoryCurrency}
        render={(): React.ReactElement => (
          <CurrencyCodeDropdown
            label={
              <Label style={{ paddingBottom: 0 }}>
                Statutory Currency
                <RequiredMarker />
              </Label>
            }
            propertyName="statutoryCurrency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('statutoryCurrency', newValue);
            }}
            defaultValue={data?.statutoryCurrency}
            errorMessage=""
            width={500}
          />
        )}
      />
      <Controller
        name="functionalCurrencyDocument"
        label="Documents"
        required
        control={control}
        defaultValue={data?.functionalCurrencyDocument}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <>
            <Label>
              Documents <RequiredMarker />
            </Label>
            <FileListUpload
              onFileUploading={(): void => {}}
              onFileUploadError={(): void => {}}
              onFileUploadComplete={attachDocument}
              autoUpload
              allowMultiple={false}
            />
          </>
        )}
        rules={{
          validate: validateFileNameLength,
        }}
      />
      <h3 style={headerStyles}>Files Uploaded:</h3>
      <Stack tokens={{ childrenGap: 10 }}>{renderDocumentList()}</Stack>
    </Stack>
  );
};

export default FunctionalCurrencyForm;
