import React, { Dispatch, SetStateAction } from 'react';
import {
  Stack,
  Label,
  Facepile,
  OverflowButtonType,
  IFacepilePersona,
  PersonaInitialsColor,
  IconButton,
} from '@fluentui/react';
import { labelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import StatDateFormats from '../../../../utils/types/DateFormats';
import { IMilestone, MilestoneStatus } from '../Interfaces';
import MilestoneRuleChecklist from '../MilestoneRuleChecklist';
import SpanWithLabel from '../../../common/SpanWithLabel';
import { CanEditDueDate } from '../panels/milestoneDueDateUtils';
import CanAccess from '../../../common/canAccess/CanAccess';
import momentUtc from '../../../../utils/DateFormatter';

interface IMilestoneContentProps {
  milestone: IMilestone;
  openDueDateEditPanel: (focusedMilestoneId: number) => void;
  toggleDividendsPanel: () => void;
  setViewComments: Dispatch<SetStateAction<boolean>>;
  viewComments: boolean;
}

const MilestoneContent = ({
  milestone,
  openDueDateEditPanel,
  toggleDividendsPanel,
  setViewComments,
  viewComments,
}: IMilestoneContentProps): JSX.Element => {
  const personas: IFacepilePersona[] = milestone.assignees?.map((person: { name: string }) => {
    return {
      personaName: person.name,
    };
  });

  const renderUserAssignment = (): JSX.Element => {
    return (
      <>
        <Label styles={labelStyles}>Assignee(s)</Label>
        {personas?.length > 0 && (
          <Facepile
            personas={personas}
            maxDisplayablePersonas={5}
            overflowButtonType={OverflowButtonType.descriptive}
          />
        )}
        {personas?.length === 0 && (
          <Facepile
            personas={[
              {
                personaName: 'Not Assigned',
                imageInitials: 'NA',
                initialsColor: PersonaInitialsColor.warmGray,
              },
            ]}
            maxDisplayablePersonas={1}
          />
        )}
      </>
    );
  };

  const renderDueDatePicker = (dueDate: Date, milestoneId: number): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="baseline">
        <SpanWithLabel
          spanOverrideStyles={{ paddingTop: 6 }}
          labelText="Due Date"
          value={momentUtc(dueDate, StatDateFormats.DaySimple)}
        />
        <CanAccess requestedAction="MilestoneDueDates:Update">
          {CanEditDueDate(milestone) && (
            <IconButton
              title="Edit Milestone Due Dates"
              ariaLabel="Edit Milestone Due Dates"
              iconProps={{ iconName: 'Edit' }}
              onClick={(): void => {
                openDueDateEditPanel(milestoneId);
              }}
            />
          )}
        </CanAccess>
      </Stack>
    );
  };

  const userAssignmentStack = { root: { margin: '0 5px ', width: 180 } };
  const dueDateStack = { root: { margin: '0 5px', width: 120 } };
  const categoryStack = { root: { margin: '0 5px', width: 180 } };
  const rulesStack = { root: { margin: '0 0 0 5px' } };
  return (
    <Stack wrap>
      <Stack horizontal wrap>
        <Stack.Item styles={userAssignmentStack}>{renderUserAssignment()}</Stack.Item>
        <Stack.Item styles={dueDateStack}>
          {renderDueDatePicker(milestone.dueDate, milestone.id)}
        </Stack.Item>
        {milestone.category && (
          <Stack.Item styles={categoryStack}>
            <SpanWithLabel
              spanOverrideStyles={{ paddingTop: 6 }}
              labelText="Category"
              value={milestone.category}
            />
          </Stack.Item>
        )}
        <Stack styles={rulesStack} grow={1}>
          <Stack verticalAlign="center" horizontalAlign="end" verticalFill grow={1}>
            <MilestoneRuleChecklist
              isMilestoneComplete={milestone.status === MilestoneStatus.complete}
              milestoneRules={milestone.milestoneRules}
              disabled={milestone.status !== MilestoneStatus.complete}
              milestoneId={milestone.id}
              toggleDividendsPanel={toggleDividendsPanel}
              commentsVisible={viewComments}
              setCommentsVisible={setViewComments}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MilestoneContent;
