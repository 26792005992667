import React from 'react';
import { ActivityItem, Icon, Stack } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { GET_PROCESS_REASON_FOR_FILING_VIOLATION_HISTORY } from '../../../../utils/statApi/ProcessApi';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import momentUtc from '../../../../utils/DateFormatter';
import { IProcessIdParams } from '../../../common/ParamTypes';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';

interface IReasonForFilingViolationHistoryPanelProps {
  closePanel: () => void;
}

interface ReasonForFilingViolationHistory {
  id: number;
  updatedDate: Date;
  comments: string;
  reasonForFilingViolation: string;
  statUser: StatUser;
}

const ReasonForFilingViolationHistoryPanel = ({
  closePanel,
}: IReasonForFilingViolationHistoryPanelProps): JSX.Element => {
  const { processId } = useParams<IProcessIdParams>();
  const { data, error, loading } = useQuery(GET_PROCESS_REASON_FOR_FILING_VIOLATION_HISTORY, {
    variables: { processId: parseInt(processId, 10) },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Reason For Filing Violation History"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Reason For Filing Violation History Panel"
    >
      <LoadingErrorMessage error={error} loading={loading} />
      {!loading && !error && (
        <Stack tokens={{ childrenGap: 5 }}>
          {data?.reasonForFilingViolationHistory?.map((item: ReasonForFilingViolationHistory) => {
            // Handle empty reason
            const description = item.reasonForFilingViolation
              ? [
                  <span key={`${item.id}-data`}>
                    <strong>{item.statUser.displayName}</strong>
                    &nbsp;updated the reason to&nbsp;
                    <strong>{item.reasonForFilingViolation}</strong>
                    &nbsp;with the comments:
                  </span>,
                  <br key={`${item.id}-space`} />,
                  <span key={`${item.id}-comment`}>{item.comments}</span>,
                ]
              : [
                  <span key={`${item.id}-data`}>
                    <strong>{item.statUser.displayName}</strong>
                    &nbsp;cleared the reason&nbsp;
                  </span>,
                  <br key={`${item.id}-space`} />,
                  <span key={`${item.id}-comment`}>{item.comments}</span>,
                ];

            return (
              <ActivityItem
                key={item.id}
                timeStamp={momentUtc(item.updatedDate)}
                activityIcon={<Icon iconName="Message" />}
                activityDescription={description}
              />
            );
          })}
        </Stack>
      )}
      {data?.reasonForFilingViolationHistory?.length === 0 && (
        <div>There have been no reason for filing violation updates yet.</div>
      )}
    </CoherencePanel>
  );
};

export default ReasonForFilingViolationHistoryPanel;
