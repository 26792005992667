import { gql } from '@apollo/client';

const GET_ACQUISITION_QUERY = gql`
  {
    acquisitions {
      key: id
      text: fieldName
    }
  }
`;
export default GET_ACQUISITION_QUERY;
