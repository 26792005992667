import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_COMPANY_SAP_FINANCIAL_INFO } from '../../../utils/statApi/CompaniesApi';
import { ICompanySapFinancialInfo } from '../../../utils/types/ICompanySapFinancialInfo';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CompanySapFinancialInfoForm from './forms/CompanySapFinancialInfoForm';

interface ICompanySapFinancialInfoPanelProps {
  closePanel: () => void;
  data: ICompanySapFinancialInfo;
}

const CompanySapFinancialInfoPanel = ({
  closePanel,
  data,
}: ICompanySapFinancialInfoPanelProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const form = useForm<ICompanySapFinancialInfo>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateCompanySapFinancialInfo, { loading, error }] = useMutation(
    UPDATE_COMPANY_SAP_FINANCIAL_INFO,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: ICompanySapFinancialInfo): void => {
    updateCompanySapFinancialInfo({
      variables: {
        companyCode,
        input: {
          id: data.id,
          sapLevel: formData.sapLevel,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit SAP Financial Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close SAP Financial Info"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save SAP Financial Info"
          saveTitle="Save SAP Financial Info"
          cancelLabel="Cancel SAP Financial Info"
          cancelTitle="Cancel SAP Financial Info"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <CompanySapFinancialInfoForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default CompanySapFinancialInfoPanel;
