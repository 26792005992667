import { THIRD_PARTY } from '../../../../app/common/constants/SiteConstants';
import { IFilingInfo, IFilingInfoErrors } from '../../serviceContacts/interfaces';
import { addErrorMessage } from '../../serviceContacts/serviceContactValidator';

const validateRequiredFilingInfo = (filingInfo: IFilingInfo): IFilingInfoErrors => {
  let errors: IFilingInfoErrors = {};

  // oversight group
  if (!filingInfo.oversightGroup) {
    errors = addErrorMessage('oversightGroup', 'Please select an Oversight Group', errors);
  } else {
    errors = addErrorMessage('oversightGroup', '', errors);
  }

  if (
    !filingInfo.responsibleForStatFilingPreparation ||
    filingInfo.responsibleForStatFilingPreparation === 'NOVALUE'
  ) {
    errors = addErrorMessage(
      'responsibleForStatFilingPreparation',
      'Please select an item',
      errors,
    );
  } else {
    errors = addErrorMessage('responsibleForStatFilingPreparation', '', errors);
  }

  if (!filingInfo.responsibleForRecord || filingInfo.responsibleForRecord === 'NOVALUE') {
    errors = addErrorMessage('responsibleForRecord', 'Please select an item', errors);
  } else {
    errors = addErrorMessage('responsibleForRecord', '', errors);
  }

  // resp for stat filing prep thrid party
  if (
    filingInfo.responsibleForStatFilingPreparation === THIRD_PARTY &&
    !filingInfo.responsibleForStatFilingPreparationThirdParty?.trim()
  ) {
    errors = addErrorMessage(
      'responsibleForStatFilingPreparationThirdParty',
      'Please enter the name of the Third Party',
      errors,
    );
  } else {
    errors = addErrorMessage('responsibleForStatFilingPreparationThirdParty', '', errors);
  }

  // resp for record third party
  if (
    filingInfo.responsibleForRecord === THIRD_PARTY &&
    !filingInfo.responsibleForRecordThirdParty?.trim()
  ) {
    errors = addErrorMessage(
      'responsibleForRecordThirdParty',
      'Please enter the name of the Third Party',
      errors,
    );
  } else {
    errors = addErrorMessage('responsibleForRecordThirdParty', '', errors);
  }

  return errors;
};

export default validateRequiredFilingInfo;
