import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  COMPLETE_SHADOW_COMPANY_REQUEST,
  GET_COMPANY_CREATION_PROGRESS,
  GET_COMPANY_CREATION_REQUEST_DETAILS,
  UPDATE_SHADOW_COMPANY_CODE,
} from '../../../utils/statApi/CompanyCreationApi';

import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IShadowCompanyCode } from './types';
import { FinancialType, ICompanyCreationRequestDetails } from '../types';
import ShadowCompanyCodeForm from './ShadowCompanyCodeForm';

interface IShadowCompanyCodePanelProps {
  companyCreationRequestData: ICompanyCreationRequestDetails;
  closePanel: () => void;
}

const ShadowCompanyCodePanel = ({
  closePanel,
  companyCreationRequestData,
}: IShadowCompanyCodePanelProps): JSX.Element => {
  const form = useForm<IShadowCompanyCode>({
    defaultValues: {
      ...companyCreationRequestData,
    },
  });
  const { handleSubmit } = form;
  const command =
    companyCreationRequestData.financialType?.id === FinancialType.ShadowCompany
      ? COMPLETE_SHADOW_COMPANY_REQUEST
      : UPDATE_SHADOW_COMPANY_CODE;

  const refetchQueries = [
    {
      query: GET_COMPANY_CREATION_REQUEST_DETAILS,
      variables: {
        companyCreationId: companyCreationRequestData.id,
      },
    },
    {
      query: GET_COMPANY_CREATION_PROGRESS,
      variables: {
        companyCreationId: companyCreationRequestData.id,
      },
    },
  ];

  const [updateShadowCompanyCode, { loading, error }] = useMutation(command, {
    refetchQueries,
    onCompleted: () => {
      closePanel();
    },
  });

  const handleOnSaveClick = (formData: IShadowCompanyCode): void => {
    updateShadowCompanyCode({
      variables: {
        companyRequestId: companyCreationRequestData.id,
        shadowCompanyCode: parseInt(formData?.shadowCompanyCode.toString(), 10),
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Shadow Company Code"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Shadow Company Code Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Shadow Company Code"
          saveTitle="Save Shadow Company Code"
          cancelLabel="Cancel Shadow Company Code"
          cancelTitle="Cancel Shadow Company Code"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <ShadowCompanyCodeForm
          form={form}
          companyCreationRequestData={companyCreationRequestData}
        />
      </form>
    </CoherencePanel>
  );
};

export default ShadowCompanyCodePanel;
