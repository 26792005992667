import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { Stack, DetailsListLayoutMode, Shimmer, ActionButton } from '@fluentui/react';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import {
  PROCESS_CONFIG_DETAIL_QUERY,
  PROCESS_CONFIG_MILESTONES_BY_VERSION_QUERY,
} from '../../../utils/statApi/ProcessConfigApi';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import BackButton from '../../common/BackButton';
import StackColumn from '../../common/StackColumn';
import SpanWithLabel from '../../common/SpanWithLabel';
import Card from '../../common/card/Card';
import StatDateFormats from '../../../utils/types/DateFormats';
import getColumns from './ProcessConfigDetail.Config';
import { bodyContent } from '../../common/headers/Headers.styles';
import { IProcessConfigParams } from '../../common/ParamTypes';
import momentUtc from '../../../utils/DateFormatter';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import MilestoneConfigEditPanel from './MilestoneConfigEditPanel';
import CanAccess from '../../common/canAccess/CanAccess';
import { addDurationToMilestones } from '../../../utils/MilestoneHelpers';
import { processConfigWizardSteps } from './processConfigWizard/CreateProcessConfigUtils';
import ProcessConfigWizard from './processConfigWizard/ProcessConfigWizard';
import IProcessConfig from '../../../utils/types/IProcessConfig';
import DeactivateProcessConfigPanel from './DeactivateProcessConfigPanel';
import QueryBasedDropdown from '../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../utils/types/IDropdownReference';

const ProcessConfigDetail = (): JSX.Element => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const processTemplateVersionIdParam = searchParams.get('processTemplateVersionId');

  const initialTemplateVersion = processTemplateVersionIdParam
    ? { id: parseInt(processTemplateVersionIdParam, 10), fieldName: 'Test' } // test here gets overwritten by the call to grab all the versions
    : null;

  const { processConfigId, companyCode } = useParams<IProcessConfigParams>();
  const [isMilestoneEditPanelOpen, setIsMilestoneEditPanelOpen] = useState(false);
  const [isProcessConfigWizardOpen, setIsProcessConfigWizardOpen] = useState(false);
  const [isDeactivateProcessConfigOpen, setIsDeactivateProcessConfigOpen] = useState(false);
  const [currentProcessTemplateVersion, setCurrentProcessTemplateVersion] =
    useState(initialTemplateVersion);

  const processConfigIdInt = parseInt(processConfigId, 10);
  const { data, loading, error } = useQuery(PROCESS_CONFIG_DETAIL_QUERY, {
    variables: {
      processConfigId: processConfigIdInt,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [
    getMilestonesByVersion,
    { loading: loadingMilestones, data: dataMilestones, error: errorMilestones },
  ] = useLazyQuery(PROCESS_CONFIG_MILESTONES_BY_VERSION_QUERY, {
    variables: {
      processConfigId: parseInt(processConfigId, 10),
      processTemplateVersionId: currentProcessTemplateVersion?.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data?.processConfigDetail) {
      getMilestonesByVersion();
    }
  }, [data?.processConfigDetail]);

  useEffect(() => {
    if (currentProcessTemplateVersion) {
      getMilestonesByVersion({
        variables: {
          processConfigId: processConfigIdInt,
          processTemplateVersionId: currentProcessTemplateVersion.id,
        },
      });
    }
  }, [currentProcessTemplateVersion]);

  if (!loading && !data?.processConfigDetail) {
    return <Redirect to={`/companies/${companyCode}/processes/configs`} />;
  }

  const processTemplateVersionIds =
    data?.processConfigDetail?.associatedProcessTemplateVersions || [];

  const milestones = dataMilestones?.processConfigMilestonesByVersion || [];

  const milestonesWithDurations = milestones ? addDurationToMilestones(milestones) : [];

  const approvedMilestones = data?.processConfigDetail?.milestones || [];
  const approvedMilestonesWithDurations = approvedMilestones
    ? addDurationToMilestones(approvedMilestones)
    : [];
  const approvedMilestonesWithAssignees = approvedMilestonesWithDurations.map(
    (milestone, currentIndex) => {
      const newMilestone = { ...milestone };
      newMilestone.assignee = milestones[currentIndex]?.assignee?.assigneeSlot;
      return newMilestone;
    },
  );
  const processConfig: IProcessConfig = {
    id: processConfigIdInt,
    processType: {
      id: data?.processConfigDetail?.processTypeId,
      fieldName: data?.processConfigDetail?.processType,
    },
    processTemplate: {
      id: data?.processConfigDetail?.processTemplateId,
      fieldName: data?.processConfigDetail?.processTemplateName,
    },
    name: data?.processConfigDetail.name,
    reportingPeriodEndDate: data?.processConfigDetail?.nextReportingPeriodEndDate,
    filingDueDate: data?.processConfigDetail?.nextFilingDueDate,
    useLastDayOfMonth: data?.processConfigDetail?.useLastDayOfMonth,
    recurrence: data?.processConfigDetail.recurrence,
    recurrenceEndDate: data?.processConfigDetail.recurrenceEndDate,
    milestones: approvedMilestonesWithAssignees,
    minReportingPeriodEndDate: data?.processConfigDetail?.previousReportingPeriodEndDate,
    minFilingDueDate: data?.processConfigDetail?.previousFilingDueDate,
    complianceRequirement: data?.processConfigDetail?.complianceRequirement,
  };

  const getBackButton = (): JSX.Element => {
    return (
      <BackButton ariaLabel="Back to process config list">
        {loading && <Shimmer />}
        {!loading && !error && (
          <h2>{`${data?.processConfigDetail?.processTemplateName} Process Configuration`}</h2>
        )}
      </BackButton>
    );
  };

  const handleMilestoneEditClick = (): void => {
    setIsMilestoneEditPanelOpen(true);
  };

  const closeEditPanel = (): void => {
    setIsMilestoneEditPanelOpen(false);
  };

  const toggleEditWizard = (): void => {
    setIsProcessConfigWizardOpen(!isProcessConfigWizardOpen);
  };

  const toggleDeactivateProcessConfig = (): void => {
    setIsDeactivateProcessConfigOpen(!isDeactivateProcessConfigOpen);
  };
  const columns = getColumns();
  const dropdownWrapperStyles = { root: { margin: '30px 0' } };
  return (
    <>
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBackButton}>
            <>
              <CanAccess requestedAction="ProcessConfiguration:Edit">
                <ActionButton
                  iconProps={{ iconName: 'Edit' }}
                  allowDisabledFocus
                  disabled={loading}
                  onClick={(): void => {
                    toggleEditWizard();
                  }}
                >
                  Edit Process Config
                </ActionButton>
              </CanAccess>
              <CanAccess requestedAction="ProcessConfiguration:Deactivate">
                <ActionButton
                  iconProps={{ iconName: 'DeactivateOrders' }}
                  allowDisabledFocus
                  disabled={loading}
                  onClick={(): void => {
                    toggleDeactivateProcessConfig();
                  }}
                >
                  Deactivate
                </ActionButton>
              </CanAccess>
            </>
          </BodyHeaderWithCommandBar>
        </div>
      </div>
      <LoadingErrorMessage error={error} loading={loading} />
      <div className={bodyContentContainer}>
        <Card title="Process Config Details" isLoading={loading}>
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Name" value={data?.processConfigDetail.name} />
              <SpanWithLabel
                labelText="Process Type"
                value={data?.processConfigDetail.processType}
              />
              <SpanWithLabel
                labelText="Template Name"
                value={data?.processConfigDetail.processTemplateName}
              />
              <SpanWithLabel
                labelText="Created from Template Version"
                value={data?.processConfigDetail.processTemplateVersion}
              />
              <SpanWithLabel
                labelText="Next Reporting Period End Date"
                value={momentUtc(
                  data?.processConfigDetail?.nextReportingPeriodEndDate,
                  StatDateFormats.DayBreakout,
                  true,
                )}
              />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Recurrence" value={data?.processConfigDetail.recurrence} />
              <SpanWithLabel
                labelText="Recurrence End Date"
                value={momentUtc(
                  data?.processConfigDetail.recurrenceEndDate,
                  StatDateFormats.DayBreakout,
                  true,
                )}
              />
              <SpanWithLabel
                labelText="Use Last Day of the Month"
                value={data?.processConfigDetail?.useLastDayOfMonth}
              />
              <SpanWithLabel
                labelText="Next Filing Due Date"
                value={momentUtc(
                  data?.processConfigDetail?.nextFilingDueDate,
                  StatDateFormats.DayBreakout,
                  true,
                )}
              />
            </StackColumn>
          </Stack>
        </Card>
        {processTemplateVersionIds && (
          <Stack styles={dropdownWrapperStyles}>
            <QueryBasedDropdown
              required
              value={
                (currentProcessTemplateVersion ||
                  processTemplateVersionIds[0]) as IDropdownReference
              }
              label="Process Template Version"
              errors={{}}
              dropdownOptionsData={processTemplateVersionIds}
              handleChange={(value) => {
                setCurrentProcessTemplateVersion(value);
              }}
              valueKey="processTemplateVersions"
            />
          </Stack>
        )}
        <div className="ms-depth-4">
          <div className={bodyContentWrapper}>
            <div className={bodyContent().bodyContentTitle}>
              <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 5 }}>
                <h3>Milestones</h3>
                <CanAccess requestedAction="ProcessConfiguration:Edit">
                  <ActionButton
                    iconProps={{ iconName: 'edit' }}
                    onClick={handleMilestoneEditClick}
                    text="Edit Milestone Assignees"
                    disabled={loadingMilestones}
                  />
                </CanAccess>
              </Stack>
            </div>
          </div>
          <LoadingErrorMessage error={errorMilestones} loading={loadingMilestones} />
          <ShimmeredDetailsListWrapper
            items={milestonesWithDurations}
            layoutMode={DetailsListLayoutMode.justified}
            columns={columns}
            enableShimmer={loadingMilestones}
            pluralListLabel="Milestone Configurations"
            singularListLabel="Milestone Configuration"
          />
        </div>
      </div>
      {isMilestoneEditPanelOpen && (
        <MilestoneConfigEditPanel
          closePanel={closeEditPanel}
          milestones={milestonesWithDurations}
          processTemplateVersionId={currentProcessTemplateVersion?.id}
        />
      )}
      {isProcessConfigWizardOpen && (
        <ProcessConfigWizard
          processConfigWizardSteps={processConfigWizardSteps}
          closePanel={toggleEditWizard}
          defaultFormState={processConfig}
        />
      )}
      {isDeactivateProcessConfigOpen && (
        <DeactivateProcessConfigPanel closePanel={toggleDeactivateProcessConfig} />
      )}
    </>
  );
};

export default ProcessConfigDetail;
