import { CoherenceTheme } from '@coherence-design-system/styles';
import { IComboBoxStyles } from '@fluentui/react';

export const headerOptionStyle = {
  color: CoherenceTheme.palette.themePrimary,
};

export const currencyComboboxStyles: Partial<IComboBoxStyles> = {
  container: { width: 200 },
  callout: { width: 200 },
  optionsContainerWrapper: {
    maxHeight: 200,
  },
};
