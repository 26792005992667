import React from 'react';
import { Stack } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { IDocumentAnalysisOutputParams } from '../../common/ParamTypes';
import { GET_DOCUMENT_ANALYSIS_RUN } from '../../../utils/statApi/DocumentAnalysisApi';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import Card from '../../common/card/Card';
import StackColumn from '../../common/StackColumn';
import SpanWithLabel from '../../common/SpanWithLabel';
import StatDateFormats from '../../../utils/types/DateFormats';
import ChunkOutputDetails from './ChunkOutputDetails';
import DocumentView from '../../common/documents/DocumentView';
import StepsContainer from '../components/StepsContainer';

const documentDivStyle = {
  marginRight: 25,
};

const OutputDetailsPage = (): JSX.Element => {
  const { runId } = useParams<IDocumentAnalysisOutputParams>();
  const intId = parseInt(runId, 10);
  const { data, loading, error } = useQuery(GET_DOCUMENT_ANALYSIS_RUN, {
    variables: { id: intId },
  });

  const run = data?.documentAnalysisRun;
  return (
    <div className={`${bodyContentContainer}  ms-depth-4`}>
      <LoadingErrorMessage error={error} loading={loading} />
      {!loading && !error && (
        <>
          <Card title="Analysis Output Details" headingLevel={2}>
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <div style={documentDivStyle}>
                  <DocumentView document={run?.document} index={0} />
                </div>
                <SpanWithLabel labelText="Status:" value={run?.status} />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="Run Started:"
                  value={moment(run?.created).format(StatDateFormats.DayWithTime)}
                />
                <SpanWithLabel
                  labelText="Run Completed:"
                  value={
                    run?.completed
                      ? moment(run?.completed).format(StatDateFormats.DayWithTime)
                      : '--'
                  }
                />
                <SpanWithLabel labelText="Steps">
                  <StepsContainer run={run} />
                </SpanWithLabel>
              </StackColumn>
            </Stack>
          </Card>

          <ChunkOutputDetails chunk={run?.analysisOutputJson} />
        </>
      )}
    </div>
  );
};

export default OutputDetailsPage;
