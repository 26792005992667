import { useQuery } from '@apollo/client';
import { ActionButton, IDropdownOption, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../app/common/styles/CommonStyleObjects';
import momentUtc from '../../utils/DateFormatter';
import GET_COMPANY_CREATION_REQUESTS from '../../utils/statApi/CompanyCreationApi';
import StatDateFormats from '../../utils/types/DateFormats';
import FilterCallout from '../common/callout/FilterCallout';
import CanAccess from '../common/canAccess/CanAccess';
import ShimmeredDetailsListWrapper from '../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import BodyHeaderWithCommandBar from '../common/headers/BodyHeaderWithCommandBar';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import PageHeaderWithActionButtons from '../common/headers/PageHeaderWithActionButtons';
import getColumns from './CompanyCreationList.config';
import CompanyCreationListFilter from './CompanyCreationListFilter';
import NewCompanyPanel from './NewCompanyPanel';
import NewShadowCompanyPanel from './NewShadowCompanyPanel';
import { CompanyCreationStatus, ICompanyCreationRequestListItem } from './types';

export interface ICompanyCreationRequestListPageState {
  statusIds: number[];
  requestorIds: number[];
  acquisitionIds: number[];
}

const CompanyCreationListPage = (): JSX.Element => {
  const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);
  const [isShadowCompanyPanelOpen, setIsShadowCompanyPanelOpen] = useState(false);
  const [filterState, setFilterState] = useState<ICompanyCreationRequestListPageState>({
    statusIds: [
      CompanyCreationStatus.RequestStarted,
      CompanyCreationStatus.CompanyCodeRequested,
      CompanyCreationStatus.PendingSubledgerInfo,
      CompanyCreationStatus.PendingStatToolSetup,
    ],
    requestorIds: [],
    acquisitionIds: [],
  });

  const handleAddButtonClick = (): void => {
    setIsAddPanelOpen(true);
  };

  const handleCloseClick = (): void => {
    setIsAddPanelOpen(false);
  };

  const toggleNewShadowCompanyPanel = (): void => {
    setIsShadowCompanyPanelOpen(!isShadowCompanyPanelOpen);
  };

  const renderHeader = (): JSX.Element => {
    return <PageHeaderWithActionButtons title="Company Creation" />;
  };

  const emptyStateStyle = { padding: 20 };

  const { loading, data, error } = useQuery(GET_COMPANY_CREATION_REQUESTS, {
    variables: filterState,
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: usersLoading,
    data: usersData,
    error: usersError,
  } = useQuery(GET_COMPANY_CREATION_REQUESTS, {
    variables: { statusIds: [], requestorIds: [], acquisitionIds: [] },
    fetchPolicy: 'cache-and-network',
  });

  const {
    companyCreationRequests,
  }: { companyCreationRequests: ICompanyCreationRequestListItem[] } = data || {
    companyCreationRequests: [],
  };

  const getRequestorOptions = (): IDropdownOption[] => {
    const uniqueIds = Array.from(
      new Set(
        usersData?.companyCreationRequests.map(
          (req: ICompanyCreationRequestListItem) => req.requestorId,
        ),
      ),
    );

    const requestorOptions: IDropdownOption[] = uniqueIds.map((id: number) => {
      const requestor = usersData?.companyCreationRequests.find(
        (req: ICompanyCreationRequestListItem) => req.requestorId === id,
      );

      return {
        key: id,
        text: requestor.requestor,
        selected: false,
      };
    });

    return requestorOptions;
  };

  const getAcquisitionOptions = (): IDropdownOption[] => {
    const uniqueIds = Array.from(
      new Set(
        usersData?.companyCreationRequests
          .filter(
            (ccr: ICompanyCreationRequestListItem) =>
              ccr.acquisitionId !== 0 && ccr.acquisitionId !== null,
          )
          .map((req: ICompanyCreationRequestListItem) => req.acquisitionId),
      ),
    );

    const acquisitionOptions: IDropdownOption[] = uniqueIds.map((id: number) => {
      const acquisition = usersData?.companyCreationRequests.find(
        (req: ICompanyCreationRequestListItem) => req.acquisitionId === id,
      );

      return {
        key: id,
        text: acquisition.acquisition,
        selected: false,
      };
    });

    return acquisitionOptions;
  };

  const onRenderCompanyName = (item: { id: number; companyName: string }): JSX.Element => {
    return <Link to={`/company-creation/${item.id}/overview`}>{item.companyName}</Link>;
  };

  const onRenderRequestor = (item: { requestor: string }): JSX.Element => {
    return <span>{item.requestor}</span>;
  };

  const onRenderRequestedDate = (item: { requestedDate: Date }): JSX.Element => {
    return <span>{`${momentUtc(item.requestedDate, StatDateFormats.DayBreakout)}`}</span>;
  };

  const onRenderStatus = (item: { status: string }): JSX.Element => {
    const spacedStatusName = item.status.replace(/([A-Z])/g, ' $1').trim(); // Add spaces between words
    return <span>{spacedStatusName}</span>;
  };

  const onRenderAcquisition = (item: { acquisition: string }): JSX.Element => {
    return <span>{item.acquisition}</span>;
  };

  const columns = getColumns({
    onRenderCompanyName,
    onRenderRequestor,
    onRenderRequestedDate,
    onRenderStatus,
    onRenderAcquisition,
  });

  const getBodyHeaderTitle = (): JSX.Element => {
    return <h2>Requests</h2>;
  };

  return (
    <>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="start">
        <FullWidthHeader title={renderHeader} />
        <Stack.Item styles={{ root: { alignSelf: 'flex-end', marginRight: 40 } }}>
          <FilterCallout triggerButtonId="CompanyCreationListFilter" triggerIconName="Filter">
            <CompanyCreationListFilter
              requestorOptions={getRequestorOptions()}
              acquisitionOptions={getAcquisitionOptions()}
              filterState={filterState}
              setFilterState={setFilterState}
            />
          </FilterCallout>
        </Stack.Item>
      </Stack>
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
            <>
              <CanAccess requestedAction="CompanyCreation:Add">
                <ActionButton
                  text="New Company"
                  iconProps={{ iconName: 'PageAdd' }}
                  styles={{ root: { marginRight: 10 } }}
                  onClick={handleAddButtonClick}
                >
                  New Company Request
                </ActionButton>
              </CanAccess>
              <CanAccess requestedAction="CompanyCreation:Add">
                <ActionButton
                  text="New Shadow Company"
                  iconProps={{ iconName: 'Preview' }}
                  styles={{ root: { marginRight: 10 } }}
                  onClick={toggleNewShadowCompanyPanel}
                >
                  New Shadow Company
                </ActionButton>
              </CanAccess>
            </>
          </BodyHeaderWithCommandBar>
        </div>
        <LoadingErrorMessage
          loading={loading || usersLoading}
          error={error || usersError}
          actionName="loading the company creation request data"
        />
        <ShimmeredDetailsListWrapper
          columns={columns}
          gridAriaLabelOverride="Company Creation Requests"
          items={companyCreationRequests || []}
          enableShimmer={loading}
          singularListLabel="Company Creation Requests"
          pluralListLabel="Company Creation Requests"
        />
        {!loading && !error && companyCreationRequests.length === 0 && (
          <div style={emptyStateStyle}>There are no company creation requests.</div>
        )}
        {isAddPanelOpen && <NewCompanyPanel closePanel={handleCloseClick} />}
        {isShadowCompanyPanelOpen && (
          <NewShadowCompanyPanel closePanel={toggleNewShadowCompanyPanel} />
        )}
      </div>
    </>
  );
};

export default CompanyCreationListPage;
