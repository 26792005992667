import React, { FormEvent, useState } from 'react';
import { IDropdownOption, ITag, Label, Stack, TextField } from '@fluentui/react';
import { Controller, FieldValues, UseFormMethods } from 'react-hook-form';
import CompanyCodeInput from '../../../companyCreation/CompanyCodeInput';
import { ICompanyNameSapInfo } from '../../../../utils/types/ICompanyNameSapInfo';
import CountryPicker from '../../../countries/CountryPicker';
import ICountry from '../../../../utils/types/ICountry';
import StateProvinceRegionDropDown from '../../../companyCreation/details/StateProvinceRegionDropDown';
import { RequiredMarker } from '../../../common/formFields/RequiredMarker';

interface ICompanyNameSapInfoFormProps {
  form: UseFormMethods<FieldValues>;
  data?: ICompanyNameSapInfo;
}

const CompanyNameSapInfoForm = ({ form, data }: ICompanyNameSapInfoFormProps): JSX.Element => {
  const initialSelectedCountry = data?.physicalCountry
    ? [
        {
          key: data?.physicalCountry?.id,
          text: data?.physicalCountry?.shortName,
          name: data?.physicalCountry?.shortName,
        },
      ]
    : null;
  const [selectedCountry, setSelectedCountry] = useState<ITag[]>(initialSelectedCountry);
  const { control, errors, setValue, formState, trigger } = form;
  const customLabelStyles = { paddingBottom: 0 };

  const onCountryUpdate = (items: ITag[]): void => {
    const countries = items.map((item) => {
      const newObject: ICountry = {
        id: item.key.toString(),
        shortName: item.name,
      };
      return newObject;
    });
    setSelectedCountry(items);
    setValue('physicalCountry', countries.length ? countries[0] : null);
    setValue('sapRegion', null);
    if (formState.isSubmitted) {
      trigger();
    }
  };

  const onSapRegionUpdate = (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    setValue('sapRegion', { id: option?.key, name: option?.text });
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <CompanyCodeInput
        defaultValue={data?.companyCode}
        readOnly
        form={form}
        fieldName="companyCode"
        label="Company Code"
      />
      <Controller
        as={TextField}
        id="legalFullName"
        name="legalFullName"
        label="Legal Full Name"
        required
        control={control}
        defaultValue={data?.legalFullName || ''}
        errorMessage={errors?.legalFullName?.message}
        rules={{
          maxLength: {
            value: 200,
            message: 'Legal Full Name cannot have more than 200 characters',
          },
          required: {
            value: true,
            message: 'Legal Full Name is required.',
          },
        }}
      />
      <Controller
        as={TextField}
        id="sapLegalName"
        name="sapLegalName"
        label="SAP Legal Name"
        required
        control={control}
        defaultValue={data?.sapLegalName || ''}
        errorMessage={errors?.sapLegalName?.message}
        rules={{
          maxLength: {
            value: 200,
            message: 'SAP Legal Name cannot have more than 200 characters',
          },
          required: {
            value: true,
            message: 'SAP Legal Name is required.',
          },
        }}
      />
      <Controller
        as={TextField}
        id="sapNameShortForm"
        name="sapNameShortForm"
        label="SAP Name (Short Form)"
        required
        control={control}
        defaultValue={data?.sapNameShortForm || ''}
        errorMessage={errors?.sapNameShortForm?.message}
        rules={{
          maxLength: {
            value: 30,
            message: 'SAP Name (Short Form) cannot have more than 30 characters',
          },
          required: {
            value: true,
            message: 'SAP Name (Short Form) is required.',
          },
        }}
      />
      <Controller
        name="physicalCountry"
        control={control}
        defaultValue={initialSelectedCountry}
        required
        rules={{
          required: {
            value: true,
            message: 'Please enter a country',
          },
        }}
        render={(): React.ReactElement => (
          <CountryPicker
            itemLimit={1}
            label={
              <Label style={customLabelStyles}>
                Physical Country
                <RequiredMarker />
              </Label>
            }
            selectedItems={selectedCountry}
            setSelectedItems={onCountryUpdate}
            isDisabled={false}
            errorMessage={errors?.physicalCountry?.message}
          />
        )}
      />
      <Controller
        name="sapRegion"
        control={control}
        defaultValue={data?.sapRegion}
        render={(): React.ReactElement => (
          <StateProvinceRegionDropDown
            selectedCountry={selectedCountry}
            defaultValue={data?.sapRegion?.id}
            onChange={onSapRegionUpdate}
            label={<Label style={customLabelStyles}>SAP Region</Label>}
            disabled={false}
          />
        )}
      />
    </Stack>
  );
};

export default CompanyNameSapInfoForm;
