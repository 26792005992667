import { useQuery } from '@apollo/client';
import { ITag } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { convertSecRegionAreaToTag } from '../../../utils/Helpers';
import { GET_SEC_REGION_AREAS_BY_CODE } from '../../../utils/statApi/CompaniesApi';
import { ISecRegionArea } from '../../../utils/types/ISecRegionArea';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import WwAreaPicker from './WwAreaPicker';

interface IFilterWwAreaPickerProps {
  initialSelectedCodes: Array<string>;
  onRegionAreaUpdate: (items: ITag[]) => void;
}
const FilterWwAreaPicker = (props: IFilterWwAreaPickerProps): JSX.Element => {
  const [initialSelectedItems, setInitialSelectedItems] = useState<ITag[]>();
  const { initialSelectedCodes, onRegionAreaUpdate } = props;
  const { data, loading, error } = useQuery(GET_SEC_REGION_AREAS_BY_CODE, {
    fetchPolicy: 'no-cache',
    variables: { secRegionAreaCodes: initialSelectedCodes },
  });
  const mapSecRegionsToTags = (secRegionAreas: ISecRegionArea[]): ITag[] => {
    const secRegionTags = secRegionAreas.map((secRegionArea) => {
      return convertSecRegionAreaToTag(secRegionArea);
    });
    return secRegionTags;
  };
  useEffect(() => {
    if (!loading && data && data.secRegionAreasByCode) {
      setInitialSelectedItems(mapSecRegionsToTags(data.secRegionAreasByCode));
    }
  }, [data]);
  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      <WwAreaPicker
        selectedItems={initialSelectedItems}
        setSelectedItems={onRegionAreaUpdate}
        isDisabled={false}
        errorMessage={null}
      />
    </>
  );
};
export default FilterWwAreaPicker;
