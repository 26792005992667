import { ChoiceGroup, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { IShadowCompany, SHADOW_COMPANY_NAME_SUFFIX } from './types';
import { yesNoOptions } from '../../../utils/types/CommonDropdownOptions';

interface IShadowCompanyFormProps {
  form: UseFormMethods<IShadowCompany>;
  data: IShadowCompany;
}

const ShadowCompanyForm = ({ form, data }: IShadowCompanyFormProps): JSX.Element => {
  const { control, errors, watch, setValue } = form;

  const isShadowCompanyNeeded = watch(
    'isShadowCompanyNeeded',
    data?.isShadowCompanyNeeded?.toString().toLowerCase(),
  );

  const shadowCompanyNameLength = 25 - SHADOW_COMPANY_NAME_SUFFIX.length;

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        name="isShadowCompanyNeeded"
        defaultValue={
          data?.isShadowCompanyNeeded
            ? data?.isShadowCompanyNeeded?.toString().toLowerCase()
            : 'false'
        }
        control={control}
        render={() => {
          return (
            <ChoiceGroup
              id="isShadowCompanyNeeded"
              label="Shadow Company Needed"
              name="isShadowCompanyNeeded"
              options={yesNoOptions}
              defaultSelectedKey={data?.isShadowCompanyNeeded?.toString().toLowerCase()}
              onChange={(e, newValue): void => {
                setValue('isShadowCompanyNeeded', newValue.key);
                if (newValue.key === 'false') {
                  setValue('shadowCompanyName', null);
                }
              }}
            />
          );
        }}
      />
      <Controller
        as={TextField}
        id="shadowCompanyName"
        name="name"
        label="Shadow Company Name"
        control={control}
        suffix={SHADOW_COMPANY_NAME_SUFFIX}
        defaultValue={data?.name || ''}
        disabled={
          isShadowCompanyNeeded ? isShadowCompanyNeeded.toString().toLowerCase() !== 'true' : true
        }
        errorMessage={errors?.name?.message}
        rules={{
          maxLength: {
            value: shadowCompanyNameLength,
            message: `Shadow Company Name can only be a max of ${shadowCompanyNameLength} characters`,
          },
        }}
      />
    </Stack>
  );
};

export default ShadowCompanyForm;
