import { gql } from '@apollo/client';

const GET_AUDIT_FREQUENCIES_QUERY = gql`
  {
    auditFrequencies {
      id
      fieldName
    }
  }
`;
export default GET_AUDIT_FREQUENCIES_QUERY;
