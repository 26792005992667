import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import React from 'react';
import { IMilestoneConfigAssigneeOption } from './ProcessConfigTypes';

interface IMilestoneConfigEditFormProps {
  milestones: IMilestoneConfigAssigneeOption[];
  updateAssignee: (newValue: IDropdownOption, milestoneConfigId: number) => void;
}

const MilestoneConfigEditForm = ({
  milestones,
  updateAssignee,
}: IMilestoneConfigEditFormProps): JSX.Element => {
  return (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        {milestones.map((milestone: IMilestoneConfigAssigneeOption) => {
          const disabled = milestone.assigneeOptions.length === 0;
          return (
            <Dropdown
              key={milestone.id}
              label={milestone.milestoneTemplateName}
              options={milestone.assigneeOptions}
              defaultSelectedKey={milestone.assigneeId}
              disabled={disabled}
              required={!disabled}
              onChange={(event, option) => updateAssignee(option, milestone.id)}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default MilestoneConfigEditForm;
