import React, { useState } from 'react';
import { CommandButton } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ProcessInstanceList from '../processes/ProcessList';
import {
  bodyContentContainer,
  bodyContentWrapper,
  styledDropdownCommandButton,
} from '../../../app/common/styles/CommonStyleObjects';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import processCurrentDropdownProps from './CompanyDetail.config';
import PROCESS_LIST_QUERY from '../../../utils/statApi/ProcessApi';
import ProcessStatus from '../../../utils/types/ProcessStatus';
import BackButton from '../../common/BackButton';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IProcessTypeParams } from '../../common/ParamTypes';

const CompanyDetailProcessesInstanceList = (): JSX.Element => {
  const { companyCode, processTypeUri } = useParams<IProcessTypeParams>();
  const [filterStatusKey, setFilterStatusKey] = useState(ProcessStatus.Active);
  const { loading, data, error, refetch } = useQuery(PROCESS_LIST_QUERY, {
    variables: { companyCode, processTypeUri, status: filterStatusKey },
    fetchPolicy: 'cache-and-network',
  });

  const getBodyHeaderTitle = (): JSX.Element => {
    return (
      <>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="loading the process type data"
        />
        {data && (
          <BackButton ariaLabel="Back to Process Types List">
            <h2>{`Processes for ${data.processList?.name}`}</h2>
          </BackButton>
        )}
      </>
    );
  };

  const onFilterUpdate = (statusString: ProcessStatus): void => {
    refetch({
      companyCode,
      processTypeUri,
      status: statusString,
    });
    setFilterStatusKey(statusString);
  };

  const { processList } = data || { processList: { processes: [] } };

  return (
    <div className={`${bodyContentContainer}  ms-depth-4`}>
      <div className={bodyContentWrapper}>
        <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
          <CommandButton
            iconProps={{
              iconName: 'RenewalCurrent',
            }}
            ariaLabel="Select option to filter by status"
            text={`${filterStatusKey} Processes`}
            menuProps={processCurrentDropdownProps(onFilterUpdate)}
            styles={styledDropdownCommandButton}
            allowDisabledFocus
          />
        </BodyHeaderWithCommandBar>
      </div>
      <ProcessInstanceList
        loading={loading}
        error={error}
        processInstances={processList?.processes}
        processListName={processList?.name}
      />
    </div>
  );
};
export default CompanyDetailProcessesInstanceList;
