import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  UPDATE_SHADOW_COMPANY,
} from '../../../utils/statApi/CompanyCreationApi';

import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ShadowCompanyForm from './ShadowCompanyForm';
import { IShadowCompany, SHADOW_COMPANY_NAME_SUFFIX } from './types';

interface IShadowCompanyPanelProps {
  closePanel: () => void;
  data: IShadowCompany;
  companyCreationId: number;
}

const ShadowCompanyPanel = ({
  closePanel,
  data,
  companyCreationId,
}: IShadowCompanyPanelProps): JSX.Element => {
  const form = useForm<IShadowCompany>({
    defaultValues: {
      id: data?.id,
      companyCreationRequestId: data?.companyCreationRequestId,
      isShadowCompanyNeeded: data?.isShadowCompanyNeeded,
      name: data?.name?.slice(0, -SHADOW_COMPANY_NAME_SUFFIX.length),
    },
  });
  const { handleSubmit } = form;

  const [updateShadowCompany, { loading, error }] = useMutation(UPDATE_SHADOW_COMPANY, {
    onCompleted: closePanel,
    refetchQueries: [
      {
        query: GET_COMPANY_CREATION_COMPANY_INFO,
        variables: {
          companyCreationId,
        },
      },
      {
        query: GET_COMPANY_CREATION_PROGRESS,
        variables: {
          companyCreationId,
        },
      },
    ],
  });

  const handleOnSaveClick = (formData: IShadowCompany): void => {
    const isShadowCompanyNeeded =
      formData.isShadowCompanyNeeded === null
        ? null
        : formData.isShadowCompanyNeeded.toString() === 'true';

    updateShadowCompany({
      variables: {
        input: {
          id: formData.id || -1,
          companyCreationRequestId: companyCreationId,
          name:
            isShadowCompanyNeeded && formData.name
              ? formData.name.trim().replace(/\s\s+/g, ' ') + SHADOW_COMPANY_NAME_SUFFIX
              : null,
          isShadowCompanyNeeded,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Shadow Company Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit Shadow Company Info Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Shadow Company Info"
          saveTitle="Save Shadow Company Info"
          cancelLabel="Cancel Shadow Company Info"
          cancelTitle="Cancel Shadow Company Info"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <ShadowCompanyForm form={form} data={data} />
      </form>
    </CoherencePanel>
  );
};

export default ShadowCompanyPanel;
