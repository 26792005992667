import { FontIcon, Separator, TooltipHost } from '@fluentui/react';
import moment from 'moment';
import React, { useState } from 'react';
import { MessagingColors } from '../../../app/common/styles/CommonStyleObjects';
import StatDateFormats from '../../../utils/types/DateFormats';
import SpanWithLabel from '../../common/SpanWithLabel';

interface IStepItemProps {
  step: {
    id: number;
    name: string;
    status: string;
    created: string;
  };
}

const getColorAndIcon = (status: string) => {
  let color = MessagingColors.successIcon;
  let iconName = 'BoxCheckmarkSolid';
  if (status === 'Failed') {
    color = MessagingColors.errorBlockIcon;
    iconName = 'Error';
  }

  return { color, iconName };
};

const StepItem = (props: IStepItemProps) => {
  const { step } = props;
  const [isVisible, setIsVisible] = useState(false);

  const createdTime = moment(step.created).format(StatDateFormats.DayWithTime);
  return (
    <TooltipHost
      id={step.id.toString()}
      key={step.id}
      calloutProps={{
        isBeakVisible: false,
        gapSpace: 0,
        setInitialFocus: true,
        doNotLayer: false,
      }}
      onTooltipToggle={setIsVisible}
      tooltipProps={{
        onRenderContent: () => {
          return (
            <div style={{ minWidth: '200px' }}>
              {isVisible && (
                <div>
                  <SpanWithLabel labelText="Step Name" value={step.name} />
                  <Separator />
                  <SpanWithLabel labelText="Started" value={createdTime} />
                </div>
              )}
            </div>
          );
        },
      }}
    >
      <div style={{ height: 15, width: 15, color: getColorAndIcon(step.status).color }}>
        <FontIcon iconName={getColorAndIcon(step.status).iconName} />
      </div>
    </TooltipHost>
  );
};

export default StepItem;
