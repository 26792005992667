import React from 'react';
import { TextField, Stack } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Controller, useWatch } from 'react-hook-form';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import IAnnualFilingRequirementsFormProps from './IAnnualFilingRequirementsFormProps';
import GET_AUDIT_FREQUENCIES_QUERY from '../../../../utils/statApi/AuditFrequencyApi';
import MonthDayPicker from '../../../common/formFields/monthDayPicker/MonthDayPicker';
import AuditingFirmsDropdown from './AuditingFirmsDropdown';

const AuditInformationForm = ({
  annualFilingRequirements,
  form,
}: IAnnualFilingRequirementsFormProps): JSX.Element => {
  const { control, errors, setValue, getValues } = form;
  const watchAuditingFirm = useWatch({
    control,
    name: 'auditingFirm',
    defaultValue: annualFilingRequirements?.auditingFirm,
  });

  const validateOtherAuditingFirm = (): string => {
    if (!getValues('otherAuditingFirm')?.trim() && watchAuditingFirm?.fieldName === 'Other') {
      return 'Other Auditing Firm is required';
    }
    return null;
  };

  const AuditFrequencyDropdown = (props: IFieldsetProps): JSX.Element => {
    const {
      value,
      label,
      errors: auditFrequencyErrors,
      handleChange,
      valueKey,
      outputKey,
      required,
    } = props;
    const { loading, data } = useQuery(GET_AUDIT_FREQUENCIES_QUERY);
    const { auditFrequencies } = !loading && data ? data : [];

    return (
      <QueryBasedDropdown
        required={required}
        value={value as IDropdownReference}
        label={label}
        outputKey={outputKey}
        dropdownOptionsData={auditFrequencies}
        errors={auditFrequencyErrors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <MonthDayPicker
        form={form}
        dueDateDay={annualFilingRequirements?.auditFilingDateDay}
        dueDateMonth={annualFilingRequirements?.auditFilingDateMonth}
        dayFormName="auditFilingDateDay"
        monthFormName="auditFilingDateMonth"
        label="Financial Statement Filing"
        required
      />
      <Controller
        render={(props) => (
          <AuditFrequencyDropdown
            label="Audit Frequencies"
            valueKey="auditFrequency"
            value={props.value}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleChange={(newValue: any, valueKey: string): void => {
              setValue('auditFrequency', newValue);
            }}
            errors={errors}
          />
        )}
        name="auditFrequency"
        control={control}
        defaultValue={annualFilingRequirements.auditFrequency}
        value={annualFilingRequirements.auditFrequency}
      />
      <Controller
        render={(props) => (
          <AuditingFirmsDropdown
            label="Auditing Firm"
            valueKey="auditingFirm"
            value={props.value}
            required
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleChange={(newValue: any, valueKey: string): void => {
              setValue('auditingFirm', newValue);
              if (newValue.fieldName !== 'Other') {
                setValue('otherAuditingFirm', '');
              }
            }}
            errors={errors}
          />
        )}
        name="auditingFirm"
        control={control}
        defaultValue={annualFilingRequirements.auditingFirm}
        value={annualFilingRequirements.auditingFirm}
      />
      <Controller
        id="otherAuditingFirm"
        name="otherAuditingFirm"
        control={control}
        defaultValue={annualFilingRequirements.otherAuditingFirm}
        render={({ onChange, value, name }): React.ReactElement => (
          <TextField
            label="Other Auditing Firm"
            disabled={watchAuditingFirm?.fieldName !== 'Other'}
            required={watchAuditingFirm?.fieldName === 'Other'}
            value={value}
            type="string"
            multiline
            resizable={false}
            onChange={onChange}
            name={name}
            errorMessage={errors?.otherAuditingFirm?.message}
          />
        )}
        rules={{
          validate: validateOtherAuditingFirm,
        }}
      />
    </Stack>
  );
};

export default AuditInformationForm;
