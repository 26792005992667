import { ApolloError, useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IGroup,
  mergeStyles,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CoherencePanelStyles, rowClasses } from '../../../app/common/styles/CommonStyleObjects';
import { GET_PROCESS_CONFIGS_BY_TEMPLATE } from '../../../utils/statApi/ProcessTemplateApi';

import { IProcessTemplate } from '../../../utils/types/IProcessTemplate';
import ActionButtons from '../../common/ActionButtons';
import Card from '../../common/card/Card';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import SpanWithLabel from '../../common/SpanWithLabel';
import StackColumn from '../../common/StackColumn';
import ProcessTemplateMilestoneCardHeader from './milestoneList/ProcessTemplateMilestoneCardHeader';

interface IProcessTemplateReviewPanelProps {
  closePanel: () => void;
  handleSubmit: () => void;
  processTemplate: IProcessTemplate;
  loadingMutation: boolean;
  errorMutation: ApolloError;
  action: string;
  messageBarText?: string;
}
const ProcessTemplateReviewPanel = (props: IProcessTemplateReviewPanelProps): JSX.Element => {
  const {
    closePanel,
    handleSubmit,
    processTemplate,
    loadingMutation,
    errorMutation,
    action,
    messageBarText,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const {
    loading: loadingDetails,
    data: dataDetails,
    error: errorDetails,
  } = useQuery(GET_PROCESS_CONFIGS_BY_TEMPLATE, {
    variables: {
      processTemplateId: processTemplate.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const templateIsInUse =
    dataDetails?.processConfigsByTemplate?.processConfigs &&
    dataDetails?.processConfigsByTemplate?.processConfigs.length > 0;

  const getGroups = (): Array<IGroup> => {
    if (dataDetails?.processConfigsByTemplate?.processConfigs) {
      const processConfigs = dataDetails?.processConfigsByTemplate?.processConfigs?.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (pc: any, item: { name: string; company: { companyCode: string } }) => {
          if (!pc[item.company.companyCode]) {
            pc[item.company.companyCode] = [];
          }

          pc[item.company.companyCode].push(item);
          return pc;
        },
        {},
      );

      const groups: IGroup[] = Object.keys(processConfigs).map((companyCode) => {
        const startIndex = dataDetails?.processConfigsByTemplate?.processConfigs?.findIndex(
          (pc: { name: string; company: { companyCode: string; entityLegalName: string } }) =>
            pc.company.companyCode === companyCode,
        );
        return {
          key: companyCode,
          name: `${companyCode} - ${processConfigs[companyCode][0].company.entityLegalName}`,
          startIndex,
          count: processConfigs[companyCode].length,
          level: 0,
          isCollapsed: true,
        };
      });

      return groups;
    }
    return [];
  };

  const columns = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 175,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      onRender: (item: {
        id: number;
        name: string;
        company: { companyCode: string };
      }): JSX.Element => {
        return (
          <Link to={`/companies/${item.company.companyCode}/processes/configs/${item.id}`}>
            {item.name}
          </Link>
        );
      },
    },
  ];

  const getCardWrapperStyles = mergeStyles({
    marginBottom: 35,
    transition: 'background 300ms ease-in',
    selectors: {
      '&:hover': {
        boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.2), 0 0.3px 0.9px 0 rgba(0,0,0,.25)',
      },
    },
  });
  const cardBodyWrapperStyles = mergeStyles({
    padding: '25px 35px 25px 35px',
  });

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={`${action} Process Template`}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loadingMutation}
          closePanel={closePanel}
          handleSubmit={handleSubmit}
          saveTitle={`${action} Process Template`}
          saveLabel={`${action} Process Template`}
          cancelTitle={`Cancel Process Template ${action}`}
          cancelLabel={`Cancel Process Template ${action}`}
        />
      )}
    >
      <LoadingErrorMessage error={errorMutation} loading={loadingMutation} />
      {messageBarText && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          styles={{ root: { marginBottom: '5px' } }}
        >
          {messageBarText}
        </MessageBar>
      )}
      <LoadingErrorMessage
        loading={loadingDetails}
        error={errorDetails}
        actionName="loading the review details"
      />
      <Card title="Template Details">
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText={`Template to ${action}`} value={processTemplate.name} />
            <Stack horizontal wrap horizontalAlign="space-between">
              <SpanWithLabel
                labelText="Companies Using this Template"
                value={dataDetails?.processConfigsByTemplate?.companiesCount}
              />
              <SpanWithLabel
                labelText={`Total Process Configs To ${action}`}
                value={dataDetails?.processConfigsByTemplate?.processConfigCount}
              />
            </Stack>
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <div className={`ms-depth-4 ${getCardWrapperStyles}`}>
        <ProcessTemplateMilestoneCardHeader
          onHeaderClick={setIsCollapsed}
          title={`Configs To ${action}`}
          contentIsCollapsed={isCollapsed}
          isGoalMilestone={false}
        />
        {!isCollapsed && (
          <div className={`${cardBodyWrapperStyles}`}>
            <ShimmeredDetailsListWrapper
              items={dataDetails?.processConfigsByTemplate?.processConfigs || []}
              layoutMode={DetailsListLayoutMode.justified}
              columns={columns}
              groups={getGroups()}
              enableShimmer={loadingDetails}
              pluralListLabel="Process Configurations"
              singularListLabel="Process Configuration"
              isHeaderVisible={false}
            />
            {!loadingDetails && !templateIsInUse && (
              <div>There are no active process configurations using this template.</div>
            )}
          </div>
        )}
      </div>
    </CoherencePanel>
  );
};
export default ProcessTemplateReviewPanel;
