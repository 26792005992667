import { Checkbox } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormMethods, useWatch } from 'react-hook-form';
import { IAddress, IAddressCharacterLimits } from '../../../utils/types/IAddress';
import AddressFields from './AddressFields';

interface ILegalDocumentInfoAddress {
  form: UseFormMethods<FieldValues>;
  legallyRegisteredAddress: IAddress;
  physicalAddress: IAddress;
  isNonTransactional: boolean;
}

const LegalDocumentInfoAddress = ({
  legallyRegisteredAddress,
  physicalAddress,
  form,
  isNonTransactional,
}: ILegalDocumentInfoAddress): JSX.Element => {
  const headerStyles = { marginTop: 15, marginBottom: 5 };
  const { control, setValue } = form;

  const addressCharacterLimits: IAddressCharacterLimits = {
    streetNumber: 60,
    streetNumber2: 40,
    city: 100,
    postalZipCode: 20,
  };

  const isAddressEmpty = (address: IAddress): boolean => {
    return (
      (address?.streetNumber === null || address?.streetNumber === '') &&
      (address?.streetNumber2 === null || address?.streetNumber2 === '') &&
      (address?.city === null || address?.city === '') &&
      (address?.postalZipCode === null || address?.postalZipCode === '')
    );
  };

  const isAddressTheSame = (address1: IAddress, address2: IAddress): boolean => {
    // If starting with blank values, don't check physicalIsSameAsLegalAddress
    if (
      (isAddressEmpty(address1) && isAddressEmpty(address2)) ||
      (address1 === undefined && address2 === undefined)
    ) {
      return false;
    }
    return (
      address1?.streetNumber === address2?.streetNumber &&
      address1?.streetNumber2 === address2?.streetNumber2 &&
      address1?.city === address2?.city &&
      address1?.postalZipCode === address2?.postalZipCode &&
      address1?.country?.id === address2?.country?.id &&
      address1?.stateProvinceRegion?.id === address2?.stateProvinceRegion?.id
    );
  };

  const [physicalIsSameAsLegalAddress, setPhysicalIsSameAsLegalAddress] = useState(
    isAddressTheSame(legallyRegisteredAddress, physicalAddress),
  );

  const setPhysicalAddress = (address: IAddress): void => {
    setValue('physicalAddress.streetNumber', address?.streetNumber);
    setValue('physicalAddress.streetNumber2', address?.streetNumber2);
    setValue('physicalAddress.city', address?.city);
    setValue('physicalAddress.postalZipCode', address?.postalZipCode);
  };

  const watchLegallyRegisteredAddress = useWatch({
    control,
    name: 'legallyRegisteredAddress',
    defaultValue: legallyRegisteredAddress,
  });

  useEffect(() => {
    if (physicalIsSameAsLegalAddress) {
      setPhysicalAddress(watchLegallyRegisteredAddress);
    }
  }, [watchLegallyRegisteredAddress]);

  const onSelectPhysicalSameAsLegalAddress = (
    ev: React.FormEvent<HTMLElement>,
    checked: boolean,
  ) => {
    setPhysicalIsSameAsLegalAddress(!physicalIsSameAsLegalAddress);

    if (checked) {
      setPhysicalAddress(watchLegallyRegisteredAddress);
      setValue('physicalAddress.country', legallyRegisteredAddress?.country);
      setValue(
        'physicalAddress.stateProvinceRegion',
        legallyRegisteredAddress?.stateProvinceRegion,
      );
    }
  };

  return (
    <div role="form">
      <br />
      <h4 style={headerStyles}>Legally Registered Address:</h4>

      <AddressFields
        fieldName="legallyRegisteredAddress"
        form={form}
        data={legallyRegisteredAddress}
        displayDoubleRequiredMarker={isNonTransactional}
        onlyRequireCountry={false}
        characterLimits={addressCharacterLimits}
      />

      <br />
      <h4 style={headerStyles}>Physical Address:</h4>

      <Checkbox
        label="Same as Legal Address?"
        checked={physicalIsSameAsLegalAddress}
        onChange={onSelectPhysicalSameAsLegalAddress}
      />

      {!physicalIsSameAsLegalAddress && (
        <AddressFields
          fieldName="physicalAddress"
          form={form}
          data={physicalAddress}
          disabled={physicalIsSameAsLegalAddress}
          displayDoubleRequiredMarker={isNonTransactional}
          onlyRequireCountry={false}
          characterLimits={addressCharacterLimits}
        />
      )}
      <br />
    </div>
  );
};

export default LegalDocumentInfoAddress;
