import { gql } from '@apollo/client';
import { COMPANY_DEFAULT_AGM_DETAILS_FRAGMENT } from './CompanyFiscalYearsApi';
import FILING_INFO_FRAGMENT from './FilingInfoApi';

const ANNUAL_FILING_REQUIREMENTS_FRAGMENT = gql`
  fragment AnnualFilingRequirements on CompanyAnnualFilingRequirementsDto {
    id
    financialStatementType {
      id
      fieldName
    }
    physicalSignatureRequired
    fsSigner
    otherFinancialStatementInfo
    localAccountSystem
    headCount
    localChartOfAccountsRequired
    isMsMarketUser
    calendarYearLedgerRequired
    financialRecordLocation
    localLanguageRequirements
    auditFilingDateDay
    auditFilingDateMonth
    auditFrequency {
      id
      fieldName
    }
    auditingFirm {
      id
      fieldName
    }
    otherAuditingFirm
    accountingStandardsUsed {
      id
      fieldName
    }
    accountingStandardsComments
    ifrsType
    ifrsSpecialElections
    xbrlRequiredForFilings
    filingsRequiredToBeFiledInXbrl
    dateOfFirstXbrlFilingRequirement
    sectionOfFilingRequiredToBeTagged
    xbrlComments
    annualGeneralMeetingDueDateDay
    annualGeneralMeetingDueDateMonth
    annualGeneralMeetingInformation
    taxId
    filingInfo {
      ...FilingInfo
    }
    companyDefaultAgmDetails {
      ...CompanyDefaultAgmDetails
    }
  }
  ${FILING_INFO_FRAGMENT}
  ${COMPANY_DEFAULT_AGM_DETAILS_FRAGMENT}
`;

export const GET_COMPANY_ANNUAL_FILING_REQUIREMENTS = gql`
  query annualFilingRequirements($companyCode: String!) {
    annualFilingRequirements(companyCode: $companyCode) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const UPDATE_FINANCIAL_SYSTEMS_MUTATION = gql`
  mutation updateFinancialSystems(
    $companyCode: String!
    $financialSystemsData: CompanyAnnualFilingRequirementFinancialSystemsDtoInput!
  ) {
    updateFinancialSystems(companyCode: $companyCode, financialSystemsData: $financialSystemsData) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;
export const UPDATE_FINANCIAL_SYSTEMS_HEADCOUNT_MUTATION = gql`
  mutation updateFinancialSystemsHeadCount(
    $companyCode: String!
    $financialSystemsData: CompanyAnnualFilingRequirementHeadCountDtoInput!
  ) {
    updateFinancialSystemsHeadCount(
      companyCode: $companyCode
      financialSystemsData: $financialSystemsData
    ) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;
export const UPDATE_XBRL_MUTATION = gql`
  mutation updateXBRL(
    $companyCode: String!
    $xbrlData: CompanyAnnualFilingRequirementsXBRLDtoInput!
  ) {
    updateXBRL(companyCode: $companyCode, xbrlData: $xbrlData) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const UPDATE_ACCOUNTING_STANDARDS_MUTATION = gql`
  mutation updateAccountingStandards(
    $companyCode: String!
    $data: CompanyAnnualFilingRequirementsAccountingStandardsDtoInput!
  ) {
    updateAccountingStandards(companyCode: $companyCode, data: $data) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const UPDATE_FINANCIAL_STATEMENT_FILING_INFO_MUTATION = gql`
  mutation updateFinancialStatementFilingInfo(
    $companyCode: String!
    $financialStatementFilingInformationData: CompanyFinancialStatementFilingInformationDtoInput!
  ) {
    updateFinancialStatementFilingInfo(
      companyCode: $companyCode
      financialStatementFilingInformationData: $financialStatementFilingInformationData
    ) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const UPDATE_AUDIT_INFORMATION_MUTATION = gql`
  mutation updateFinancialStatementAuditInfo(
    $companyCode: String!
    $auditInformation: CompanyAnnualFilingRequirementsAuditInformationDtoInput!
  ) {
    updateFinancialStatementAuditInfo(
      companyCode: $companyCode
      auditInformation: $auditInformation
    ) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const UPDATE_COMPANY_FILING_INFO_MUTATION = gql`
  mutation updateCompanyFilingInfo($companyCode: String!, $filingInfo: FilingInfoDtoInput!) {
    updateCompanyFilingInfo(companyCode: $companyCode, filingInfo: $filingInfo) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const UPDATE_ANNUAL_GENERAL_MEETING = gql`
  mutation updateAnnualGeneralMeeting($companyCode: String!, $agmUpdateDto: AgmUpdateDtoInput!) {
    updateAnnualGeneralMeeting(companyCode: $companyCode, agmUpdateDto: $agmUpdateDto) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const UPDATE_COMPANY_DEFAULT_AGM_DETAILS = gql`
  mutation updateCompanyDefaultAgmDetails(
    $input: CompanyDefaultAgmDetailsDtoInput!
    $companyCode: String!
  ) {
    updateCompanyDefaultAgmDetails(input: $input, companyCode: $companyCode) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;

export const CREATE_COMPANY_DEFAULT_AGM_DETAILS = gql`
  mutation createCompanyDefaultAgmDetails(
    $input: CompanyDefaultAgmDetailsDtoInput!
    $companyCode: String!
  ) {
    createCompanyDefaultAgmDetails(input: $input, companyCode: $companyCode) {
      ...AnnualFilingRequirements
    }
  }
  ${ANNUAL_FILING_REQUIREMENTS_FRAGMENT}
`;
