import React, { useState } from 'react';
import {
  Stack,
  Separator,
  Shimmer,
  IconButton,
  IIconProps,
  DefaultPalette,
  NeutralColors,
} from '@fluentui/react';
import CardShimmer from './CardShimmer';

import {
  cardContentWrapperStyles,
  cardTitleWrapperStyles,
  cardWrapperStyles,
} from '../../../app/common/styles/CommonStyleObjects';

interface ICardProps {
  title: string;
  children: React.ReactNode;
  actionButton?: React.ReactNode;
  isLoading?: boolean;
  collapsable?: boolean;
  isCollapsed?: boolean;
  headingLevel?: number;
}

const Card: React.FunctionComponent<ICardProps> = ({
  title,
  children,
  actionButton,
  isLoading,
  headingLevel,
  collapsable,
  isCollapsed: collapseDefault,
}: ICardProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(collapseDefault);
  const shouldShimmer = isLoading === undefined ? true : !isLoading;

  const onHeaderClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const chevronRight: IIconProps = {
    iconName: 'chevronRight',
  };

  const chevronDown: IIconProps = {
    iconName: 'chevronDown',
  };

  const cardActionButtonStyles = { padding: '0 21px' };

  const headerStyles = { display: 'flex', alignItems: 'center' };
  const buttonStyles = {
    root: { marginRight: 10, color: DefaultPalette.blue },
    rootFocused: { background: NeutralColors.gray30, color: DefaultPalette.blue },
  };

  const header = (): JSX.Element => {
    switch (headingLevel) {
      case 1:
        return (
          <div style={headerStyles}>
            {collapsable && (
              <IconButton
                styles={buttonStyles}
                iconProps={isCollapsed ? chevronRight : chevronDown}
                title={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
                ariaLabel={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
                onClick={(): void => {
                  onHeaderClick();
                }}
              />
            )}
            <h1>{title}</h1>
          </div>
        );
      case 2:
        return (
          <div style={headerStyles}>
            {collapsable && (
              <IconButton
                styles={buttonStyles}
                iconProps={isCollapsed ? chevronRight : chevronDown}
                title={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
                ariaLabel={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
                onClick={(): void => {
                  onHeaderClick();
                }}
              />
            )}
            <h2>{title}</h2>
          </div>
        );
      default:
        return (
          <div style={headerStyles}>
            {collapsable && (
              <IconButton
                styles={buttonStyles}
                iconProps={isCollapsed ? chevronRight : chevronDown}
                title={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
                ariaLabel={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
                onClick={(): void => {
                  onHeaderClick();
                }}
              />
            )}
            <h3>{title}</h3>
          </div>
        );
    }
  };

  return (
    <Shimmer isDataLoaded={shouldShimmer} customElementsGroup={<CardShimmer />}>
      <div className={`ms-depth-4 ${cardWrapperStyles}`}>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
          <div className={cardTitleWrapperStyles}>{header()}</div>
          {actionButton && <div style={cardActionButtonStyles}>{actionButton}</div>}
        </Stack>
        {(!collapsable || (collapsable && !isCollapsed)) && (
          <>
            <Separator />
            <div className={cardContentWrapperStyles}>{children}</div>
          </>
        )}
      </div>
    </Shimmer>
  );
};

export default Card;
