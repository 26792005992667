import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { ActionButton, IconButton, PrimaryButton, Spinner } from '@fluentui/react';
import momentUtc from '../../utils/DateFormatter';
import {
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION_ORDINAL,
} from '../../utils/statApi/NotificationsApi';
import StatDateFormats from '../../utils/types/DateFormats';
import ShimmeredDetailsListWrapper from '../common/DetailsLists/ShimmeredDetailsListWrapper';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import Subheader from '../common/headers/Subheader';
import { convertNotificationToCoherence, IStatNotification, Toast } from './StatNotification';
import getColumns from './NotificationsConfig';
import { bodyContentContainer } from '../../app/common/styles/CommonStyleObjects';
import CanAccess from '../common/canAccess/CanAccess';
import PageHeaderWithActionButtons from '../common/headers/PageHeaderWithActionButtons';
import NotificationEditPanel from './NotificationsEditPanel';
import NotificationContext from './NotificationContext';
import { ListReorderButtons } from '../common/DetailsLists/listReorderButtons/ListReorderButtons';
import useToast from '../../utils/hooks/useToast';

const NotificationsPage = (): JSX.Element => {
  const [loadingIndex, setLoadingIndex] = useState<number>(null);
  const [selectedNotification, setSelectedNotification] = useState<IStatNotification>(null);
  const [isAddPanelOpen, setIsAddPanelOpen] = useState<boolean>(false);
  const { createToast } = useToast();
  const { statNotifications, setNotifications, setStatNotifications } =
    useContext(NotificationContext);

  const [updateNotificationOrdinal, { error: ordinalUpdateError }] = useMutation(
    UPDATE_NOTIFICATION_ORDINAL,
    {
      onCompleted: (res) => {
        setLoadingIndex(null);
        const newNotifications = convertNotificationToCoherence(res.updateNotificationOrdinal);
        setStatNotifications(res.updateNotificationOrdinal);
        setNotifications(newNotifications);
      },
      onError: (error) => {
        const toast: Toast = {
          id: Math.random(),
          content: ordinalUpdateError.message,
          header: 'Notifications',
          iconName: 'ErrorBadge',
        };
        createToast(toast);
      },
    },
  );

  const [deleteNotification, { error: deleteError }] = useMutation(DELETE_NOTIFICATION, {
    onCompleted: (res) => {
      const newStatNotifications = statNotifications.filter(
        (no) => no.id !== res.deleteNotification.id,
      );
      const newNotifications = convertNotificationToCoherence(newStatNotifications);
      setStatNotifications(newStatNotifications);
      setNotifications(newNotifications);
    },
    onError: (error) => {
      const toast: Toast = {
        id: Math.random(),
        content: deleteError.message,
        header: 'Notifications',
        iconName: 'ErrorBadge',
      };
      createToast(toast);
    },
  });

  const renderHeader = (): JSX.Element => {
    const button = (
      <CanAccess requestedAction="Notification:AddEdit">
        <PrimaryButton
          text="Add New Notification"
          onClick={() => {
            setIsAddPanelOpen(true);
          }}
        />
      </CanAccess>
    );

    return <PageHeaderWithActionButtons title="Notifications" actionButtons={button} />;
  };
  const renderSubtitle = (): JSX.Element => <Subheader title="STAT Notifications" />;

  const onRenderStartDate = (notification: IStatNotification): JSX.Element => {
    return <span>{`${momentUtc(notification.startDate, StatDateFormats.DayBreakout)}`}</span>;
  };

  const onRenderEndDate = (notification: IStatNotification): JSX.Element => {
    return <span>{`${momentUtc(notification.endDate, StatDateFormats.DayBreakout)}`}</span>;
  };

  const onRenderNotificationType = (notification: IStatNotification): JSX.Element => {
    return <span>{notification.notificationType?.fieldName}</span>;
  };

  const onSwapItems = (currentIemIndex: number, swapItemIndex: number) => {
    const notification = statNotifications[currentIemIndex];
    setLoadingIndex(currentIemIndex);
    updateNotificationOrdinal({
      variables: {
        notificationId: notification.id,
        swappedNotificationId: statNotifications[swapItemIndex].id,
      },
    });
  };

  const onRenderNotificationHeader = (notification: IStatNotification): JSX.Element => {
    return (
      <ActionButton
        className="user-link"
        onClick={() => {
          setSelectedNotification(notification);
          setIsAddPanelOpen(true);
        }}
      >
        {notification.header}
      </ActionButton>
    );
  };

  const onRenderOrdinal = (notification: IStatNotification) => {
    const notificationIndex = statNotifications.findIndex((no) => no.id === notification.id);
    return (
      <>
        {notificationIndex === loadingIndex && <Spinner />}
        {notificationIndex !== loadingIndex && (
          <ListReorderButtons
            listItemIndex={notificationIndex}
            totalItemCount={statNotifications.length}
            onSwapDown={onSwapItems}
            onSwapUp={onSwapItems}
          />
        )}
      </>
    );
  };

  const deleteButtonClick = (notification: IStatNotification): void => {
    deleteNotification({
      variables: {
        notificationId: notification.id,
      },
    });
  };

  const deleteButtonColumn = (notification: IStatNotification): JSX.Element => (
    <IconButton
      text="Delete"
      aria-label="Delete Notification"
      iconProps={{ iconName: 'delete' }}
      onClick={() => deleteButtonClick(notification)}
    />
  );

  const columns = getColumns({
    onRenderNotificationHeader,
    onRenderStartDate,
    onRenderEndDate,
    onRenderNotificationType,
    onRenderOrdinal,
    deleteButtonColumn,
  });

  const onPanelClose = () => {
    setIsAddPanelOpen(false);
    setSelectedNotification(null);
  };

  return (
    <>
      <FullWidthHeader title={renderHeader} subtitle={renderSubtitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <ShimmeredDetailsListWrapper
          columns={columns}
          items={statNotifications || []}
          // enableShimmer={loading}
          singularListLabel="Notification"
          pluralListLabel="Notifications"
        />
      </div>
      {isAddPanelOpen && (
        <NotificationEditPanel notification={selectedNotification} closePanel={onPanelClose} />
      )}
    </>
  );
};

export default NotificationsPage;
