import { useMutation, useQuery } from '@apollo/client';
import {
  Stack,
  PrimaryButton,
  DefaultButton,
  Spinner,
  SpinnerSize,
  Separator,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
  separatorStyles,
} from '../../../../app/common/styles/CommonStyleObjects';
import {
  addDurationToMilestones,
  updateMilestoneOffsets,
} from '../../../../utils/MilestoneHelpers';
import {
  PROCESS_TEMPLATE_DETAIL_QUERY,
  UPDATE_PROCESS_TEMPLATE_VERSION,
} from '../../../../utils/statApi/ProcessTemplateApi';
import { IMilestoneTemplate } from '../../../../utils/types/IMilestoneTemplate';
import {
  IProcessTemplate,
  IProcessTemplateMilestone,
} from '../../../../utils/types/IProcessTemplate';
import BackButton from '../../../common/BackButton';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import BodyHeaderWithCommandBar from '../../../common/headers/BodyHeaderWithCommandBar';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import { IProcessTemplateTypeParams } from '../../../common/ParamTypes';
import ProcessTemplateReviewPanel from '../ProcessTemplateReviewPanel';
import ProcessTemplateMilestoneAdd from './ProcessTemplateMilestoneAddPanel';
import ProcessTemplateMilestoneEditItem from './ProcessTemplateMilestoneEditItem';

export interface IPanelState {
  panelIsOpen: boolean;
  index: number;
}

const ProcessTemplateMilestoneEdit = (): JSX.Element => {
  const { processTemplateId } = useParams<IProcessTemplateTypeParams>();
  const [isAddMilestonePanelOpen, setIsAddMilestonePanelOpen] = useState<IPanelState>({
    panelIsOpen: false,
    index: 0,
  });
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isProcessTemplateReviewPanelOpen, setIsProcessTemplateReviewPanelOpen] = useState(false);
  const { data, loading, error } = useQuery(PROCESS_TEMPLATE_DETAIL_QUERY, {
    variables: {
      processTemplateId: parseInt(processTemplateId, 10),
      processTemplateVersionId: null,
    },
    fetchPolicy: 'cache-and-network',
  });
  const toggleProcessTemplateReviewPanel = (): void => {
    setIsProcessTemplateReviewPanelOpen(!isProcessTemplateReviewPanelOpen);
  };

  const [updatedProcessTemplateVersion, { loading: loadingMutation, error: errorMutation }] =
    useMutation(UPDATE_PROCESS_TEMPLATE_VERSION, {
      onCompleted: () => {
        setIsSaveButtonEnabled(false);
        toggleProcessTemplateReviewPanel();
      },
      refetchQueries: [
        {
          query: PROCESS_TEMPLATE_DETAIL_QUERY,
          variables: {
            processTemplateId: parseInt(processTemplateId, 10),
          },
        },
      ],
    });

  const [processTemplateMilestonesWithDurations, setProcessTemplateMilestonesWithDurations] =
    useState([]);
  const { processTemplateDetail } = data || {
    processTemplateDetail: {} as IProcessTemplate,
  };

  useEffect(() => {
    if (processTemplateDetail && processTemplateDetail.templateMilestones) {
      const defaultMilestones = addDurationToMilestones(
        processTemplateDetail.templateMilestones,
        true,
      );
      setProcessTemplateMilestonesWithDurations(defaultMilestones);
    }
  }, [processTemplateDetail]);
  const onMilestonesChange = (modifiedMilestones: IProcessTemplateMilestone[]) => {
    setProcessTemplateMilestonesWithDurations([...modifiedMilestones]);
  };

  const checkIsTemplateEdited = (editedMilestones: IProcessTemplateMilestone[]): boolean => {
    const defaultMilestones = addDurationToMilestones(
      processTemplateDetail.templateMilestones,
      true,
    );

    if (defaultMilestones.length !== editedMilestones.length) {
      return true;
    }

    let hasBeenEdited = false;

    defaultMilestones.forEach((item) => {
      const index = defaultMilestones.indexOf(item);
      if (
        item.milestoneTemplateId !== editedMilestones[index].milestoneTemplateId ||
        item.name !== editedMilestones[index].name ||
        item.enforceDefaultAsMinDuration !== editedMilestones[index].enforceDefaultAsMinDuration ||
        item.defaultDaysOffset !== editedMilestones[index].defaultDaysOffset ||
        item.minimumDuration !== editedMilestones[index].minimumDuration ||
        (index === 0 ? false : item.duration !== editedMilestones[index].duration) // The first milestone does not have a duration
      ) {
        hasBeenEdited = true;
      }
    });

    return hasBeenEdited;
  };

  const getHeaderTitle = (): JSX.Element => {
    return <h1>Template Management</h1>;
  };
  const getBodyHeaderTitle = (): JSX.Element => {
    return (
      <BackButton ariaLabel="Back to processes list">
        <h2>Edit Current Milestone List - {processTemplateDetail.name}</h2>
      </BackButton>
    );
  };
  const handleSubmit = (): void => {
    const processTemplateVersionDto = {
      id: processTemplateDetail.approvedProcessTemplateVersionId,
      processTemplateId: processTemplateDetail.id,
      goalMilestoneTemplateId: 0,
      milestones: processTemplateMilestonesWithDurations.map((milestone) => {
        return {
          id: milestone.id,
          milestoneTemplateId: milestone.milestoneTemplateId,
          defaultDaysOffset: milestone.defaultDaysOffset,
          maxDaysOffset: milestone.defaultDaysOffset,
          enforceDefaultAsMinDuration: milestone.enforceDefaultAsMinDuration,
        };
      }),
    };
    updatedProcessTemplateVersion({
      variables: {
        processTemplateVersionDto,
      },
    });
  };

  const getDefaultOffset = (index: number): number => {
    if (index === 0) {
      // this is the first milestone
      // check to see if there is one after it
      if (processTemplateMilestonesWithDurations.length > 0) {
        const nextMilestone = processTemplateMilestonesWithDurations[index];

        const defaultOffset = nextMilestone?.defaultDaysOffset - nextMilestone?.duration;
        return defaultOffset;
      }

      return 0;
    }

    if (index === processTemplateMilestonesWithDurations.length) {
      // end of the array, there's more than one, it's not the goal milestone
      const previousMilestone = processTemplateMilestonesWithDurations[index - 1];

      if (previousMilestone?.defaultDaysOffset === 0) {
        return 0;
      }

      const defaultOffset = previousMilestone?.defaultDaysOffset;
      return defaultOffset;
    }

    const durationMilestone = processTemplateMilestonesWithDurations[index];
    return durationMilestone?.defaultDaysOffset - durationMilestone?.duration;
  };

  const onAddSave = (milestoneTemplate: IMilestoneTemplate, index: number): void => {
    setIsAddMilestonePanelOpen({ panelIsOpen: false, index: null });
    const newMilestones = [...processTemplateMilestonesWithDurations];

    if (index === 0 && newMilestones.length > 0) {
      newMilestones[0].duration = 1;
    }

    const newMilestone = {
      id: milestoneTemplate.id * -1,
      milestoneTemplateId: milestoneTemplate.id,
      name: milestoneTemplate.name,
      duration: 0,
      defaultDaysOffset: getDefaultOffset(index),
      minimumDuration: 1,
      enforceDefaultAsMinDuration: false,
      milestoneCategoryName: milestoneTemplate.milestoneCategoryName,
    };

    newMilestones.splice(index, 0, newMilestone);

    const { newMilestones: updatedDurations } = updateMilestoneOffsets(
      1,
      newMilestone.id,
      newMilestones,
      true,
    );

    setIsSaveButtonEnabled(checkIsTemplateEdited(newMilestones));
    setProcessTemplateMilestonesWithDurations(updatedDurations);
  };

  const updateDurations = (newDuration: number, id: number) => {
    const { newMilestones, newValue } = updateMilestoneOffsets(
      newDuration,
      id,
      processTemplateMilestonesWithDurations,
    );
    onMilestonesChange(newMilestones as IProcessTemplateMilestone[]);
    setIsSaveButtonEnabled(checkIsTemplateEdited(newMilestones as IProcessTemplateMilestone[]));
    return newValue;
  };

  const setGoalMilestone = (position: number) => {
    const copiedMilestones = [...processTemplateMilestonesWithDurations];

    // call update milestone offsets
    let negativeOffset = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = position - 1; i >= 0; i--) {
      const milestoneInLoop = copiedMilestones[i];
      milestoneInLoop.defaultDaysOffset = negativeOffset;
      milestoneInLoop.maxDaysOffset = negativeOffset; // TODO: may need to come back to this

      negativeOffset -= milestoneInLoop.duration;
    }

    let positiveOffset = 0;
    // eslint-disable-next-line no-plusplus
    for (let j = position; j < copiedMilestones.length; j++) {
      const milestoneInLoop = copiedMilestones[j];
      positiveOffset += milestoneInLoop.duration;
      milestoneInLoop.defaultDaysOffset = positiveOffset;
      milestoneInLoop.maxDaysOffset = positiveOffset; // TODO: may need to come back to this
    }

    setIsSaveButtonEnabled(checkIsTemplateEdited(copiedMilestones));
    setProcessTemplateMilestonesWithDurations(copiedMilestones);
  };

  const updateEnforceMinDurations = (checked: boolean, id: number) => {
    const newMilestones = [...processTemplateMilestonesWithDurations];
    const modifiedMilestone = newMilestones.find((mi) => mi.id === id);
    modifiedMilestone.enforceDefaultAsMinDuration = checked;
    onMilestonesChange(newMilestones as IProcessTemplateMilestone[]);
    setIsSaveButtonEnabled(checkIsTemplateEdited(newMilestones));
  };
  const buttonSeparatorStackStyles = { root: { margin: '25px 15px' } };

  return (
    <>
      <FullWidthHeader title={getHeaderTitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              {loadingMutation && <Spinner size={SpinnerSize.medium} />}
              <PrimaryButton
                disabled={!isSaveButtonEnabled || loadingMutation}
                onClick={() => toggleProcessTemplateReviewPanel()}
              >
                Review and Save
              </PrimaryButton>
            </Stack>
          </BodyHeaderWithCommandBar>
        </div>
      </div>
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading process template details from database"
      />
      <LoadingErrorMessage
        loading={loadingMutation}
        error={errorMutation}
        actionName="loading saving process template details from database"
      />
      <div className={bodyContentContainer}>
        <Stack
          styles={buttonSeparatorStackStyles}
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
        >
          <DefaultButton
            iconProps={{ iconName: 'Add' }}
            title="Add Milestone to Template"
            ariaLabel="Add Milestone to Template"
            onClick={() => setIsAddMilestonePanelOpen({ panelIsOpen: true, index: 0 })}
          >
            Add Milestone
          </DefaultButton>
          <Stack.Item grow>
            <Separator styles={separatorStyles} />
          </Stack.Item>
        </Stack>
        {processTemplateMilestonesWithDurations.map(
          (milestone: IProcessTemplateMilestone, index: number) => {
            return (
              <ProcessTemplateMilestoneEditItem
                key={milestone.id}
                milestone={milestone}
                index={index + 1}
                setIsAddMilestonePanelOpen={setIsAddMilestonePanelOpen}
                onDeleteMilestone={(id: number) => {
                  const updatedMilestones = updateMilestoneOffsets(
                    0,
                    id,
                    processTemplateMilestonesWithDurations,
                    true,
                  );
                  const { newMilestones } = updatedMilestones;
                  const milestonesWithoutRemovedItem = newMilestones.filter(
                    (mi: IProcessTemplateMilestone) => {
                      return mi.id !== id;
                    },
                  );
                  setProcessTemplateMilestonesWithDurations(milestonesWithoutRemovedItem);
                  setIsSaveButtonEnabled(
                    checkIsTemplateEdited(
                      milestonesWithoutRemovedItem as IProcessTemplateMilestone[],
                    ),
                  );
                }}
                onDurationChange={updateDurations}
                setGoalMilestone={setGoalMilestone}
                onEnforceMinDurationChange={updateEnforceMinDurations}
              />
            );
          },
        )}
      </div>
      {isAddMilestonePanelOpen.panelIsOpen && (
        <ProcessTemplateMilestoneAdd
          onAddSave={onAddSave}
          closePanel={() => setIsAddMilestonePanelOpen({ panelIsOpen: false, index: null })}
          index={isAddMilestonePanelOpen.index}
          currentMilestones={processTemplateMilestonesWithDurations}
        />
      )}
      {isProcessTemplateReviewPanelOpen && (
        <ProcessTemplateReviewPanel
          processTemplate={processTemplateDetail}
          closePanel={toggleProcessTemplateReviewPanel}
          handleSubmit={handleSubmit}
          loadingMutation={loadingMutation}
          errorMutation={errorMutation}
          action="Update"
          messageBarText="Saving these changes will update all process configurations using this
          template. All unstarted processes will be updated with the changes."
        />
      )}
    </>
  );
};
export default ProcessTemplateMilestoneEdit;
