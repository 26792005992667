import { ICustomContactGroup, IServiceContactsUserAssignment } from './interfaces';
import { ServiceContactErrorMessageString } from './serviceContactValidator';

const validateCustomContactPeoplePicker = ({
  updatingContactGroup,
  contactGroups,
  mainContacts,
}: {
  updatingContactGroup: ICustomContactGroup;
  contactGroups: ICustomContactGroup[];
  mainContacts: IServiceContactsUserAssignment;
}): string => {
  if (!updatingContactGroup || !updatingContactGroup?.contacts) {
    return null;
  }

  const updatingContactIds: string[] = updatingContactGroup.contacts.map(
    (contact) => contact.graphId,
  );

  const mainContactIds: string[] = Object.values(mainContacts)
    .filter((val) => Array.isArray(val))
    .flat()
    .map((contact) => contact.graphId);

  // If this group has duplicates
  if (updatingContactIds.length !== new Set([...updatingContactIds]).size) {
    return 'Service Contacts are only allowed to be in a list once';
  }

  const customContactsIds: string[] =
    contactGroups.length > 0
      ? contactGroups
          .map((cg: ICustomContactGroup) => cg.contacts)
          .flat()
          .map((contact) => contact.graphId)
      : [];

  for (let i = 0; i < updatingContactIds.length; i += 1) {
    // If one of the other custom contact groups has duplicates
    if (customContactsIds.includes(updatingContactIds[i])) {
      return 'Service Contacts are only allowed to be in one custom list';
    }

    // If one of the main contact group has duplicates
    if (mainContactIds.includes(updatingContactIds[i])) {
      return ServiceContactErrorMessageString;
    }
  }

  return null;
};

export default validateCustomContactPeoplePicker;
