import React from 'react';
import { RequiredFieldsType } from './details/types';

interface IRequiredFieldsNotificationProps {
  type: RequiredFieldsType;
}

const RequiredFieldsNotification = ({ type }: IRequiredFieldsNotificationProps): JSX.Element => {
  const requiredStyles = { color: '#A4262C', fontSize: 12 };

  return (
    <div>
      {type === RequiredFieldsType.SapSetup && (
        <div style={requiredStyles}>* Fields required for SAP Setup</div>
      )}
      {type === RequiredFieldsType.StatSetup && (
        <div style={requiredStyles}>** Fields required for STAT Setup</div>
      )}
      {type === RequiredFieldsType.SubledgerSetup && (
        <div style={requiredStyles}>* Fields required for Subledger Setup</div>
      )}
    </div>
  );
};

export default RequiredFieldsNotification;
