import React from 'react';
import { StackItem, Stack, mergeStyles } from '@fluentui/react';

interface IPageHeaderProps {
  title: string;
  actionButtons?: JSX.Element;
}

const PageHeaderWithActionButtons: React.FunctionComponent<IPageHeaderProps> = (
  props: IPageHeaderProps,
) => {
  const { title, actionButtons } = props;
  const buttonContainerStyle = mergeStyles({
    marginTop: -5,
  });

  return (
    <Stack horizontal horizontalAlign="space-between" wrap>
      <h1>{title}</h1>
      <Stack
        grow
        horizontal
        verticalAlign="center"
        horizontalAlign="end"
        className={buttonContainerStyle}
      >
        <StackItem>{actionButtons}</StackItem>
      </Stack>
    </Stack>
  );
};

export default PageHeaderWithActionButtons;
