import { ColumnActionsMode, IColumn } from '@fluentui/react';
import { rowClasses } from '../../app/common/styles/CommonStyleObjects';
import { IStatNotification } from './StatNotification';

interface INotificationsListColumnParams {
  onRenderNotificationHeader: (notification: IStatNotification) => JSX.Element;
  onRenderStartDate: (notification: IStatNotification) => JSX.Element;
  onRenderEndDate: (notification: IStatNotification) => JSX.Element;
  onRenderNotificationType: (notification: IStatNotification) => JSX.Element;
  onRenderOrdinal: (notification: IStatNotification) => JSX.Element;
  deleteButtonColumn: (notification: IStatNotification) => JSX.Element;
}

const getColumns = ({
  onRenderNotificationHeader,
  onRenderStartDate,
  onRenderEndDate,
  onRenderNotificationType,
  onRenderOrdinal,
  deleteButtonColumn,
}: INotificationsListColumnParams): Array<IColumn> => {
  return [
    {
      key: 'ordinal',
      name: 'Ordinal',
      fieldName: 'ordinal',
      minWidth: 75,
      maxWidth: 125,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderOrdinal,
    },
    {
      key: 'header',
      name: 'Header',
      fieldName: 'header',
      minWidth: 200,
      maxWidth: 250,
      className: rowClasses.medFont,
      columnActionsMode: ColumnActionsMode.disabled,
      isRowHeader: true,
      isResizable: true,
      onRender: onRenderNotificationHeader,
    },
    {
      key: 'content',
      name: 'Content',
      fieldName: 'content',
      minWidth: 200,
      maxWidth: 600,
      isResizable: true,
      className: rowClasses.medFont,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'notificationType',
      name: 'Notification Type',
      fieldName: 'notificationType.fieldName',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderNotificationType,
    },
    {
      key: 'startDate',
      name: 'Start Date',
      fieldName: 'startDate',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderStartDate,
    },
    {
      key: 'endDate',
      name: 'End Date',
      fieldName: 'endDate',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderEndDate,
    },
    {
      key: 'deleteButton',
      name: 'Delete',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: false,
      isResizable: false,
      onRender: deleteButtonColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export default getColumns;
