import { useQuery } from '@apollo/client';
import {
  Stack,
  TextField,
  IGroup,
  Selection,
  DetailsList,
  IColumn,
  DetailsHeader,
  IRenderFunction,
  IDetailsHeaderProps,
  Label,
  Spinner,
  SpinnerSize,
  Checkbox,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Action, GET_ACTIONS_QUERY, RoleActions } from '../../utils/statApi/RolesApi';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';

interface RoleActionsFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormMethods<Record<string, any>>;
  roleActions: RoleActions;
}

const RoleActionsForm = ({ form, roleActions }: RoleActionsFormProps): JSX.Element => {
  const { control, errors, setValue, register } = form;
  const [actionGroups, setActionGroups] = useState<IGroup[]>([]);
  const selectionObject: Selection = new Selection({
    onSelectionChanged: () => {
      setValue('actions', selectionObject.getSelection());
    },
  });

  const { data, loading, error } = useQuery(GET_ACTIONS_QUERY);

  const actions: Action[] = data?.actions;

  const actionColumns: IColumn[] = [
    {
      key: 'entityName',
      name: 'Entity Name',
      fieldName: 'entityName',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: false,
      data: 'string',
    },
    {
      key: 'entityAction',
      name: 'Entity Action',
      fieldName: 'entityAction',
      minWidth: 75,
      maxWidth: 100,
      isRowHeader: false,
      data: 'string',
    },
  ];

  useEffect(() => {
    register('actions');
    setValue('actions', roleActions?.actions || []);
  }, []);

  useEffect(() => {
    if (actions) {
      const actionObject = actions?.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, item: Action) => {
          if (!acc[item.entityName]) {
            acc[item.entityName] = [];
          }

          acc[item.entityName].push(item);
          return acc;
        },
        {},
      );

      const groups: IGroup[] = Object.keys(actionObject).map((propName) => {
        const startIndex = actions.findIndex((ac: Action) => ac.entityName === propName);
        return {
          key: propName,
          name: propName,
          startIndex,
          count: actionObject[propName].length,
          level: 0,
        };
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectionObject.setItems(actions as any, true);

      roleActions?.actions.forEach((act: Action) => {
        const index = actions.findIndex((action) => action.id === act.id);
        selectionObject.setIndexSelected(index, true, false);
      });

      setActionGroups(groups);
    }
  }, [actions, roleActions]);

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps,
    _defaultRender?: IRenderFunction<IDetailsHeaderProps>,
  ) => {
    return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton="Expand collapse groups" />;
  };

  const onRenderColumn = (item: RoleActions, index: number, column: IColumn) => {
    const value =
      item && column && column.fieldName ? item[column.fieldName as keyof RoleActions] || '' : '';

    return <div data-is-focusable>{value}</div>;
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <input type="hidden" ref={register()} name="id" defaultValue={roleActions?.id} />
      <LoadingErrorMessage loading={loading} error={error} actionName="loading the actions" />
      <Controller
        as={TextField}
        label="Name"
        id="name"
        name="role.name"
        control={control}
        required
        resizable={false}
        defaultValue={roleActions?.role?.name || ''}
        errorMessage={errors?.name?.message}
        rules={{
          required: 'Please enter Role name',
          maxLength: {
            value: 150,
            message: 'Input is greater than the maximum character limit of 150.',
          },
        }}
      />

      <Controller
        name="role.isGlobal"
        control={control}
        defaultValue={roleActions?.role?.isGlobal}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="Is Global"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              onChange(checked);
            }}
            checked={value}
            name={name}
          />
        )}
      />

      <Controller
        name="role.showInSecurityRolesList"
        control={control}
        defaultValue={roleActions?.role?.showInSecurityRolesList}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="Show In Security Roles List"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              onChange(checked);
            }}
            checked={value}
            name={name}
          />
        )}
      />

      <Label>Assigned Actions</Label>
      {!loading && (
        <DetailsList
          items={actions || []}
          groups={actionGroups}
          columns={actionColumns}
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          ariaLabelForSelectionColumn="Toggle selection"
          checkButtonAriaLabel="Row checkbox"
          onRenderDetailsHeader={onRenderDetailsHeader}
          selectionPreservedOnEmptyClick
          groupProps={{
            showEmptyGroups: true,
          }}
          compact
          onRenderItemColumn={onRenderColumn}
          selection={selectionObject}
        />
      )}
      {loading && <Spinner size={SpinnerSize.medium} />}
    </Stack>
  );
};

export default RoleActionsForm;
