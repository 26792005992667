import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import ActionButtons from '../common/ActionButtons';

import { convertNotificationToCoherence, IStatNotification } from './StatNotification';
import NotificationForm from './NotificationForm';
import {
  CREATE_NOTIFICATION,
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
} from '../../utils/statApi/NotificationsApi';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import NotificationContext from './NotificationContext';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';

interface INotificationEditPanel {
  closePanel: () => void;
  notification?: IStatNotification;
}

const NotificationEditPanel: React.FunctionComponent<INotificationEditPanel> = ({
  closePanel,
  notification,
}: INotificationEditPanel) => {
  const notificationForm = useForm();
  const { errors, handleSubmit } = notificationForm;
  const { statNotifications, setStatNotifications, setNotifications } =
    useContext(NotificationContext);

  const updateNotificationsContext = (statNotificationsList: IStatNotification[]) => {
    const newNotifications = convertNotificationToCoherence(statNotificationsList);

    setStatNotifications(statNotificationsList);
    setNotifications(newNotifications);
  };

  const [createNotification, { loading: createLoading, error: createError }] = useMutation(
    CREATE_NOTIFICATION,
    {
      refetchQueries: [GET_NOTIFICATIONS],
      onCompleted: (res) => {
        const newStatNotifications = [...statNotifications, res.createNotification];
        updateNotificationsContext(newStatNotifications);
        closePanel();
      },
    },
  );

  const [updateNotification, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_NOTIFICATION,
    {
      onCompleted: (res) => {
        const replaceIndex = statNotifications.findIndex(
          (oldNotification) => oldNotification.id === res.updateNotification.id,
        );
        const notifications = [...statNotifications];
        notifications[replaceIndex] = res.updateNotification;
        updateNotificationsContext(notifications);
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: IStatNotification): void => {
    const notificationSaveData: IStatNotification = {
      id: parseInt(formData.id.toString(), 10) || -1,
      header: formData.header,
      content: formData.content,
      ordinal: -1,
      startDate: formData.startDate,
      endDate: formData.endDate,
      createdDate: new Date(),
      notificationType: {
        id: formData.notificationType?.id,
        fieldName: formData.notificationType?.fieldName,
      },
    };

    if (!Object.keys(errors).length) {
      if (notificationSaveData.id <= 0) {
        createNotification({
          variables: {
            notification: notificationSaveData,
          },
        });
      } else {
        updateNotification({
          variables: {
            notification: notificationSaveData,
          },
        });
      }
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Save Notification"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Notification Edit"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={updateLoading || createLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Notification"
          saveTitle="Save Notification"
          cancelLabel="Cancel Notification Edit"
          cancelTitle="Cancel Notification Edit"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <LoadingErrorMessage
          loading={createLoading}
          error={createError}
          actionName="creating a notification"
        />
        <LoadingErrorMessage
          loading={updateLoading}
          error={updateError}
          actionName="updating the notification"
        />
        <NotificationForm notification={notification} form={notificationForm} />
      </form>
    </CoherencePanel>
  );
};

export default NotificationEditPanel;
