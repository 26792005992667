import { useQuery } from '@apollo/client';
import { IconButton, PrimaryButton, Stack, TextField, IButtonStyles } from '@fluentui/react';
import React, { CSSProperties, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import momentUtc from '../../utils/DateFormatter';
import {
  GET_FILLING_FREQUENCIES,
  GET_FILING_PREPARATION_COMPANIES,
  GET_TAX_TYPES,
} from '../../utils/statApi/CountryApi';
import { ALL_PROCESS_TYPE_LIST_QUERY } from '../../utils/statApi/ProcessTypesApi';
import IDropdownReference from '../../utils/types/IDropdownReference';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import MonthDayPicker from '../common/formFields/monthDayPicker/MonthDayPicker';
import QueryBasedDropdown from '../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import StackColumn from '../common/StackColumn';
import UtcDatePicker from '../common/utcDatePicker/UtcDatePicker';
import { IComplianceRequirement, ITaxRate } from './CountryConfiguration.config';

const FilingTypeDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(ALL_PROCESS_TYPE_LIST_QUERY);
  const { allProcessTypes } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={allProcessTypes}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

const FilingFrequencyDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_FILLING_FREQUENCIES);
  const { filingFrequencies } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={filingFrequencies}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

const FilingPreparationCompanyDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_FILING_PREPARATION_COMPANIES);
  const { filingPreparationCompanies } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={filingPreparationCompanies}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

const TaxTypeDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, errorMessage, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_TAX_TYPES);
  const { taxTypes } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={taxTypes}
      errorMessage={errorMessage}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

interface ComplianceRequirementFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  complianceRequirement: IComplianceRequirement;
}

const ComplianceRequirementForm = ({
  form,
  complianceRequirement,
}: ComplianceRequirementFormProps): JSX.Element => {
  const [newTaxRateId, setNewTaxRateId] = useState(-1);
  const { control, errors, setValue, register, formState, trigger } = form;

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'taxRates',
    keyName: 'formId',
  });

  const stackStyles: CSSProperties = {
    position: 'relative',
    marginTop: 10,
    padding: 15,
  };

  const createTaxRateComponent = (): void => {
    insert(fields.length, {
      id: newTaxRateId,
      taxType: null,
      description: '',
      rate: null,
      effectiveDate: null,
    });
    setNewTaxRateId(newTaxRateId - 1);
  };

  const buttonStyles: IButtonStyles = {
    root: { position: 'absolute', right: 0, zIndex: 1, top: 0 },
  };

  const taxRateStyles = { root: { justifyContent: 'space-between' } };

  const addTaxRateStyle = { root: { alignSelf: 'center' } };

  return (
    <Stack tokens={{ childrenGap: 20 }} styles={{ root: { paddingBottom: 15 } }}>
      <input type="hidden" ref={register()} name="id" defaultValue={complianceRequirement?.id} />

      <Controller
        as={TextField}
        label="Requirement Name"
        id="name"
        name="name"
        control={control}
        required
        defaultValue={complianceRequirement?.name || ''}
        errorMessage={errors?.name?.message}
        rules={{
          required: 'Please enter the requirement name',
        }}
      />

      <MonthDayPicker
        form={form}
        dueDateDay={complianceRequirement?.filingDueDateDay || 1}
        dueDateMonth={complianceRequirement?.filingDueDateMonth || 1}
        dayFormName="filingDueDateDay"
        monthFormName="filingDueDateMonth"
        label="Filing Due Date"
        required
      />

      <Controller
        name="filingType"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <FilingTypeDropdown
            label="Filing Type"
            value={complianceRequirement?.filingType}
            valueKey="filingType"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('filingType', newValue);
            }}
            errors={errors}
          />
        )}
        defaultValue={complianceRequirement?.filingType || null}
        rules={{
          required: 'Please select a filing type',
        }}
        required
      />

      <Controller
        name="filingFrequency"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <FilingFrequencyDropdown
            label="Filing Frequency"
            value={complianceRequirement?.filingFrequency}
            valueKey="filingFrequency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('filingFrequency', newValue);
            }}
            errors={errors}
          />
        )}
        defaultValue={complianceRequirement?.filingFrequency || null}
        rules={{
          required: 'Please select a filing frequency',
        }}
        required
      />

      <Controller
        name="filingPreparationCompany"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <FilingPreparationCompanyDropdown
            label="Filing Preparation Company"
            value={complianceRequirement?.filingPreparationCompany}
            valueKey="filingPreparationCompany"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('filingPreparationCompany', newValue);
            }}
            errors={errors}
          />
        )}
        defaultValue={complianceRequirement?.filingPreparationCompany || null}
        rules={{
          required: 'Please select a filing preparation company',
        }}
        required
      />

      <Controller
        as={TextField}
        label="Comments"
        id="comments"
        name="comments"
        control={control}
        multiline
        resizable={false}
        defaultValue={complianceRequirement?.comments || ''}
        errorMessage={errors?.comments?.message}
        rules={{
          maxLength: {
            value: 250,
            message: 'Input is greater than the maximum character limit of 250.',
          },
        }}
      />

      <Stack horizontal styles={taxRateStyles}>
        <Stack>
          <h3>Tax Rates</h3>
        </Stack>
        <Stack styles={addTaxRateStyle}>
          <Stack>
            <PrimaryButton
              text="Add New Tax Rate"
              iconProps={{ iconName: 'Add' }}
              onClick={createTaxRateComponent}
            />
          </Stack>
        </Stack>
      </Stack>

      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fields as any).map((taxRate: ITaxRate, index: number) => {
          const fieldName = `taxRates[${index}]`;
          return (
            <Stack
              key={taxRate.id}
              horizontal
              tokens={{ childrenGap: 10 }}
              horizontalAlign="space-between"
              style={stackStyles}
              className="ms-depth-8"
            >
              <IconButton
                styles={buttonStyles}
                iconProps={{ iconName: 'Cancel' }}
                title="Remove Tax Rate"
                ariaLabel="Remove Tax Rate"
                onClick={(): void => {
                  remove(index);
                }}
              />
              <input
                type="hidden"
                ref={register()}
                name={`${fieldName}.id`}
                defaultValue={taxRate.id}
              />
              <StackColumn minWidth="200px">
                <Controller
                  name={`${fieldName}.taxType`}
                  control={control}
                  render={({ onChange, onBlur, value, name }): React.ReactElement => (
                    <TaxTypeDropdown
                      label="Tax Type"
                      value={taxRate?.taxType}
                      valueKey="taxType"
                      handleChange={(newValue: string, valueKey: string): void => {
                        setValue(`${fieldName}.taxType`, newValue);
                      }}
                      errors={errors}
                      errorMessage={errors?.taxRates?.[index]?.taxType?.message}
                      required
                    />
                  )}
                  defaultValue={taxRate?.taxType || null}
                  rules={{
                    required: 'Please select a tax type',
                  }}
                />

                <Controller
                  as={TextField}
                  label="Description"
                  name={`${fieldName}.taxDescription`}
                  control={control}
                  resizable={false}
                  errorMessage={errors?.taxRates?.[index]?.taxDescription?.message}
                  defaultValue={taxRate?.taxDescription || ''}
                  rules={{
                    maxLength: {
                      value: 250,
                      message: 'Input is greater than the maximum character limit of 250.',
                    },
                  }}
                />
              </StackColumn>
              <StackColumn minWidth="200px">
                <Controller
                  name={`${fieldName}.rate`}
                  control={control}
                  render={(): React.ReactElement => (
                    <TextField
                      label="Tax Rate"
                      type="number"
                      required
                      min={0}
                      onBlur={(): void => {
                        if (formState.isSubmitted) {
                          trigger();
                        }
                      }}
                      onChange={(
                        event: React.FormEvent<HTMLInputElement>,
                        newValue: string,
                      ): void => {
                        setValue(`${fieldName}.rate`, parseFloat(newValue) || null);
                      }}
                      defaultValue={taxRate?.rate?.toString() || null}
                      errorMessage={errors?.taxRates?.[index]?.rate?.message}
                    />
                  )}
                  rules={{
                    min: {
                      value: 0,
                      message: 'Tax Rate cannot be less than zero',
                    },
                    required: 'Please provide a Tax Rate',
                  }}
                />

                <Controller
                  name={`${fieldName}.effectiveDate`}
                  control={control}
                  defaultValue={taxRate.effectiveDate}
                  render={({ value }): React.ReactElement => (
                    <UtcDatePicker
                      label="Effective Date"
                      isRequired
                      textField={{
                        name: 'effectiveDate',
                        errorMessage: errors?.taxRates?.[index]?.effectiveDate?.message,
                      }}
                      value={value ? momentUtc(value) : null}
                      onSelectDate={(utcDateString: string): void => {
                        setValue(`${fieldName}.effectiveDate`, utcDateString);
                      }}
                    />
                  )}
                  rules={{
                    required: 'Please provide a new date',
                  }}
                />
              </StackColumn>
            </Stack>
          );
        })
      }
    </Stack>
  );
};

export default ComplianceRequirementForm;
