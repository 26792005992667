import { Stack } from '@fluentui/react';
import React from 'react';
import UtcDatePicker from '../../common/utcDatePicker/UtcDatePicker';

interface IDateRangePickerProps {
  label: string | JSX.Element;
  onUpdateDate: (startDate: string, endDate: string) => void;
  initialStartDate?: string;
  initialEndDate?: string;
}
const DateRangePicker = (props: IDateRangePickerProps): JSX.Element => {
  const { label, onUpdateDate, initialStartDate, initialEndDate } = props;

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <strong>{label}</strong>
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <UtcDatePicker
          label="From"
          value={initialStartDate}
          onSelectDate={(date) => {
            onUpdateDate(date, initialEndDate);
          }}
          allowTextInput
        />
        <UtcDatePicker
          label="To"
          value={initialEndDate}
          onSelectDate={(date) => {
            onUpdateDate(initialStartDate, date);
          }}
          allowTextInput
        />
      </Stack>
    </Stack>
  );
};
export default DateRangePicker;
