import config from '../../components/common/ConfigLoader';
import { getAuthInstance } from '../ApiProvider';
import { getSilentStatApiRequest, getStatApiRequest } from '../AuthConfig';
import { IFileValidationError } from '../validation/validateFiles';
import { StatUser } from './UsersApi';

export interface IFilesPayload {
  file: File;
  fileNameWithTimeStamp: string;
  fileName: string;
  fileType: string;
  validationErrors?: IFileValidationError[];
}
export interface IDocument {
  title: string;
  url: string;
  uploadDate: Date;
  uploader?: StatUser;
  id?: number;
  isDeleted: boolean;
}

const getDirectoryFromRouteParams = (companyCode = ''): string => {
  const url = window.location;
  if (url.pathname.indexOf('process-extensions') !== -1) {
    return 'process-extensions';
  }

  if (url.pathname.indexOf('company-creation') !== -1) {
    return 'companydocuments';
  }

  if (companyCode !== '') {
    if (url.pathname.indexOf('companies') !== -1 && url.pathname.indexOf('documents') !== -1) {
      return 'companydocuments';
    }

    return companyCode;
  }

  return '';
};

const BuildDocumentApiUrl = () => {
  const baseUri = `${config?.settings?.baseURI}/api/`;

  return `${baseUri}CompanyDocument`;
};

export const DownloadDocument = async (
  documentId: string,
  onError: (errorMessage: string) => void,
) => {
  const authinstance = getAuthInstance();

  const token = await authinstance.acquireToken(getSilentStatApiRequest(), getStatApiRequest());
  const result = await fetch(
    `${BuildDocumentApiUrl()}?documentId=${encodeURIComponent(documentId)}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  if (!result.ok) {
    const errorResult = await result.json();
    onError(errorResult?.message);
  }
  return result.blob();
};

// fetch does not yet support progress indicator, use xhr for now.
export const UploadDocument = async (
  files: IFilesPayload[],
  payloadSize: number,
  onProgress: (percentageDone: number) => void,
  onError: (errorMessage?: string) => void,
  onSuccess: (files: IDocument[]) => void,
  companyCode = '',
) => {
  const authinstance = getAuthInstance();

  const token = await authinstance.acquireToken(getSilentStatApiRequest(), getStatApiRequest());

  const promises = Array<Promise<void | Response>>();
  const progressArray = Array<number>();
  const uploadedFilesArray = Array<IDocument>();
  const directory = getDirectoryFromRouteParams(companyCode);
  const directoryPrefix = directory === '' ? '' : `${directory}/`;

  try {
    files.forEach((fileObject, index) => {
      progressArray[index] = 0;

      promises.push(
        new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.upload.addEventListener('progress', (e) => {
            progressArray[index] = e.loaded;
            onProgress(progressArray.reduce((a, b) => a + b) / payloadSize);
          });
          xhr.addEventListener('error', () => reject(new Error('File upload failed')));
          xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
          xhr.addEventListener('load', () =>
            xhr.status !== 200
              ? reject(new Error('Error uploading'))
              : resolve({ status: xhr.status, body: xhr.responseText }),
          );

          xhr.open('POST', BuildDocumentApiUrl(), true);

          xhr.setRequestHeader('Authorization', `bearer ${token} `);

          const formData = new FormData();

          formData.append(
            'formFile',
            fileObject.file,
            `${directoryPrefix}${fileObject.fileNameWithTimeStamp}`,
          );

          xhr.send(formData);
        }).then(() => {
          uploadedFilesArray.push({
            title: fileObject.fileName,
            url: `${directoryPrefix}${fileObject.fileNameWithTimeStamp}`,
            uploadDate: new Date(),
            isDeleted: false,
          });
        }),
      );
    });
  } catch (error) {
    onError(error?.message);
  }

  await Promise.all(promises)
    .then(() => onSuccess(uploadedFilesArray))
    .catch((error) => onError(error?.message));
};
