import { Label, Stack } from '@fluentui/react';
import React from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { DoubleRequiredMarker } from '../../common/formFields/RequiredMarker';
import RequiredFieldsNotification from '../RequiredNotification';
import CompanyContactsPicker from './CompanyContactsPicker';
import {
  CompanyCreationContactType,
  ICompanyCreationContact,
  IStatutoryContacts,
  RequiredFieldsType,
} from './types';

interface IStatutoryContactsFormProps {
  form: UseFormMethods<IStatutoryContacts>;
  data?: IStatutoryContacts;
  isBpoRequired: boolean;
}

const requiredLabelStyles = { paddingBottom: 0 };

const StatutoryContactsForm = ({
  data,
  form,
  isBpoRequired,
}: IStatutoryContactsFormProps): JSX.Element => {
  const { control, errors, setValue } = form;

  const updateForm = (
    formKey: keyof IStatutoryContacts,
    contacts: ICompanyCreationContact[],
    contactType: CompanyCreationContactType,
  ): void => {
    const newContacts = [] as ICompanyCreationContact[];
    contacts.forEach((contact) => {
      contact.contactType = contactType;
      newContacts.push(contact);
    });
    setValue(formKey, newContacts);
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <RequiredFieldsNotification type={RequiredFieldsType.StatSetup} />
      <Controller
        name="statutoryController"
        valueKey="statutoryController"
        control={control}
        defaultValue={data?.statutoryController}
        render={() => {
          return (
            <>
              <Label htmlFor="statutoryControllerPicker" style={requiredLabelStyles}>
                Statutory Controller <DoubleRequiredMarker />
              </Label>
              <CompanyContactsPicker
                removeButtonAriaLabel="Remove Statutory Controller"
                controlName="statutoryController"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm(
                    'statutoryController',
                    contacts,
                    CompanyCreationContactType.StatutoryController,
                  );
                }}
                errorMessage={(errors?.statutoryController as unknown as FieldError)?.message}
                defaultValue={data?.statutoryController}
                itemLimit={1}
              />
            </>
          );
        }}
      />
      <Controller
        name="regionalControllers"
        valueKey="regionalControllers"
        control={control}
        defaultValue={data?.regionalControllers}
        render={() => {
          return (
            <>
              <Label htmlFor="regionalControllersPicker" style={requiredLabelStyles}>
                Regional Controller <DoubleRequiredMarker />
              </Label>
              <CompanyContactsPicker
                removeButtonAriaLabel="Remove Regional Controller"
                controlName="regionalControllers"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm(
                    'regionalControllers',
                    contacts,
                    CompanyCreationContactType.RegionalController,
                  );
                }}
                errorMessage={(errors?.regionalControllers as unknown as FieldError)?.message}
                defaultValue={data?.regionalControllers}
              />
            </>
          );
        }}
      />
      <Controller
        name="fsTaxRepContacts"
        valueKey="fsTaxRepContacts"
        control={control}
        defaultValue={data?.fsTaxRepContacts}
        render={() => {
          return (
            <>
              <Label htmlFor="fsTaxRepContactsPicker" style={requiredLabelStyles}>
                F/S Tax Rep <DoubleRequiredMarker />
              </Label>
              <CompanyContactsPicker
                removeButtonAriaLabel="Remove Tax Rep"
                controlName="fsTaxRepContacts"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm(
                    'fsTaxRepContacts',
                    contacts,
                    CompanyCreationContactType.TaxSupportRep,
                  );
                }}
                errorMessage={(errors?.fsTaxRepContacts as unknown as FieldError)?.message}
                defaultValue={data?.fsTaxRepContacts}
              />
            </>
          );
        }}
      />
      <Controller
        name="treasuryRepContacts"
        valueKey="treasuryRepContacts"
        control={control}
        defaultValue={data?.treasuryRepContacts}
        render={() => {
          return (
            <>
              <Label htmlFor="treasuryRepContactsPicker" style={requiredLabelStyles}>
                Treasury Rep <DoubleRequiredMarker />
              </Label>
              <CompanyContactsPicker
                removeButtonAriaLabel="Remove Treasury Rep"
                controlName="treasuryRepContacts"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm(
                    'treasuryRepContacts',
                    contacts,
                    CompanyCreationContactType.TreasurySupportRep,
                  );
                }}
                errorMessage={(errors?.treasuryRepContacts as unknown as FieldError)?.message}
                defaultValue={data?.treasuryRepContacts}
              />
            </>
          );
        }}
      />
      <Controller
        name="bpoContacts"
        valueKey="bpoContacts"
        control={control}
        defaultValue={data?.bpoContacts}
        render={() => {
          return (
            <>
              <Label htmlFor="bpoContactsPicker" style={requiredLabelStyles}>
                BPO Contacts {isBpoRequired ? <DoubleRequiredMarker /> : null}
              </Label>
              <CompanyContactsPicker
                removeButtonAriaLabel="Remove BPO Contacts"
                controlName="bpoContacts"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm('bpoContacts', contacts, CompanyCreationContactType.BpoContact);
                }}
                errorMessage={(errors?.bpoContacts as unknown as FieldError)?.message}
                defaultValue={data?.bpoContacts}
              />
            </>
          );
        }}
      />
      <Controller
        name="alternativeContacts"
        valueKey="alternativeContacts"
        control={control}
        defaultValue={data?.alternativeContacts}
        render={() => {
          return (
            <CompanyContactsPicker
              removeButtonAriaLabel="Remove Alternative Contacts"
              label="Alternative Contacts"
              controlName="alternativeContacts"
              onChange={(contacts: ICompanyCreationContact[]): void => {
                updateForm(
                  'alternativeContacts',
                  contacts,
                  CompanyCreationContactType.AlternativeContact,
                );
              }}
              errorMessage={(errors?.alternativeContacts as unknown as FieldError)?.message}
              defaultValue={data?.alternativeContacts}
            />
          );
        }}
      />
    </Stack>
  );
};

export default StatutoryContactsForm;
