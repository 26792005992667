import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_COMPANY_NAME_SAP_INFO } from '../../../utils/statApi/CompaniesApi';
import { ICompanyNameSapInfo } from '../../../utils/types/ICompanyNameSapInfo';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CompanyNameSapInfoForm from './forms/CompanyNameSapInfoForm';

interface ICompanyNameSapInfoPanelProps {
  closePanel: () => void;
  data: ICompanyNameSapInfo;
}

const CompanyNameSapInfoPanel = ({
  closePanel,
  data,
}: ICompanyNameSapInfoPanelProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const form = useForm<FieldValues>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateCompanyNameSapInfo, { loading, error }] = useMutation(UPDATE_COMPANY_NAME_SAP_INFO, {
    onCompleted: () => {
      closePanel();
    },
  });

  const handleSaveClick = (formData: ICompanyNameSapInfo): void => {
    updateCompanyNameSapInfo({
      variables: {
        companyCode,
        input: {
          id: data.id,
          legalFullName: formData.legalFullName,
          sapLegalName: formData.sapLegalName,
          sapNameShortForm: formData.sapNameShortForm,
          sapRegion: formData.sapRegion?.id,
          physicalCountryCode: formData.physicalCountry?.id,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Name & SAP Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Name & SAP Info Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Name & SAP Info"
          saveTitle="Save Name & SAP Info"
          cancelLabel="Cancel Name & SAP Info"
          cancelTitle="Cancel Name & SAP Info"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <CompanyNameSapInfoForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyNameSapInfoPanel;
