/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import Card from '../../common/card/Card';
import SpanWithLabel from '../../common/SpanWithLabel';
import { toTitleCase } from '../../../utils/Helpers';

interface IChunkOutputDetailsProps {
  chunk: string;
}

const ChunkOutputDetails = (props: IChunkOutputDetailsProps): JSX.Element => {
  const { chunk } = props;

  if (!chunk) {
    return <></>;
  }
  const outputArray = JSON.parse(chunk);

  return (
    <>
      <br />
      <Card title="Output" headingLevel={2}>
        {Object.keys(outputArray).map((property: string) => {
          const value = outputArray[property];
          if (Array.isArray(value)) {
            return (
              <>
                <h3>{toTitleCase(property)}</h3>
                {value.map((item: any, index: number) => (
                  <>
                    <div key={`${property}}`}>
                      {Object.keys(item).map((itemProperty: string) => (
                        <SpanWithLabel
                          labelText={toTitleCase(itemProperty)}
                          value={item[itemProperty]}
                          key={`${property}-${itemProperty}`}
                        />
                      ))}

                      <br />
                    </div>
                  </>
                ))}
              </>
            );
          }
          return <SpanWithLabel labelText={toTitleCase(property)} value={value} key={property} />;
        })}
        {!Array.isArray(outputArray) && outputArray.length === 0 && (
          <SpanWithLabel labelText="Output" value="No observations" />
        )}
      </Card>
    </>
  );
};

export default ChunkOutputDetails;
