import { mergeStyles } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { IRecentCompaniesCardItem } from '../../utils/types/IRecentCompaniesCardItem';
import { LocalStorageKeys } from '../../utils/hooks/UseLocalStorage';
import GlobeSvg from '../common/svgs/GlobeSvg';
import InsightCard from './InsightCard';
import { legacyCompaniesCardContent } from './LegacyCompanyCard';
import ListItem from './ListItem';
import { MessagingColors } from '../../app/common/styles/CommonStyleObjects';

const listStyle = mergeStyles({
  paddingLeft: 0,
});

const RecentCompaniesCard = (): JSX.Element => {
  const companiesString: string = localStorage.getItem(LocalStorageKeys.recentCompanies);
  const companiesList: IRecentCompaniesCardItem[] = JSON.parse(companiesString);
  let recentCompaniesCardContent: JSX.Element = <></>;

  const getSubText = (company: { companyCode: string }): JSX.Element => {
    return (
      <>
        <Link to={`/companies/${company.companyCode}/fiscal-years`}>Fiscal Year Summary</Link>
        {' | '}
        <Link to={`/companies/${company.companyCode}/service-contacts`}>Service Contacts</Link>
        {' | '}
        <Link to={`/companies/${company.companyCode}/processes`}>Processes</Link>
      </>
    );
  };
  if (companiesList !== null && companiesList?.length > 0) {
    recentCompaniesCardContent = (
      <ul className={listStyle}>
        {companiesList?.map((company) => {
          return (
            <ListItem
              key={company.companyCode}
              title={`${company.companyCode} - ${company.sapName}`}
              sideColor={MessagingColors.successIcon}
              subText={getSubText(company)}
            />
          );
        })}
      </ul>
    );
  } else {
    recentCompaniesCardContent = legacyCompaniesCardContent;
  }
  return (
    <InsightCard
      caption="Recent Companies"
      mainText={recentCompaniesCardContent}
      footerLink={<Link to="/companies">Go to Company &#38; Process Management</Link>}
      svgComponent={<GlobeSvg />}
    />
  );
};

export default RecentCompaniesCard;
