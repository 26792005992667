import React from 'react';
import { TextField, Stack, StackItem, Checkbox } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Controller, useWatch } from 'react-hook-form';
import IDividendFormProps from './IDividendFormProps';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import { GET_DIVIDEND_REASONS_QUERY } from '../../../../utils/statApi/CompanyFiscalYearsApi';
import maxDividendAllowedStyle from './DividendFormStyles';

const DividendForm = ({ dividends, form }: IDividendFormProps): JSX.Element => {
  const companyId = dividends?.companyId;
  const { control, getValues, errors, setValue } = form;

  const watchDividendAmounts = useWatch({
    control,
    name: ['maxDividendAllowed', 'proposedDividendAllowed'],
    defaultValue: {
      maxDividendAllowed: dividends?.maxDividendAllowed.toString(),
      proposedDividendAllowed: dividends?.proposedDividendAllowed.toString(),
    },
  });

  const watchNoDividendDeclared = useWatch({
    control,
    name: 'noDividendDeclared',
    defaultValue: dividends?.noDividendDeclared,
  });

  const watchLessDividendReason = useWatch({
    control,
    name: 'lessDividendReason',
    defaultValue: dividends?.lessDividendReason,
  });

  const validateProposedDividendAllowed = (newVal: string): string => {
    const parsedVal = parseInt(newVal, 10);

    if (parsedVal > parseInt(getValues('maxDividendAllowed'), 10)) {
      return 'Proposed Dividend Amount cannot be greater than the Maximum Dividend Allowed';
    }

    return null;
  };

  const validateCurrentAndRetainedDividends = (newVal: string): string => {
    if (
      parseInt(getValues('dividendsFromCurrentYear'), 10) +
        parseInt(getValues('dividendsFromRetainedEarnings'), 10) !==
      parseInt(getValues('proposedDividendAllowed'), 10)
    ) {
      return 'The proposed dividend amount must equal the dividends from current year + dividends from retained earnings';
    }

    return null;
  };

  const validateComments = (newVal: string): string => {
    if (!newVal && (watchNoDividendDeclared || watchLessDividendReason?.fieldName === 'Other')) {
      return 'Comments are required';
    }
    return null;
  };

  const validateLessDividendReason = (newVal: IDropdownReference): string => {
    if (
      (!newVal?.id || newVal?.fieldName === '--') &&
      parseInt(watchDividendAmounts?.proposedDividendAllowed, 10) <
        parseInt(watchDividendAmounts?.maxDividendAllowed, 10)
    ) {
      return 'Reason is required if Proposed Dividend Amount is less than Max Dividend Allowed';
    }
    return null;
  };

  const DividendReasonDropdown = (props: IFieldsetProps): JSX.Element => {
    const {
      value: divValue,
      label,
      errors: divErrors,
      handleChange,
      valueKey,
      outputKey,
      required,
    } = props;
    const { loading: divLoading, data: divData } = useQuery(GET_DIVIDEND_REASONS_QUERY);
    const { dividendReasons } = !divLoading && divData ? divData : [];

    return (
      <QueryBasedDropdown
        required={required}
        value={divValue as IDropdownReference}
        label={label}
        outputKey={outputKey}
        dropdownOptionsData={dividendReasons}
        errors={divErrors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <StackItem>
          <Controller
            as={TextField}
            id="maxDividendAllowed"
            label="Maximum Dividend Allowed"
            name="maxDividendAllowed"
            type="number"
            className={maxDividendAllowedStyle}
            required
            min={0}
            defaultValue={dividends.maxDividendAllowed}
            control={control}
            rules={{
              min: {
                value: 0,
                message: 'Maximum Dividend Allowed cannot be less than zero',
              },
              required: 'Please provide a Maximum Dividend Allowed',
            }}
            errorMessage={errors?.maxDividendAllowed?.message}
          />
        </StackItem>
        <StackItem>
          <Controller
            name="currencyCode"
            control={control}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <CurrencyCodeDropdown
                useDefaultCurrenciesForCompanyId={companyId}
                propertyName="currencyCode"
                handleChange={(newValue: string, valueKey: string): void => {
                  setValue('currencyCode', newValue);
                }}
                defaultValue={dividends.currencyCode}
                errorMessage={errors?.currencyCode?.message}
                required
              />
            )}
            defaultValue={dividends.currencyCode}
            rules={{
              required: 'Please provide a Currency Code',
            }}
            required
          />
        </StackItem>
      </Stack>

      <UtcDatePicker
        label="Declared Date"
        disabled
        allowTextInput
        textField={{
          name: 'declaredDate',
          errorMessage: errors?.declaredDate?.message,
        }}
        value={dividends.declaredDate}
      />

      <Controller
        id="proposedDividendAllowed"
        name="proposedDividendAllowed"
        control={control}
        defaultValue={dividends.proposedDividendAllowed}
        render={({ onChange, value, name }): React.ReactElement => (
          <TextField
            label="Proposed Dividend Amount"
            disabled={watchNoDividendDeclared}
            value={value}
            type="number"
            min={0}
            onChange={onChange}
            name={name}
            onBlur={(): void => {
              if (parseInt(getValues('proposedDividendAllowed'), 10) === 0) {
                setValue('noDividendDeclared', true);
              }
            }}
            errorMessage={errors?.proposedDividendAllowed?.message}
          />
        )}
        rules={{
          min: {
            value: 0,
            message: 'Proposed Dividend Amount cannot be less than zero',
          },
          required: 'Please provide a Proposed Dividend Amount',
          validate: validateProposedDividendAllowed,
        }}
      />
      <Controller
        id="dividendsFromCurrentYear"
        name="dividendsFromCurrentYear"
        control={control}
        defaultValue={dividends.dividendsFromCurrentYear}
        render={({ onChange, value, name }): React.ReactElement => (
          <TextField
            label="Dividends from Current Year"
            disabled={watchNoDividendDeclared}
            value={value}
            type="number"
            min={0}
            onChange={onChange}
            name={name}
            errorMessage={errors?.dividendsFromCurrentYear?.message}
          />
        )}
        rules={{
          min: {
            value: 0,
            message: 'Dividends from Current Year cannot be less than zero',
          },
          required: 'Please provide the Dividends from Current Year',
          validate: validateCurrentAndRetainedDividends,
        }}
      />
      <Controller
        id="dividendsFromRetainedEarnings"
        name="dividendsFromRetainedEarnings"
        control={control}
        defaultValue={dividends.dividendsFromRetainedEarnings}
        render={({ onChange, value, name }): React.ReactElement => (
          <TextField
            label="Dividends from Retained Earnings"
            disabled={watchNoDividendDeclared}
            value={value}
            type="number"
            min={0}
            onChange={onChange}
            name={name}
            errorMessage={errors?.dividendsFromRetainedEarnings?.message}
          />
        )}
        rules={{
          min: {
            value: 0,
            message: 'Dividends from Retained Earnings cannot be less than zero',
          },
          required: 'Please provide the Dividends from Retained Earnings',
          validate: validateCurrentAndRetainedDividends,
        }}
      />
      <Controller
        name="noDividendDeclared"
        control={control}
        defaultValue={dividends.noDividendDeclared}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="No Dividend Proposed this year"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              onChange(checked);
              if (checked) {
                setValue('proposedDividendAllowed', 0);
                setValue('dividendsFromCurrentYear', 0);
                setValue('dividendsFromRetainedEarnings', 0);
              }
            }}
            checked={value}
            name={name}
          />
        )}
      />

      <Controller
        render={(props) => (
          <DividendReasonDropdown
            label="Reason Proposed Is Less Than Max"
            valueKey="lessDividendReason"
            value={props.value}
            required={
              parseInt(watchDividendAmounts.proposedDividendAllowed, 10) <
              parseInt(watchDividendAmounts.maxDividendAllowed, 10)
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleChange={(newValue: any, valueKey: string): void => {
              setValue('lessDividendReason', newValue);
            }}
            errors={errors}
          />
        )}
        name="lessDividendReason"
        control={control}
        defaultValue={dividends.lessDividendReason}
        value={dividends.lessDividendReason}
        rules={{ validate: validateLessDividendReason }}
      />

      <Controller
        as={TextField}
        label="Comments"
        id="comments"
        name="comments"
        control={control}
        required={watchNoDividendDeclared || watchLessDividendReason?.fieldName === 'Other'}
        defaultValue={dividends.comments || ''}
        rules={{ validate: validateComments }}
        errorMessage={errors?.comments?.message}
        multiline
        rows={8}
      />
    </Stack>
  );
};

export default DividendForm;
