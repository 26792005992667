import React, { useEffect, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import { onRenderCheckbox } from '@coherence-design-system/controls';
import {
  CommandBar,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  IDetailsRowProps,
  IObjectWithKey,
  IRenderFunction,
  ISearchBoxStyles,
  SearchBox,
  SelectionMode,
  Selection,
  Stack,
  MessageBar,
  MessageBarType,
  IGroup,
  ConstrainMode,
  IStackStyles,
  IDetailsRowStyles,
  IStyle,
  IColumn,
  CheckboxVisibility,
  Sticky,
  StickyPositionType,
  IColumnReorderOptions,
  ScrollablePane,
  ScrollbarVisibility,
  ShimmeredDetailsList,
  NeutralColors,
  Announced,
  IContextualMenuProps,
  IDetailsList,
  ContextualMenu,
  Dialog,
  TextField,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  ITextField,
  DialogType,
} from '@fluentui/react';

import {
  defaultListContainerStyles,
  getSortableColumnHeaderStyles,
  lockedStyledDetailsList,
  resetScrollablePanelStyles,
  styledDetailsListCompact,
} from '../../../app/common/styles/CommonStyleObjects';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';
import { IListSortDefinition, SortDirection } from '../../../utils/types/IListSortDefinition';
import { IColumnConfig } from './ColumnConfigHelper';

export interface IGeneralEntityListProps<O> {
  data: O[];
  groups?: IGroup[];
  listColumns: IColumnConfig[];
  commandBarItems?: ICommandBarItemProps[];
  onChangeSelectedItems?: (selection: IObjectWithKey[]) => void;
  onEditColumnOrderClick?: () => void;
  onSearchBoxChange?: (event: React.ChangeEvent<HTMLInputElement>, searchItem: string) => void;
  initialSearchItem?: string;
  onSort?: (col: IColumn) => void;
  sortDefinition?: IListSortDefinition;
  setInitialSelection?: (data: O[], selection: Selection) => void;
  disableSelect?: boolean;
  hideSelectedCount?: boolean;
  loading?: boolean;
  error?: ApolloError;
  layoutMode?: DetailsListLayoutMode;
  lockActionsColumn?: boolean;
  listWrapperStyles?: IStackStyles;
  isHeaderVisible?: boolean;
  setListColumns?: (columns: IColumnConfig[]) => void;
  frozenColumnsFromStart?: number;
  GeneralFilterTagHandler?: () => JSX.Element;
}

const RESIZE = 'Resize';
const REORDER = 'Reorder';

const dialogStyles = { main: { maxWidth: 450 } };

const GeneralEntityList = <O,>(props: IGeneralEntityListProps<O>): JSX.Element => {
  const {
    data,
    commandBarItems,
    groups,
    onChangeSelectedItems,
    listColumns,
    onEditColumnOrderClick,
    onSearchBoxChange,
    initialSearchItem,
    setInitialSelection,
    disableSelect,
    hideSelectedCount,
    loading,
    error,
    layoutMode = DetailsListLayoutMode.justified,
    lockActionsColumn,
    listWrapperStyles,
    isHeaderVisible,
    onSort,
    sortDefinition,
    setListColumns,
    frozenColumnsFromStart,
    GeneralFilterTagHandler,
  } = props;
  const commandBarStyles = { root: { padding: ' 0 5px' } };
  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      width: 'auto',
      marginTop: '5px',
      marginLeft: '9px',
      marginBottom: '5px',
    },
  };
  const selectedDividerStyles = {
    paddingRight: '5px',
    marginBottom: '5px',
    borderRight: `1px solid ${NeutralColors.gray110}`,
  };

  const selectionRef = useRef(
    new Selection({
      onSelectionChanged: () => {
        if (onChangeSelectedItems) {
          onChangeSelectedItems(selectionRef.current.getSelection());
        }
      },
      items: data as IObjectWithKey[],
    }),
  );

  const getSelectionDetails = (selectionState: Selection<IObjectWithKey>): JSX.Element => {
    const selectionCount = selectionState.getSelectedCount();
    if (selectionCount > 0) {
      const selectedCountItem = {
        key: 'selectedCount',
        text: `${selectionCount} selected`,
        onRender: () => {
          return (
            <p style={selectedDividerStyles}>
              {selectionCount} selected
              <Announced message={`${selectionCount} selected`} />
            </p>
          );
        },
      };

      return (
        <CommandBar
          items={commandBarItems ? [selectedCountItem, ...commandBarItems] : [selectedCountItem]}
          ariaLabel="Task Actions"
          primaryGroupAriaLabel="Task actions"
          farItemsGroupAriaLabel="Task actions"
          styles={commandBarStyles}
          overflowButtonProps={{ ariaLabel: 'More actions' }}
        />
      );
    }
    return <></>;
  };

  const sortColumns = () => {
    if (!sortDefinition?.sortDir || !sortDefinition?.sortKey) {
      return listColumns;
    }
    const activeColumns = listColumns.filter((x) => x.active !== false && x.active !== undefined);
    const newColumns: IColumn[] = activeColumns.slice();
    const currentColumn: IColumn = newColumns.find(
      (currCol) => sortDefinition.sortKey === currCol.key,
    );

    newColumns.forEach((newColumn: IColumn) => {
      if (newColumn === currentColumn) {
        newColumn.isSortedDescending = sortDefinition.sortDir === SortDirection.DESC;
        newColumn.isSorted = true;
        newColumn.styles = getSortableColumnHeaderStyles(true);
      } else {
        newColumn.isSorted = false;
        newColumn.isSortedDescending = true;
        newColumn.styles = getSortableColumnHeaderStyles(false);
      }
    });
    return newColumns;
  };
  const sortedColumns = sortColumns();
  // If the data source changes we must unselect
  useEffect(() => {
    if (loading && !hideSelectedCount) {
      selectionRef.current.setAllSelected(false);
    }
    if (!loading && data?.length > 0 && setInitialSelection) {
      setInitialSelection(data, selectionRef.current);
    }
  }, [loading]);

  const selectionDetails = getSelectionDetails(selectionRef.current);

  // Fix to allow user to select text from columns. Without it, row selection
  // code takes over the highlight functionality
  const onRenderRow = (
    rowProps: IDetailsRowProps,
    defaultRender?: IRenderFunction<IDetailsRowProps>,
  ): JSX.Element => {
    const customStyles: Partial<IDetailsRowStyles> = { root: {} as IStyle };
    customStyles.root = { userSelect: 'any' };
    rowProps.styles = customStyles;
    const ariaLabelCheckbox = rowProps?.item?.name;
    if (ariaLabelCheckbox?.length > 0) {
      rowProps.checkButtonAriaLabel = `Select ${rowProps.item.name}`;
    }
    return <>{defaultRender && defaultRender(rowProps)}</>;
  };

  const handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
    const draggedItems = sortedColumns[draggedIndex];
    const newColumns: IColumn[] = [...sortedColumns];

    // insert before the dropped item
    newColumns.splice(draggedIndex, 1);
    newColumns.splice(targetIndex, 0, draggedItems);
    // reset columns
    setListColumns(
      newColumns.map((sc: IObjectWithKey, index: number) => {
        return {
          ...sc,
          key: sc.key,
          active: true,
          order: index,
        } as IColumnConfig;
      }),
    );
  };

  const getColumnReorderOptions = (): IColumnReorderOptions => {
    return {
      frozenColumnCountFromStart: frozenColumnsFromStart || 0,
      frozenColumnCountFromEnd: 0,
      handleColumnReorder,
    };
  };

  const farItems = onEditColumnOrderClick
    ? [
        {
          key: 'editColumnOrder',
          text: 'Edit Columns',
          iconProps: { iconName: 'CalendarSettings' },
          onClick: onEditColumnOrderClick,
          ariaLabel: 'Edit Columns',
          iconOnly: true,
        },
      ]
    : [];

  // This is used to setup keyboard controls for columns. This can be removed on the update to fluent 9
  const [contextualMenuProps, setContextualMenuProps] = React.useState<
    IContextualMenuProps | undefined
  >(undefined);
  const [isDialogHidden, setIsDialogHidden] = React.useState(true);
  const columnToEdit = React.useRef<IColumn | null>(null);
  const clickHandler = React.useRef<string>(RESIZE);
  const textfieldRef = React.useRef<ITextField>(null);
  const input = React.useRef<number | null>(null);
  const detailsListRef = React.useRef<IDetailsList>(null);

  const onHideContextualMenu = React.useCallback(() => setContextualMenuProps(undefined), []);

  const resizeDialogContentProps = {
    type: DialogType.normal,
    title: 'Resize Column',
    closeButtonAriaLabel: 'Close',
    subText: 'Enter desired column width pixels:',
  };

  const reorderDialogContentProps = {
    type: DialogType.normal,
    title: 'Reorder Column',
    closeButtonAriaLabel: 'Close',
    subText: 'Enter which column to move this to (use 1-based indexing):',
  };

  const modalProps = {
    titleAriaId: 'Dialog',
    subtitleAriaId: 'Dialog sub',
    isBlocking: false,
    styles: dialogStyles,
  };

  const resizeColumn = (column: IColumn) => {
    columnToEdit.current = column;
    clickHandler.current = RESIZE;
    setIsDialogHidden(false);
  };

  const reorderColumn = (column: IColumn) => {
    columnToEdit.current = column;
    clickHandler.current = REORDER;
    setIsDialogHidden(false);
  };

  const getContextualMenuProps = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): IContextualMenuProps => {
    const items = [
      { key: 'resize', text: 'Resize', onClick: () => resizeColumn(column) },
      { key: 'reorder', text: 'Reorder', onClick: () => reorderColumn(column) },
      {
        key: 'sort',
        text: 'Sort',
        onClick: () => {
          if (onSort !== undefined) {
            onSort(column);
          }
        },
      },
    ];

    return {
      items,
      target: ev.currentTarget as HTMLElement,
      gapSpace: 10,
      isBeakVisible: true,
      onDismiss: onHideContextualMenu,
    };
  };

  const confirmDialog = () => {
    if (textfieldRef.current) {
      input.current = Number(textfieldRef.current.value);
    }

    if (columnToEdit.current && input.current) {
      if (clickHandler.current === RESIZE) {
        const detailsList = detailsListRef.current;
        const width = input.current;
        detailsList.updateColumn(columnToEdit.current, { width });
      } else if (clickHandler.current === REORDER) {
        const previousIndex = listColumns.findIndex((col) => col.key === columnToEdit.current.key);
        handleColumnReorder(previousIndex, input.current);
      }
    }

    input.current = null;
    clickHandler.current = null;
    setIsDialogHidden(true);
  };
  // End keyboard column controls to be removed on update to fluent 9

  return (
    <>
      <Stack>
        <LoadingErrorMessage loading={false} error={error} />
        {!loading && !error && data && data.length === 0 && (
          <MessageBar messageBarType={MessageBarType.warning}>
            <span>There are currently no items in this list. Please add an item and refresh. </span>
          </MessageBar>
        )}
      </Stack>
      {!hideSelectedCount && selectionDetails && <>{selectionDetails}</>}

      {commandBarItems && !selectionRef?.current?.getSelectedCount() && (
        <CommandBar
          items={commandBarItems}
          ariaLabel="Task Actions"
          primaryGroupAriaLabel="Task actions"
          farItemsGroupAriaLabel="Task actions"
          styles={commandBarStyles}
          overflowButtonProps={{ ariaLabel: 'More actions' }}
          farItems={farItems}
        />
      )}
      {onSearchBoxChange && (
        <SearchBox
          defaultValue={initialSearchItem}
          disableAnimation
          onChange={onSearchBoxChange}
          styles={searchBoxStyles}
          placeholder="Search"
          aria-label="Search"
        />
      )}
      {GeneralFilterTagHandler && GeneralFilterTagHandler()}

      <Stack styles={listWrapperStyles || defaultListContainerStyles}>
        <ScrollablePane
          styles={resetScrollablePanelStyles}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          <ShimmeredDetailsList
            componentRef={detailsListRef}
            enableShimmer={loading}
            shimmerLines={5}
            detailsListStyles={
              lockActionsColumn ? lockedStyledDetailsList : styledDetailsListCompact
            }
            columns={sortedColumns}
            columnReorderOptions={setListColumns ? getColumnReorderOptions() : undefined}
            layoutMode={layoutMode}
            items={data || []}
            groups={groups}
            selection={selectionRef.current}
            selectionMode={disableSelect ? SelectionMode.none : SelectionMode.multiple}
            checkButtonAriaLabel="checkbox"
            ariaLabelForSelectAllCheckbox="select all checkbox"
            onRenderRow={onRenderRow}
            selectionPreservedOnEmptyClick
            constrainMode={ConstrainMode.unconstrained}
            isHeaderVisible={isHeaderVisible}
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              const headerPropsOverride = { ...headerProps };
              headerPropsOverride.styles = { root: { paddingTop: 0 } };
              return (
                <Sticky stickyPosition={StickyPositionType.Header}>
                  {defaultRender(headerPropsOverride)}
                </Sticky>
              );
            }}
            onColumnHeaderClick={(ev, col) => {
              if (setListColumns) setContextualMenuProps(getContextualMenuProps(ev, col));
            }}
            onRenderCheckbox={onRenderCheckbox}
            checkboxVisibility={CheckboxVisibility.always}
          />
          {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
        </ScrollablePane>
      </Stack>
      <Dialog
        hidden={isDialogHidden}
        onDismiss={() => setIsDialogHidden(true)}
        dialogContentProps={
          clickHandler.current === RESIZE ? resizeDialogContentProps : reorderDialogContentProps
        }
        modalProps={modalProps}
      >
        <TextField
          componentRef={textfieldRef}
          ariaLabel={clickHandler.current === RESIZE ? 'Enter column width' : 'Enter column index '}
        />
        <DialogFooter>
          <PrimaryButton onClick={confirmDialog} text={clickHandler.current} />
          <DefaultButton onClick={() => setIsDialogHidden(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default GeneralEntityList;
