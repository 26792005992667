import React from 'react';
import { NormalPeoplePicker, IPersonaProps, Label, IBasePickerStyles } from '@fluentui/react';
import {
  suggestionProps,
  mapCompanyGroupsStatUserToStatPersonas,
} from '../../../utils/PersonaHelpers';
import ICompanyGroupStatUsers from '../../../utils/types/ICompanyGroupsStatUsers';
import IPersonaWithGUID from '../../../utils/types/IPersonaWithGUIDs';
import searchForUsers from '../../../utils/microsoftGraphApi/userSearch';

interface ICompanyGroupUsersPickerProps {
  onInputFieldUpdate?: (inputFieldPersonas: IPersonaWithGUID[]) => void;
  isDisabled?: boolean;
  selectedItems: ICompanyGroupStatUsers[];
  ariaLabelId: string;
}

const CompanyGroupUsersPicker = (props: ICompanyGroupUsersPickerProps): JSX.Element => {
  const { onInputFieldUpdate, isDisabled, selectedItems, ariaLabelId } = props;
  const [currentSelectedItems, setCurrentSelectedItems] = React.useState([]);

  React.useEffect(() => {
    if (selectedItems) {
      setCurrentSelectedItems(mapCompanyGroupsStatUserToStatPersonas(selectedItems));
    }
  }, [selectedItems]);

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  const pickerStyles: IBasePickerStyles = {
    root: {},
    input: {},
    text: {},
    screenReaderText: {},
    itemsWrapper: {},
  };

  return (
    <>
      <Label htmlFor="peoplePicker">Admin Users</Label>
      <NormalPeoplePicker
        onResolveSuggestions={onResolveSuggestions}
        onEmptyResolveSuggestions={(): [] => []}
        removeButtonAriaLabel="Remove Admin User"
        className="ms-PeoplePicker"
        key="normal"
        disabled={isDisabled}
        styles={
          isDisabled
            ? pickerStyles
            : {
                root: {
                  marginTop: '0',
                },
              }
        }
        onChange={(items: IPersonaWithGUID[]): void => {
          setCurrentSelectedItems(items);
          onInputFieldUpdate(items);
        }}
        selectedItems={currentSelectedItems}
        pickerSuggestionsProps={suggestionProps}
        inputProps={{
          id: ariaLabelId,
          'aria-label': 'Search Users',
          placeholder: isDisabled ? '' : 'Search Users',
        }}
        resolveDelay={200}
      />
    </>
  );
};

export default CompanyGroupUsersPicker;
