import { Stack } from '@fluentui/react';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import CompanyCodeInput from './CompanyCodeInput';
import { ICompanyCode } from './details/types';
import { FinancialType, ICompanyCreationRequestDetails } from './types';

interface ICompanyCodeFormProps {
  form: UseFormMethods<ICompanyCode>;
  companyCreationRequestData?: ICompanyCreationRequestDetails;
}

const CompanyCodeForm = ({
  form,
  companyCreationRequestData,
}: ICompanyCodeFormProps): JSX.Element => {
  const codeLength =
    companyCreationRequestData.financialType.id === FinancialType.NonTransactional ? 7 : 4;

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <CompanyCodeInput
        defaultValue={companyCreationRequestData?.companyCode}
        form={form}
        codeLength={codeLength}
        fieldName="companyCode"
        label="Company Code"
      />
    </Stack>
  );
};

export default CompanyCodeForm;
