import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import ActionButtons from '../../../common/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import CanAccess from '../../../common/canAccess/CanAccess';
import ReasonForFilingViolationForm from './ReasonForFilingViolationForm';
import { IFinancialStatementProcess } from '../Interfaces';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import { EDIT_PROCESS_REASON_FOR_FILING_VIOLATION } from '../../../../utils/statApi/ProcessApi';
import { IProcessIdParams } from '../../../common/ParamTypes';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';

interface IReasonForFilingViolationEditPanelProps {
  processDetail: IFinancialStatementProcess;
  closePanel: () => void;
}
export interface IReasonForFilingViolation {
  reasonForFilingViolation: IDropdownReference;
  reasonForFilingViolationComments: string;
}
const ReasonForFilingViolationEditPanel: React.FunctionComponent<
  IReasonForFilingViolationEditPanelProps
> = ({ closePanel, processDetail }: IReasonForFilingViolationEditPanelProps): JSX.Element => {
  const form = useForm<IFinancialStatementProcess>({
    defaultValues: {
      ...processDetail,
    },
  });
  const { processId } = useParams<IProcessIdParams>();
  const { handleSubmit } = form;
  const [updateReasonForFilingViolation, { loading: mutationLoading, error: mutationError }] =
    useMutation(EDIT_PROCESS_REASON_FOR_FILING_VIOLATION, {
      onCompleted: closePanel,
    });

  const handleOnSaveClick = (formData: IFinancialStatementProcess): void => {
    // GraphQL types don't like the typename property
    // reselect the formdata to remove __typename
    const reasonForFilingViolationSaveData = {
      reasonForFilingViolation: {
        id: formData?.reasonForFilingViolation?.id,
        fieldName: formData?.reasonForFilingViolation?.fieldName,
      },
      reasonForFilingViolationComments: formData?.reasonForFilingViolationComments,
    };
    updateReasonForFilingViolation({
      variables: {
        processReasonForFilingViolation: {
          ...reasonForFilingViolationSaveData,
          processId: parseInt(processId, 10),
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Reason For Filing Violation"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Reason For Filing Violation Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="ReasonForFilingViolation:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveTitle="Save Reason For Filing Violation"
            saveLabel="Save Reason For Filing Violation"
            cancelTitle="Cancel Reason For Filing Violation Edit"
            cancelLabel="Cancel Reason For Filing Violation Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the Reason For Filing Violation to the server"
        />
        <ReasonForFilingViolationForm form={form} processDetail={processDetail} />
      </form>
    </CoherencePanel>
  );
};
export default ReasonForFilingViolationEditPanel;
