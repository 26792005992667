import { gql } from '@apollo/client';

const GET_AUDITING_FIRMS_QUERY = gql`
  {
    auditingFirms {
      id
      fieldName
    }
  }
`;
export default GET_AUDITING_FIRMS_QUERY;
