import { gql } from '@apollo/client';

const GET_FINANCIAL_STATEMENT_TYPE = gql`
  {
    financialStatementTypes {
      id
      fieldName
    }
  }
`;

export default GET_FINANCIAL_STATEMENT_TYPE;
