import React, { useState } from 'react';
import { PrimaryButton, SearchBox, Announced, Toggle, Stack, IStackTokens } from '@fluentui/react';
import { bodyContentContainer, searchBoxStyles } from '../../app/common/styles/CommonStyleObjects';
import { StatUser, GetUsersWithPaginationQuery } from '../../utils/statApi/UsersApi';
import PageHeaderWithActionButtons from '../common/headers/PageHeaderWithActionButtons';
import Subheader from '../common/headers/Subheader';
import UsersList from './UsersList';
import UserDetailsPanel from './UserDetailsPanel';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import CanAccess from '../common/canAccess/CanAccess';
import WrappedListPage, {
  IPaginatedListPageProps,
} from '../common/DetailsLists/pagination/PaginatedListPage';

interface IUserListPageState {
  isInactive: boolean;
  isInvalid: boolean;
}

const UsersListPage = (): JSX.Element => {
  const [isInputError, setIsInputError] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [statUsers, setStatUsers] = useState<Array<StatUser>>([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterState, setFilterState] = useState<IUserListPageState>({
    isInactive: false,
    isInvalid: false,
  });

  const isValidSearchTerm = (value: string): boolean => value && value.length > 2;

  const onUserAddClick = (): void => {
    setSelectedUser(null);
    setIsPanelOpen(true);
  };

  const onUserEditClick = (item: StatUser): void => {
    setSelectedUser(item);
    setIsPanelOpen(true);
  };

  const onPanelDismiss = (): void => {
    setSelectedUser(null);
    setIsPanelOpen(false);
  };

  const handleOnChangeSearch = (
    event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string,
  ): void => {
    setIsInputError(false);
    const searchText = newValue ? newValue.trim() : '';

    if (isValidSearchTerm(searchText)) {
      setSearchTerm(searchText);
    } else if (searchText.length === 0) {
      setSearchTerm(null);
    }
  };

  const handleOnSearchExecuted = (newValue: string): void => {
    if (!isValidSearchTerm(newValue)) setIsInputError(true);
  };

  const renderHeaderButtons = (): JSX.Element => {
    return (
      <CanAccess requestedAction="GlobalUser:Add">
        <div data-testid="add-user">
          <PrimaryButton
            data-testid="add-user-button"
            text="Add User"
            onClick={onUserAddClick}
            allowDisabledFocus
          />
        </div>
      </CanAccess>
    );
  };

  const renderHeader = (): JSX.Element => (
    <PageHeaderWithActionButtons title="Security" actionButtons={renderHeaderButtons()} />
  );

  const handleIsInactiveToggleChange = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean,
  ) => {
    setFilterState({
      isInactive: value,
      isInvalid: filterState.isInvalid,
    });
  };

  const handleIsInvalidToggleChange = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean,
  ) => {
    setFilterState({
      isInactive: filterState.isInactive,
      isInvalid: value,
    });
  };

  const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
  };

  const renderSubtitle = (): JSX.Element => (
    <div data-testid="users-list">
      <Subheader title="User Management">
        <Stack horizontal tokens={horizontalGapStackTokens}>
          <Toggle
            label="Show Deactivated Users"
            inlineLabel
            onChange={handleIsInactiveToggleChange}
          />
          <Toggle label="Show Invalid Users" inlineLabel onChange={handleIsInvalidToggleChange} />
          <SearchBox
            ariaLabel="search users"
            placeholder="Search users"
            onChange={handleOnChangeSearch}
            styles={searchBoxStyles}
            onSearch={handleOnSearchExecuted}
          />
        </Stack>
      </Subheader>
      {searchTerm && (
        <Announced
          message={`${statUsers?.length} user${statUsers?.length === 1 ? '' : 's'} found`}
        />
      )}
    </div>
  );

  const wrappedListPageParams: IPaginatedListPageProps<StatUser> = {
    dataMapperKey: 'statUsers',
    actionName: 'loading the user list data',
    query: GetUsersWithPaginationQuery,
    searchTerm,
    isInputError,
    setIsInputError,
    setData: setStatUsers,
    onRowClick: onUserEditClick,
    filters: filterState,
  };

  return (
    <>
      <FullWidthHeader title={renderHeader} subtitle={renderSubtitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        {WrappedListPage(UsersList, wrappedListPageParams)}
      </div>
      {/* Begin Add New User Panel */}
      {isPanelOpen && <UserDetailsPanel selectedUser={selectedUser} onDismiss={onPanelDismiss} />}
    </>
  );
};

export default UsersListPage;
