import React from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { Label, TagPicker, ITag, Stack } from '@fluentui/react';
import { GetCompaniesByFilter } from '../../../utils/statApi/CompaniesApi';
import InlineFormInputErrorMessage from '../../common/errorContent/InlineFormInputErrorMessage';
import { ICompanyBase } from '../../../utils/types/ICompany';
import { MessagingColors } from '../../../app/common/styles/CommonStyleObjects';

interface ICompanyPickerProps {
  selectedItems: ITag[];
  setSelectedItems: (companyGroupCompanies: ITag[]) => void;
  itemLimit?: number;
  required?: boolean;
  errorMessage?: string;
  label: string;
}

const CompanyPicker: React.FunctionComponent<ICompanyPickerProps> = ({
  selectedItems,
  setSelectedItems,
  itemLimit,
  required,
  errorMessage,
  label,
}: ICompanyPickerProps): JSX.Element => {
  // dont forget to change this
  const [loadCompanies] = useLazyQuery(GetCompaniesByFilter);

  const onResolveSuggestions = async (filterText: string): Promise<ITag[]> => {
    return loadCompanies({
      variables: {
        companySearchDto: {
          keyword: filterText,
        },
        paginationDto: {
          currentPage: 1,
          pageSize: 10,
        },
      },
    }).then((result: QueryResult<{ companies: { items: ICompanyBase[] } }, OperationVariables>) => {
      const companiesList: ICompanyBase[] = result?.data?.companies?.items;
      return companiesList?.map((node): ITag => {
        return {
          key: node.id.toString(),
          name: `${node.companyCode} - ${node.entityLegalName}`,
        };
      });
    });
  };

  const getTextFromItem = (item: ITag): string => {
    return item.name;
  };

  const onItemSelected = (item?: ITag): ITag | PromiseLike<ITag> => {
    const companyGroupCompany: ITag = {
      key: item.key,
      name: item.name,
    };

    return companyGroupCompany;
  };

  const addSelectedItem = (items: ITag[]): void => {
    setSelectedItems(items);
  };

  const pickerStyle = errorMessage
    ? { text: { borderColor: MessagingColors.errorBlockIcon }, root: { marginTop: '0' } }
    : { root: { marginTop: '0' } };

  return (
    <Stack>
      <Label htmlFor="companyPicker" required={required}>
        {label}
      </Label>
      <TagPicker
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Company"
        pickerSuggestionsProps={{
          noResultsFoundText: 'No results found',
        }}
        inputProps={{
          id: 'companyPicker',
          'aria-label': 'Search Companies',
          placeholder: 'Search Companies',
        }}
        itemLimit={itemLimit}
        resolveDelay={300}
        selectedItems={selectedItems}
        onChange={addSelectedItem}
        onItemSelected={onItemSelected}
        getTextFromItem={getTextFromItem}
        onEmptyResolveSuggestions={(): ITag[] => {
          return [];
        }}
        styles={pickerStyle}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </Stack>
  );
};

export default CompanyPicker;
