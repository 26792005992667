import React, { useState } from 'react';
import { CommandBarButton, Stack, IButtonStyles, Separator, NeutralColors } from '@fluentui/react';
import { IProcessTemplateMilestone } from '../../../../utils/types/IProcessTemplate';
import SpanWithLabel from '../../../common/SpanWithLabel';
import StackColumn from '../../../common/StackColumn';

interface ITemplateMilestoneContentProps {
  milestone: IProcessTemplateMilestone;
}
const buttonStyles: IButtonStyles = {
  root: {
    padding: 10,
    marginLeft: '-8px',
    backgroundColor: NeutralColors.gray20,
    borderColor: NeutralColors.gray100,
  },
  rootHovered: {
    background: NeutralColors.white,
  },
};
const ProcessTemplateMilestoneDrilldownInfo = ({
  milestone,
}: ITemplateMilestoneContentProps): JSX.Element => {
  const [viewButtonState, setViewButtonState] = useState({
    buttonText: 'View More',
    isExpanded: false,
    icon: { iconName: 'ChevronRight' },
  });

  const currentlyExpanded = viewButtonState.isExpanded;
  const toggleMilestoneDrilldownInfo = (): void => {
    if (currentlyExpanded) {
      setViewButtonState({
        buttonText: 'View More',
        isExpanded: false,
        icon: { iconName: 'ChevronRight' },
      });
    } else {
      setViewButtonState({
        buttonText: 'Show Less',
        isExpanded: true,
        icon: { iconName: 'ChevronDown' },
      });
    }
  };

  return (
    <Stack wrap styles={{ root: { paddingTop: '20px' } }} tokens={{ childrenGap: 5 }}>
      <Stack.Item styles={{ root: { paddingTop: '20px' } }}>
        <CommandBarButton
          aria-expanded={currentlyExpanded}
          iconProps={viewButtonState.icon}
          text={viewButtonState.buttonText}
          onClick={toggleMilestoneDrilldownInfo}
          styles={buttonStyles}
        />
      </Stack.Item>

      {viewButtonState.isExpanded && (
        <Stack tokens={{ childrenGap: 5 }}>
          <Separator />
          <StackColumn>
            <SpanWithLabel labelText="Description" value={milestone.description} />
            <SpanWithLabel labelText="Attestation Text" value={milestone.attestationText} />
          </StackColumn>
        </Stack>
      )}
    </Stack>
  );
};

export default ProcessTemplateMilestoneDrilldownInfo;
