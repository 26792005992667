import React, { CSSProperties } from 'react';
import { Stack, Persona, PersonaSize, PersonaPresence } from '@fluentui/react';
import { IServiceContact } from './interfaces';
import { labelStyles } from '../../../app/common/styles/CommonStyleObjects';

interface IServiceContactListProps {
  contacts: Array<IServiceContact>;
  labelText: string;
  horizontal?: boolean;
}
const serviceContactHeaderStyle = labelStyles.root as CSSProperties;

const ServiceContactList = ({
  contacts,
  labelText,
  horizontal,
}: IServiceContactListProps): JSX.Element => {
  if (contacts.length === 0) {
    return (
      <Stack>
        <h3 style={serviceContactHeaderStyle}>{labelText}</h3>
        <Persona text="" secondaryText="Not Assigned" size={PersonaSize.size40} />
      </Stack>
    );
  }

  const renderPersona = (person: IServiceContact): JSX.Element => {
    return (
      <Persona
        key={`${labelText}-${person.graphId}`}
        text={person.isInvalidUser ? `${person.text} (Invalid User)` : person.text}
        secondaryText={person.email}
        size={PersonaSize.size40}
        presence={person.isInvalidUser ? PersonaPresence.blocked : undefined}
      />
    );
  };

  return (
    <Stack>
      <h3 style={serviceContactHeaderStyle}>{labelText}</h3>
      <Stack horizontal={horizontal} wrap tokens={{ childrenGap: 3 }}>
        {contacts.map((person: IServiceContact) => {
          return renderPersona(person);
        })}
      </Stack>
    </Stack>
  );
};

export default ServiceContactList;
