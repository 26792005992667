import { useQuery } from '@apollo/client';
import { ActionButton, ColumnActionsMode, PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../../app/common/styles/CommonStyleObjects';
import {
  CREATE_MILESTONE_CATEGORY,
  GET_PROCESS_TYPE_CATEGORIES,
} from '../../../../utils/statApi/MilestoneTemplateApi';
import { IMilestoneCategory } from '../../../../utils/types/IProcessTemplate';
import BackButton from '../../../common/BackButton';
import ShimmeredDetailsListWrapper from '../../../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import BodyHeaderWithCommandBar from '../../../common/headers/BodyHeaderWithCommandBar';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import { IProcessTemplateTypeParams } from '../../../common/ParamTypes';
import MilestoneCategoriesAddEditPanel from './MilestoneCategoriesAddEditPanel';
import MilestoneCategoriesDeleteButton from './MilestoneCategoriesDeleteButton';
import MilestoneCategoriesEditButton from './MilestoneCategoriesEditButton';

const MilestoneCategoriesListPage = (): JSX.Element => {
  const { processTypeId } = useParams<IProcessTemplateTypeParams>();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { data, loading, error } = useQuery(GET_PROCESS_TYPE_CATEGORIES, {
    variables: {
      processTypeId: parseInt(processTypeId, 10),
    },
  });

  const createMutation = CREATE_MILESTONE_CATEGORY;

  const getHeaderTitle = (): JSX.Element => {
    return <h1>Template Management</h1>;
  };

  const getBackButton = (): JSX.Element => {
    return (
      <BackButton ariaLabel="Back to process types list" goToUrl="/process-templates">
        <h2>Milestone Categories</h2>
      </BackButton>
    );
  };

  const togglePanel = (): void => {
    setIsPanelOpen(!isPanelOpen);
  };

  const columns = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 300,
      isResizable: true,
      maxWidth: 400,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'actions',
      name: 'Actions',
      minWidth: 200,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IMilestoneCategory) => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
            <MilestoneCategoriesEditButton item={item} />
            <div>{' | '}</div>
            <MilestoneCategoriesDeleteButton id={item.id} />
          </Stack>
        );
      },
    },
  ];

  const getBodyHeaderTitle = (): JSX.Element => {
    return <h2>Categories</h2>;
  };

  return (
    <>
      <FullWidthHeader title={getHeaderTitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBackButton} />
        </div>
      </div>
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
            <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={togglePanel}>
              Add Category
            </ActionButton>
          </BodyHeaderWithCommandBar>
          <LoadingErrorMessage
            loading={loading}
            error={error}
            actionName="loading Milestone Categories"
          />
          {data?.milestoneCategories?.length === 0 && !loading && !error && (
            <div className={bodyContentWrapper}>
              <p>
                There are no milestone categories for the current process type. Select the Add
                Category button to create a new one.
              </p>
              <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                text=" Add New Category"
                onClick={togglePanel}
              />
            </div>
          )}
          <ShimmeredDetailsListWrapper
            pluralListLabel="Categories"
            singularListLabel="Category"
            items={data?.milestoneCategories || []}
            enableShimmer={loading}
            columns={columns}
          />
        </div>
      </div>
      {isPanelOpen && (
        <MilestoneCategoriesAddEditPanel closePanel={togglePanel} mutation={createMutation} />
      )}
    </>
  );
};

export default MilestoneCategoriesListPage;
