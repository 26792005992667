import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { ScrollablePane, mergeStyleSets } from '@fluentui/react';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import ActionButtons from '../../../common/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import {
  IAgmTrackerItemInput,
  IAgmTrackerPageTrackerSearchResult,
} from '../../annualFilingRequirements/IAgmListPageSearchResult';
import AgmTrackerItemEditForm from './AgmTrackerItemEditForm';
import {
  GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY,
  UPDATE_AGM_TRACKER_ITEM,
  UPDATE_AGM_TRACKER_ITEM_BULK,
} from '../../../../utils/statApi/CompanyFiscalYearsApi';
import AgmTrackerItemHistory from './AgmTrackerItemHistory';

interface IAgmTrackerItemEditPanelProps {
  closePanel: () => void;
  refetchTrackerList: () => void;
  agmTrackerItem?: IAgmTrackerPageTrackerSearchResult;
  bulkAgmTrackerItems?: IAgmTrackerPageTrackerSearchResult[];
  ownerFilter: string;
}

const styles = mergeStyleSets({
  scrollablePaneContainer: {
    height: '75vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  form: {
    paddingRight: '20px',
  },
});
const AgmTrackerItemEditPanel = ({
  closePanel,
  refetchTrackerList,
  agmTrackerItem,
  bulkAgmTrackerItems,
  ownerFilter,
}: IAgmTrackerItemEditPanelProps): JSX.Element => {
  const form = useForm<IAgmTrackerItemInput>();
  const { handleSubmit } = form;
  const [updateAgmTrackerItem, { loading, error }] = useMutation(UPDATE_AGM_TRACKER_ITEM, {
    onCompleted: () => {
      refetchTrackerList();
      closePanel();
    },
    refetchQueries: [
      {
        query: GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY,
        variables: {
          owner: ownerFilter,
        },
      },
    ],
  });

  const [updateBulkAgmTrackerItems, { loading: bulkLoading, error: bulkError }] = useMutation(
    UPDATE_AGM_TRACKER_ITEM_BULK,
    {
      onCompleted: () => {
        refetchTrackerList();
        closePanel();
      },
      refetchQueries: [
        {
          query: GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY,
          variables: {
            owner: ownerFilter,
          },
        },
      ],
    },
  );

  const isBulkEdit = bulkAgmTrackerItems?.length > 1;

  const handleSaveClick = (formData: IAgmTrackerItemInput): void => {
    if (!isBulkEdit) {
      updateAgmTrackerItem({
        variables: {
          input: {
            trackerId: agmTrackerItem.trackerId,
            celaAssignees:
              formData.celaAssignees?.map((contact) => {
                return {
                  id: contact?.id ? parseInt(contact.id, 10) : null,
                  graphId: contact.graphId,
                  name: contact.text,
                };
              }) ?? null,
            status: formData.status?.fieldName ?? null,
            statusChangeComment: formData.reasonForChange ?? null,
            comment: formData.comment ?? null,
            owner: formData.owner?.text ?? null,
            ownerChangeComment: formData.reasonForChange ?? null,
            agmDate: formData.agmDate ?? null,
          },
        },
      });
    } else {
      updateBulkAgmTrackerItems({
        variables: {
          input: {
            trackerIds: bulkAgmTrackerItems?.map((tracker) => tracker.trackerId),
            celaAssignees:
              formData.celaAssignees?.map((contact) => {
                return {
                  id: contact?.id ? parseInt(contact.id, 10) : null,
                  graphId: contact.graphId,
                  name: contact.text,
                };
              }) ?? null,
            status: formData.status?.fieldName ?? null,
            statusChangeComment: formData.reasonForChange ?? null,
            comment: formData.comment ?? null,
            owner: formData.owner?.text ?? null,
            ownerChangeComment: formData.reasonForChange ?? null,
            agmDate: formData.agmDate ?? null,
          },
        },
      });
    }
  };
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={isBulkEdit ? 'Bulk Edit AGM Tracker Items' : 'Edit AGM Tracker Item'}
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close AGM Tracker Item Edit"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading || bulkLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel={isBulkEdit ? 'Save AGM Tracker Items' : 'Save AGM Tracker Item'}
          saveTitle={isBulkEdit ? 'Save AGM Tracker Items' : 'Save AGM Tracker Item'}
          cancelLabel="Cancel AGM Tracker Item Edit"
          cancelTitle="Cancel AGM Tracker Item Edit"
        />
      )}
    >
      <div className={styles.scrollablePaneContainer}>
        <ScrollablePane>
          <form className={styles.form}>
            <LoadingErrorMessage error={error || bulkError} loading={loading || bulkLoading} />
            <AgmTrackerItemEditForm
              agmTrackerItem={agmTrackerItem}
              bulkAgmTrackerItems={bulkAgmTrackerItems}
              form={form}
              currentOwnerFilter={ownerFilter}
            />
            {!isBulkEdit && (
              <AgmTrackerItemHistory actionItems={agmTrackerItem?.agmTrackerActions} />
            )}
          </form>
        </ScrollablePane>
      </div>
    </CoherencePanel>
  );
};
export default AgmTrackerItemEditPanel;
