import { ApolloError, useQuery } from '@apollo/client';
import { DetailsListLayoutMode, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import momentUtc from '../../../../utils/DateFormatter';
import StatDateFormats from '../../../../utils/types/DateFormats';
import Card from '../../../common/card/Card';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import SpanWithLabel from '../../../common/SpanWithLabel';
import StackColumn from '../../../common/StackColumn';
import IProcessConfig, { IProcessConfigPreview } from '../../../../utils/types/IProcessConfig';
import ShimmeredDetailsListWrapper from '../../../common/DetailsLists/ShimmeredDetailsListWrapper';
import { GET_PROCESS_PREVIEW } from '../../../../utils/statApi/ProcessConfigApi';
import { addDurationToMilestones } from '../../../../utils/MilestoneHelpers';
import { previewMilestoneListColumns } from './CreateProcessConfigUtils';
import { ICompanyCodeParams, IProcessConfigIdParams } from '../../../common/ParamTypes';

interface ICreateProcessConfigReviewFormProps {
  mutationLoading: boolean;
  mutationError: ApolloError;
  processConfig: IProcessConfig;
  originalConfigDuration: number;
  showUpdateWarningMessage: boolean;
  processCountLabel: string;
  readyMessage: string;
}

const CreateProcessConfigReviewForm = ({
  mutationLoading,
  mutationError,
  processConfig,
  originalConfigDuration,
  showUpdateWarningMessage,
  processCountLabel,
  readyMessage,
}: ICreateProcessConfigReviewFormProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { processConfigId } = useParams<IProcessConfigIdParams>();
  const {
    loading: loadingProcessPreview,
    data: dataProcessPreview,
    error: errorProcessPreview,
  } = useQuery(GET_PROCESS_PREVIEW, {
    variables: {
      previewProcessConfigDto: {
        id: parseInt(processConfigId, 10),
        processTemplateId: processConfig.processTemplate?.id,
        initialFilingDueDate: processConfig.filingDueDate,
        initialReportingPeriodEndDate: processConfig.reportingPeriodEndDate,
        recurrenceEndDate: processConfig.recurrenceEndDate,
        recurrence: processConfig.recurrence,
        useLastDayOfMonth: processConfig.useLastDayOfMonth,
        milestones: processConfig.milestones.map((milestone) => {
          return {
            id: milestone.id,
            assigneeId: milestone.assigneeId,
            defaultDaysOffset: milestone.defaultDaysOffset,
          };
        }),
      },
      companyCode,
    },
  });

  const messageBarStyle = { root: { marginBottom: '5px' } };

  const configDuration = Math.abs(processConfig.milestones[0]?.defaultDaysOffset);
  const milestonesWithDurations = addDurationToMilestones(processConfig.milestones);
  const warningThreshold = 10;

  const { previewMilestones, processCount }: IProcessConfigPreview =
    dataProcessPreview?.processPreview || {
      previewMilestones: [],
      processCount: 0,
    };

  const milestonesWithDueDates = milestonesWithDurations.map((milestone, currentIndex) => {
    const newMilestone = { ...milestone };
    newMilestone.dueDate = previewMilestones[currentIndex]?.dueDate;
    return newMilestone;
  });

  return (
    <>
      <LoadingErrorMessage error={mutationError} loading={mutationLoading} />
      <LoadingErrorMessage
        loading={loadingProcessPreview}
        error={errorProcessPreview}
        actionName="loading the process preview"
      />
      <Stack>
        <MessageBar styles={messageBarStyle}>
          {`Your process configuration is ready ${readyMessage}. Please review the following details and click save.`}
        </MessageBar>
        {configDuration < originalConfigDuration && (
          <MessageBar messageBarType={MessageBarType.warning} isMultiline styles={messageBarStyle}>
            {`This configuration is ${configDuration} business days long, which is less than the default of ${originalConfigDuration} business days.`}
            <br />
            Confirm the durations for each milestone before proceeding.
          </MessageBar>
        )}
        {processCount >= warningThreshold && (
          <MessageBar messageBarType={MessageBarType.warning} styles={messageBarStyle}>
            {`Saving this configuration will generate ${processCount} processes. Confirm this process should recur ${processConfig.recurrence}.`}
          </MessageBar>
        )}
        {showUpdateWarningMessage && processCount > 0 && (
          <MessageBar messageBarType={MessageBarType.warning} styles={messageBarStyle}>
            Saving this configuration will modify all existing future processes, overwriting any due
            date changes made to those individual processes.
          </MessageBar>
        )}
        <Card title="Process Details">
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText={processCountLabel} value={processCount?.toString()} />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card title="Configuration Details">
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Name" value={processConfig.name} />
              <SpanWithLabel
                labelText="Process Type"
                value={processConfig.processType?.fieldName}
              />
              <SpanWithLabel
                labelText="Template Name"
                value={processConfig.processTemplate?.fieldName}
              />
              <SpanWithLabel
                labelText="Reporting Period End Date"
                value={moment(processConfig.reportingPeriodEndDate)
                  .utc(false)
                  .format(StatDateFormats.DayBreakout)}
              />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Recurrence" value={processConfig.recurrence} />
              <SpanWithLabel
                labelText="Recurrence End Date"
                value={momentUtc(
                  processConfig.recurrenceEndDate,
                  StatDateFormats.DayBreakout,
                  true,
                )}
              />
              <SpanWithLabel
                labelText="Use Last Day of the Month"
                value={processConfig.useLastDayOfMonth}
              />
              <SpanWithLabel
                labelText="Filing Due Date"
                value={moment(processConfig.filingDueDate)
                  .utc(false)
                  .format(StatDateFormats.DayBreakout)}
              />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card title="Milestone Preview">
          <Stack>
            <ShimmeredDetailsListWrapper
              items={milestonesWithDueDates}
              layoutMode={DetailsListLayoutMode.justified}
              columns={previewMilestoneListColumns}
              enableShimmer={loadingProcessPreview}
              pluralListLabel="Milestone Configurations"
              singularListLabel="Milestone Configuration"
            />
          </Stack>
        </Card>
      </Stack>
    </>
  );
};
export default CreateProcessConfigReviewForm;
