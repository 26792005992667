import React from 'react';
import { ShimmerElementsGroup, ShimmerElementType, IShimmerElement } from '@fluentui/react';

const ServiceContactsShimmer = (): JSX.Element => {
  const baseRow = [
    { type: ShimmerElementType.line, width: '45%', height: 20 },
    { type: ShimmerElementType.gap, width: '10%', height: 20 },
    { type: ShimmerElementType.line, width: '45%', height: 20 },
    { type: ShimmerElementType.gap, width: '100%', height: 20 },
  ];

  let allRows: Array<IShimmerElement> = [];

  for (let index = 0; index < 10; index += 1) {
    allRows = allRows.concat(baseRow);
  }

  return (
    <div style={{ display: 'flex' }}>
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={allRows} />
    </div>
  );
};

export default ServiceContactsShimmer;
