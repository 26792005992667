import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_COMPANY_FINANCIAL_INFO } from '../../../utils/statApi/CompaniesApi';
import ICompanyFinancialInfo from '../../../utils/types/ICompanyFinancialInfo';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CompanyFinancialInfoForm from './forms/CompanyFinancialInfoForm';

interface ICompanyFinancialInfoPanelProps {
  closePanel: () => void;
  data: ICompanyFinancialInfo;
}

const CompanyFinancialInfoPanel = ({
  closePanel,
  data,
}: ICompanyFinancialInfoPanelProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const form = useForm<FieldValues>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateCompanyFinancialInfo, { loading, error }] = useMutation(
    UPDATE_COMPANY_FINANCIAL_INFO,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: ICompanyFinancialInfo): void => {
    const salesLocationForReporting = formData.salesLocationForReporting?.id
      ? {
          id: formData.salesLocationForReporting?.id,
          name: formData.salesLocationForReporting?.name,
        }
      : null;

    updateCompanyFinancialInfo({
      variables: {
        companyCode,
        input: {
          id: data.id,
          statutoryYearEndDay: formData.statutoryYearEndDay,
          statutoryYearEndMonth: formData.statutoryYearEndMonth,
          statutoryCurrency: formData.statutoryCurrency,
          functionalCurrency: formData.functionalCurrency,
          salesLocationForReporting,
          taxJurisdictionCode: formData.taxJurisdictionCode,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Financial Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Financial Info"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Financial Info"
          saveTitle="Save Financial Info"
          cancelLabel="Cancel Financial Info"
          cancelTitle="Cancel Financial Info"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <CompanyFinancialInfoForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyFinancialInfoPanel;
