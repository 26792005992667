import { IColumn, ColumnActionsMode } from '@fluentui/react';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';

interface ICompaniesListColumnParams {
  renderCompanyCodeLink: (item: { companyCode: React.ReactNode }) => JSX.Element;
  renderStatutoryYearEnd: (item: {
    statutoryYearEndMonth: number;
    statutoryYearEndDay: number;
  }) => JSX.Element;
}

const getColumns = ({
  renderCompanyCodeLink,
  renderStatutoryYearEnd,
}: ICompaniesListColumnParams): Array<IColumn> => {
  return [
    {
      key: 'companyCode',
      name: 'Company Code',
      fieldName: 'companyCode',
      minWidth: 100,
      maxWidth: 150,
      className: rowClasses.medFont,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: renderCompanyCodeLink,
    },
    {
      key: 'name',
      name: 'Full Name',
      fieldName: 'entityLegalName',
      minWidth: 100,
      maxWidth: 400,
      isRowHeader: true,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'sapName',
      name: 'SAP Name',
      fieldName: 'sapName',
      minWidth: 100,
      maxWidth: 165,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'physicalCountry',
      name: 'Country',
      fieldName: 'physicalCountry',
      minWidth: 100,
      maxWidth: 165,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'area',
      name: 'Area',
      fieldName: 'area',
      minWidth: 100,
      maxWidth: 165,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'entityStatus',
      name: 'Entity Status',
      fieldName: 'entityStatus',
      minWidth: 100,
      maxWidth: 165,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'statutoryYearEnd',
      name: 'Statutory Year End',
      onRender: renderStatutoryYearEnd,
      minWidth: 100,
      maxWidth: 135,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'statutoryController',
      name: 'Statutory Controller',
      fieldName: 'statutoryController',
      minWidth: 100,
      maxWidth: 165,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export default getColumns;
