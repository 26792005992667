import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { IDropdownOption } from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  PROCESS_CONFIG_MILESTONES_BY_VERSION_QUERY,
  UPDATE_PROCESS_CONFIG_MILESTONE_MUTATION,
} from '../../../utils/statApi/ProcessConfigApi';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IProcessConfigIdParams } from '../../common/ParamTypes';
import MilestoneConfigEditForm from './MilestoneConfigEditForm';
import { IMilestoneConfigAssigneeOption } from './ProcessConfigTypes';

interface IMilestoneConfigEditPanelProps {
  closePanel: () => void;
  milestones: IMilestoneConfigAssigneeOption[];
  processTemplateVersionId: number;
}

const MilestoneConfigEditPanel = ({
  closePanel,
  milestones,
  processTemplateVersionId,
}: IMilestoneConfigEditPanelProps): JSX.Element => {
  const { processConfigId } = useParams<IProcessConfigIdParams>();
  const defaults = milestones
    .filter((milestone: IMilestoneConfigAssigneeOption) => milestone.assigneeOptions.length !== 0)
    .map((milestone: IMilestoneConfigAssigneeOption) => {
      const milestoneConfigEdit: IMilestoneConfigAssigneeOption = {
        id: milestone.id,
        assigneeId: milestone.assigneeId,
        assigneeOptions: [],
        milestoneTemplateName: '',
        signoffRoleIds: [],
        ordinal: milestone.ordinal,
        defaultDaysOffset: milestone.defaultDaysOffset,
        assignee: {
          assigneeSlot: milestone.assignee.assigneeSlot,
          assigneeName: milestone.assignee.assigneeName,
        },
      };
      return milestoneConfigEdit;
    });

  const [milestoneConfigEdits, setMilestoneConfigEdits] =
    useState<IMilestoneConfigAssigneeOption[]>(defaults);

  const [saveMilestoneConfigEdits, { loading, error }] = useMutation(
    UPDATE_PROCESS_CONFIG_MILESTONE_MUTATION,
    {
      onCompleted: (): void => {
        closePanel();
      },
      refetchQueries: [
        {
          query: PROCESS_CONFIG_MILESTONES_BY_VERSION_QUERY,
          variables: {
            processConfigId: parseInt(processConfigId, 10),
            processTemplateVersionId,
          },
        },
      ],
    },
  );

  const updateMilestoneConfigEdits = (
    newValue: IDropdownOption,
    milestoneConfigId: number,
  ): void => {
    const milestoneConfigEdit: IMilestoneConfigAssigneeOption = {
      id: milestoneConfigId,
      assigneeId: parseInt(newValue?.key?.toString(), 10),
      assigneeOptions: [],
      milestoneTemplateName: '',
      signoffRoleIds: [],
      ordinal: 0,
      defaultDaysOffset: 0,
      assignee: {
        assigneeSlot: '',
        assigneeName: '',
      },
    };

    const otherDueDates = milestoneConfigEdits.filter(
      (mdd: IMilestoneConfigAssigneeOption) => mdd.id !== milestoneConfigId,
    );
    setMilestoneConfigEdits([...otherDueDates, milestoneConfigEdit]);
  };
  const handleSubmit = (): void => {
    saveMilestoneConfigEdits({
      variables: {
        processConfigId: parseInt(processConfigId, 10),
        processConfigMilestoneEdits: milestoneConfigEdits,
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Milestone Assignees"
      isOpen
      hasCloseButton
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Edit Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit}
          saveTitle="Save Milestone Assignees"
          saveLabel="Save Milestone Assignees"
          cancelTitle="Cancel Milestone Assignees Edit"
          cancelLabel="Cancel Milestone Assignees Edit"
        />
      )}
    >
      <LoadingErrorMessage error={error} loading={loading} />
      <form onSubmit={handleSubmit}>
        <MilestoneConfigEditForm
          updateAssignee={updateMilestoneConfigEdits}
          milestones={milestones}
        />
      </form>
    </CoherencePanel>
  );
};

export default MilestoneConfigEditPanel;
