import React from 'react';
import {
  IColumn,
  getTheme,
  Stack,
  TooltipHost,
  TooltipDelay,
  NeutralColors,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import momentUtc from '../../utils/DateFormatter';
import StatDateFormats from '../../utils/types/DateFormats';
import { IMilestoneStatus, IProcessSearchResult } from './processSearch/IProcessSearchResult';
import MilestoneDetailsWrapper from './MilestoneDetailsWrapper';
import { MessagingColors } from '../../app/common/styles/CommonStyleObjects';

const theme = getTheme();

const onRenderFilingDueDate = (item: IProcessSearchResult): JSX.Element => {
  return <span>{`${momentUtc(item.filingDueDate, StatDateFormats.DayBreakout)}`}</span>;
};

const onRenderReportingPeriodEndDate = (item: IProcessSearchResult): JSX.Element => {
  return <span>{`${momentUtc(item.reportingPeriodEndDate, StatDateFormats.DayBreakout)}`}</span>;
};

const onRenderCompanyCode = (item: IProcessSearchResult): JSX.Element => {
  return (
    <TooltipHost
      delay={TooltipDelay.medium}
      id={item.companyName}
      key={item.companyCode}
      tooltipProps={{
        onRenderContent: () => {
          return (
            <div>
              <strong>{item.companyName}</strong>
            </div>
          );
        },
      }}
    >
      <Link to={`/companies/${item.companyCode}/processes/${item.processTypeUri}/${item.id}`}>
        {item.companyCode}-{item.companyName}
      </Link>
      <br />
      <span>
        {item.countryName}
        {item.companyArea && <span> | Area: {item.companyArea}</span>}
      </span>
    </TooltipHost>
  );
};

const onRenderProcessTypeAndName = (item: IProcessSearchResult): JSX.Element => {
  return (
    <TooltipHost
      delay={TooltipDelay.medium}
      id={item.processName}
      key={item.processName}
      tooltipProps={{
        onRenderContent: () => {
          return (
            <div>
              <strong>{item.processName}</strong>
            </div>
          );
        },
      }}
    >
      <div style={{ marginBottom: 5, textOverflow: 'ellipsis', overflow: 'hidden' }}>
        <strong>Name: </strong> {item.processName}
      </div>
      <strong>Type: </strong>
      {item.processType}
    </TooltipHost>
  );
};

const onRenderMilestones = (item: IProcessSearchResult): JSX.Element => {
  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 3 }}>
        {item?.milestoneStatuses?.map((milestone: IMilestoneStatus): JSX.Element => {
          let color = NeutralColors.gray130;
          let iconName = 'Checkbox';
          if (milestone.status === 'COMPLETE') {
            color = MessagingColors.successIcon;
            iconName = 'BoxCheckmarkSolid';
          } else if (milestone.status === 'READY') {
            color = theme.palette.themePrimary;
            iconName = 'CheckboxFill';
          }

          return (
            <MilestoneDetailsWrapper
              key={milestone.milestoneId}
              id={milestone.milestoneId}
              status={milestone.status}
              iconName={iconName}
              color={color}
            />
          );
        })}
      </Stack>
      <br />
      {item.currentMilestoneTitle === 'Milestones Complete' && <span>Process Complete</span>}
      {item.currentMilestoneTitle !== 'Milestones Complete' && (
        <>
          <div>Current: {item.currentMilestoneTitle}</div>
          <span>
            Due Date: {`${momentUtc(item.currentMilestoneDueDate, StatDateFormats.DayBreakout)}`}
          </span>
        </>
      )}
    </>
  );
};

const getColumns = (): Array<IColumn> => {
  return [
    {
      key: 'companyCode',
      name: 'Company',
      minWidth: 300,
      maxWidth: 300,
      onRender: onRenderCompanyCode,
    },
    {
      key: 'process',
      name: 'Process',
      minWidth: 300,
      maxWidth: 400,
      onRender: onRenderProcessTypeAndName,
    },
    {
      key: 'reportingPeriodEndDate',
      name: 'Reporting Period End Date',
      fieldName: 'reportingPeriodEndDate',
      minWidth: 175,
      maxWidth: 175,
      onRender: onRenderReportingPeriodEndDate,
    },
    {
      key: 'filingDueDate',
      name: 'Filing Due Date',
      minWidth: 135,
      maxWidth: 135,
      onRender: onRenderFilingDueDate,
    },
    {
      key: 'milestoneStatuses',
      name: 'Milestones',
      minWidth: 300,
      onRender: onRenderMilestones,
    },
  ];
};

export default getColumns;
