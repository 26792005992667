import { FontSizes, IDocumentCardPreviewProps } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { MessagingColors } from '../../../../app/common/styles/CommonStyleObjects';

export const getIconName = (fileName: string): string => {
  const fileExtension = fileName.split('.').slice(-1)[0];
  switch (fileExtension) {
    case 'doc':
    case 'docx': {
      return 'WordLogo';
    }

    case 'pdf': {
      return 'PDF';
    }

    case 'xls':
    case 'xlsx': {
      return 'ExcelLogo';
    }

    case 'ppt':
    case 'pptx': {
      return 'PowerPointLogo';
    }

    case 'msg': {
      return 'OutlookLogo';
    }

    case 'jpg':
    case 'jpeg':
    case 'png': {
      return 'FileImage';
    }

    case 'txt': {
      return 'TextDocument';
    }

    default:
      return 'OpenFile';
  }
};

const previewPropsUsingIcon = (
  hasErrorMessages: boolean,
  fileName: string,
  forceIcon?: string,
): IDocumentCardPreviewProps => {
  const backgroundColor = hasErrorMessages
    ? MessagingColors.errorBlockIcon
    : CoherenceTheme.palette.neutralLighterAlt;
  const iconName = hasErrorMessages ? 'Warning' : forceIcon || getIconName(fileName);

  return {
    previewImages: [
      {
        previewIconProps: {
          iconName,
          styles: {
            root: {
              fontSize: FontSizes.size32,
              color: '#0078d7',
              backgroundColor: CoherenceTheme.palette.neutralLighterAlt,
            },
          },
        },
        width: 100,
      },
    ],
    styles: { previewIcon: { backgroundColor } },
  };
};

export default previewPropsUsingIcon;
