import moment from 'moment';
import { IDropdownOption, ITag } from '@fluentui/react';
import ICountry from './types/ICountry';
import { ISecRegionArea } from './types/ISecRegionArea';

export const doesTextInclude = (text: string, filterText: string): boolean => {
  return text.toLowerCase().includes(filterText.toLowerCase()) === true;
};
export const stripTrailingSlash = (str: string): string => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};
export const getParentUriSegment = (routeString: string): string => {
  const uriArray = stripTrailingSlash(routeString).split('/');
  uriArray.pop();
  const parentUri = uriArray.join('/');
  return stripTrailingSlash(parentUri);
};
export const addTimestampBeforeExtension = (fileName: string): string =>
  fileName.replace(/(\.[\w\d_-]+)$/i, `${new Date().getTime()}$1`);

export const convertBlobUrlToFilename = (url: string): string => {
  const splitString = url.split('/');
  const blobFilename = splitString[splitString.length - 1];

  return blobFilename;
};

export const monthDropdownOptions: IDropdownOption[] = moment
  .months()
  .map((item): IDropdownOption => {
    return {
      key: Number(moment().month(item).format('M')),
      text: item,
    };
  });

export const getDayDropdownOptions = (month: number): IDropdownOption[] => {
  if (!month) {
    return [];
  }
  const nonLeapYear = 2021;
  const daysInMonth = Array.from(
    { length: moment(new Date(nonLeapYear, month, 0)).daysInMonth() },
    (x, i) => i + 1,
  ).map((item): IDropdownOption => {
    return {
      key: item,
      text: item.toString(),
    };
  });

  return daysInMonth;
};

export const getUtcTodayWithNoTime = (): string => {
  return `${new Date().toISOString().split('T')[0]}T00:00:00.000Z`;
};

// Since the DatePicker component needs a Date object for min/max dates, and we want to maintain UTC dates,
// we need to pass in a date where the localized day is the UTC day
export const getLocalizedDateWithUtcDay = (utcDate: Date): Date => {
  const localizedDate = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
  );

  return localizedDate;
};
const isBusinessDay = (originalDate: Date) => {
  const defaultWorkingWeekdays = [1, 2, 3, 4, 5];
  if (defaultWorkingWeekdays.indexOf(originalDate.getUTCDay()) >= 0) return true;
  return false;
};
export const addBusinessDays = (originalDate: Date, days: number): Date => {
  const day = moment(getLocalizedDateWithUtcDay(originalDate)).utc(true);
  if (days < 0) {
    days = Math.round(-1 * days) * -1;
  } else {
    days = Math.round(days);
  }

  const signal = days < 0 ? -1 : 1;

  let remaining = Math.abs(days);
  while (remaining > 0) {
    day.add(signal, 'days');

    if (isBusinessDay(day.toDate())) {
      remaining -= 1;
    }
  }

  return day.utc(true).toDate();
};
export const convertCountryToTag = (country: ICountry): ITag => {
  return {
    key: country.id,
    name: `${country.shortName} (${country.id})`,
  };
};
export const convertSecRegionAreaToTag = (secRegionArea: ISecRegionArea): ITag => {
  return {
    key: secRegionArea.secAreaCode,
    name: `${secRegionArea?.secAreaName} (${secRegionArea?.secAreaCode})`,
  };
};
export const paperSizeDisplay = (size: string): string => {
  switch (size) {
    case 'NOTSPECIFIED':
      return 'Not Specified';
    case 'SIZE8X11':
      return '8 1/2 x 11';
    case 'A4':
      return 'A4';
    case 'OTHER':
      return 'Other';
    default:
      return size;
  }
};

export const convertFiscalYearToCalendarYear = (year: number | string, month: number): number => {
  if (month > 6) {
    return Number(year) - 1;
  }
  return Number(year);
};

// Converts from fiscal period (July = 1) to calendar month (July = 7)
export const convertFiscalPeriodToCalendarMonth = (period: number): number => {
  if (period <= 6) {
    return period + 6;
  }
  return period - 6;
};

export const toTitleCase = (text: string): string => {
  return text.replace(/(?!^)([A-Z])/g, ' $1');
};
