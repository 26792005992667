import { mergeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import useLocalStorage, { LocalStorageKeys } from '../../utils/hooks/UseLocalStorage';
import ComingSoonSvg from '../common/svgs/ComingSoonSvg';
import RecentProcessesSvg from '../common/svgs/RecentProcessesSvg';
import { IRecentProcess } from '../companies/processes/Interfaces';
import InsightCard from './InsightCard';
import ListItem from './ListItem';
import { MessagingColors } from '../../app/common/styles/CommonStyleObjects';

const listStyle = mergeStyles({
  paddingLeft: 0,
});

const blankStateStyles = mergeStyles({
  display: 'flex',
  alignSelf: 'center',
});

const blankStateCard = mergeStyles({
  height: 220,
  fontSize: '1rem',
});

const centerBlankText = mergeStyles({
  textAlign: 'center',
});

const RecentProcessesCard = (): JSX.Element => {
  const convertProcessTypeToUri = (processType: string): string => {
    return processType.toLowerCase().split(' ').join('-');
  };

  const [recentProcesses] = useLocalStorage<IRecentProcess[]>(LocalStorageKeys.recentProcesses, []);

  const getSubText = (process: IRecentProcess): JSX.Element => {
    return (
      <Link
        to={`/companies/${process.companyCode}/processes/${convertProcessTypeToUri(
          process.processType,
        )}/${process.id}`}
      >
        Go to process
      </Link>
    );
  };

  const getMainText = () => {
    return (
      <>
        {recentProcesses.length === 0 && (
          <Stack tokens={{ childrenGap: 20 }} className={blankStateCard}>
            <div className={blankStateStyles}>
              <span>Looks like you haven&apos;t visited any processes yet. </span>
            </div>
            <div className={blankStateStyles}>
              <ComingSoonSvg />
            </div>
            <div className={centerBlankText}>
              <span>
                Once you visit some processes, we&apos;ll save them here so you can access them
                easier in the future!
              </span>
            </div>
          </Stack>
        )}
        {recentProcesses.length > 0 && (
          <ul className={listStyle}>
            {recentProcesses.map((process) => {
              return (
                <ListItem
                  key={process.id}
                  title={`${process.companyCode} - ${process.name}`}
                  subText={getSubText(process)}
                  sideColor={MessagingColors.successIcon}
                />
              );
            })}
          </ul>
        )}
      </>
    );
  };

  return (
    <InsightCard
      caption="Recent Processes"
      mainText={getMainText()}
      svgComponent={recentProcesses.length > 0 ? <RecentProcessesSvg /> : null}
      footerLink={<Link to="/processes">Go to Processes</Link>}
    />
  );
};

export default RecentProcessesCard;
