import { ITag } from '@fluentui/react';
import React from 'react';

import Collapsible from './Collapsible';

import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import GenericChecklist from './GenericChecklist';
import { GetCompaniesBySearchInputQuery } from '../../../utils/statApi/CompaniesApi';

export interface IChecklistImplementationProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  initialValues?: IGenericFilterTag;
  disabledValues?: IGenericFilterTag;
  label: string;
  dataKey: string;
}

const CompanyCollapsibleChecklist = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;

  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({
      dataKey,
      title: label,
      values: items,
    });
  };

  const mapToTag = (item: { id: string; companyCode: string; entityLegalName: string }): ITag => {
    return {
      key: item.id,
      name: `${item.companyCode.toString()}- ${item.entityLegalName}`,
    };
  };

  const mapData = (data: {
    companiesByKeyword: { id: string; companyCode: string; entityLegalName: string }[];
  }): ITag[] => {
    return data?.companiesByKeyword?.map((companies) => {
      return mapToTag(companies);
    });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        mapData={mapData}
        query={GetCompaniesBySearchInputQuery}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};

export default CompanyCollapsibleChecklist;
