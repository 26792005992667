import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_COMPANY_BUSINESS_NATURE } from '../../../utils/statApi/CompaniesApi';
import { ICompanyBusinessNature } from '../../../utils/types/ICompanyBusinessNature';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CompanyBusinessNatureForm from './forms/CompanyBusinessNatureForm';

interface ICompanyBusinessNatureProps {
  closePanel: () => void;
  data: ICompanyBusinessNature;
}

const CompanyBusinessNature = ({ closePanel, data }: ICompanyBusinessNatureProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const form = useForm<FieldValues>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateCompanyLegalAddress, { loading, error }] = useMutation(
    UPDATE_COMPANY_BUSINESS_NATURE,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: ICompanyBusinessNature): void => {
    const acquisition = formData.isAcquisition
      ? {
          id: parseInt(formData.acquisition.key.toString(), 10),
          fieldName: formData.acquisition.text,
        }
      : null;
    updateCompanyLegalAddress({
      variables: {
        companyCode,
        input: {
          controllerMustBeFte: formData.controllerMustBeFte,
          businessNatureOfEntity: formData.businessNatureOfEntity,
          isAcquisition: formData.isAcquisition,
          entityTypeId: formData.entityType?.id,
          acquisition,
          dateOfAcquisition: formData.isAcquisition ? formData.dateOfAcquisition : null,
          entityStatusId: formData.entityStatus?.id,
          lifecycleStatusId: formData.lifecycleStatus?.id,
          effectiveDateOfStatusChange: formData.effectiveDateOfStatusChange,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Business Nature & Status"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Business Nature & Status Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Business Nature & Status"
          saveTitle="Save Business Nature & Status"
          cancelLabel="Cancel Business Nature & Status"
          cancelTitle="Cancel Business Nature & Status"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <CompanyBusinessNatureForm data={data} form={form} companyCode={companyCode} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyBusinessNature;
