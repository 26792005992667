import React from 'react';
import { mergeStyleSets } from '@fluentui/react';
import StepItem from './StepItem';
import { IDocument } from '../../../utils/statApi/DocumentApi';

export interface IRunStep {
  id: number;
  name: string;
  status: string;
  created: string;
}

export interface IRun {
  id: number;
  created: string;
  completed: string;
  document: IDocument;
  steps: IRunStep[];
}

interface IStepsContainerProps {
  run: IRun;
}

const stepsContainerStyle = mergeStyleSets({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const StepsContainer = (props: IStepsContainerProps): JSX.Element => {
  const { run } = props;

  return (
    <div className={stepsContainerStyle.container}>
      {run?.steps?.map((step: IRunStep) => (
        <StepItem step={step} />
      ))}
    </div>
  );
};

export default StepsContainer;
