import { gql } from '@apollo/client';

const PROCESS_CONFIG_LIST_QUERY = gql`
  query ProcessConfigList($companyCode: String!) {
    processConfigList(companyCode: $companyCode) {
      id
      processType
      processTemplateName
      processTemplateVersion
      recurrence
      recurrenceEndDate
      name
    }
  }
`;

export const GetProcessConfigsWithPaginationQuery = gql`
  query ProcessConfigList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String!
  ) {
    processConfigList(
      first: $first
      after: $after
      last: $last
      before: $before
      companyCode: $keyword
    ) {
      edges {
        node {
          id
          processType
          processTemplateName
          processTemplateVersion
          recurrence
          recurrenceEndDate
          name
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const PROCESS_CONFIG_FRAGMENT = gql`
  fragment ProcessConfig on ProcessConfigDetailDto {
    id
    name
    processType
    processTemplateName
    processTemplateVersion
    recurrence
    recurrenceEndDate
    useLastDayOfMonth
    initialReportingPeriodEndDate
    initialFilingDueDate
    milestones {
      id
      ordinal
      milestoneTemplateName
      minimumDuration
      maximumDuration
      assignee {
        assigneeSlot
        assigneeName
      }
      defaultDaysOffset
      maxDaysOffset
    }
    complianceRequirement {
      id
      name
    }
  }
`;

export const PROCESS_CONFIG_DETAIL_QUERY = gql`
  query ProcessConfigDetail($processConfigId: Int!) {
    processConfigDetail(processConfigId: $processConfigId) {
      ...ProcessConfig
      processTypeId
      processTemplateId
      nextReportingPeriodEndDate
      nextFilingDueDate
      previousReportingPeriodEndDate
      previousFilingDueDate
      associatedProcessTemplateVersions {
        id
        fieldName: versionLabel
      }
    }
  }
  ${PROCESS_CONFIG_FRAGMENT}
`;

export const PROCESS_CONFIG_MILESTONES_BY_VERSION_QUERY = gql`
  query ProcessConfigMilestonesByVersion($processConfigId: Int!, $processTemplateVersionId: Int) {
    processConfigMilestonesByVersion(
      processConfigId: $processConfigId
      processTemplateVersionId: $processTemplateVersionId
    ) {
      id
      milestoneTemplateName
      assigneeId
      signoffRoleIds
      ordinal
      assignee {
        assigneeSlot
        assigneeName
      }
      assigneeOptions {
        key: id
        text: name
      }
      defaultDaysOffset
    }
  }
`;

export const UPDATE_PROCESS_CONFIG_MILESTONE_MUTATION = gql`
  mutation updateProcessConfigMilestones(
    $processConfigId: Int!
    $processConfigMilestoneEdits: [ProcessConfigMilestoneEditAssigneesDtoInput]
  ) {
    updateProcessConfigMilestones(
      processConfigId: $processConfigId
      processConfigMilestoneEdits: $processConfigMilestoneEdits
    ) {
      ...ProcessConfig
    }
  }
  ${PROCESS_CONFIG_FRAGMENT}
`;

export const GET_PROCESS_PREVIEW = gql`
  query ProcessPreview(
    $previewProcessConfigDto: PreviewProcessConfigDtoInput!
    $companyCode: String!
  ) {
    processPreview(previewProcessConfigDto: $previewProcessConfigDto, companyCode: $companyCode) {
      processCount
      previewMilestones {
        dueDate
      }
    }
  }
`;

export const CREATE_NEW_PROCESS_CONFIG = gql`
  mutation createNewProcessConfig(
    $processConfigInputDto: ProcessConfigInputDtoInput!
    $companyCode: String!
  ) {
    createNewProcessConfig(
      processConfigInputDto: $processConfigInputDto
      companyCode: $companyCode
    ) {
      name
      processTemplateId
      processTypeId
      useLastDayOfMonth
      initialReportingPeriodEndDate
      initialFilingDueDate
      recurrenceEndDate
      milestones {
        id
        assigneeId
        defaultDaysOffset
      }
      complianceRequirement {
        id
        name
      }
    }
  }
`;

export const UPDATE_PROCESS_CONFIG = gql`
  mutation updateProcessConfig(
    $processConfigInputDto: ProcessConfigInputDtoInput!
    $processConfigId: Int!
    $companyCode: String!
  ) {
    updateProcessConfig(
      processConfigInputDto: $processConfigInputDto
      processConfigId: $processConfigId
      companyCode: $companyCode
    ) {
      name
      processTemplateId
      processTypeId
      useLastDayOfMonth
      initialReportingPeriodEndDate
      initialFilingDueDate
      recurrenceEndDate
      milestones {
        id
        assigneeId
        defaultDaysOffset
      }
      recurrence
    }
  }
`;

export const DEACTIVATE_PROCESS_CONFIG = gql`
  mutation deactivateProcessConfig($processConfigDeactivateDto: ProcessConfigDeactivateDtoInput) {
    deactivateProcessConfig(processConfigDeactivateDto: $processConfigDeactivateDto) {
      ...ProcessConfig
      nextReportingPeriodEndDate
      nextFilingDueDate
      previousReportingPeriodEndDate
      previousFilingDueDate
    }
  }
  ${PROCESS_CONFIG_FRAGMENT}
`;

export const GET_PROCESS_CONFIG_COMPANY_DEFAULT_DATES = gql`
  query ProcessConfigCompanyDefaultDates($companyCode: String!) {
    processConfigCompanyDefaultDates(companyCode: $companyCode) {
      statutoryYearEnd
      auditFilingDueDate
      hasDefaultCompanyAFSTemplate
    }
  }
`;

export default PROCESS_CONFIG_LIST_QUERY;
