import { useContext } from 'react';
import FeatureFlagContext from '../../../utils/featureFlags/FeatureFlagContext';
import FeatureFlags from '../../../utils/featureFlags/FeatureFlags';

interface IFeatureFlaggedProps {
  flagName: string;
  children: JSX.Element;
  showIfInactive?: boolean;
}

const FeatureFlagged = ({
  flagName,
  children,
  showIfInactive,
}: IFeatureFlaggedProps): JSX.Element => {
  const featureFlags: FeatureFlags = useContext(FeatureFlagContext);

  let isActive = featureFlags?.flags[flagName];

  isActive = showIfInactive ? !isActive : isActive;

  return isActive ? children : null;
};

export default FeatureFlagged;
