import { useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { ColumnActionsMode, DetailsListLayoutMode, Stack } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { CoherencePanelStyles, rowClasses } from '../../../../app/common/styles/CommonStyleObjects';
import { GET_PROCESS_TEMPLATES_BY_MILESTONE_TEMPLATE } from '../../../../utils/statApi/ProcessTemplateApi';

import { IMilestoneTemplate } from '../../../../utils/types/IMilestoneTemplate';
import Card from '../../../common/card/Card';
import ShimmeredDetailsListWrapper from '../../../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import SpanWithLabel from '../../../common/SpanWithLabel';
import StackColumn from '../../../common/StackColumn';

interface IMilestonePoolDetailsPanel {
  closePanel: () => void;
  milestoneTemplate: IMilestoneTemplate;
}
const MilestoneTemplateDetailsPanel = (props: IMilestonePoolDetailsPanel): JSX.Element => {
  const { closePanel, milestoneTemplate } = props;

  const {
    loading: loadingDetails,
    data: dataDetails,
    error: errorDetails,
  } = useQuery(GET_PROCESS_TEMPLATES_BY_MILESTONE_TEMPLATE, {
    variables: {
      milestoneTemplateId: milestoneTemplate.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 175,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      onRender: (item: { id: number; name: string }): JSX.Element => {
        return <Link to={`template/${item.id}`}>{item.name}</Link>;
      },
    },
    {
      key: 'configCount',
      name: 'Config Count',
      fieldName: 'configCount',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
    },
  ];

  const milestoneInUse =
    dataDetails?.processTemplatesByMilestoneTemplate &&
    dataDetails?.processTemplatesByMilestoneTemplate?.length > 0;

  const getTotalConfigs = (): number => {
    return dataDetails?.processTemplatesByMilestoneTemplate.reduce(
      (sum: number, b: { configCount: number }) => sum + b.configCount,
      0,
    );
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Milestone Template Details"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close"
    >
      <LoadingErrorMessage error={errorDetails} loading={loadingDetails} />
      <Card title="Milestone Details">
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Milestone Name" value={milestoneTemplate.name} />
            <Stack horizontal wrap horizontalAlign="space-between">
              <SpanWithLabel
                labelText="Total Templates Using This Milestone"
                value={dataDetails?.processTemplatesByMilestoneTemplate?.length}
              />
              <SpanWithLabel
                labelText="Total Process Configs Using This Milestone"
                value={getTotalConfigs()}
              />
            </Stack>
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card title="Templates">
        {!loadingDetails && milestoneInUse && (
          <ShimmeredDetailsListWrapper
            compact
            items={dataDetails?.processTemplatesByMilestoneTemplate || []}
            layoutMode={DetailsListLayoutMode.justified}
            columns={columns}
            enableShimmer={loadingDetails}
            pluralListLabel="Process Templates"
            singularListLabel="Process Templates"
          />
        )}
        {!loadingDetails && !milestoneInUse && (
          <div>There are no active process templates using this milestone.</div>
        )}
      </Card>
    </CoherencePanel>
  );
};
export default MilestoneTemplateDetailsPanel;
