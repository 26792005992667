import { gql } from '@apollo/client';

const GET_REASONS_FOR_REPORTING_EXCLUSION_APPROVAL = gql`
  {
    reasonsForReportingExclusionApproval {
      id
      fieldName
    }
  }
`;
export default GET_REASONS_FOR_REPORTING_EXCLUSION_APPROVAL;
