import { IColumn } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Action } from '../../utils/statApi/RolesApi';

const getColumns = (): Array<IColumn> => {
  return [
    {
      key: 'displayName',
      name: 'Display Name',
      fieldName: 'displayName',
      minWidth: 200,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
      onRender: (item: Action): JSX.Element => (
        <Link to={`/role-description/actions/${item.id}`}>{item.displayName}</Link>
      ),
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 100,
      isRowHeader: false,
      data: 'string',
    },
  ];
};

export default getColumns;
