import React, { useContext } from 'react';
import hasAccess from '../../../utils/authorization/authorizationCheck';
import UserContext from '../../../utils/authorization/UserContext';
import { StatUser } from '../../../utils/statApi/UsersApi';

interface ICanAccessProps {
  requestedAction: string;
  children: JSX.Element;
  notAuthorizedChildren?: JSX.Element;
}

const CanAccess: React.FunctionComponent<ICanAccessProps> = ({
  requestedAction,
  children,
  notAuthorizedChildren,
}: ICanAccessProps): JSX.Element => {
  const user: StatUser = useContext(UserContext);

  const fragment: JSX.Element = hasAccess(user?.permissions, requestedAction)
    ? children
    : notAuthorizedChildren;

  return fragment || null;
};

export default CanAccess;
