import React from 'react';
import { PersonaSize, Persona, IPersonaSharedProps } from '@fluentui/react';
import { personaDetailsWrapper } from './UsersStyles';
import { IGraphUserObject } from '../../utils/types/Users';

interface IGraphUserBlockProps {
  graphUser: IGraphUserObject;
  userImage?: string;
}
const GraphUserBlock = (props: IGraphUserBlockProps): JSX.Element => {
  const { graphUser, userImage } = props;
  if (!graphUser) {
    return <></>;
  }

  const { givenName, surname, displayName, jobTitle, officeLocation } =
    graphUser as IGraphUserObject;

  const detailsPersona: IPersonaSharedProps = {
    imageUrl: userImage || '',
    imageInitials: `${givenName && givenName.charAt(0)}${surname && surname.charAt(0)}`,
    text: displayName,
    secondaryText: jobTitle,
    tertiaryText: officeLocation,
  };
  return (
    <div className={personaDetailsWrapper}>
      <Persona {...detailsPersona} size={PersonaSize.size100} hidePersonaDetails={false} />
    </div>
  );
};
export default GraphUserBlock;
