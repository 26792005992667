import { gql } from '@apollo/client';

const GET_SAP_LEVELS_QUERY = gql`
  {
    sapLevels {
      id
      fieldName
    }
  }
`;
export default GET_SAP_LEVELS_QUERY;
