import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams, Link, Redirect } from 'react-router-dom';
import { DefaultButton, getTheme, Icon, Spinner, Stack, TeachingBubble } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IProcessDetailParams } from '../../../common/ParamTypes';
import ActionButtons from '../../../common/ActionButtons';
import CanAccess from '../../../common/canAccess/CanAccess';
import { IProcessExtensionRequest, MilestoneStatus } from '../Interfaces';
import ProcessExtensionRequestForm from './ProcessExtensionRequestForm';
import { IReviewDocumentInput, IReviewDocumentsInput } from '../../../../utils/statApi/ReviewApi';
import {
  CREATE_PROCESS_EXTENSION_REQUEST,
  GET_PROCESS_EXTENSION_REQUESTS_FOR_PROCESS,
  UPDATE_PROCESS_EXTENSION_REQUEST,
} from '../../../../utils/statApi/ProcessExtensionApi';
import momentUtc from '../../../../utils/DateFormatter';
import StatDateFormats from '../../../../utils/types/DateFormats';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import { IDocument } from '../../../../utils/statApi/DocumentApi';

export interface IProcessExtensionPanelProps {
  processExtension: IProcessExtensionRequest;
  closePanel: () => void;
  currentFilingDueDate?: Date;
}

const ProcessExtensionRequestPanel = ({
  processExtension,
  closePanel,
  currentFilingDueDate,
}: IProcessExtensionPanelProps): JSX.Element => {
  const [showTeachingBubble, setShowTeachingBubble] = useState<boolean>(false);
  const processExtensionForm = useForm();
  const { companyCode, processId } = useParams<IProcessDetailParams>();
  const [getExtensions, { data: listData, loading, error }] = useLazyQuery(
    GET_PROCESS_EXTENSION_REQUESTS_FOR_PROCESS,
    {
      variables: { processId: parseInt(processId, 10) },
      fetchPolicy: 'cache-and-network',
    },
  );
  const history = useHistory();
  const [inCreateMode, setInCreateMode] = useState<boolean>(false);

  const theme = getTheme();

  useEffect(() => {
    if (processExtension.id === -1) {
      getExtensions();
    }
  }, []);

  const navigateToProcessExtensionReviewPage = (per: IProcessExtensionRequest): void => {
    history.push(`/process-extensions/${per.id}/review`);
  };

  const [createProcessExtensionRequest, { loading: createLoading, error: createError }] =
    useMutation(CREATE_PROCESS_EXTENSION_REQUEST, {
      onCompleted: (data: { createProcessExtensionRequest: IProcessExtensionRequest }) =>
        navigateToProcessExtensionReviewPage(data.createProcessExtensionRequest),
    });

  const [updateProcessExtensionRequest, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_PROCESS_EXTENSION_REQUEST, {
      onCompleted: closePanel,
    });

  const handleNewButtonClick = (): void => {
    setInCreateMode(true);
  };

  const { errors, handleSubmit } = processExtensionForm;

  const toggleTeachingBubble = (): void => {
    setShowTeachingBubble(!showTeachingBubble);
  };

  const renderAddNewButton = (): JSX.Element => {
    const areAnyPending = listData?.processExtensionsForProcess?.some(
      (extension: { reviewStatus: string }) => {
        return extension.reviewStatus === 'Pending';
      },
    );

    let teachingBubbleContent = '';

    const goalMilestone = processExtension?.process?.milestones?.find(
      (mi) => mi.maxDaysOffset === 0,
    );
    const isGoalMilestoneComplete = goalMilestone?.status === MilestoneStatus.complete;

    const isDueDatePast = moment(currentFilingDueDate).utc().endOf('day').isBefore(moment().utc());

    if (areAnyPending) {
      teachingBubbleContent =
        'There is already a process extension in progress, a new extension cannot be created until that one is accepted/rejected.';
    } else if (isGoalMilestoneComplete) {
      teachingBubbleContent =
        'The goal milestone is complete on this process and cannot be extended.';
    }

    if (!areAnyPending && !isGoalMilestoneComplete) {
      const permission = isDueDatePast
        ? 'ProcessExtensionRequest:AdminAdd'
        : 'ProcessExtensionRequest:Add';

      return (
        <CanAccess requestedAction={permission}>
          <>
            <h2>Request New Extension</h2>
            <DefaultButton
              text="New Extension"
              iconProps={{ iconName: 'add' }}
              onClick={handleNewButtonClick}
            />
          </>
        </CanAccess>
      );
    }

    return (
      <>
        <h2>Request New Extension</h2>
        <DefaultButton
          id="DisabledNexExtensionButton"
          text="New Extension"
          iconProps={{ iconName: 'add' }}
          onClick={toggleTeachingBubble}
        />
        {showTeachingBubble && (
          <TeachingBubble
            target="#DisabledNexExtensionButton"
            primaryButtonProps={{ children: 'Close', onClick: toggleTeachingBubble }}
            headline={areAnyPending ? 'Extension Currently In Progress' : 'Process Complete'}
          >
            {teachingBubbleContent}
          </TeachingBubble>
        )}
      </>
    );
  };

  const iconStyle = (color: string) => {
    return {
      fontSize: 20,
      height: 20,
      width: 20,
      color,
    };
  };

  const teachingBubbleProps = {
    header: 'Process Complete',
    content: 'The goal milestone is complete on this process and cannot be extended.',
    showTeachingBubble,
    toggleTeachingBubble,
  };

  const listItemStyle = { paddingBottom: 10, listStyleType: 'none' };

  const renderStatusIcon = (status: string): JSX.Element => {
    if (status === 'Accepted') {
      return <Icon iconName="SkypeCircleCheck" style={iconStyle(theme.palette.green)} />;
    }

    if (status === 'Rejected') {
      return <Icon iconName="StatusCircleBlock" style={iconStyle(theme.palette.red)} />;
    }

    return <Icon iconName="AwayStatus" style={iconStyle(theme.palette.blue)} />;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSaveClick = (formData: any): void => {
    // GraphQL types don't like the typename property
    const goalMilestone = processExtension?.process?.milestones?.find(
      (mi) => mi.maxDaysOffset === 0,
    );
    const isGoalMilestoneComplete = goalMilestone?.status === MilestoneStatus.complete;

    if (isGoalMilestoneComplete) {
      setShowTeachingBubble(true);
      return;
    }

    const saveData: IProcessExtensionRequest = {
      id: parseInt(formData.id.toString(), 10),
      extensionReason: {
        id: formData.extensionReason.id,
        fieldName: formData.extensionReason.fieldName,
      },
      comments: formData.comments,
      requestedDate: formData.requestedDate,
    };

    if (!Object.keys(errors).length) {
      if (saveData.id > 0) {
        updateProcessExtensionRequest({
          variables: {
            processExtensionRequest: {
              ...saveData,
              processId: parseInt(formData.processId.toString(), 10),
            },
          },
        });
      } else {
        const reviewDocs: IReviewDocumentsInput = {
          reviewId: -1,
          reviewDocuments: formData.reviewDocuments?.map((rv: IDocument) => {
            return {
              id: -1,
              reviewId: -1,
              reviewDto: null,
              title: rv.title,
              url: rv.url,
              uploadDate: rv.uploadDate,
              uploader: null,
              isDeleted: rv.isDeleted,
            } as IReviewDocumentInput;
          }),
        };

        createProcessExtensionRequest({
          variables: {
            processExtensionRequest: {
              ...saveData,
              processId: parseInt(processId, 10),
            },
            companyCode,
            reviewDocuments: reviewDocs,
          },
        });
      }
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Process Extensions"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Process Extension Panel"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="ProcessExtensionRequest:Add">
          <>
            {!loading &&
              !error &&
              (!listData?.processExtensionsForProcess?.length ||
                inCreateMode ||
                processExtension.id !== -1) && (
                <ActionButtons
                  mutationLoading={createLoading || updateLoading}
                  closePanel={closePanel}
                  handleSubmit={handleSubmit(handleOnSaveClick)}
                  saveTitle="Save Process Extension Request"
                  saveLabel="Save Process Extension Request"
                  cancelTitle="Cancel Process Extension Request Edit"
                  cancelLabel="Cancel Process Extension Request Edit"
                  teachingBubbleProps={teachingBubbleProps}
                />
              )}
          </>
        </CanAccess>
      )}
    >
      <>
        {loading && <Spinner label="Loading" />}
        {!loading &&
          !error &&
          (!listData?.processExtensionsForProcess?.length ||
            inCreateMode ||
            processExtension.id !== -1) && (
            <form onSubmit={handleSubmit(handleOnSaveClick)}>
              <LoadingErrorMessage
                loading={createLoading || updateLoading}
                error={createError || updateError}
                actionName="saving the Process Extension Request to the server"
              />
              <ProcessExtensionRequestForm
                processExtensionRequest={processExtension}
                form={processExtensionForm}
              />
            </form>
          )}
        {!loading &&
          !error &&
          !inCreateMode &&
          listData?.processExtensionsForProcess?.length === 1 &&
          listData?.processExtensionsForProcess[listData?.processExtensionsForProcess?.length - 1]
            ?.reviewStatus === 'Pending' && (
            <Redirect
              to={`/process-extensions/${listData?.processExtensionsForProcess[0].id}/review`}
            />
          )}
        {!loading &&
          !error &&
          !inCreateMode &&
          listData?.processExtensionsForProcess?.length > 0 &&
          listData?.processExtensionsForProcess[listData?.processExtensionsForProcess?.length - 1]
            ?.reviewStatus !== 'Pending' && (
            <div>
              <h2>Existing Extensions</h2>
              <ul>
                {listData.processExtensionsForProcess.map(
                  (extension: { id: number; requestedDate: string; reviewStatus: string }) => {
                    return (
                      <li key={extension.id} style={listItemStyle}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                          {renderStatusIcon(extension.reviewStatus)}
                          <Link
                            key={extension.id}
                            to={`/process-extensions/${extension.id}/review`}
                          >
                            <span>
                              {`Requested Filing Date: ${momentUtc(
                                extension.requestedDate,
                                StatDateFormats.DayBreakout,
                              )}`}
                            </span>
                            {' | '}
                            <span>{`Status: ${extension.reviewStatus}`}</span>
                          </Link>
                        </Stack>
                      </li>
                    );
                  },
                )}
              </ul>
              {renderAddNewButton()}
            </div>
          )}
      </>
    </CoherencePanel>
  );
};

export default ProcessExtensionRequestPanel;
