import { IContextualMenuProps } from '@fluentui/react';
import ProcessStatus from '../../../utils/types/ProcessStatus';

function processCurrentDropdownProps(onItemClick: {
  (itemKey: string): void;
}): IContextualMenuProps {
  return {
    onItemClick: (ev, item): void => {
      onItemClick(item.key);
    },
    items: [
      {
        key: ProcessStatus.Active,
        text: `${ProcessStatus.Active} Processes`,
        iconProps: { iconName: 'RenewalCurrent' },
      },
      {
        key: ProcessStatus.Complete,
        text: `${ProcessStatus.Complete} Processes`,
        iconProps: { iconName: 'Calendar' },
      },
    ],
    directionalHintFixed: true,
  };
}
export default processCurrentDropdownProps;
