import { Stack } from '@fluentui/react';
import React from 'react';
import formatCurrency from '../../../utils/CurrencyFormatter';
import IAgmDetail from '../../../utils/types/IAgmDetail';
import Card from '../../common/card/Card';
import SpanWithLabel from '../../common/SpanWithLabel';
import StackColumn from '../../common/StackColumn';

interface IAgmDetailsDividendsCardProps {
  agmDetail: IAgmDetail;
  isLoading: boolean;
  actionButtons?: React.ReactNode;
}

const AgmDetailsDividendsCard = ({
  agmDetail,
  isLoading,
  actionButtons,
}: IAgmDetailsDividendsCardProps): JSX.Element => {
  return (
    <Card title="Dividends Details" isLoading={isLoading} actionButton={actionButtons}>
      <Stack horizontal wrap horizontalAlign="space-between">
        <StackColumn>
          <SpanWithLabel
            labelText="Dividend Review Status"
            value={
              agmDetail?.dividendsReviewStatus ? agmDetail?.dividendsReviewStatus : 'Not Started'
            }
          />
          <SpanWithLabel
            labelText="Dividends from Current Year"
            value={
              agmDetail?.dividendCurrentYear
                ? formatCurrency(agmDetail.dividendCurrencyCode, agmDetail.dividendCurrentYear)
                : '--'
            }
          />
        </StackColumn>
        <StackColumn>
          <SpanWithLabel
            labelText="Proposed Dividends"
            value={
              agmDetail?.proposedDividendAllowed
                ? formatCurrency(agmDetail.dividendCurrencyCode, agmDetail.proposedDividendAllowed)
                : '--'
            }
          />

          <SpanWithLabel
            labelText="Dividends from Retained Earnings"
            value={
              agmDetail?.dividendRetainedEarnings
                ? formatCurrency(agmDetail.dividendCurrencyCode, agmDetail.dividendRetainedEarnings)
                : '--'
            }
          />
        </StackColumn>
      </Stack>
    </Card>
  );
};

export default AgmDetailsDividendsCard;
