import React from 'react';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { ApolloError } from '@apollo/client';

interface IErrorMessageProps {
  loading: boolean;
  error: ApolloError;
  actionName?: string;
}

const LoadingErrorMessage = ({ loading, error, actionName }: IErrorMessageProps): JSX.Element => {
  if (loading || !error) {
    return null;
  }
  const formatErrorMessage = (): string => {
    if (!actionName) {
      return 'An error occurred loading data from the server';
    }

    return `An error occurred ${actionName}.`;
  };

  if (loading || !error) {
    return null;
  }
  return (
    <MessageBar messageBarType={MessageBarType.error}>
      <Stack tokens={{ childrenGap: 10 }}>
        <span>{formatErrorMessage()}</span>
        <span>{error.message}</span>
      </Stack>
    </MessageBar>
  );
};

export default LoadingErrorMessage;
