import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { ISubwayNavNodeProps, SubwayNavNodeState } from '@m365-admin/wizard';
import { Stack } from '@fluentui/react';

import ProcessConfigWizardNav from './ProcessConfigWizardNav';
import { getWizardText, NewProcessCreationSteps } from './CreateProcessConfigUtils';
import CreateProcessConfigDetailsForm from './CreateProcessConfigDetailsForm';
import CreateProcessConfigMilestonesForm from './CreateProcessConfigMilestonesForm';
import CreateProcessConfigReviewForm from './CreateProcessConfigReviewForm';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import ProcessConfigWizardFooter from './ProcessConfigWizardFooter';
import {
  CREATE_NEW_PROCESS_CONFIG,
  GetProcessConfigsWithPaginationQuery,
  PROCESS_CONFIG_DETAIL_QUERY,
  PROCESS_CONFIG_MILESTONES_BY_VERSION_QUERY,
  UPDATE_PROCESS_CONFIG,
} from '../../../../utils/statApi/ProcessConfigApi';
import { ICompanyCodeParams, IProcessConfigIdParams } from '../../../common/ParamTypes';
import IProcessConfig, {
  IProcessTemplateVersionMilestone,
} from '../../../../utils/types/IProcessConfig';
import { GET_PROCESS_TEMPLATE_MILESTONES_BY_TYPE } from '../../../../utils/statApi/ProcessTemplateApi';
import EditProcessConfigDetailsForm from './EditProcessConfigDetailsForm';
import PROCESS_TYPE_LIST_QUERY from '../../../../utils/statApi/ProcessTypesApi';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import { DEFAULT_RECORDS_PER_PAGE } from '../../../common/DetailsLists/pagination/DetailsListPaginationBanner';

interface IProcessConfigWizardProps {
  processConfigWizardSteps: ISubwayNavNodeProps[];
  closePanel?: () => void;
  defaultFormState?: IProcessConfig;
}
const ProcessConfigWizard = ({
  closePanel,
  processConfigWizardSteps,
  defaultFormState = {
    name: '',
    processType: null as IDropdownReference,
    processTemplate: null as IDropdownReference,
    recurrence: null,
    filingDueDate: null,
    reportingPeriodEndDate: null,
    minFilingDueDate: null,
    minReportingPeriodEndDate: null,
    recurrenceEndDate: null,
    useLastDayOfMonth: false,
    milestones: [],
    reportingPeriodYear: new Date().getFullYear(),
    complianceRequirement: null,
  },
}: IProcessConfigWizardProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { processConfigId } = useParams<IProcessConfigIdParams>();
  const defaultCurrentStep = processConfigWizardSteps.find((step) => {
    return step.state === SubwayNavNodeState.Current;
  });
  const [navSteps, setNavSteps] = useState<ISubwayNavNodeProps[]>(processConfigWizardSteps);
  const [currentNavStepId, setCurrentNavStepId] = useState<string>(defaultCurrentStep.id);
  const [detailsFormState, setDetailsFormState] = useState(defaultFormState);
  const [originalConfigDuration, setOriginalConfigDuration] = useState(0);

  const [
    getProcessTemplateMilestoneData,
    {
      loading: processTemplateLoading,
      data: processTemplateMilestonesData,
      error: processTemplateError,
    },
  ] = useLazyQuery(GET_PROCESS_TEMPLATE_MILESTONES_BY_TYPE);

  useEffect(() => {
    if (processTemplateMilestonesData?.processTemplateMilestones) {
      setOriginalConfigDuration(
        Math.abs(processTemplateMilestonesData.processTemplateMilestones[0]?.defaultDaysOffset),
      );
      setDetailsFormState({
        ...detailsFormState,
        milestones: processTemplateMilestonesData.processTemplateMilestones,
      });
    }
  }, [processTemplateMilestonesData]);

  const updateMilestoneState = (newMilestones: IProcessTemplateVersionMilestone[]) => {
    setDetailsFormState({ ...detailsFormState, milestones: newMilestones });
  };

  const toggleStep = (id: NewProcessCreationSteps, isDisabled: boolean) => {
    const newSteps = [...navSteps];
    newSteps.find((step) => {
      return step.id === id;
    }).disabled = isDisabled;
    setNavSteps(newSteps);
  };

  const onProcessConfigDetailsValidated = (isValidated: boolean) => {
    toggleStep(NewProcessCreationSteps.Milestones, !isValidated);
    toggleStep(NewProcessCreationSteps.Review, !isValidated);
  };

  const leftWizardNavContainer = {
    root: { width: '200px' },
  };

  const [createNewProcessConfig, { loading: loadingCreateNewConfig, error: errorNewConfig }] =
    useMutation(CREATE_NEW_PROCESS_CONFIG, {
      onCompleted: () => {
        closePanel();
      },
      refetchQueries: [
        {
          query: GetProcessConfigsWithPaginationQuery,
          variables: {
            first: DEFAULT_RECORDS_PER_PAGE,
            after: null,
            last: null,
            before: null,
            keyword: companyCode,
          },
        },
        {
          query: PROCESS_TYPE_LIST_QUERY,
          variables: { companyCode },
        },
      ],
    });

  const [
    updateProcessConfig,
    { loading: loadingUpdateProcessConfig, error: errorUpdateProcessConfig },
  ] = useMutation(UPDATE_PROCESS_CONFIG, {
    onCompleted: () => {
      closePanel();
    },
    refetchQueries: [
      {
        query: GetProcessConfigsWithPaginationQuery,
        variables: {
          first: DEFAULT_RECORDS_PER_PAGE,
          after: null,
          last: null,
          before: null,
          keyword: companyCode,
        },
      },
      {
        query: PROCESS_CONFIG_DETAIL_QUERY,
        variables: { processConfigId: parseInt(processConfigId, 10) },
      },
      {
        query: PROCESS_CONFIG_MILESTONES_BY_VERSION_QUERY,
        variables: {
          processConfigId: parseInt(processConfigId, 10),
          processTemplateVersionId: null,
        },
      },
    ],
  });

  const handleSubmit = (): void => {
    const id = processConfigId === undefined ? null : parseInt(processConfigId, 10);
    const isNew = id === null;
    const processConfigInputDto = {
      id,
      name: detailsFormState.name,
      processTemplateId: detailsFormState.processTemplate.id,
      processTypeId: detailsFormState.processType.id,
      initialFilingDueDate: detailsFormState.filingDueDate,
      initialReportingPeriodEndDate: detailsFormState.reportingPeriodEndDate,
      useLastDayOfMonth: detailsFormState.useLastDayOfMonth,
      milestones: detailsFormState.milestones.map((milestone) => {
        return {
          id: milestone.id,
          assigneeId: milestone.assigneeId,
          defaultDaysOffset: milestone.defaultDaysOffset,
        };
      }),
      recurrenceEndDate: detailsFormState.recurrenceEndDate,
      recurrence: detailsFormState.recurrence,
      complianceRequirementId: detailsFormState?.complianceRequirement?.id,
    };

    if (isNew) {
      createNewProcessConfig({
        variables: {
          processConfigInputDto,
          companyCode,
        },
      });
    } else {
      updateProcessConfig({
        variables: {
          processConfigInputDto,
          processConfigId: parseInt(processConfigId, 10),
          companyCode,
        },
      });
    }
  };

  const isEditMode = detailsFormState.id !== undefined;
  const loading = isEditMode ? loadingUpdateProcessConfig : loadingCreateNewConfig;
  const error = isEditMode ? errorUpdateProcessConfig : errorNewConfig;
  const labels = getWizardText(isEditMode);

  /** Necessary since scrollable pane fills space.
   * Prevents milestones list from having  0px height
   */
  const forceFullHeightOnPanelBodyStyles = {
    body: {
      height: '100%',
    },
    ...CoherencePanelStyles,
  };
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText={`${labels.formTitle} Process Configuration`}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={forceFullHeightOnPanelBodyStyles}
      closeButtonAriaLabel="Close"
      onRenderFooter={() => (
        <ProcessConfigWizardFooter
          currentNavStepId={currentNavStepId}
          setCurrentNavStepId={setCurrentNavStepId}
          navSteps={navSteps}
          setNavSteps={setNavSteps}
          closePanel={closePanel}
          handleSubmit={handleSubmit}
          loading={loading}
          disableSaveButton={currentNavStepId !== NewProcessCreationSteps.Review}
          buttonText={labels.saveButtonText}
          cancelText={labels.saveButtonCancelText}
        />
      )}
    >
      <Stack horizontal verticalFill tokens={{ childrenGap: 2 }}>
        <Stack.Item shrink={0} styles={leftWizardNavContainer}>
          <ProcessConfigWizardNav
            onStepClick={(newSteps: ISubwayNavNodeProps[], newCurrentStepId: string) => {
              setCurrentNavStepId(newCurrentStepId);
              setNavSteps(newSteps);
            }}
            steps={navSteps}
          />
        </Stack.Item>
        <Stack.Item grow verticalFill shrink={1} styles={{ root: { position: 'relative' } }}>
          <form onSubmit={() => {}}>
            {currentNavStepId === NewProcessCreationSteps.Details && !isEditMode && (
              <CreateProcessConfigDetailsForm
                onValidated={onProcessConfigDetailsValidated}
                setData={setDetailsFormState}
                data={detailsFormState}
                onTemplateChange={(processTemplateId) => {
                  getProcessTemplateMilestoneData({
                    variables: { processTemplateId, companyCode },
                  });
                }}
                companyCode={companyCode}
              />
            )}
            {currentNavStepId === NewProcessCreationSteps.Details && isEditMode && (
              <EditProcessConfigDetailsForm
                onValidated={onProcessConfigDetailsValidated}
                setData={setDetailsFormState}
                data={detailsFormState}
              />
            )}
            {currentNavStepId === NewProcessCreationSteps.Milestones && (
              <CreateProcessConfigMilestonesForm
                processTemplateMilestones={detailsFormState.milestones}
                loading={processTemplateLoading}
                error={processTemplateError}
                onMilestonesChange={updateMilestoneState}
              />
            )}
            {currentNavStepId === NewProcessCreationSteps.Review && (
              <CreateProcessConfigReviewForm
                mutationLoading={loading}
                mutationError={error}
                processConfig={detailsFormState}
                originalConfigDuration={originalConfigDuration}
                showUpdateWarningMessage={isEditMode}
                readyMessage={labels.readyMessage}
                processCountLabel={labels.processCountLabel}
              />
            )}
          </form>
        </Stack.Item>
      </Stack>
    </CoherencePanel>
  );
};
export default ProcessConfigWizard;
