import {
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  IChoiceGroupOptionProps,
  ITag,
  NeutralColors,
  mergeStyleSets,
  normalize,
} from '@fluentui/react';
import React from 'react';
import { MessagingColors } from '../../../../app/common/styles/CommonStyleObjects';

interface IAgmTrackerAgingCounts {
  agingId: string;
  agingName: string;
  alternativeName: string;
  count: number;
}

interface IAgmTrackerAgingMetricSelectorProps {
  onchange: (items: ITag[], key: string, title: string) => void;
  agingSelectedItems: ITag[];
  agingItems: IAgmTrackerAgingCounts[];
  colorMapping: (count: number) => string;
}

const AgmTrackerAgingMetricSelector = (props: IAgmTrackerAgingMetricSelectorProps) => {
  const { onchange, agingSelectedItems, agingItems, colorMapping } = props;

  const styles = mergeStyleSets({
    itemContent: [
      normalize,
      {
        height: 40,
        borderBottom: `1px solid ${NeutralColors.gray30}`,
        overflow: 'hidden',
      },
    ],
    itemNameAndCount: {
      display: 'flex',
      alignItems: 'center',
    },
  });

  const getChoiceGroupOptions = () => {
    return agingItems?.map((statuses: IAgmTrackerAgingCounts) => {
      return {
        key: statuses?.agingId,
        text: statuses?.alternativeName,
        id: statuses?.agingName,
        styles: {
          choiceFieldWrapper: {
            width: '100%',
          },
        },
        onRenderField: (
          choiceGroupProp: IChoiceGroupOption & IChoiceGroupOptionProps,
          render: (props: IChoiceGroupOption & IChoiceGroupOptionProps) => JSX.Element | null,
        ) => {
          return (
            <div data-is-focusable className={styles.itemContent} key={statuses.agingId}>
              <div
                style={{
                  borderRight: `solid 3px ${
                    colorMapping ? colorMapping(statuses?.count) : MessagingColors.defaultIcon
                  }`,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: 'auto',
                }}
              >
                <div>{render(choiceGroupProp)}</div>
                <div style={{ padding: 5 }}>{statuses?.count}</div>
              </div>
            </div>
          );
        },
      } as IChoiceGroupOption;
    });
  };

  return (
    <>
      <ChoiceGroup
        options={getChoiceGroupOptions()}
        selectedKey={agingSelectedItems?.[0]?.key ?? null}
        onChange={(ev, option) => {
          onchange([{ key: option?.key, name: option?.id }], 'aging', 'Agm Tracker Aging');
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          padding: '16px 0',
        }}
      >
        <DefaultButton text="Reset Aging Selection" onClick={() => onchange([], 'aging', '')} />
      </div>
    </>
  );
};

export default AgmTrackerAgingMetricSelector;
