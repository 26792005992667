import { gql } from '@apollo/client';

const GET_AGM_STATUSES_QUERY = gql`
  {
    agmStatuses {
      id
      fieldName
    }
  }
`;
export default GET_AGM_STATUSES_QUERY;
