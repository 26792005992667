import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DefaultButton, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import { GET_RUNS_FOR_DOCUMENT } from '../../../utils/statApi/DocumentAnalysisApi';
import AnalysisQueueColumns from './DocumentAnalysisQueueList.config';
import Card from '../../common/card/Card';
import {
  bodyContentContainer,
  styledDetailsList,
} from '../../../app/common/styles/CommonStyleObjects';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import DocumentView from '../../common/documents/DocumentView';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IDocumentAnalysisRunParams } from '../../common/ParamTypes';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import AnalyzeButton from './AnalyzeButton';

const documentDetailsStyle = {
  flex: '1',
  margin: '5px',
};

const emptyStateStyle = {
  padding: '20px',
};

const DocumentAnalysisQueueList = () => {
  const label = 'Analyses';

  const { id } = useParams<IDocumentAnalysisRunParams>();
  const intId = parseInt(id, 10);
  const variables = { id: intId };
  const { data, loading, error, refetch } = useQuery(GET_RUNS_FOR_DOCUMENT, {
    variables,
  });

  const document = data?.documentAnalysisRuns;

  const refresh = (): void => {
    refetch(variables);
  };

  return (
    <div>
      <FullWidthHeader title={() => <h1>{label}</h1>} />
      <div className={`${bodyContentContainer}`}>
        <LoadingErrorMessage error={error} loading={loading} />
        {!loading && !error && (
          <>
            <Card title="Document Details" headingLevel={2}>
              <div style={{ display: 'flex' }}>
                <div style={documentDetailsStyle}>
                  {document?.document && <DocumentView document={document?.document} index={0} />}
                </div>
                <div style={documentDetailsStyle}>
                  <AnalyzeButton documentId={intId} />
                </div>
              </div>
            </Card>
            <br />
            <DefaultButton text="Refresh" onClick={refresh} disabled={loading} />
            <br />
            <br />
            <div className="ms-depth-4">
              {!document?.runs.length && (
                <p style={emptyStateStyle}>
                  No previous analyses. Click the analyze button to create a new analysis.
                </p>
              )}
              {document?.runs.length > 0 && (
                <ShimmeredDetailsListWrapper
                  items={document?.runs}
                  columns={AnalysisQueueColumns}
                  layoutMode={DetailsListLayoutMode.justified}
                  enableShimmer={loading}
                  selectionMode={SelectionMode.none}
                  detailsListStyles={styledDetailsList}
                  ariaLabelForShimmer={`${label} are being fetched`}
                  ariaLabelForGrid={`${label} details`}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  pluralListLabel={label}
                  singularListLabel={label}
                  isColumnSortedList
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentAnalysisQueueList;
