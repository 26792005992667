import { DocumentNode, useMutation } from '@apollo/client';
import {
  MessageBar,
  MessageBarType,
  Persona,
  PersonaPresence,
  PrimaryButton,
  Spinner,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { IReview, IReviewApproval } from '../../utils/types/IReview';
import CanAccess from '../common/canAccess/CanAccess';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import RejectButton from './RejectButton';
import RejectButtonMode from './RejectButtonMode';
import ReviewStatus from './ReviewStatus';

interface IReviewApprovalsProps {
  review: IReview;
  reviewApprovals: Array<IReviewApproval>;
  approveMutation: DocumentNode;
  rejectMutation: DocumentNode;
  loading: boolean;
  rejectButtonMode?: RejectButtonMode;
  disableApproveButton?: boolean;
  approverWarningMessage?: string;
}

const ReviewApprovals = ({
  review,
  reviewApprovals,
  approveMutation,
  rejectMutation,
  loading,
  rejectButtonMode = RejectButtonMode.Reject,
  disableApproveButton,
  approverWarningMessage,
}: IReviewApprovalsProps): JSX.Element => {
  const [approve, { loading: approveLoading, error: approveError }] = useMutation(approveMutation);
  const [reject, { loading: rejectLoading, error: rejectError }] = useMutation(rejectMutation);

  const isReviewEditable =
    !approveLoading &&
    !rejectLoading &&
    (review?.status === 'Pending' || review?.status === 'Changes Requested');

  if (!reviewApprovals) {
    return <div>No approvers have been set.</div>;
  }

  if (loading) {
    return <Spinner />;
  }

  const renderSecondaryText = (approval: IReviewApproval): string => {
    let secondaryText = 'Pending';

    if (approval.status.id === ReviewStatus.Accepted) {
      secondaryText = `Approved via ${approval.approver.displayName}`;
    } else if (approval.status.id === ReviewStatus.Rejected) {
      secondaryText = `Rejected via ${approval.approver.displayName}`;
    } else if (approval.status.id === ReviewStatus.ChangesRequested) {
      secondaryText = `Changes Requested via ${approval.approver.displayName}`;
    }

    return secondaryText;
  };

  const onApproveReview = (approval: IReviewApproval): void => {
    approve({
      variables: {
        reviewId: review.id,
        approvalId: approval.id,
      },
    });
  };

  const onRejectReview = (
    approval: IReviewApproval,
    reason?: string,
    callback?: () => void,
  ): void => {
    reject({
      variables: {
        reviewId: review.id,
        approvalId: approval.id,
        reason,
      },
    }).then(() => {
      if (callback) {
        callback();
      }
    });
  };

  return (
    <>
      <Stack horizontal horizontalAlign="space-between">
        <h3>Approvers</h3>
        {(approveLoading || rejectLoading) && <Spinner />}
      </Stack>
      <LoadingErrorMessage
        loading={approveLoading}
        error={approveError}
        actionName="approving this review"
      />
      <LoadingErrorMessage
        loading={rejectLoading}
        error={rejectError}
        actionName="rejecting this review"
      />
      <Stack tokens={{ childrenGap: 30 }}>
        {approverWarningMessage && (
          <MessageBar messageBarType={MessageBarType.warning} isMultiline>
            {approverWarningMessage}
          </MessageBar>
        )}
        {reviewApprovals.map((approval) => {
          const secondaryText = renderSecondaryText(approval);
          let approvedStyle = PersonaPresence.none;
          if (approval.status.id === ReviewStatus.Accepted) {
            approvedStyle = PersonaPresence.online;
          } else if (approval.status.id === ReviewStatus.Rejected) {
            approvedStyle = PersonaPresence.blocked;
          } else if (approval.status.id === ReviewStatus.ChangesRequested) {
            approvedStyle = PersonaPresence.away;
          }
          return (
            <Stack
              key={approval.id}
              tokens={{ childrenGap: 10 }}
              horizontal
              horizontalAlign="space-between"
            >
              <Persona
                text={approval.approvalGroupName}
                coinSize={56}
                secondaryText={secondaryText}
                presence={approvedStyle}
                styles={{ root: { flexBasis: '175px', width: '250px' } }}
              />
              <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                <CanAccess requestedAction={approval.approvalEntityAction}>
                  <PrimaryButton
                    text="Approve"
                    disabled={
                      !isReviewEditable ||
                      approval.status.id === ReviewStatus.Accepted ||
                      disableApproveButton
                    }
                    onClick={(): void => {
                      onApproveReview(approval);
                    }}
                  />
                </CanAccess>
                <CanAccess requestedAction={approval.approvalEntityAction}>
                  <RejectButton
                    disabled={!isReviewEditable || approval.status.id === ReviewStatus.Rejected}
                    onClick={(reason?: string, callback?: () => void): void => {
                      onRejectReview(approval, reason, callback);
                    }}
                    mode={rejectButtonMode}
                    mutationLoading={rejectLoading}
                    mutationError={rejectError}
                  />
                </CanAccess>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default ReviewApprovals;
