import { useMutation, useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { MessageBar } from '@fluentui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';
import { GetCompaniesByFilter } from '../../utils/statApi/CompaniesApi';
import {
  ACTIVATE_COMPANY,
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
} from '../../utils/statApi/CompanyCreationApi';
import ActionButtons from '../common/ActionButtons';
import { DEFAULT_RECORDS_PER_PAGE } from '../common/DetailsLists/pagination/DetailsListPaginationBanner';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { ICompanyCreationParams } from '../common/ParamTypes';
import CompanyRequestInfoCards from './details/CompanyRequestInfoCards';

interface IActivateCompanyReviewPanel {
  closePanel: () => void;
}
const ActivateCompanyReviewPanel = (props: IActivateCompanyReviewPanel): JSX.Element => {
  const { closePanel } = props;
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const messageBarStyle = { root: { marginBottom: '5px' } };

  const { data, error, loading } = useQuery(GET_COMPANY_CREATION_COMPANY_INFO, {
    variables: {
      companyCreationId,
    },
  });
  const [activateCompany, { loading: emailSending, error: emailError }] = useMutation(
    ACTIVATE_COMPANY,
    {
      onCompleted: closePanel,
      refetchQueries: [
        {
          query: GET_COMPANY_CREATION_PROGRESS,
          variables: {
            companyCreationId,
          },
        },
        {
          query: GetCompaniesByFilter,
          variables: {
            companySearchDto: {
              keyword: '',
              companyCodes: [],
              countryCodes: [],
              entityStatusIds: [],
              statutoryYearEndMonths: [],
              statutoryControllerIds: [],
            },
            paginationDto: {
              currentPage: 1,
              pageSize: DEFAULT_RECORDS_PER_PAGE,
            },
          },
        },
      ],
    },
  );

  const handleOnSaveClick = (): void => {
    activateCompany({
      variables: {
        companyRequestId: companyCreationId,
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText="Activate Company"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={emailSending}
          closePanel={closePanel}
          handleSubmit={handleOnSaveClick}
          saveLabel="Activate Company"
          saveTitle="Activate Company"
          cancelLabel="Cancel Activate Company"
          cancelTitle="Cancel Activate Company"
        />
      )}
    >
      <LoadingErrorMessage error={error} loading={loading} />
      <LoadingErrorMessage error={emailError} loading={emailSending} />
      <MessageBar styles={messageBarStyle}>
        Activating this company will make it available in Stat and complete this request. Please
        review the following information.
      </MessageBar>
      <br />
      <CompanyRequestInfoCards
        companyRequestInfo={data?.companyCreationCompanyInfo}
        loading={loading}
        error={error}
        showCurrencyDocument={false}
        showMsSalesLocation={false}
        showShadowCompanyCard={false}
        showStatContactsCard
        showCompanyCreationContactsCard
        showSapSetup={false}
        showIhccSetup={false}
        showSubledger={false}
        showFunctionalCurrency={false}
      />
      <br />
    </CoherencePanel>
  );
};
export default ActivateCompanyReviewPanel;
