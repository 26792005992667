import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  PrimaryButton,
} from '@fluentui/react';
import React, { CSSProperties, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import hasAccess from '../../../utils/authorization/authorizationCheck';
import UserContext from '../../../utils/authorization/UserContext';
import formatCurrency from '../../../utils/CurrencyFormatter';
import momentUtc from '../../../utils/DateFormatter';
import {
  COMPANY_FISCAL_YEAR_DETAIL_QUERY,
  DELETE_DIVIDEND_PAYMENT_MUTATION,
} from '../../../utils/statApi/CompanyFiscalYearsApi';
import { StatUser } from '../../../utils/statApi/UsersApi';
import StatDateFormats from '../../../utils/types/DateFormats';
import ICompanyFiscalYear, {
  IFiscalYearQueryResult,
} from '../../../utils/types/ICompanyFiscalYear';
import CanAccess from '../../common/canAccess/CanAccess';
import Card from '../../common/card/Card';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IFiscalYearParams } from '../../common/ParamTypes';
import { IDividendPayment } from '../serviceContacts/interfaces';
import DividendPaymentEditPanel from './DividendPaymentEditPanel';
import getColumns from './DividendPaymentList.config';
import transformDividendPaymentDto from './DividendPaymentTranslator';

interface IDividendPaymentCardProps {
  dividendPayments: IDividendPayment[];
  parentLoading: boolean;
  dividendsId: number;
}

const DividendPaymentCard = ({
  dividendPayments,
  parentLoading,
  dividendsId,
}: IDividendPaymentCardProps): JSX.Element => {
  const user: StatUser = useContext(UserContext);
  const { fiscalYearId, companyCode } = useParams<IFiscalYearParams>();
  const intFiscalYear = parseInt(fiscalYearId, 10);
  const [isDeleteConfirmHidden, setIsDeleteConfirmHidden] = useState(true);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [dividendPaymentSelected, setDividendPaymentSelected] = useState<IDividendPayment>(null);
  const [deleteDividendPayment, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_DIVIDEND_PAYMENT_MUTATION,
    {
      update(cache, { data }) {
        const deletedPayment = data?.deleteDividendPayment;
        const queryData = cache.readQuery<IFiscalYearQueryResult>({
          query: COMPANY_FISCAL_YEAR_DETAIL_QUERY,
          variables: { companyCode, fiscalYearId: intFiscalYear },
        });

        const companyFiscalYearDetail: ICompanyFiscalYear = queryData?.companyFiscalYearDetail;

        if (companyFiscalYearDetail && deletedPayment) {
          const listWithDeletedGone = companyFiscalYearDetail.dividendPayments.filter(
            (item) => item.id !== deletedPayment.id,
          );
          cache.writeQuery({
            query: COMPANY_FISCAL_YEAR_DETAIL_QUERY,
            variables: { companyCode, fiscalYearId: intFiscalYear },
            data: {
              companyFiscalYearDetail: {
                ...companyFiscalYearDetail,
                dividendPayments: listWithDeletedGone,
              },
            },
          });
        }
      },
    },
  );

  const togglePanel = (): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  const deleteDividendPaymentConfirmClick = (): void => {
    setIsDeleteConfirmHidden(true);
    const submitValue = transformDividendPaymentDto(dividendPaymentSelected);
    deleteDividendPayment({ variables: { dividendPayment: submitValue } });
  };

  const modalPropsStyles = { main: { maxWidth: 450 } };
  const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Are you sure',
    subText: 'Do you want to delete this dividend payment?',
  };

  const hideDeleteDialog = (): void => {
    setIsDeleteConfirmHidden(true);
  };

  const editButtonClick = (item: IDividendPayment): void => {
    setDividendPaymentSelected(item);
    togglePanel();
  };

  const deleteButtonClick = (item: IDividendPayment): void => {
    setDividendPaymentSelected(item);
    setIsDeleteConfirmHidden(false);
  };

  const dividendsColumnStyles = { justifyContent: 'flex-end', display: 'flex' } as CSSProperties;
  const paymentTypeColumn = (item: IDividendPayment): JSX.Element => (
    <span>{item.dividendPaymentType.fieldName}</span>
  );

  const amountColumn = (item: { amount: number; currencyCode: string }): JSX.Element => (
    <span style={dividendsColumnStyles}>{formatCurrency(item.currencyCode, item.amount)}</span>
  );

  const whAmountColumn = (item: { whAmount: number; currencyCode: string }): JSX.Element => (
    <span style={dividendsColumnStyles}>
      {item.whAmount ? formatCurrency(item.currencyCode, item.whAmount) : ''}
    </span>
  );

  const paymentDateColumn = (item: { paymentDate: Date }): JSX.Element => (
    <span>{momentUtc(item.paymentDate, StatDateFormats.DayBreakout)}</span>
  );

  const editButtonColumn = (item: IDividendPayment): JSX.Element => (
    <CanAccess requestedAction="DividendPayment:Edit">
      <IconButton
        text="Edit"
        aria-label="Edit Payment Item"
        iconProps={{ iconName: 'edit' }}
        onClick={() => editButtonClick(item)}
      />
    </CanAccess>
  );

  const deleteButtonColumn = (item: IDividendPayment): JSX.Element => (
    <CanAccess requestedAction="DividendPayment:Delete">
      <IconButton
        text="Delete"
        aria-label="Delete Payment Item"
        iconProps={{ iconName: 'delete' }}
        onClick={() => deleteButtonClick(item)}
      />
    </CanAccess>
  );

  const { permissions } = user;
  const columns = getColumns({
    paymentTypeColumn,
    amountColumn,
    whAmountColumn,
    paymentDateColumn,
    editButtonColumn,
    deleteButtonColumn,
    canEdit: hasAccess(permissions, 'DividendPayment:Edit'),
    canDelete: hasAccess(permissions, 'DividendPayment:Delete'),
  });

  const renderDividendAddButton = (): JSX.Element => {
    return (
      <CanAccess requestedAction="DividendPayment:Add">
        <IconButton
          title="Add Dividend Payment"
          ariaLabel="Add Dividend Payment"
          iconProps={{ iconName: 'Add' }}
          onClick={(): void => {
            setDividendPaymentSelected({
              id: -1,
              dividendId: dividendsId,
              dividendPaymentType: null,
              dividendType: null,
              currencyCode: null,
              amount: null,
              fxRate: null,
              whAmount: null,
              whRate: null,
              paymentDate: null,
            });
            togglePanel();
          }}
        />
      </CanAccess>
    );
  };

  return (
    <Card
      title="Dividend Payment History"
      isLoading={parentLoading}
      actionButton={renderDividendAddButton()}
    >
      <LoadingErrorMessage loading={deleteLoading} error={deleteError} />
      {!parentLoading && dividendPayments.length === 0 && (
        <div>No dividend payment history has been entered yet.</div>
      )}
      {!parentLoading && dividendPayments.length > 0 && (
        <ShimmeredDetailsListWrapper
          columns={columns}
          items={dividendPayments || []}
          enableShimmer={parentLoading}
          singularListLabel="Dividend Payment History"
          pluralListLabel="Dividend Payments"
        />
      )}
      <Dialog
        hidden={isDeleteConfirmHidden}
        onDismiss={hideDeleteDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteDividendPaymentConfirmClick} text="Yes" />
          <DefaultButton onClick={hideDeleteDialog} text="No" />
        </DialogFooter>
      </Dialog>

      {isEditPanelOpen && (
        <DividendPaymentEditPanel
          closePanel={togglePanel}
          dividendPayment={dividendPaymentSelected}
          mode={dividendPaymentSelected?.id > 0 ? 'Edit' : 'Create'}
        />
      )}
    </Card>
  );
};

export default DividendPaymentCard;
