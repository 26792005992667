import { useState, Dispatch, SetStateAction } from 'react';

export enum LocalStorageKeys {
  recentCompanies = 'recentCompanies',
  recentProcesses = 'recentProcesses',
  navIsHidden = 'navIsHidden',
}

export default function useLocalStorage<S>(
  key: string,
  initialValue: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: S | ((prop: S) => S)): void => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // Need to log to telemetry
    }
  };

  return [storedValue, setValue];
}
