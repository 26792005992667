import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_COMPANY_LEGAL_ADDRESS } from '../../../utils/statApi/CompaniesApi';
import { ICompanyLegalAddress } from '../../../utils/types/ICompanyLegalAddress';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CompanyAddressForm from './forms/CompanyAddressForm';

interface ICompanyAddressPanelProps {
  closePanel: () => void;
  data: ICompanyLegalAddress;
}

const CompanyAddressPanel = ({ closePanel, data }: ICompanyAddressPanelProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const form = useForm<ICompanyLegalAddress>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateCompanyLegalAddress, { loading, error }] = useMutation(
    UPDATE_COMPANY_LEGAL_ADDRESS,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: ICompanyLegalAddress): void => {
    updateCompanyLegalAddress({
      variables: {
        companyCode,
        input: {
          id: data.id,
          streetNumber: formData.legallyRegisteredAddress.streetNumber,
          streetNumber2: formData.legallyRegisteredAddress.streetNumber2,
          city: formData.legallyRegisteredAddress.city,
          zipCode: formData.legallyRegisteredAddress.postalZipCode,
          incorporatedCountryCode: formData.legallyRegisteredAddress.country?.id,
          stateProvinceCode: formData.legallyRegisteredAddress.stateProvinceRegion?.id,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Legal Address"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Legal Address Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Legal Address"
          saveTitle="Save Legal Address"
          cancelLabel="Cancel Legal Address"
          cancelTitle="Cancel Legal Address"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <CompanyAddressForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyAddressPanel;
