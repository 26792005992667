import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { UpdateUserLastLoginMutation } from '../../utils/statApi/UsersApi';

const AuthCallback: React.FunctionComponent = () => {
  const [updateUserLastLogin] = useMutation(UpdateUserLastLoginMutation);

  useEffect(() => {
    // update user last login
    updateUserLastLogin();
  }, []);

  const redirectUrl = window.sessionStorage.getItem('return-url') || '/';
  window.sessionStorage.removeItem('return-url');
  return <Redirect to={redirectUrl} />;
};

export default AuthCallback;
