import { DatePicker, Dropdown, IDropdownOption, Stack, Toggle } from '@fluentui/react';
import moment from 'moment';
import React, { useState } from 'react';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';

interface IProcessSearchFiscalYearPickerProps {
  label: string;
  onUpdateDate: (startDate: Date, endDate: Date, selectedDateRangeOption: string) => void;
  initialSelectedOption: string;
  initialStartDate?: Date;
  initialEndDate?: Date;
}
const ProcessSearchFiscalYearPicker = (props: IProcessSearchFiscalYearPickerProps): JSX.Element => {
  const { label, onUpdateDate, initialSelectedOption, initialStartDate, initialEndDate } = props;
  const [showFiscalYear, setShowFiscalYear] = useState(
    initialSelectedOption !== null && initialSelectedOption !== 'customRange',
  );

  const generateArrayOfYears = (min: number, max: number) => {
    const years = [];

    for (let i = max; i >= min; i -= 1) {
      years.push(i);
    }
    return years;
  };
  const mapIntegersToFY = (): IDropdownOption[] => {
    const currentYear = new Date().getFullYear();
    const fiscalYearsOptions = generateArrayOfYears(currentYear - 9, currentYear + 2);
    const companiesTags = fiscalYearsOptions.map((fy) => {
      const fyStartDate = new Date(`${fy - 1}-07-01 12:00`);
      const fyEndDate = new Date(`${fy}-06-30 12:00`);
      return {
        key: fy.toString(),
        text: `FY${String(fy).slice(-2)} - ${moment(momentUtc(fyStartDate)).format(
          StatDateFormats.DaySimple,
        )} - ${moment(momentUtc(fyEndDate)).format(StatDateFormats.DaySimple)}`,
        data: {
          fyStartDate,
          fyEndDate,
        },
      };
    });
    companiesTags.unshift({
      key: 'customRange',
      text: '--',
      data: { fyStartDate: new Date(), fyEndDate: new Date() },
    });
    return companiesTags;
  };
  const datePickerStyles = { root: { width: 150 } };
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <strong>{label}</strong>
      {!showFiscalYear && (
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <DatePicker
            label="From"
            value={initialStartDate}
            onSelectDate={(date) => {
              onUpdateDate(date, initialEndDate, 'customRange');
            }}
            allowTextInput
            styles={datePickerStyles}
          />
          <DatePicker
            label="To"
            value={initialEndDate}
            onSelectDate={(date) => {
              onUpdateDate(initialStartDate, date, 'customRange');
            }}
            allowTextInput
            styles={datePickerStyles}
          />
        </Stack>
      )}
      {showFiscalYear && (
        <Dropdown
          placeholder="Select a fiscal year"
          label="Fiscal Year"
          ariaLabel="Select a fiscal year"
          selectedKey={initialSelectedOption || 'customRange'}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption,
            index?: number,
          ) => {
            if (option?.key !== 'customRange') {
              const selectedStartDate = option.data.fyStartDate;
              const selectedEndDate = option.data.fyEndDate;
              onUpdateDate(selectedStartDate, selectedEndDate, option.key as string);
            } else {
              onUpdateDate(null, null, null);
            }
          }}
          options={mapIntegersToFY()}
        />
      )}
      <Toggle
        label="Show fiscal year selector"
        inlineLabel
        onText="On"
        offText="Off"
        defaultChecked={showFiscalYear}
        onChange={() => {
          const selectedOption = showFiscalYear ? 'customRange' : null;
          onUpdateDate(initialStartDate, initialEndDate, selectedOption);
          setShowFiscalYear(!showFiscalYear);
        }}
      />
    </Stack>
  );
};
export default ProcessSearchFiscalYearPicker;
