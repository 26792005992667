import {
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
  AccountInfo,
  InteractionRequiredAuthError,
  RedirectRequest,
  EndSessionRequest,
} from '@azure/msal-browser';
import { getLoginRequest, getMsalConfig } from '../AuthConfig';

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
  private clientApp: PublicClientApplication;

  private account: AccountInfo;

  constructor() {
    this.clientApp = new PublicClientApplication(getMsalConfig());
    this.account = null;
  }

  public getAccount(): AccountInfo {
    if (this.account) {
      return this.account;
    }

    const accounts = this.clientApp.getAllAccounts();

    if (accounts.length > 0) {
      return accounts[0] || null;
    }

    return null;
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  loadAuthModule(setIsLoggedIn: (value: boolean) => void): void {
    this.clientApp.handleRedirectPromise().then((resp: AuthenticationResult) => {
      this.handleResponse(resp, setIsLoggedIn);
    });
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  handleResponse(response: AuthenticationResult, setIsLoggedIn: (value: boolean) => void): void {
    if (response !== null) {
      this.account = response.account;
      setIsLoggedIn(true);
    } else if (this.getAccount() === null) {
      window.sessionStorage.setItem(
        'return-url',
        window.location.pathname + window.location.search,
      );
      this.login();
    } else {
      this.account = this.getAccount();
      setIsLoggedIn(true);
    }
  }

  login(): void {
    this.clientApp.loginRedirect(getLoginRequest());
  }

  logout(): void {
    const logOutRequest: EndSessionRequest = {
      account: this.getAccount(),
    };

    this.clientApp.logout(logOutRequest);
  }

  getInstance(): AuthModule {
    return this;
  }

  public async acquireToken(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest,
  ): Promise<string> {
    try {
      const account = this.getAccount();
      const request = { ...silentRequest, account };
      const response = await this.clientApp.acquireTokenSilent(request);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        this.clientApp.acquireTokenRedirect(interactiveRequest);
      }
    }

    return null;
  }
}

export const msalInstance = new AuthModule();
