import { useContext, useEffect, useRef } from 'react';
import NotificationContext from '../../components/notifications/NotificationContext';
import { Toast } from '../../components/notifications/StatNotification';

const useToast = (): { toasts: Toast[]; createToast: (toastItem: Toast) => void } => {
  const { toasts, setToasts } = useContext(NotificationContext);
  const toastRef = useRef(toasts);
  toastRef.current = toasts;

  useEffect(() => {
    if (toasts && toasts.length > 0 && toasts[0]?.className === 'ms-motion-slideUpOut') {
      const removeToast = setTimeout(() => {
        const toastWithRemoved = toasts.slice(1);
        setToasts(toastWithRemoved);
      }, 100);
      return () => clearTimeout(removeToast);
    }
    return () => {};
  }, [toasts]);

  const createToast = (toastItem: Toast) => {
    if (toasts.length < 3) {
      setToasts([...toasts, toastItem]);
    }
    setTimeout(() => {
      const without = toastRef.current.filter((toast) => toast.id !== toastItem.id);
      const toastToRemove = toastRef.current[0];
      if (toastToRemove) {
        toastToRemove.className = 'ms-motion-slideUpOut';
      }

      setToasts([toastToRemove, ...without]);
    }, 7000);
  };

  return { toasts, createToast };
};

export default useToast;
