import React from 'react';
import { ColumnActionsMode, IColumn, IDropdownOption, Stack } from '@fluentui/react';
import { M365LightTheme } from '@m365-admin/customizations';
import { SubwayNavNodeState } from '@m365-admin/wizard';
import moment from 'moment';
import MessageWithTooltip from '../../../common/MessageWithToolTip';
import momentUtc from '../../../../utils/DateFormatter';
import StatDateFormats from '../../../../utils/types/DateFormats';
import { IProcessTemplateVersionMilestone } from '../../../../utils/types/IProcessConfig';

const { theme } = M365LightTheme.settings;
export enum NewProcessCreationSteps {
  Details = 'ProcessConfigurationDetails',
  Milestones = 'ProcessConfigurationMilestones',
  Review = 'ProcessConfigurationReview',
}
const stepStyles = {
  label: {
    whiteSpace: 'normal',
  },
  labelSelected: {
    whiteSpace: 'normal',
  },
};
export const processConfigWizardSteps = [
  {
    id: NewProcessCreationSteps.Details,
    label: 'Configuration Details',
    state: SubwayNavNodeState.Current,
    disabled: false,
    theme,
    styles: stepStyles,
  },
  {
    id: NewProcessCreationSteps.Milestones,
    label: 'Edit Milestones',
    state: SubwayNavNodeState.NotStarted,
    disabled: true,
    theme,
    styles: stepStyles,
  },
  {
    id: NewProcessCreationSteps.Review,
    label: 'Review and Create',
    state: SubwayNavNodeState.NotStarted,
    disabled: true,
    theme,
    styles: stepStyles,
  },
];

export const recurrenceOptions: IDropdownOption[] = [
  { key: 'ONETIME', text: 'One Time' },
  { key: 'WEEKLY', text: 'Weekly' },
  { key: 'MONTHLY', text: 'Monthly' },
  { key: 'QUARTERLY', text: 'Quarterly' },
  { key: 'SEMIANNUALLY', text: 'Semiannually' },
  { key: 'ANNUALLY', text: 'Annually' },
  { key: 'BIANNUALLY', text: 'Biannually' },
];

export const milestoneListColumns: Array<IColumn> = [
  {
    key: 'assigneeOptions',
    name: 'Milestone Name and Assignee',
    fieldName: 'assigneeOptions',
    minWidth: 280,
    maxWidth: 440,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */

  {
    key: 'duration',
    name: (
      <MessageWithTooltip
        label="Duration"
        tooltipMessage="Duration is calculated in business days"
      />
    ) as any,
    fieldName: 'duration',
    minWidth: 200,
    maxWidth: 200,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'defaultDaysOffset',
    name: (
      <MessageWithTooltip
        label="Days from Due Date"
        tooltipMessage="Days from Due Date is calculated in business days"
      />
    ) as any,
    fieldName: 'defaultDaysOffset',
    minWidth: 200,
    maxWidth: 200,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
  },

  /* eslint-enable  @typescript-eslint/no-explicit-any */
];

export const reportingPeriodEndDateMaxDate = (filingDueDate: string): Date => {
  if (filingDueDate) {
    return moment(momentUtc(filingDueDate)).add(-1, 'day').toDate();
  }
  return null;
};
export const filingDueDateMinDate = (reportingPeriodEndDate: string): Date => {
  if (reportingPeriodEndDate) {
    return moment(momentUtc(reportingPeriodEndDate)).add(1, 'day').toDate();
  }
  return null;
};

export interface IAnnualFinancialStatementDefaultDates {
  auditFilingDueDate: string;
  statutoryYearEnd: string;
  hasDefaultCompanyAFSTemplate: boolean;
}

export const filingDueDateDefaultDate = (
  auditFilingDueDate: string,
  statutoryYearEnd: string,
): string => {
  let auditFilingYear = new Date().getUTCFullYear();
  if (
    moment(`${statutoryYearEnd}/${auditFilingYear}`).isSameOrAfter(
      `${auditFilingDueDate}/${auditFilingYear}`,
    )
  ) {
    auditFilingYear += 1;
  }
  const auditFilingDate = moment(`${auditFilingDueDate}/${auditFilingYear}`).utc(true).format();
  return auditFilingDate;
};
export const reportingPeriodEndDateDefaultDate = (statutoryYearEnd: string): string => {
  const currentYear = new Date().getUTCFullYear();
  const statutoryYearEndDate = moment(`${statutoryYearEnd}/${currentYear}`).utc(true).format();

  return statutoryYearEndDate;
};
export const previewMilestoneListColumns = [
  {
    key: 'milestoneName',
    name: 'Milestone Name',
    fieldName: 'milestoneTemplateName',
    minWidth: 175,
    maxWidth: 300,
    isRowHeader: false,
    data: 'string',
    isPadded: true,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: IProcessTemplateVersionMilestone): JSX.Element => {
      const idToDisplay = item.globalAssigneeId !== null ? item.globalAssigneeId : item.assigneeId;
      const titleStyle = { fontWeight: 500 };
      return (
        <Stack>
          <div style={titleStyle}>
            <span>{item.milestoneTemplateName}</span>
          </div>
          <span>
            {
              item.assigneeOptions?.find((x: { id: number; name: string }) => x.id === idToDisplay)
                ?.name
            }
          </span>
        </Stack>
      );
    },
  },
  {
    key: 'dueDate',
    name: 'Due Date',
    fieldName: 'dueDate',
    minWidth: 125,
    maxWidth: 200,
    isRowHeader: false,
    data: 'int',
    isPadded: true,
    onRender: (item: IProcessTemplateVersionMilestone): string => {
      return momentUtc(item.dueDate, StatDateFormats.DayBreakout);
    },
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'duration',
    name: 'Duration',
    fieldName: 'duration',
    minWidth: 100,
    maxWidth: 150,
    isRowHeader: false,
    data: 'int',
    isPadded: true,
    onRender: (item: IProcessTemplateVersionMilestone): string | number => {
      return item.duration || '--';
    },
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'daysFromDueDate',
    name: 'Days from Due Date',
    fieldName: 'daysFromDueDate',
    minWidth: 100,
    maxWidth: 150,
    isRowHeader: false,
    data: 'int',
    isPadded: true,
    onRender: (item: { defaultDaysOffset: number }): string => {
      return item.defaultDaysOffset === 0 ? '--' : Math.abs(item.defaultDaysOffset).toString();
    },
    columnActionsMode: ColumnActionsMode.disabled,
  },
];

export interface IProcessConfigWizardFormText {
  formTitle: string;
  saveButtonText: string;
  saveButtonCancelText: string;
  readyMessage: string;
  processCountLabel: string;
}

export const getWizardText = (edit: boolean): IProcessConfigWizardFormText => {
  const text: IProcessConfigWizardFormText = {
    formTitle: edit ? 'Edit ' : 'Create New ',
    saveButtonText: edit ? 'Save and Update' : 'Save and Create',
    saveButtonCancelText: edit ? 'Cancel Process Config Edit' : 'Cancel Process Config Creation',
    readyMessage: edit ? 'to be updated' : 'for creation',
    processCountLabel: edit
      ? 'Number of Existing Future Processes To Be Modified'
      : 'Number of Processes To Be Created',
  };

  return text;
};
