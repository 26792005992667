import { IPivotHeaderItems } from './IPivotHeaderItems';

const CompanyHeaderItems: IPivotHeaderItems = {
  processes: {
    headerText: 'Processes',
    iconName: 'ServerProcesses',
  },
  'company-information': {
    headerText: 'Company Information',
    iconName: 'Info',
  },
  'annual-filing-requirements': {
    headerText: 'Annual Filing Requirements',
    iconName: 'OpenFolderHorizontal',
  },
  'fiscal-years': {
    headerText: 'Fiscal Year Summary',
    iconName: 'Calendar',
  },
  'service-contacts': {
    headerText: 'Service Contacts',
    iconName: 'ContactCard',
  },
  documents: {
    headerText: 'Documents',
    iconName: 'TextDocument',
  },
};
export default CompanyHeaderItems;
