import { gql } from '@apollo/client';

const GET_AUDIT_OPINION_QUERY = gql`
  {
    auditOpinions {
      id
      fieldName
    }
  }
`;
export default GET_AUDIT_OPINION_QUERY;
