import { useQuery } from '@apollo/client';
import { Dropdown, IDropdownOption, Separator, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cardContentWrapperStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_MILESTONE_DOCUMENTS,
  GET_COMPANY_DOCUMENT_FILTERS,
} from '../../../utils/statApi/CompaniesApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import {
  multiDropdownLargeWrapper,
  multiDropdownMediumWrapper,
  multiDropdownSmallWrapper,
} from './CompanyDocumentStyles';
import DocumentsList from '../../common/documents/DocumentsList';
import ICompanyMilestoneDocument from '../../../utils/types/ICompanyMilestoneDocument';
import {
  getInitialPageSize,
  getTotalPageCount,
} from '../../common/DetailsLists/pagination/DetailsListPaginationBanner';
import IPaginationMetadata from '../../common/DetailsLists/pagination/IPaginationMetadata';

interface IMilestoneDocumentsProps {
  showDeletedFiles: boolean;
}

const MilestoneDocuments = ({ showDeletedFiles }: IMilestoneDocumentsProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const [fiscalYears, setFiscalYears] = useState([]);
  const [processIds, setProcessIds] = useState([]);
  const [processTypeIds, setProcessTypeIds] = useState([]);
  const [milestoneNameIds, setMilestoneNameIds] = useState([]);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [defaultPageSize, setDefaultPageSize] = useState<number>(getInitialPageSize());

  const { data, loading, error } = useQuery(GET_COMPANY_MILESTONE_DOCUMENTS, {
    variables: {
      paginationDto: {
        currentPage: selectedPage,
        pageSize: defaultPageSize,
      },
      companyCode,
      fiscalYears,
      processIds,
      processTypeIds,
      milestoneNameIds,
      showDeletedFiles,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: filterData,
    loading: filterLoading,
    error: filterError,
  } = useQuery(GET_COMPANY_DOCUMENT_FILTERS, {
    variables: { companyCode, fiscalYears, processIds, processTypeIds },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setSelectedPage(1);
  }, [fiscalYears, processIds, processTypeIds, milestoneNameIds, showDeletedFiles]);

  const companyDocuments: ICompanyMilestoneDocument[] = data?.companyMilestoneDocuments.items || [];
  const pageCount =
    !loading && !error
      ? getTotalPageCount(data?.companyMilestoneDocuments?.totalCount, defaultPageSize)
      : 1;
  const companyDocumentFiscalYearFilters: IDropdownOption[] =
    filterData?.companyDocumentFilters?.companyDocumentFiscalYearFilters || [];

  const companyDocumentProcessFilters: IDropdownOption[] =
    filterData?.companyDocumentFilters?.companyDocumentProcessFilters || [];

  const companyDocumentProcessTypeFilters: IDropdownOption[] =
    filterData?.companyDocumentFilters?.companyDocumentProcessTypeFilters || [];

  const companyDocumentMilestoneNameFilters: IDropdownOption[] =
    filterData?.companyDocumentFilters?.companyDocumentMilestoneNameFilters || [];

  const paginationMetadata: IPaginationMetadata = {
    pageCount,
    selectedPage,
    pageSize: defaultPageSize,
    loadingData: false,
    onPageChange: (startItemIndex: number, endItemIndex: number, newPageNumber: number): void => {
      setSelectedPage(newPageNumber);
    },
    onPageSizeChange: (newPageSize: string | number): void => {
      setDefaultPageSize(newPageSize as number);
    },
  };

  return (
    <div className={cardContentWrapperStyles}>
      <Separator />
      <Stack tokens={{ childrenGap: 10 }} horizontal wrap>
        <LoadingErrorMessage error={filterError} loading={filterLoading} />
        <Dropdown
          label="Fiscal Year"
          className={multiDropdownSmallWrapper}
          onChange={(event, item): void => {
            if (item) {
              setFiscalYears(
                item.selected
                  ? [...fiscalYears, item.id]
                  : fiscalYears.filter((id) => id !== item.id),
              );
              setProcessTypeIds([]);
            }
          }}
          selectedKeys={fiscalYears}
          multiSelect
          placeholder="Select an option"
          options={companyDocumentFiscalYearFilters}
        />

        <Dropdown
          label="Process Type"
          className={multiDropdownMediumWrapper}
          onChange={(event, item): void => {
            setProcessTypeIds(
              item.selected
                ? [...processTypeIds, item.id]
                : processTypeIds.filter((id) => id !== item.id),
            );
            setProcessIds([]);
          }}
          selectedKeys={processTypeIds}
          multiSelect
          placeholder="Select an option"
          options={companyDocumentProcessTypeFilters}
        />

        <Dropdown
          label="Process"
          className={multiDropdownSmallWrapper}
          onChange={(event, item): void => {
            setProcessIds(
              item.selected ? [...processIds, item.id] : processIds.filter((id) => id !== item.id),
            );
            setMilestoneNameIds([]);
          }}
          selectedKeys={processIds}
          multiSelect
          placeholder="Select an option"
          options={companyDocumentProcessFilters}
        />

        <Dropdown
          label="Milestone Name"
          className={multiDropdownLargeWrapper}
          onChange={(event, item): void => {
            setMilestoneNameIds(
              item.selected
                ? [...milestoneNameIds, item.id]
                : milestoneNameIds.filter((id) => id !== item.id),
            );
          }}
          selectedKeys={milestoneNameIds}
          multiSelect
          placeholder="Select an option"
          options={companyDocumentMilestoneNameFilters}
        />
      </Stack>
      <br />
      <DocumentsList
        loading={loading}
        error={error}
        docType="Milestone"
        documents={companyDocuments}
        showDeletedFiles={showDeletedFiles}
        paginationData={paginationMetadata}
      />
    </div>
  );
};

export default MilestoneDocuments;
