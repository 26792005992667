import { IDocument } from '../../../utils/statApi/DocumentApi';
import { IAddress } from '../../../utils/types/IAddress';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import { IServiceContact } from '../../companies/serviceContacts/interfaces';

export default interface ICashFlow {
  id: number;
  cashFlowCurrency: string;
  cashFlowCurrencyNotes: string;
  cashFlowAffectsParent: boolean | string;
  cashFlowAffectsParentNotes: string;
}

export interface IFunctionalCurrency {
  id: number;
  functionalCurrency: string;
  statutoryCurrency: string;
  functionalCurrencyDocument: IFunctionalCurrencyDocument;
}

export interface IFunctionalCurrencyDocument extends IDocument {
  companyCreationCurrencyId: number;
  message?: string;
}

export interface ISapSetupInfo {
  id?: number;
  region?: IRegion;
  taxJurisdictionCode: string;
  msReportingSalesYear: number;
  msReportingSalesLocation: ISalesLocation;
}

export interface ICompanyCode {
  companyCode: number;
}

export interface ISalesLocation {
  id: string;
  name: string;
}

export interface IRegion {
  id: string;
  name: string;
}

export interface ILegalDocumentInfo {
  id?: number;
  fullLegalName?: string;
  sapLegalName?: string;
  abbreviatedSapName?: string;
  statutoryYearEndMonth?: number;
  statutoryYearEndDay?: number;
  legallyRegisteredAddress?: IAddress;
  physicalAddress?: IAddress;
  incorporatedDate?: Date;
}

export interface ISAPProgressInfo {
  functionalCurrency: string;
  statutoryCurrency: string;
  functionalCurrencyDocument: IFunctionalCurrencyDocument;

  fullLegalName?: string;
  abbreviatedSapName?: string;
  statutoryYearEndMonth?: number;
  statutoryYearEndDay?: number;
  legallyRegisteredAddress?: IAddress;
  physicalAddress?: IAddress;
  incorporatedDate?: Date;

  region?: IRegion;
  taxJurisdictionCode: string;
  msReportingSalesYear: number;
  msReportingSalesLocation: ISalesLocation;

  inHouseCashCenterTier: IInHouseCashCenterTier;
  isIncludedInInHouseCashCenter: string;
  inHouseCashCenterAccountCurrency: string;
  inHouseCashCenterPostingMode: string;

  isShadowCompanyNeeded: string;
  name: string;
}

export interface IStatProgressInfo {
  companyCode: number;
  oversightGroup?: IDropdownReference;
  isMcaps?: string;
  isOneFinance?: string;
  auditingFirm?: IDropdownReference;
  otherAuditingFirm?: string;
  isFsAuditLegallyRequired?: string;
  einTaxNumber?: string;
  controllerMustBeFte?: string;

  statutoryController: Array<ICompanyCreationContact>;
  regionalControllers: Array<ICompanyCreationContact>;
  fsTaxRepContacts: Array<ICompanyCreationContact>;
  treasuryRepContacts: Array<ICompanyCreationContact>;
  bpoContacts: Array<ICompanyCreationContact>;

  fullLegalName?: string;
  abbreviatedSapName?: string;
  statutoryYearEndMonth?: number;
  statutoryYearEndDay?: number;
  legallyRegisteredAddress?: IAddress;
  physicalAddress?: IAddress;

  isShadowCompanyNeeded: string;
  shadowCompanyCode: number;
}

export interface IInHouseCashCenterSetup {
  id?: number;
  inHouseCashCenterTier: IInHouseCashCenterTier;
  isIncludedInInHouseCashCenter: string;
  inHouseCashCenterAccountCurrency: string;
  inHouseCashCenterPostingMode: string;
}

export interface IInHouseCashCenterTier {
  id: number;
  name: string;
}

export interface IStatutoryInformation {
  id: number;
  companyCreationRequestId: number;
  oversightGroup?: IDropdownReference;
  isMcaps?: boolean;
  isOneFinance?: boolean;
  auditingFirm?: IDropdownReference;
  otherAuditingFirm?: string;
  isFsAuditLegallyRequired?: boolean;
  einTaxNumber?: string;
  controllerMustBeFte?: boolean;
}

export interface IAuditingFirm {
  id: string;
  fieldName: string;
}

export interface IShadowCompany {
  id: number;
  companyCreationRequestId: number;
  name?: string;
  isShadowCompanyNeeded: string;
}

export interface ICompanyCreationInfo {
  functionalCurrency: IFunctionalCurrency;
  legalDocumentInfo: ILegalDocumentInfo;
  sapSetupInfo: ISapSetupInfo;
  shadowCompanyInfo: IShadowCompany;
  inHouseCashCenterSetup: IInHouseCashCenterSetup;
  statutoryInformation: IStatutoryInformation;
  statutoryContacts: IStatutoryContacts;
  subledgerInfo: ISubledgerInfo;
}

export interface ICompanyCreationContact extends IServiceContact {
  id?: string;
  contactType: string;
}

export interface IStatutoryContacts {
  companyCreationRequestId: number;
  statutoryController: Array<ICompanyCreationContact>;
  regionalControllers: Array<ICompanyCreationContact>;
  fsTaxRepContacts: Array<ICompanyCreationContact>;
  treasuryRepContacts: Array<ICompanyCreationContact>;
  alternativeContacts: Array<ICompanyCreationContact>;
  bpoContacts: Array<ICompanyCreationContact>;
}

export enum ContactType {
  StatutoryController = 'STATUTORYCONTROLLER',
  RegionalController = 'REGIONALCONTROLLER',
  TreasurySupportRep = 'TREASURYSUPPORTREP',
  TaxSupportRep = 'TAXSUPPORTREP',
  GeneralManager = 'GENERALMANAGER',
  VpPresidentDirector = 'VPPRESIDENTDIRECTOR',
  DavaDealLead = 'DAVADEALLEAD',
  BpoContact = 'BPOCONTACT',
  FinOpsContact = 'FINOPSCONTACT',
  CompanyUser = 'COMPANYUSER',
}

export enum CompanyCreationContactType {
  StatutoryController = 'STATUTORYCONTROLLER',
  RegionalController = 'REGIONALCONTROLLER',
  TreasurySupportRep = 'TREASURYSUPPORTREP',
  TaxSupportRep = 'TAXSUPPORTREP',
  AlternativeContact = 'ALTERNATIVECONTACT',
  BpoContact = 'BPOCONTACT',
  BsReconOwner = 'BSRECONOWNER',
  BsReconReviewer = 'BSRECONREVIEWER',
  BsReconController = 'BSRECONCONTROLLER',
}

export enum RequiredFieldsType {
  SapSetup,
  StatSetup,
  SubledgerSetup,
}

export interface IRequiredItem {
  key: string;
  value: string;
}

export interface ISubledgerInfo {
  id?: number;
  isDomesticEntity: boolean;
  willHaveIntercompanyActivity: boolean;
  willRequireMscPremierServices: boolean;
  willHaveHeadcount: boolean;
  personalSubareaCities: string;
  weeklyWorkingHours: number;
  willNeedFaModule: boolean;
  willNeedApMmModule: boolean;
  willTransmitToMsSales: boolean;
  bsReconOwner: ICompanyCreationContact;
  bsReconReviewer: ICompanyCreationContact;
  bsReconController: ICompanyCreationContact;
}

export interface IShadowCompanyCode {
  shadowCompanyCode: number;
}
export const SHADOW_COMPANY_NAME_SUFFIX = '-SHDW';
