import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
  NeutralColors,
  ScrollablePane,
  Separator,
  TextField,
  mergeStyleSets,
} from '@fluentui/react';
import {
  IAgmTrackerItemInput,
  IAgmTrackerPageTrackerSearchResult,
} from '../../annualFilingRequirements/IAgmListPageSearchResult';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import ActionButtons from '../../../common/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import AgmTrackerItemHistory from './AgmTrackerItemHistory';
import {
  GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY,
  UPDATE_AGM_TRACKER_ITEM,
  UPDATE_AGM_TRACKER_ITEM_BULK,
} from '../../../../utils/statApi/CompanyFiscalYearsApi';

interface IAgmTrackerCommentsPanelProps {
  closePanel: () => void;
  refetchTrackerList: () => void;
  agmTrackerItems?: IAgmTrackerPageTrackerSearchResult[];
  ownerFilter: string;
}

const styles = mergeStyleSets({
  scrollablePaneContainer: {
    height: '75vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  form: {
    paddingRight: '20px',
  },
  separator: {
    root: {
      selectors: {
        '&:before': {
          background: NeutralColors.gray20,
        },
      },
    },
  },
});

const AgmTrackerCommentsPanel = ({
  closePanel,
  refetchTrackerList,
  agmTrackerItems,
  ownerFilter,
}: IAgmTrackerCommentsPanelProps): JSX.Element => {
  const form = useForm<IAgmTrackerItemInput>();
  const { handleSubmit, control } = form;

  const isBulkEdit = agmTrackerItems?.length > 1;

  const [updateAgmTrackerItem, { loading, error }] = useMutation(UPDATE_AGM_TRACKER_ITEM, {
    onCompleted: () => {
      refetchTrackerList();
      closePanel();
    },
    refetchQueries: [
      {
        query: GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY,
        variables: {
          owner: ownerFilter,
        },
      },
    ],
  });

  const [updateBulkAgmTrackerItems, { loading: bulkLoading, error: bulkError }] = useMutation(
    UPDATE_AGM_TRACKER_ITEM_BULK,
    {
      onCompleted: () => {
        refetchTrackerList();
        closePanel();
      },
      refetchQueries: [
        {
          query: GET_AGM_TRACKER_COUNT_BY_STATUS_QUERY,
          variables: {
            owner: ownerFilter,
          },
        },
      ],
    },
  );

  const handleSaveClick = (formData: IAgmTrackerItemInput): void => {
    if (!isBulkEdit) {
      updateAgmTrackerItem({
        variables: {
          input: {
            trackerId: agmTrackerItems[0].trackerId,
            comment: formData.comment ?? null,
          },
        },
      });
    } else {
      updateBulkAgmTrackerItems({
        variables: {
          input: {
            trackerIds: agmTrackerItems?.map((tracker) => tracker.trackerId),
            comment: formData.comment ?? null,
          },
        },
      });
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={isBulkEdit ? 'Bulk AGM Tracker Comments' : 'AGM Tracker Comments'}
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close AGM Tracker Comments"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel={isBulkEdit ? 'Save AGM Tracker Comments' : 'Save AGM Tracker Comment'}
          saveTitle={isBulkEdit ? 'Save AGM Tracker Comments' : 'Save AGM Tracker Comment'}
          cancelLabel="Cancel AGM Tracker Comments"
          cancelTitle="Cancel AGM Tracker Comments"
        />
      )}
    >
      <div className={styles.scrollablePaneContainer}>
        <ScrollablePane>
          <form className={styles.form}>
            <LoadingErrorMessage error={error || bulkError} loading={loading || bulkLoading} />
            <Controller
              as={TextField}
              id="comment"
              name="comment"
              label="Add Comment"
              control={control}
              multiline
            />

            {!isBulkEdit && (
              <>
                <Separator className={styles.separator} />
                <AgmTrackerItemHistory actionItems={agmTrackerItems[0]?.agmTrackerActions} />
              </>
            )}
          </form>
        </ScrollablePane>
      </div>
    </CoherencePanel>
  );
};

export default AgmTrackerCommentsPanel;
