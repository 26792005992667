import { CoherenceTreeItem, CoherenceTreeView } from '@coherence-design-system/controls';
import { MessageBar, MessageBarType, NeutralColors, SearchBox, Stack } from '@fluentui/react';
import React from 'react';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../app/common/constants/SiteConstants';
import { bodyContentContainer, searchBoxStyles } from '../../app/common/styles/CommonStyleObjects';

interface ITreeView {
  treeItems: CoherenceTreeItem[];
  children: JSX.Element;
  navSectionHeader?: JSX.Element;
  commandBarSection?: JSX.Element;
  showSearchBox?: boolean;
  handleOnChangeSearch?: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void;
}
const TreeView = ({
  treeItems,
  children,
  navSectionHeader,
  commandBarSection,
  showSearchBox,
  handleOnChangeSearch,
}: ITreeView): JSX.Element => {
  const mainSectionStyles = {
    root: {
      width: 320,
      minWidth: 320,
      background: NeutralColors.gray10,
    },
  };

  const detailsSectionStyles = {
    root: {
      background: NeutralColors.white,
    },
  };
  const childrenWrapperStyles = {
    root: { width: '100%', padding: 30 },
  };
  const mobileViewStyles = {
    root: {
      selectors: {
        [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
          flexFlow: 'column',
        },
      },
    },
  };

  return (
    <div className={`${bodyContentContainer}  ms-depth-4`}>
      <Stack horizontal styles={mobileViewStyles}>
        <Stack styles={mainSectionStyles}>
          {navSectionHeader}
          {showSearchBox && (
            <SearchBox
              ariaLabel="search actions"
              styles={searchBoxStyles}
              placeholder="Search actions"
              onChange={handleOnChangeSearch}
            />
          )}
          {treeItems.length === 0 && (
            <MessageBar messageBarType={MessageBarType.info}>
              <span>No results found. Please try again.</span>
            </MessageBar>
          )}
          <CoherenceTreeView data={treeItems} />
        </Stack>
        <Stack grow styles={detailsSectionStyles}>
          {commandBarSection}
          <Stack styles={childrenWrapperStyles}>{children}</Stack>
        </Stack>
      </Stack>
    </div>
  );
};
export default TreeView;
