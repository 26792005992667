import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page from '../../common/page/Page';
import MilestoneCategoriesListPage from './categories/MilestoneCategoriesListPage';
import ProcessTemplateMilestoneEdit from './milestoneEdit/ProcessTemplateMilestoneEdit';
import ProcessTemplatesListPage from './ProcessTemplatesListPage';
import ProcessTemplatesTypesListPage from './ProcessTemplatesTypesListPage';
import ProcessTypeMilestonePool from './processTypeMilestones/ProcessTypeMilestonePool';

const ProcessTemplateRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Page
        path="/process-templates"
        exact
        component={ProcessTemplatesTypesListPage}
        title="Template Management"
      />
      <Page
        exact
        path="/process-templates/:processTypeId/template/:processTemplateId/edit"
        component={ProcessTemplateMilestoneEdit}
        title="Edit Process Template"
      />
      <Page
        exact
        path="/process-templates/:processTypeId/template/:processTemplateId"
        component={ProcessTemplatesListPage}
        title="Process Templates"
      />
      <Route path="/process-templates/:processTypeId" exact component={ProcessTemplatesListPage} />
      <Page
        path="/process-templates/:processTypeId/pool"
        exact
        component={ProcessTypeMilestonePool}
        title="Milestone Pool"
      />
      <Page
        path="/process-templates/:processTypeId/categories"
        exact
        component={MilestoneCategoriesListPage}
        title="Milestone Categories"
      />
    </Switch>
  );
};

export default ProcessTemplateRoute;
