import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page from '../common/page/Page';
import CompanyCreationListPage from './CompanyCreationListPage';
import CompanyRequestDetailPage from './details/CompanyRequestDetailPage';

const CompanyCreationRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Page
        path="/company-creation"
        exact
        component={CompanyCreationListPage}
        title="Company Creation"
      />
      <Route path="/company-creation/:id" component={CompanyRequestDetailPage} />
    </Switch>
  );
};

export default CompanyCreationRoute;
