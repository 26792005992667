import { useQuery } from '@apollo/client';
import { IconButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import { GET_FUNCTIONAL_CURRENCY } from '../../../utils/statApi/CompanyCreationApi';
import Card from '../../common/card/Card';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import SpanWithLabel from '../../common/SpanWithLabel';
import StackColumn from '../../common/StackColumn';
import { FunctionalCurrencyForm } from '../types';
import CashFlowEditPanel from './functionalCurrencyForms/CashFlowEditPanel';

interface IFunctionalCurrencyPageState {
  isEditPanelOpen: boolean;
  currentForm?: FunctionalCurrencyForm;
}

const FunctionalCurrencyPage: React.FunctionComponent = (): JSX.Element => {
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const [panelState, setPanelState] = useState<IFunctionalCurrencyPageState>({
    isEditPanelOpen: false,
    currentForm: null,
  });
  const { data, error, loading } = useQuery(GET_FUNCTIONAL_CURRENCY, {
    variables: {
      companyCreationId,
    },
  });

  const closePanel = (): void => {
    setPanelState({ isEditPanelOpen: false, currentForm: null });
  };

  const renderActionButton = (
    label: string,
    formType: FunctionalCurrencyForm,
    requestedAction?: string,
  ): JSX.Element => {
    return (
      <>
        {/* <CanAccess requestedAction={requestedAction}> */}
        <IconButton
          title={`Edit ${label}`}
          ariaLabel={label}
          iconProps={{ iconName: 'Edit' }}
          onClick={(): void => {
            setPanelState({
              isEditPanelOpen: true,
              currentForm: formType,
            });
          }}
        />
        {/* </CanAccess> */}
      </>
    );
  };

  const functionalCurrencyData = data?.functionalCurrency;

  return (
    <div className={`${bodyContentContainer} lightBackground`}>
      <LoadingErrorMessage error={error} loading={loading} />
      <Card
        title="Cash Flows"
        headingLevel={2}
        actionButton={renderActionButton('Cash Flows', FunctionalCurrencyForm.CashFlows)}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="What are the cash flows of the entity's assets and liabilities in?"
              value={functionalCurrencyData?.cashFlowCurrency}
            />
            <SpanWithLabel
              labelText="Do these cash flows directly affect the parent entity's cash flows and are they readily available for remittance to the parent entity?"
              value={functionalCurrencyData?.cashFlowAffectsParent}
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel
              labelText="Notes"
              value={functionalCurrencyData?.cashFlowCurrencyNotes}
            />
            <SpanWithLabel
              labelText="Notes"
              value={functionalCurrencyData?.cashFlowAffectsParentNotes}
            />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card title="Sales Price" headingLevel={2}>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="Are sales prices for the entity's products determined by worldwide competition and/or international prices?"
              value=""
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Notes" value="" />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card title="Sales Market" headingLevel={2}>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Is there an active local sales market?" value="" />
            <SpanWithLabel labelText="Which currency are the products mostly priced in?" value="" />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Notes" value="" />
            <SpanWithLabel labelText="Notes" value="" />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card title="Expenses" headingLevel={2}>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="What curency are the expenses priced in (particularly major ones such as payroll, COGS, Rent)? "
              value=""
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Notes" value="" />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card title="Financing" headingLevel={2}>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="Will the entity provide its own financing from its operations or will it be provided by the parent?"
              value=""
            />
            <SpanWithLabel
              labelText="If the entity will be provided with loans, are these loans from intercompany transactions?"
              value=""
            />
            <SpanWithLabel
              labelText="(a) What currency will these loans be denominated in?"
              value=""
            />
            <SpanWithLabel
              labelText="(b) Will these loans primarily be from one source or several?"
              value=""
            />
            <SpanWithLabel
              labelText="(c) Once the entity is in operation, will there be a need for continued infusion of cash/loans to maintain operations?"
              value=""
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Notes" value="" />
            <SpanWithLabel labelText="Notes" value="" />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card title="Intercompany Transactions and Arrangements" headingLevel={2}>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="Will there be a high volume of intercompany transactions?"
              value=""
            />
            <SpanWithLabel
              labelText="Is the entity simply holding investments, obligations, IP, etc. which could readily be carried on the parent’s or an affiliate’s books?"
              value=""
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Notes" value="" />
            <SpanWithLabel labelText="Notes" value="" />
          </StackColumn>
        </Stack>
      </Card>
      {panelState.isEditPanelOpen &&
        panelState.currentForm === FunctionalCurrencyForm.CashFlows && (
          <CashFlowEditPanel
            closePanel={closePanel}
            data={functionalCurrencyData}
            companyCreationId={companyCreationId}
          />
        )}
    </div>
  );
};

export default FunctionalCurrencyPage;
