import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { IColumn, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import getColumns from './ProcessList.config';
import { PROCESS_SEARCH_QUERY } from '../../utils/statApi/ProcessApi';
import { IPaginationSearch, IProcessSearchState } from './processSearch/IProcessSearchResult';
import { KeysOfType } from '../../utils/types/KeysOfType';
import { IPaginationMetadataRefs } from '../common/DetailsLists/pagination/IPaginationMetadata';
import { getTotalPageCount } from '../common/DetailsLists/pagination/DetailsListPaginationBanner';
import momentUtc from '../../utils/DateFormatter';
import StatDateFormats from '../../utils/types/DateFormats';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { bodyContentContainer } from '../../app/common/styles/CommonStyleObjects';
import ShimmeredDetailsListWrapper, {
  GetUpdatedColumnState,
} from '../common/DetailsLists/ShimmeredDetailsListWrapper';
import FilterCallout from '../common/callout/FilterCallout';
import ProcessSearchFilter from './processSearch/ProcessSearchFilter';
import DetailListPaginationBannerRefs from '../common/DetailsLists/pagination/DetailsListPaginationBannerRefs';
import { IDetailsListColumnSortProps } from '../../utils/types/IDetailsListColumnSortProps';

const initialState: IProcessSearchState = {
  companyCodes: [],
  processTypeIds: [],
  readyMilestoneStatuses: [],
  processStatuses: ['ACTIVE'],
  reportingPeriodEndFromDate: null,
  reportingPeriodEndToDate: null,
  filingDueFromDate: null,
  filingDueToDate: null,
  countryCodes: [],
  selectedPeriodEndDateOption: null,
  selectedFilingDueDateOption: null,
  companyAreaCodes: [],
  sortedFieldName: null,
  isSortedDescending: null,
  showMyItems: false,
  milestoneDueDateStartDate: null,
  milestoneDueDateEndDate: null,
  opsDetailIds: [],
};

interface IProcessSearchProps {
  showFcwFilters?: boolean;
}

const ProcessSearch = ({ showFcwFilters }: IProcessSearchProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [searchState, setSearchState] = useState<IProcessSearchState>(initialState);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [sortedFieldName, setSortedFieldName] = useState<string>();
  const [isSortedDescending, setIsSortedDescending] = useState(false);
  const [companyIdMessage, setCompanyIdMessage] = useState<string>();

  const pageCount = React.useRef<number>(0);
  const selectedPage = React.useRef<number>(1);
  const defaultPageSize = React.useRef<number>(25);
  const dateParameterKeys = [
    'reportingPeriodEndFromDate',
    'reportingPeriodEndToDate',
    'filingDueFromDate',
    'filingDueToDate',
  ];

  const [fetchProcesses, { data, loading, error }] = useLazyQuery(PROCESS_SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      processSearchDto: {
        companyCodes: searchState.companyCodes,
        processTypeIds: searchState.processTypeIds,
        processStatuses: searchState.processStatuses,
        readyMilestoneStatuses: searchState.readyMilestoneStatuses,
        reportingPeriodEndFromDate: searchState.reportingPeriodEndFromDate,
        reportingPeriodEndToDate: searchState.reportingPeriodEndToDate,
        filingDueFromDate: searchState.filingDueFromDate,
        filingDueToDate: searchState.filingDueToDate,
        countryCodes: searchState.countryCodes,
        companyAreaCodes: searchState.companyAreaCodes,
        sortedFieldName: searchState.sortedFieldName,
        isSortedDescending: searchState.isSortedDescending,
        showMyItems: searchState.showMyItems,
        milestoneDueDateStartDate: searchState.milestoneDueDateStartDate,
        milestoneDueDateEndDate: searchState.milestoneDueDateEndDate,
        opsDetailIds: searchState.opsDetailIds,
      },
      paginationDto: {
        currentPage: selectedPage.current,
        pageSize: defaultPageSize.current,
      },
    },
  });

  const processes = data?.processSearch.items || [];
  const totalRecordCount: number = data?.processSearch?.totalCount;

  const queryString = new URLSearchParams(useLocation().search);
  useEffect(() => {
    const stateWithParsedQueryStrings = {
      companyCodes: queryString.getAll('companyCodes'),
      processTypeIds: queryString.getAll('processTypeIds').map(Number),
      processStatuses: queryString.getAll('processStatuses'),
      readyMilestoneStatuses: queryString.getAll('readyMilestoneStatuses'),
      countryCodes: queryString.getAll('countryCodes'),
      selectedPeriodEndDateOption: queryString.get('selectedPeriodEndDateOption'),
      selectedFilingDueDateOption: queryString.get('selectedFilingDueDateOption'),
      companyAreaCodes: queryString.getAll('companyAreaCodes'),
      sortedFieldName: queryString.get('sortedFieldName'),
      isSortedDescending: queryString.get('isSortedDescending'),
      showMyItems: queryString.get('showMyItems') === 'true',
      milestoneDueDateStartDate: queryString.get('milestoneDueDateStartDate'),
      milestoneDueDateEndDate: queryString.get('milestoneDueDateEndDate'),
      opsDetailIds: queryString.getAll('opsDetailIds').map(Number),
    } as IProcessSearchState;

    dateParameterKeys.forEach((value) => {
      const key = value as KeysOfType<IProcessSearchState, Date>;
      const dateString = queryString.get(key);
      stateWithParsedQueryStrings[key] = dateString ? new Date(dateString) : null;
    });
    setSearchState(stateWithParsedQueryStrings);

    const defaultColumnState = getColumns();

    // Set column sorting state
    setColumns(
      GetUpdatedColumnState(
        stateWithParsedQueryStrings.sortedFieldName,
        defaultColumnState,
        stateWithParsedQueryStrings.isSortedDescending,
      ),
    );

    const pageSize = queryString.get('pageSize') ?? defaultPageSize.current;
    const currentPage = queryString.get('currentPage') ?? 1;

    selectedPage.current = parseInt(currentPage.toString(), 10);
    defaultPageSize.current = parseInt(pageSize.toString(), 10);

    const companyId = queryString.get('companyIds');
    if (companyId) {
      setCompanyIdMessage(
        `The link you are using is outdated. Please refresh your link by reselecting the filters.`,
      );
    } else {
      setCompanyIdMessage(null);
    }

    fetchProcesses({
      variables: {
        processSearchDto: {
          companyCodes: stateWithParsedQueryStrings.companyCodes,
          processTypeIds: stateWithParsedQueryStrings.processTypeIds,
          processStatuses: stateWithParsedQueryStrings.processStatuses,
          readyMilestoneStatuses: stateWithParsedQueryStrings.readyMilestoneStatuses,
          reportingPeriodEndFromDate: stateWithParsedQueryStrings.reportingPeriodEndFromDate,
          reportingPeriodEndToDate: stateWithParsedQueryStrings.reportingPeriodEndToDate,
          filingDueFromDate: stateWithParsedQueryStrings.filingDueFromDate,
          filingDueToDate: stateWithParsedQueryStrings.filingDueToDate,
          countryCodes: stateWithParsedQueryStrings.countryCodes,
          companyAreaCodes: stateWithParsedQueryStrings.companyAreaCodes,
          sortedFieldName: stateWithParsedQueryStrings.sortedFieldName,
          isSortedDescending: stateWithParsedQueryStrings.isSortedDescending,
          showMyItems: stateWithParsedQueryStrings.showMyItems,
          milestoneDueDateStartDate: stateWithParsedQueryStrings.milestoneDueDateStartDate,
          milestoneDueDateEndDate: stateWithParsedQueryStrings.milestoneDueDateEndDate,
          opsDetailIds: stateWithParsedQueryStrings.opsDetailIds,
        },
        paginationDto: {
          currentPage: selectedPage.current,
          pageSize: defaultPageSize.current,
        },
      },
    });
  }, [location]);

  const createDateString = (date: Date | string): string => {
    return momentUtc(date, StatDateFormats.Default);
  };

  const resetPagination = (): void => {
    selectedPage.current = 1;
  };

  const onUpdateUrl = (newState: IProcessSearchState, paginationData: IPaginationSearch) => {
    const newUrlObject = new URLSearchParams();
    // handle array parameters
    Object.entries(newState).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        if (v.length > 0) {
          v.forEach((value) => {
            newUrlObject.append(k as string, value);
          });
        }
      }
    });

    // handle date parameters
    dateParameterKeys.forEach((value) => {
      const key = value as keyof IProcessSearchState;
      if (newState[key] !== null) {
        newUrlObject.append(key.toString(), createDateString(newState[key] as Date));
      }
    });

    // handle all others
    if (newState.selectedFilingDueDateOption !== null) {
      newUrlObject.append('selectedFilingDueDateOption', newState.selectedFilingDueDateOption);
    }
    if (newState.selectedPeriodEndDateOption !== null) {
      newUrlObject.append('selectedPeriodEndDateOption', newState.selectedPeriodEndDateOption);
    }
    if (newState.sortedFieldName !== null) {
      newUrlObject.append('sortedFieldName', newState.sortedFieldName);
    }
    if (newState.isSortedDescending !== null) {
      newUrlObject.append('isSortedDescending', newState.isSortedDescending);
    }
    if (newState.showMyItems !== null) {
      newUrlObject.append('showMyItems', newState.showMyItems.toString());
    }
    if (newState.milestoneDueDateStartDate !== null) {
      newUrlObject.append(
        'milestoneDueDateStartDate',
        createDateString(newState.milestoneDueDateStartDate),
      );
    }
    if (newState.milestoneDueDateEndDate !== null) {
      newUrlObject.append(
        'milestoneDueDateEndDate',
        createDateString(newState.milestoneDueDateEndDate),
      );
    }

    newUrlObject.append('pageSize', paginationData?.pageSize?.toString());
    newUrlObject.append('currentPage', paginationData?.currentPage?.toString());

    history.replace(location.pathname.concat(`?${newUrlObject.toString()}`));
  };

  const updateStateResetPagination = (newState: IProcessSearchState) => {
    setSearchState(newState);
    resetPagination();
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(newState, paginationData);
  };

  const renderHeader = (): JSX.Element => <h1>Processes</h1>;

  const onResetFiltersClick = (): void => {
    const emptyState = initialState;
    emptyState.processStatuses = [];
    setSearchState(emptyState);
    resetPagination();
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(emptyState, paginationData);
  };

  const onPageSizeChange = (newPageSize: string | number): void => {
    defaultPageSize.current = newPageSize as number;
    selectedPage.current = 1;

    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };

  const onPageChange = (
    startItemIndex: number,
    endItemIndex: number,
    newPageNumber: number,
  ): void => {
    selectedPage.current = newPageNumber;
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };

  pageCount.current = getTotalPageCount(totalRecordCount, defaultPageSize.current);

  const paginationMetadata: IPaginationMetadataRefs = {
    pageCountRef: pageCount,
    selectedPageRef: selectedPage,
    pageSizeRef: defaultPageSize,
    onPageChange,
    onPageSizeChange,
  };

  const CompanyIdMessageBar = (): JSX.Element | null => {
    if (companyIdMessage) {
      return (
        <MessageBar messageBarType={MessageBarType.info}>
          <span>{companyIdMessage}</span>
        </MessageBar>
      );
    }
    return null;
  };

  const NoResultsMessageBar = (): JSX.Element => {
    if (processes?.length === 0 && !loading) {
      return (
        <MessageBar messageBarType={MessageBarType.info}>
          <span>No processes found for current filters</span>
        </MessageBar>
      );
    }
    return <></>;
  };

  const detailsListColumnSortProps: IDetailsListColumnSortProps = {
    columns,
    sortedFieldName,
    searchState,
    isSortedDescending,
    setColumns,
    setSortedFieldName,
    setIsSortedDescending,
    updateStateResetPagination,
  };

  return (
    <>
      <CompanyIdMessageBar />
      <FullWidthHeader
        title={renderHeader}
        subtitle={() => {
          return (
            <Stack horizontalAlign="end">
              <FilterCallout triggerButtonId="ProcessSearchFilter" triggerIconName="Filter">
                <ProcessSearchFilter
                  onResetFiltersClick={onResetFiltersClick}
                  filterState={searchState}
                  onUpdateState={updateStateResetPagination}
                  showFcwFilters={showFcwFilters}
                />
              </FilterCallout>
            </Stack>
          );
        }}
      />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <LoadingErrorMessage loading={loading} error={error} />
        <NoResultsMessageBar />
        <ShimmeredDetailsListWrapper
          gridAriaLabelOverride="Processes"
          items={processes}
          enableShimmer={loading}
          singularListLabel="Processes"
          pluralListLabel="Processes"
          columns={columns}
          isColumnSortedList
          detailsListColumnSortProps={detailsListColumnSortProps}
        />
        {paginationMetadata && <DetailListPaginationBannerRefs {...paginationMetadata} />}
      </div>
    </>
  );
};

export default ProcessSearch;
