import React from 'react';
import { Switch } from 'react-router-dom';
import DocumentAnalysisQueueList from './list/DocumentAnalysisQueueList';
import Page from '../common/page/Page';
import DocumentsToAnalyzeList from './list/DocumentsToAnalyzeList';
import OutputDetailsPage from './outputDetails/OutputDetailsPage';

const DocumentAnalysisRoutes = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Page
          path="/document/analysis/list"
          component={DocumentsToAnalyzeList}
          title="Management Letters"
        />
        <Page
          path="/document/analysis/:id/run/:runId"
          component={OutputDetailsPage}
          title="Analysis Output Details"
        />
        <Page
          path="/document/analysis/:id"
          component={DocumentAnalysisQueueList}
          title="Previous Analyses"
        />
      </Switch>
    </>
  );
};

export default DocumentAnalysisRoutes;
