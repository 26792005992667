import moment from 'moment';
import { IDropdownOption, Dropdown } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import StatDateFormats from '../../../../utils/types/DateFormats';

const generateDueDateStrings = (
  reportingPeriodEndDate: string,
  filingDueDate: string,
  currentYear: number,
  dayFormat: StatDateFormats,
): {
  reportingPeriodEndDateString: string;
  filingDueDateString: string;
} => {
  const startYear = currentYear;
  let endYear = currentYear;
  const startDateString = `${reportingPeriodEndDate}/${currentYear}`;
  const endDateString = `${filingDueDate}/${currentYear}`;

  if (moment(startDateString).isSameOrAfter(endDateString)) {
    endYear += 1;
  }

  const reportingPeriodEndDateString = moment(
    `${reportingPeriodEndDate}/${startYear.toString()}`,
  ).format(dayFormat);
  const filingDueDateString = moment(`${filingDueDate}/${endYear.toString()}`).format(dayFormat);
  return { reportingPeriodEndDateString, filingDueDateString };
};

const generateReportingDates = (
  reportingPeriodEndDate: string,
  filingDueDate: string,
): IDropdownOption[] => {
  const max = new Date().getFullYear() + 1;
  const min = max - 10;
  const years = Array<IDropdownOption>();
  for (let i = max; i >= min; i -= 1) {
    const { reportingPeriodEndDateString, filingDueDateString } = generateDueDateStrings(
      reportingPeriodEndDate,
      filingDueDate,
      i,
      StatDateFormats.DayBreakout,
    );
    years.push({
      key: i,
      text: `${reportingPeriodEndDateString} - ${filingDueDateString}`,
    });
  }
  return years;
};

interface IInitialReportingPeriodDropdownProps {
  statutoryYearEnd: string;
  auditFilingDueDate: string;
  selectedKey: number;
  handleChange: (
    reportingPeriodEndDate: string,
    filingDueDate: string,
    selectedKey: number,
  ) => void;
  disabled: boolean;
}
const InitialReportingPeriodDropdown = (
  props: IInitialReportingPeriodDropdownProps,
): JSX.Element => {
  const { statutoryYearEnd, auditFilingDueDate, selectedKey, handleChange, disabled } = props;
  const dropdownStyles = { dropdown: { width: 300 } };
  const [reportingDates, setReportingDates] = useState<IDropdownOption[]>();
  useEffect(() => {
    setReportingDates(
      statutoryYearEnd && auditFilingDueDate
        ? generateReportingDates(statutoryYearEnd, auditFilingDueDate)
        : [],
    );
  }, [statutoryYearEnd, auditFilingDueDate]);

  return (
    <>
      <Dropdown
        id="initialProcessDates"
        label="Initial Process Dates"
        onChange={(
          event: React.FormEvent<HTMLDivElement>,
          option?: IDropdownOption,
          index?: number,
        ): void => {
          const { reportingPeriodEndDateString, filingDueDateString } = generateDueDateStrings(
            statutoryYearEnd,
            auditFilingDueDate,
            option.key as number,
            StatDateFormats.DaySimple,
          );
          handleChange(reportingPeriodEndDateString, filingDueDateString, Number(option.key));
        }}
        selectedKey={selectedKey}
        options={reportingDates}
        required
        styles={dropdownStyles}
        placeholder="Select an option"
        disabled={disabled}
      />
    </>
  );
};
export default InitialReportingPeriodDropdown;
