import React from 'react';
import { useQuery } from '@apollo/client';
import { DefaultButton, Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import GET_ENTITY_STATUSES_QUERY from '../../utils/statApi/EntityStatusApi';
import IDropdownReference from '../../utils/types/IDropdownReference';
import { multiDropdownMediumWrapper } from '../companies/documents/CompanyDocumentStyles';
import { IInvalidServiceContactsSearchState } from './InvalidServiceContactsList';
import { filterWrapperStyles } from '../../app/common/styles/CommonStyleObjects';

interface IInvalidServiceContactsFilterProps {
  onResetFiltersClick: () => void;
  filterState: IInvalidServiceContactsSearchState;
  onUpdateState: (propertyName: keyof IInvalidServiceContactsSearchState, value: string[]) => void;
}
const InvalidServiceContactsFilter = (props: IInvalidServiceContactsFilterProps): JSX.Element => {
  const { onResetFiltersClick, filterState, onUpdateState } = props;
  const { entityStatuses } = filterState;
  const { loading, data } = useQuery(GET_ENTITY_STATUSES_QUERY);

  const { entityStatuses: entityStatusesSource } = !loading && data ? data : [];
  const entityStatusOptions = entityStatusesSource?.map((entityStatus: IDropdownReference) => {
    return { key: entityStatus.fieldName, text: entityStatus.fieldName };
  });

  const onUpdateEntityStatus = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ): void => {
    const itemsFromState = item.selected
      ? [...entityStatuses, item.key as string]
      : entityStatuses.filter((id) => id !== item.key);
    onUpdateState('entityStatuses' as keyof IInvalidServiceContactsSearchState, itemsFromState);
  };

  return (
    <Stack.Item grow disableShrink styles={filterWrapperStyles}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <h2>Filter</h2>
        <DefaultButton text="Reset Filters" onClick={onResetFiltersClick} />
      </Stack>

      <Dropdown
        label="Entity Status"
        className={multiDropdownMediumWrapper}
        onChange={(_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
          return onUpdateEntityStatus(_event, item);
        }}
        selectedKeys={entityStatuses}
        multiSelect
        placeholder="Select an option"
        options={entityStatusOptions}
      />
    </Stack.Item>
  );
};
export default InvalidServiceContactsFilter;
