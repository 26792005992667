import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { ActionButton, Stack } from '@fluentui/react';
import { useHistory, useParams } from 'react-router-dom';
import { bodyContentContainer } from '../../../../app/common/styles/CommonStyleObjects';
import {
  APPROVE_DIVIDENDS_REVIEW,
  DIVIDENDS_REVIEW_QUERY,
  REJECT_DIVIDENDS_REVIEW,
  ATTACH_DIVIDEND_REVIEW_DOCUMENT,
  CREATE_DIVIDEND_REVIEW_COMMENT,
  EDIT_DIVIDENDS_MUTATION,
} from '../../../../utils/statApi/CompanyFiscalYearsApi';
import IDividends from '../../../../utils/types/IDividends';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams, IDividendReviewParams } from '../../../common/ParamTypes';
import ReviewComponent from '../../../reviews/ReviewComponent';
import DividendsCardContent from './DividendsCardContent';
import FiscalYearDividendsEditPanel from '../FiscalYearDividendsEditPanel';
import CanAccess from '../../../common/canAccess/CanAccess';
import RejectButtonMode from '../../../reviews/RejectButtonMode';

const DividendsReview = (): JSX.Element => {
  const { dividendReviewId } = useParams<IDividendReviewParams>();
  const { companyCode } = useParams<ICompanyCodeParams>();
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const { data, error, loading, refetch } = useQuery(DIVIDENDS_REVIEW_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { dividendReviewId: parseInt(dividendReviewId, 10) },
  });

  const history = useHistory();

  const closePanel = (): void => {
    setIsEditPanelOpen(false);
    refetch();
  };

  const renderActionButton = (): JSX.Element => {
    return (
      <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
        {data?.dividendReview?.afsProcessId && (
          <>
            <ActionButton
              title="Go to Process"
              ariaLabel="Go to Process"
              iconProps={{ iconName: 'TimelineProgress' }}
              onClick={(): void => {
                history.push(
                  `/companies/${companyCode}/processes/annual-financial-statements/${data?.dividendReview?.afsProcessId}`,
                );
              }}
            >
              Go To Process
            </ActionButton>
            <span style={{ paddingTop: 10 }}>|</span>
          </>
        )}
        <CanAccess requestedAction="DividendInfo:Update">
          <ActionButton
            title="Edit Dividends"
            ariaLabel="Edit Dividends"
            iconProps={{ iconName: 'Edit' }}
            onClick={(): void => {
              setIsEditPanelOpen(true);
            }}
          >
            Edit Dividends
          </ActionButton>
        </CanAccess>
      </Stack>
    );
  };

  const dividends: IDividends = data?.dividendReview?.dividends || {};

  return (
    <>
      <div className={`${bodyContentContainer} lightBackground`}>
        <LoadingErrorMessage error={error} loading={loading} />
        <DividendsCardContent
          dividends={dividends}
          isLoading={loading}
          actionButtons={renderActionButton()}
        />
        <br />
        <ReviewComponent
          review={data?.dividendReview?.review}
          approveMutation={APPROVE_DIVIDENDS_REVIEW}
          rejectMutation={REJECT_DIVIDENDS_REVIEW}
          loading={loading}
          createCommentMutation={CREATE_DIVIDEND_REVIEW_COMMENT}
          attachDocumentMutation={ATTACH_DIVIDEND_REVIEW_DOCUMENT}
          rejectButtonMode={RejectButtonMode.RequestChanges}
        />
      </div>
      {isEditPanelOpen && (
        <FiscalYearDividendsEditPanel
          dividends={dividends}
          closePanel={closePanel}
          mutation={EDIT_DIVIDENDS_MUTATION}
        />
      )}
    </>
  );
};

export default DividendsReview;
