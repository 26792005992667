import {
  IProcessedStyleSet,
  mergeStyleSets,
  FontWeights,
  IStyle,
  FontSizes,
} from '@fluentui/react';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../../app/common/constants/SiteConstants';

interface IPageHeaderFullWidthStyles {
  pageHeaderWrapper: IStyle;
  pageSubheaderWrapper: IStyle;
  pageHeaderTitle: IStyle;
  pageHeaderSubtitle: IStyle;
}
interface IBodyContentStyles {
  bodyContentTitle: IStyle;
}
export const bodyContent = (): IProcessedStyleSet<IBodyContentStyles> => {
  return mergeStyleSets({
    bodyContentTitle: {
      selectors: {
        'h3, h2': {
          margin: 0,
          padding: '10px 0',
          fontSize: FontSizes.size14,
          fontWeight: FontWeights.semibold,
        },
      },
    },
  });
};
export const pageHeaderFullWidth = (): IProcessedStyleSet<IPageHeaderFullWidthStyles> => {
  return mergeStyleSets({
    pageHeaderWrapper: {
      padding: '30px 40px 0 40px',
      selectors: {
        [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
          paddingTop: 8,
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 8,
          margin: 0,
          marginBottom: 10,
        },
      },
    },
    pageHeaderSubtitle: {
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.regular,
    },

    pageHeaderTitle: {
      selectors: {
        h1: {
          margin: 0,
          fontSize: FontSizes.size20,
          fontWeight: FontWeights.semibold,
          selectors: {
            [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
              fontSize: FontSizes.size16,
            },
          },
        },
        [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
          paddingTop: 6,
          marginLeft: 24,
          marginRight: 24,
          lineHeight: 22,
          position: 'fixed',
          top: 55,
          zIndex: 1,
          width: 'calc(100vw - 96px)',
        },
      },
    },
    pageSubheaderWrapper: {
      marginTop: '10px',
      selectors: {
        [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
          paddingTop: 6,
        },
      },
    },
  });
};

export const HeaderLinkStyles = mergeStyleSets({
  headerLink: {
    margin: '8px 0',
    display: 'block',
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  } as IStyle,
});
