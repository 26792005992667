import React from 'react';
import {
  IIconProps,
  IButtonStyles,
  PrimaryButton,
  DefaultButton,
  TeachingBubble,
  NeutralColors,
  DefaultPalette,
} from '@fluentui/react';
import { TeachingBubbleProps } from '../../common/ActionButtons';

interface IMilestoneCardActionButtonProps {
  title: string;
  ariaLabel: string;
  iconProps: IIconProps;
  disabled: boolean;
  isCurrent: boolean;
  teachingBubbleProps?: TeachingBubbleProps;
  onClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onKeyUp: (ev: React.KeyboardEvent<HTMLDivElement>) => void;
}
const MilestoneCardActionButton = (props: IMilestoneCardActionButtonProps): JSX.Element => {
  const {
    isCurrent,
    title,
    ariaLabel,
    iconProps,
    disabled,
    teachingBubbleProps,
    onClick,
    onKeyUp,
  } = props;
  const { header, content, showTeachingBubble, toggleTeachingBubble } = teachingBubbleProps;

  const defaultStyles: IButtonStyles = {
    root: {
      backgroundColor: NeutralColors.gray40,
      borderColor: NeutralColors.gray100,
    },
  };
  const iconStyles: IButtonStyles = {
    root: {
      border: '1px solid transparent',
      marginLeft: 20,
      color: DefaultPalette.blue,
      background: NeutralColors.white,
    },
    rootHovered: {
      borderColor: NeutralColors.white,
      background: DefaultPalette.blue,
    },
    rootFocused: {
      background: DefaultPalette.blue,
      color: NeutralColors.white,
    },
    iconPressed: {
      color: NeutralColors.white,
    },
    iconHovered: {
      color: NeutralColors.white,
    },
  };
  return (
    <>
      {isCurrent && (
        <PrimaryButton
          id={title}
          title={title}
          ariaLabel={ariaLabel}
          iconProps={iconProps}
          styles={iconStyles}
          disabled={disabled}
          onClick={onClick}
          onKeyUp={onKeyUp}
        >
          {title}
        </PrimaryButton>
      )}
      {!isCurrent && (
        <DefaultButton
          title={title}
          styles={defaultStyles}
          ariaLabel={ariaLabel}
          iconProps={iconProps}
          disabled={disabled}
          onClick={onClick}
          onKeyUp={onKeyUp}
        >
          {title}
        </DefaultButton>
      )}
      {showTeachingBubble && (
        <TeachingBubble
          target="#Signoff"
          primaryButtonProps={{
            children: 'Close',
            onClick: () => {
              toggleTeachingBubble();
            },
          }}
          headline={header}
        >
          {content}
        </TeachingBubble>
      )}
    </>
  );
};
export default MilestoneCardActionButton;
