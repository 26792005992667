import * as React from 'react';

const OpenProcessesSVG = (): JSX.Element => {
  return (
    <svg width="50" height="50" viewBox="0 0 138.592 160">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-5.826"
          y1="16.221"
          x2="-6.678"
          y2="15.711"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#e2e2e2" />
          <stop offset="0.069" stopColor="#e8e8e8" />
          <stop offset="0.265" stopColor="#f3f3f3" />
          <stop offset="0.473" stopColor="#f7f7f7" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0669bc" />
          <stop offset="0.575" stopColor="#0669bc" />
          <stop offset="1" stopColor="#0669bc" />
        </linearGradient>
      </defs>
      <g id="Illustrations" transform="translate(-11.204 -1695.687)">
        <g id="Tips">
          <g id="Group_105" data-name="Group 105">
            <path
              id="Path_1061"
              data-name="Path 1061"
              d="M94.87,1799.429l.12-11.9.241-60.707a6.048,6.048,0,0,0-.241-1.442,4.522,4.522,0,0,0-1.082-1.8L66.5,1697.37a4.111,4.111,0,0,0-2.163-1.082,4.476,4.476,0,0,0-1.082-.24l-46.882-.361a4.694,4.694,0,0,0-4.689,4.688l-.48,98.452a4.8,4.8,0,0,0,4.688,4.809l66.837.481h7.333A4.721,4.721,0,0,0,94.87,1799.429Z"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_1062"
              data-name="Path 1062"
              d="M71.669,1725.259l23.2.12a4.522,4.522,0,0,0-1.082-1.8L66.5,1697.37a4.11,4.11,0,0,0-2.164-1.082l-.12,21.518A7.339,7.339,0,0,0,71.669,1725.259Z"
              fill="#9ee5fa"
            />
            <path
              id="Path_1063"
              data-name="Path 1063"
              d="M37.65,1739.323H32.6a1.034,1.034,0,0,1-.962-.961v-5.049a1.035,1.035,0,0,1,.962-.962H37.65a1.035,1.035,0,0,1,.962.962v5.049A.948.948,0,0,1,37.65,1739.323Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1064"
              data-name="Path 1064"
              d="M37.53,1753.388H32.481a1.034,1.034,0,0,1-.962-.962v-5.049a1.034,1.034,0,0,1,.962-.961H37.53a1.034,1.034,0,0,1,.961.961v5.049A.948.948,0,0,1,37.53,1753.388Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1065"
              data-name="Path 1065"
              d="M37.409,1767.453H32.36a1.034,1.034,0,0,1-.961-.962v-5.049a1.034,1.034,0,0,1,.961-.962h5.049a1.035,1.035,0,0,1,.962.962v5.049A.949.949,0,0,1,37.409,1767.453Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1066"
              data-name="Path 1066"
              d="M37.289,1781.517H32.241a1.034,1.034,0,0,1-.962-.962v-5.048a1.034,1.034,0,0,1,.962-.962h5.048a1.034,1.034,0,0,1,.962.962v5.048A1.034,1.034,0,0,1,37.289,1781.517Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1067"
              data-name="Path 1067"
              d="M73.833,1737.52l-29.091-.24c-.24,0-.36-.241-.36-.481v-1.923c0-.241.24-.361.481-.361l29.09.24c.241,0,.361.241.361.481v1.924C74.194,1737.28,74.074,1737.52,73.833,1737.52Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1068"
              data-name="Path 1068"
              d="M73.713,1751.585l-29.091-.241c-.24,0-.361-.24-.361-.48v-1.924c0-.24.241-.36.481-.36l28.971.24c.24,0,.361.24.361.481v1.923C74.194,1751.344,73.953,1751.585,73.713,1751.585Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1069"
              data-name="Path 1069"
              d="M73.593,1765.529l-28.971-.12c-.24,0-.361-.24-.361-.481v-1.923c0-.241.241-.361.481-.361l29.091.241c.241,0,.361.24.361.48v1.924A.919.919,0,0,1,73.593,1765.529Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1070"
              data-name="Path 1070"
              d="M73.473,1779.594l-29.092-.241c-.24,0-.36-.24-.36-.48v-1.924c0-.24.24-.36.481-.36l29.091.24c.24,0,.36.24.36.481v1.923C73.953,1779.474,73.713,1779.594,73.473,1779.594Z"
              fill="#e2e2e2"
            />
            <path
              id="Path_1071"
              data-name="Path 1071"
              d="M126.846,1737.52a12.443,12.443,0,0,1-3.847-9.136h-1.683a12.981,12.981,0,0,1-12.742,13.1v1.683a12.981,12.981,0,0,1,13.1,12.742h1.563a12.981,12.981,0,0,1,12.742-13.1v-1.562A12.714,12.714,0,0,1,126.846,1737.52Z"
              fill="#ffe192"
            />
            <path
              id="Path_1072"
              data-name="Path 1072"
              d="M60.49,1841.983a9.573,9.573,0,0,1-2.885-6.732H56.4a9.569,9.569,0,0,1-9.5,9.737v1.2a9.569,9.569,0,0,1,9.737,9.5h1.2a9.57,9.57,0,0,1,9.5-9.737v-1.2A9.948,9.948,0,0,1,60.49,1841.983Z"
              fill="#ffe192"
            />
            <path
              id="Path_1073"
              data-name="Path 1073"
              d="M38.972,1823.951a3.726,3.726,0,0,1-1.2-2.885h-.481a4.157,4.157,0,0,1-4.087,4.208v.481a4.156,4.156,0,0,1,4.207,4.087h.481a4.157,4.157,0,0,1,4.087-4.208v-.48A3.813,3.813,0,0,1,38.972,1823.951Z"
              fill="#ffe192"
            />
            <path
              id="Path_1074"
              data-name="Path 1074"
              d="M120.355,1840.42a45.431,45.431,0,0,1-9.617,2.525,38.892,38.892,0,0,1-43.4-33.779A38.98,38.98,0,0,1,91.5,1768.174a45.415,45.415,0,0,1,9.617-2.525,38.893,38.893,0,0,1,43.4,33.78A39.13,39.13,0,0,1,120.355,1840.42Z"
              fill="#fff"
            />
            <path
              id="Path_1075"
              data-name="Path 1075"
              d="M93.307,1772.742a32.081,32.081,0,0,1,8.415-2.164,33.926,33.926,0,0,1,16.829,65.154,32.063,32.063,0,0,1-8.414,2.164,33.926,33.926,0,0,1-16.83-65.154Zm-3.726-9.256,3.726,9.376-3.726-9.376a43.932,43.932,0,0,0,21.758,84.387,46.1,46.1,0,0,0,10.819-2.765,43.932,43.932,0,0,0-21.758-84.387A37.693,37.693,0,0,0,89.581,1763.486Z"
              fill="url(#linear-gradient-2)"
            />
            <line
              id="Line_7"
              data-name="Line 7"
              x2="20"
              transform="translate(89 1805.187)"
              fill="none"
              stroke="#0d76c4"
              strokeWidth="2.5"
            />
            <line
              id="Line_8"
              data-name="Line 8"
              y2="21"
              transform="translate(108 1784.187)"
              fill="none"
              stroke="#086ebf"
              strokeWidth="2.5"
            />
          </g>
          <g
            data-name="Ellipse"
            transform="translate(106.5 1803.687)"
            fill="#086ebf"
            stroke="#0e79c6"
            strokeWidth="1"
          >
            <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
            <circle cx="1.5" cy="1.5" r="1" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default OpenProcessesSVG;
