import React, { useState, useContext } from 'react';
import {
  DocumentCard,
  DocumentCardPreview,
  DocumentCardType,
  DocumentCardDetails,
  DocumentCardTitle,
  DocumentCardActivity,
  DocumentCardActions,
  DocumentCardStatus,
  Stack,
  DefaultButton,
  PrimaryButton,
  DocumentCardLocation,
  IButtonProps,
} from '@fluentui/react';
import previewPropsUsingIcon from './fileSelector.config';
import {
  deleteFileItemIcon,
  fileSelectorItemWrapper,
  documentCardTitleStyles,
} from './fileSelectorStyles';
import { IFileValidationError } from '../../../../utils/validation/validateFiles';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import UserContext from '../../../../utils/authorization/UserContext';
import hasAccess from '../../../../utils/authorization/authorizationCheck';
import { openBlob, downloadBlob } from '../../documents/documentDownload';

interface IFileSelectorItemProps {
  index: number;
  user: StatUser;
  fileName: string;
  url?: string;
  validationErrors?: IFileValidationError[];
  onRemoveFromSelected?: (index: number) => void;
  allowDownload?: boolean;
  allowViewInTab?: boolean;
  allowDelete?: boolean;
  uiDisabled: boolean;
  isMilestoneComplete?: boolean;
  activity: string;
}
const FileSelectorItem: React.FC<IFileSelectorItemProps> = (props: IFileSelectorItemProps) => {
  const {
    index,
    user,
    fileName,
    url,
    onRemoveFromSelected,
    allowDownload,
    allowViewInTab,
    allowDelete,
    uiDisabled,
    isMilestoneComplete,
    validationErrors,
    activity,
  } = props;
  const loggedInUser: StatUser = useContext(UserContext);
  const { permissions } = loggedInUser;
  const [showWarnBeforeDelete, setShowWarnBeforeDelete] = useState(false);
  const [hasDownloadError, setHasDownloadError] = useState(false);
  const hasErrors = (validationErrors && validationErrors.length > 0) || hasDownloadError;
  const getUploadErrors = (): string =>
    hasDownloadError
      ? 'Error when downloading file'
      : validationErrors.map((e) => e.title).join(' ');
  const warnBeforeDelete = (): JSX.Element => {
    return (
      <Stack horizontalAlign="center" verticalAlign="center">
        <p>Are you sure you want to remove this file</p>
        <Stack horizontalAlign="center" horizontal tokens={{ childrenGap: 20 }}>
          <PrimaryButton
            text="Yes"
            onClick={(event: React.MouseEvent<HTMLDivElement>): void => {
              event.stopPropagation();
              onRemoveFromSelected(index);
              setShowWarnBeforeDelete(false);
            }}
          />
          <DefaultButton
            text="Cancel"
            onClick={(event: React.MouseEvent<HTMLDivElement>): void => {
              event.stopPropagation();
              setShowWarnBeforeDelete(false);
            }}
          />
        </Stack>
      </Stack>
    );
  };

  const onReadError = (): void => {
    setHasDownloadError(true);
  };

  const cardActions: IButtonProps[] = ((): IButtonProps[] => {
    const actions: IButtonProps[] = [];

    if (allowDownload && hasAccess(permissions, 'MilestoneDocument:Download')) {
      actions.push({
        iconProps: { iconName: 'Download' },
        onClick: (): void => {
          downloadBlob(url, onReadError);
        },
        ariaLabel: `Download file: ${fileName}`,
      });
    }

    if (
      (allowDelete === undefined || allowDelete) &&
      hasAccess(permissions, 'MilestoneDocument:Remove')
    ) {
      actions.push({
        iconProps: { iconName: 'Delete' },
        onClick: (): void => {
          setShowWarnBeforeDelete(true);
        },
        ariaLabel: `Remove file: ${fileName} from uploads`,
      });
    }

    return actions;
  })();

  return (
    <DocumentCard
      aria-label="File selected to upload for milestone signoff"
      type={DocumentCardType.compact}
      styles={fileSelectorItemWrapper}
    >
      <DocumentCardPreview {...previewPropsUsingIcon(hasErrors, fileName)} />
      <DocumentCardDetails>
        {!showWarnBeforeDelete && (
          <>
            <DocumentCardTitle
              styles={documentCardTitleStyles}
              title={fileName}
              shouldTruncate={fileName?.length > 25}
            />

            {!uiDisabled &&
              allowViewInTab &&
              hasAccess(permissions, 'MilestoneDocument:Download') && (
                <DocumentCardLocation
                  location="View Document"
                  locationHref=""
                  onClick={(ev?: React.MouseEvent<HTMLElement>) => {
                    ev.preventDefault();
                    openBlob(url, onReadError);
                  }}
                  ariaLabel={`View Document: ${fileName}`}
                />
              )}

            {hasErrors && (
              <div role="alert">
                <DocumentCardStatus statusIcon="warning" status={getUploadErrors()} />
              </div>
            )}

            {!hasErrors && (
              <DocumentCardActivity
                activity={activity}
                people={[
                  {
                    name: user?.displayName,
                    profileImageSrc: '',
                    initials: `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`,
                  },
                ]}
              />
            )}
            {!uiDisabled && !isMilestoneComplete && (
              <DocumentCardActions styles={deleteFileItemIcon} actions={cardActions} />
            )}
          </>
        )}
        {showWarnBeforeDelete && warnBeforeDelete()}
      </DocumentCardDetails>
    </DocumentCard>
  );
};

export default FileSelectorItem;
