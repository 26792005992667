import { useQuery } from '@apollo/client';
import { mergeStyles, NeutralColors, Spinner } from '@fluentui/react';
import React from 'react';
import momentUtc from '../../utils/DateFormatter';
import { MILESTONE_DETAILS } from '../../utils/statApi/MilestoneApi';
import StatDateFormats from '../../utils/types/DateFormats';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';

export interface IMilestoneDetailsProps {
  id: number;
  status: string;
}

const MilestoneDetails = ({ id, status }: IMilestoneDetailsProps): JSX.Element => {
  const { data, loading, error } = useQuery(MILESTONE_DETAILS, { variables: { milestoneId: id } });

  if (loading && !error) {
    return <Spinner label="Loading" />;
  }

  const milestoneDetails = data?.milestoneDetails;
  const labelStyles = mergeStyles({ color: NeutralColors.gray110 });

  const getMilestoneStatusFormatted = (rawStatus: string): string => {
    if (status === 'NOTSTARTED') {
      return 'Not Started';
    }
    if (status === 'COMPLETE') {
      return 'Complete';
    }
    return 'Ready';
  };

  return (
    <div>
      <LoadingErrorMessage loading={loading} error={error} />
      {!loading && !error && (
        <>
          <strong>{milestoneDetails.title}</strong>
          <br />
          <br />
          <span className={labelStyles}>Due Date:</span>{' '}
          {momentUtc(milestoneDetails.dueDate, StatDateFormats.DayBreakout)} <br />
          <span className={labelStyles}>Status:</span>{' '}
          {getMilestoneStatusFormatted(milestoneDetails.status)} <br />
          {milestoneDetails.actionText}
        </>
      )}
    </div>
  );
};

export default MilestoneDetails;
