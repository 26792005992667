import { ApolloError } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  Spinner,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Icon,
  getTheme,
  IGroup,
} from '@fluentui/react';
import React from 'react';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IRequiredItem } from './types';

interface IRequiredFieldsPanel {
  closePanel: () => void;
  doesItemHaveValue(itemKey: string): boolean;
  items: IRequiredItem[];
  groups: IGroup[];
  loading: boolean;
  error: ApolloError;
}

const RequiredFieldsPanel = ({
  closePanel,
  doesItemHaveValue,
  items,
  groups,
  loading,
  error,
}: IRequiredFieldsPanel): JSX.Element => {
  const columns = [
    { key: 'name', name: 'Field Name', fieldName: 'value', minWidth: 60, maxWidth: 60 },
  ];

  const theme = getTheme();

  const iconStyle = (color: string): React.CSSProperties => {
    return {
      marginRight: 6,
      fontSize: 16,
      height: 16,
      width: 16,
      color,
    };
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Required Fields"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      closeButtonAriaLabel="Close"
      styles={CoherencePanelStyles}
    >
      <LoadingErrorMessage error={error} loading={loading} />
      {loading && <Spinner />}
      {!loading && (
        <DetailsList
          compact
          items={items}
          groups={groups}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          onRenderItemColumn={(item: IRequiredItem, index: number) => {
            const itemHasValue: boolean = doesItemHaveValue(item.key);
            return (
              <div style={{ display: 'flex' }}>
                <Icon
                  iconName={itemHasValue ? `SkypeCircleCheck` : 'StatusCircleRing'}
                  style={iconStyle(itemHasValue ? theme.palette.green : theme.palette.blue)}
                />
                <span>{item.value}</span>
              </div>
            );
          }}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="select row"
        />
      )}
    </CoherencePanel>
  );
};

export default RequiredFieldsPanel;
