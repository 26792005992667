import { gql } from '@apollo/client';

const GET_ENTITY_STATUSES_QUERY = gql`
  {
    entityStatuses {
      id
      fieldName
      isSelectable
    }
  }
`;

export const GET_LIFECYCLE_STATUSES_QUERY = gql`
  {
    lifecycleStatuses {
      id
      fieldName
      entityStatusId
    }
  }
`;

export const GET_TERMINATION_LIFECYCLE_STATUSES_QUERY = gql`
  {
    terminationLifecycleStatuses {
      id
      fieldName
    }
  }
`;

export const GET_LEGO_CATEGORIES_QUERY = gql`
  {
    legoCategories {
      id
      fieldName
      terminationLifecycleStatusId
    }
  }
`;

export const GET_INTAKE_METHODS_QUERY = gql`
  {
    intakeMethods {
      id
      fieldName
    }
  }
`;

export default GET_ENTITY_STATUSES_QUERY;
