import { ActionButton, ITag, IconButton, NeutralColors, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import GenericFilterTagItems from './GenericFilterTagItems';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../../app/common/constants/SiteConstants';

interface IGenericFilterTagHandlerProps {
  onChange: (filterTags: IGenericFilterTag[]) => void;
  allFilterTags: IGenericFilterTag[];
  children?: JSX.Element;
  hideSavedFilter?: boolean;
}

export const filterOutSavedTags = (filterTags: IGenericFilterTag[]) => {
  let tags = filterTags.map((f: IGenericFilterTag) => {
    return { ...f, values: f.values.filter((v) => !v?.isSavedFilter) };
  });

  tags = tags.filter((f) => f.values.length > 0);

  return tags;
};

const filterValueLength = (filterTags: IGenericFilterTag[]) => {
  return filterTags?.map((f) => f.values.length).reduce((a, b) => a + b, 0);
};

const GenericFilterTagHandler = (props: IGenericFilterTagHandlerProps): JSX.Element => {
  const { onChange, allFilterTags, children, hideSavedFilter } = props;
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);

  const filterWrapperStyles = {
    root: {
      padding: ' 0 5px 0 9px',
      background: filterValueLength(allFilterTags) > 0 ? NeutralColors.gray20 : 'inherit',
      outerWidth: '100%',
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        alignItems: 'flex-start',
        flexFlow: 'column',
      },
    },
  };

  const deleteEmptyArrays = (filterTags: IGenericFilterTag[]) => {
    return filterTags?.filter((f) => f.values.length > 0);
  };

  const getChildFilters = (parentDataKey: string): IGenericFilterTag[] => {
    return allFilterTags?.filter((filter) => filter?.parentDataKey === parentDataKey);
  };

  const getUpdatedFilters = (filterValues: IGenericFilterTag[]): IGenericFilterTag[] => {
    let filters: IGenericFilterTag[] = structuredClone(allFilterTags);

    const updateFilters = (filterValue: IGenericFilterTag) => {
      // Get any associated child filters for this filter
      const childFilters = getChildFilters(filterValue?.dataKey);

      // Recursively update any child filters
      childFilters?.forEach((childFilter) => {
        childFilter.values.length = 0;
        updateFilters(childFilter);
      });

      const indexOfValue = filters?.findIndex((item) => item.dataKey === filterValue.dataKey);
      if (indexOfValue === -1) {
        // Add new filter
        filters = filters.concat([filterValue]);
      } else {
        // Update previous filter
        filters.splice(indexOfValue, 1, {
          dataKey: filters[indexOfValue].dataKey,
          title: filters[indexOfValue].title,
          hideFromBar: filters[indexOfValue].hideFromBar,
          parentDataKey: filters[indexOfValue].parentDataKey,
          values: filterValue.values,
        });
      }
    };

    // For each flter tag update it and append it to the list of tags
    filterValues.forEach((filterValue) => {
      updateFilters(filterValue);
    });
    return filters;
  };

  const onFilterItemUpdate = (filterValue: IGenericFilterTag) => {
    // Get list of updated filters and update state all at once
    const updatedFilters = getUpdatedFilters([filterValue]);
    onChange(updatedFilters);
  };

  const onFilterTagsChange = (filterTagKey: string, filterTagItems: ITag[]): void => {
    const tag: IGenericFilterTag = {
      title: filterTagKey,
      dataKey: filterTagKey,
      values: filterTagItems,
    };
    onFilterItemUpdate(tag);
  };

  const onClearAllFilters = () => {
    let newFilterTags = structuredClone(allFilterTags);
    newFilterTags = newFilterTags.filter((f) => f.dataKey === 'forMeOnly');
    onChange(newFilterTags || []);
  };

  const onFilterPanelUpdate = (newFilterTags: IGenericFilterTag[]) => {
    const cleanedFilterTags = deleteEmptyArrays(newFilterTags);
    onChange(cleanedFilterTags);
    setShowFilterPanel(false);
  };

  const onResetFilters = () => {
    onChange([]);
  };

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 20 }}
        styles={filterWrapperStyles}
      >
        <Stack horizontal wrap verticalAlign="center" tokens={{ childrenGap: 10 }}>
          {!hideSavedFilter && (
            <>
              <ActionButton text="Reset Filter" onClick={onResetFilters} />
            </>
          )}
          {allFilterTags?.length > 0 && (
            <GenericFilterTagItems
              filterTagItems={allFilterTags}
              onFilterTagsChange={onFilterTagsChange}
              onClearAllFilters={onClearAllFilters}
            />
          )}
        </Stack>
        <IconButton
          iconProps={{
            iconName: 'Filter',
          }}
          title="Edit Filters"
          ariaLabel="Edit Filters"
          styles={{ root: { margin: '4px 0px' } }}
          onClick={() => setShowFilterPanel(true)}
        />
      </Stack>
      {showFilterPanel &&
        React.cloneElement(children, {
          currentFilterValues: allFilterTags,
          onFilterUpdate: onFilterPanelUpdate,
          onClosePanel: () => setShowFilterPanel(false),
        })}
    </>
  );
};
export default GenericFilterTagHandler;
