import { useMutation, useQuery } from '@apollo/client';
import {
  ActionButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import hasAccess from '../../utils/authorization/authorizationCheck';
import UserContext from '../../utils/authorization/UserContext';
import momentUtc from '../../utils/DateFormatter';
import monthDayFormat from '../../utils/MonthDayFormatter';
import {
  DELETE_COMPLIANCE_REQUIREMENT,
  GET_COUNTRY_CONFIG_DETAILS,
} from '../../utils/statApi/CountryApi';
import { StatUser } from '../../utils/statApi/UsersApi';
import StatDateFormats from '../../utils/types/DateFormats';
import CanAccess from '../common/canAccess/CanAccess';
import Card from '../common/card/Card';
import ShimmeredDetailsListWrapper from '../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { ICountryIdParams } from '../common/ParamTypes';
import SpanWithLabel from '../common/SpanWithLabel';
import StackColumn from '../common/StackColumn';
import ComplianceRequirementEditPanel from './ComplianceRequirementPanel';
import {
  getColumns,
  IComplianceRequirement,
  ICountryConfiguration,
  ICountryConfigurationQueryResult,
} from './CountryConfiguration.config';

interface CountryConfigurationInformationProps {
  toggleCountryConfigPanel: () => void;
  setCountryConfigData: React.Dispatch<React.SetStateAction<ICountryConfiguration>>;
}

const CountryConfigurationInformation = ({
  toggleCountryConfigPanel,
  setCountryConfigData,
}: CountryConfigurationInformationProps): JSX.Element => {
  const { countryId } = useParams<ICountryIdParams>();
  const user: StatUser = useContext(UserContext);
  const { permissions } = user;
  const [selectedComplianceRequirement, setSelectedComplianceRequirement] =
    useState<IComplianceRequirement>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isComplianceRequirementPanelOpen, setIsComplianceRequirementPanelOpen] = useState(false);

  const {
    data: configData,
    loading: configLoading,
    error: configError,
  } = useQuery(GET_COUNTRY_CONFIG_DETAILS, {
    variables: { countryConfigId: parseInt(countryId, 10) },
    // Note: onComplete is tied to the network call and
    // is bypassed by using cache so the onComplete is not
    // called here.
    onCompleted: (configurationData) => {
      setCountryConfigData(configurationData?.countryConfigurationDetails);
    },
  });

  const [deleteComplianceRequirement, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_COMPLIANCE_REQUIREMENT,
    {
      onCompleted: () => {
        setShowDeleteConfirmation(false);
      },
      update(cache, { data }) {
        const deletedComplianceRequirement = data?.deleteComplianceRequirement;

        const queryData = cache.readQuery<ICountryConfigurationQueryResult>({
          query: GET_COUNTRY_CONFIG_DETAILS,
          variables: { countryConfigId: parseInt(countryId, 10) },
        });

        const countryData: ICountryConfiguration = queryData?.countryConfigurationDetails;

        const allComplianceRequirements = countryData.complianceRequirements.filter(
          (cr) => cr.id !== deletedComplianceRequirement.id,
        );

        cache.writeQuery({
          query: GET_COUNTRY_CONFIG_DETAILS,
          variables: { countryConfigId: parseInt(countryId, 10) },
          data: {
            countryConfigurationDetails: {
              ...countryData,
              complianceRequirements: allComplianceRequirements,
            },
          },
        });
      },
      onError: () => {
        setShowDeleteConfirmation(false);
      },
    },
  );

  useEffect(() => {
    setCountryConfigData(configData?.countryConfigurationDetails || {});
  }, [configData]);

  const countryData: ICountryConfiguration = configData?.countryConfigurationDetails || {};

  const toggleComplianceRequirementPanel = () => {
    setIsComplianceRequirementPanelOpen(!isComplianceRequirementPanelOpen);
  };

  const renderEditActionButton = (label: string): JSX.Element => {
    return (
      <CanAccess requestedAction="CountryConfiguration:AddEdit">
        <IconButton
          title={`Edit ${label}`}
          ariaLabel={label}
          iconProps={{ iconName: 'Edit' }}
          onClick={(): void => {
            toggleCountryConfigPanel();
          }}
        />
      </CanAccess>
    );
  };

  const renderAddActionButton = (label: string): JSX.Element => {
    return (
      <CanAccess requestedAction="CountryConfiguration:AddEdit">
        <IconButton
          title={`Add ${label}`}
          ariaLabel={label}
          iconProps={{ iconName: 'Add' }}
          onClick={(): void => {
            setSelectedComplianceRequirement(null);
            toggleComplianceRequirementPanel();
          }}
        />
      </CanAccess>
    );
  };

  const deleteButtonClick = (complianceRequirement: IComplianceRequirement): void => {
    setSelectedComplianceRequirement(complianceRequirement);
    setShowDeleteConfirmation(true);
  };

  const onRenderUpdateDate = (complianceRequirement: IComplianceRequirement): JSX.Element => {
    return <span>{momentUtc(complianceRequirement.updatedDate, StatDateFormats.DayBreakout)}</span>;
  };

  const onRenderFilingType = (complianceRequirement: IComplianceRequirement): JSX.Element => {
    return <span>{complianceRequirement?.filingType?.fieldName}</span>;
  };

  const onRenderFilingFrequency = (complianceRequirement: IComplianceRequirement): JSX.Element => {
    return <span>{complianceRequirement?.filingFrequency?.fieldName}</span>;
  };

  const onRenderComplianceName = (complianceRequirement: IComplianceRequirement): JSX.Element => {
    return (
      <ActionButton
        className="user-link"
        onClick={() => {
          setSelectedComplianceRequirement(complianceRequirement);
          toggleComplianceRequirementPanel();
        }}
      >
        {complianceRequirement.name}
      </ActionButton>
    );
  };

  const deleteComplianceRequirementConfirmClick = () => {
    deleteComplianceRequirement({
      variables: {
        complianceRequirementId: parseInt(selectedComplianceRequirement.id.toString(), 10),
      },
    });
  };

  const deleteButtonColumn = (item: IComplianceRequirement): JSX.Element => (
    <CanAccess requestedAction="ComplianceRequirement:Delete">
      <IconButton
        text="Delete"
        aria-label="Delete Compliance Requirement"
        iconProps={{ iconName: 'delete' }}
        onClick={() => deleteButtonClick(item)}
      />
    </CanAccess>
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Are you sure',
    subText: 'Do you want to delete this compliance requirement?',
  };

  const modalPropsStyles = { main: { maxWidth: 450 } };
  const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
  };

  const columns = getColumns({
    onRenderComplianceName,
    onRenderUpdateDate,
    onRenderFilingType,
    onRenderFilingFrequency,
    deleteButtonColumn,
    canDelete: hasAccess(permissions, 'ComplianceRequirement:Delete'),
  });

  return (
    <>
      <LoadingErrorMessage
        loading={configLoading}
        error={configError}
        actionName="loading the Country Configuration"
      />
      <LoadingErrorMessage
        loading={deleteLoading}
        error={deleteError}
        actionName="deleting the Compliance Requirement"
      />
      <Card
        title="Configuration Information"
        actionButton={renderEditActionButton('Country Configuration')}
        headingLevel={2}
        isLoading={configLoading}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Country Code" value={countryData?.country?.id} />
            <SpanWithLabel labelText="Work Week" value={countryData?.workWeek?.fieldName} />
            <SpanWithLabel
              labelText="Tax Return Due Date"
              value={monthDayFormat(
                countryData?.taxReturnDueDateMonth,
                countryData?.taxReturnDueDateDay,
              )}
            />
            <SpanWithLabel
              labelText="Tax Reporting Period"
              value={monthDayFormat(
                countryData?.taxReportingPeriodDateMonth,
                countryData?.taxReportingPeriodDateDay,
              )}
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel
              labelText="Months To File"
              value={countryData?.monthsToFileFromReportingPeriodEnd}
            />
            <SpanWithLabel
              labelText="Align AGM With Fin Statement"
              value={countryData?.alignAgmWithFinancialStatement}
            />
            <SpanWithLabel
              labelText="Electronic Sigature Acceptable"
              value={countryData?.electronicSignatureAcceptable}
            />
            <SpanWithLabel
              labelText="External Filing Required"
              value={countryData?.externalFilingRequired}
            />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card
        title="Compliance Requirements"
        actionButton={renderAddActionButton('Compliance Requirement')}
        headingLevel={2}
        isLoading={configLoading}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <ShimmeredDetailsListWrapper
            columns={columns}
            items={countryData?.complianceRequirements || []}
            enableShimmer={configLoading}
            singularListLabel="Compliance Requirement"
            pluralListLabel="Compliance Requirements"
          />
        </Stack>
      </Card>
      {isComplianceRequirementPanelOpen && (
        <ComplianceRequirementEditPanel
          complianceRequirement={selectedComplianceRequirement}
          closePanel={toggleComplianceRequirementPanel}
        />
      )}
      {showDeleteConfirmation && (
        <Dialog
          hidden={!showDeleteConfirmation}
          onDismiss={() => {
            setShowDeleteConfirmation(false);
          }}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton onClick={deleteComplianceRequirementConfirmClick} text="Yes" />
            <DefaultButton
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};

export default CountryConfigurationInformation;
