import { useQuery } from '@apollo/client';
import { IDropdownOption, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, FieldValues, UseFormMethods } from 'react-hook-form';
import GET_SAP_LEVELS_QUERY from '../../../../utils/statApi/SapLevelApi';
import { ICompanySapFinancialInfo } from '../../../../utils/types/ICompanySapFinancialInfo';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';

interface ICompanySapFinancialInfoFormProps {
  form: UseFormMethods<FieldValues>;
  data: ICompanySapFinancialInfo;
}

const CompanySapFinancialInfoForm = ({
  form,
  data,
}: ICompanySapFinancialInfoFormProps): JSX.Element => {
  const { control, errors: formErrors, setValue } = form;

  const onSapLevelChange = (value: IDropdownOption): void => {
    setValue('sapLevel', value);
  };

  const SapLevelsDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors, handleChange, valueKey, outputKey } = props;
    const { loading, data: sapLevelData } = useQuery(GET_SAP_LEVELS_QUERY);
    const { sapLevels } = !loading && sapLevelData ? sapLevelData : [];
    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        label={label}
        required={required}
        outputKey={outputKey}
        dropdownOptionsData={sapLevels}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        as={TextField}
        id="shadowCompany"
        name="shadowCompany"
        label="Shadow Company"
        control={control}
        defaultValue={data?.shadowCompany || ''}
        disabled
      />
      <Controller
        name="sapLevel"
        label="SAP Level"
        valueKey="sapLevel"
        control={control}
        defaultValue={data?.sapLevel}
        render={(props: { value: IDropdownReference }) => {
          return (
            <SapLevelsDropdown
              label="SAP Level"
              valueKey="sapLevel"
              required
              errors={formErrors}
              handleChange={onSapLevelChange}
              value={props.value}
            />
          );
        }}
        rules={{
          required: 'Please select an SAP level',
        }}
      />
    </Stack>
  );
};

export default CompanySapFinancialInfoForm;
