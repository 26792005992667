import { useQuery } from '@apollo/client';
import { mergeStyles, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React from 'react';
import { GET_OVERDUE_MILESTONE_DASHBOARD_CARD_LIST } from '../../utils/statApi/MilestoneApi';
import { IMilestoneDashboardCardItem } from '../../utils/types/IMilestoneDashboardCardItem';
import InsightCard from './InsightCard';
import momentUtc from '../../utils/DateFormatter';
import StatDateFormats from '../../utils/types/DateFormats';
import {
  milestoneLiStyle,
  overdueMilestoneDateStyle,
  SvgColor,
} from '../../app/common/styles/CommonStyleObjects';
import MilestoneCardList from './MilestoneCardList';
import MilestoneCalendarSvg from '../common/svgs/MilestoneCalendarSvg';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';

const listStyle = mergeStyles({
  paddingLeft: 0,
});

const OverdueMilestonesCard = (): JSX.Element => {
  const { data, loading, error } = useQuery(GET_OVERDUE_MILESTONE_DASHBOARD_CARD_LIST, {
    fetchPolicy: 'cache-and-network',
  });
  let overdueMilestonesCardContent: JSX.Element = <></>;
  const overdueMilestonesCardTitle = 'My Overdue Milestones';

  if (!error && !loading) {
    if (
      data?.overdueMilestoneDashboardCardList !== null &&
      data?.overdueMilestoneDashboardCardList?.length > 0
    ) {
      const milestones: IMilestoneDashboardCardItem[] =
        data?.overdueMilestoneDashboardCardList?.map(
          (overdueMilestone: IMilestoneDashboardCardItem) => {
            return {
              id: overdueMilestone?.id,
              title: overdueMilestone?.title,
              companyCode: overdueMilestone?.companyCode,
              sapName: overdueMilestone?.sapName,
              processId: overdueMilestone?.processId,
              processTypeUri: overdueMilestone?.processTypeUri,
              dueDate: momentUtc(overdueMilestone?.dueDate, StatDateFormats.DaySimpleNoComma, true),
            };
          },
        );
      overdueMilestonesCardContent = (
        <MilestoneCardList milestones={milestones} milestoneDateStyle={overdueMilestoneDateStyle} />
      );
    } else {
      overdueMilestonesCardContent = (
        <Stack>
          <ul className={listStyle}>
            <li className={milestoneLiStyle}>You have no current overdue milestones</li>
          </ul>
        </Stack>
      );
    }
  } else if (loading) {
    overdueMilestonesCardContent = <Spinner size={SpinnerSize.medium} />;
  } else if (error) {
    overdueMilestonesCardContent = (
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading Overdue Milestones from the server"
      />
    );
  }

  return (
    <InsightCard
      caption={overdueMilestonesCardTitle}
      mainText={overdueMilestonesCardContent}
      svgComponent={
        <MilestoneCalendarSvg mainColor={SvgColor.lightRed} accentColor={SvgColor.darkRed} />
      }
      footerLink={<>Only the top 20 milestones are included in these results</>}
    />
  );
};

export default OverdueMilestonesCard;
