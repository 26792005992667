import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import momentUtc from '../DateFormatter';
import StatDateFormats from '../types/DateFormats';

export interface IFilterState<T> {
  filter: T;
}

const createDateString = (date: Date | string): string => {
  return momentUtc(date, StatDateFormats.TwoDigitDate);
};

interface IUrlFiltering<T> {
  defaultFilterObject: T;
  dateParameterKeys?: string[];
  arrayParameterKeys?: string[];
  numberParameterKeys?: string[];
  boolParameterKeys?: string[];
}

const useUrlFiltering = <T>(props: IUrlFiltering<T>) => {
  const {
    defaultFilterObject,
    dateParameterKeys,
    arrayParameterKeys,
    numberParameterKeys,
    boolParameterKeys,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const convertValue = (key: string, value: string) => {
    if (dateParameterKeys?.indexOf(key) > -1) {
      return new Date(value);
    }

    if (numberParameterKeys?.indexOf(key) > -1) {
      return parseInt(value, 10);
    }

    if (boolParameterKeys?.indexOf(key) > -1) {
      return value === 'true';
    }
    return value;
  };

  const initialState: T = Object.keys(defaultFilterObject).reduce((obj, key) => {
    const value = urlParams.get(key) || defaultFilterObject[key as keyof T];

    if (!value) return obj;

    if (arrayParameterKeys?.indexOf(key) > -1 && value !== null) {
      const arrayValue = value.toString().split(',');
      const typedArray = arrayValue.map((item) => convertValue(key, item));
      return { ...obj, [key]: typedArray };
    }

    return { ...obj, [key]: convertValue(key, value.toString()) };
  }, {} as T);

  const [filterState, setFilterState] = useState<IFilterState<T>>({ filter: initialState });

  useEffect(() => {
    Object.keys(filterState.filter).forEach((key) => {
      const value = filterState.filter[key as keyof T];

      if (value !== false && !value) {
        urlParams.delete(key);
        return;
      }

      if (dateParameterKeys?.indexOf(key) > -1 && value !== null) {
        urlParams.set(key, createDateString(value as Date));
        return;
      }

      if (arrayParameterKeys?.indexOf(key) > -1 && value !== null) {
        urlParams.set(key, (value as string[]).join(','));
        return;
      }

      urlParams.set(key, String(value));
    });

    history.replace(location.pathname.concat(`?${urlParams.toString()}`));
  }, [filterState]);

  return [filterState, setFilterState] as const;
};

export default useUrlFiltering;
