import { Configuration, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import config from '../components/common/ConfigLoader';

export const graphScopes = ['User.Read'];
export const getAppApiScopes = () => {
  return [`${config?.settings?.azureAdScopeUriPrefix}/user_impersonation`];
};

export const getLoginRequest = (): RedirectRequest => {
  return {
    scopes: graphScopes.concat(getAppApiScopes()),
  };
};

export const graphRequest: RedirectRequest = {
  scopes: graphScopes,
};

export const getStatApiRequest = (): RedirectRequest => {
  return {
    scopes: getAppApiScopes(),
  };
};

export const silentGraphRequest: SilentRequest = {
  scopes: graphScopes,
  account: null,
};

export const getSilentStatApiRequest = (): SilentRequest => {
  return {
    scopes: getAppApiScopes(),
    account: null,
  };
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphMePhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};

export const getMsalConfig = (): Configuration => {
  return {
    auth: {
      // make the value below configurable by the environment
      // the id below is for ppe and the above is for prod
      // clientId: '30b9b2d4-9520-46cf-8767-19f590d1941c',
      clientId: config?.settings?.azureAdAppClientId,
      authority: config?.settings?.azureAdAuthority,
      navigateToLoginRequestUrl: false,
      redirectUri: `${window.location.origin}/authcallback`,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  };
};
