import { IDropdownOption, SelectableOptionMenuItemType } from '@fluentui/react';

export const topSuggestedCurrencyCodes: IDropdownOption[] = [
  {
    key: 'suggested_USD',
    text: 'USD',
  },
  {
    key: 'suggested_EUR',
    text: 'EUR',
  },
];

export const allCurrencyCodesHeaderOption: IDropdownOption[] = [
  {
    key: 'divider',
    text: '-',
    itemType: SelectableOptionMenuItemType.Divider,
  },
  {
    key: 'allCodesHeader',
    text: 'All Currency',
    itemType: SelectableOptionMenuItemType.Header,
  },
];

export const suggestedCurrencyCodesHeaderOption: IDropdownOption[] = [
  {
    key: 'suggestedCodesHeader',
    text: 'Suggested Currency',
    itemType: SelectableOptionMenuItemType.Header,
  },
];

export const savedDividendCurrency = (currencyCode: string): IDropdownOption[] => {
  return [
    {
      key: `suggested_${currencyCode}`,
      text: currencyCode,
    },
  ];
};
