import { IPersonaProps, mergeStyles } from '@fluentui/react';
import React from 'react';
import searchForUsers from '../../../utils/microsoftGraphApi/userSearch';
import ErrorHandlingPeoplePicker from '../../common/formFields/ErrorHandlingPeoplePicker';
import { ICompanyCreationContact } from './types';

interface ICompanyContactsPicker {
  label?: string;
  controlName: string;
  onChange: (value: ICompanyCreationContact[]) => void;
  errorMessage?: string;
  itemLimit?: number;
  defaultValue?: ICompanyCreationContact[];
  removeButtonAriaLabel?: string;
}

const CompanyContactsPicker = ({
  errorMessage,
  onChange,
  label,
  controlName,
  itemLimit,
  defaultValue,
  removeButtonAriaLabel,
}: ICompanyContactsPicker): JSX.Element => {
  const PICKER_RESOLVE_DELAY = 450;

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  const getPersona = (users: ICompanyCreationContact[]): IPersonaProps[] => {
    if (users) {
      const results = users.map((user) => {
        return {
          id: user?.id.toString(),
          text: user?.name,
          graphId: user?.graphId,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          name: user?.name,
          alias: user?.alias,
          personnelNumber: user?.personnelNumber,
        };
      });

      return results;
    }
    return [];
  };
  /** We need this style because the people picker has an absolutely positioned div that overflows panels */
  const peoplePickerWrapperStyle = mergeStyles({ position: 'relative' });

  return (
    <div role="form" className={peoplePickerWrapperStyle}>
      <ErrorHandlingPeoplePicker
        removeButtonAriaLabel={removeButtonAriaLabel}
        controlName={controlName}
        labelText={label}
        onChange={(items?: IPersonaProps[]): void => {
          onChange(items as ICompanyCreationContact[]);
        }}
        defaultSelectedItems={getPersona(defaultValue)}
        onResolveSuggestions={onResolveSuggestions}
        resolveDelay={PICKER_RESOLVE_DELAY}
        itemLimit={itemLimit}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default CompanyContactsPicker;
