import React from 'react';
import {
  Stack,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  IGroup,
  Label,
  DetailsList,
  ColumnActionsMode,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import { StatUser, GroupIdentifier } from '../../utils/statApi/UsersApi';
import IUserRoles from '../../utils/types/IUserRoles';

interface IGraphUserBlockProps {
  statUser: StatUser;
}

const SecondaryFormFields = (props: IGraphUserBlockProps): JSX.Element => {
  const { statUser } = props;

  const orderedRoles = statUser.userRoles
    ?.sort((a, b) => {
      if (a.companyCode) return a.companyCode.localeCompare(b.companyCode);
      if (b.companyCode) return b.companyCode.localeCompare(a.companyCode);
      return 0;
    })
    ?.sort((a, b) => a.groupIdentifier.localeCompare(b.groupIdentifier));

  const columns: Array<IColumn> = [
    {
      key: 'companyCode',
      name: 'Company',
      fieldName: 'companyCode',
      minWidth: 50,
      maxWidth: 165,
      isResizable: false,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'role',
      name: 'Role',
      fieldName: 'roleName',
      minWidth: 100,
      maxWidth: 165,
      isResizable: false,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];

  const getGroups = (): Array<IGroup> => {
    const groups: IGroup[] = [];
    if (orderedRoles) {
      Object.keys(GroupIdentifier).forEach((key) => {
        type groupIdentifierKeys = keyof typeof GroupIdentifier;
        const count = orderedRoles.filter((role) => role.groupIdentifier === key).length;
        const name = GroupIdentifier[key as groupIdentifierKeys];
        if (count > 0) {
          const startIndex = orderedRoles.findIndex((role) => role.groupIdentifier === key);
          groups.push({ key, name, startIndex, count });
        }
      });
    }
    return groups;
  };

  const renderCompanyCode = (companyCode: string): JSX.Element => {
    return <Link to={`/companies/${companyCode}/service-contacts`}>{companyCode}</Link>;
  };

  const renderItemColumn = (item: IUserRoles, index: number, column: IColumn): JSX.Element => {
    const propertyKey = column.fieldName as keyof IUserRoles;
    const fieldContent = item[propertyKey];
    const isServiceContact = item.groupIdentifier === 'COMPANY';
    switch (column.key) {
      case 'companyCode': {
        return (
          <span>
            {item.groupIdentifier === 'GLOBAL'
              ? 'All Companies'
              : renderCompanyCode(item.companyCode)}
          </span>
        );
      }
      case 'role': {
        return <span>{isServiceContact ? item.serviceContactName : item.roleName}</span>;
      }
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const renderList = (): JSX.Element => {
    if (orderedRoles?.length > 0) {
      return (
        <DetailsList
          ariaLabelForGrid="Current Roles"
          items={orderedRoles}
          columns={columns}
          groups={getGroups()}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={false}
          onRenderItemColumn={renderItemColumn}
          onShouldVirtualize={() => false}
        />
      );
    }
    return <span>No roles assigned</span>;
  };

  if (!statUser) {
    return <></>;
  }
  return (
    <Stack>
      <Label>Current Roles</Label>
      {renderList()}
    </Stack>
  );
};

export default SecondaryFormFields;
