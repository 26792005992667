import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { getLocalizedDateWithUtcDay, getUtcTodayWithNoTime } from '../../utils/Helpers';
import { GET_NOTIFICATION_TYPES_QUERY } from '../../utils/statApi/NotificationsApi';
import IDropdownReference from '../../utils/types/IDropdownReference';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import UtcDatePicker from '../common/utcDatePicker/UtcDatePicker';
import { IStatNotification } from './StatNotification';

const NotificationTypeDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_NOTIFICATION_TYPES_QUERY);
  const { notificationTypes } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={notificationTypes}
      errors={errors}
      handleChange={handleChange}
      tooltipProps={{
        showTooltip: true,
        tooltipContent:
          'All notifications show up in the panel at the top right of the screen. Other options add additional display locations.',
      }}
      valueKey={valueKey}
      required
    />
  );
};

interface NotificationFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormMethods<Record<string, any>>;
  notification: IStatNotification;
}

const GetDefaultMinEndDate = (startDate: Date): Date => {
  return startDate
    ? getLocalizedDateWithUtcDay(moment(startDate).add(1, 'days').toDate())
    : getLocalizedDateWithUtcDay(moment(new Date()).add(1, 'days').toDate());
};

const NotificationForm = ({ form, notification }: NotificationFormProps): JSX.Element => {
  const { control, errors, setValue, register, getValues } = form;
  const [minEndDate, setMinEndDate] = useState(GetDefaultMinEndDate(notification?.startDate));

  const handleStartDateChange = (utcDateString: string) => {
    setValue('startDate', utcDateString);

    const localizedDate = getLocalizedDateWithUtcDay(new Date(utcDateString));

    setMinEndDate(moment(localizedDate).add(1, 'days').toDate());

    if (new Date(getValues('endDate')) <= localizedDate) {
      setValue('endDate', moment(utcDateString).add(1, 'day').toISOString());
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <input type="hidden" ref={register()} name="id" defaultValue={notification?.id} />

      <Controller
        as={TextField}
        label="Header"
        id="header"
        name="header"
        control={control}
        required
        defaultValue={notification?.header || ''}
        errorMessage={errors?.header?.message}
        rules={{
          required: 'Please enter a notification header',
          maxLength: {
            value: 100,
            message: 'Input is greater than the maximum character limit of 100.',
          },
        }}
      />
      <Controller
        as={TextField}
        label="Content"
        id="content"
        name="content"
        control={control}
        required
        multiline
        resizable={false}
        defaultValue={notification?.content || ''}
        errorMessage={errors?.content?.message}
        rules={{
          required: 'Please enter notification content',
          maxLength: {
            value: 250,
            message: 'Input is greater than the maximum character limit of 250.',
          },
        }}
      />

      <Controller
        name="notificationType"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <NotificationTypeDropdown
            label="Notification Type"
            value={notification?.notificationType}
            valueKey="notificationType"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('notificationType', newValue);
            }}
            errors={errors}
          />
        )}
        defaultValue={notification?.notificationType || null}
        rules={{
          required: 'Please select a notification type',
        }}
        required
      />

      <Controller
        name="startDate"
        control={control}
        as={UtcDatePicker}
        label="Notification Start Date"
        textField={{
          name: 'startDate',
          errorMessage: errors?.startDate?.message,
        }}
        defaultValue={notification?.startDate || getUtcTodayWithNoTime()}
        onSelectDate={(utcDateString: string): void => {
          handleStartDateChange(utcDateString);
        }}
        minDate={new Date()}
        rules={{
          required: 'Please enter a start date',
        }}
        isRequired
      />

      <Controller
        name="endDate"
        control={control}
        as={UtcDatePicker}
        label="Notification End Date"
        textField={{
          name: 'endDate',
          errorMessage: errors?.endDate?.message,
        }}
        defaultValue={
          notification?.endDate || moment(getUtcTodayWithNoTime()).add(1, 'day').toISOString()
        }
        onSelectDate={(utcDateString: string): void => {
          setValue('endDate', utcDateString);
        }}
        minDate={minEndDate}
        rules={{
          required: 'Please enter an end date',
        }}
        isRequired
      />
    </Stack>
  );
};

export default NotificationForm;
