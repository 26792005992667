import React, { useEffect } from 'react';
import { DocumentNode, useLazyQuery } from '@apollo/client';
import { DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import {
  bodyContentContainer,
  styledDetailsList,
} from '../../../app/common/styles/CommonStyleObjects';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ShimmeredDetailsListWrapper, {
  GetUpdatedColumnState,
} from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import DetailListPaginationBanner, {
  calculateSkip,
  getTotalPageCount,
} from '../../common/DetailsLists/pagination/DetailsListPaginationBanner';
import { IDetailsListColumnSortProps } from '../../../utils/types/IDetailsListColumnSortProps';
import useUrlPagination from '../../../utils/hooks/useUrlPagination';
import IPaginationMetadata from '../../common/DetailsLists/pagination/IPaginationMetadata';
import useUrlSorting, { getSortObject } from '../../../utils/hooks/useUrlSorting';

interface IPaginationProps {
  query: DocumentNode;
  columns: IColumn[];
  label: string;
  queryResultKey: string;
  defaultSortColumn: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchDto?: any;
  children?: JSX.Element;
}

const Pagination = (props: IPaginationProps) => {
  const { query, columns, label, queryResultKey, defaultSortColumn, searchDto, children } = props;

  const [paginationState, setPaginationState] = useUrlPagination();
  const [sortedState, setSortedState] = useUrlSorting(defaultSortColumn);
  const firstLoad = React.useRef(true);

  const sortedColumns = GetUpdatedColumnState(
    sortedState.sortedFieldName,
    columns,
    (sortedState.sortOrder === 'DESC').toString(),
  );

  const [refetch, { data, loading, error }] = useLazyQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: 0,
      take: paginationState.pageSize,
      order: sortedState.sortObject,
      searchDto,
    },
  });

  useEffect(() => {
    let skip = 0;
    if (firstLoad.current) {
      firstLoad.current = false;
      skip = paginationState.skip;
    } else {
      setPaginationState({ ...paginationState, currentPage: 1 });
    }

    refetch({
      variables: {
        skip,
        take: paginationState.pageSize,
        order: sortedState.sortObject,
        searchDto,
      },
    });
  }, [searchDto]);

  const onPageSizeChange = (newPageSize: string | number): void => {
    const paginationData = {
      currentPage: 1,
      pageSize: newPageSize as number,
      skip: 0,
    };

    setPaginationState(paginationData);

    refetch({
      variables: {
        skip: paginationData.skip,
        take: paginationData.pageSize,
        order: sortedState.sortObject,
        searchDto,
      },
    });
  };

  const onPageChange = (
    startItemIndex: number,
    endItemIndex: number,
    newPageNumber: number,
  ): void => {
    const paginationData = structuredClone(paginationState);
    paginationData.currentPage = newPageNumber;
    paginationData.skip = calculateSkip(paginationData.pageSize, newPageNumber);
    refetch({
      variables: {
        skip: paginationData.skip,
        take: paginationData.pageSize,
        order: sortedState.sortObject,
        searchDto,
      },
    });

    setPaginationState(paginationData);
  };

  const totalRecordCount: number = data ? data[queryResultKey]?.totalCount : 0;

  const paginationMetadata: IPaginationMetadata = {
    loadingData: loading,
    pageCount: getTotalPageCount(totalRecordCount, paginationState.pageSize),
    selectedPage: paginationState.currentPage,
    pageSize: paginationState.pageSize,
    onPageChange,
    onPageSizeChange,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateStateResetPagination = (newState: any) => {
    const paginationData = structuredClone(paginationState);
    paginationData.currentPage = 1;
    paginationData.skip = 0;

    const newSortedState = structuredClone(sortedState);

    const order = getSortObject(
      newState.sortedFieldName,
      newState.isSortedDescending === 'true' ? 'DESC' : 'ASC',
    );

    newSortedState.sortedFieldName = newState.sortedFieldName;
    newSortedState.sortObject = order;
    newSortedState.sortOrder = newState.isSortedDescending === 'true' ? 'DESC' : 'ASC';

    refetch({
      variables: {
        skip: paginationData.skip,
        take: paginationData.pageSize,
        order,
        searchDto,
      },
    });

    setPaginationState(paginationData);
    setSortedState(newSortedState);
  };

  // These event handlers are not used, but instead one state update for the sorting and pagination
  // are done in the above function.  This is to prevent multiple renders.
  const translateSortOrder = (isSortedDescending: boolean) => {};
  const setSortedFieldName = (sortedFieldName: string) => {};
  const setSortedColumns = (newColumns: IColumn[]) => {};

  const detailsListColumnSortProps: IDetailsListColumnSortProps = {
    columns: sortedColumns,
    sortedFieldName: sortedState.sortedFieldName,
    searchState: searchDto,
    isSortedDescending: sortedState.sortOrder === 'DESC',
    setColumns: setSortedColumns,
    setSortedFieldName,
    setIsSortedDescending: translateSortOrder,
    updateStateResetPagination,
  };

  const items = data ? data[queryResultKey]?.items : [];
  return (
    <>
      <FullWidthHeader
        title={() => <h1>{label}</h1>}
        subtitle={() => {
          return <>{children}</>;
        }}
      />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <LoadingErrorMessage error={error} loading={loading} />
        <ShimmeredDetailsListWrapper
          items={items?.length ? items : []}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          enableShimmer={loading}
          selectionMode={SelectionMode.none}
          detailsListStyles={styledDetailsList}
          ariaLabelForShimmer={`${label} are being fetched`}
          ariaLabelForGrid={`${label} details`}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          pluralListLabel={label}
          singularListLabel={label}
          isColumnSortedList
          detailsListColumnSortProps={detailsListColumnSortProps}
        />
        {paginationMetadata && <DetailListPaginationBanner {...paginationMetadata} />}
      </div>
    </>
  );
};

export default Pagination;
