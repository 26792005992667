import React, { useEffect } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import ProtectedRoute from '../../authentication/ProtectedRoute';

interface IPageProps extends RouteProps {
  title: string;
  requestedPermission?: string;
  render?: (props: RouteComponentProps<unknown>) => React.ReactNode;
}

const Page: React.FunctionComponent<IPageProps> = (props) => {
  const { title, requestedPermission, render, ...rest } = props;

  const pageTitle = title?.length ? `Stat | ${title}` : 'Stat';

  useEffect(() => {
    document.title = pageTitle;
  });

  if (requestedPermission) {
    return <ProtectedRoute {...rest} render={render} requestedPermission={requestedPermission} />;
  }
  return <Route {...rest} render={render} />;
};

export default Page;
