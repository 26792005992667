import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { InternalRefetchQueryDescriptor, useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { PanelType } from '@fluentui/react';
import IAgmDetail from '../../../utils/types/IAgmDetail';
import { panelFooterStyle } from '../../../app/common/styles/CommonStyleObjects';
import AgmDetailForm from '../detail/forms/AgmDetailForm';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams, IProcessIdParams } from '../../common/ParamTypes';
import CanAccess from '../../common/canAccess/CanAccess';
import {
  COMPANY_DEFAULT_AGM_DETAILS_QUERY,
  UPDATE_AGM_DETAILS,
} from '../../../utils/statApi/CompanyFiscalYearsApi';
import CoherencePanelWithSubheader from '../../common/CoherencePanelWithSubheader';
import ActionButtons from '../../common/ActionButtons';

interface IAgmDetailEditPanelProps {
  agmDetail: IAgmDetail;
  closePanel: () => void;
  refetchQueries: InternalRefetchQueryDescriptor[];
}

const AgmDetailEditPanel: React.FunctionComponent<IAgmDetailEditPanelProps> = ({
  agmDetail,
  closePanel,
  refetchQueries,
}: IAgmDetailEditPanelProps): JSX.Element => {
  const form = useForm<IAgmDetail>();
  const { processId } = useParams<IProcessIdParams>();
  const { companyCode } = useParams<ICompanyCodeParams>();

  const { handleSubmit } = form;

  const [updateAgmDetails, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_AGM_DETAILS,
    {
      refetchQueries,
      onCompleted: closePanel,
    },
  );

  const [fetchCompanyDefaultAgmDetails, { data, loading: defaultAgmDetailsLoading }] = useLazyQuery(
    COMPANY_DEFAULT_AGM_DETAILS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        companyCode,
      },
    },
  );

  useEffect(() => {
    if (!agmDetail?.id && !defaultAgmDetailsLoading) {
      fetchCompanyDefaultAgmDetails({
        variables: {
          companyCode,
        },
      });
    }
  }, []);

  const companyDefaultAgmDetails = data?.companyDefaultAgmDetails;

  const defaultAgmDetail: IAgmDetail = {
    // Fields with potential default values
    id: companyDefaultAgmDetails?.id,
    agmDeadlineBeforeAfsDeadline: companyDefaultAgmDetails?.agmDeadlineBeforeAfsDeadline,
    taxFilingDeadlineBeforeAfsDeadline:
      companyDefaultAgmDetails?.taxFilingDeadlineBeforeAfsDeadline,
    taxFilingDeadline: companyDefaultAgmDetails?.taxFilingDeadline,
    externalAfsFilingRequired: companyDefaultAgmDetails?.externalAfsFilingRequired,
    physicalSignatureRequired: companyDefaultAgmDetails?.physicalSignatureRequired,
    hardCopyShippingAddress: companyDefaultAgmDetails?.hardCopyShippingAddress,
    requiredToBeFiledWithAfs: companyDefaultAgmDetails?.requiredToBeFiledWithAfs,
    inkColorRequired: companyDefaultAgmDetails?.inkColorRequired,
    signatureInkColor: companyDefaultAgmDetails?.signatureInkColor,
    originalsCount: companyDefaultAgmDetails?.originalsCount,
    paperSize: companyDefaultAgmDetails?.paperSize,
    otherPaperSize: companyDefaultAgmDetails?.otherPaperSize,
    auditorFeesCurrencyCode: companyDefaultAgmDetails?.auditorFeesCurrencyCode,
    auditingFirm: {
      id: companyDefaultAgmDetails?.auditingFirm?.id,
      fieldName: companyDefaultAgmDetails?.auditingFirm?.fieldName,
    },
    otherAuditingFirm: companyDefaultAgmDetails?.otherAuditingFirm,
    proposedDividendAllowed: companyDefaultAgmDetails?.id,
    dividendCurrentYear: companyDefaultAgmDetails?.id,
    dividendRetainedEarnings: companyDefaultAgmDetails?.id,
    canAfsBeExtended: companyDefaultAgmDetails?.canAfsBeExtended,
    managementRepresentationLetterRequired:
      companyDefaultAgmDetails?.managementRepresentationLetterRequired,
    additionalProcessingTimeApostille: companyDefaultAgmDetails?.additionalProcessingTimeApostille,
    additionalProcessingTimeLegalization:
      companyDefaultAgmDetails?.additionalProcessingTimeLegalization,
    additionalProcessingTimeLocalSigner:
      companyDefaultAgmDetails?.additionalProcessingTimeLocalSigner,
    filingDueDate: agmDetail?.filingDueDate,
    annualGeneralMeetingDueDateMonth: agmDetail?.annualGeneralMeetingDueDateMonth,
    annualGeneralMeetingDueDateDay: agmDetail?.annualGeneralMeetingDueDateDay,

    // Fields without potential default values
    processId: null,
    hardCopyShippingContactName: null,
    hardCopyShippingPhoneNumber: null,
    finalExternalFilingSubmitter: null,
    otherFinalExternalFilingSubmitter: null,
    auditorFees: null,
    auditorAppointmentPeriod: null,
    dividendCurrencyCode: null,
    additionalProcessingTime: null,
    dividendsReviewId: null,
    dividendsReviewStatus: null,
  };

  const handleOnSaveClick = (formData: IAgmDetail): void => {
    const auditingFirm = formData.auditingFirm
      ? {
          id: formData.auditingFirm.id,
          fieldName: formData.auditingFirm.fieldName,
        }
      : null;
    updateAgmDetails({
      variables: {
        input: {
          id: agmDetail?.id || -1,
          agmDeadlineBeforeAfsDeadline: formData.agmDeadlineBeforeAfsDeadline,
          taxFilingDeadlineBeforeAfsDeadline: formData.taxFilingDeadlineBeforeAfsDeadline,
          taxFilingDeadline: formData.taxFilingDeadline,
          externalAfsFilingRequired: formData.externalAfsFilingRequired,
          physicalSignatureRequired: formData.physicalSignatureRequired,
          hardCopyShippingAddress: formData.hardCopyShippingAddress,
          hardCopyShippingContactName: formData.hardCopyShippingContactName,
          hardCopyShippingPhoneNumber: formData.hardCopyShippingPhoneNumber,
          requiredToBeFiledWithAfs: formData.requiredToBeFiledWithAfs,
          inkColorRequired: formData.inkColorRequired,
          signatureInkColor: formData.signatureInkColor,
          originalsCount: formData.originalsCount ? parseInt(formData.originalsCount, 10) : null,
          paperSize: formData.paperSize,
          otherPaperSize: formData.otherPaperSize,
          finalExternalFilingSubmitter: formData.finalExternalFilingSubmitter,
          otherFinalExternalFilingSubmitter: formData.otherFinalExternalFilingSubmitter,
          auditorFees: formData.auditorFees ? parseInt(formData.auditorFees.toString(), 10) : null,
          auditorFeesCurrencyCode: formData.auditorFeesCurrencyCode,
          auditingFirm,
          otherAuditingFirm: formData.otherAuditingFirm,
          auditorAppointmentPeriod: formData.auditorAppointmentPeriod,
          canAfsBeExtended: formData.canAfsBeExtended,
          managementRepresentationLetterRequired: formData.managementRepresentationLetterRequired,
          additionalProcessingTimeApostille: formData.additionalProcessingTime.some(
            (at) => at === 'APOSTILLE',
          ),
          additionalProcessingTimeLegalization: formData.additionalProcessingTime.some(
            (at) => at === 'LEGALIZATION',
          ),
          additionalProcessingTimeLocalSigner: formData.additionalProcessingTime.some(
            (at) => at === 'LOCALSIGNER',
          ),
        },
        processId: processId ? parseInt(processId, 10) : agmDetail?.processId,
      },
    });
  };

  return (
    <CoherencePanelWithSubheader
      headerText="Edit AGM Details"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      isFooterAtBottom
      isLightDismiss
      styles={panelFooterStyle}
      type={PanelType.medium}
      closeButtonAriaLabel="Close AGM Details Edit"
      subheader={
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the AGM Details to the server"
        />
      }
      onRenderFooterContent={(): JSX.Element => (
        <CanAccess requestedAction="AgmDetail:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveTitle="Save AGM Details"
            saveLabel="Save AGM Details"
            cancelTitle="Cancel"
            cancelLabel="Cancel"
          />
        </CanAccess>
      )}
    >
      <form>
        {!defaultAgmDetailsLoading && (
          // If there is not currently an AGM Review for the process, check for AGM defaults values for the company
          <AgmDetailForm form={form} agmDetail={agmDetail?.id ? agmDetail : defaultAgmDetail} />
        )}
      </form>
    </CoherencePanelWithSubheader>
  );
};

export default AgmDetailEditPanel;
