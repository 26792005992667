import React, { useState } from 'react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { DocumentNode, useMutation } from '@apollo/client';
import { Stack, Dropdown, TextField, IDropdownOption } from '@fluentui/react';
import { THIRD_PARTY } from '../../../../app/common/constants/SiteConstants';
import { IFilingInfo } from '../../serviceContacts/interfaces';
import ActionButtons from '../../../common/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import validateRequiredFilingInfo from '../../fiscalYears/validators/FilingInfoValidator';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import OversightGroupDropdown from '../../fiscalYears/OversightGroupDropdown';

interface IFilingInfoFormProps {
  closePanel: () => void;
  filingInfoData: IFilingInfo;
  mutation: DocumentNode;
  parentId: string | number;
  parentIdName: string;
}

const FilingInfoForm = (props: IFilingInfoFormProps): JSX.Element => {
  const { closePanel, filingInfoData, mutation, parentId, parentIdName } = props;
  const [pageState, setPageState] = useState({ ...filingInfoData });

  const [updateFilingInfo, { loading, error }] = useMutation(mutation, {
    onCompleted: () => {
      closePanel();
    },
  });

  const onOverSightGroupChange = (newValue: IDropdownReference, valueKey: string): void => {
    setPageState({ ...pageState, oversightGroup: newValue });
  };

  const onResponsibleForStatFilingPreparationChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    if (option.key.toString() !== THIRD_PARTY) {
      setPageState({
        ...pageState,
        responsibleForStatFilingPreparation: option.key.toString(),
        responsibleForStatFilingPreparationThirdParty: null,
      });
    } else {
      setPageState({
        ...pageState,
        responsibleForStatFilingPreparation: option.key.toString(),
      });
    }
  };

  const onResponsibleForRecordChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    if (option.key.toString() !== THIRD_PARTY) {
      setPageState({
        ...pageState,
        responsibleForRecord: option.key.toString(),
        responsibleForRecordThirdParty: null,
      });
    } else {
      setPageState({ ...pageState, responsibleForRecord: option.key.toString() });
    }
  };

  const onResponsibleForStatFilingPreparationThirdPartyChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ): void => {
    setPageState({ ...pageState, responsibleForStatFilingPreparationThirdParty: newValue });
  };

  const onResponsibleForRecordChangeThirdParty = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ): void => {
    setPageState({ ...pageState, responsibleForRecordThirdParty: newValue });
  };

  const onOtherInfoChange = (
    eevent: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ): void => {
    setPageState({ ...pageState, otherInformation: newValue });
  };

  const validateForm = (): void => {
    const errors = validateRequiredFilingInfo(pageState);

    if (!Object.keys(errors).length) {
      const payload = {
        id: pageState.id || -1,
        oversightGroup: pageState.oversightGroup
          ? {
              id: pageState.oversightGroup?.id,
              fieldName: pageState.oversightGroup?.fieldName,
            }
          : null,
        responsibleForStatFilingPreparation: pageState.responsibleForStatFilingPreparation,
        responsibleForStatFilingPreparationThirdParty:
          pageState.responsibleForStatFilingPreparationThirdParty || '',
        responsibleForRecord: pageState.responsibleForRecord,
        responsibleForRecordThirdParty: pageState.responsibleForRecordThirdParty || '',
        otherInformation: pageState.otherInformation || '',
      };

      updateFilingInfo({
        variables: {
          [parentIdName]: parentId,
          filingInfo: payload,
        },
      });
    }

    setPageState({ ...pageState, errors });
  };

  return (
    <CoherencePanel
      closeButtonAriaLabel="Close Filing Info Edit Panel"
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Filing Information"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          closePanel={closePanel}
          saveLabel="Save Filing Information"
          saveTitle="Save Filing Information"
          cancelLabel="Cancel Filing Information Edit"
          cancelTitle="Cancel Filing Information Edit"
          handleSubmit={validateForm}
          mutationLoading={loading}
        />
      )}
    >
      <LoadingErrorMessage loading={loading} error={error} />
      <form>
        <Stack>
          <OversightGroupDropdown
            label="Oversight Group"
            valueKey="oversightGroup"
            required
            errors={pageState?.errors}
            handleChange={onOverSightGroupChange}
            value={pageState.oversightGroup}
          />
          <Stack.Item>
            <Dropdown
              label="Responsible for STAT Filing Preparation"
              required
              placeholder="Select an option"
              options={[
                { key: 'BPO', text: 'BPO' },
                { key: 'DAVA', text: 'DAVA' },
                { key: 'ONEFINANCE', text: 'One Finance' },
                { key: 'ROCCOE', text: 'ROC COE' },
                { key: 'STATUTORYCONTROLLER', text: 'Statutory Controller' },
                { key: THIRD_PARTY, text: 'Third Party' },
                { key: 'NOFILINGREQUIREMENT', text: 'No Filing Required' },
                { key: 'ENTITYTERMINATED', text: 'Entity Terminated' },
              ]}
              selectedKey={pageState.responsibleForStatFilingPreparation}
              onChange={onResponsibleForStatFilingPreparationChange}
              errorMessage={pageState.errors?.responsibleForStatFilingPreparation?.message}
            />
            <br />
            <TextField
              ariaLabel="Name of Third Party"
              placeholder="Name of Third Party"
              multiline
              rows={3}
              disabled={pageState.responsibleForStatFilingPreparation !== THIRD_PARTY}
              resizable={false}
              value={pageState.responsibleForStatFilingPreparationThirdParty}
              onChange={onResponsibleForStatFilingPreparationThirdPartyChange}
              errorMessage={
                pageState?.errors?.responsibleForStatFilingPreparationThirdParty?.message
              }
            />
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              label="Responsible for Record"
              required
              placeholder="Select an option"
              options={[
                { key: 'BPO', text: 'BPO' },
                { key: 'CORP', text: 'Corp' },
                { key: 'DAVA', text: 'DAVA' },
                { key: 'LOCALFINANCE', text: 'Local Finance' },
                { key: THIRD_PARTY, text: 'Third Party' },
                { key: 'NOFILINGREQUIREMENT', text: 'No Filing Required' },
                { key: 'ENTITYTERMINATED', text: 'Entity Terminated' },
              ]}
              selectedKey={pageState.responsibleForRecord}
              onChange={onResponsibleForRecordChange}
              errorMessage={pageState?.errors?.responsibleForRecord?.message}
            />
            <br />
            <TextField
              ariaLabel="Name of Third Party"
              placeholder="Name of Third Party"
              multiline
              rows={3}
              disabled={pageState.responsibleForRecord !== THIRD_PARTY}
              resizable={false}
              value={pageState.responsibleForRecordThirdParty}
              onChange={onResponsibleForRecordChangeThirdParty}
              errorMessage={pageState?.errors?.responsibleForRecordThirdParty?.message}
            />
          </Stack.Item>
          <TextField
            ariaLabel="Other Information"
            label="Other Information"
            multiline
            resizable={false}
            value={pageState?.otherInformation}
            onChange={onOtherInfoChange}
          />
        </Stack>
      </form>
    </CoherencePanel>
  );
};

export default FilingInfoForm;
