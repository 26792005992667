import { useQuery } from '@apollo/client';
import { Separator } from '@fluentui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { cardContentWrapperStyles } from '../../../app/common/styles/CommonStyleObjects';
import { GET_COMPANY_PROCESS_EXTENSTION_DOCUMENTS } from '../../../utils/statApi/CompaniesApi';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import DocumentCardList from '../../common/documents/DocumentCardList';
import { IDocument } from '../../../utils/statApi/DocumentApi';

interface IProcessExtensionDocumentsProps {
  showDeletedFiles: boolean;
}

const ProcessExtensionDocuments = ({
  showDeletedFiles,
}: IProcessExtensionDocumentsProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { data, loading, error } = useQuery(GET_COMPANY_PROCESS_EXTENSTION_DOCUMENTS, {
    variables: {
      companyCode,
      showDeletedFiles,
    },
    fetchPolicy: 'network-only',
  });

  const companyDocuments: IDocument[] = data?.companyProcessExtensionDocuments || [];

  return (
    <div className={cardContentWrapperStyles}>
      <Separator />
      <DocumentCardList
        loading={loading}
        error={error}
        docType="ProcessExtension"
        documents={companyDocuments}
        showDeletedFiles={showDeletedFiles}
      />
    </div>
  );
};

export default ProcessExtensionDocuments;
