import { useQuery } from '@apollo/client';
import {
  CommandBar,
  CommandButton,
  ICommandBarItemProps,
  ICommandBarStyles,
  IContextualMenuProps,
  INavLink,
  INavLinkGroup,
  NeutralColors,
  PrimaryButton,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import { GET_PROCESS_TEMPLATE_BY_TYPE } from '../../../utils/statApi/ProcessTemplateApi';
import { IProcessTemplate } from '../../../utils/types/IProcessTemplate';
import BackButton from '../../common/BackButton';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import MainDetailsView from '../../common/mainDetail/MainDetailsView';
import { IProcessTemplateTypeParams } from '../../common/ParamTypes';
import ProcessTemplateAddPanel from './ProcessTemplateAddPanel';
import ProcessTemplateDetails from './ProcessTemplateDetails';

const ProcessTemplatesListPage = (): JSX.Element => {
  const { processTypeId, processTemplateId } = useParams<IProcessTemplateTypeParams>();
  const [emptyResults, setEmptyResults] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [isAddProcessTemplatePanelOpen, setIsAddProcessTemplatePanelOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const { loading, data, error, refetch } = useQuery(GET_PROCESS_TEMPLATE_BY_TYPE, {
    variables: { processTypeId: parseInt(processTypeId, 10), isActiveFilter: true },
    fetchPolicy: 'cache-and-network',
  });

  const { processTemplatesByType }: { processTemplatesByType: IProcessTemplate[] } = data || {
    processTemplatesByType: [],
  };
  const processTemplateTypeName =
    !loading && processTemplatesByType ? processTemplatesByType[0]?.processType?.name : null;

  useEffect(() => {
    setEmptyResults(false);
    if (!processTemplateId && !loading) {
      const firstProcessTemplateId = data?.processTemplatesByType[0]?.id;
      if (firstProcessTemplateId) {
        history.replace(`/process-templates/${processTypeId}/template/${firstProcessTemplateId}`);
      } else {
        setEmptyResults(true);
      }
    }
  }, [data, loading]);

  const navCommandBarItems: ICommandBarItemProps[] = [];
  if (isActiveFilter) {
    navCommandBarItems.push({
      key: 'addProcessTemplate',
      text: 'Add Process Template',
      iconProps: { iconName: 'Add' },
      buttonStyles: { root: { background: NeutralColors.gray10 } },
      onClick: () => {
        setIsAddProcessTemplatePanelOpen(true);
      },
    });
  }

  const navCommandBarStyles: ICommandBarStyles = {
    root: {
      padding: 0,
      background: NeutralColors.gray10,
    },
  };
  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const pieces = uri.split('/');
  const activeBaseSegment = pieces.slice(0, 2);
  const baseUrl = activeBaseSegment.join('/');

  const getNavLinkGroups = (): INavLinkGroup[] => {
    const processTemplateNavLinks = processTemplatesByType.map(
      (processTemplate: IProcessTemplate, index: number) => {
        return {
          name: processTemplate.name,
          ariaLabel: processTemplate.name,
          url: processTemplate.id.toString(),
          onClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink): void => {
            ev.preventDefault();
            ev.stopPropagation();
            history.push(`/${baseUrl}/template/${processTemplate.id}`);
          },
        } as INavLink;
      },
    );

    return [{ links: processTemplateNavLinks }];
  };
  const getHeaderTitle = (): JSX.Element => {
    return <h1>Template Management</h1>;
  };

  const onFilterUpdate = (isActive: boolean): void => {
    history.replace(`/process-templates/${processTypeId}`);
    refetch({
      processTypeId: parseInt(processTypeId, 10),
      isActiveFilter: isActive,
    });
    setIsActiveFilter(isActive);
  };

  const templateButtonProps = (): IContextualMenuProps => {
    const props: IContextualMenuProps = {
      items: [
        {
          key: 'Active',
          text: `Active Templates`,
          iconProps: { iconName: 'ActivateOrders' },
          onClick: () => {
            onFilterUpdate(true);
          },
        },
        {
          key: 'Deactivated',
          text: `Deactivated Templates`,
          iconProps: { iconName: 'DeactivateOrders' },
          onClick: () => {
            onFilterUpdate(false);
          },
        },
      ],
    };

    return props;
  };

  const buttonText = isActiveFilter ? 'Active' : 'Deactivated';

  const getBackButton = (): JSX.Element => {
    return (
      <BackButton ariaLabel="Back to process types list" goToUrl="/process-templates">
        <h2>{`${
          loading
            ? ''
            : `Process Templates ${processTemplateTypeName ? `for ${processTemplateTypeName}` : ''}`
        }`}</h2>
      </BackButton>
    );
  };

  /** We need to find a way to get the process type title in the child view */
  // const getBodyHeaderTitle = (): JSX.Element => {
  //   return <Subheader title="Need to get process type title" />;
  // };

  const getCurrentProcessTemplate = () => {
    return processTemplatesByType.find((processTemplate) => {
      return processTemplate.id === parseInt(processTemplateId, 10);
    });
  };
  const getNavSectionHeader = (): JSX.Element => {
    return (
      <>
        {navCommandBarItems.length > 0 && (
          <CommandBar
            styles={navCommandBarStyles}
            items={navCommandBarItems}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
        )}
      </>
    );
  };

  return (
    <>
      <FullWidthHeader title={getHeaderTitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBackButton}>
            <CommandButton
              ariaLabel="Select option to filter by status"
              iconProps={{
                iconName: isActiveFilter ? 'ActivateOrders' : 'DeactivateOrders',
              }}
              text={`${buttonText} Templates`}
              menuProps={templateButtonProps()}
              allowDisabledFocus
            />
          </BodyHeaderWithCommandBar>
          {emptyResults && (
            <div className={bodyContentWrapper}>
              <p>
                {`There are no ${buttonText.toLowerCase()} process templates for the current process type`}
              </p>
              {isActiveFilter && (
                <PrimaryButton
                  iconProps={{ iconName: 'Add' }}
                  text=" Add Process Template"
                  onClick={() => {
                    setIsAddProcessTemplatePanelOpen(true);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading process templates from database"
      />
      {processTemplateId && processTemplatesByType.length > 0 && (
        <MainDetailsView
          navLinkGroups={getNavLinkGroups()}
          navSectionHeader={getNavSectionHeader()}
        >
          <ProcessTemplateDetails processTemplateDetail={getCurrentProcessTemplate()} />
        </MainDetailsView>
      )}
      {isAddProcessTemplatePanelOpen && (
        <ProcessTemplateAddPanel
          closePanel={() => {
            setIsAddProcessTemplatePanelOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ProcessTemplatesListPage;
