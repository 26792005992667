import { mergeStyles, NeutralColors } from '@fluentui/react';
import React, { useState } from 'react';
import { IProcessTemplateMilestone } from '../../../../utils/types/IProcessTemplate';
import ProcessTemplateMilestoneCardHeader from './ProcessTemplateMilestoneCardHeader';

interface IProcessTemplateMilestoneListCardProps {
  milestone: IProcessTemplateMilestone;
  children: React.ReactNode;
}
const completedCardBg = NeutralColors.gray20;

const pendingBg = NeutralColors.white;
const ProcessTemplateMilestoneListCard = (
  props: IProcessTemplateMilestoneListCardProps,
): JSX.Element => {
  const { milestone, children } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const getCardWrapperStyles = mergeStyles({
    marginBottom: 35,
    backgroundColor: isCollapsed ? completedCardBg : pendingBg,
    transition: 'background 300ms ease-in',
    selectors: {
      '&:hover': {
        boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.2), 0 0.3px 0.9px 0 rgba(0,0,0,.25)',
      },
    },
  });
  const cardBodyWrapperStyles = mergeStyles({
    padding: '25px 35px 25px 35px',
  });

  return (
    <div key={milestone.id} className={`ms-depth-4 ${getCardWrapperStyles}`}>
      <ProcessTemplateMilestoneCardHeader
        onHeaderClick={setIsCollapsed}
        title={milestone.name.toString()}
        contentIsCollapsed={isCollapsed}
        isGoalMilestone={milestone.defaultDaysOffset === 0}
      />
      {!isCollapsed && <div className={`${cardBodyWrapperStyles}`}>{children}</div>}
    </div>
  );
};
export default ProcessTemplateMilestoneListCard;
