import { gql } from '@apollo/client';
import { DIVIDENDS_FRAGMENT } from './CompanyFiscalYearsApi';

const PROCESS_LIST_QUERY = gql`
  query ProcessList($processTypeUri: String!, $companyCode: String!, $status: ProcessStatus!) {
    processList(processTypeUri: $processTypeUri, companyCode: $companyCode, status: $status) {
      id
      name
      description
      uri
      processes {
        id
        processConfigTitle
        processStartDate
        processEndDate
      }
    }
  }
`;

export const PROCESS_COUNT_BY_TYPE_QUERY = gql`
  query ProcessCountByType($status: ProcessStatus!) {
    processCountByType(status: $status) {
      count
      id
      name
    }
  }
`;

export const PROCESS_SEARCH_QUERY = gql`
  query ProcessSearch(
    $processSearchDto: ProcessSearchDtoInput
    $paginationDto: PaginationDtoInput
  ) {
    processSearch(processSearchDto: $processSearchDto, paginationDto: $paginationDto) {
      items {
        id
        processName
        companyCode
        companyName
        companyArea
        processTypeUri
        processType
        processName
        filingDueDate
        reportingPeriodEndDate
        countryName
        countryCode
        currentMilestoneTitle
        currentMilestoneDueDate
        milestoneStatuses {
          milestoneId
          status
          category
        }
      }
      totalCount
      currentPage
      pageSize
    }
  }
`;

export const PROCESS_CONFIG_DEACTIVATE_QUERY = gql`
  query ProcessListEligibleForDeactivate($processConfigId: Int!) {
    processListEligibleForDeactivate(processConfigId: $processConfigId) {
      processes {
        id
        processConfigTitle
        processStartDate
        processEndDate
        status
      }
      eligibleForDelete
    }
  }
`;

export const MILESTONE_FRAGMENT = gql`
  fragment Milestone on MilestoneDto {
    id
    title
    description
    dueDate
    maxDaysOffset
    minimumDuration
    maximumDuration
    actionText
    commentText
    ordinal
    status
    attestationText
    category
    assignees {
      id
      graphId
      name
    }
    milestoneRules {
      id
      type
      isComplete
      data
    }
    processTemplateVersionId
  }
`;

export const MILESTONE_DUE_DATES_EDIT_FRAGMENT = gql`
  fragment MilestoneDueDateEdit on MilestoneDueDateEditDto {
    id
    title
    description
    dueDate
    maxDaysOffset
    minimumDuration
    maximumDuration
    ordinal
    status
    processFilingDueDate
  }
`;
export const PROCESS_DETAIL_FRAGMENT = gql`
  fragment ProcessDetail on ProcessDto {
    id
    name
    processType
    processStatus
    filingDueDate
    originalFilingDueDate
    reportingPeriodEndDate
    agmFiscalYear {
      id
      companyFiscalYearId
      agmStatus {
        id
        fieldName
      }
      celaAgmStatusDate
      agmDueDateDay
      agmDueDateMonth
      agmInformation
    }
    companyFiscalYearId
    statutoryAuditFinding
    remediationPlanInPlace
    expectedClosureDate
    reasonForFilingViolationComments
    processConfig {
      id
    }
    hasProcessExtensionRequest
    hasPendingProcessExtensionRequest
    reasonForFilingViolation {
      id
      fieldName
    }
    reasonForReportExclusionComments
    reasonForReportExclusion {
      id
      fieldName
    }
    excludeFromMetrics
    processExceptionApproved
    reportExceptionApprovalReasonComments
    reportExceptionApprovedByDisplayName
    reportExceptionApprovedByGraphGUID
    reasonForReportingExclusionApproval {
      id
      fieldName
    }
    auditOpinion {
      id
      fieldName
    }
    corpReasonForLateFiling {
      id
      fieldName
    }
    corpReasonForLateFilingComment
    milestones {
      ...Milestone
    }
    dividends {
      ...Dividends
    }
  }
  ${MILESTONE_FRAGMENT}
  ${DIVIDENDS_FRAGMENT}
`;

export const GET_PROCESS_QUERY = gql`
  query ProcessDetail($processId: Int!) {
    processDetail(processId: $processId) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const UPDATE_PROCESS_DETAIL_MUTATION = gql`
  mutation updateProcessDetails($processDetails: ProcessAuditOpinionDtoInput!) {
    updateProcessDetails(processDetails: $processDetails) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const GET_PROCESS_DIVIDENDS = gql`
  query ProcessDividends($processId: Int!) {
    companyFiscalYearDividends(processId: $processId) {
      ...Dividends
    }
  }
  ${DIVIDENDS_FRAGMENT}
`;

export const GET_PROCESS_AGM_STATUS = gql`
  query ProcessAgmStatus($processId: Int!) {
    companyFiscalYearAgmStatus(processId: $processId) {
      id
      agmStatus {
        id
        fieldName
      }
      celaAgmStatusDate
      agmDueDateDay
      agmDueDateMonth
      agmInformation
    }
  }
`;

export const EDIT_DIVIDENDS_FROM_PROCESS_MUTATION = gql`
  mutation updateDividendsFromProcess($dividends: CompanyDividendsDtoInput!, $processId: Int!) {
    updateDividendsFromProcess(dividends: $dividends, processId: $processId) {
      process {
        ...ProcessDetail
      }
      dividends {
        ...Dividends
      }
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
  ${DIVIDENDS_FRAGMENT}
`;

export const EDIT_AGM_STATUS_FROM_PROCESS_MUTATION = gql`
  mutation updateAgmStatusFromProcess($agmStatus: AgmFiscalYearInputDtoInput!, $processId: Int!) {
    updateAgmStatusFromProcess(agmStatus: $agmStatus, processId: $processId) {
      process {
        ...ProcessDetail
      }
      agmStatus {
        id
        agmStatus {
          id
          fieldName
        }
        celaAgmStatusDate
        agmDueDateDay
        agmDueDateMonth
      }
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;
/**
 * Here we are assuming that reason for filing violation is on the
 * process level. AKA: Only one milestone will be tracking reason
 * for filing violation
 */
export const GET_REASONS_FOR_FILING_VIOLATION = gql`
  {
    reasonsForFilingViolation {
      reasonForFilingViolationId
      reasonForFilingViolationComments
    }
  }
`;
export const GET_PROCESS_REASON_FOR_FILING_VIOLATION = gql`
  query getProcessReasonForFilingViolation($processId: Int!) {
    processReasonForFilingViolation(processId: $processId) {
      reasonForFilingViolationId
      reasonForFilingViolationComments
    }
  }
`;

export const GET_PROCESS_REASON_FOR_FILING_VIOLATION_HISTORY = gql`
  query reasonForFilingViolationHistory($processId: Int!) {
    reasonForFilingViolationHistory(processId: $processId) {
      id
      processId
      reasonForFilingViolation
      comments
      updatedDate
      statUser {
        displayName
      }
    }
  }
`;

export const EDIT_PROCESS_REASON_FOR_FILING_VIOLATION = gql`
  mutation updateProcessReasonForFilingViolation(
    $processReasonForFilingViolation: ProcessReasonForFilingViolationDtoInput!
  ) {
    updateProcessReasonForFilingViolation(
      processReasonForFilingViolation: $processReasonForFilingViolation
    ) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const EDIT_CORP_REASON_FOR_LATE_FILING_MUTATION = gql`
  mutation updateCorpReasonForLateFiling(
    $processCorpReasonForLateFiling: ProcessCorpReasonForLateFilingDtoInput!
  ) {
    updateCorpReasonForLateFiling(processCorpReasonForLateFiling: $processCorpReasonForLateFiling) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const EDIT_REASON_FOR_REPORTING_EXCLUSION_MUTATION = gql`
  mutation updateReasonForProcessInstanceReportingExclusion(
    $processReasonForReportingExclusion: ProcessReasonForReportingExclusionDtoInput!
  ) {
    updateReasonForProcessInstanceReportingExclusion(
      processReasonForReportingExclusion: $processReasonForReportingExclusion
    ) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const GET_PROCESS_MILESTONE_DUE_DATES_EDIT = gql`
  query ProcessMilestoneDueDatesEdit($processId: Int!) {
    processMilestoneDueDatesEdit(processId: $processId) {
      ...MilestoneDueDateEdit
    }
  }
  ${MILESTONE_DUE_DATES_EDIT_FRAGMENT}
`;

export const PROCESS_STATUS_SUMMARY_QUERY = gql`
  query processStatusSummary($processStatusSummarySearchDto: ProcessStatusSummarySearchDtoInput) {
    processStatusSummary(processStatusSummarySearchDto: $processStatusSummarySearchDto) {
      countOfProcessesWithFutureMilestones
      countOfProcessesWithUpcomingMilestones
      countOfProcessesWithLateMilestones
      countOfCompletedProcesses
      processTypeSummary {
        processTypeName
        processTypeId
        countOfProcessesWithFutureMilestones
        countOfProcessesWithUpcomingMilestones
        countOfProcessesWithLateMilestones
        countOfCompletedProcesses
      }
    }
  }
`;
export default PROCESS_LIST_QUERY;
