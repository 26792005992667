enum FiscalYearSummaryFormType {
  AgmStatus,
  AgmDetails,
  AuditStatus,
  Dividends,
  TaxDividends,
  OtherInfo,
  DividendPaymentEdit,
}

export default FiscalYearSummaryFormType;
