import React, { useState } from 'react';
import { Panel, PrimaryButton, Stack, TextField } from '@fluentui/react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
  IMilestone,
  MilestoneStatus,
  MilestoneActionType,
  MilestoneRuleType,
  IFinancialStatementProcess,
} from '../Interfaces';
import MilestoneCard from './MilestoneCard';
import TimelineNavItem from './TimelineNav';
import MilestoneContent from './MilestoneContent';
import MilestoneSignoffAction from './MilestoneSignoffAction';
import MilestoneRescindAction from './MilestoneRescindAction';
import { CollapseAllStatus } from '../ProcessDetail';

import {
  COMMENT_MILESTONE_MUTATION,
  GET_ALL_MILESTONE_HISTORY,
} from '../../../../utils/statApi/MilestoneApi';
import MilestoneHistoryList from '../MilestoneHistory';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';

interface IMilestoneListItemProps {
  milestone: IMilestone;
  process: IFinancialStatementProcess;
  openEditPanel: (focusedMilestoneId: number) => void;
  toggleDividendsPanel: () => void;
  allCollapsed: CollapseAllStatus;
  isLast?: boolean;
}

const MilestoneListItem: React.FunctionComponent<IMilestoneListItemProps> = ({
  milestone,
  process,
  openEditPanel,
  toggleDividendsPanel,
  allCollapsed,
  isLast,
}: IMilestoneListItemProps): JSX.Element => {
  const [cardActionMode, setCardActionMode] = useState(MilestoneActionType.normal);

  const [showComments, setShowComments] = useState<boolean>(false);

  const cardDisabled = milestone.status === MilestoneStatus.notStarted;

  const [
    createMilestoneCommentRequest,
    { loading: createCommentLoading, error: createCommentError },
  ] = useMutation(COMMENT_MILESTONE_MUTATION);
  const [commentText, setCommentText] = React.useState('');

  const getIcon = (): string => {
    if (cardActionMode !== MilestoneActionType.normal) {
      return 'cancel';
    }
    if (milestone.status === MilestoneStatus.complete) {
      return 'undo';
    }
    return 'insertSignatureLine';
  };

  const iconClick = (): void => {
    if (getIcon() === 'undo') {
      setCardActionMode(MilestoneActionType.rescind);
    } else if (getIcon() === 'cancel') {
      setCardActionMode(MilestoneActionType.normal);
    } else {
      setCardActionMode(MilestoneActionType.attestation);
    }
  };

  const hasOutstandingMilestoneGates = (): boolean => {
    if (!milestone.milestoneRules || milestone.status === MilestoneStatus.complete) {
      return false;
    }
    const outstandingGates = milestone.milestoneRules.filter((rule) => {
      const isBeforeFilingDueDate =
        moment.utc(new Date()).startOf('day') <= moment(process.filingDueDate);
      if (rule.type === MilestoneRuleType.reasonForViolation && isBeforeFilingDueDate) {
        return false;
      }
      return !rule.isComplete;
    });
    return outstandingGates.length > 0;
  };

  const isCardActionDisabled = (): boolean => {
    return cardDisabled || hasOutstandingMilestoneGates();
  };

  const isComplete = milestone.status === MilestoneStatus.complete;
  const actionText = cardActionMode === MilestoneActionType.normal ? milestone.actionText : '';
  const timelineWrapperStyles = { root: { marginRight: 30 } };
  const milestoneWrapperStyles = { root: { width: '100%' } };

  const showProcessExtensionWarning =
    milestone.maxDaysOffset === 0 && process.hasPendingProcessExtensionRequest;

  const onCommentTextChange = (e: React.FormEvent, newValue: string) => {
    setCommentText(newValue);
  };
  const onCommentsSaveClick = () => {
    createMilestoneCommentRequest({
      variables: {
        milestoneId: milestone?.id,
        comment: commentText,
      },
      refetchQueries: [GET_ALL_MILESTONE_HISTORY],
    });
    setCommentText('');
  };

  return (
    <>
      <Panel isOpen={showComments} isLightDismiss type={3} onDismiss={() => setShowComments(false)}>
        <Stack>
          <h1>Milestone Information</h1>
          <h2>{milestone.title}</h2>
          <p>{milestone.description}</p>
          <h3>History</h3>
          <Stack tokens={{ childrenGap: 10 }}>
            <LoadingErrorMessage
              loading={createCommentLoading}
              error={createCommentError}
              actionName="saving the comment to the server"
            />
            <Stack horizontal tokens={{ childrenGap: '10px' }}>
              <Stack.Item grow>
                <TextField
                  value={commentText}
                  onChange={onCommentTextChange}
                  placeholder="Add Comment"
                />
              </Stack.Item>

              <PrimaryButton
                disabled={commentText.trim().length === 0 || createCommentLoading}
                onClick={onCommentsSaveClick}
              >
                Add Comment
              </PrimaryButton>
            </Stack>
          </Stack>
          <MilestoneHistoryList milestone={milestone} />
        </Stack>
      </Panel>
      <div>
        <Stack horizontal>
          <Stack.Item className="ms-hiddenMdDown" grow={0} styles={timelineWrapperStyles}>
            <TimelineNavItem
              status={milestone.status}
              isLast={isLast}
              isGoalMilestone={milestone.maxDaysOffset === 0}
            />
          </Stack.Item>
          <Stack.Item grow={1} styles={milestoneWrapperStyles}>
            <MilestoneCard
              title={milestone.title}
              actionText={actionText}
              commentText={milestone.commentText}
              disabled={isCardActionDisabled()}
              showProcessExtensionWarning={showProcessExtensionWarning}
              status={milestone.status}
              complete={isComplete}
              icon={getIcon()}
              collapseAllStatus={allCollapsed}
              iconClick={iconClick}
              setShowComments={setShowComments}
            >
              {cardActionMode === MilestoneActionType.normal && (
                <MilestoneContent
                  milestone={milestone}
                  openDueDateEditPanel={openEditPanel}
                  toggleDividendsPanel={toggleDividendsPanel}
                  setViewComments={setShowComments}
                  viewComments={showComments}
                />
              )}
              {cardActionMode === MilestoneActionType.attestation && (
                <MilestoneSignoffAction milestone={milestone} submit={setCardActionMode} />
              )}
              {cardActionMode === MilestoneActionType.rescind && (
                <MilestoneRescindAction milestone={milestone} submit={setCardActionMode} />
              )}
            </MilestoneCard>
          </Stack.Item>
        </Stack>
      </div>
    </>
  );
};

export default MilestoneListItem;
