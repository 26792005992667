import { gql } from '@apollo/client';

const GET_CORP_REASON_FOR_LATE_FILING_QUERY = gql`
  {
    corpReasonsForLateFiling {
      id
      fieldName
    }
  }
`;
export default GET_CORP_REASON_FOR_LATE_FILING_QUERY;
