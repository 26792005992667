import { ApolloError } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { DefaultButton, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';

import ActionButtons from '../common/ActionButtons';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import RejectButtonMode from './RejectButtonMode';

interface IRejectButtonProps {
  disabled?: boolean;
  onClick: (reason?: string, callback?: () => void) => void;
  mode: RejectButtonMode;
  mutationLoading: boolean;
  mutationError: ApolloError;
}

interface IRejectReason {
  reason?: string;
}

const validateReason = (reason: string): string => {
  if (reason.trim().replace(/\s\s+/g, ' ').length < 1) {
    return 'Reason cannot be blank';
  }
  return null;
};

const RejectButton = ({
  disabled,
  onClick,
  mode,
  mutationLoading,
  mutationError,
}: IRejectButtonProps): JSX.Element => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { handleSubmit, control, errors } = useForm();

  const onRequestChangesClick = (): void => {
    setIsPanelOpen(true);
  };

  const closePanel = (): void => {
    setIsPanelOpen(false);
  };

  const onSubmit = ({ reason }: IRejectReason) => {
    onClick(reason, closePanel);
  };

  const displayLabel = mode === RejectButtonMode.Reject ? 'Reject Request' : 'Request Changes';
  const commentLabel = mode === RejectButtonMode.Reject ? 'Rejecting' : 'Requesting Changes';
  const buttonLabel = mode === RejectButtonMode.Reject ? 'Reject' : 'Request Changes';

  return (
    <>
      <DefaultButton text={buttonLabel} disabled={disabled} onClick={onRequestChangesClick} />
      <CoherencePanel
        panelSize={CoherencePanelSize.medium}
        titleText={displayLabel}
        isOpen={isPanelOpen}
        onDismiss={closePanel}
        styles={CoherencePanelStyles}
        hasCloseButton
        closeButtonAriaLabel={`Close ${displayLabel}`}
        onRenderFooter={(): JSX.Element => (
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(onSubmit)}
            saveLabel={displayLabel}
            saveTitle={displayLabel}
            cancelLabel={`Cancel ${displayLabel}`}
            cancelTitle={`Cancel ${displayLabel}`}
          />
        )}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoadingErrorMessage loading={mutationLoading} error={mutationError} />
          <Controller
            name="reason"
            control={control}
            rules={{
              required: 'A reason is required',
              validate: validateReason,
            }}
            as={TextField}
            defaultValue=""
            label={`Reason For ${commentLabel}`}
            errorMessage={errors?.reason?.message}
            required
            multiline
          />
        </form>
      </CoherencePanel>
    </>
  );
};

export default RejectButton;
