import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import getColumns from './CompaniesListConfig';
import ErrorMessage from '../../common/errorContent/ErrorMessage';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import IItemListProps from '../../../utils/types/IItemListProps';
import { ICompanyBase } from '../../../utils/types/ICompany';
import monthDayFormat from '../../../utils/MonthDayFormatter';

const CompaniesList = (props: IItemListProps<ICompanyBase>): JSX.Element => {
  const { items, isLoading } = props;

  const location = useLocation();
  // main column link
  const renderStatutoryYearEnd = (item: {
    statutoryYearEndMonth: number;
    statutoryYearEndDay: number;
  }): JSX.Element => {
    if (!item.statutoryYearEndDay || !item.statutoryYearEndMonth) {
      return <span>--</span>;
    }
    return <span>{monthDayFormat(item?.statutoryYearEndMonth, item?.statutoryYearEndDay)}</span>;
  };

  // main column link
  const renderCompanyCodeLink = (item: { companyCode: React.ReactNode }): JSX.Element => (
    <Link to={`/companies/${item.companyCode}/processes`}>{item.companyCode}</Link>
  );

  const columns = getColumns({
    renderCompanyCodeLink,
    renderStatutoryYearEnd,
  });

  const getNotFoundMessage = (): boolean => {
    const result = new URLSearchParams(location.search).get('NotFound');
    return result === 'true';
  };

  return (
    <>
      {getNotFoundMessage() && (
        <ErrorMessage>
          <span>The company was not found or you do not have access to that company.</span>
        </ErrorMessage>
      )}
      <ShimmeredDetailsListWrapper
        columns={columns}
        items={items || []}
        enableShimmer={isLoading}
        singularListLabel="Company"
        pluralListLabel="Companies"
      />
    </>
  );
};
export default CompaniesList;
