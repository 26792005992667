import React from 'react';
import { Stack, StackItem } from '@fluentui/react';
import { commandBarWrapper } from '../../users/UsersStyles';
import { pageHeaderFullWidth } from './Headers.styles';

interface SubheaderProps {
  title: string;
  children?: JSX.Element;
}
const Subheader: React.FunctionComponent<SubheaderProps> = (props: SubheaderProps) => {
  const { title, children } = props;

  const Styles = pageHeaderFullWidth();
  return (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
      <h2 className={Styles.pageHeaderSubtitle}>{title}</h2>
      <Stack grow horizontal verticalAlign="center" horizontalAlign="end">
        <StackItem className={commandBarWrapper}>{children}</StackItem>
      </Stack>
    </Stack>
  );
};
export default Subheader;
