import { DetailsList, SelectionMode, Stack } from '@fluentui/react';
import React from 'react';
import { Action } from '../../utils/statApi/RolesApi';
import Card from '../common/card/Card';
import SpanWithLabel from '../common/SpanWithLabel';
import StackColumn from '../common/StackColumn';
import getColumns from './ActionList.config';

interface IActionDetail {
  action: Action;
}
const ActionDetail = ({ action }: IActionDetail): JSX.Element => {
  const columns = getColumns();

  return (
    <>
      {action && (
        <>
          <Card title={action?.displayName}>
            <Stack horizontal wrap horizontalAlign="space-around" tokens={{ childrenGap: 60 }}>
              <StackColumn>
                <SpanWithLabel labelText="Description" value={action?.description} />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Category" value={action?.category} />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card title="Roles">
            <DetailsList
              items={action?.roles || []}
              columns={columns}
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              compact
              selectionMode={SelectionMode.none}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default ActionDetail;
