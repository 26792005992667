import { useQuery } from '@apollo/client';
import { DefaultButton, Dropdown, IDropdownOption, ITag, Separator, Stack } from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import { filterWrapperStyles } from '../../../app/common/styles/CommonStyleObjects';
import GET_ENTITY_STATUSES_QUERY from '../../../utils/statApi/EntityStatusApi';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import ProcessSearchCompanyPicker from '../../processes/processSearch/ProcessSearchCompanyPicker';
import ProcessSearchCountryPicker from '../../processes/processSearch/ProcessSearchCountryPicker';
import { multiDropdownMediumWrapper } from '../documents/CompanyDocumentStyles';
import { ICompaniesSearchState } from './CompaniesListPage';
import FilterWwAreaPicker from './FilterWwAreaPicker';
import StatUserPicker from './StatUserPicker';

interface ICompaniesListFilterProps {
  onResetFiltersClick: () => void;
  filterState: ICompaniesSearchState;
  onFieldChange: (propertyName: keyof ICompaniesSearchState, value: number[] | string[]) => void;
}
const CompaniesListFilter = (props: ICompaniesListFilterProps): JSX.Element => {
  const { onResetFiltersClick, filterState, onFieldChange } = props;
  const {
    companyCodes,
    countryCodes,
    entityStatusIds,
    statutoryYearEndMonths,
    statutoryControllerIds,
    secRegionAreaCodes,
  } = filterState;
  const { loading, data } = useQuery(GET_ENTITY_STATUSES_QUERY);
  const { entityStatuses: entityStatusesSource } = !loading && data ? data : [];
  const entityStatusOptions = entityStatusesSource?.map((entityStatus: IDropdownReference) => {
    return { key: entityStatus.id, text: entityStatus.fieldName };
  });
  const statutoryYearEndMonthOptions = moment.months().map((month: string, index: number) => {
    return {
      key: index + 1,
      text: month,
    };
  });

  const onUpdateEntityStatus = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ): void => {
    const itemsFromState = item.selected
      ? [...entityStatusIds, item.key as number]
      : entityStatusIds.filter((id) => id !== item.key);
    onFieldChange('entityStatusIds' as keyof ICompaniesSearchState, itemsFromState);
  };

  const onUpdateStatutoryYearEndMonths = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ): void => {
    const itemsFromState = item.selected
      ? [...statutoryYearEndMonths, item.key as number]
      : statutoryYearEndMonths.filter((id) => id !== item.key);
    onFieldChange('statutoryYearEndMonths' as keyof ICompaniesSearchState, itemsFromState);
  };
  const onUpdateStatutoryController = (itemIds: number[]): void => {
    onFieldChange('statutoryControllerIds' as keyof ICompaniesSearchState, itemIds);
  };

  return (
    <Stack.Item grow disableShrink styles={filterWrapperStyles}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <h2>Filter</h2>
        <DefaultButton text="Reset Filters" onClick={onResetFiltersClick} />
      </Stack>
      <ProcessSearchCompanyPicker
        initialSelectedCodes={companyCodes}
        onCompanyUpdate={(items: ITag[]) => {
          onFieldChange(
            'companyCodes',
            items.map((item) => item.name.split(' ')[0].toString()),
          );
        }}
      />
      <Separator />
      <ProcessSearchCountryPicker
        initialSelectedCodes={countryCodes}
        onCountryUpdate={(items: ITag[]) => {
          onFieldChange(
            'countryCodes',
            items.map((item) => item.key.toString()),
          );
        }}
      />
      <Separator />
      <FilterWwAreaPicker
        initialSelectedCodes={secRegionAreaCodes}
        onRegionAreaUpdate={(items: ITag[]) => {
          onFieldChange(
            'secRegionAreaCodes',
            items.map((item) => item.key.toString()),
          );
        }}
      />
      <Separator />
      <Dropdown
        label="Entity Status"
        className={multiDropdownMediumWrapper}
        onChange={(_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
          return onUpdateEntityStatus(_event, item);
        }}
        selectedKeys={entityStatusIds}
        multiSelect
        placeholder="Select an option"
        options={entityStatusOptions}
      />
      <Separator />
      <Dropdown
        label="Statutory Year End Month"
        className={multiDropdownMediumWrapper}
        onChange={(_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
          return onUpdateStatutoryYearEndMonths(_event, item);
        }}
        selectedKeys={statutoryYearEndMonths}
        multiSelect
        placeholder="Select an option"
        options={statutoryYearEndMonthOptions}
      />
      <Separator />
      <StatUserPicker
        label="Statutory Controller"
        initialSelectedItems={statutoryControllerIds}
        onInputFieldUpdate={(items) => {
          onUpdateStatutoryController(items);
        }}
      />
    </Stack.Item>
  );
};
export default CompaniesListFilter;
