import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  ActionButton,
  PrimaryButton,
  DialogType,
  Spinner,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DELETE_MILESTONE_CATEGORY,
  GET_PROCESS_TYPE_CATEGORIES,
} from '../../../../utils/statApi/MilestoneTemplateApi';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IProcessTemplateTypeParams } from '../../../common/ParamTypes';

interface IMilestoneCategoriesDeleteButtonProps {
  id: number;
}

const MilestoneCategoriesDeleteButton = ({
  id,
}: IMilestoneCategoriesDeleteButtonProps): JSX.Element => {
  const [isDeleteConfirmHidden, setIsDeleteConfirmHidden] = useState(true);
  const [error, setError] = useState(null);

  const hideDeleteDialog = (): void => {
    setIsDeleteConfirmHidden(true);
    setError(null);
  };

  const { processTypeId } = useParams<IProcessTemplateTypeParams>();
  const processTypeIdInt = parseInt(processTypeId, 10);
  const [deleteCategory, { loading }] = useMutation(DELETE_MILESTONE_CATEGORY, {
    onCompleted: () => {
      hideDeleteDialog();
    },
    refetchQueries: [
      {
        query: GET_PROCESS_TYPE_CATEGORIES,
        variables: { processTypeId: processTypeIdInt },
      },
    ],
    onError: setError,
  });

  const deleteButtonClick = (): void => {
    setIsDeleteConfirmHidden(false);
  };

  const deleteConfirmClick = (): void => {
    deleteCategory({
      variables: {
        milestoneCategoryId: id,
      },
    });
  };

  const modalPropsStyles = { main: { maxWidth: 450 } };
  const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Are you sure',
  };

  return (
    <>
      <ActionButton
        label="Delete Category"
        aria-label="Delete Category"
        iconProps={{ iconName: 'delete' }}
        onClick={deleteButtonClick}
      >
        Delete Category
      </ActionButton>
      <Dialog
        hidden={isDeleteConfirmHidden}
        onDismiss={hideDeleteDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <LoadingErrorMessage error={error} loading={loading} />
        <p>Are you sure you want to delete this milestone category?</p>
        {loading && <Spinner />}
        <DialogFooter>
          <PrimaryButton disabled={loading} onClick={deleteConfirmClick} text="Yes" />
          <DefaultButton disabled={loading} onClick={hideDeleteDialog} text="No" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default MilestoneCategoriesDeleteButton;
