import { ApolloError } from '@apollo/client';
import moment from 'moment';
import { FontSizes, Stack, StackItem } from '@fluentui/react';
import React from 'react';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';
import Card from '../../common/card/Card';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import FileSelectorItem from '../../common/formFields/fileUpload/fileSelectorItem';
import SpanWithLabel from '../../common/SpanWithLabel';
import StackColumn from '../../common/StackColumn';
import ServiceContactList from '../../companies/serviceContacts/ServiceContactList';
import { ICompanyCreationInfo } from './types';
import monthDayFormat from '../../../utils/MonthDayFormatter';

interface ICompnayRequestInfoCards {
  companyRequestInfo: ICompanyCreationInfo;
  loading: boolean;
  error: ApolloError;
  showCurrencyDocument: boolean;
  showMsSalesLocation: boolean;
  showShadowCompanyCard: boolean;
  showStatContactsCard: boolean;
  showCompanyCreationContactsCard: boolean;
  showFunctionalCurrency: boolean;
  showSapSetup: boolean;
  showIhccSetup: boolean;
  showSubledger: boolean;
  currencyActionButton?: React.ReactNode;
  legalDocumentActionButton?: React.ReactNode;
  sapSetupActionButton?: React.ReactNode;
  ihccActionButton?: React.ReactNode;
  shadowCompanyActionButton?: React.ReactNode;
  subledgerActionButton?: React.ReactNode;
  statutoryInfoActionButton?: React.ReactNode;
  companyCreationContactsButton?: React.ReactNode;
  isShadowRequest?: boolean;
}

const CompanyRequestInfoCards = ({
  companyRequestInfo,
  loading,
  error,
  showCurrencyDocument,
  showMsSalesLocation,
  showShadowCompanyCard,
  showStatContactsCard,
  showCompanyCreationContactsCard,
  showFunctionalCurrency,
  showSapSetup,
  showIhccSetup,
  showSubledger,
  currencyActionButton,
  legalDocumentActionButton,
  sapSetupActionButton,
  ihccActionButton,
  shadowCompanyActionButton,
  subledgerActionButton,
  statutoryInfoActionButton,
  companyCreationContactsButton,
  isShadowRequest,
}: ICompnayRequestInfoCards): JSX.Element => {
  const functionalCurrency = companyRequestInfo?.functionalCurrency;
  const functionalCurrencyDocument = functionalCurrency?.functionalCurrencyDocument;
  const legalDocumentInfo = companyRequestInfo?.legalDocumentInfo;
  const sapSetupInfo = companyRequestInfo?.sapSetupInfo;
  const shadowCompanyInfo = companyRequestInfo?.shadowCompanyInfo;
  const inHouseCashCenterSetup = companyRequestInfo?.inHouseCashCenterSetup;
  const statutoryInformation = companyRequestInfo?.statutoryInformation;
  const companyContacts = companyRequestInfo?.statutoryContacts;
  const subledgerInfo = companyRequestInfo?.subledgerInfo;

  const titlePrefix = isShadowRequest ? 'Parent Company ' : '';

  const subHeadingStyle = {
    fontSize: FontSizes.size14,
    fontWeight: 600,
  };

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      {showFunctionalCurrency && (
        <>
          <Card
            title={`${titlePrefix}Functional Currency`}
            headingLevel={2}
            isLoading={loading}
            actionButton={currencyActionButton}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Functional Currency"
                  value={functionalCurrency?.functionalCurrency}
                />
                <SpanWithLabel
                  labelText="Statutory Reporting Currency"
                  value={functionalCurrency?.statutoryCurrency}
                />
              </StackColumn>
              {showCurrencyDocument && (
                <StackColumn>
                  <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
                    {functionalCurrencyDocument && (
                      <StackItem
                        key={`${functionalCurrencyDocument.id}-${functionalCurrencyDocument.title}}`}
                      >
                        <FileSelectorItem
                          index={0}
                          user={functionalCurrencyDocument.uploader}
                          url={functionalCurrencyDocument.url}
                          fileName={functionalCurrencyDocument.title}
                          uiDisabled={false}
                          allowDelete={false}
                          allowDownload
                          allowViewInTab
                          activity={`Uploaded ${moment(
                            functionalCurrencyDocument.uploadDate,
                          ).format(StatDateFormats.DayBreakout)}`}
                        />
                      </StackItem>
                    )}
                    {!functionalCurrencyDocument && <p>No document has been uploaded yet.</p>}
                  </Stack>
                </StackColumn>
              )}
            </Stack>
          </Card>
          <br />
        </>
      )}
      <Card
        title={`${titlePrefix}Legal Document Info`}
        headingLevel={2}
        isLoading={loading}
        actionButton={legalDocumentActionButton}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Legal Full Name:" value={legalDocumentInfo?.fullLegalName} />
            <SpanWithLabel labelText="SAP Legal Name:" value={legalDocumentInfo?.sapLegalName} />
            <SpanWithLabel
              labelText="SAP Name (Short Form):"
              value={legalDocumentInfo?.abbreviatedSapName}
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel
              labelText="Statutory Year End:"
              value={monthDayFormat(
                legalDocumentInfo?.statutoryYearEndMonth,
                legalDocumentInfo?.statutoryYearEndDay,
              )}
            />
            <SpanWithLabel
              labelText="Incorporated Date:"
              value={momentUtc(
                legalDocumentInfo?.incorporatedDate,
                StatDateFormats.DayBreakout,
                true,
              )}
            />
          </StackColumn>
        </Stack>
        <h3 style={subHeadingStyle}>Legally Registered Address</h3>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="Street:"
              value={legalDocumentInfo?.legallyRegisteredAddress?.streetNumber}
            />
            <SpanWithLabel
              labelText="Street 2:"
              value={legalDocumentInfo?.legallyRegisteredAddress?.streetNumber2}
            />
            <SpanWithLabel
              labelText="City:"
              value={legalDocumentInfo?.legallyRegisteredAddress?.city}
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel
              labelText="State/Province/Region:"
              value={legalDocumentInfo?.legallyRegisteredAddress?.stateProvinceRegion?.name}
            />
            <SpanWithLabel
              labelText="Postal/Zip Code:"
              value={legalDocumentInfo?.legallyRegisteredAddress?.postalZipCode}
            />
            <SpanWithLabel
              labelText="Country:"
              value={legalDocumentInfo?.legallyRegisteredAddress?.country?.shortName}
            />
          </StackColumn>
        </Stack>
        <h3 style={subHeadingStyle}>Physical Address</h3>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="Street:"
              value={legalDocumentInfo?.physicalAddress?.streetNumber}
            />
            <SpanWithLabel
              labelText="Street 2:"
              value={legalDocumentInfo?.physicalAddress?.streetNumber2}
            />
            <SpanWithLabel labelText="City:" value={legalDocumentInfo?.physicalAddress?.city} />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel
              labelText="State/Province/Region:"
              value={legalDocumentInfo?.physicalAddress?.stateProvinceRegion?.name}
            />
            <SpanWithLabel
              labelText="Postal/Zip Code:"
              value={legalDocumentInfo?.physicalAddress?.postalZipCode}
            />
            <SpanWithLabel
              labelText="Country:"
              value={legalDocumentInfo?.physicalAddress?.country?.shortName}
            />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      {showSapSetup && (
        <>
          <Card
            title={`${titlePrefix}SAP Setup`}
            headingLevel={2}
            isLoading={loading}
            actionButton={sapSetupActionButton}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Tax Jurisdiction Code:"
                  value={sapSetupInfo?.taxJurisdictionCode}
                />
                <SpanWithLabel labelText="Region:" value={sapSetupInfo?.region?.toString()} />
              </StackColumn>
              {showMsSalesLocation && (
                <StackColumn>
                  <SpanWithLabel
                    labelText="Sales Location for MS Reporting Year:"
                    value={sapSetupInfo?.msReportingSalesYear}
                  />
                  <SpanWithLabel
                    labelText="Sales Location for MS Reporting Region:"
                    value={
                      sapSetupInfo?.msReportingSalesLocation?.id
                        ? `${sapSetupInfo?.msReportingSalesLocation?.name} - ${sapSetupInfo?.msReportingSalesLocation?.id}`
                        : null
                    }
                  />
                </StackColumn>
              )}
            </Stack>
          </Card>
          <br />
        </>
      )}
      {showIhccSetup && (
        <>
          <Card
            title="IHCC Setup"
            headingLevel={2}
            isLoading={loading}
            actionButton={ihccActionButton}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Should the entity be included in IHCC?"
                  value={inHouseCashCenterSetup?.isIncludedInInHouseCashCenter}
                />
                <SpanWithLabel
                  labelText="IHCC Tier"
                  value={inHouseCashCenterSetup?.inHouseCashCenterTier?.name}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="Account Currency in IHCC Module"
                  value={inHouseCashCenterSetup?.inHouseCashCenterAccountCurrency}
                />
                <SpanWithLabel
                  labelText="Will IHCC transaction be posted systematically (via IHCC module) or manually (via JEM)?"
                  value={
                    inHouseCashCenterSetup?.inHouseCashCenterPostingMode
                      ? inHouseCashCenterSetup.inHouseCashCenterPostingMode
                          .toLowerCase()
                          .replace(/^\w/, (chr: string) => chr.toUpperCase())
                      : null
                  }
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
        </>
      )}
      {showShadowCompanyCard && (
        <>
          <Card
            title="Shadow Company"
            headingLevel={2}
            isLoading={loading}
            actionButton={shadowCompanyActionButton}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Shadow Company Needed:"
                  value={shadowCompanyInfo?.isShadowCompanyNeeded}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Shadow Company Name:" value={shadowCompanyInfo?.name} />
              </StackColumn>
            </Stack>
          </Card>
          <br />
        </>
      )}
      {showSubledger && (
        <>
          <Card
            title="Subledger Info"
            headingLevel={2}
            isLoading={loading}
            actionButton={subledgerActionButton}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Is this a new domestic entity?"
                  value={subledgerInfo?.isDomesticEntity}
                />
                <SpanWithLabel
                  labelText="Will the new company require billing for MCS or Premier Services?"
                  value={subledgerInfo?.willRequireMscPremierServices}
                />
                <SpanWithLabel
                  labelText="Personnel Subarea"
                  value={subledgerInfo?.personalSubareaCities}
                />
                <SpanWithLabel
                  labelText="Will the company need the FA module?"
                  value={subledgerInfo?.willNeedFaModule}
                />
                <SpanWithLabel
                  labelText="Will the new company transmit sales to MS Sales?"
                  value={subledgerInfo?.willTransmitToMsSales}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="Will the company have inter-company transactions with other Microsoft entities?"
                  value={subledgerInfo?.willHaveIntercompanyActivity}
                />
                <SpanWithLabel
                  labelText="Will the company have headcount?"
                  value={subledgerInfo?.willHaveHeadcount}
                />
                <SpanWithLabel
                  labelText="Work Schedule"
                  value={subledgerInfo?.weeklyWorkingHours}
                />
                <SpanWithLabel
                  labelText="Will the company need the AP/MM module?"
                  value={subledgerInfo?.willNeedApMmModule}
                />
              </StackColumn>
            </Stack>
            <h3 style={subHeadingStyle}>Contacts</h3>
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <ServiceContactList
                  labelText="Balance Sheet Recon Owner"
                  contacts={subledgerInfo?.bsReconOwner ? [{ ...subledgerInfo?.bsReconOwner }] : []}
                />
                <ServiceContactList
                  labelText="Balance Sheet Recon Controller"
                  contacts={
                    subledgerInfo?.bsReconController
                      ? [{ ...subledgerInfo?.bsReconController }]
                      : []
                  }
                />
              </StackColumn>
              <StackColumn>
                <ServiceContactList
                  labelText="Balance Sheet Recon Reviewer"
                  contacts={
                    subledgerInfo?.bsReconReviewer ? [{ ...subledgerInfo?.bsReconReviewer }] : []
                  }
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
        </>
      )}
      {showStatContactsCard && (
        <>
          <Card
            title="Statutory Information"
            headingLevel={2}
            isLoading={loading}
            actionButton={statutoryInfoActionButton}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel
                  labelText="Statutory Oversight Group:"
                  value={statutoryInformation?.oversightGroup?.fieldName}
                />
                <SpanWithLabel labelText="MCAPS Flag?" value={statutoryInformation?.isMcaps} />
                <SpanWithLabel
                  labelText="One Finance Flag?"
                  value={statutoryInformation?.isOneFinance}
                />
                <SpanWithLabel
                  labelText="Controller Must Be FTE?"
                  value={statutoryInformation?.controllerMustBeFte}
                />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel
                  labelText="F/S Audit Legally Required by Regulator?"
                  value={statutoryInformation?.isFsAuditLegallyRequired}
                />
                <SpanWithLabel
                  labelText="Auditing Firm:"
                  value={
                    statutoryInformation?.auditingFirm
                      ? `${statutoryInformation?.auditingFirm?.fieldName}${
                          statutoryInformation?.otherAuditingFirm
                            ? `: ${statutoryInformation?.otherAuditingFirm}`
                            : ''
                        }`
                      : null
                  }
                />
                <SpanWithLabel
                  labelText="EIN Tax Number:"
                  value={statutoryInformation?.einTaxNumber}
                />
              </StackColumn>
            </Stack>
          </Card>
          <br />
        </>
      )}
      {showCompanyCreationContactsCard && (
        <Card
          title="Statutory Contacts"
          headingLevel={2}
          isLoading={loading}
          actionButton={companyCreationContactsButton}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <ServiceContactList
                labelText="Statutory Controller"
                contacts={companyContacts?.statutoryController || []}
              />
              <ServiceContactList
                labelText="Regional Controller"
                contacts={companyContacts?.regionalControllers || []}
              />
              <ServiceContactList
                labelText="F/S Tax Rep"
                contacts={companyContacts?.fsTaxRepContacts || []}
              />
            </StackColumn>
            <StackColumn>
              <ServiceContactList
                labelText="Treasury Rep"
                contacts={companyContacts?.treasuryRepContacts || []}
              />
              <ServiceContactList
                labelText="BPO Contacts"
                contacts={companyContacts?.bpoContacts || []}
              />
              <ServiceContactList
                labelText="Alternative Contact"
                contacts={companyContacts?.alternativeContacts || []}
              />
            </StackColumn>
          </Stack>
        </Card>
      )}
    </>
  );
};

export default CompanyRequestInfoCards;
