import { IColumn, ColumnActionsMode } from '@fluentui/react';
import { rowClasses } from '../../app/common/styles/CommonStyleObjects';

interface IColumnsProps {
  onRenderCompanyName: (item: { id: number; companyName: string }) => JSX.Element;
  onRenderRequestor: (item: { requestor: string }) => JSX.Element;
  onRenderRequestedDate: (item: { requestedDate: Date }) => JSX.Element;
  onRenderStatus: (item: { status: string }) => JSX.Element;
  onRenderAcquisition: (item: { acquisition: string }) => JSX.Element;
}

const getColumns = ({
  onRenderCompanyName,
  onRenderRequestor,
  onRenderRequestedDate,
  onRenderStatus,
  onRenderAcquisition,
}: IColumnsProps): Array<IColumn> => {
  return [
    {
      key: 'companyName',
      name: 'Company Name',
      fieldName: 'companyName',
      minWidth: 250,
      maxWidth: 400,
      isRowHeader: true,
      className: rowClasses.medFont,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderCompanyName,
    },
    {
      key: 'requestor',
      name: 'Requestor',
      fieldName: 'requestor',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: false,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderRequestor,
    },
    {
      key: 'requestedDate',
      name: 'Requested Date',
      fieldName: 'requestedDate',
      minWidth: 150,
      maxWidth: 250,
      isRowHeader: false,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderRequestedDate,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 150,
      maxWidth: 250,
      isRowHeader: false,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderStatus,
    },
    {
      key: 'acquisition',
      name: 'Acquisition',
      fieldName: 'acquisition',
      minWidth: 150,
      maxWidth: 400,
      isRowHeader: false,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderAcquisition,
    },
  ];
};

export default getColumns;
