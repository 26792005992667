import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import StatDateFormats from '../../../utils/types/DateFormats';
import StepsContainer, { IRun } from '../components/StepsContainer';

const AnalysisQueueColumns = [
  {
    key: 'document.title',
    name: 'Document',
    minWidth: 100,
    maxWith: 100,
    isResizable: true,
    isHeaderRow: true,
    onRender: (item: IRun) => {
      return (
        <Link to={`/document/analysis/${item.document.id}/run/${item.id}`}>
          {item.document.title}
        </Link>
      );
    },
  },
  {
    key: 'runStatus',
    name: 'Status',
    fieldName: 'runStatus',
    minWidth: 100,
    maxWith: 100,
    isResizable: true,
  },
  {
    key: 'steps',
    name: 'Steps',
    minWidth: 100,
    maxWith: 100,
    isResizable: true,
    onRender: (item: IRun) => {
      return <StepsContainer run={item} />;
    },
  },
  {
    key: 'created',
    name: 'Created',
    fieldName: 'created',
    minWidth: 200,
    maxWith: 200,
    isResizable: true,
    onRender: (item: IRun) => {
      return <span>{moment(item?.created).format(StatDateFormats.DayWithTime)}</span>;
    },
  },
  {
    key: 'completed',
    name: 'Completed',
    fieldName: 'completed',
    minWidth: 200,
    maxWith: 200,
    isResizable: true,
    onRender: (item: IRun) => {
      return (
        <span>
          {item?.completed ? moment(item?.completed).format(StatDateFormats.DayWithTime) : '--'}
        </span>
      );
    },
  },
];

export default AnalysisQueueColumns;
