import * as React from 'react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Controller, FieldError, useForm, useWatch } from 'react-hook-form';
import { IPersonaProps, Stack, TextField, Toggle } from '@fluentui/react';
import { IProcessReportingReasonForExclusion } from '../Interfaces';
import { EDIT_REASON_FOR_REPORTING_EXCLUSION_MUTATION } from '../../../../utils/statApi/ProcessApi';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import CanAccess from '../../../common/canAccess/CanAccess';
import ActionButtons from '../../../common/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';

import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import GET_REASONS_FOR_REPORTING_EXCLUSION from '../../../../utils/statApi/ReasonForProcessReportingExclusionApi';
import { IProcessIdParams } from '../../../common/ParamTypes';
import searchForUsers from '../../../../utils/microsoftGraphApi/userSearch';
import ErrorHandlingPeoplePicker from '../../../common/formFields/ErrorHandlingPeoplePicker';
import GET_REASONS_FOR_REPORTING_EXCLUSION_APPROVAL from '../../../../utils/statApi/ReasonForProcessReportingExclusionApprovalApi';
import { IServiceContact } from '../../serviceContacts/interfaces';

interface IReportingExclusionEditPanelProps {
  processReasonForReportExclusion: IProcessReportingReasonForExclusion;
  closePanel: () => void;
}

const ReportingExclusionEditPanel: React.FunctionComponent<IReportingExclusionEditPanelProps> = ({
  processReasonForReportExclusion,
  closePanel,
}): JSX.Element => {
  const { processId } = useParams<IProcessIdParams>();

  const [updateReasonForReportingExlusion, { loading: mutationLoading, error: mutationError }] =
    useMutation(EDIT_REASON_FOR_REPORTING_EXCLUSION_MUTATION, {
      onCompleted: closePanel,
    });

  const [exceptionApprover, setExceptionApprover] = React.useState<IPersonaProps[]>();

  const form = useForm<IProcessReportingReasonForExclusion>({
    defaultValues: {
      ...processReasonForReportExclusion,
    },
  });

  const { handleSubmit, control, errors, setValue } = form;

  const handleOnSaveClick = (formData: IProcessReportingReasonForExclusion) => {
    const exclusion = {
      reasonForReportingExclusion: {
        id: formData?.reasonForReportExclusion?.id ?? null,
        fieldName: formData?.reasonForReportExclusion?.fieldName ?? '',
      },
      reasonForReportingExclusionComment: formData?.reasonForReportExclusionComments,
      excludeFromMetrics: formData?.excludeFromMetrics ?? false,
      approvedReasonComments: formData?.approvedReasonComments,
      processId: parseInt(processId, 10),
      approvedByDisplayName: formData?.processExceptionApprovedBy?.text,
      approvedByGraphGUID: formData?.processExceptionApprovedBy?.graphId,
      reasonForReportingExclusionApproval: formData?.processExceptionApprovedReason
        ? {
            id: formData?.processExceptionApprovedReason?.id ?? null,
            fieldName: formData?.processExceptionApprovedReason?.fieldName ?? '',
          }
        : { id: null, fieldName: '' },
      processExceptionApproved: formData?.processExceptionApproved,
    };
    updateReasonForReportingExlusion({
      variables: {
        processReasonForReportingExclusion: {
          ...exclusion,
        },
      },
    });
  };

  const handleExceptionApprovedChange = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setValue('processExceptionApproved', checked);

    if (!checked) {
      setValue('processExceptionApprovedReason', { fieldName: '', id: null });
      setValue('approvedReasonComments', null);
      setValue('processExceptionApprovedBy', null);
      setExceptionApprover([]);
    }
  };

  const handleExcludeFromMetricsChange = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setValue('excludeFromMetrics', checked);

    if (!checked) {
      setValue('reasonForReportExclusion', { fieldName: '', id: null });
      setValue('reasonForReportExclusionComments', null);
    }
  };

  const handleReportExclusionReasonDropDownChange = (
    newValue: IDropdownReference,
    valueKey: string,
  ) => {
    setValue('reasonForReportExclusion', newValue);
  };

  const handleApprovedReasonDropDownChange = (newValue: IDropdownReference, valueKey: string) => {
    setValue('processExceptionApprovedReason', newValue);
  };

  const watchExcludeFromMetrics = useWatch({
    control,
    name: 'excludeFromMetrics',
    defaultValue: processReasonForReportExclusion?.excludeFromMetrics,
  });

  const watchApprovers = useWatch({
    control,
    name: 'processExceptionApprovedBy',
    defaultValue: { text: processReasonForReportExclusion?.processExceptionApprovedBy?.text },
  });

  const watchReportExclusionReason = useWatch({
    control,
    name: 'reasonForReportExclusion',
    defaultValue: processReasonForReportExclusion?.reasonForReportExclusion,
  });

  const watchExceptionApproved = useWatch({
    control,
    name: 'processExceptionApproved',
    defaultValue: processReasonForReportExclusion?.processExceptionApproved,
  });

  const watchComment = useWatch({
    control,
    name: 'reasonForReportExclusionComments',
    defaultValue: processReasonForReportExclusion?.reasonForReportExclusionComments,
  });

  const watchWhoWhy = useWatch({
    control,
    name: 'approvedReasonComments',
    defaultValue: processReasonForReportExclusion?.approvedReasonComments,
  });

  const watchReasonForApproval = useWatch({
    control,
    name: 'processExceptionApprovedReason',
    defaultValue: processReasonForReportExclusion?.processExceptionApprovedReason,
  });

  const validateReason = (newVal: string): string => {
    if (
      watchExcludeFromMetrics &&
      (watchReportExclusionReason?.id === null || watchReportExclusionReason == null)
    ) {
      return 'A Reason Is Required';
    }
    return null;
  };

  const validateReasonForApproval = (newVal: string): string => {
    if (
      watchExceptionApproved &&
      (watchReasonForApproval?.id === null || watchReasonForApproval === null)
    ) {
      return 'A Reason For Approval Is Required';
    }
    return null;
  };

  const validateComment = (newVal: string): string => {
    if (
      watchReportExclusionReason?.fieldName === 'Other' &&
      (watchComment?.trim()?.length === 0 || watchComment === null || watchComment === undefined) &&
      watchExcludeFromMetrics
    ) {
      return 'A Comment Is Required When Reason Is "Other"';
    }
    return null;
  };

  const validateApprovedByComments = (newVal: string): string => {
    if (
      watchReasonForApproval?.fieldName === 'Other' &&
      (watchWhoWhy?.trim()?.length === 0 || watchWhoWhy === undefined || watchWhoWhy === null)
    ) {
      return 'Approval Comments Are Required When Reason For Approval Is "Other"';
    }
    return null;
  };

  const validateApprovedBy = (newVal: string): string => {
    if (
      watchExceptionApproved &&
      (watchApprovers === null ||
        watchApprovers === undefined ||
        watchApprovers?.text === null ||
        watchApprovers?.text === undefined ||
        watchApprovers?.text === '')
    ) {
      return 'Approved By Is Required If Process Exception Is "Approved"';
    }
    return null;
  };

  const {
    loading: reasonForReportingExclusionDataLoading,
    data: reasonsForReportingExclusionData,
  } = useQuery(GET_REASONS_FOR_REPORTING_EXCLUSION);
  const { reasonsForReportingExclusion } =
    !reasonForReportingExclusionDataLoading && reasonsForReportingExclusionData
      ? reasonsForReportingExclusionData
      : [];

  const {
    loading: reasonForReportingExclusionApprovalDataLoading,
    data: reasonsForReportingExclusionApprovalData,
  } = useQuery(GET_REASONS_FOR_REPORTING_EXCLUSION_APPROVAL);
  const { reasonsForReportingExclusionApproval } =
    !reasonForReportingExclusionApprovalDataLoading && reasonsForReportingExclusionApprovalData
      ? reasonsForReportingExclusionApprovalData
      : [];

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Reporting Modifications"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Reporting Modifications"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="ReasonForReportExclusion:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveTitle="Save Reporting Modifications"
            saveLabel="Save Reporting Modifications"
            cancelTitle="Cancel Reporting Modifications"
            cancelLabel="Cancel Reporting Modifications"
          />
        </CanAccess>
      )}
    >
      <form>
        <Stack tokens={{ childrenGap: 10 }}>
          <CanAccess requestedAction="ReasonForReportExclusion:Edit">
            <Controller
              control={control}
              name="excludeFromMetrics"
              value={processReasonForReportExclusion?.excludeFromMetrics}
              render={({ value }) => (
                <Toggle
                  id="excludeFromMetrics"
                  label="Exclude From Metrics"
                  checked={value as boolean}
                  onChange={handleExcludeFromMetricsChange}
                />
              )}
            />
          </CanAccess>
          <CanAccess requestedAction="ReasonForReportExclusion:Edit">
            <Controller
              control={control}
              name="reasonForReportExclusion"
              value={processReasonForReportExclusion?.reasonForReportExclusion}
              rules={{
                validate: {
                  validateReason,
                },
              }}
              render={({ value }) => (
                <QueryBasedDropdown
                  id="reasonForReportingExclusionDropdown"
                  value={value as IDropdownReference}
                  label="Reason"
                  dropdownOptionsData={reasonsForReportingExclusion}
                  errors={errors}
                  handleChange={handleReportExclusionReasonDropDownChange}
                  valueKey="reasonForReportExclusion"
                  disabled={!watchExcludeFromMetrics}
                  clearable
                  width={400}
                  required={watchExcludeFromMetrics}
                />
              )}
            />
          </CanAccess>
          <CanAccess requestedAction="ReasonForReportExclusion:Edit">
            <Controller
              as={TextField}
              id="reasonForReportExclusionComments"
              name="reasonForReportExclusionComments"
              label="Comments"
              control={control}
              multiline
              maxLength={500}
              defaultValue={processReasonForReportExclusion?.reasonForReportExclusionComments || ''}
              required={watchReportExclusionReason?.fieldName === 'Other'}
              disabled={!watchExcludeFromMetrics}
              rules={{
                validate: { validateComment },
                maxLength: {
                  value: 500,
                  message: 'Comment Cannot Be Longer Than 500 Characters',
                },
              }}
              errorMessage={errors?.reasonForReportExclusionComments?.message}
            />
          </CanAccess>
          <CanAccess requestedAction="ReasonForReportExclusion:Edit">
            <Controller
              control={control}
              name="processExceptionApproved"
              value={processReasonForReportExclusion?.processExceptionApproved}
              render={({ value }) => (
                <Toggle
                  id="processExceptionApproved"
                  label="Process Exception Approved?"
                  checked={value as boolean}
                  onChange={handleExceptionApprovedChange}
                />
              )}
            />
          </CanAccess>

          <CanAccess requestedAction="ReasonForReportExclusion:Edit">
            <Controller
              id="processExceptionApprovedBy"
              name="processExceptionApprovedBy"
              label="Approved By"
              control={control}
              required={watchExceptionApproved}
              render={() => (
                <ErrorHandlingPeoplePicker
                  onChange={(items: IServiceContact[]) => {
                    setValue('processExceptionApprovedBy', {
                      text: items[0]?.text,
                      graphId: items[0]?.graphId,
                    });
                    setExceptionApprover(items);
                  }}
                  removeButtonAriaLabel="Remove Approver"
                  onResolveSuggestions={onResolveSuggestions}
                  disabled={!watchExceptionApproved}
                  selectedItems={exceptionApprover}
                  controlName="processExceptionApprovedBy"
                  labelText="Approved By"
                  required={watchExceptionApproved}
                  itemLimit={1}
                  defaultSelectedItems={
                    processReasonForReportExclusion?.processExceptionApprovedBy?.text
                      ? [
                          {
                            text:
                              processReasonForReportExclusion?.processExceptionApprovedBy?.text ||
                              '',
                            guid: processReasonForReportExclusion?.processExceptionApprovedBy
                              ?.graphId,
                          } as Partial<IServiceContact>,
                        ]
                      : null
                  }
                  errorMessage={(errors?.processExceptionApprovedBy as FieldError)?.message}
                />
              )}
              rules={{
                validate: { validateApprovedBy },
                maxLength: {
                  value: 500,
                  message: 'Comment Cannot Be Longer Than 500 Characters',
                },
              }}
            />
          </CanAccess>
          <CanAccess requestedAction="ReasonForReportExclusion:Edit">
            <Controller
              control={control}
              name="processExceptionApprovedReason"
              value={processReasonForReportExclusion?.processExceptionApprovedReason}
              rules={{
                validate: {
                  validateReasonForApproval,
                },
              }}
              render={({ value }) => (
                <QueryBasedDropdown
                  id="processExceptionApprovedReason"
                  value={value as IDropdownReference}
                  label="Reason For Approval"
                  dropdownOptionsData={reasonsForReportingExclusionApproval}
                  errors={errors}
                  handleChange={handleApprovedReasonDropDownChange}
                  valueKey="processExceptionApprovedReason"
                  disabled={!watchExceptionApproved}
                  clearable
                  width={400}
                  required={watchExceptionApproved}
                />
              )}
            />
          </CanAccess>
          <CanAccess requestedAction="ReasonForReportExclusion:Edit">
            <Controller
              as={TextField}
              id="approvedReasonComments"
              name="approvedReasonComments"
              label="Approval Comments"
              control={control}
              multiline
              maxLength={500}
              defaultValue={processReasonForReportExclusion?.approvedReasonComments || ''}
              required={watchReasonForApproval?.fieldName === 'Other'}
              disabled={!watchExceptionApproved}
              rules={{
                validate: { validateApprovedByComments },
                maxLength: {
                  value: 500,
                  message: 'Comment Cannot Be Longer Than 500 Characters',
                },
              }}
              errorMessage={errors?.approvedReasonComments?.message}
            />
          </CanAccess>
        </Stack>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There Was An Error Saving The Reporting Modifications To The Server"
        />
      </form>
    </CoherencePanel>
  );
};

export default ReportingExclusionEditPanel;
