import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';

export interface IAgmTrackerOwnerDropdownProps {
  onOwnerUpdate: (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
  selectedOption: string;
  label: string;
  defaultSelectedKey: string;
  defaultValue?: string;
  disabled?: boolean;
  isBulkEdit?: boolean;
}

const AgmTrackerOwnerDropdown = (props: IAgmTrackerOwnerDropdownProps): JSX.Element => {
  const {
    defaultSelectedKey,
    defaultValue,
    onOwnerUpdate,
    selectedOption,
    label,
    disabled,
    isBulkEdit,
  } = props;

  const ownerOptions: IDropdownOption[] = [
    { key: 'CELA', text: 'CELA' },
    { key: 'CONTROLLER', text: 'Controller' },
  ];

  if (isBulkEdit) {
    ownerOptions.unshift({ key: '', text: '--' });
  }

  return (
    <Dropdown
      label={label}
      defaultSelectedKey={defaultValue || defaultSelectedKey}
      onChange={onOwnerUpdate}
      selectedKey={selectedOption}
      options={ownerOptions}
      disabled={disabled}
      placeholder="Select an option"
    />
  );
};

export default AgmTrackerOwnerDropdown;
