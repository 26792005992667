import { IDocumentCardActionsStyles } from '@fluentui/react';

export const fileSelectorItemWrapper = {
  root: { maxWidth: '800px', width: '100%', margin: '0 auto', height: 106 },
};
export const deleteFileItemIcon = {
  root: { position: 'absolute', right: 0, bottom: 3 },
} as IDocumentCardActionsStyles;

export const documentCardTitleStyles = {
  root: { width: 350 },
};
