import { FontIcon, IconButton } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import IDividends from '../../utils/types/IDividends';
import CanAccess from './canAccess/CanAccess';

interface IDividendsLinkProps {
  dividends: IDividends;
  companyCode: string;
  toggleDividendsPanel: () => void;
}

const DividendsLink = ({
  dividends,
  companyCode,
  toggleDividendsPanel,
}: IDividendsLinkProps): JSX.Element => {
  if (dividends?.dividendReviewId) {
    return (
      <Link
        aria-label="Dividends Review"
        to={`/companies/${companyCode}/dividends-review/${dividends.dividendReviewId}`}
      >
        <FontIcon iconName="openInNewTab" />
      </Link>
    );
  }

  return (
    <CanAccess requestedAction="DividendInfo:Update">
      <IconButton
        title="Edit Dividends"
        ariaLabel="Edit Dividends"
        iconProps={{ iconName: 'Edit' }}
        onClick={toggleDividendsPanel}
      />
    </CanAccess>
  );
};

export default DividendsLink;
