enum CompanyFormType {
  NameAndSapInfo = 'Name & SAP Info',
  IncorpHierarchy = 'Incorporation / Hierarchy Information',
  BusinessStatus = 'Business Nature & Status',
  EntityTermination = 'Legal Entity Termination Details',
  Address = 'Legal Address',
  SapFinanicalInfo = 'SAP Financial Information',
  FinancialInfo = 'Financial Information',
}

export default CompanyFormType;
