import { useQuery } from '@apollo/client/react';
import { Checkbox, ITag, Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { GET_WORK_WEEK_CONFIGURATIONS } from '../../utils/statApi/CountryApi';
import ICountry from '../../utils/types/ICountry';
import IDropdownReference from '../../utils/types/IDropdownReference';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import MonthDayPicker from '../common/formFields/monthDayPicker/MonthDayPicker';
import QueryBasedDropdown from '../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import { ICountryConfiguration } from './CountryConfiguration.config';
import CountryPicker from './CountryPicker';

interface CountryConfigFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormMethods<Record<string, any>>;
  countryData: ICountryConfiguration;
}

const WorkWeekDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_WORK_WEEK_CONFIGURATIONS);
  const { workWeekConfigurations } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={workWeekConfigurations}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

const CountryConfigForm = ({ form, countryData }: CountryConfigFormProps): JSX.Element => {
  const countryConfigFormState = { ...countryData };
  const [countryConfigCountry, setCountryConfigCountry] = useState(() => {
    if (countryConfigFormState?.country === null || countryConfigFormState?.country === undefined)
      return null;
    const newObject: ITag[] = [
      {
        key: countryConfigFormState?.country?.id.toString(),
        name: `${
          countryConfigFormState?.country?.shortName
        } (${countryConfigFormState?.country?.id.toString()})`,
      },
    ];
    return newObject;
  });

  const validateCountry = (country: ICountry[]): string => {
    if (country === undefined || country.length < 1) {
      return 'Please choose a valid country';
    }
    return null;
  };

  const { control, errors, register, setValue, trigger, formState } = form;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newValue: any, valueKey: string): void => {
    setValue(valueKey, newValue);
    if (formState.isSubmitted) {
      trigger();
    }
  };

  // when new item selected, convert tag to ICountry
  // update state with new item
  const onCountryUpdate = (items: ITag[]): void => {
    const countries = items.map((item) => {
      const newObject: ICountry = {
        id: item.key.toString(),
        shortName: item.name,
      };
      return newObject;
    });
    setCountryConfigCountry(items);

    handleChange(countries, 'countries');
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <input type="hidden" ref={register()} name="id" defaultValue={countryData?.id} />

      <Controller
        name="countries"
        control={control}
        defaultValue={[countryConfigFormState.country]} // default value needs to be an array here since tag picker expects array of tags as selection
        required
        render={(): React.ReactElement => (
          <CountryPicker
            itemLimit={1}
            selectedItems={countryConfigCountry}
            setSelectedItems={onCountryUpdate}
            isDisabled={!!countryConfigFormState.id}
            errorMessage={errors?.countries?.message}
          />
        )}
        rules={{
          required: 'Please select a valid Country',
          validate: validateCountry,
        }}
      />

      <Controller
        as={TextField}
        label="Months To File From Reporting Period End"
        id="monthsToFileFromReportingPeriodEnd"
        name="monthsToFileFromReportingPeriodEnd"
        control={control}
        required
        type="number"
        defaultValue={countryData?.monthsToFileFromReportingPeriodEnd || 0}
        rules={{
          required: 'Please enter the number of months to file from reporting period end',
        }}
      />

      <Controller
        name="workWeek"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <WorkWeekDropdown
            label="Work Week"
            value={countryData?.workWeek}
            valueKey="workWeek"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('workWeek', newValue);
            }}
            errors={errors}
          />
        )}
        defaultValue={countryData?.workWeek}
        rules={{
          required: 'Please select work week',
        }}
        required
      />

      <MonthDayPicker
        dueDateMonth={countryData?.taxReturnDueDateMonth || 1}
        dueDateDay={countryData?.taxReturnDueDateDay || 1}
        form={form}
        dayFormName="taxReturnDueDateDay"
        monthFormName="taxReturnDueDateMonth"
        label="Tax Return Due Date"
        required
      />

      <MonthDayPicker
        dueDateMonth={countryData?.taxReportingPeriodDateMonth || 1}
        dueDateDay={countryData?.taxReportingPeriodDateDay || 1}
        form={form}
        dayFormName="taxReportingPeriodDateDay"
        monthFormName="taxReportingPeriodDateMonth"
        label="Tax Reporting Period"
        required
      />

      <Controller
        name="alignAgmWithFinancialStatement"
        control={control}
        defaultValue={countryData?.alignAgmWithFinancialStatement || false}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="Align AGM With Financial Statement"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              onChange(checked);
            }}
            checked={value}
            name={name}
          />
        )}
      />

      <Controller
        name="electronicSignatureAcceptable"
        control={control}
        defaultValue={countryData?.electronicSignatureAcceptable || false}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="Is Electronic Signature Acceptable"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              onChange(checked);
            }}
            checked={value}
            name={name}
          />
        )}
      />

      <Controller
        name="externalFilingRequired"
        control={control}
        defaultValue={countryData?.externalFilingRequired || false}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="External Filing Required"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              onChange(checked);
            }}
            checked={value}
            name={name}
          />
        )}
      />
    </Stack>
  );
};

export default CountryConfigForm;
