import { useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  DetailsList,
  IColumn,
  PrimaryButton,
  Selection,
  SelectionMode,
  Spinner,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import { GET_PROCESS_TYPE_WITH_MILESTONE_POOL } from '../../../../utils/statApi/ProcessTypesApi';

import { IMilestoneTemplate } from '../../../../utils/types/IMilestoneTemplate';
import { IProcessTemplateMilestone } from '../../../../utils/types/IProcessTemplate';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IProcessTemplateTypeParams } from '../../../common/ParamTypes';

interface IProcessTemplateMilestoneAddProps {
  closePanel: () => void;
  onAddSave: (selectedMilestone: IMilestoneTemplate, index: number) => void;
  index: number;
  currentMilestones: IProcessTemplateMilestone[];
}
const ProcessTemplateMilestoneAdd = (props: IProcessTemplateMilestoneAddProps): JSX.Element => {
  const { closePanel, onAddSave, index, currentMilestones } = props;

  const [selectedMilestone, setSelectedMilestone] = useState(null);

  const selection = new Selection({
    onSelectionChanged: () => {
      const selectedItems = selection.getSelection();
      if (selectedItems.length) {
        setSelectedMilestone(selectedItems[0] as IMilestoneTemplate);
      } else {
        setSelectedMilestone(null);
      }
    },
  });

  const onSaveClick = () => {
    // TODO: make sure this is not null
    onAddSave(selectedMilestone, index);
  };

  const { processTypeId } = useParams<IProcessTemplateTypeParams>();
  const processTypeIdInt = parseInt(processTypeId, 10);

  const { data, loading, error } = useQuery(GET_PROCESS_TYPE_WITH_MILESTONE_POOL, {
    variables: { processTypeId: processTypeIdInt },
  });
  const [newMilestoneTemplates, setNewMilestoneTemplates] = useState<IProcessTemplateMilestone[]>(
    [],
  );
  useEffect(() => {
    if (data?.processTypeWithMilestonePool.milestoneTemplates) {
      const currentMilestoneIds = currentMilestones.map((mi) => mi.milestoneTemplateId);
      const unusedMilestoneTemplates = data?.processTypeWithMilestonePool.milestoneTemplates.filter(
        (mi: IProcessTemplateMilestone) => {
          return !currentMilestoneIds.includes(mi.id);
        },
      );
      setNewMilestoneTemplates(unusedMilestoneTemplates);
    }
  }, [data, currentMilestones]);
  const columns: IColumn[] = [
    {
      key: 'milestoneName',
      name: 'Milestone Name',
      isResizable: true,
      fieldName: 'name',
      minWidth: 150,
    },
  ];

  columns.push({
    key: 'milestoneCategory',
    name: 'Category',
    isResizable: true,
    fieldName: 'milestoneCategoryName',
    minWidth: 150,
  });

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Add Milestone"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close"
      onRenderFooter={() => (
        <PrimaryButton
          text="Add Selected Milestone"
          onClick={onSaveClick}
          disabled={selectedMilestone === null}
        />
      )}
    >
      <LoadingErrorMessage loading={loading} error={error} />
      {loading && !error && <Spinner label="Loading Milestones" />}
      {!loading && !error && (
        <>
          Select a milestone from the milestone pool
          <DetailsList
            compact
            columns={columns}
            items={newMilestoneTemplates}
            selectionMode={SelectionMode.single}
            selection={selection}
            checkButtonAriaLabel="Select Row"
          />
        </>
      )}
    </CoherencePanel>
  );
};
export default ProcessTemplateMilestoneAdd;
