import React, { useEffect, useState } from 'react';
import {
  ChoiceGroup,
  Dropdown,
  IDropdownOption,
  Label,
  mergeStyles,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { Controller, UseFormMethods } from 'react-hook-form';
import IAgmDetail from '../../../../utils/types/IAgmDetail';
import InlineFormInputErrorMessage from '../../../common/errorContent/InlineFormInputErrorMessage';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import AuditingFirmsDropdown from './AuditingFirmsDropdown';
import StatDateFormats from '../../../../utils/types/DateFormats';
import momentUtc from '../../../../utils/DateFormatter';
import monthDayFormat from '../../../../utils/MonthDayFormatter';
import {
  yesNoOptions,
  signatureInkColorOptions,
  paperSizeOptions,
  additionalProcessingTimeOptions,
} from '../../../../utils/types/CommonDropdownOptions';
import { RequiredMarker } from '../../../common/formFields/RequiredMarker';

export interface IAgmDetailFormProps {
  agmDetail: IAgmDetail;
  form: UseFormMethods<IAgmDetail>;
}

const finalExternalFilingSubmitterOptions: IDropdownOption[] = [
  { key: 'AUDITOR', text: 'Auditor' },
  { key: 'COUNSEL', text: 'Counsel' },
  { key: 'LOCAL', text: 'Local Finance Team' },
  { key: 'OTHER', text: 'Other' },
];

const AgmDetailForm = ({ agmDetail, form }: IAgmDetailFormProps): JSX.Element => {
  const { control, errors: formErrors, watch, setValue } = form;

  const watchTaxDeadlineBeforeAfsDeadline = watch(
    'taxFilingDeadlineBeforeAfsDeadline',
    agmDetail?.taxFilingDeadlineBeforeAfsDeadline,
  );

  const watchSignatureRequiredDeadline = watch(
    'physicalSignatureRequired',
    agmDetail?.physicalSignatureRequired,
  );

  const watchInkColorRequired = watch('inkColorRequired', agmDetail?.inkColorRequired);

  const watchAuditingFirm = watch('auditingFirm', agmDetail?.auditingFirm);

  const watchFinalExternalFilingSubmitter = watch(
    'finalExternalFilingSubmitter',
    agmDetail?.finalExternalFilingSubmitter,
  );

  const watchPaperSize = watch('paperSize', agmDetail?.paperSize);

  const amountStyles = mergeStyles({
    width: '295px',
  });

  const [selectedAdditionalTimeOptions, setSelectedAdditionalTime] = useState<string[]>(() => {
    const values: string[] = [];
    if (agmDetail?.additionalProcessingTimeApostille) {
      values.push('APOSTILLE');
    }
    if (agmDetail?.additionalProcessingTimeLegalization) {
      values.push('LEGALIZATION');
    }
    if (agmDetail?.additionalProcessingTimeLocalSigner) {
      values.push('LOCALSIGNER');
    }
    return values;
  });

  useEffect(() => {
    setValue('additionalProcessingTime', selectedAdditionalTimeOptions);
  }, [selectedAdditionalTimeOptions]);

  const requiredMessageStyles = { color: '#A4262C', fontSize: 12 };
  const requiredLabelStyles = { paddingBottom: 0 };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <div style={requiredMessageStyles}>* Fields required to start the review</div>

      <Controller
        name="agmDeadlineBeforeAfsDeadline"
        defaultValue={agmDetail?.agmDeadlineBeforeAfsDeadline}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                {`Is the AGM deadline (${monthDayFormat(
                  agmDetail?.annualGeneralMeetingDueDateMonth,
                  agmDetail?.annualGeneralMeetingDueDateDay,
                )}) earlier than the AFS deadline (${momentUtc(
                  agmDetail?.filingDueDate,
                  StatDateFormats.DaySimple,
                )})?`}
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="agmDeadlineBeforeAfsDeadline"
                name="agmDeadlineBeforeAfsDeadline"
                defaultSelectedKey={agmDetail?.agmDeadlineBeforeAfsDeadline
                  ?.toString()
                  .toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('agmDeadlineBeforeAfsDeadline', newValue.key === 'true');
                  if (newValue.key === 'false') {
                    setValue('agmDeadline', null);
                  }
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={formErrors?.agmDeadlineBeforeAfsDeadline?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="taxFilingDeadlineBeforeAfsDeadline"
        defaultValue={agmDetail?.taxFilingDeadlineBeforeAfsDeadline}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                {`Is the tax filing deadline earlier than the AFS deadline (${momentUtc(
                  agmDetail?.filingDueDate,
                  StatDateFormats.DaySimple,
                )})?`}
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="taxFilingDeadlineBeforeAfsDeadline"
                name="taxFilingDeadlineBeforeAfsDeadline"
                defaultSelectedKey={agmDetail?.taxFilingDeadlineBeforeAfsDeadline
                  ?.toString()
                  .toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('taxFilingDeadlineBeforeAfsDeadline', newValue.key === 'true');
                  if (newValue.key === 'false') {
                    setValue('taxFilingDeadline', null);
                  }
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={formErrors?.taxFilingDeadlineBeforeAfsDeadline?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="taxFilingDeadline"
        control={control}
        defaultValue={agmDetail?.taxFilingDeadline}
        render={({ value }): React.ReactElement => (
          <div role="form">
            <Label disabled={watchTaxDeadlineBeforeAfsDeadline !== true}>
              Tax Filing Deadline
              {watchTaxDeadlineBeforeAfsDeadline === true ? <RequiredMarker /> : <></>}
            </Label>
            <UtcDatePicker
              disabled={watchTaxDeadlineBeforeAfsDeadline !== true}
              allowTextInput
              disableAutoFocus={false}
              value={value}
              textField={{
                name: 'taxFilingDeadline',
                errorMessage: formErrors?.taxFilingDeadline?.message,
              }}
              onSelectDate={(utcDateString: string): void => {
                setValue('taxFilingDeadline', utcDateString);
              }}
            />
          </div>
        )}
      />
      <Controller
        name="externalAfsFilingRequired"
        defaultValue={agmDetail?.externalAfsFilingRequired}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Is there a formal AFS external filing required?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="externalAfsFilingRequired"
                name="externalAfsFilingRequired"
                defaultSelectedKey={agmDetail?.externalAfsFilingRequired?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('externalAfsFilingRequired', newValue.key === 'true');
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={formErrors?.externalAfsFilingRequired?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="requiredToBeFiledWithAfs"
        defaultValue={agmDetail?.requiredToBeFiledWithAfs}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Do the AGM date and the FS date have to be aligned?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="requiredToBeFiledWithAfs"
                name="requiredToBeFiledWithAfs"
                defaultSelectedKey={agmDetail?.requiredToBeFiledWithAfs?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('requiredToBeFiledWithAfs', newValue.key === 'true');
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={formErrors?.requiredToBeFiledWithAfs?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="canAfsBeExtended"
        defaultValue={agmDetail?.canAfsBeExtended}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Can the AFS deadline be extended?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="canAfsBeExtended"
                name="canAfsBeExtended"
                defaultSelectedKey={agmDetail?.canAfsBeExtended?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('canAfsBeExtended', newValue.key === 'true');
                }}
              />
              <InlineFormInputErrorMessage errorMessage={formErrors?.canAfsBeExtended?.message} />
            </>
          );
        }}
      />
      <Controller
        name="physicalSignatureRequired"
        defaultValue={agmDetail?.physicalSignatureRequired}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Is a hard copy signature required for filing? <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="physicalSignatureRequired"
                name="physicalSignatureRequired"
                defaultSelectedKey={agmDetail?.physicalSignatureRequired?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('physicalSignatureRequired', newValue.key === 'true');
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={formErrors?.physicalSignatureRequired?.message}
              />
            </>
          );
        }}
      />
      <Controller
        id="hardCopyShippingAddress"
        name="hardCopyShippingAddress"
        control={control}
        defaultValue={agmDetail?.hardCopyShippingAddress || ''}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles} disabled={watchSignatureRequiredDeadline !== true}>
              Address for Hard Copies
              {watchSignatureRequiredDeadline === true ? <RequiredMarker /> : <></>}
            </Label>
            <TextField
              label=""
              disabled={watchSignatureRequiredDeadline !== true}
              value={value}
              type="string"
              multiline
              resizable={false}
              onChange={onChange}
              name={name}
              errorMessage={formErrors?.hardCopyShippingAddress?.message}
            />
          </>
        )}
      />
      <Controller
        id="hardCopyShippingContactName"
        name="hardCopyShippingContactName"
        control={control}
        defaultValue={agmDetail?.hardCopyShippingContactName || ''}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles} disabled={watchSignatureRequiredDeadline !== true}>
              Contact Name
              {watchSignatureRequiredDeadline === true ? <RequiredMarker /> : <></>}
            </Label>
            <TextField
              disabled={watchSignatureRequiredDeadline !== true}
              value={value}
              type="string"
              resizable={false}
              onChange={onChange}
              name={name}
              errorMessage={formErrors?.hardCopyShippingContactName?.message}
            />
          </>
        )}
      />
      <Controller
        id="hardCopyShippingPhoneNumber"
        name="hardCopyShippingPhoneNumber"
        control={control}
        defaultValue={agmDetail?.hardCopyShippingPhoneNumber || ''}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles} disabled={watchSignatureRequiredDeadline !== true}>
              Phone Number
              {watchSignatureRequiredDeadline === true ? <RequiredMarker /> : <></>}
            </Label>
            <TextField
              disabled={watchSignatureRequiredDeadline !== true}
              value={value}
              type="string"
              resizable={false}
              onChange={onChange}
              name={name}
              errorMessage={formErrors?.hardCopyShippingPhoneNumber?.message}
            />
          </>
        )}
      />
      <Controller
        name="inkColorRequired"
        defaultValue={agmDetail?.inkColorRequired}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Is there a required ink color for the signatures? <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="inkColorRequired"
                name="inkColorRequired"
                defaultSelectedKey={agmDetail?.inkColorRequired?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('inkColorRequired', newValue.key === 'true');
                  if (newValue.key === 'false') {
                    setValue('signatureInkColor', null);
                  }
                }}
              />
              <InlineFormInputErrorMessage errorMessage={formErrors?.inkColorRequired?.message} />
            </>
          );
        }}
      />
      <Controller
        name="signatureInkColor"
        control={control}
        defaultValue={agmDetail?.signatureInkColor}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles} disabled={watchInkColorRequired !== true}>
              Signature Ink Color
              {watchInkColorRequired === true ? <RequiredMarker /> : <></>}
            </Label>
            <Dropdown
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                index?: number,
              ): void => {
                setValue('signatureInkColor', option.key);
              }}
              options={signatureInkColorOptions}
              defaultSelectedKey={agmDetail?.signatureInkColor}
              disabled={watchInkColorRequired !== true}
              placeholder="Select an option"
              errorMessage={formErrors?.signatureInkColor?.message}
            />
          </>
        )}
      />
      <Label style={requiredLabelStyles}>
        How many originals are required? <RequiredMarker />
      </Label>
      <Controller
        name="originalsCount"
        control={control}
        as={TextField}
        type="number"
        defaultValue={agmDetail?.originalsCount?.toString() || ''}
        rules={{
          min: {
            value: 0,
            message: 'Please enter a positive number',
          },
        }}
        errorMessage={formErrors?.originalsCount?.message}
      />
      <Controller
        name="paperSize"
        control={control}
        defaultValue={agmDetail?.paperSize}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Paper Size <RequiredMarker />
            </Label>
            <Dropdown
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                index?: number,
              ): void => {
                setValue('paperSize', option.key);
                if (option.key !== 'OTHER') {
                  setValue('otherPaperSize', '');
                }
              }}
              options={paperSizeOptions}
              defaultSelectedKey={agmDetail?.paperSize}
              placeholder="Select an option"
              errorMessage={formErrors?.paperSize?.message}
            />
          </>
        )}
      />
      <Controller
        id="otherPaperSize"
        name="otherPaperSize"
        control={control}
        defaultValue={agmDetail?.otherPaperSize || ''}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles} disabled={watchPaperSize !== 'OTHER'}>
              Other Paper Size
              {watchPaperSize === 'OTHER' ? <RequiredMarker /> : <></>}
            </Label>
            <TextField
              disabled={watchPaperSize !== 'OTHER'}
              value={value}
              type="string"
              multiline
              resizable={false}
              onChange={onChange}
              name={name}
              errorMessage={formErrors?.otherPaperSize?.message}
            />
          </>
        )}
      />
      <Controller
        name="managementRepresentationLetterRequired"
        defaultValue={agmDetail?.managementRepresentationLetterRequired}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Is a Management Representation Letter Required? <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="managementRepresentationLetterRequired"
                name="managementRepresentationLetterRequired"
                defaultSelectedKey={agmDetail?.managementRepresentationLetterRequired
                  ?.toString()
                  .toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('managementRepresentationLetterRequired', newValue.key === 'true');
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={formErrors?.managementRepresentationLetterRequired?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="finalExternalFilingSubmitter"
        control={control}
        defaultValue={agmDetail?.finalExternalFilingSubmitter}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Who is submitting the final external filing? <RequiredMarker />
            </Label>
            <Dropdown
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                index?: number,
              ): void => {
                setValue('finalExternalFilingSubmitter', option.key);
                if (option.key !== 'OTHER') {
                  setValue('otherFinalExternalFilingSubmitter', '');
                }
              }}
              options={finalExternalFilingSubmitterOptions}
              defaultSelectedKey={agmDetail?.finalExternalFilingSubmitter}
              placeholder="Select an option"
              errorMessage={formErrors?.finalExternalFilingSubmitter?.message}
            />
          </>
        )}
      />
      <Controller
        id="otherFinalExternalFilingSubmitter"
        name="otherFinalExternalFilingSubmitter"
        control={control}
        defaultValue={agmDetail?.otherFinalExternalFilingSubmitter || ''}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Label
              style={requiredLabelStyles}
              disabled={watchFinalExternalFilingSubmitter !== 'OTHER'}
            >
              Other Final External Filing Submitter
              {watchFinalExternalFilingSubmitter === 'OTHER' ? <RequiredMarker /> : <></>}
            </Label>
            <TextField
              disabled={watchFinalExternalFilingSubmitter !== 'OTHER'}
              value={value}
              type="string"
              multiline
              resizable={false}
              onChange={onChange}
              name={name}
              errorMessage={formErrors?.otherFinalExternalFilingSubmitter?.message}
            />
          </>
        )}
      />
      <Controller
        name="auditingFirm"
        control={control}
        defaultValue={agmDetail?.auditingFirm}
        value={agmDetail?.auditingFirm}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Auditing Firm <RequiredMarker />
            </Label>
            <AuditingFirmsDropdown
              valueKey="auditingFirm"
              ariaLabel="Auditing Firm Dropdown"
              value={value}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              handleChange={(newValue: any, valueKey: string): void => {
                setValue('auditingFirm', newValue);
                if (newValue.fieldName !== 'Other') {
                  setValue('otherAuditingFirm', '');
                }
              }}
              errors={formErrors}
            />
          </>
        )}
      />
      <Controller
        id="otherAuditingFirm"
        name="otherAuditingFirm"
        control={control}
        defaultValue={agmDetail?.otherAuditingFirm || ''}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles} disabled={watchAuditingFirm?.fieldName !== 'Other'}>
              Other Auditing Firm
              {watchAuditingFirm?.fieldName === 'Other' ? <RequiredMarker /> : <></>}
            </Label>
            <TextField
              disabled={watchAuditingFirm?.fieldName !== 'Other'}
              value={value}
              type="string"
              multiline
              resizable={false}
              onChange={onChange}
              name={name}
              errorMessage={formErrors?.otherAuditingFirm?.message}
            />
          </>
        )}
      />
      <Controller
        id="auditorAppointmentPeriod"
        name="auditorAppointmentPeriod"
        control={control}
        defaultValue={agmDetail?.auditorAppointmentPeriod || ''}
        render={({ onChange, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Period of Auditor Appointment <RequiredMarker />
            </Label>
            <TextField
              value={value}
              type="string"
              multiline
              resizable={false}
              onChange={onChange}
              name={name}
              errorMessage={formErrors?.auditorAppointmentPeriod?.message}
            />
          </>
        )}
      />
      <Controller
        name="additionalProcessingTime"
        control={control}
        defaultValue={selectedAdditionalTimeOptions}
        render={() => {
          return (
            <>
              <Dropdown
                label="Additional Processing Time"
                onChange={(event, item): void => {
                  setSelectedAdditionalTime(
                    item.selected
                      ? [...selectedAdditionalTimeOptions, item.key.toString()]
                      : selectedAdditionalTimeOptions.filter((key) => key !== item.key),
                  );
                }}
                multiSelect
                options={additionalProcessingTimeOptions}
                defaultSelectedKeys={selectedAdditionalTimeOptions}
                placeholder="Select an option"
                errorMessage={formErrors?.additionalProcessingTimeApostille?.message}
              />
            </>
          );
        }}
      />
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <Stack tokens={{ childrenGap: 5 }}>
          <Label style={requiredLabelStyles}>
            Auditor Fees <RequiredMarker />
          </Label>
          <Controller
            name="auditorFees"
            control={control}
            as={TextField}
            type="number"
            defaultValue={agmDetail?.auditorFees?.toString() || ''}
            className={amountStyles}
            rules={{
              min: {
                value: 0,
                message: 'Please enter a positive number',
              },
            }}
            errorMessage={formErrors?.auditorFees?.message}
          />
        </Stack>
        <StackItem>
          <Controller
            name="auditorFeesCurrencyCode"
            control={control}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <CurrencyCodeDropdown
                label={
                  <Label style={{ paddingBottom: 0 }}>
                    Currency
                    <RequiredMarker />
                  </Label>
                }
                propertyName="auditorFeesCurrencyCode"
                handleChange={(newValue: string, valueKey: string): void => {
                  setValue('auditorFeesCurrencyCode', newValue);
                }}
                defaultValue={agmDetail?.auditorFeesCurrencyCode}
                errorMessage={formErrors?.auditorFeesCurrencyCode?.message}
              />
            )}
            defaultValue={agmDetail?.auditorFeesCurrencyCode}
          />
        </StackItem>
      </Stack>
    </Stack>
  );
};

export default AgmDetailForm;
