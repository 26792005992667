import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import ActionButtons from '../common/ActionButtons';

import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import RoleActionsForm from './RoleActionsForm';
import {
  CREATE_ROLE,
  GET_ROLE_ACTIONS_QUERY,
  RoleActions,
  UPDATE_ROLE,
} from '../../utils/statApi/RolesApi';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';

interface IRoleActionsPanel {
  closePanel: () => void;
  roleActions?: RoleActions;
}

const RoleActionsPanel: React.FunctionComponent<IRoleActionsPanel> = ({
  roleActions,
  closePanel,
}: IRoleActionsPanel) => {
  const notificationForm = useForm();
  const { errors, handleSubmit } = notificationForm;

  const [createRole, { loading: createLoading, error: createError }] = useMutation(CREATE_ROLE, {
    refetchQueries: [GET_ROLE_ACTIONS_QUERY],
    onCompleted: closePanel,
  });

  const [updateRole, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ROLE, {
    onCompleted: closePanel,
  });

  const handleSaveClick = (formData: RoleActions): void => {
    const roleActionSaveData: RoleActions = {
      id: parseInt(formData.id.toString(), 10) || -1,
      role: {
        id: parseInt(formData.id.toString(), 10) || -1,
        name: formData.role.name,
        isGlobal: formData.role.isGlobal,
        showInSecurityRolesList: formData.role.showInSecurityRolesList || false,
      },
      actions: formData.actions.map((ac) => {
        return {
          id: ac.id,
          entityName: ac.entityName,
          entityAction: ac.entityAction,
        };
      }),
    };

    if (!Object.keys(errors).length) {
      if (roleActionSaveData.id <= 0) {
        createRole({
          variables: {
            roleAction: roleActionSaveData,
          },
        });
      } else {
        updateRole({
          variables: {
            roleAction: roleActionSaveData,
          },
        });
      }
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Save Role Actions"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Role Actions Edit"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={updateLoading || createLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Role Actions"
          saveTitle="Save Role Actions"
          cancelLabel="Cancel Role Actions Edit"
          cancelTitle="Cancel Role Actions Edit"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <RoleActionsForm roleActions={roleActions} form={notificationForm} />
        <LoadingErrorMessage
          loading={createLoading}
          error={createError}
          actionName="creating a role"
        />
        <LoadingErrorMessage
          loading={updateLoading}
          error={updateError}
          actionName="updating the role"
        />
      </form>
    </CoherencePanel>
  );
};

export default RoleActionsPanel;
