import { OperationVariables, QueryResult, useQuery } from '@apollo/client';
import {
  Checkbox,
  ITag,
  mergeStyleSets,
  NeutralColors,
  normalize,
  SearchBox,
} from '@fluentui/react';
import { DocumentNode } from 'graphql';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../utils/hooks/UseDebounce';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../app/common/constants/SiteConstants';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';

const styles = mergeStyleSets({
  searchContainer: {
    marginBottom: 20,
  },
  container: {
    overflow: 'auto',
    maxHeight: 400,
  },
  itemContent: [
    normalize,
    {
      padding: '10px 5px 0 5px',
      height: 40,
      borderBottom: `1px solid ${NeutralColors.gray30}`,
      overflow: 'hidden',
    },
  ],
  checkLabel: {
    label: {
      whiteSpace: 'nowrap',
    },
  },
});

interface IChecklistItemProps {
  tag: ITag;
  onChange: (tag: ITag, isChecked: boolean) => void;
  isChecked: boolean;
  isDisabled?: boolean;
}

const ChecklistItem = (props: IChecklistItemProps): JSX.Element => {
  const { tag, onChange, isChecked, isDisabled } = props;

  const onCheckChanged = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean,
  ): void => {
    onChange(tag, checked);
  };

  return (
    <div data-is-focusable className={styles.itemContent}>
      <Checkbox
        label={tag?.name}
        checked={isChecked || isDisabled}
        onChange={onCheckChanged}
        className={styles.checkLabel}
        disabled={isDisabled}
      />
    </div>
  );
};

interface IGenericChecklistProps {
  label?: string;
  items?: ITag[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapData?: (item: any) => ITag[];
  query?: DocumentNode;
  onCheckChanged?: (currentSelections: ITag[]) => void;
  selections?: ITag[];
  disabledItems?: ITag[];
  showSearch?: boolean;
}

const GenericChecklist = (props: IGenericChecklistProps): JSX.Element => {
  const {
    label,
    query,
    items,
    mapData,
    onCheckChanged,
    selections,
    disabledItems,
    showSearch = true,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');

  const onChange = (tag: ITag, isChecked: boolean): void => {
    const indexOfValue = selections?.findIndex(
      (item) => (item.key ?? item.name) === (tag.key ?? tag.name),
    );

    if (isChecked) {
      selections.push(tag);
    } else {
      selections.splice(indexOfValue, 1);
    }

    if (onCheckChanged) {
      onCheckChanged(selections);
    }
  };

  const onSearchChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
    setSearchTerm(newValue);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, THROTTLE_SEARCH_TIMEOUT);

  const getVariables = () => {
    return {
      keyword: debouncedSearchTerm,
    };
  };

  // https://github.com/apollographql/apollo-client/issues/7021 a valid query is needed even if using skip since the query is initialized before parsing the skip

  let useQueryResult: Partial<QueryResult<unknown, OperationVariables>> = {
    loading: false,
    error: undefined,
    data: undefined,
    refetch: () => null,
  };

  if (!items && query) {
    useQueryResult = useQuery(query, {
      variables: getVariables(),
      skip: items?.length > 0,
    });
  }

  useEffect(() => {
    if (!useQueryResult.loading && !useQueryResult.error && !items?.length && query) {
      useQueryResult.refetch(getVariables());
    }
  }, [debouncedSearchTerm]);

  const onRenderRow = (item: ITag) => {
    const stateIndex = selections?.findIndex((s) => (s.key ?? s.name) === (item.key ?? item.name));
    const disabledIndex = disabledItems?.findIndex(
      (s) => (s.key ?? s.name) === (item.key ?? item.name),
    );
    return (
      <ChecklistItem
        key={`${item?.key ?? item?.name}:ChecklistItem`}
        tag={item}
        onChange={onChange}
        isChecked={stateIndex >= 0}
        isDisabled={disabledIndex >= 0}
      />
    );
  };

  return (
    <div>
      {label && label?.length && <div>{label}</div>}
      {!items?.length && showSearch && (
        <SearchBox
          value={searchTerm}
          onChange={onSearchChange}
          placeholder="Search items"
          ariaLabel={label}
        />
      )}
      <LoadingErrorMessage loading={useQueryResult.loading} error={useQueryResult.error} />
      <div className={styles.container} data-is-scrollable>
        {items?.length > 0 &&
          items.map((item: ITag) => {
            return onRenderRow(item);
          })}

        {!items?.length &&
          query &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          mapData(useQueryResult.data)?.map((item: any) => {
            return onRenderRow(item);
          })}
      </div>
    </div>
  );
};

export default GenericChecklist;
