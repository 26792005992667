import { IDocument } from '../../utils/statApi/DocumentApi';
import { StatUser } from '../../utils/statApi/UsersApi';

export default interface ICompanyCreationRequest {
  id?: number;
  companyCode?: number;
  companyName: string;
  isAcquisition: string;
  acquisition: IAcquisition;
  isConfidential: string;
  entityType: IEntityType;
  businessNatureOfEntity: string;
  financialType: IFinancialType;
  dateOfAcquisition: Date;
  davaDealLead: StatUser;
  status: string;
  statusId?: CompanyCreationStatus;
  shadowCompanyCode?: number;
  parentCompanyCode?: number;
}

export interface ICompanyCreationRequestDetails extends ICompanyCreationRequest {
  requestor: StatUser;
}

export interface ICompanyCreationRequestListItem {
  id: number;
  companyName: string;
  requestedDate: Date;
  requestor: string;
  requestorId: number;
  acquisition: string;
  acquisitionId: number;
}

export interface IAcquisition {
  key: number;
  text: string;
}

interface IEntityType {
  id: number;
  fieldName: string;
}

interface IFinancialType {
  id: number;
  fieldName: string;
}

export enum CompanyCreationStatus {
  RequestStarted = 1,
  CompanyCodeRequested,
  PendingSubledgerInfo,
  Complete,
  Canceled,
  PendingStatToolSetup,
}

export enum FunctionalCurrencyForm {
  CashFlows,
  SalesPrice,
  SalesMarket,
  Expenses,
  Financing,
  IntercompanyTransactionsAndArrangements,
}

export enum CompanyInformationForm {
  FunctionalCurrency,
  LegalDocumentInfo,
  SapSetup,
  InHouseCashCenter,
  SubledgerInfo,
  ShadowCompany,
  StatutoryInformation,
  CompanyCreationContacts,
}

export interface IDocumentsList {
  id: number;
  currentDocuments: IDocument[];
  newDocuments: IDocument[];
  removedDocuments: IDocument[];
  uploadIsInProgress: boolean;
}

export enum OverviewForm {
  InitialInfo,
  SubmitSAP,
  CompleteSubledger,
  ActivateCompany,
  SubmitSAPProgress,
  SubmitSubledgerProgress,
  SubmitStatProgress,
  CompanyCode,
  ShadowCompanyCode,
}

export enum FinancialType {
  ConsolidatedMicrosoftEntity = 1,
  JointVenture,
  NonTransactional,
  ShadowCompany,
}

export interface IShadowCompanyCreationRequest {
  id?: number;
  parentCompanyCode?: number;
  shadowCompanyName: string;
}
