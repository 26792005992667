import { useQuery } from '@apollo/client';
import { CommandBar, ICommandBarItemProps, INavLinkGroup } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GET_COUNTRY_CONFIGS } from '../../utils/statApi/CountryApi';
import CanAccess from '../common/canAccess/CanAccess';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import MainDetailsView from '../common/mainDetail/MainDetailsView';
import { ICountryIdParams } from '../common/ParamTypes';
import CountryConfigEditPanel from './CountryConfigEditPanel';
import { ICountryConfiguration } from './CountryConfiguration.config';
import CountryConfigurationInformation from './CountryConfigurationInformation';

const CountryConfigurationView: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const { countryId } = useParams<ICountryIdParams>();
  const [addMode, setIsAddMode] = useState(false);
  const [countryConfigData, setCountryConfigData] = useState<ICountryConfiguration>(null);
  const [isCountryConfigPanelOpen, setIsCountryConfigPanelOpen] = useState(false);
  const { data, loading, error } = useQuery(GET_COUNTRY_CONFIGS);
  const countryConfigurations: ICountryConfiguration[] = data?.countryConfigurations;

  useEffect(() => {
    if (!countryId && countryConfigurations && countryConfigurations.length > 0) {
      history.replace(`/countries/${countryConfigurations[0].id}`);
    }
  }, [countryConfigurations]);

  const links = countryConfigurations?.map((config) => {
    return {
      name: config.country.shortName,
      onClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
        ev.preventDefault();
        ev.stopPropagation();
        history.push(`/countries/${config.id}`);
      },
      url: config.id.toString(),
    };
  });

  const navLinks: INavLinkGroup[] = [
    {
      links,
    },
  ];

  const toggleAddCountryConfigPanel = () => {
    setIsAddMode(!addMode);
    setIsCountryConfigPanelOpen(!isCountryConfigPanelOpen);
  };

  const toggleCountryConfigPanel = () => {
    setIsAddMode(false);
    setIsCountryConfigPanelOpen(!isCountryConfigPanelOpen);
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'New Country Configuration',
      iconProps: { iconName: 'Add' },
      onClick: () => {
        toggleAddCountryConfigPanel();
      },
    },
  ];

  const renderHeader = (): JSX.Element => <h1>Country Configuration</h1>;

  return (
    <>
      <FullWidthHeader title={renderHeader} />
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading the Country Configuration List"
      />
      <MainDetailsView
        navLinkGroups={navLinks}
        navSectionHeader={
          <CanAccess requestedAction="CountryConfiguration:AddEdit">
            <CommandBar
              items={commandBarItems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
            />
          </CanAccess>
        }
      >
        {countryId && (
          <CountryConfigurationInformation
            toggleCountryConfigPanel={toggleCountryConfigPanel}
            setCountryConfigData={setCountryConfigData}
          />
        )}
      </MainDetailsView>
      {isCountryConfigPanelOpen && (
        <CountryConfigEditPanel
          countryData={addMode ? ({} as ICountryConfiguration) : countryConfigData}
          closePanel={toggleCountryConfigPanel}
        />
      )}
    </>
  );
};

export default CountryConfigurationView;
