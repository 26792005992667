import React from 'react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { IProcessCorpReasonForLateFiling } from '../Interfaces';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import CorpReasonForLateFilingForm from './CorpReasonForLateFilingForm';
import { EDIT_CORP_REASON_FOR_LATE_FILING_MUTATION } from '../../../../utils/statApi/ProcessApi';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import CanAccess from '../../../common/canAccess/CanAccess';
import ActionButtons from '../../../common/ActionButtons';
import { IProcessIdParams } from '../../../common/ParamTypes';

interface ICorpReasonForLateFilingEditPanelProps {
  processCorpReasonForLateFiling: IProcessCorpReasonForLateFiling;
  closePanel: () => void;
}

const CorpReasonForLateFilingEditPanel: React.FunctionComponent<
  ICorpReasonForLateFilingEditPanelProps
> = ({
  processCorpReasonForLateFiling,
  closePanel,
}: ICorpReasonForLateFilingEditPanelProps): JSX.Element => {
  const form = useForm<IProcessCorpReasonForLateFiling>({
    defaultValues: {
      ...processCorpReasonForLateFiling,
    },
  });
  const { processId } = useParams<IProcessIdParams>();
  const { handleSubmit } = form;
  const [updateCorpReasonForLateFiling, { loading: mutationLoading, error: mutationError }] =
    useMutation(EDIT_CORP_REASON_FOR_LATE_FILING_MUTATION, {
      onCompleted: closePanel,
    });

  const handleOnSaveClick = (formData: IProcessCorpReasonForLateFiling): void => {
    const corpReasonSaveData = {
      corpReasonForLateFiling: {
        id: formData?.corpReasonForLateFiling?.id,
        fieldName: formData?.corpReasonForLateFiling?.fieldName,
      },
      corpReasonForLateFilingComment: formData?.corpReasonForLateFilingComment,
      processId: parseInt(processId, 10),
    };

    updateCorpReasonForLateFiling({
      variables: {
        processCorpReasonForLateFiling: {
          ...corpReasonSaveData,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Corp Reason For Late Filing"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Corp Reason For Filing Violation Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="CorpReasonForFilingViolation:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveTitle="Save Corp Reason For Filing Violation"
            saveLabel="Save Corp Reason For Filing Violation"
            cancelTitle="Cancel Corp Reason For Filing Violation Edit"
            cancelLabel="Cancel Corp Reason For Filing Violation Edit"
          />
        </CanAccess>
      )}
    >
      <form>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the Corp Reason For Late Filing to the server"
        />
        <CorpReasonForLateFilingForm
          form={form}
          processCorpReasonForLateFiling={processCorpReasonForLateFiling}
        />
      </form>
    </CoherencePanel>
  );
};

export default CorpReasonForLateFilingEditPanel;
