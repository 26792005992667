// eslint-disable-next-line
export default function hasAccess(userPermissions: any, action = ''): boolean {
  const separator = ':';

  const [entity, requestedAction] = action.split(separator);

  let permissionsObj;
  try {
    permissionsObj = JSON.parse(userPermissions);
  } catch (e) {
    permissionsObj = userPermissions;
  }

  return permissionsObj && permissionsObj[entity] ? permissionsObj[entity][requestedAction] : false;
}
