import React from 'react';
import { Stack, TextField } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Controller, UseFormMethods, useWatch } from 'react-hook-form';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import GET_REASON_FOR_FILING_VIOLATION from '../../../../utils/statApi/ReasonForFilingViolationApi';
import { IFinancialStatementProcess } from '../Interfaces';

export interface IReasonForFilingViolationFormProps {
  form: UseFormMethods<IFinancialStatementProcess>;
  processDetail: IFinancialStatementProcess;
}

const ReasonForFilingViolationDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_REASON_FOR_FILING_VIOLATION);
  const { reasonsForFilingViolation } = !loading && data ? data : [];
  return (
    <QueryBasedDropdown
      id="reasonForFilingViolationDropdown"
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={reasonsForFilingViolation}
      errors={errors}
      handleChange={handleChange}
      tooltipProps={{
        showTooltip: true,
        tooltipContent:
          '"Not available at the time of entry" option is deprecated and cannot be selected.',
      }}
      valueKey={valueKey}
      clearable
      required
    />
  );
};

const ReasonForFilingViolationForm = ({
  form,
  processDetail,
}: IReasonForFilingViolationFormProps): JSX.Element => {
  const { control, errors, setValue } = form;
  const watchFilingReason = useWatch({
    control,
    name: 'reasonForFilingViolation',
    defaultValue: processDetail?.reasonForFilingViolation,
  });

  const onReasonChange = (newValue: IDropdownReference, valueKey: string) => {
    if (!newValue?.id) {
      setValue('reasonForFilingViolationComments', '');
    }
    setValue('reasonForFilingViolation', newValue);
  };
  const validateComments = (newVal: string): string => {
    if (!newVal?.trim() && watchFilingReason !== null && watchFilingReason?.fieldName !== '--') {
      return 'Comments are required';
    }
    return null;
  };
  const validateReason = (newVal: string): string => {
    if (!watchFilingReason?.id && watchFilingReason?.fieldName !== '--') {
      return 'A reason is required';
    }
    return null;
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Controller
        control={control}
        name="reasonForFilingViolation"
        value={processDetail?.reasonForFilingViolation}
        defaultValue={processDetail?.reasonForFilingViolation}
        rules={{ validate: validateReason }}
        errorMessage={errors?.reasonForFilingViolation}
        render={({ value }) => (
          <ReasonForFilingViolationDropdown
            label="Reason"
            valueKey="reasonForFilingViolation"
            value={value}
            errors={errors}
            handleChange={onReasonChange}
          />
        )}
      />
      <Controller
        as={TextField}
        id="reasonForFilingViolationComments"
        name="reasonForFilingViolationComments"
        label="Comments"
        control={control}
        multiline
        defaultValue={processDetail?.reasonForFilingViolationComments || ''}
        required={watchFilingReason !== null && watchFilingReason?.fieldName !== '--'}
        disabled={!watchFilingReason?.id ?? true}
        rules={{ validate: validateComments }}
        errorMessage={errors?.reasonForFilingViolationComments?.message}
      />
    </Stack>
  );
};

export default ReasonForFilingViolationForm;
