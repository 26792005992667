import { M365LightTheme } from '@m365-admin/customizations';
import {
  getCurrentStep,
  goToStepById,
  ISubwayNavNodeProps,
  SubwayNav,
  SubwayNavNodeState,
} from '@m365-admin/wizard';
import React from 'react';

interface IProcessConfigWizardNavProps {
  steps: ISubwayNavNodeProps[];
  onStepClick: (newSteps: ISubwayNavNodeProps[], newCurrentStepId: string) => void;
}
const ProcessConfigWizardNav = (props: IProcessConfigWizardNavProps): JSX.Element => {
  const { steps, onStepClick } = props;

  const handleStepClick = (step: ISubwayNavNodeProps): void => {
    const currentStep = getCurrentStep(steps);
    const currentStepIndex = steps.findIndex((loopedStep) => loopedStep.id === currentStep.id);
    const newStepIndex = steps.findIndex((loopedStep) => loopedStep.id === step.id);
    const navState =
      currentStepIndex > newStepIndex ? SubwayNavNodeState.Unsaved : SubwayNavNodeState.Completed;
    const newSteps = goToStepById(steps, step.id, navState).steps;
    onStepClick(newSteps, step.id);
  };

  const stepsWithClick = steps.map((step) => {
    step.onClickStep = handleStepClick;
    return step;
  });

  const ariaLabelStrings = {
    NotStarted: 'NotStarted',
    Current: 'Current',
    CurrentWithSubSteps: 'CurrentWithSubSteps',
    Completed: 'Completed',
    ViewedNotCompleted: 'ViewedNotCompleted',
    Unsaved: 'Unsaved',
    Skipped: 'Skipped',
    Error: 'Error',
    WizardComplete: 'WizardComplete',
  };

  return (
    <SubwayNav
      theme={M365LightTheme.settings.theme}
      stateAriaLabels={ariaLabelStrings}
      steps={stepsWithClick}
    />
  );
};
export default ProcessConfigWizardNav;
