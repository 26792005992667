import { useQuery } from '@apollo/client';
import { IGroup } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_COMPANY_CREATION_COMPANY_INFO } from '../../../utils/statApi/CompanyCreationApi';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import RequiredFieldsPanel from './RequiredFieldsPanel';
import { IRequiredItem, ISubledgerInfo } from './types';

interface ISubledgerProgressPanelProps {
  closePanel: () => void;
  companyCode: number;
}

const SubledgerProgressPanel = (props: ISubledgerProgressPanelProps): JSX.Element => {
  const { closePanel, companyCode } = props;
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const [subledgerProgressData, setSubledgerProgressData] = useState<ISubledgerInfo>();

  const { data, loading, error } = useQuery(GET_COMPANY_CREATION_COMPANY_INFO, {
    variables: {
      companyCreationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const progressData: ISubledgerInfo = {
      ...data?.companyCreationCompanyInfo?.subledgerInfo,
      companyCode,
    };
    setSubledgerProgressData(progressData);
  }, [data]);

  const doesItemHaveValue = (itemKey: string): boolean => {
    const value = subledgerProgressData[itemKey as keyof ISubledgerInfo];
    return value !== '' && value !== null && value !== undefined;
  };

  const generalItems: IRequiredItem[] = [];
  const subledgerItems: IRequiredItem[] = [];

  const getItems = (): IRequiredItem[] => {
    const hasHeadcount = subledgerProgressData?.willHaveHeadcount;

    const items: IRequiredItem[] = [];

    generalItems.push({ key: 'companyCode', value: 'Company Code' });

    subledgerItems.push(
      { key: 'isDomesticEntity', value: 'Is New Domestic Entity' },
      { key: 'willHaveIntercompanyActivity', value: 'Requires Inter-compny Transactions' },
      { key: 'willRequireMscPremierServices', value: 'Requires MCS/Premier' },
      { key: 'willHaveHeadcount', value: 'Has Headcount' },
    );

    if (hasHeadcount) {
      subledgerItems.push({ key: 'personalSubareaCities', value: 'Personnel Subarea' });
      subledgerItems.push({ key: 'weeklyWorkingHours', value: 'Work Schedule' });
    }

    subledgerItems.push(
      { key: 'willNeedFaModule', value: 'FA Module' },
      { key: 'willNeedApMmModule', value: 'AP/MM Module' },
      { key: 'willTransmitToMsSales', value: 'Will Transmit to MS Sales' },
      { key: 'bsReconOwner', value: 'Balance Sheet Recon Owner' },
      { key: 'bsReconReviewer', value: 'Balance Sheet Recon Reviewer' },
      { key: 'bsReconController', value: 'Balance Sheet Recon Controller' },
    );

    return items.concat(generalItems, subledgerItems);
  };

  const getGroups = (): IGroup[] => {
    const groups: IGroup[] = [];
    groups.push({ key: 'general', name: 'General', startIndex: 0, count: generalItems.length });

    groups.push({
      key: 'subledgerItems',
      name: 'Subledger Info',
      startIndex: 1,
      count: subledgerProgressData?.willHaveHeadcount ? 12 : 10,
    });

    return groups;
  };

  const items = getItems();
  const groups = getGroups();

  return (
    <RequiredFieldsPanel
      closePanel={closePanel}
      doesItemHaveValue={doesItemHaveValue}
      items={items}
      groups={groups}
      loading={loading}
      error={error}
    />
  );
};

export default SubledgerProgressPanel;
