import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import {
  GET_PROCESS_TEMPLATE_VERSIONS_FOR_TEMPLATE,
  DEACTIVATE_PROCESS_TEMPLATE,
} from '../../../utils/statApi/ProcessTemplateApi';
import { IProcessTemplate } from '../../../utils/types/IProcessTemplate';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ProcessTemplateDetailsCommand from './ProcessTemplateDetailsCommand';
import ProcessTemplateEditPanel from './ProcessTemplateEditPanel';
import QueryBasedDropdown from '../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import ProcessTemplateReviewPanel from './ProcessTemplateReviewPanel';
import { IProcessTemplateTypeParams } from '../../common/ParamTypes';
import ProcessTemplateMilestoneList from './ProcessTemplateMilestoneList';

interface IProcessTemplateDetailsProps {
  processTemplateDetail: IProcessTemplate;
}
const ProcessTemplateDetails = ({
  processTemplateDetail,
}: IProcessTemplateDetailsProps): JSX.Element => {
  const { processTypeId } = useParams<IProcessTemplateTypeParams>();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const milestoneListWrapperStyles = {
    root: { width: '100%', padding: 30 },
  };

  const [currentProcessTemplateVersion, setCurrentProcessTemplateVersion] = useState(null);

  const { data, loading, error } = useQuery(GET_PROCESS_TEMPLATE_VERSIONS_FOR_TEMPLATE, {
    variables: {
      processTemplateId: processTemplateDetail.id,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => {
      if (result?.processTemplateVersions?.length) {
        setCurrentProcessTemplateVersion(result.processTemplateVersions[0].id);
      }
    },
  });
  const { processTemplateVersions } = !loading && data ? data : { processTemplateVersions: [] };

  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [isProcessTemplateReviewPanelOpen, setIsProcessTemplateReviewPanelOpen] = useState(false);

  const toggleProcessTemplateReviewPanel = (): void => {
    setIsProcessTemplateReviewPanelOpen(!isProcessTemplateReviewPanelOpen);
  };

  const toggleEditProcessTemplatePanel = (): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  const [deactivateProcessTemplate, { loading: loadingMutation, error: errorMutation }] =
    useMutation(DEACTIVATE_PROCESS_TEMPLATE, {
      onCompleted: (): void => {
        toggleProcessTemplateReviewPanel();
        history.replace(`/process-templates/${processTypeId}`);
      },
    });

  const handleSubmit = (): void => {
    deactivateProcessTemplate({
      variables: {
        processTemplateDeactivateDto: {
          processTemplateId: processTemplateDetail.id,
        },
      },
    });
  };

  return (
    <>
      {processTemplateDetail.isActive && !loading && !error && (
        <ProcessTemplateDetailsCommand
          onManageMilestoneClick={() => {
            history.push(`${currentPath}/edit`);
          }}
          onEditProcessTemplateClick={toggleEditProcessTemplatePanel}
          onDeactivateProcessTemplateClick={toggleProcessTemplateReviewPanel}
          disableCommandButtons={loading || error !== undefined}
        />
      )}
      {!processTemplateDetail.isActive && !loading && !error && (
        <MessageBar
          messageBarType={MessageBarType.info}
          styles={{
            root: {
              padding: '0px',
              margin: '-30px -30px 0 -30px',
            },
          }}
        >
          This template has been deactivated and cannot be edited
        </MessageBar>
      )}
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading process template versions from database"
      />

      <Stack horizontal tokens={{ childrenGap: 50 }}>
        <Stack grow tokens={{ childrenGap: 20 }} styles={milestoneListWrapperStyles}>
          {processTemplateVersions?.length > 0 && (
            <QueryBasedDropdown
              required
              value={processTemplateVersions[0] as IDropdownReference}
              label="Version"
              errors={{}}
              dropdownOptionsData={processTemplateVersions}
              handleChange={(value) => {
                setCurrentProcessTemplateVersion(value.id);
              }}
              valueKey="processTemplateVersions"
            />
          )}
          <ProcessTemplateMilestoneList processTemplateVersionId={currentProcessTemplateVersion} />
        </Stack>
        {isEditPanelOpen && (
          <ProcessTemplateEditPanel
            processTemplate={processTemplateDetail}
            closePanel={toggleEditProcessTemplatePanel}
          />
        )}
        {isProcessTemplateReviewPanelOpen && (
          <ProcessTemplateReviewPanel
            processTemplate={processTemplateDetail}
            closePanel={toggleProcessTemplateReviewPanel}
            handleSubmit={handleSubmit}
            loadingMutation={loadingMutation}
            errorMutation={errorMutation}
            action="Deactivate"
            messageBarText="Deactivating this template will end the recurrence of all process configurations using that
            template. All unstarted processes will be removed."
          />
        )}
      </Stack>
    </>
  );
};
export default ProcessTemplateDetails;
