import { ISharedMilestoneAttributes } from './types/IProcessTemplate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addDurationToMilestones = (milestones: any[], defaultAllMinimum?: boolean): any[] => {
  const newMilestones = milestones?.map((milestone, currentIndex) => {
    const compareTo = currentIndex - 1;

    const newMilestone = { ...milestone };

    newMilestone.duration = 0;
    if (currentIndex !== 0) {
      const currentOffset = milestones[compareTo].defaultDaysOffset;
      const duration = Math.abs(currentOffset - milestone.defaultDaysOffset);
      newMilestone.duration = duration;
    }
    if (defaultAllMinimum) {
      newMilestone.minimumDuration = 1;
    }

    return newMilestone;
  });
  return newMilestones;
};

export const updateMilestoneOffsets = (
  newValue: number,
  milestoneId: number,
  milestonesWithDurations: ISharedMilestoneAttributes[],
  ignoreMinimumValue?: boolean,
): { newMilestones: ISharedMilestoneAttributes[]; newValue: string } => {
  const modifiedMilestone = milestonesWithDurations.find((mi) => mi.id === milestoneId);
  const indexOfModified = milestonesWithDurations.indexOf(modifiedMilestone);

  const goalMilestone = milestonesWithDurations.find(
    (mi) => mi.maxDaysOffset === 0 || mi.defaultDaysOffset === 0,
  );
  const indexOfGoalMilestone = milestonesWithDurations.indexOf(goalMilestone);

  if (!ignoreMinimumValue) {
    const { minimumDuration, maximumDuration } = modifiedMilestone;
    if (minimumDuration !== null && newValue < minimumDuration) {
      newValue = minimumDuration;
    }
    if (maximumDuration !== null && newValue > maximumDuration) {
      newValue = maximumDuration;
    }
  }
  // Calculate the delta between the old duration and the new duration
  const daysDifference = newValue - Math.abs(modifiedMilestone.duration);
  modifiedMilestone.duration = newValue;
  // Grab the milestones that we need to update
  // If the modified milestone is the goal milestone or before , get all  milestones before the modified milestone
  // If it's after the goal milestone, get all milestones after and including the modified milestone
  const milestonesToUpdate = milestonesWithDurations.filter((mi, index) =>
    indexOfModified <= indexOfGoalMilestone ? index < indexOfModified : index >= indexOfModified,
  );

  // For milestones before goal, we need to subtract the difference
  // For milestones after the goal, we need to add the difference
  milestonesToUpdate.map((mi, index) => {
    const indexOfCurrent = milestonesWithDurations.indexOf(mi);
    const newDaysOffset =
      indexOfCurrent < indexOfGoalMilestone
        ? mi.defaultDaysOffset - daysDifference
        : mi.defaultDaysOffset + daysDifference;
    mi.defaultDaysOffset = newDaysOffset;

    return mi;
  });
  return { newMilestones: milestonesWithDurations, newValue: newValue.toString() };
};
export const updateMilestoneOffsetsCallback = (
  newValue: number,
  milestoneId: number,
  milestonesWithDurations: ISharedMilestoneAttributes[],
  callback: (newMilestone: ISharedMilestoneAttributes[]) => void,
): string => {
  const { newMilestones, newValue: validatedInputValue } = updateMilestoneOffsets(
    newValue,
    milestoneId,
    milestonesWithDurations,
  );
  callback(newMilestones);

  return validatedInputValue.toString();
};
