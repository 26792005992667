import { Stack } from '@fluentui/react';
import React from 'react';
import { IFilingInfo } from '../companies/serviceContacts/interfaces';
import Card from './card/Card';
import SpanWithLabel from './SpanWithLabel';
import StackColumn from './StackColumn';

interface IFilingInfoCardProps {
  loading: boolean;
  filingInfo: IFilingInfo;
  editAction: JSX.Element;
}

const FilingInfoCard = ({ loading, filingInfo, editAction }: IFilingInfoCardProps): JSX.Element => {
  return (
    <Card title="Filing Information" isLoading={loading} actionButton={editAction}>
      <Stack horizontal wrap horizontalAlign="space-around" tokens={{ childrenGap: 60 }}>
        <StackColumn>
          <SpanWithLabel
            labelText="Oversight Group"
            value={filingInfo?.oversightGroup ? filingInfo?.oversightGroup.fieldName : ''}
          />
          {/* TODO: figure out enum for these */}
          <SpanWithLabel
            labelText="Responsible for STAT Filing Preparation"
            value={`${filingInfo?.responsibleForStatFilingPreparation || ''}  ${
              filingInfo?.responsibleForStatFilingPreparationThirdParty || ''
            }`}
          />
        </StackColumn>
        <StackColumn>
          <SpanWithLabel
            labelText="Responsible for Record"
            value={`${filingInfo?.responsibleForRecord || ''}  ${
              filingInfo?.responsibleForRecordThirdParty || ''
            }`}
          />
          <SpanWithLabel labelText="Other Information" value={filingInfo?.otherInformation} />
        </StackColumn>
      </Stack>
    </Card>
  );
};

export default FilingInfoCard;
