import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Stack, TextField, ChoiceGroup } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import { IFinancialStatementProcess } from '../Interfaces';
import StatDateFormats from '../../../../utils/types/DateFormats';
import GET_AUDIT_OPINION_QUERY from '../../../../utils/statApi/AuditOpinionApi';
import ActionButtons from '../../../common/ActionButtons';
import CanAccess from '../../../common/canAccess/CanAccess';
import momentUtc from '../../../../utils/DateFormatter';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import InlineFormInputErrorMessage from '../../../common/errorContent/InlineFormInputErrorMessage';
import monthDayFormat from '../../../../utils/MonthDayFormatter';
import { yesNoOptions } from '../../../../utils/types/CommonDropdownOptions';

export interface IProcessDetailFormProps {
  processDetails: IFinancialStatementProcess;
  handleSubmit: (payload: IFinancialStatementProcess) => void;
  handleCancel: () => void;
  loading: boolean;
}

interface IErrorMessage {
  message?: string;
}

interface IProcessDetailFormErrorProps {
  auditOpinion?: IErrorMessage;
  statutoryAuditFinding?: IErrorMessage;
  remediationPlanInPlace?: IErrorMessage;
  expectedClosureDate?: IErrorMessage;
}

interface IProcessDetailFormState extends IFinancialStatementProcess {
  errors?: IProcessDetailFormErrorProps;
}

const AuditOpinionDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, handleChange, valueKey, outputKey, errors } = props;
  const { loading, data } = useQuery(GET_AUDIT_OPINION_QUERY);
  const { auditOpinions } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      id="auditOpinionDropdown"
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={auditOpinions}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

const ProcessDetailForm = ({
  processDetails,
  handleCancel,
  handleSubmit,
  loading,
}: IProcessDetailFormProps): JSX.Element => {
  const [pageState, setPageState] = useState<IProcessDetailFormState>({
    ...processDetails,
    errors: {},
  });

  const handleSaveClick = (): void => {
    if (!pageState.auditOpinion) {
      setPageState({
        ...pageState,
        errors: { auditOpinion: { message: 'Audit Opinion is required.' } },
      });
      return;
    }
    if (
      pageState.auditOpinion.fieldName === 'Qualified' &&
      pageState.statutoryAuditFinding === null
    ) {
      setPageState({
        ...pageState,
        errors: {
          statutoryAuditFinding: {
            message: 'Please make a Statutory Audit Finding selection.',
          },
        },
      });
      return;
    }
    if (
      pageState.auditOpinion.fieldName === 'Qualified' &&
      pageState.statutoryAuditFinding &&
      pageState.remediationPlanInPlace === null
    ) {
      setPageState({
        ...pageState,
        errors: {
          remediationPlanInPlace: {
            message: 'Please make a Remediation Plan in Place selection.',
          },
        },
      });
      return;
    }

    if (
      pageState.auditOpinion.fieldName === 'Qualified' &&
      pageState.statutoryAuditFinding &&
      pageState.remediationPlanInPlace &&
      pageState.expectedClosureDate === null
    ) {
      setPageState({
        ...pageState,
        errors: {
          expectedClosureDate: {
            message: 'Expected Closure Date is required.',
          },
        },
      });
      return;
    }

    if (
      pageState.auditOpinion.fieldName === 'Qualified' &&
      pageState.statutoryAuditFinding &&
      pageState.remediationPlanInPlace &&
      // only want to compare dates here, not time.
      moment(pageState.expectedClosureDate.split('T')[0]).isBefore(new Date().toDateString(), 'day')
    ) {
      setPageState({
        ...pageState,
        errors: {
          expectedClosureDate: {
            message: 'Expected Closure cannot be in the past.',
          },
        },
      });
      return;
    }

    handleSubmit(pageState);
  };

  useEffect(() => {
    if (pageState.auditOpinion?.fieldName !== 'Qualified') {
      setPageState({
        ...pageState,
        statutoryAuditFinding: null,
        remediationPlanInPlace: null,
        expectedClosureDate: null,
      });
    }
  }, [pageState.auditOpinion]);

  useEffect(() => {
    if (pageState.statutoryAuditFinding === false) {
      setPageState({ ...pageState, remediationPlanInPlace: null });
    }
  }, [pageState.statutoryAuditFinding]);

  useEffect(() => {
    if (!pageState.remediationPlanInPlace) {
      setPageState({ ...pageState, expectedClosureDate: null });
    }
  }, [pageState.remediationPlanInPlace]);

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <UtcDatePicker
        label="Reporting Period End Date"
        disabled
        value={momentUtc(pageState.reportingPeriodEndDate, StatDateFormats.DayBreakout)}
      />

      <UtcDatePicker
        label="Filing Due Date"
        disabled
        value={momentUtc(pageState.filingDueDate, StatDateFormats.DayBreakout)}
      />

      <TextField
        label="AGM Due Date"
        disabled
        name="annualGeneralMeetingDueDate"
        defaultValue={monthDayFormat(
          pageState?.agmFiscalYear.agmDueDateMonth,
          pageState?.agmFiscalYear.agmDueDateDay,
        )}
      />

      <UtcDatePicker
        label="AGM Meeting Date"
        disabled
        textField={{
          name: 'annualGeneralMeetingDate',
        }}
        value={
          pageState.agmFiscalYear.celaAgmStatusDate
            ? momentUtc(pageState.agmFiscalYear.celaAgmStatusDate, StatDateFormats.DayBreakout)
            : null
        }
        onSelectDate={(date: string): void => {
          pageState.agmFiscalYear.celaAgmStatusDate = date;
          setPageState({
            ...pageState,
          });
        }}
      />

      <AuditOpinionDropdown
        label="Audit Opinion"
        valueKey="auditOpinion"
        handleChange={(opinion): void => {
          setPageState({
            ...pageState,
            auditOpinion: opinion,
            errors: {},
          });
        }}
        required
        value={pageState.auditOpinion}
        errors={pageState.errors}
      />

      <ChoiceGroup
        id="statutoryAuditFinding"
        label="Statutory Audit Finding"
        name="statutoryAuditFinding"
        options={yesNoOptions}
        disabled={pageState.auditOpinion?.fieldName !== 'Qualified'}
        required={pageState.auditOpinion?.fieldName === 'Qualified'}
        selectedKey={pageState.statutoryAuditFinding?.toString()}
        onChange={(e, newValue): void => {
          setPageState({
            ...pageState,
            statutoryAuditFinding: newValue.key === 'true',
            errors: {},
          });
        }}
      />
      <InlineFormInputErrorMessage
        errorMessage={pageState.errors?.statutoryAuditFinding?.message}
      />

      <ChoiceGroup
        id="remediationPlanInPlace"
        label="Remediation Plan in Place"
        name="remediationPlanInPlace"
        options={yesNoOptions}
        disabled={pageState.statutoryAuditFinding !== true}
        required={pageState.statutoryAuditFinding === true}
        selectedKey={pageState.remediationPlanInPlace?.toString()}
        onChange={(e, newValue): void => {
          setPageState({
            ...pageState,
            remediationPlanInPlace: newValue.key === 'true',
            errors: {},
          });
        }}
      />
      <InlineFormInputErrorMessage
        errorMessage={pageState.errors?.remediationPlanInPlace?.message}
      />

      <UtcDatePicker
        label="Expected Closure Date"
        allowTextInput
        disabled={pageState.remediationPlanInPlace !== true}
        isRequired={pageState.remediationPlanInPlace === true}
        textField={{
          name: 'expectedClosureDate',
          errorMessage: pageState.errors?.expectedClosureDate?.message,
        }}
        value={pageState.expectedClosureDate}
        onSelectDate={(date: string): void => {
          setPageState({
            ...pageState,
            expectedClosureDate: date,
          });
        }}
        minDate={new Date()}
      />

      <CanAccess requestedAction="AuditOpinion:Update">
        <ActionButtons
          closePanel={handleCancel}
          handleSubmit={handleSaveClick}
          saveLabel="Save Process Details"
          saveTitle="Save Process Details"
          cancelLabel="Cancel"
          cancelTitle="Cancel Process Details Edit"
          mutationLoading={loading}
        />
      </CanAccess>
    </Stack>
  );
};

export default ProcessDetailForm;
