import { gql } from '@apollo/client';

const GET_AUDIT_STATUSES_QUERY = gql`
  {
    auditStatuses {
      id
      fieldName
    }
  }
`;
export default GET_AUDIT_STATUSES_QUERY;
