import React, { FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { ChoiceGroup, IChoiceGroupOption, Stack, TextField } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import ActionButtons from '../../common/ActionButtons';
import UtcDatePicker from '../../common/utcDatePicker/UtcDatePicker';
import { UPDATE_XBRL_MUTATION } from '../../../utils/statApi/AnnualFilingRequirementsApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import IAnnualFilingRequirements from '../../../utils/types/IAnnualFilingRequirements';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { yesNoOptions } from '../../../utils/types/CommonDropdownOptions';

interface IXBRLForm {
  xbrlDetails: IAnnualFilingRequirements;
  closePanel: () => void;
}

const XBRLForm = ({ xbrlDetails, closePanel }: IXBRLForm): JSX.Element => {
  const [pageState, setPageState] = useState({ ...xbrlDetails });
  const { companyCode } = useParams<ICompanyCodeParams>();
  const [firstXBRLDate, setfirstXBRLDate] = useState(
    xbrlDetails?.dateOfFirstXbrlFilingRequirement?.toString(),
  );
  const [updateXBRLDetails, { loading, error }] = useMutation(UPDATE_XBRL_MUTATION, {
    onCompleted: () => {
      closePanel();
    },
  });

  const onTaggedSectionChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ): void => {
    setPageState({ ...pageState, sectionOfFilingRequiredToBeTagged: newValue });
  };

  const onCommentsChange = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    newValue?: string,
  ): void => {
    setPageState({ ...pageState, xbrlComments: newValue });
  };

  const onRequiredFilingChange = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    newValue?: string,
  ): void => {
    setPageState({ ...pageState, filingsRequiredToBeFiledInXbrl: newValue });
  };

  const onXBRLSTATFilingsChange = (
    ev?: FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption,
  ): void => {
    setPageState({ ...pageState, xbrlRequiredForFilings: option.key === 'true' });
  };

  const saveXBRL = (): void => {
    const payload = {
      id: pageState.id,
      xbrlRequiredForFilings: pageState.xbrlRequiredForFilings,
      filingsRequiredToBeFiledInXbrl: pageState.filingsRequiredToBeFiledInXbrl,
      dateOfFirstXbrlFilingRequirement: new Date(firstXBRLDate),
      sectionOfFilingRequiredToBeTagged: pageState.sectionOfFilingRequiredToBeTagged,
      xbrlComments: pageState.xbrlComments,
    };

    updateXBRLDetails({
      variables: {
        companyCode,
        xbrlData: payload,
      },
    });
  };

  return (
    <CoherencePanel
      closeButtonAriaLabel="Close XBRL Edit Panel"
      panelSize={CoherencePanelSize.medium}
      titleText="Edit XBRL Form"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          closePanel={closePanel}
          handleSubmit={saveXBRL}
          saveLabel="Save XBRL Form"
          saveTitle="Save XBRL Form"
          cancelLabel="Cancel XBRL Form Edit"
          cancelTitle="Cancel XBRL Form Edit"
          mutationLoading={loading}
        />
      )}
    >
      <form>
        <Stack>
          <LoadingErrorMessage error={error} loading={loading} />
          <ChoiceGroup
            id="xbrlRequiredForStatutoryFilings"
            label="XBRL Required for Statutory Filings"
            name="xbrlRequiredForStatutoryFilings"
            options={yesNoOptions}
            selectedKey={pageState.xbrlRequiredForFilings?.toString()}
            onChange={onXBRLSTATFilingsChange}
          />
          <br />
          <TextField
            ariaLabel="Section of Filing Required to be Tagged"
            label="Section of Filing Required to be Tagged"
            multiline
            resizable={false}
            value={pageState?.sectionOfFilingRequiredToBeTagged}
            onChange={onTaggedSectionChange}
          />
          <br />
          <TextField
            ariaLabel="Filings Required to be Filed in XBRL"
            label="Filings Required to be Filed in XBRL"
            multiline
            resizable={false}
            value={pageState?.filingsRequiredToBeFiledInXbrl}
            onChange={onRequiredFilingChange}
          />
          <br />
          <TextField
            ariaLabel="XBRL Comments"
            label="XBRL Comments"
            multiline
            resizable={false}
            value={pageState?.xbrlComments}
            onChange={onCommentsChange}
          />
          <br />
          <UtcDatePicker
            label="Date of First XBRL Filing Requirement"
            textField={{
              name: 'firstXBRLDate',
            }}
            value={firstXBRLDate}
            onSelectDate={setfirstXBRLDate}
          />
        </Stack>
      </form>
    </CoherencePanel>
  );
};

export default XBRLForm;
