import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  IContextualMenuItemProps,
  IPanel,
  Panel,
  Persona,
  PersonaSize,
  ResizeGroup,
  useTheme,
} from '@fluentui/react';
import { ListItemStatusType, useIsMobile } from '@coherence-design-system/controls';
import {
  Header,
  HeaderButton,
  INotificationBadgeProps,
  NotificationBadge,
  usePanels,
  useResizeGroup,
} from '@coherence-design-system/header';
import { Template } from '@coherence-design-system/slot';
import { ProfileCenter } from '@coherence-design-system/profile-center';
import {
  INotificationCategory,
  INotificationItem,
  NotificationCenter,
  NotificationStatus,
  NotificationStatusType,
} from '@coherence-design-system/notification-center';
import HelpPanel from './headers/HelpPanel';
import { mobileStickyHeader } from '../../app/common/styles/CommonStyleObjects';
import NotificationContext from '../notifications/NotificationContext';
import { IGraphUserObject } from '../../utils/types/Users';
import ToastContainer from './ToastContainer';
import StatHeaderSearch from './StatHeaderSearch';

interface IStatHeaderProps {
  onLogOut: () => void;
  graphProfileData: IGraphUserObject;
  imageUrl: string;
  environmentName?: string;
}

const StatHeader = ({
  onLogOut,
  graphProfileData,
  imageUrl,
  environmentName,
}: IStatHeaderProps): JSX.Element => {
  const isMobile = useIsMobile();
  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);

  const profileCenterRef = useRef<IPanel>(null);
  const helpPanelRef = React.useRef<IPanel>(null);
  const notificationCenterRef = React.useRef<IPanel>(null);
  const { currentPanel, openPanel, closePanel } = usePanels();

  const prodTheme = useTheme();
  const nonProdTheme = { ...prodTheme, palette: { ...prodTheme.palette, themePrimary: '#d40004' } };
  const name = graphProfileData?.displayName || '';
  const email = graphProfileData?.mail?.toLowerCase() || '';
  const { notifications, setNotifications } = useContext(NotificationContext);

  const notificationCategories: INotificationCategory[] = [
    {
      itemKey: 'Notifications',
      title: 'Notifications',
      items: notifications,
    },
  ];

  const updateItem = (
    itemKey: string,
    displayStatus: NotificationStatusType,
    readStatus?: ListItemStatusType,
  ): void => {
    const list = [...notifications];
    list.forEach((item: INotificationItem) => {
      if (item.itemKey === itemKey) {
        item.notificationStatus = displayStatus;
        if (readStatus) {
          item.status = readStatus;
        }
      }
    });
    setNotifications(list);
  };

  const numNewNotifications = notifications.filter(
    (no) => no.notificationStatus === NotificationStatus.UNREAD,
  )?.length;

  const items = [
    {
      key: 'notification',
      icon: 'Ringer',
      ariaLabel: 'Notification',
      text: 'Notification',
      onClick: () => {
        openPanel(notificationCenterRef);
      },
      checked: currentPanel?.current === notificationCenterRef?.current,
      iconProps: { iconName: 'Ringer' },
      onRenderIcon: (
        props: INotificationBadgeProps,
        defaultRender:
          | ((props?: IContextualMenuItemProps | undefined) => JSX.Element | null)
          | undefined,
      ) => (
        <NotificationBadge
          {...props}
          numNewNotifications={numNewNotifications}
          defaultRender={defaultRender}
        />
      ),
    },
    {
      key: 'help',
      icon: 'Help',
      iconProps: { iconName: 'Help' },
      ariaLabel: 'Help',
      text: 'Help',
      checked: currentPanel?.current === helpPanelRef?.current,
      onClick: () => {
        openPanel(helpPanelRef);
      },
    },
  ];

  useEffect(() => {
    if (!isMobile) {
      setIsSearchOverlayOpen(false);
    }
  }, [isMobile]);

  type ItemType = (typeof items)[number];

  const formattedData = {
    primary: items,
    overflow: [] as ItemType[],
  };
  const { onReduceData, onGrowData, onRenderData } = useResizeGroup({ closePanel, currentPanel });

  return (
    <div role="banner" className={mobileStickyHeader}>
      <Header
        title={{
          href: '/',
          children:
            environmentName === '' ? 'Microsoft Stat' : `Microsoft Stat - ${environmentName}`,
        }}
        showHamburger={false}
        hidePanelContainer={isSearchOverlayOpen}
        isSearchIconOnly={isMobile && !isSearchOverlayOpen}
        theme={environmentName === '' ? prodTheme : nonProdTheme}
        styles={{
          panelContainer: {
            marginLeft: '10px',
          },
        }}
      >
        <Template slot="search">
          <StatHeaderSearch
            isSearchOverlayOpen={isSearchOverlayOpen}
            setIsSearchOverlayOpen={setIsSearchOverlayOpen}
          />
          {isMobile && !isSearchOverlayOpen && (
            <HeaderButton
              onClick={() => setIsSearchOverlayOpen(true)}
              iconProps={{ iconName: 'search' }}
              aria-describedby="coherence-search"
            />
          )}
        </Template>
        <Template slot="collapsible-triggers">
          <ResizeGroup
            data={formattedData}
            onReduceData={onReduceData}
            onGrowData={onGrowData}
            onRenderData={onRenderData}
          />
        </Template>
        <Template slot="static-triggers">
          <HeaderButton
            onClick={() => openPanel(profileCenterRef)}
            checked={currentPanel?.current === profileCenterRef?.current}
            aria-describedby="profile"
          >
            <Persona size={PersonaSize.size32} imageUrl={imageUrl} />
          </HeaderButton>
        </Template>
      </Header>
      <ToastContainer />
      <NotificationCenter
        isOpen={Boolean(currentPanel === notificationCenterRef)}
        componentRef={notificationCenterRef}
        onDismiss={() => closePanel()}
        closeButtonAriaLabel="Close"
        categories={notificationCategories}
        onUpdateNotificationItem={updateItem}
      />
      <ProfileCenter
        componentRef={profileCenterRef}
        onDismiss={() => closePanel()}
        persona={{
          imageUrl,
          text: name,
          secondaryText: email,
        }}
        actions={[
          {
            children: 'Sign out',
            onClick: () => {
              onLogOut();
            },
          },
        ]}
      />
      <Panel
        componentRef={helpPanelRef}
        onDismiss={closePanel}
        isOpen={Boolean(currentPanel === helpPanelRef)}
        isLightDismiss
        headerText="Help"
        // We need to explicitly define this function so that isLightDismiss will bubble
        // the outer click event to other header buttons
        // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
        onOuterClick={(_e) => {}}
      >
        <HelpPanel />
      </Panel>
    </div>
  );
};

export default StatHeader;
