import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProcessExtensionReview from './ProcessExtensionReview';
import ProcessExtensionsListPage from './ProcessExtensionsListPage';

const ProcessExtensionRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/process-extensions" exact component={ProcessExtensionsListPage} />
      <Route
        path="/process-extensions/:processExtensionId/review"
        component={ProcessExtensionReview}
      />
    </Switch>
  );
};

export default ProcessExtensionRoute;
