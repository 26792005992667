import React from 'react';
import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { useParams } from 'react-router-dom';
import useValidationFromServer from '../../../utils/validation/useValidationFromServer';
import VALIDATION_DATA_QUERY from '../../../utils/statApi/ValidationsApi';
import { EDIT_AUDIT_INFO_MUTATION } from '../../../utils/statApi/CompanyFiscalYearsApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import IAuditFiscalYears from '../../../utils/types/IAuditFiscalYear';
import AuditStatusForm from '../detail/forms/AuditStatusForm';
import CanAccess from '../../common/canAccess/CanAccess';
import ActionButtons from '../../common/ActionButtons';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

export interface IFiscalYearAuditStatusEditPanelProps {
  auditStatus: IAuditFiscalYears;
  closePanel: () => void;
}
const FiscalYearAuditStatusEditPanel: React.FunctionComponent<
  IFiscalYearAuditStatusEditPanelProps
> = ({ closePanel, auditStatus }: IFiscalYearAuditStatusEditPanelProps): JSX.Element => {
  const { errors, handleSubmit, handleChange } = useValidationFromServer(
    auditStatus,
    VALIDATION_DATA_QUERY,
    'auditFiscalYear',
  );
  const { companyCode } = useParams<ICompanyCodeParams>();

  const [updateAuditStatus, { loading: mutationLoading, error: mutationError }] = useMutation(
    EDIT_AUDIT_INFO_MUTATION,
    {
      onCompleted: closePanel,
    },
  );

  const handleOnSaveClick = (formData: IAuditFiscalYears): void => {
    // GraphQL types don't like the typename property
    const auditStatusSaveData = {
      id: formData.id,
      auditingFirm: {
        id: formData.auditingFirm.id,
        fieldName: formData.auditingFirm.fieldName,
      },
      auditStatus: {
        id: formData.auditStatus.id,
        fieldName: formData.auditStatus.fieldName,
      },
      otherAuditingFirm: formData.otherAuditingFirm,
      statusDate: formData.statusDate,
      auditLegallyRequired: formData.auditLegallyRequired,
    };

    updateAuditStatus({
      variables: {
        auditInfo: {
          ...auditStatusSaveData,
        },
        companyCode,
      },
    });
  };

  return (
    <CoherencePanel
      closeButtonAriaLabel="Close Audit Status Edit Panel"
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Audit Status"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="AuditStatus:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveLabel="Save Audit Status"
            saveTitle="Save Audit Status"
            cancelLabel="Cancel"
            cancelTitle="Cancel Audit Status Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the audit information to the server"
        />
        <AuditStatusForm
          auditStatus={auditStatus}
          handleClose={closePanel}
          handleChange={handleChange}
          errors={errors}
        />
      </form>
    </CoherencePanel>
  );
};
export default FiscalYearAuditStatusEditPanel;
