import { gql } from '@apollo/client';
import { COMPANY_DOCUMENT_FRAGMENT } from './CompanyCreationApi';

export const GetCompaniesBySearchInputQuery = gql`
  query Companies($keyword: String) {
    companiesByKeyword(keyword: $keyword) {
      id
      companyCode
      sapName
      entityLegalName
      physicalCountry
      entityStatus
      statutoryYearEndMonth
      statutoryYearEndDay
      statutoryController
    }
  }
`;

export const GetCompaniesBySearchInputWithPaginationQuery = gql`
  query Companies($first: Int, $after: String, $last: Int, $before: String, $keyword: String) {
    companies(first: $first, after: $after, last: $last, before: $before, keyword: $keyword) {
      edges {
        node {
          id
          companyCode
          sapName
          entityLegalName
          physicalCountry
          entityStatus
          statutoryYearEndMonth
          statutoryYearEndDay
          statutoryController
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GetCompaniesByFilter = gql`
  query Companies($companySearchDto: CompanySearchDtoInput, $paginationDto: PaginationDtoInput) {
    companies(companySearchDto: $companySearchDto, paginationDto: $paginationDto) {
      items {
        id
        companyCode
        sapName
        entityLegalName
        physicalCountry
        area
        entityStatus
        statutoryYearEndDay
        statutoryYearEndMonth
        statutoryController
      }
      totalCount
      currentPage
      pageSize
    }
  }
`;
export const GET_SEC_REGION_AREAS_BY_SEARCH_INPUT = gql`
  query SecRegionAreas($keyword: String) {
    secRegionAreas(secRegionAreaSearchInput: $keyword) {
      secAreaCode
      secAreaName
    }
  }
`;
export const GET_SEC_REGION_AREAS_BY_CODE = gql`
  query secRegionAreasByCode($secRegionAreaCodes: [String!]) {
    secRegionAreasByCode(secRegionAreaCodes: $secRegionAreaCodes) {
      secAreaCode
      secAreaName
    }
  }
`;
export const COMPANY_FRAGMENT = gql`
  fragment Company on CompanyDto {
    id
    companyCode
    sapName
    entityLegalName
    streetNumber
    streetNumber2
    poBox
    city
    state
    stateProvinceCode
    zipCode
    sapLegalName
    sapLegalName2
    sapLegalName3
    sapRegion
    sapRegionCode
    sapNameShortForm
    incorporatedCountry
    incorporatedCountryCode
    physicalCountry
    physicalCountryCode
    incorporatedSapRegion
    wwArea
    incorporatedDate
    region
    controllerMustBeFte
    businessNatureOfEntity
    statutoryYearEndMonth
    statutoryYearEndDay
    financialType
    statutoryCurrency
    salesLocationForReporting {
      id
      name
    }
    taxId
    shadowCompany
    sapLevel {
      id
      fieldName
    }
    entityType {
      id
      fieldName
    }
    acquisition {
      id
      fieldName
    }
    dateOfAcquisition
    entityStatus {
      id
      fieldName
    }
    lifecycleStatus {
      id
      fieldName
      entityStatusId
    }
    taxJurisdictionCode
    functionalCurrency
    effectiveDateOfStatusChange
    entityStatusModifiedDate
    legalEntityTerminationNotes
    terminationLifecycleStatus {
      id
      fieldName
    }
    legoCategory {
      id
      fieldName
    }
    anticipatedTerminationFY
    anticipatedTerminationQuarter
    terminationContacts {
      id
      graphId
      text: name
      email
      firstName
      lastName
      alias
    }
    intakeMethod {
      id
      fieldName
    }
    accountingPlanStarted
    celaTermContact {
      id
      graphId
      text: name
      email
      firstName
      lastName
      alias
    }
  }
`;

export const GetCompanyByCompanyCode = gql`
  query Company($companyCode: String) {
    company(companyCode: $companyCode) {
      ...Company
    }
  }
  ${COMPANY_FRAGMENT}
`;
export const GetCompaniesByCode = gql`
  query CompaniesByCode($companyCodes: [String!]) {
    companiesByCode(companyCodes: $companyCodes) {
      id
      companyCode
      entityLegalName
    }
  }
`;
export const EDIT_COMPANY_MUTATION = gql`
  mutation updateCompany($company: CompanyDtoInput!) {
    updateCompany(company: $company) {
      id
      sapName
      entityLegalName
      streetNumber
      streetNumber2
      poBox
      city
      zipCode
      sapLegalName
      sapLegalName2
      sapLegalName3
      sapRegion
      sapNameShortForm
      incorporatedCountry
      physicalCountry
      incorporatedSapRegion
      wwArea
      incorporatedDate
      region
      controllerMustBeFte
      businessNatureOfEntity
      entityType {
        id
        fieldName
      }
      acquisition {
        id
        fieldName
      }
      entityStatus {
        id
        fieldName
      }
    }
  }
`;

export const GET_COMPANY_MILESTONE_DOCUMENTS = gql`
  query GetCompanyMilestoneDocuments(
    $paginationDto: PaginationDtoInput
    $companyCode: String!
    $fiscalYears: [Int!]
    $processIds: [Int!]
    $processTypeIds: [Int!]
    $milestoneNameIds: [Int!]
    $showDeletedFiles: Boolean
  ) {
    companyMilestoneDocuments(
      paginationDto: $paginationDto
      companyCode: $companyCode
      fiscalYears: $fiscalYears
      processIds: $processIds
      processTypeIds: $processTypeIds
      milestoneNameIds: $milestoneNameIds
      showDeletedFiles: $showDeletedFiles
    ) {
      items {
        id
        title
        url
        uploadDate
        uploader {
          id
          displayName
          firstName
          lastName
        }
        isDeleted
        milestoneName
        processName
        processType
      }
      totalCount
      currentPage
      pageSize
    }
  }
`;

export const GET_COMPANY_PROCESS_EXTENSTION_DOCUMENTS = gql`
  query GetCompanyProcessExtensionDocuments($companyCode: String!, $showDeletedFiles: Boolean) {
    companyProcessExtensionDocuments(
      companyCode: $companyCode
      showDeletedFiles: $showDeletedFiles
    ) {
      id
      title
      url
      uploadDate
      uploader {
        id
        displayName
        firstName
        lastName
      }
      isDeleted
    }
  }
`;

export const GET_COMPANY_OTHER_DOCUMENTS = gql`
  query companyOtherDocuments($parentId: Int!, $showDeletedFiles: Boolean!) {
    otherCompanyDocuments(companyCode: $parentId, showDeletedFiles: $showDeletedFiles) {
      id
      functionalCurrencyDocument {
        ...CompanyDocument
      }
      incorporationDocuments {
        ...CompanyDocument
      }
      otherDocuments {
        ...CompanyDocument
      }
    }
  }
  ${COMPANY_DOCUMENT_FRAGMENT}
`;

export const UPDATE_COMPANY_DOCUMENTS = gql`
  mutation updateCompanyDocuments($input: DocumentsInputDtoInput!) {
    updateCompanyDocuments(input: $input) {
      id
      functionalCurrencyDocument {
        ...CompanyDocument
      }
      incorporationDocuments {
        ...CompanyDocument
      }
      otherDocuments {
        ...CompanyDocument
      }
    }
  }
  ${COMPANY_DOCUMENT_FRAGMENT}
`;

export const GET_COMPANY_DOCUMENT_FILTERS = gql`
  query GetCompanyDocumentFilters(
    $companyCode: String!
    $fiscalYears: [Int!]
    $processIds: [Int!]
    $processTypeIds: [Int!]
  ) {
    companyDocumentFilters(
      companyCode: $companyCode
      fiscalYears: $fiscalYears
      processIds: $processIds
      processTypeIds: $processTypeIds
    ) {
      companyDocumentFiscalYearFilters {
        key: id
        id
        text: fieldName
      }
      companyDocumentMilestoneNameFilters {
        key: id
        id
        text: fieldName
      }
      companyDocumentProcessFilters {
        key: id
        id
        text: fieldName
      }
      companyDocumentProcessTypeFilters {
        key: id
        id
        text: fieldName
      }
    }
  }
`;

export const GetUsersForStatControllerFilterQuery = gql`
  query StatUsersForStatControllerFilter(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
  ) {
    statUsersForStatControllerFilter(
      first: $first
      after: $after
      last: $last
      before: $before
      userSearchInput: $keyword
    ) {
      edges {
        node {
          id
          graphGUID
          displayName
          emailAddress
          firstName
          lastName
          roleName
          roleId
          groupIdentifiers
          alias
          isActive
          lastLoginDate
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GetStatUsersByIdsForStatControllerFilterQuery = gql`
  query StatUsersByIdForStatControllerFilter($statUserIds: [Int!]) {
    statUsersByIdForStatControllerFilter(statUserIds: $statUserIds) {
      id
      graphGUID
      displayName
      emailAddress
      firstName
      lastName
      roleName
      roleId
      alias
      isActive
      lastLoginDate
    }
  }
`;
export const UPDATE_COMPANY_BUSINESS_NATURE = gql`
  mutation updateCompanyBusinessNature(
    $input: CompanyBusinessNatureDtoInput!
    $companyCode: String!
  ) {
    updateCompanyBusinessNature(input: $input, companyCode: $companyCode) {
      id
      controllerMustBeFte
      businessNatureOfEntity
      entityType {
        id
        fieldName
      }
      acquisition {
        id
        fieldName
      }
      dateOfAcquisition
      entityStatus {
        id
        fieldName
      }
      lifecycleStatus {
        id
        fieldName
        entityStatusId
      }
      effectiveDateOfStatusChange
      entityStatusModifiedDate
    }
  }
`;
export const UPDATE_COMPANY_LEGAL_ADDRESS = gql`
  mutation updateCompanyLegalAddress($input: CompanyLegalAddressDtoInput!, $companyCode: String!) {
    updateCompanyLegalAddress(input: $input, companyCode: $companyCode) {
      id
      streetNumber
      streetNumber2
      city
      zipCode
      incorporatedCountryCode
      incorporatedCountry
      stateProvinceCode
      state
      region
      wwArea
    }
  }
`;

export const UPDATE_INCORPORATION_HIERARCHY_INFO = gql`
  mutation updateCompanyIncorporationHierarchyInformation(
    $input: CompanyIncorporationHierarchyDtoInput!
    $companyCode: String!
  ) {
    updateCompanyIncorporationHierarchyInformation(input: $input, companyCode: $companyCode) {
      id
      incorporatedDate
      wwArea
      region
    }
  }
`;

export const UPDATE_COMPANY_NAME_SAP_INFO = gql`
  mutation updateCompanyNameSapInfo($input: CompanyNameSapInfoDtoInput!, $companyCode: String!) {
    updateCompanyNameSapInfo(input: $input, companyCode: $companyCode) {
      id
      entityLegalName
      sapLegalName
      sapNameShortForm
      sapRegion
      sapRegionCode
      physicalCountry
      physicalCountryCode
    }
  }
`;

export const GetControllersAreFTECheck = gql`
  query ControllersAreFTECheck($companyCode: String!) {
    controllersAreFTECheck(companyCode: $companyCode) {
      areControllersFTE
    }
  }
`;

export const UPDATE_COMPANY_SAP_FINANCIAL_INFO = gql`
  mutation updateCompanySapFinancialInfo(
    $input: CompanySapFinancialInfoDtoInput!
    $companyCode: String!
  ) {
    updateCompanySapFinancialInfo(input: $input, companyCode: $companyCode) {
      id
      sapLevel {
        id
        fieldName
      }
    }
  }
`;

export const UPDATE_COMPANY_FINANCIAL_INFO = gql`
  mutation updateCompanyFinancialInfo(
    $input: CompanyFinancialInfoDtoInput!
    $companyCode: String!
  ) {
    updateCompanyFinancialInfo(input: $input, companyCode: $companyCode) {
      id
      statutoryYearEndDay
      statutoryYearEndMonth
      financialType
      statutoryCurrency
      functionalCurrency
      salesLocationForReporting {
        id
        name
      }
      taxJurisdictionCode
    }
  }
`;

export const UPDATE_LEGAL_ENTITY_TERMINATION_INFO = gql`
  mutation updateCompanyLegalEntityTerminationInfo(
    $input: CompanyLegalEntityTerminationInfoDtoInput!
    $companyCode: String!
  ) {
    updateCompanyLegalEntityTerminationInfo(input: $input, companyCode: $companyCode) {
      id
      legalEntityTerminationNotes
      terminationLifecycleStatus {
        id
        fieldName
      }
      legoCategory {
        id
        fieldName
      }
      anticipatedTerminationFY
      anticipatedTerminationQuarter
      terminationContacts {
        id
        graphId
        text: name
        email
        firstName
        lastName
        alias
      }
      intakeMethod {
        id
        fieldName
      }
      accountingPlanStarted
      celaTermContact {
        id
        graphId
        text: name
        email
        firstName
        lastName
        alias
      }
    }
  }
`;
