import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface ISortedState {
  sortedFieldName: string;
  sortOrder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortObject: any;
}

export function getSortObject(sortedFieldName: string, sortOrder: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortObject: { [key: string]: any } = {};
  const fields = sortedFieldName.split('.');
  let current = sortObject;

  fields.forEach((field, index) => {
    if (index === fields.length - 1) {
      current[field] = sortOrder;
    } else {
      current[field] = {};
      current = current[field];
    }
  });

  return [sortObject];
}

const useUrlSorting = (defaultSortColumn = 'id') => {
  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const initialState: ISortedState = {
    sortedFieldName: urlParams.get('sortedFieldName') || defaultSortColumn,
    sortOrder: urlParams.get('sortOrder') || 'ASC',
    sortObject: {},
  };

  initialState.sortObject = getSortObject(initialState.sortedFieldName, initialState.sortOrder);

  const [sortedState, setSortedState] = useState(initialState);

  useEffect(() => {
    urlParams.set('sortedFieldName', sortedState.sortedFieldName);
    urlParams.set('sortOrder', sortedState.sortOrder);

    history.replace(location.pathname.concat(`?${urlParams.toString()}`));
  }, [sortedState]);

  return [sortedState, setSortedState] as const;
};

export default useUrlSorting;
