import React, { useState } from 'react';
import {
  IDropdownOption,
  IPersonaProps,
  NeutralColors,
  Separator,
  Stack,
  TextField,
} from '@fluentui/react';
import { Controller, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import {
  IAgmTrackerItemInput,
  IAgmTrackerPageTrackerSearchResult,
} from '../../annualFilingRequirements/IAgmListPageSearchResult';
import ErrorHandlingPeoplePicker from '../../../common/formFields/ErrorHandlingPeoplePicker';
import searchForUsers from '../../../../utils/microsoftGraphApi/userSearch';
import { IAgmReviewContact } from '../../../../utils/types/IAgmReview';
import AgmTrackerStatusDropdown from './AgmTrackerStatusDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import AgmTrackerOwnerDropdown from './AgmTrackerOwnerDropdown';
import CanAccess from '../../../common/canAccess/CanAccess';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';

interface IAgmTrackerItemEditFormProps {
  agmTrackerItem: IAgmTrackerPageTrackerSearchResult;
  bulkAgmTrackerItems?: IAgmTrackerPageTrackerSearchResult[];
  form: UseFormMethods<IAgmTrackerItemInput>;
  currentOwnerFilter: string;
}

const AGM_COMPLETED_STATUSES = ['Completed (Pending)', 'Completed'];
const RETURNED_TO_STC_STATUS = 'Returned to STC';

const AgmTrackerItemEditForm = ({
  agmTrackerItem,
  bulkAgmTrackerItems,
  form,
  currentOwnerFilter,
}: IAgmTrackerItemEditFormProps): JSX.Element => {
  const { control, setValue, errors, formState } = form;
  const { dirtyFields } = formState;
  const [isOwnerDropdownDisabled, setIsOwnerDropdownDisabled] = useState<boolean>(false);

  const isBulkEdit = bulkAgmTrackerItems?.length > 1;

  const onOwnerChange = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    if (!item?.key) {
      setValue('owner', null);
    } else {
      setValue('owner', item);
    }
  };

  const onStatusDateChange = (utcDateString: string, currentValue: string) => {
    setValue('agmDate', utcDateString, {
      shouldValidate: true,
      shouldDirty: currentValue !== utcDateString ?? true,
    });
  };

  const watchAgmTrackerStatus = useWatch({
    control,
    name: 'status',
    defaultValue: !isBulkEdit ? agmTrackerItem?.status : undefined,
  });

  const watchOwner = useWatch({
    control,
    name: 'owner',
    defaultValue: !isBulkEdit
      ? { key: agmTrackerItem?.owner, text: agmTrackerItem?.owner }
      : undefined,
  });

  const returnedToStcHandler = (newValue: IDropdownReference): void => {
    if (
      newValue.fieldName === RETURNED_TO_STC_STATUS &&
      currentOwnerFilter === 'CELA' &&
      agmTrackerItem?.status?.fieldName !== RETURNED_TO_STC_STATUS
    ) {
      setIsOwnerDropdownDisabled(true);
    } else if (
      newValue.fieldName !== RETURNED_TO_STC_STATUS &&
      watchAgmTrackerStatus?.fieldName === RETURNED_TO_STC_STATUS
    ) {
      setIsOwnerDropdownDisabled(false);
    }
  };

  const onStatusChange = (newValue: IDropdownReference, valueKey: string) => {
    if (!newValue?.id) {
      setValue('status', '');
    } else {
      returnedToStcHandler(newValue);
      setValue('status', newValue, { shouldDirty: true });
    }
  };

  const onResolveAssigneeSuggestions = (
    filterText: string,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  const validateReasonForChange = (newVal: string): string => {
    if (
      (watchAgmTrackerStatus?.fieldName !== agmTrackerItem?.status?.fieldName ||
        watchOwner?.text !== agmTrackerItem?.owner) &&
      (!newVal || newVal.length === 0)
    ) {
      return 'Reason For Change is required when the Status or Owner is changed.';
    }
    return null;
  };

  const validateAgmDate = (newVal: string): string => {
    if (
      watchAgmTrackerStatus?.fieldName !== agmTrackerItem?.status?.fieldName &&
      (!newVal || newVal.length === 0)
    ) {
      return 'AGM Date is required when this status is chosen.';
    }
    return null;
  };

  const reasonForChangeIsNeeded = (): boolean => {
    return (
      (watchAgmTrackerStatus?.fieldName !== undefined &&
        watchAgmTrackerStatus?.fieldName !== agmTrackerItem?.status?.fieldName) ||
      (watchOwner?.text !== undefined && watchOwner?.text !== agmTrackerItem?.owner)
    );
  };

  const nonCompleteStatusNeededOnOwnerChange = (): string => {
    if (
      (isBulkEdit &&
        watchOwner !== null &&
        watchOwner !== undefined &&
        watchAgmTrackerStatus?.fieldName === 'Completed') ||
      (!isBulkEdit &&
        watchOwner?.text !== agmTrackerItem?.owner &&
        watchAgmTrackerStatus?.fieldName === 'Completed')
    ) {
      return 'When changing the owner, the status must not be "Completed".';
    }
    return null;
  };

  const isReturnToStcStatusDirty =
    watchAgmTrackerStatus?.fieldName === RETURNED_TO_STC_STATUS &&
    agmTrackerItem?.status?.fieldName !== RETURNED_TO_STC_STATUS &&
    dirtyFields?.status !== undefined;

  const needsAgmDateDate =
    watchAgmTrackerStatus?.fieldName !== agmTrackerItem?.status?.fieldName &&
    AGM_COMPLETED_STATUSES.includes(watchAgmTrackerStatus?.fieldName);

  const separatorStyles = {
    root: {
      selectors: {
        '&:before': {
          background: NeutralColors.gray70,
        },
      },
    },
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <CanAccess requestedAction="AgmTracker:OwnerEdit">
        <>
          <Controller
            control={control}
            name="owner"
            defaultValue={!isBulkEdit ? agmTrackerItem?.owner : null}
            errorMessage={errors?.owner?.text?.message}
            render={({ value }) => (
              <AgmTrackerOwnerDropdown
                label="Owner"
                selectedOption={value?.key}
                onOwnerUpdate={onOwnerChange}
                defaultSelectedKey={isReturnToStcStatusDirty ? 'CONTROLLER' : agmTrackerItem?.owner}
                disabled={isOwnerDropdownDisabled}
                isBulkEdit={isBulkEdit}
              />
            )}
          />
        </>
      </CanAccess>

      <CanAccess requestedAction="AgmTracker:StatusEdit">
        <>
          <Controller
            control={control}
            name="status"
            value={!isBulkEdit ? agmTrackerItem?.status : null}
            defaultValue={!isBulkEdit ? agmTrackerItem?.status : null}
            rules={{ validate: nonCompleteStatusNeededOnOwnerChange }}
            render={({ value }) => (
              <AgmTrackerStatusDropdown
                label="AGM Tracker Status"
                handleChange={onStatusChange}
                valueKey="status"
                errors={errors?.status?.message as unknown as string}
                value={value}
                isBulkEdit={isBulkEdit}
              />
            )}
          />
          {needsAgmDateDate && (
            <Controller
              control={control}
              name="agmDate"
              value={!isBulkEdit ? agmTrackerItem?.agmDate : ''}
              defaultValue={!isBulkEdit ? agmTrackerItem?.agmDate : ''}
              rules={{ validate: validateAgmDate }}
              render={({ value }) => (
                <>
                  <UtcDatePicker
                    id="agmDate"
                    label="AGM Date"
                    isRequired={needsAgmDateDate}
                    onSelectDate={(utcDateString: string): void => {
                      onStatusDateChange(utcDateString, value);
                    }}
                    isRequiredErrorMessage={errors?.agmDate?.message}
                    allowTextInput
                    textField={{
                      name: 'agmDate',
                      errorMessage: errors?.agmDate?.message,
                    }}
                  />
                </>
              )}
            />
          )}
        </>
      </CanAccess>

      {/* This access can be restricted to AgmTracker:OwnerEdit, instead of 
        AgmTracker:StatusChange, since it is less limiting than StatusChange 
        and is used for both Owner and Status changes */}
      <CanAccess requestedAction="AgmTracker:OwnerEdit">
        <Controller
          as={TextField}
          id="reasonForChange"
          name="reasonForChange"
          label="Reason For Change"
          control={control}
          defaultValue=""
          multiline
          required={reasonForChangeIsNeeded()}
          disabled={!reasonForChangeIsNeeded()}
          rules={{ validate: validateReasonForChange }}
          errorMessage={errors?.reasonForChange?.message}
        />
      </CanAccess>

      <CanAccess requestedAction="AgmTracker:CelaAssigneeEdit">
        <Controller
          id="celaAssignees"
          name="celaAssignees"
          control={control}
          defaultValue={!isBulkEdit ? agmTrackerItem?.celaAssignees : null}
          render={() => (
            <ErrorHandlingPeoplePicker
              onChange={(items: IAgmReviewContact[]) => {
                const newContacts = [] as IAgmReviewContact[];
                items.forEach((contact) => {
                  newContacts.push(contact);
                });
                setValue('celaAssignees', newContacts, { shouldDirty: true });
              }}
              removeButtonAriaLabel="Remove CELA Assignees"
              onResolveSuggestions={onResolveAssigneeSuggestions}
              controlName="agmReviewContacts"
              labelText="CELA Assignee(s)"
              defaultSelectedItems={!isBulkEdit ? agmTrackerItem?.celaAssignees : null}
              errorMessage={(agmTrackerItem?.celaAssignees as unknown as FieldError)?.message}
            />
          )}
        />
      </CanAccess>
      <Separator styles={separatorStyles} />
    </Stack>
  );
};
export default AgmTrackerItemEditForm;
