import { IColumn, ColumnActionsMode } from '@fluentui/react';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';

interface IColumnsProps {
  onRenderProcessName: (item: { id: number; processName: string }) => JSX.Element;
  onRenderRequestedDate: (item: { requestedDate: Date }) => JSX.Element;
  onRenderOldFilingDueDate: (item: { oldFilingDueDate: Date }) => JSX.Element;
  onRenderReportingPeriodEndDate: (item: { reportingPeriodEndDate: Date }) => JSX.Element;
}

const getColumns = ({
  onRenderProcessName,
  onRenderRequestedDate,
  onRenderOldFilingDueDate,
  onRenderReportingPeriodEndDate,
}: IColumnsProps): Array<IColumn> => {
  return [
    {
      key: 'companyCode',
      name: 'Company',
      fieldName: 'companyCode',
      minWidth: 120,
      maxWidth: 300,
      isRowHeader: false,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processType',
      name: 'Process Type',
      fieldName: 'processType',
      minWidth: 225,
      maxWidth: 400,
      isRowHeader: true,
      className: rowClasses.medFont,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processName',
      name: 'Process Name',
      fieldName: 'processName',
      minWidth: 250,
      maxWidth: 400,
      isRowHeader: true,
      className: rowClasses.medFont,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderProcessName,
    },
    {
      key: 'status',
      name: 'Request Status',
      fieldName: 'reviewStatus',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: false,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'reportingPeriodEndDate',
      name: 'Reporting Period End Date',
      fieldName: 'reportingPeriodEndDate',
      minWidth: 150,
      maxWidth: 250,
      isRowHeader: false,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderReportingPeriodEndDate,
    },
    {
      key: 'oldFilingDueDate',
      name: 'Filing Due Date',
      fieldName: 'oldFilingDueDate',
      minWidth: 150,
      maxWidth: 250,
      isRowHeader: false,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderOldFilingDueDate,
    },
    {
      key: 'requestedDate',
      name: 'Requested Filing Due Date',
      fieldName: 'requestedDate',
      minWidth: 150,
      maxWidth: 250,
      isRowHeader: false,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderRequestedDate,
    },
  ];
};

export default getColumns;
