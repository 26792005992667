import { useLazyQuery, useQuery } from '@apollo/client';
import { IGroup } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_STATE_PROVINCE_REGION_FOR_COUNTRY,
} from '../../../utils/statApi/CompanyCreationApi';
import { IAddress } from '../../../utils/types/IAddress';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import RequiredFieldsPanel from './RequiredFieldsPanel';
import { IRequiredItem, ISAPProgressInfo } from './types';

interface ISapProgressPanelProps {
  closePanel: () => void;
}

const SapProgressPanel = (props: ISapProgressPanelProps): JSX.Element => {
  const { closePanel } = props;
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const [sapProgressData, setSapProgressData] = useState<ISAPProgressInfo>();

  const { data, loading, error } = useQuery(GET_COMPANY_CREATION_COMPANY_INFO, {
    variables: {
      companyCreationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getLegalRegionForCountry, { data: legalRegionData }] = useLazyQuery(
    GET_STATE_PROVINCE_REGION_FOR_COUNTRY,
  );

  const [getPhysicalRegionForCountry, { data: physicalRegionData }] = useLazyQuery(
    GET_STATE_PROVINCE_REGION_FOR_COUNTRY,
  );

  useEffect(() => {
    const progressData: ISAPProgressInfo = {
      ...data?.companyCreationCompanyInfo?.functionalCurrency,
      ...data?.companyCreationCompanyInfo?.legalDocumentInfo,
      ...data?.companyCreationCompanyInfo?.sapSetupInfo,
      ...data?.companyCreationCompanyInfo?.inHouseCashCenterSetup,
      ...data?.companyCreationCompanyInfo?.shadowCompanyInfo,
    };
    setSapProgressData(progressData);

    const physicalAddressCountryCode = progressData?.physicalAddress?.country?.id;
    const legalAddressCountryCode = progressData?.legallyRegisteredAddress?.country?.id;

    if (legalAddressCountryCode) {
      getLegalRegionForCountry({ variables: { countryCode: legalAddressCountryCode } });
    }

    if (physicalAddressCountryCode) {
      getPhysicalRegionForCountry({ variables: { countryCode: physicalAddressCountryCode } });
    }
  }, [data]);

  const isAddressComplete = (addressKey: string): boolean => {
    const addr = sapProgressData[addressKey as keyof ISAPProgressInfo] as IAddress;

    let isRegionRequired = false;

    if (addressKey === 'physicalAddress') {
      isRegionRequired = physicalRegionData?.regionsByCountry?.length > 0;
    }

    if (addressKey === 'legallyRegisteredAddress') {
      isRegionRequired = legalRegionData?.regionsByCountry?.length > 0;
    }

    const isComplete: boolean =
      addr &&
      addr.city &&
      addr.city !== '' &&
      addr.country &&
      addr.postalZipCode &&
      addr.postalZipCode !== '' &&
      (isRegionRequired ? addr.stateProvinceRegion : true) &&
      addr.streetNumber &&
      addr.streetNumber !== '';

    return isComplete;
  };

  const handleCustomValidation = (key: string): boolean => {
    switch (key) {
      case 'inHouseCashCenterTier':
        return sapProgressData.inHouseCashCenterTier?.id !== 0;
      case 'statutoryYearEndMonth':
      case 'statutoryYearEndDay':
        return !!(sapProgressData.statutoryYearEndMonth && sapProgressData.statutoryYearEndDay);
      case 'legallyRegisteredAddress':
        return isAddressComplete('legallyRegisteredAddress');
      case 'physicalAddress':
        return isAddressComplete('physicalAddress');
      default:
        return false;
    }
  };

  const doesItemHaveValue = (itemKey: string): boolean => {
    const value = sapProgressData[itemKey as keyof ISAPProgressInfo];
    const customValidationKeys = [
      'inHouseCashCenterTier',
      'statutoryYearEndMonth',
      'statutoryYearEndDay',
      'legallyRegisteredAddress',
      'physicalAddress',
    ];

    if (customValidationKeys.includes(itemKey)) {
      return handleCustomValidation(itemKey);
    }

    return value !== '' && value !== null && value !== undefined;
  };

  const functionalCurrencyItems: IRequiredItem[] = [];
  const legalDocItems: IRequiredItem[] = [];
  const sapSetupItems: IRequiredItem[] = [];
  const ihccItems: IRequiredItem[] = [];
  const shadowCompanyItems: IRequiredItem[] = [];

  const getItems = (): IRequiredItem[] => {
    const legallyRegisteredInUSorCAN: boolean =
      sapProgressData?.legallyRegisteredAddress?.country?.shortName === 'United States' ||
      sapProgressData?.legallyRegisteredAddress?.country?.shortName === 'Canada';

    const isIncludedInIHCC = sapProgressData?.isIncludedInInHouseCashCenter;

    const items: IRequiredItem[] = [];

    functionalCurrencyItems.push(
      { key: 'functionalCurrency', value: 'Functional Currency' },
      { key: 'statutoryCurrency', value: 'Statutory Reporting Currency' },
      { key: 'functionalCurrencyDocument', value: 'Functional Currency Document' },
    );

    legalDocItems.push(
      { key: 'fullLegalName', value: 'Full Legal Name' },
      { key: 'sapLegalName', value: 'SAP Legal Name' },
      { key: 'abbreviatedSapName', value: 'SAP Name (Short Form)' },
      { key: 'statutoryYearEndMonth', value: 'Statutory Year End' },
      { key: 'legallyRegisteredAddress', value: 'Legally Registered Address' },
      { key: 'physicalAddress', value: 'Physical Address' },
    );

    if (legallyRegisteredInUSorCAN) {
      sapSetupItems.push({ key: 'taxJurisdictionCode', value: 'Tax Jurisdiction Code' });
    }

    ihccItems.push({ key: 'isIncludedInInHouseCashCenter', value: 'Is Included In IHCC' });

    if (isIncludedInIHCC) {
      ihccItems.push(
        { key: 'inHouseCashCenterTier', value: 'IHCC Tier' },
        { key: 'inHouseCashCenterAccountCurrency', value: 'IHCC Account Currency' },
        { key: 'inHouseCashCenterPostingMode', value: 'IHCC Posting Mode' },
      );
    }

    if (sapProgressData?.isShadowCompanyNeeded) {
      shadowCompanyItems.push({ key: 'name', value: 'Shadow Company Name' });
    }

    return items.concat(
      functionalCurrencyItems,
      legalDocItems,
      sapSetupItems,
      ihccItems,
      shadowCompanyItems,
    );
  };

  const getGroups = (): IGroup[] => {
    const legallyRegisteredInUSorCAN: boolean =
      sapProgressData?.legallyRegisteredAddress?.country?.shortName === 'United States' ||
      sapProgressData?.legallyRegisteredAddress?.country?.shortName === 'Canada';

    const groups: IGroup[] = [];
    groups.push(
      {
        key: 'functionalCurrency',
        name: 'Functional Currency',
        startIndex: 0,
        count: functionalCurrencyItems.length,
      },
      {
        key: 'legalDocumentInfo',
        name: 'Legal Doc Info',
        startIndex: functionalCurrencyItems.length,
        count: legalDocItems.length,
      },
    );

    if (legallyRegisteredInUSorCAN) {
      groups.push({
        key: 'sapSetupInfo',
        name: 'SAP Setup',
        startIndex: functionalCurrencyItems.length + legalDocItems.length,
        count: sapSetupItems.length,
      });
    }

    groups.push({
      key: 'inHouseCashCenterSetup',
      name: 'IHCC Setup',
      startIndex: legallyRegisteredInUSorCAN
        ? functionalCurrencyItems.length + legalDocItems.length + sapSetupItems.length
        : functionalCurrencyItems.length + legalDocItems.length,
      count: ihccItems.length,
    });

    if (sapProgressData?.isShadowCompanyNeeded) {
      groups.push({
        key: 'shadowCompanyInfo',
        name: 'Shadow Company Info',
        startIndex: legallyRegisteredInUSorCAN
          ? functionalCurrencyItems.length +
            legalDocItems.length +
            sapSetupItems.length +
            ihccItems.length
          : functionalCurrencyItems.length + legalDocItems.length + ihccItems.length,
        count: shadowCompanyItems.length,
      });
    }
    return groups;
  };

  const items = getItems();
  const groups = getGroups();

  return (
    <RequiredFieldsPanel
      closePanel={closePanel}
      doesItemHaveValue={doesItemHaveValue}
      items={items}
      groups={groups}
      loading={loading}
      error={error}
    />
  );
};

export default SapProgressPanel;
