import { IColumn, ColumnActionsMode } from '@fluentui/react';

export const organizationDropdownItems = [
  { key: '@microsoft.com', text: 'Microsoft' },
  { key: '@linkedin.biz', text: 'LinkedIn' },
];
export const columns: Array<IColumn> = [
  {
    key: 'name',
    name: 'Full Name',
    fieldName: 'displayName',
    minWidth: 100,
    maxWidth: 165,
    isResizable: true,
    isRowHeader: true,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'userAlias',
    name: 'User Alias',
    fieldName: 'alias',
    minWidth: 250,
    maxWidth: 250,
    isResizable: false,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'email',
    name: 'Email',
    fieldName: 'emailAddress',
    minWidth: 100,
    maxWidth: 165,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'groupIdentifiers',
    name: 'Type',
    fieldName: 'groupIdentifiers',
    minWidth: 100,
    maxWidth: 165,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 100,
    maxWidth: 165,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
  },
];
