import { ActionButton, ITag, Label, Stack } from '@fluentui/react';
import React from 'react';
import { IGenericFilterTag, ITypedTag } from '../../../utils/types/IGenericFilterTag';
import ValueTag from '../card/ValuteTag';

interface IGenericFilterTagItemsProps {
  filterTagItems: IGenericFilterTag[];
  onFilterTagsChange?: (
    filterTagKey: string,
    filterTagItems: ITag[],
    parentDataKey?: string,
  ) => void;
  onClearAllFilters: () => void;
}
const GenericFilterTagItems = (props: IGenericFilterTagItemsProps): JSX.Element => {
  const { filterTagItems, onFilterTagsChange, onClearAllFilters } = props;

  const emptyItems = filterTagItems.filter((tag: IGenericFilterTag) => {
    return tag.values?.length === 0;
  });

  if (emptyItems.length === filterTagItems.length) {
    return <></>;
  }

  return (
    <Stack horizontal wrap verticalAlign="center" tokens={{ childrenGap: 10 }}>
      {filterTagItems.map((tag: IGenericFilterTag): JSX.Element => {
        if (tag.values?.length > 0 && !tag.hideFromBar) {
          return (
            <Stack
              key={tag.dataKey}
              horizontal
              wrap
              verticalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              <Label styles={{ root: { fontWeight: 300 } }}>{tag.title}</Label>

              {tag.values.map((filterValue: ITypedTag) => {
                return (
                  <ValueTag
                    key={filterValue.key}
                    tagString={filterValue.name}
                    iconName={filterValue?.isSavedFilter ? 'QueryList' : undefined}
                    onClick={(itemName: string) => {
                      onFilterTagsChange(
                        tag.dataKey,
                        tag.values.filter((t) => t.name !== itemName),
                      );
                    }}
                  />
                );
              })}
            </Stack>
          );
        }
        return <React.Fragment key={tag.dataKey} />;
      })}
      {!!filterTagItems?.length && <ActionButton text="Clear all" onClick={onClearAllFilters} />}
    </Stack>
  );
};
export default GenericFilterTagItems;
