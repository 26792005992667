import { ApolloError } from '@apollo/client';
import {
  DetailsRow,
  DirectionalHint,
  Dropdown,
  IColumn,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IDropdownOption,
  IRenderFunction,
  ITooltipHostStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SpinButton,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from '@fluentui/react';
import React, { useState } from 'react';
import {
  addDurationToMilestones,
  updateMilestoneOffsetsCallback,
} from '../../../../utils/MilestoneHelpers';
import { IProcessTemplateVersionMilestone } from '../../../../utils/types/IProcessConfig';
import ShimmeredDetailsListWrapper from '../../../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import SpanWithLabel from '../../../common/SpanWithLabel';
import { milestoneListColumns } from './CreateProcessConfigUtils';
import { MessagingColors } from '../../../../app/common/styles/CommonStyleObjects';

interface ICreateProcessConfigMilestonesFormProps {
  processTemplateMilestones: IProcessTemplateVersionMilestone[];
  onMilestonesChange: (newMilestone: IProcessTemplateVersionMilestone[]) => void;
  loading: boolean;
  error: ApolloError;
}
export const offsetTooltipHostStyles: Partial<ITooltipHostStyles> = {
  root: { alignSelf: 'end' },
};

const CreateProcessConfigMilestonesForm = (
  props: ICreateProcessConfigMilestonesFormProps,
): JSX.Element => {
  const { processTemplateMilestones, onMilestonesChange, error, loading } = props;
  const [isMinimumDuration, setIsMinimumDuration] = useState(false);
  const [focusedDurationIndex, setFocusedDurationIndex] = useState(-1);

  const processTemplateMilestonesWithDurations = processTemplateMilestones
    ? addDurationToMilestones(processTemplateMilestones)
    : [];
  const updateMilestoneConfigEdits = (
    newValue: IDropdownOption,
    milestoneConfigId: number,
  ): void => {
    const updatedProcessTemplateMilestones: IProcessTemplateVersionMilestone[] = [
      ...processTemplateMilestones,
    ];
    const editedMilestoneIndex = updatedProcessTemplateMilestones.findIndex(
      (u) => u.id === milestoneConfigId,
    );

    updatedProcessTemplateMilestones[editedMilestoneIndex] = {
      ...updatedProcessTemplateMilestones[editedMilestoneIndex],
      assigneeId: parseInt(newValue?.key?.toString(), 10),
    };
    onMilestonesChange(updatedProcessTemplateMilestones);
  };
  const spinButtonStyles = { root: { marginTop: 28 } };

  const handleMinDurationTooltip = (
    durationInput: number,
    milestone: IProcessTemplateVersionMilestone,
    index: number,
  ) => {
    setFocusedDurationIndex(index);
    if (durationInput <= milestone.minimumDuration) {
      setIsMinimumDuration(true);
    } else {
      setIsMinimumDuration(false);
    }
  };

  const renderItemColumn = (
    item: IProcessTemplateVersionMilestone,
    index: number,
    column: IColumn,
  ): JSX.Element => {
    const fieldContent = item[column.fieldName];

    switch (column.key) {
      case 'assigneeOptions': {
        if (fieldContent !== undefined) {
          const options = (fieldContent as { id: number; name: string }[]).map((option) => {
            return { key: option.id, text: option.name };
          });
          const assignedToGlobalUser = item.globalAssigneeId !== null;

          return (
            <Dropdown
              placeholder="Please select assignee"
              key={item.id}
              label={item.milestoneTemplateName}
              defaultSelectedKey={assignedToGlobalUser ? item.globalAssigneeId : item.assigneeId}
              options={options}
              disabled={assignedToGlobalUser}
              onChange={(event, option) => {
                updateMilestoneConfigEdits(option, item.id);
              }}
            />
          );
        }
        return (
          <SpanWithLabel
            labelText={item.milestoneTemplateName}
            value={item?.assignee?.toString()}
          />
        );
      }
      case 'duration': {
        if (fieldContent !== 0) {
          return (
            <TooltipHost
              content={`${item.minimumDuration} is the minimum`}
              id="tooltip"
              styles={offsetTooltipHostStyles}
              hidden={!isMinimumDuration || focusedDurationIndex !== index}
              directionalHint={DirectionalHint.bottomCenter}
            >
              <SpinButton
                inputProps={{ 'aria-label': 'Edit duration' }}
                styles={spinButtonStyles}
                defaultValue={(fieldContent as number).toString()}
                step={1}
                incrementButtonAriaLabel="Increase value by 1"
                decrementButtonAriaLabel="Decrease value by 1"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleMinDurationTooltip(parseInt(event.target.value, 10), item, index);
                }}
                onMouseOver={(event: React.MouseEvent<HTMLInputElement>) => {
                  handleMinDurationTooltip(
                    parseInt((event.target as HTMLInputElement).value, 10),
                    item,
                    index,
                  );
                }}
                onValidate={(value: string) => {
                  handleMinDurationTooltip(parseInt(value, 10), item, index);
                  return updateMilestoneOffsetsCallback(
                    parseInt(value, 10) || item.minimumDuration,
                    item.id,
                    processTemplateMilestonesWithDurations,
                    onMilestonesChange,
                  );
                }}
                onIncrement={(value: string) => {
                  handleMinDurationTooltip(parseInt(value, 10) + 1, item, index);
                  return updateMilestoneOffsetsCallback(
                    (parseInt(value, 10) || item.minimumDuration) + 1,
                    item.id,
                    processTemplateMilestonesWithDurations,
                    onMilestonesChange,
                  );
                }}
                onDecrement={(value: string) => {
                  handleMinDurationTooltip(parseInt(value, 10) - 1, item, index);
                  return updateMilestoneOffsetsCallback(
                    (parseInt(value, 10) || item.minimumDuration) - 1,
                    item.id,
                    processTemplateMilestonesWithDurations,
                    onMilestonesChange,
                  );
                }}
              />
            </TooltipHost>
          );
        }
        return <>--</>;
      }
      case 'defaultDaysOffset': {
        if (fieldContent != null) {
          const defaultDaysOffset = Math.abs(parseInt(fieldContent as string, 10));
          return (
            <span>
              {defaultDaysOffset}
              &nbsp; days
            </span>
          );
        }
        return <>--</>;
      }
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const onRenderRow = (rowProps: IDetailsRowProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (rowProps) {
      if (rowProps.item.maxDaysOffset === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: MessagingColors.warning };
      }

      return <DetailsRow {...rowProps} styles={customStyles} />;
    }
    return null;
  };
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    headerProps: IDetailsHeaderProps,
    defaultRender,
  ) => {
    if (!headerProps) {
      return null;
    }

    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender?.({
          ...headerProps,
        })}
      </Sticky>
    );
  };

  return (
    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="Loading the milestones for the process template"
      />
      <ShimmeredDetailsListWrapper
        columns={milestoneListColumns}
        items={processTemplateMilestonesWithDurations}
        onRenderRow={onRenderRow}
        enableShimmer={loading}
        onRenderDetailsHeader={onRenderDetailsHeader}
        onRenderItemColumn={renderItemColumn}
        singularListLabel="Milestone"
        pluralListLabel="Milestones"
      />
    </ScrollablePane>
  );
};
export default CreateProcessConfigMilestonesForm;
