import { ChoiceGroup, IChoiceGroupOption, Separator, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import InlineFormInputErrorMessage from '../../../common/errorContent/InlineFormInputErrorMessage';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import ICashFlow from '../types';
import { yesNoOptions } from '../../../../utils/types/CommonDropdownOptions';

interface ICashFlowFormProps {
  form: UseFormMethods<ICashFlow>;
  data: ICashFlow;
}

const CashFlowForm = ({ form, data }: ICashFlowFormProps): JSX.Element => {
  const { setValue, control } = form;

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Controller
        name="cashFlowCurrency"
        control={control}
        defaultValue={data?.cashFlowCurrency}
        render={(): React.ReactElement => (
          <CurrencyCodeDropdown
            label="What are the cash flows of the entity's assets and liabilities in?"
            propertyName="CashFlowCurrency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('cashFlowCurrency', newValue);
            }}
            defaultValue={data?.cashFlowCurrency}
            errorMessage=""
            width={500}
          />
        )}
      />
      <Controller
        name="cashFlowCurrencyNotes"
        id="cashFlowCurrencyNotes"
        label="Notes"
        multiline
        as={TextField}
        control={control}
        defaultValue={data?.cashFlowCurrencyNotes}
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          newValue?: string,
        ): void => {
          setValue('cashFlowCurrencyNotes', newValue);
        }}
      />
      <Separator />
      <div>
        <Controller
          name="cashFlowAffectsParent"
          control={control}
          defaultValue={data?.cashFlowAffectsParent?.toString()}
          render={(): React.ReactElement => (
            <>
              <ChoiceGroup
                id="CashFlowAffectsParent"
                label="Do these cash flows directly affect the parent entity's cash flows and are they readily available for remittance to the parent entity?"
                options={yesNoOptions}
                required
                defaultSelectedKey={data?.cashFlowAffectsParent?.toString()}
                onChange={(
                  ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                  option?: IChoiceGroupOption,
                ): void => {
                  setValue('cashFlowAffectsParent', option.key);
                }}
              />
              <InlineFormInputErrorMessage errorMessage="" />
            </>
          )}
        />
      </div>
      <Controller
        name="cashFlowAffectsParentNotes"
        id="cashFlowAffectsParentNotes"
        label="Notes"
        multiline
        as={TextField}
        control={control}
        defaultValue={data?.cashFlowAffectsParentNotes}
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          newValue?: string,
        ): void => {
          setValue('cashFlowAffectsParentNotes', newValue);
        }}
      />
    </Stack>
  );
};

export default CashFlowForm;
