import { gql } from '@apollo/client';

export interface IReport {
  id: string;
  name: string;
  reportUri: string;
}

export interface IEmbeddedReportData extends IReport {
  embedUrl: string;
  token: string;
}

export const GET_REPORT = gql`
  query GetReport($reportId: String!) {
    report(reportId: $reportId) {
      id
      name
      embedUrl
      token
    }
  }
`;

export const GET_REPORT_STAT_CONTACTS = gql`
  query GetReportStatContacts {
    reportStatContacts {
      id
      name
      embedUrl
      token
    }
  }
`;
export const GET_REPORTS = gql`
  query GetReports {
    reports {
      id
      name
      reportUri
    }
  }
`;
