import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  silentGraphRequest,
  graphRequest,
  getSilentStatApiRequest,
  getStatApiRequest,
} from './AuthConfig';
import config from '../components/common/ConfigLoader';
import { AuthModule } from './authentication/AuthModule';

let msalInstance: AuthModule;

async function apiRequest(url: string, accessToken: string): Promise<Response> {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers,
  };

  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    return null; // TODO: need to do something with error
  }
}

export const setAuthInstance = (auth: AuthModule): void => {
  msalInstance = auth;
};

export const getAuthInstance = (): AuthModule => {
  return msalInstance;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function graphApiRequest(endpoint: string, returnJson = true): Promise<any> {
  const graphUrl = endpoint;

  return msalInstance.acquireToken(silentGraphRequest, graphRequest).then((accessToken: string) => {
    return apiRequest(graphUrl, accessToken).then((apiResponse) =>
      returnJson ? apiResponse.json() : apiResponse,
    );
  });
}

const authLink = setContext((_, { headers }) => {
  return msalInstance
    .acquireToken(getSilentStatApiRequest(), getStatApiRequest())
    .then((accessToken: string) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken}`,
        },
      };
    });
});

export const getClient = (): ApolloClient<unknown> => {
  const httpLink = createHttpLink({
    uri: `${config?.settings?.baseURI}/api/graphql`,
  });
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(), // previously handled automatically
  });
};

export const getCurrentAppVersion = (): string => {
  return config?.settings?.appBuildId;
};
