import React from 'react';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';

interface IErrorMessageProps {
  children: JSX.Element;
}

const ErrorMessage = ({ children }: IErrorMessageProps): JSX.Element => {
  return (
    <MessageBar messageBarType={MessageBarType.error}>
      <Stack tokens={{ childrenGap: 10 }}>{children}</Stack>
    </MessageBar>
  );
};

export default ErrorMessage;
