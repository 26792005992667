import {
  IServiceContact,
  IServiceContactsUserAssignment,
  IServiceContactsUserAssignmentErrors,
  ICustomContactGroup,
  IFilingInfoErrors,
} from './interfaces';

const validateRequiredArray = (
  key: string,
  errors: IServiceContactsUserAssignmentErrors,
  pageState: IServiceContactsUserAssignment,
): IServiceContactsUserAssignmentErrors => {
  const pageErrors = { ...errors };
  const errorKey = key as keyof IServiceContactsUserAssignmentErrors;
  const stateKey = key as keyof IServiceContactsUserAssignment;

  pageErrors[errorKey] = {};

  const array = pageState[stateKey] as Array<IServiceContact>;

  if (!array || array.length < 1) {
    pageErrors[errorKey] = {
      message: 'You must select at least one person to fill this slot.',
    };
  } else {
    delete pageErrors[errorKey];
  }

  return pageErrors;
};

export const addErrorMessage = (
  stateKey: string,
  errorMessage: string,
  errors: IFilingInfoErrors,
): IFilingInfoErrors => {
  const pageErrors = { ...errors };
  const key = stateKey as keyof IFilingInfoErrors;
  pageErrors[key] = {};

  if (errorMessage) {
    pageErrors[key].message = errorMessage;
  } else {
    delete pageErrors[key];
  }

  return pageErrors;
};

export const addErrorMessageUserAssignment = (
  stateKey: string,
  errorMessage: string,
  errors: IServiceContactsUserAssignmentErrors,
): IServiceContactsUserAssignmentErrors => {
  const pageErrors = { ...errors };
  const key = stateKey as keyof IServiceContactsUserAssignmentErrors;
  pageErrors[key] = {};

  if (errorMessage) {
    pageErrors[key].message = errorMessage;
  } else {
    delete pageErrors[key];
  }

  return pageErrors;
};

export const ServiceContactErrorMessageString =
  'Service Contacts are only allowed to be in one list';

const setErrorMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageErrors: any,
  outerErrorKey: keyof IServiceContactsUserAssignmentErrors,
  innerErrorKey?: keyof IServiceContactsUserAssignmentErrors,
): void => {
  pageErrors[outerErrorKey] = {
    message: ServiceContactErrorMessageString,
  };
  if (innerErrorKey) {
    pageErrors[innerErrorKey] = {
      message: ServiceContactErrorMessageString,
    };
  }
};

const duplicateCheck = (
  pageState: IServiceContactsUserAssignment,
  errors: IServiceContactsUserAssignmentErrors,
): IServiceContactsUserAssignmentErrors => {
  const pageErrors = { ...errors };
  // go through each list and check for duplicates
  const pageStateLists = [
    'statutoryController',
    'regionalControllers',
    'generalManagers',
    'vpPresidentDirectors',
    'davaDealLeads',
    'bpoContacts',
    'finOpsContacts',
  ];

  // Get all custom contacts into one collection for duplicate testing
  const customContacts: IServiceContact[] = pageState.customContactGroups.contactGroups
    .map((cg: ICustomContactGroup) => cg.contacts)
    .flat();

  // Iterate through each contact list
  pageStateLists.forEach((value: string, index: number) => {
    const outerKey = value as keyof IServiceContactsUserAssignment;
    const outerList = pageState[outerKey] as Array<IServiceContact>;

    // For each contact list
    outerList.forEach((serviceContactOuter: IServiceContact) => {
      for (let innerIndex = index + 1; innerIndex < pageStateLists.length; innerIndex += 1) {
        const innerKey = pageStateLists[innerIndex] as keyof IServiceContactsUserAssignment;
        const innerList = pageState[innerKey] as Array<IServiceContact>;

        const outerErrorKey = value as keyof IServiceContactsUserAssignmentErrors;
        const innerErrorKey = innerKey as keyof IServiceContactsUserAssignmentErrors;

        // If the contact is already assigned as a custom contact
        if (
          customContacts.find(
            (contact: IServiceContact) => contact.graphId === serviceContactOuter.graphId,
          )
        ) {
          setErrorMessage(pageErrors, outerErrorKey);
          break;
        }

        // For every other contact list
        innerList.forEach((serviceContactInner: IServiceContact) => {
          // If the contact is already assigned as a main contact
          if (serviceContactOuter.graphId === serviceContactInner.graphId) {
            setErrorMessage(pageErrors, outerErrorKey, innerErrorKey);
          }
        });
      }
    });
  });
  return pageErrors;
};

export const validateServiceContactsState = (
  pageState: IServiceContactsUserAssignment,
): IServiceContactsUserAssignmentErrors => {
  let errors: IServiceContactsUserAssignmentErrors = {};

  errors = validateRequiredArray('statutoryController', errors, pageState);
  errors = validateRequiredArray('regionalControllers', errors, pageState);

  // is one finance checked, bpo contacts required
  if (pageState.isOneFinance) {
    errors = validateRequiredArray('bpoContacts', errors, pageState);
  } else {
    delete errors.bpoContacts;
  }

  // check for unique graph Ids across all the lists
  errors = duplicateCheck(pageState, errors);

  return errors;
};
