import { DetailsList, IGroup, SelectionMode, Stack } from '@fluentui/react';
import React from 'react';
import { RoleActions } from '../../utils/statApi/RolesApi';
import Card from '../common/card/Card';
import SpanWithLabel from '../common/SpanWithLabel';
import StackColumn from '../common/StackColumn';
import getColumns from './RolesList.config';

interface IRoleDetail {
  roleActions: RoleActions;
}
const RoleDetail = ({ roleActions }: IRoleDetail): JSX.Element => {
  const columns = getColumns();

  const sortedActions = roleActions
    ? [...roleActions?.actions].sort((a, b) => (a.category > b.category ? 1 : -1))
    : [];

  const getGroups = (): Array<IGroup> => {
    if (sortedActions) {
      const groupedActions = sortedActions.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (ga: any, item: { category?: string }) => {
          if (!ga[item.category]) {
            ga[item.category] = [];
          }

          ga[item.category].push(item);
          return ga;
        },
        {},
      );

      const groups: IGroup[] = Object.keys(groupedActions).map((category) => {
        const startIndex = sortedActions.findIndex(
          (ga: { category?: string }) => ga.category === category,
        );
        return {
          key: category,
          name: `${category}`,
          startIndex,
          count: groupedActions[category].length,
          level: 0,
          isCollapsed: false,
        };
      });

      return groups;
    }
    return [];
  };
  return (
    <>
      {sortedActions && (
        <>
          <Card title={roleActions?.role?.name}>
            <Stack horizontal wrap horizontalAlign="space-around" tokens={{ childrenGap: 60 }}>
              <StackColumn>
                <SpanWithLabel labelText="Description" value={roleActions?.role?.description} />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Is Global" value={roleActions?.role?.isGlobal} />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card title="Actions">
            <DetailsList
              items={sortedActions || []}
              columns={columns}
              groups={getGroups()}
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              compact
              selectionMode={SelectionMode.none}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default RoleDetail;
