import { ITag } from '@fluentui/react';
import React from 'react';
import { useQuery } from '@apollo/client';
import Collapsible from './Collapsible';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import GenericChecklist from './GenericChecklist';
import { GET_AGM_TRACKER_STATUSES_QUERY } from '../../../utils/statApi/CompanyFiscalYearsApi';
import { IAgmTrackerStatus } from '../../companies/annualFilingRequirements/IAgmListPageSearchResult';

export interface IChecklistImplementationProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  initialValues?: IGenericFilterTag;
  disabledValues?: IGenericFilterTag;
  label: string;
  dataKey: string;
}

const AgmTrackerStatusCollapsibleChecklist = (
  props: IChecklistImplementationProps,
): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;
  const { loading, data } = useQuery(GET_AGM_TRACKER_STATUSES_QUERY);
  const { agmTrackerStatuses } = !loading && data ? data : [];

  const mapToTag = (item: { id: number; fieldName: string; trackerIndex: number }): ITag => {
    return {
      key: item.id,
      name: `${item.trackerIndex} - ${item.fieldName}`,
    };
  };

  const agmTrackerStatusTags = agmTrackerStatuses?.map((status: IAgmTrackerStatus) => {
    return mapToTag(status);
  });

  const onCheckChanged = (items: ITag[]): void => {
    const selectedStatuses = agmTrackerStatuses
      .filter((status: IAgmTrackerStatus) => {
        return items.some((item) => item.key === status.id);
      })
      .map((status: IAgmTrackerStatus) => {
        return {
          key: status.id,
          name: status.fieldName,
        };
      });

    onSelectedItems({
      dataKey,
      title: label,
      values: selectedStatuses,
    });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        items={agmTrackerStatusTags}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
        showSearch={false}
      />
    </Collapsible>
  );
};

export default AgmTrackerStatusCollapsibleChecklist;
