import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import ActionButtons from '../../common/ActionButtons';

import StatutoryContactsForm from './StatutoryContactsForm';
import { ICompanyCreationContact, IStatutoryContacts } from './types';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  UPDATE_COMPANY_CREATION_CONTACTS,
} from '../../../utils/statApi/CompanyCreationApi';

interface IStatutoryContactsPanelProps {
  closePanel: () => void;
  data: IStatutoryContacts;
  companyCreationId: number;
  isBpoRequired: boolean;
}

const StatutoryContactsPanel = ({
  closePanel,
  data,
  companyCreationId,
  isBpoRequired,
}: IStatutoryContactsPanelProps): JSX.Element => {
  const form = useForm<IStatutoryContacts>({
    defaultValues: {
      ...data,
    },
  });

  const [updateStatutoryContacts, { loading, error }] = useMutation(
    UPDATE_COMPANY_CREATION_CONTACTS,
    {
      onCompleted: closePanel,
      refetchQueries: [
        {
          query: GET_COMPANY_CREATION_COMPANY_INFO,
          variables: {
            companyCreationId,
          },
        },
        {
          query: GET_COMPANY_CREATION_PROGRESS,
          variables: {
            companyCreationId,
          },
        },
      ],
    },
  );
  const { handleSubmit } = form;

  const handleOnSaveClick = (formData: IStatutoryContacts): void => {
    // GraphQL types don't like the typename property so reselect the contact
    const {
      statutoryController,
      regionalControllers,
      fsTaxRepContacts,
      treasuryRepContacts,
      alternativeContacts,
      bpoContacts,
    } = formData;
    const contactsList: ICompanyCreationContact[] = [].concat(
      statutoryController,
      regionalControllers,
      fsTaxRepContacts,
      treasuryRepContacts,
      alternativeContacts,
      bpoContacts,
    );

    updateStatutoryContacts({
      variables: {
        statutoryContactsInputDto: {
          companyCreationRequestId: companyCreationId,
          contacts: contactsList.map((contact) => {
            return {
              id: contact?.id ? parseInt(contact.id, 10) : null,
              graphGUID: contact.graphId,
              firstName: contact.firstName,
              lastName: contact.lastName,
              displayName: contact.name,
              alias: contact.alias,
              emailAddress: contact.email,
              contactType: contact.contactType,
              personnelNumber: contact.personnelNumber,
            };
          }),
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Statutory Contacts"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit Statutory Contacts Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Statutory Contacts"
          saveTitle="Save Statutory Contacts"
          cancelLabel="Cancel Statutory Contacts Edit"
          cancelTitle="Cancel Statutory Contacts Edit"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <StatutoryContactsForm data={data} form={form} isBpoRequired={isBpoRequired} />
      </form>
    </CoherencePanel>
  );
};
export default StatutoryContactsPanel;
