import {
  ActivityItem,
  Icon,
  Label,
  Stack,
  Sticky,
  StickyPositionType,
  mergeStyleSets,
} from '@fluentui/react';
import React from 'react';
import moment from 'moment';
import StatDateFormats from '../../../../utils/types/DateFormats';
import {
  AgmTrackerItemActionType,
  IAgmTrackerAction,
} from '../../annualFilingRequirements/IAgmListPageSearchResult';

interface IAgmTrackerItemHistoryProps {
  actionItems: IAgmTrackerAction[];
}

const styles = mergeStyleSets({
  activityItem: {
    marginTop: '10px',
  },
  historyStack: {
    marginBottom: '20px',
  },
});

const AgmTrackerItemHistory = ({ actionItems }: IAgmTrackerItemHistoryProps): JSX.Element => {
  const renderActivityItem = (item: IAgmTrackerAction): JSX.Element => {
    const user = item.changedBy?.displayName;
    let description = '';
    let iconName = '';
    let comments = '';

    switch (item.actionType) {
      case AgmTrackerItemActionType.CelaAssigneeChange:
        description = `${user} updated CELA assignees to:`;
        comments = item.actionValue ?? 'No assignees';
        iconName = 'peopleAdd';
        break;
      case AgmTrackerItemActionType.StatusChange:
        description = `${user} updated the status to "${item.actionValue}" with comments:`;
        comments = item.comments;
        iconName = 'trackers';
        break;
      case AgmTrackerItemActionType.Comment:
        description = `${user} commented:`;
        comments = item.comments;
        iconName = 'message';
        break;
      case AgmTrackerItemActionType.OwnerChange:
        description = `${user} updated owner to "${item.actionValue}" with comments:`;
        comments = item.comments;
        iconName = 'assign';
        break;
      case AgmTrackerItemActionType.OwnerChangeBySystem:
        description = `Owner changed to "${item.actionValue}":`;
        comments = item.comments;
        iconName = 'assign';
        break;
      default:
        description = '';
        iconName = 'trackers';
    }
    return (
      <ActivityItem
        key={item.id}
        timeStamp={moment(item.actionDate).format(StatDateFormats.DayWithTime)}
        activityDescription={description}
        className={styles.activityItem}
        activityIcon={<Icon iconName={iconName} />}
        comments={comments}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.historyStack}>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <Label>History</Label>
      </Sticky>
      <Stack.Item>
        {actionItems?.length === 0 && <span>No history available.</span>}
        {actionItems?.map((item: IAgmTrackerAction) => {
          return renderActivityItem(item);
        })}
      </Stack.Item>
    </Stack>
  );
};

export default AgmTrackerItemHistory;
