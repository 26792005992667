import { IconButton } from '@fluentui/react';
import React from 'react';

export interface IListReorderButtons {
  listItemIndex: number;
  totalItemCount: number;
  onSwapUp: (currentItemIndex: number, previousItemIndex: number) => void;
  onSwapDown: (currentItemIndex: number, nextItemIndex: number) => void;
}
export const ListReorderButtons = (props: IListReorderButtons): JSX.Element => {
  const { listItemIndex, totalItemCount, onSwapUp, onSwapDown } = props;
  const orderIconStyles = { root: { marginRight: 10 } };
  return (
    <>
      <IconButton
        iconProps={{ iconName: 'ChevronUp' }}
        styles={orderIconStyles}
        title="Move Up"
        disabled={listItemIndex === 0}
        ariaLabel="Move Up"
        onClick={(): void => {
          onSwapUp(listItemIndex, listItemIndex - 1);
        }}
      />

      <IconButton
        iconProps={{ iconName: 'ChevronDown' }}
        styles={orderIconStyles}
        title="Move Down"
        disabled={listItemIndex === totalItemCount - 1}
        ariaLabel="Move Down"
        onClick={(): void => {
          onSwapDown(listItemIndex, listItemIndex + 1);
        }}
      />
    </>
  );
};
