import React from 'react';
import { Shimmer, Stack } from '@fluentui/react';

const CardShimmer = (): JSX.Element => {
  return (
    <div className="ms-depth-4">
      <Stack>
        <Shimmer />
        <br />
        <Shimmer />
        <br />
        <Shimmer />
        <br />
        <Shimmer />
        <br />
        <Shimmer />
      </Stack>
    </div>
  );
};

export default CardShimmer;
