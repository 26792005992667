import React, { useState } from 'react';
import { Stack, Shimmer, IconButton, PrimaryButton } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import Card from '../../common/card/Card';
import { ServiceContactFormType, ICustomContactGroup } from './interfaces';
import GET_SERVICE_CONTACTS_QUERY from '../../../utils/statApi/ServiceContactsApi';
import ServiceContactsShimmer from './ServiceContactsShimmer';
import ContactGroupsEditPanel from './ContactGroupsEditPanel';
import StackColumn from '../../common/StackColumn';
import ServiceContactList from './ServiceContactList';
import SpanWithLabel from '../../common/SpanWithLabel';
import ServiceContactUserAssignmentForm from './ServiceContactUserAssingmentForm';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import CanAccess from '../../common/canAccess/CanAccess';
import { ICompanyCodeParams } from '../../common/ParamTypes';

const CompanyDetailServiceContacts = (): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const [isContactGroupEditPanelOpen, setIsContactGroupEditPanelOpen] = useState(false);
  const [isUserAssignmentEditPanelOpen, setIsUserAssignmentEditPanelOpen] = useState(false);
  const { data, loading, error } = useQuery(GET_SERVICE_CONTACTS_QUERY, {
    variables: { companyCode },
    fetchPolicy: 'cache-and-network',
  });

  const toggleContactGroupPanel = (): void => {
    setIsContactGroupEditPanelOpen(!isContactGroupEditPanelOpen);
  };

  const toggleUserAssignmentPanel = (): void => {
    setIsUserAssignmentEditPanelOpen(!isUserAssignmentEditPanelOpen);
  };

  const renderActionButton = (formType: ServiceContactFormType): JSX.Element => {
    if (formType === ServiceContactFormType.ContactGroups) {
      return (
        <CanAccess requestedAction="ServiceContactsCustomList:Edit">
          <IconButton
            title={`Edit ${formType}`}
            ariaLabel={formType}
            iconProps={{ iconName: 'Edit' }}
            onClick={toggleContactGroupPanel}
          />
        </CanAccess>
      );
    }

    return (
      <CanAccess requestedAction="ServiceContactsMainList:Edit">
        <IconButton
          title={`Edit ${formType}`}
          ariaLabel={formType}
          iconProps={{ iconName: 'Edit' }}
          onClick={toggleUserAssignmentPanel}
        />
      </CanAccess>
    );
  };
  const statutoryControllerArray = data?.serviceContacts?.statutoryController
    ? [data.serviceContacts.statutoryController]
    : [];
  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      <div className={bodyContentContainer}>
        <Card
          title="User Assignment"
          headingLevel={2}
          isLoading={!data?.serviceContacts?.id}
          actionButton={renderActionButton(ServiceContactFormType.UserAssignment)}
        >
          <Shimmer
            isDataLoaded={data?.serviceContacts?.id}
            ariaLabel="Loading Content"
            customElementsGroup={<ServiceContactsShimmer />}
          >
            <Stack horizontal wrap horizontalAlign="space-around" tokens={{ childrenGap: 60 }}>
              <StackColumn>
                <ServiceContactList
                  labelText="Statutory Controller"
                  contacts={statutoryControllerArray}
                />
                <ServiceContactList
                  labelText="General Managers"
                  contacts={data?.serviceContacts?.generalManagers || []}
                />
                <ServiceContactList
                  labelText="DAVA Deal Leads"
                  contacts={data?.serviceContacts?.davaDealLeads || []}
                />
                <ServiceContactList
                  labelText="Treasury Support Reps"
                  contacts={data?.serviceContacts?.treasurySupportReps || []}
                />
                <SpanWithLabel
                  labelText="One Finance"
                  value={data?.serviceContacts?.isOneFinance}
                />
                <SpanWithLabel labelText="Is MCAPS" value={data?.serviceContacts?.isMcaps} />
              </StackColumn>
              <StackColumn>
                <ServiceContactList
                  labelText="Regional Controllers"
                  contacts={data?.serviceContacts?.regionalControllers || []}
                />
                <ServiceContactList
                  labelText="VP/President/Directors"
                  contacts={data?.serviceContacts?.vpPresidentDirectors || []}
                />
                <ServiceContactList
                  labelText="Fin Ops Contacts"
                  contacts={data?.serviceContacts?.finOpsContacts || []}
                />
                <ServiceContactList
                  labelText="BPO Contacts"
                  contacts={data?.serviceContacts?.bpoContacts || []}
                />
                <ServiceContactList
                  labelText="Tax Support Reps"
                  contacts={data?.serviceContacts?.taxSupportReps || []}
                />
              </StackColumn>
            </Stack>
          </Shimmer>
        </Card>
        <br />
        <Card
          title="Contact Groups"
          actionButton={renderActionButton(ServiceContactFormType.ContactGroups)}
          headingLevel={2}
          isLoading={!data?.serviceContacts?.id}
        >
          <Stack horizontal wrap tokens={{ childrenGap: 60 }}>
            {data?.serviceContacts?.customContactGroups?.contactGroups?.length === 0 && (
              <Stack>
                <p>Looks like you haven&apos;t created any Contact Groups.</p>
                <CanAccess requestedAction="ServiceContactsCustomList:Edit">
                  <PrimaryButton
                    onClick={(): void => {
                      toggleContactGroupPanel();
                    }}
                    iconProps={{ iconName: 'add' }}
                  >
                    Create a Contact Group
                  </PrimaryButton>
                </CanAccess>
              </Stack>
            )}
            {data?.serviceContacts?.customContactGroups.contactGroups?.map(
              (customGroup: ICustomContactGroup) => {
                return (
                  <ServiceContactList
                    labelText={customGroup.label}
                    contacts={customGroup.contacts}
                    key={customGroup.id}
                  />
                );
              },
            )}
          </Stack>
        </Card>

        {isContactGroupEditPanelOpen && (
          <ContactGroupsEditPanel
            customContactGroups={data?.serviceContacts?.customContactGroups?.contactGroups}
            mainContacts={{
              ...data?.serviceContacts,
              treasurySupportReps: [],
              taxSupportReps: [],
              statutoryController: statutoryControllerArray,
            }}
            closePanel={toggleContactGroupPanel}
          />
        )}
        {isUserAssignmentEditPanelOpen && (
          <ServiceContactUserAssignmentForm
            contacts={{
              ...data?.serviceContacts,
              statutoryController: statutoryControllerArray,
            }}
            closePanel={toggleUserAssignmentPanel}
          />
        )}
      </div>
    </>
  );
};
export default CompanyDetailServiceContacts;
