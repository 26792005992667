import { ITag, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, FieldValues, UseFormMethods } from 'react-hook-form';
import CompanyPicker from '../companyGroups/detail/CompanyGroupCompanyPicker';
import { SHADOW_COMPANY_NAME_SUFFIX } from './details/types';

interface INewShadowCompanyFormProps {
  form: UseFormMethods<FieldValues>;
}

const NewShadowCompanyForm = ({ form }: INewShadowCompanyFormProps): JSX.Element => {
  const { control, errors, setValue } = form;
  const shadowCompanyNameLength = 25 - SHADOW_COMPANY_NAME_SUFFIX.length;

  const onCompanyUpdate = (items: ITag[]): void => {
    if (items.length > 0) {
      setValue('parentCompanyCode', parseInt(items[0]?.name.toString(), 10));
    } else {
      setValue('parentCompanyCode', null);
    }
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        id="parentCompanyCode"
        name="parentCompanyCode"
        control={control}
        required
        render={(): React.ReactElement => (
          <CompanyPicker
            selectedItems={null}
            itemLimit={1}
            setSelectedItems={onCompanyUpdate}
            required
            errorMessage={errors?.parentCompanyCode?.message}
            label="Parent Company"
          />
        )}
        rules={{
          required: 'Please select a parent company',
        }}
      />
      <Controller
        as={TextField}
        id="shadowCompanyName"
        name="shadowCompanyName"
        label="Shadow Company Name"
        control={control}
        suffix={SHADOW_COMPANY_NAME_SUFFIX}
        errorMessage={errors?.shadowCompanyName?.message}
        required
        rules={{
          required: 'Please enter a name',
          maxLength: {
            value: shadowCompanyNameLength,
            message: `Shadow Company Name can only be a max of ${shadowCompanyNameLength} characters`,
          },
        }}
      />
    </Stack>
  );
};

export default NewShadowCompanyForm;
