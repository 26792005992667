import React, { useState } from 'react';
import { Stack, Spinner } from '@fluentui/react';
import { useMutation, QueryLazyOptions } from '@apollo/client';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import FileListUpload from '../../../common/formFields/fileUpload/fileSelector';
import { ATTACH_DOCUMENT_MILESTONE } from '../../../../utils/statApi/MilestoneApi';
import { IDocument } from '../../../../utils/statApi/DocumentApi';

interface IMilestoneDocumentUpload {
  milestoneId: number;
  onUpload: (
    options?: QueryLazyOptions<{
      milestoneId: number;
    }>,
  ) => void;
}

const MilestoneDocumentUpload = ({
  milestoneId,
  onUpload,
}: IMilestoneDocumentUpload): JSX.Element => {
  const [mutationSuccessMessage, setMutationSuccessMessage] = useState(null);
  const [attachDocumentMilestone, { loading, error }] = useMutation(ATTACH_DOCUMENT_MILESTONE, {
    onCompleted: () => {
      setMutationSuccessMessage('The document was uploaded to the milestone successfully.');
    },
  });

  const submitSignoff = async (files: IDocument[]): Promise<void> => {
    const process = await attachDocumentMilestone({
      variables: {
        milestoneDocumentsInput: { milestoneId, milestoneDocuments: files },
      },
    });

    // If file was not properly uploaded, document will not be attached to milestone
    if (process?.data?.attachMilestoneDocument !== null) {
      onUpload();
    }
  };

  return (
    <Stack horizontalAlign="center">
      <LoadingErrorMessage
        error={error}
        loading={loading}
        actionName="uploading a document to the milestone"
      />
      <p style={{ textAlign: 'center', maxWidth: '700px' }}>
        Please select documents that you wish to upload
      </p>
      <FileListUpload
        onFileUploading={(): void => {}}
        onFileUploadError={(): void => {}}
        onFileUploadComplete={submitSignoff}
        uploadMutationLoading={loading}
        mutationSuccessMessage={mutationSuccessMessage}
      />
      {loading && <Spinner />}
    </Stack>
  );
};

export default MilestoneDocumentUpload;
