import moment from 'moment';
import StatDateFormats from './types/DateFormats';

const monthDayFormat = (month: string | number, day: string | number): string => {
  if (!month || !day) {
    return '--';
  }

  return moment(`${month}/${day}`, 'M-D').format(StatDateFormats.NoYear);
};

export default monthDayFormat;
