import { gql } from '@apollo/client';
import { REVIEW_FRAGMENT } from './ReviewApi';

export const PROCESS_EXTENSION_FRAGMENT = gql`
  fragment ProcessExtension on ProcessExtensionRequestDto {
    id
    process {
      id
      name
      filingDueDate
      originalFilingDueDate
      processTypeUri
    }
    processId
    companyCode
    companyName
    comments
    requestedDate
    extensionReason {
      id
      fieldName
    }
    review {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const GET_PROCESS_EXTENSION_REQUESTS = gql`
  query getProcessExtensions($statusIds: [Int!], $assignments: [String!]) {
    processExtensions(statusIds: $statusIds, assignments: $assignments) {
      id
      companyCode
      processName
      processType
      oldFilingDueDate
      requestedDate
      reportingPeriodEndDate
      reviewStatus
    }
  }
`;

export const GetProcessExtensionsWithPaginationQuery = gql`
  query getProcessExtensions(
    $statusIds: [Int!]
    $assignments: [String!]
    $processTypeIds: [Int!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    processExtensions(
      first: $first
      after: $after
      last: $last
      before: $before
      statusIds: $statusIds
      assignments: $assignments
      processTypeIds: $processTypeIds
    ) {
      edges {
        node {
          id
          companyCode
          processName
          processType
          oldFilingDueDate
          requestedDate
          reportingPeriodEndDate
          reviewStatus
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_PROCESS_EXTENSION_REQUESTS_FOR_PROCESS = gql`
  query getProcessExtensionsForProcess($processId: Int!) {
    processExtensionsForProcess(processId: $processId) {
      id
      companyCode
      processName
      oldFilingDueDate
      requestedDate
      reviewStatus
    }
  }
`;

export const UPDATE_PROCESS_EXTENSION_REQUEST = gql`
  mutation updateProcessExtensionRequest(
    $processExtensionRequest: ProcessExtensionRequestDtoInput!
  ) {
    updateProcessExtensionRequest(processExtensionRequest: $processExtensionRequest) {
      ...ProcessExtension
    }
  }
  ${PROCESS_EXTENSION_FRAGMENT}
`;

export const GET_PROCESS_EXTENSION_REQUEST = gql`
  query getProcessExtension($processExtensionId: Int!) {
    processExtension(processExtensionId: $processExtensionId) {
      ...ProcessExtension
    }
  }
  ${PROCESS_EXTENSION_FRAGMENT}
`;

export const CREATE_PROCESS_EXTENSION_REQUEST = gql`
  mutation createProcessExtensionRequest(
    $processExtensionRequest: ProcessExtensionRequestDtoInput!
    $companyCode: String!
    $reviewDocuments: ReviewDocumentsDtoInput!
  ) {
    createProcessExtensionRequest(
      processExtensionRequest: $processExtensionRequest
      companyCode: $companyCode
      reviewDocuments: $reviewDocuments
    ) {
      ...ProcessExtension
    }
  }
  ${PROCESS_EXTENSION_FRAGMENT}
`;

export const GET_REVIEW_STATUSES = gql`
  query getReviewStatuses {
    reviewStatuses {
      key: id
      text: fieldName
    }
  }
`;

export const GET_PROCESS_TYPES = gql`
  query getProcessTypes {
    processTypes {
      key: id
      text: name
    }
  }
`;

export const APPROVE_PROCESS_EXTENSION = gql`
  mutation approveProcessExtension($reviewId: Int!, $approvalId: Int!) {
    approveProcessExtension(reviewId: $reviewId, approvalId: $approvalId) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const REJECT_PROCESS_EXTENSION = gql`
  mutation rejectProcessExtension($reviewId: Int!, $approvalId: Int!, $reason: String!) {
    rejectProcessExtension(reviewId: $reviewId, approvalId: $approvalId, reason: $reason) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const GET_PROCESS_EXTENSION_REASONS = gql`
  {
    processExtensionReasons {
      id
      fieldName
    }
  }
`;

export const CREATE_PROCESS_EXTENSION_REVIEW_COMMENT = gql`
  mutation createProcessExtensionReviewComment($reviewId: Int!, $comment: String!) {
    createProcessExtensionReviewComment(reviewId: $reviewId, comment: $comment) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const ATTACH_PROCESS_EXTENSION_REVIEW_DOCUMENT = gql`
  mutation attachProcessExtensionReviewDocument($reviewDocuments: ReviewDocumentsDtoInput!) {
    attachProcessExtensionReviewDocument(reviewDocuments: $reviewDocuments) {
      ...Review
    }
  }
  ${REVIEW_FRAGMENT}
`;
