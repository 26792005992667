import React from 'react';
import { Spinner } from '@fluentui/react';
import {
  CoherencePagination,
  ICoherencePageSizeProps,
  ICoherencePaginationProps,
  IComboBoxNumberOption,
} from '@coherence-design-system/controls';
import IPaginationMetadata from './IPaginationMetadata';
import { IPageInfo } from '../../../../utils/types/IPageInfo';

/**
 * Default system wide max records per paginated page
 */
export const DEFAULT_RECORDS_PER_PAGE = 25;

export function getInitialPageSize(defaultPageSize?: number): number {
  return defaultPageSize != null && defaultPageSize !== 0
    ? defaultPageSize
    : DEFAULT_RECORDS_PER_PAGE;
}

export function calculateSkip(pageSize: number, currentPage: number): number {
  return pageSize * (currentPage - 1);
}

export function getTotalPageCount(totalRecordCount: number, defaultPageSize: number): number {
  // check for 0
  if (defaultPageSize <= 0) return 1;
  return Math.ceil(totalRecordCount / defaultPageSize);
}
export function getPaginationInfo(
  pageInfo: IPageInfo,
  selectedPage: number,
  newPageNumber: number,
  defaultPageSize: number,
): { first: number; after: string; last: number; before: string } {
  const { hasNextPage, hasPreviousPage, endCursor, startCursor } = pageInfo;
  const goingBackward = newPageNumber < selectedPage && hasPreviousPage;
  const goingForward = newPageNumber > selectedPage && hasNextPage;
  return {
    first: goingForward ? defaultPageSize : null,
    after: goingForward ? endCursor : null,
    last: goingBackward ? defaultPageSize : null,
    before: goingBackward ? startCursor : null,
  };
}
const DetailListPaginationBanner = (props: IPaginationMetadata): JSX.Element => {
  const { pageCount, selectedPage, pageSize, onPageChange, onPageSizeChange, loadingData } = props;
  const paginationProps: ICoherencePaginationProps = {
    pageCount: pageCount === 0 ? 1 : pageCount,
    selectedPage,
    previousPageAriaLabel: 'previous page',
    nextPageAriaLabel: 'next page',
    inputFieldAriaLabel: 'page number text box disabled',
    isIndexed: false,
    onPageChange,
    canJumpToPage: false,
  };

  const paginationPageSizeProps: ICoherencePageSizeProps = {
    pageSize,
    pageSizeList: [
      { key: 5, text: '5' },
      { key: 15, text: '15' },
      { key: 25, text: '25' },
      { key: 50, text: '50' },
    ] as IComboBoxNumberOption[],
    comboBoxAriaLabel: 'select page size',
    onPageSizeChange,
  };

  return (
    <>
      {!loadingData && (
        <CoherencePagination {...paginationProps} pageSize={paginationPageSizeProps} />
      )}
      {loadingData && (
        <Spinner
          styles={{ root: { padding: '15px 0' } }}
          label="Loading more results"
          ariaLive="assertive"
          labelPosition="right"
        />
      )}
    </>
  );
};

export default DetailListPaginationBanner;
