import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import {
  GET_FUNCTIONAL_CURRENCY,
  UPDATE_FUNCTIONAL_CURRENCY_CASHFLOW,
} from '../../../../utils/statApi/CompanyCreationApi';

import ActionButtons from '../../../common/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import ICashFlow from '../types';
import CashFlowForm from './CashFlowForm';

interface ICashFlowEditPanelProps {
  closePanel: () => void;
  data: ICashFlow;
  companyCreationId: number;
}

const CashFlowEditPanel = ({
  closePanel,
  data,
  companyCreationId,
}: ICashFlowEditPanelProps): JSX.Element => {
  const cashFlowForm = useForm<ICashFlow>({
    defaultValues: {
      ...data,
    },
  });

  const { handleSubmit } = cashFlowForm;

  const [updateCashFlow, { loading, error }] = useMutation(UPDATE_FUNCTIONAL_CURRENCY_CASHFLOW, {
    onCompleted: closePanel,
    refetchQueries: [
      {
        query: GET_FUNCTIONAL_CURRENCY,
        variables: {
          companyCreationId,
        },
      },
    ],
  });

  const handleOnSaveClick = (formData: ICashFlow): void => {
    const cashFlowAffectsParent = formData.cashFlowAffectsParent
      ? formData.cashFlowAffectsParent === 'true'
      : null;

    updateCashFlow({
      variables: {
        input: {
          companyCreationRequestId: companyCreationId,
          cashFlowCurrency: formData.cashFlowCurrency,
          cashFlowCurrencyNotes: formData.cashFlowCurrencyNotes,
          cashFlowAffectsParent,
          cashFlowAffectsParentNotes: formData.cashFlowAffectsParentNotes,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Cash Flows"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Edit Cash Flows Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Cash Flows"
          saveTitle="Save Cash Flows"
          cancelLabel="Cancel Cash Flows Edit"
          cancelTitle="Cancel Cash Flows Edit"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <CashFlowForm form={cashFlowForm} data={data} />
      </form>
    </CoherencePanel>
  );
};

export default CashFlowEditPanel;
