import { TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { IMilestoneCategory } from '../../../../utils/types/IProcessTemplate';

interface IMilestoneCategoriesFormProps {
  category?: IMilestoneCategory;
  form: UseFormMethods<IMilestoneCategory>;
}

const MilestoneCategoriesForm = (props: IMilestoneCategoriesFormProps): JSX.Element => {
  const { category, form } = props;
  const { control, errors } = form;

  return (
    <Controller
      as={TextField}
      label="Name"
      id="name"
      name="name"
      control={control}
      resizable={false}
      defaultValue={category?.name}
      errorMessage={errors?.name?.message}
      required
      rules={{
        maxLength: {
          value: 200,
          message: 'Input is greater than the maximum character limit of 200.',
        },
        required: 'Please enter a name',
      }}
    />
  );
};

export default MilestoneCategoriesForm;
