export default class FeatureFlags {
  flags: Flags = {};

  outageBannerMessage = '';

  currentUiVersion = '';

  constructor(payload?: FeatureFlagResponse) {
    if (payload) {
      this.flags = JSON.parse(payload.flags);
      this.outageBannerMessage = payload.outageBannerMessage;
      this.currentUiVersion = payload.currentUiVersion;
    }
  }
}

interface FeatureFlagResponse {
  flags: string;
  outageBannerMessage: string;
  currentUiVersion: string;
}

interface Flags {
  [key: string]: boolean;
}
