import React, { FunctionComponent, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { PrimaryButton } from '@fluentui/react';
import { Link } from 'react-router-dom';
import {
  COMPANY_GROUP_LIST_QUERY,
  CREATE_COMPANY_GROUP_MUTATION,
} from '../../../utils/statApi/CompanyGroupsApi';
import getColumns from './CompanyGroupList.config';
import Subheader from '../../common/headers/Subheader';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyGroup } from '../../../utils/types/ICompanyGroups';
import PageHeaderWithActionButtons from '../../common/headers/PageHeaderWithActionButtons';
import CompanyGroupEditPanel from '../creation/CompanyGroupEditPanel';
import CanAccess from '../../common/canAccess/CanAccess';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';

const CompanyGroupList: FunctionComponent = () => {
  const { loading, data, error } = useQuery(COMPANY_GROUP_LIST_QUERY);
  const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);
  const { companyGroups } = data || {};
  const newCompanyGroup: ICompanyGroup = {
    id: -1,
    name: '',
    description: '',
    companyGroupsStatUsers: [],
    companyGroupCompanies: [],
  };

  const handleAddButtonClick = (): void => {
    setIsAddPanelOpen(true);
  };

  const handleCloseClick = (): void => {
    setIsAddPanelOpen(false);
  };

  const renderHeader = (): JSX.Element => {
    const button = (
      <CanAccess requestedAction="CompanyGroup:Add">
        <PrimaryButton text="Add New Group" onClick={handleAddButtonClick} />
      </CanAccess>
    );

    return <PageHeaderWithActionButtons title="Company Group Management" actionButtons={button} />;
  };

  const renderSubtitle = (): JSX.Element => <Subheader title="Company groups" />;

  // main column link
  const renderCompanyGroupNameLink = (item: ICompanyGroup): JSX.Element => (
    <Link to={`/companygroups/${item.id}`}>{item.name}</Link>
  );

  const columns = getColumns({
    renderCompanyGroupNameLink,
  });

  const onSaveSuccess = (): void => {
    setIsAddPanelOpen(false);
  };

  const [createCompanyGroup, { loading: mutationLoading, error: mutationError }] = useMutation(
    CREATE_COMPANY_GROUP_MUTATION,
    {
      refetchQueries: [COMPANY_GROUP_LIST_QUERY],
      onCompleted: onSaveSuccess,
    },
  );

  const handleSaveClick = ({
    id,
    name,
    description,
    companyGroupsStatUsers,
    companyGroupCompanies,
  }: ICompanyGroup): void => {
    createCompanyGroup({
      variables: {
        companyGroup: {
          id,
          name,
          description,
          companyGroupsStatUsers,
          companyGroupCompanies,
        },
      },
    });
  };

  return (
    <>
      <FullWidthHeader title={renderHeader} subtitle={renderSubtitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="loading the company group data"
        />

        <ShimmeredDetailsListWrapper
          columns={columns}
          items={companyGroups || []}
          enableShimmer={loading}
          singularListLabel="Company Group"
          pluralListLabel="Company Groups"
        />
      </div>
      {isAddPanelOpen && (
        <CompanyGroupEditPanel
          mutationLoading={mutationLoading}
          companyGroup={newCompanyGroup}
          onSaveClick={handleSaveClick}
          closePanel={handleCloseClick}
        >
          <LoadingErrorMessage
            loading={mutationLoading}
            error={mutationError}
            actionName="creating the company group data"
          />
        </CompanyGroupEditPanel>
      )}
    </>
  );
};

export default CompanyGroupList;
