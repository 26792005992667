import { mergeStyles } from '@fluentui/react';

export const multiDropdownSmallWrapper = mergeStyles({
  minWidth: 200,
  maxWidth: 200,
});

export const multiDropdownMediumWrapper = mergeStyles({
  minWidth: 250,
  maxWidth: 250,
});

export const multiDropdownLargeWrapper = mergeStyles({
  minWidth: 310,
  maxWidth: 310,
});
