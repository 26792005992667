import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import { GET_PROCESS_TEMPLATE_BY_TYPE_FOR_DROPDOWN } from '../../../../utils/statApi/ProcessTemplateApi';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IProcessConfig from '../../../../utils/types/IProcessConfig';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';

interface IProcessTemplateDropdownProps {
  processTypeId: number;
  errors: DeepMap<IProcessConfig, FieldError>;
  selectedKey: IDropdownReference;
  handleChange: (newValue: IDropdownReference) => void;
}
const ProcessTemplateDropdown = (props: IProcessTemplateDropdownProps): JSX.Element => {
  const { processTypeId, errors, selectedKey, handleChange } = props;
  const [getProcessTemplates, { loading, data, error: errorProcessTemplateQuery }] = useLazyQuery(
    GET_PROCESS_TEMPLATE_BY_TYPE_FOR_DROPDOWN,
  );

  useEffect(() => {
    if (processTypeId) {
      const parameter = { variables: { processTypeId, isActiveFilter: true } };
      getProcessTemplates(parameter);
    }
  }, [processTypeId]);

  const { processTemplatesByType } = !loading && data ? data : [];

  return (
    <>
      <LoadingErrorMessage
        loading={loading}
        error={errorProcessTemplateQuery}
        actionName="loading the process template data"
      />
      <QueryBasedDropdown
        label="Process Template"
        value={selectedKey}
        valueKey="processTemplate"
        dropdownOptionsData={processTemplatesByType}
        handleChange={handleChange}
        errors={errors}
        width="100%"
        required
        disabled={!processTypeId}
      />
    </>
  );
};
export default ProcessTemplateDropdown;
