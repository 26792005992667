import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { mergeStyles, NeutralColors, Stack } from '@fluentui/react';
import { MilestoneStatus } from '../Interfaces';
import MilestoneCardHeader from '../MilestoneCardHeader';
import MilestoneCardActions from '../MilestoneCardActions';
import { CollapseAllStatus } from '../ProcessDetail';

interface IMilestoneCardProps {
  title: string;
  children: React.ReactNode;
  actionText: string;
  commentText: string;
  status: MilestoneStatus;
  disabled: boolean;
  complete: boolean;
  collapseAllStatus: CollapseAllStatus;
  icon: string;
  iconClick: () => void;
  showProcessExtensionWarning: boolean;
  setShowComments: Dispatch<SetStateAction<boolean>>;
}
const completedCardBg = NeutralColors.gray20;
const pendingBg = NeutralColors.white;

const MilestoneCard: React.FunctionComponent<IMilestoneCardProps> = ({
  title,
  children,
  actionText,
  commentText,
  disabled = false,
  complete = false,
  collapseAllStatus,
  icon,
  iconClick,
  showProcessExtensionWarning,
  status,
  setShowComments,
}: IMilestoneCardProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(complete);

  useEffect(() => {
    if (collapseAllStatus !== CollapseAllStatus.DEFAULT) {
      setIsCollapsed(collapseAllStatus === CollapseAllStatus.EXPAND);
    }
  }, [collapseAllStatus]);

  useEffect(() => {
    setIsCollapsed(complete);
  }, [complete]);

  const isNotStarted = (): boolean => {
    return status === MilestoneStatus.notStarted;
  };
  const isCurrent = (): boolean => {
    return status === MilestoneStatus.ready;
  };

  const getCardWrapperStyles = mergeStyles({
    marginBottom: 20,
    backgroundColor: complete ? completedCardBg : pendingBg,
    position: 'relative',
    transition: 'background 300ms ease-in',
    selectors: {
      '&:hover': {
        boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.2), 0 0.3px 0.9px 0 rgba(0,0,0,.25)',
      },
    },
  });
  const cardBodyWrapperStyles = mergeStyles({
    padding: 15,
  });
  const mobileCardActionsWrapperStyles = { root: { marginTop: 30 } };

  return (
    <div className={`ms-depth-4 ${getCardWrapperStyles}`}>
      <MilestoneCardHeader
        setShowComments={setShowComments}
        isCurrent={isCurrent()}
        isNotStarted={isNotStarted()}
        isComplete={complete}
        isDisabled={disabled}
        onHeaderClick={setIsCollapsed}
        iconClick={iconClick}
        contentIsCollapsed={isCollapsed}
        icon={icon}
        status={status}
        actionText={actionText}
        showProcessExtensionWarning={showProcessExtensionWarning}
        title={title}
        commentText={commentText}
      />
      {!isCollapsed && (
        <div className={`${cardBodyWrapperStyles}`}>
          {children}
          <Stack className="ms-hiddenLgUp" styles={mobileCardActionsWrapperStyles}>
            <MilestoneCardActions
              setShowComments={setShowComments}
              isCurrent={false}
              isNotStarted={isNotStarted()}
              isDisabled={disabled}
              onHeaderClick={setIsCollapsed}
              iconClick={iconClick}
              contentIsCollapsed={isCollapsed}
              showProcessExtensionWarning={showProcessExtensionWarning}
              icon={icon}
              status={status}
              actionText={actionText}
              commentText={commentText}
              title={title}
            />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default MilestoneCard;
