import { IColumn, ColumnActionsMode } from '@fluentui/react';

interface IInvalidServiceContactsListColumnParams {
  renderInvalidServiceCompanyCodeLink: (item: { name: React.ReactNode }) => JSX.Element;
}
const getColumns = ({
  renderInvalidServiceCompanyCodeLink,
}: IInvalidServiceContactsListColumnParams): Array<IColumn> => {
  return [
    {
      key: 'company',
      name: 'Company',
      fieldName: 'company',
      minWidth: 250,
      maxWidth: 400,
      isResizable: false,
      onRender: renderInvalidServiceCompanyCodeLink,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'contactType',
      name: 'Contact Type',
      fieldName: 'contactType',
      minWidth: 175,
      maxWidth: 175,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'name',
      name: 'Full Name',
      fieldName: 'name',
      minWidth: 250,
      maxWidth: 250,
      isResizable: false,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'alias',
      name: 'User Alias',
      fieldName: 'alias',
      minWidth: 180,
      maxWidth: 180,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'entityStatus',
      name: 'Entity Status',
      fieldName: 'entityStatus',
      minWidth: 200,
      maxWidth: 200,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};
export default getColumns;
