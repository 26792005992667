import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { ITag, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React, { PropsWithChildren, ReactElement, useState } from 'react';

import GenericFilterTagItems from './GenericFilterTagItems';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import ActionButtons from '../ActionButtons';

type IListFiltersProps = PropsWithChildren<{
  onClosePanel?: () => void;
  currentFilterValues?: IGenericFilterTag[];
  onFilterUpdate?: (formData: IGenericFilterTag[]) => void;
}>;

const ListFilters = (props: IListFiltersProps): JSX.Element => {
  const { onFilterUpdate, onClosePanel, currentFilterValues, children } = props;

  const [filterState, setFilterValues] = useState<{ items: IGenericFilterTag[] }>({
    items: currentFilterValues,
  });
  const handleOnSaveClick = () => {
    onFilterUpdate(filterState.items);
  };

  const getChildFilters = (parentDataKey: string): IGenericFilterTag[] => {
    return filterState?.items?.filter((filter) => filter?.parentDataKey === parentDataKey);
  };

  const getUpdatedFilters = (filterValues: IGenericFilterTag[]): IGenericFilterTag[] => {
    let filters: IGenericFilterTag[] = structuredClone(filterState.items ?? []);

    const updateFilters = (filterValue: IGenericFilterTag) => {
      // Get any associated child filters for this filter
      const childFilters = getChildFilters(filterValue?.dataKey);

      // Recursively update any child filters
      childFilters?.forEach((childFilter) => {
        childFilter.values.length = 0;
        updateFilters(childFilter);
      });

      const indexOfValue = filters?.findIndex((item) => item.dataKey === filterValue.dataKey);
      if (indexOfValue === -1) {
        // Add new filter
        filters = filters.concat([filterValue]);
      } else {
        // Update previous filter
        filters.splice(indexOfValue, 1, {
          dataKey: filters[indexOfValue].dataKey,
          title: filters[indexOfValue].title,
          hideFromBar: filters[indexOfValue].hideFromBar,
          parentDataKey: filters[indexOfValue].parentDataKey,
          values: filterValue.values,
        });
      }
    };

    // For each filter tag update it and append it to the list of tags
    filterValues.forEach((filterValue) => {
      updateFilters(filterValue);
    });
    return filters;
  };

  const onFilterItemUpdate = (filterValue: IGenericFilterTag) => {
    const updatedFilters = getUpdatedFilters([filterValue]);
    setFilterValues({ items: updatedFilters });
  };

  const onFilterItemsUpdate = (filterValues: IGenericFilterTag[]) => {
    // Get list of updated filters and update state all at once
    const updatedFilters = getUpdatedFilters(filterValues);
    setFilterValues({ items: updatedFilters });
  };

  const getFilterByKey = (filterKey: string) => {
    const filterIndex = filterState?.items?.findIndex((f) => f.dataKey === filterKey);
    return filterState?.items[filterIndex];
  };

  const onFilterTagsChange = (filterTagKey: string, filterTagItems: ITag[]): void => {
    const tag: IGenericFilterTag = {
      title: filterTagKey,
      dataKey: filterTagKey,
      values: filterTagItems,
    };
    onFilterItemUpdate(tag);
  };

  const onClearAllFilters = (): void => {
    setFilterValues({ items: [] });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Filters"
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Filters"
      onRenderFooter={(): JSX.Element => (
        <Stack>
          <ActionButtons
            closePanel={onClosePanel}
            handleSubmit={handleOnSaveClick}
            saveLabel="Done"
            saveTitle="Done"
            cancelLabel="Cancel"
            cancelTitle="Cancel"
          />
        </Stack>
      )}
    >
      {!children && (
        <MessageBar messageBarType={MessageBarType.warning}>
          <Stack tokens={{ childrenGap: 10 }}>
            <span>There are currently no filters for this view</span>
          </Stack>
        </MessageBar>
      )}
      {filterState?.items?.length > 0 && (
        <GenericFilterTagItems
          filterTagItems={filterState?.items}
          onFilterTagsChange={onFilterTagsChange}
          onClearAllFilters={onClearAllFilters}
        />
      )}
      {React.Children?.map(children, (child: ReactElement) => {
        const filterTagsIndex = filterState?.items?.findIndex(
          (fsItem) => fsItem.dataKey === child?.props?.dataKey,
        );
        let filterItem: IGenericFilterTag = null;
        let filterItems: IGenericFilterTag[] = null;

        if (child?.props?.dataKey) {
          filterItem = {
            dataKey: child?.props?.dataKey,
            title: child?.props?.label,
            values: filterState?.items ? filterState?.items[filterTagsIndex]?.values : null,
          };
        }

        if (child?.props?.dataKeys) {
          const filterItemsArray = (child.props.dataKeys as string[]).map((dataKey) => {
            const filterTagsIndexLocal = filterState?.items?.findIndex(
              (fsItem) => fsItem.dataKey === dataKey,
            );
            return {
              dataKey,
              title: dataKey,
              values: filterState?.items[filterTagsIndexLocal]?.values,
            };
          });
          filterItems = filterItemsArray;
        }

        return React.cloneElement(child, {
          onSelectedItems: onFilterItemUpdate,
          onMultipleFiltersUpdate: onFilterItemsUpdate,
          getFilterByKey,
          initialValues: filterItems || filterItem,
        });
      })}
    </CoherencePanel>
  );
};
export default ListFilters;
