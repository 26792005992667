import { gql } from '@apollo/client';

const COMPLIANCE_REQUIREMENT_FRAGMENT = gql`
  fragment ComplianceRequirement on ComplianceRequirementDto {
    id
    name
    comments
    filingDueDateDay
    filingDueDateMonth
    filingPreparationCompany {
      id
      fieldName
    }
    filingType {
      id
      fieldName
    }
    filingFrequency {
      id
      fieldName
    }
    updatedDate
    taxRates {
      id
      taxDescription
      rate
      effectiveDate
      taxType {
        id
        fieldName
      }
    }
  }
`;

const COUNTRY_CONFIGURATION_FRAGMENT = gql`
  fragment CountryConfiguration on CountryConfigurationDto {
    id
    country {
      id
      shortName
    }
    monthsToFileFromReportingPeriodEnd
    workWeek {
      id
      fieldName
    }
    alignAgmWithFinancialStatement
    electronicSignatureAcceptable
    taxReturnDueDateMonth
    taxReturnDueDateDay
    taxReportingPeriodDateMonth
    taxReportingPeriodDateDay
    externalFilingRequired
    complianceRequirements {
      ...ComplianceRequirement
    }
  }
  ${COMPLIANCE_REQUIREMENT_FRAGMENT}
`;

export const GET_COUNTRY_CONFIGS = gql`
  query getCountryConfigurations {
    countryConfigurations {
      id
      country {
        id
        shortName
      }
    }
  }
`;

export const GET_COUNTRY_CONFIG_DETAILS = gql`
  query getCountryConfigurationDetails($countryConfigId: Int!) {
    countryConfigurationDetails(countryConfigId: $countryConfigId) {
      ...CountryConfiguration
    }
  }
  ${COUNTRY_CONFIGURATION_FRAGMENT}
`;

export const GET_WORK_WEEK_CONFIGURATIONS = gql`
  query getWorkWeekConfigurations {
    workWeekConfigurations {
      id
      fieldName
    }
  }
`;

export const GET_TAX_TYPES = gql`
  query getTaxTypes {
    taxTypes {
      id
      fieldName
    }
  }
`;

export const GET_FILLING_FREQUENCIES = gql`
  query getFilingFrequencies {
    filingFrequencies {
      id
      fieldName
    }
  }
`;

export const GET_FILING_PREPARATION_COMPANIES = gql`
  query getFilingPreparationCompanies {
    filingPreparationCompanies {
      id
      fieldName
    }
  }
`;

export const GET_COMPLIANCE_REQUIREMENTS = gql`
  query getComplianceRequirements($keyword: String!) {
    complianceRequirements(keyword: $keyword) {
      ...ComplianceRequirement
    }
  }
  ${COMPLIANCE_REQUIREMENT_FRAGMENT}
`;

export const GET_COUNTRIES_BY_SEARCH_INPUT = gql`
  query getCountries($keyword: String) {
    countries(keyword: $keyword) {
      id
      shortName
    }
  }
`;
export const GET_COUNTRIES_BY_CODE = gql`
  query getCountriesByCode($countryCodes: [String!]) {
    countriesByCode(countryCodes: $countryCodes) {
      id
      shortName
    }
  }
`;
export const CREATE_COMPLIANCE_REQUIREMENT = gql`
  mutation createComplianceRequirement($complianceRequirement: ComplianceRequirementDtoInput!) {
    createComplianceRequirement(complianceRequirement: $complianceRequirement) {
      ...ComplianceRequirement
    }
  }
  ${COMPLIANCE_REQUIREMENT_FRAGMENT}
`;

export const UPDATE_COMPLIANCE_REQUIREMENT = gql`
  mutation updateComplianceRequirement($complianceRequirement: ComplianceRequirementDtoInput!) {
    updateComplianceRequirement(complianceRequirement: $complianceRequirement) {
      ...ComplianceRequirement
    }
  }
  ${COMPLIANCE_REQUIREMENT_FRAGMENT}
`;

export const DELETE_COMPLIANCE_REQUIREMENT = gql`
  mutation deleteComplianceRequirement($complianceRequirementId: Int!) {
    deleteComplianceRequirement(complianceRequirementId: $complianceRequirementId) {
      ...ComplianceRequirement
    }
  }
  ${COMPLIANCE_REQUIREMENT_FRAGMENT}
`;

export const CREATE_COUNTRY_CONFIGURATION = gql`
  mutation createCountryConfiguration($countryConfiguration: CountryConfigurationDtoInput!) {
    createCountryConfiguration(countryConfiguration: $countryConfiguration) {
      ...CountryConfiguration
    }
  }
  ${COUNTRY_CONFIGURATION_FRAGMENT}
`;

export const UPDATE_COUNTRY_CONFIGURATION = gql`
  mutation updateCountryConfiguration($countryConfiguration: CountryConfigurationDtoInput!) {
    updateCountryConfiguration(countryConfiguration: $countryConfiguration) {
      ...CountryConfiguration
    }
  }
  ${COUNTRY_CONFIGURATION_FRAGMENT}
`;
