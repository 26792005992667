import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  UPDATE_STATUTORY_INFORMATION,
} from '../../../utils/statApi/CompanyCreationApi';

import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import StatutoryInformationForm from './StatutoryInformationForm';
import { IStatutoryInformation } from './types';

interface IStatutoryInformationPanelProps {
  closePanel: () => void;
  data: IStatutoryInformation;
  companyCreationId: number;
}

const StatutoryInformationPanel = ({
  closePanel,
  data,
  companyCreationId,
}: IStatutoryInformationPanelProps): JSX.Element => {
  const form = useForm<IStatutoryInformation>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateStatutoryInfo, { loading, error }] = useMutation(UPDATE_STATUTORY_INFORMATION, {
    onCompleted: closePanel,
    refetchQueries: [
      {
        query: GET_COMPANY_CREATION_COMPANY_INFO,
        variables: {
          companyCreationId,
        },
      },
      {
        query: GET_COMPANY_CREATION_PROGRESS,
        variables: {
          companyCreationId,
        },
      },
    ],
  });

  const handleOnSaveClick = (formData: IStatutoryInformation): void => {
    const oversightGroup = formData.oversightGroup
      ? {
          id: formData.oversightGroup.id,
          fieldName: formData.oversightGroup.fieldName,
        }
      : null;
    const auditingFirm = formData.auditingFirm
      ? {
          id: formData.auditingFirm.id,
          fieldName: formData.auditingFirm.fieldName,
        }
      : null;

    updateStatutoryInfo({
      variables: {
        input: {
          id: formData.id || -1,
          companyCreationRequestId: companyCreationId,
          oversightGroup,
          isMcaps: formData.isMcaps,
          isOneFinance: formData.isOneFinance,
          auditingFirm,
          otherAuditingFirm: formData.otherAuditingFirm || '',
          isFsAuditLegallyRequired: formData.isFsAuditLegallyRequired,
          einTaxNumber: formData.einTaxNumber || '',
          controllerMustBeFte: formData.controllerMustBeFte,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Statutory Information"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit Statutory Info Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Statutory Info"
          saveTitle="Save Statutory Info"
          cancelLabel="Cancel Statutory Info Edit"
          cancelTitle="Cancel Statutory Info Edit"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <StatutoryInformationForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default StatutoryInformationPanel;
