import {
  canGoToPreviousStep,
  canGoToNextStep,
  getNextStep,
  goToPreviousStep,
  SubwayNavNodeState,
  goToNextStep,
  ISubwayNavNodeProps,
} from '@m365-admin/wizard';
import { Stack, PrimaryButton } from '@fluentui/react';
import React from 'react';
import ActionButtons from '../../../common/ActionButtons';

interface IProcessConfigWizardFooterProps {
  currentNavStepId: string;
  setCurrentNavStepId: React.Dispatch<React.SetStateAction<string>>;
  navSteps: ISubwayNavNodeProps[];
  setNavSteps: React.Dispatch<React.SetStateAction<ISubwayNavNodeProps[]>>;
  closePanel?: () => void;
  disableSaveButton: boolean;
  loading: boolean;
  handleSubmit?: () => void;
  buttonText: string;
  cancelText: string;
}
const ProcessConfigWizardFooter = (props: IProcessConfigWizardFooterProps): JSX.Element => {
  const {
    currentNavStepId,
    setCurrentNavStepId,
    navSteps,
    setNavSteps,
    closePanel,
    handleSubmit,
    disableSaveButton,
    loading,
    buttonText,
    cancelText,
  } = props;
  const canGoBack = canGoToPreviousStep(navSteps);
  const canGoForward = canGoToNextStep(navSteps);

  const isNextStepDisabled = () => {
    const nextStep = getNextStep(navSteps, currentNavStepId);
    return nextStep?.destinationStep?.disabled;
  };
  return (
    <Stack horizontal verticalAlign="center" grow tokens={{ childrenGap: 5 }}>
      <Stack horizontal>
        {canGoBack && (
          <PrimaryButton
            onClick={() => {
              const returnStepsBack = goToPreviousStep(navSteps, SubwayNavNodeState.Unsaved);
              setNavSteps(returnStepsBack.steps);
              setCurrentNavStepId(returnStepsBack.newCurrentStep.id);
            }}
          >
            Back
          </PrimaryButton>
        )}
        {canGoForward && (
          <PrimaryButton
            disabled={isNextStepDisabled()}
            onClick={() => {
              const returnStepsNext = goToNextStep(navSteps, SubwayNavNodeState.Completed);
              setNavSteps(returnStepsNext.steps);
              setCurrentNavStepId(returnStepsNext.newCurrentStep.id);
            }}
          >
            Next
          </PrimaryButton>
        )}
      </Stack>
      <Stack horizontalAlign="end" grow>
        <ActionButtons
          closePanel={closePanel}
          mutationLoading={loading}
          handleSubmit={handleSubmit}
          saveTitle={buttonText}
          saveLabel={buttonText}
          cancelTitle={cancelText}
          cancelLabel={cancelText}
          disabled={disableSaveButton}
        />
      </Stack>
    </Stack>
  );
};
export default ProcessConfigWizardFooter;
