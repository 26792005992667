import React, { useState, useEffect } from 'react';
import { Stack, TextField, Dropdown, IDropdownOption } from '@fluentui/react';
import { useQuery, ApolloError } from '@apollo/client';
import { StatUser } from '../../utils/statApi/UsersApi';
import { sideBySideWrapper, sideBySideFormFields } from './UsersStyles';
import GET_ROLES_QUERY, { Role } from '../../utils/statApi/RolesApi';
import SpanWithLabel from '../common/SpanWithLabel';
import momentUtc from '../../utils/DateFormatter';
import StatDateFormats from '../../utils/types/DateFormats';

interface IPrimaryFormFieldProps {
  statUser: StatUser;
  userRole: number;
  alias: string;
  onSetUserRole: (roleId: number) => void;
}

const PrimaryFormFields = (props: IPrimaryFormFieldProps): JSX.Element => {
  const { statUser, userRole, alias, onSetUserRole } = props;
  const {
    error,
    loading,
    data,
  }: { error?: ApolloError; loading: boolean; data: { role: Role[] } } = useQuery(GET_ROLES_QUERY);

  const status = statUser.isActive ? 'Active' : 'Deactivated';
  const userLastLoginDate = momentUtc(statUser.lastLoginDate, StatDateFormats.DaySimple, true);

  const selectedRole = userRole;
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (!loading && !error && data) {
      const options: IDropdownOption[] = data.role.map((role) => {
        return { key: role.id, text: role.name };
      });
      options.unshift({ key: null, text: '--' });
      setUserRoles(options);
    }
  }, [loading]);

  return (
    <Stack horizontal wrap tokens={sideBySideWrapper}>
      <Stack.Item styles={sideBySideFormFields}>
        <TextField id="alias" label="User Alias" defaultValue={alias} disabled />
      </Stack.Item>
      <Stack.Item styles={sideBySideFormFields}>
        <TextField id="isActive" label="Status" defaultValue={status} disabled />
      </Stack.Item>
      <Stack.Item styles={sideBySideFormFields}>
        <Dropdown
          placeholder="Select a role"
          label="Global Role"
          options={userRoles}
          selectedKey={selectedRole}
          onChange={(element, option): void => {
            onSetUserRole(option.key as number);
          }}
          required
        />
      </Stack.Item>
      <Stack.Item styles={sideBySideFormFields}>
        <SpanWithLabel labelText="Last Login:" value={userLastLoginDate} />
      </Stack.Item>
    </Stack>
  );
};
export default PrimaryFormFields;
