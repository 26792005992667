import React from 'react';
import { DatePicker, defaultDatePickerStrings, ITextFieldProps } from '@fluentui/react';
import moment from 'moment';

export interface IUtcDatePickerProps {
  id?: string;
  label?: string;
  ariaLabel?: string;
  className?: string;
  textField?: ITextFieldProps;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  allowTextInput?: boolean;
  isRequired?: boolean;
  disableAutoFocus?: boolean;
  value?: string | Date;
  onSelectDate?: (utcDateString: string) => void;
  isRequiredErrorMessage?: string;
}

const UtcDatePicker: React.FunctionComponent<IUtcDatePickerProps> = ({
  value,
  onSelectDate,
  ...props
}: IUtcDatePickerProps): JSX.Element => {
  const utcDateValue = value ? new Date(moment(value.toString().split('T')[0]).format()) : null;
  const handleSelectDate = (selectedDate: Date): void => {
    if (selectedDate === null) {
      onSelectDate(null);
      return;
    }
    const selectedDateString = moment(selectedDate).format();

    const formattedDateString = `${selectedDateString.split('T')[0]}T00:00:00.000Z`;

    onSelectDate(formattedDateString);
  };

  const {
    id,
    ariaLabel,
    label,
    className,
    textField,
    disabled,
    minDate,
    maxDate,
    allowTextInput,
    isRequired,
    disableAutoFocus,
    isRequiredErrorMessage,
  } = props;
  const utcDateStrings = defaultDatePickerStrings;
  if (isRequiredErrorMessage) {
    utcDateStrings.isRequiredErrorMessage = isRequiredErrorMessage;
  }
  return (
    <DatePicker
      id={id}
      ariaLabel={ariaLabel}
      label={label}
      className={className}
      textField={textField}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      allowTextInput={allowTextInput}
      isRequired={isRequired}
      value={utcDateValue}
      onSelectDate={handleSelectDate}
      disableAutoFocus={disableAutoFocus}
      strings={utcDateStrings}
    />
  );
};

export default UtcDatePicker;
