import React from 'react';
import { pageHeaderFullWidth } from './Headers.styles';

interface IPageHeaderProps {
  title: () => JSX.Element;
  subtitle?: () => JSX.Element;
}

const FullWidthHeader: React.FunctionComponent<IPageHeaderProps> = (props: IPageHeaderProps) => {
  const { title, subtitle } = props;
  const Styles = pageHeaderFullWidth();
  return (
    <div className={`${Styles.pageHeaderWrapper}`}>
      <div className={Styles.pageHeaderTitle}>{title()}</div>
      {subtitle && <div className={Styles.pageSubheaderWrapper}>{subtitle()}</div>}
    </div>
  );
};

export default FullWidthHeader;
