import React, { FunctionComponent } from 'react';
import { IColumn, Stack, Icon, FontSizes } from '@fluentui/react';
import { Link, useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import getColumns from './ProcessList.config';
import { IProcessInstance } from '../../../utils/types/IProcessInstance';
import StatDateFormats from '../../../utils/types/DateFormats';
import ProcessStatus from '../../../utils/types/ProcessStatus';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import momentUtc from '../../../utils/DateFormatter';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import { IProcessTypeParams } from '../../common/ParamTypes';
import { MessagingColors } from '../../../app/common/styles/CommonStyleObjects';

interface IProcessListProps {
  processInstances: IProcessInstance[];
  processListName: string;
  error: ApolloError; // make sure to add the message to error
  loading: boolean;
}

const ProcessList: FunctionComponent<IProcessListProps> = (props: IProcessListProps) => {
  const { processInstances, error, loading, processListName } = props;
  const { companyCode, processTypeUri } = useParams<IProcessTypeParams>();
  const renderItemColumn = (
    item: IProcessInstance,
    index: number,
    column: IColumn,
  ): JSX.Element => {
    const propertyKey = column.fieldName as keyof IProcessInstance;
    const fieldContent = item[propertyKey];
    const isComplete = item.status === ProcessStatus.Complete;
    switch (column.key) {
      case 'processConfigTitle': {
        return (
          <Stack horizontal verticalAlign="center">
            <Icon
              iconName={isComplete ? 'SkypeCircleCheck' : ''}
              styles={{
                root: {
                  color: MessagingColors.successIcon,
                  fontSize: FontSizes.size20,
                  marginRight: 20,
                  minHeight: 22,
                },
              }}
            />
            <span>
              {`${momentUtc(item.processStartDate, StatDateFormats.DaySimple)} - ${fieldContent}`}
            </span>
          </Stack>
        );
      }
      case 'processStartDate': {
        return <span>{`${momentUtc(fieldContent, StatDateFormats.DayBreakout)}`}</span>;
      }
      case 'processEndDate': {
        return <span>{`${momentUtc(fieldContent, StatDateFormats.DayBreakout)}`}</span>;
      }

      default:
        return <span>{fieldContent}</span>;
    }
  };

  // main column link
  const renderProcessTitleLink = (item: IProcessInstance): JSX.Element => (
    <Link to={`/companies/${companyCode}/processes/${processTypeUri}/${item.id}`}>
      {item.processConfigTitle}
    </Link>
  );
  const columns = getColumns({
    renderProcessTitleLink,
  });

  return (
    <>
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading the process list data"
      />
      <ShimmeredDetailsListWrapper
        columns={columns}
        gridAriaLabelOverride={` Processes for ${processListName}`}
        items={processInstances || []}
        enableShimmer={loading}
        onRenderItemColumn={renderItemColumn}
        singularListLabel="Process"
        pluralListLabel="Processes"
      />
    </>
  );
};

export default ProcessList;
