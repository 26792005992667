import { DocumentNode, useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React, { useEffect, useState } from 'react';
import { DeepPartial, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import { GET_PROCESS_TYPE_WITH_MILESTONE_POOL } from '../../../../utils/statApi/ProcessTypesApi';

import { IMilestoneTemplate } from '../../../../utils/types/IMilestoneTemplate';
import ActionButtons from '../../../common/ActionButtons';
import CanAccess from '../../../common/canAccess/CanAccess';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IProcessTemplateTypeParams } from '../../../common/ParamTypes';
import MilestoneTemplateForm from './MilestoneTemplateForm';

interface IManageMilestoneTemplatePanelProps {
  MILESTONE_TEMPLATE_MUTATION: DocumentNode;
  milestoneTemplate?: IMilestoneTemplate;
  closePanel: () => void;
}

const ManageMilestoneTemplatePanel = (props: IManageMilestoneTemplatePanelProps): JSX.Element => {
  const { milestoneTemplate, closePanel, MILESTONE_TEMPLATE_MUTATION } = props;

  const { processTypeId } = useParams<IProcessTemplateTypeParams>();
  const processTypeIdInt = parseInt(processTypeId, 10);
  const form = useForm<IMilestoneTemplate>();
  const { errors, handleSubmit, formState, control } = form;
  const { isDirty, dirtyFields } = formState;
  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  const watchForm: DeepPartial<IMilestoneTemplate> = useWatch({
    control,
    defaultValue: milestoneTemplate as IMilestoneTemplate,
  });

  useEffect(() => {
    if (isDirty) {
      setHasBeenEdited(true);
    } else {
      setHasBeenEdited(false);
    }
  }, [dirtyFields, watchForm]);

  const [updateMilestoneTemplate, { loading: mutationLoading, error: mutationError }] = useMutation(
    MILESTONE_TEMPLATE_MUTATION,
    {
      onCompleted: () => {
        closePanel();
      },
      refetchQueries: [
        {
          query: GET_PROCESS_TYPE_WITH_MILESTONE_POOL,
          variables: { processTypeId: processTypeIdInt },
        },
      ],
    },
  );

  const handleOnSaveClick = (formData: IMilestoneTemplate): void => {
    const {
      name,
      description,
      attestationText,
      milestoneRules,
      signoffRoleIds,
      milestoneCategoryId,
    } = formData;

    const milestoneTemplateDto = {
      id: milestoneTemplate?.id || 0,
      name,
      description,
      attestationText,
      signoffRoleIds,
      milestoneRules,
      processTypePoolId: parseInt(processTypeId, 10),
      milestoneCategoryId,
    };
    if (!Object.keys(errors).length) {
      updateMilestoneTemplate({
        variables: {
          milestoneTemplateDto,
        },
      });
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={milestoneTemplate ? 'Edit Milestone' : 'Create Milestone'}
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close"
      onRenderFooter={() => (
        <CanAccess
          requestedAction={
            milestoneTemplate ? 'MilestoneTemplate:Edit' : 'MilestoneTemplate:Create'
          }
        >
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveLabel="Save Milestone Template"
            saveTitle="Save Milestone Template"
            cancelLabel="Cancel Milestone Template Save"
            cancelTitle="Cancel Milestone Template Save"
            disabled={!hasBeenEdited}
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="saving the milestone template information to the server"
        />
        <MilestoneTemplateForm
          milestoneTemplate={milestoneTemplate}
          form={form}
          processTypeId={processTypeIdInt}
        />
      </form>
    </CoherencePanel>
  );
};
export default ManageMilestoneTemplatePanel;
