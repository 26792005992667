import { DetailsListLayoutMode } from '@fluentui/react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';
import IItemListProps from '../../../utils/types/IItemListProps';
import { IProcessConfigListItem } from '../../../utils/types/IProcessConfig';
import CanAccess from '../../common/canAccess/CanAccess';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import getColumns from './ProcessConfigList.Config';

const ProcessConfigList = (
  processConfigListItemProps: IItemListProps<IProcessConfigListItem>,
): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { items, isLoading } = processConfigListItemProps;

  const renderNameColumn = (item: { id: number; name: string }): JSX.Element => {
    return (
      <CanAccess
        requestedAction="ProcessConfiguration:View"
        notAuthorizedChildren={<span>item.name</span>}
      >
        <Link to={`/companies/${companyCode}/processes/configs/${item.id}`}>{item.name}</Link>
      </CanAccess>
    );
  };

  const renderDateColumn = (item: { id: number; recurrenceEndDate: string }): JSX.Element => {
    return <span>{momentUtc(item.recurrenceEndDate, StatDateFormats.DayBreakout, true)}</span>;
  };

  const columns = getColumns({ renderNameColumn, renderDateColumn });

  return (
    <>
      {(isLoading || items) && (
        <ShimmeredDetailsListWrapper
          columns={columns}
          items={items || []}
          layoutMode={DetailsListLayoutMode.justified}
          enableShimmer={isLoading}
          singularListLabel="Process Configuration"
          pluralListLabel="Process Configurations"
        />
      )}
    </>
  );
};

export default ProcessConfigList;
