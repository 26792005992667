import { useQuery } from '@apollo/client';
import { IconButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import momentUtc from '../../../utils/DateFormatter';
import {
  APPROVE_PROCESS_EXTENSION,
  GET_PROCESS_EXTENSION_REQUEST,
  REJECT_PROCESS_EXTENSION,
  ATTACH_PROCESS_EXTENSION_REVIEW_DOCUMENT,
  CREATE_PROCESS_EXTENSION_REVIEW_COMMENT,
} from '../../../utils/statApi/ProcessExtensionApi';
import BackButton from '../../common/BackButton';
import CanAccess from '../../common/canAccess/CanAccess';
import Card from '../../common/card/Card';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import { IProcessExtensionIdParams } from '../../common/ParamTypes';
import SpanWithLabel from '../../common/SpanWithLabel';
import StackColumn from '../../common/StackColumn';
import { IProcessExtensionRequest } from '../../companies/processes/Interfaces';
import ProcessExtensionRequestPanel from '../../companies/processes/panels/ProcessExtensionRequestPanel';
import ReviewComponent from '../../reviews/ReviewComponent';

const ProcessExtensionReview = (): JSX.Element => {
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const { processExtensionId } = useParams<IProcessExtensionIdParams>();

  const { data, loading, error } = useQuery(GET_PROCESS_EXTENSION_REQUEST, {
    variables: { processExtensionId: parseInt(processExtensionId, 10) },
  });

  if (
    error &&
    error.message.indexOf('A process extension request was not found with that id') !== -1
  ) {
    return <Redirect to="/process-extensions?NotFound=true" />;
  }

  const processExtension: IProcessExtensionRequest = data?.processExtension;

  const togglePanel = (): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  const getBodyHeaderTitle = (): JSX.Element => {
    return (
      <BackButton ariaLabel="Back to process extensions list" backDepth={2}>
        <h2>Process Extension Review</h2>
      </BackButton>
    );
  };

  const renderActionButton = (label: string): JSX.Element => {
    return (
      <CanAccess requestedAction="ProcessExtensionRequest:Add">
        {processExtension?.review?.status === 'Pending' && (
          <IconButton
            title={`Edit ${label}`}
            ariaLabel={label}
            iconProps={{ iconName: 'Edit' }}
            onClick={(): void => {
              togglePanel();
            }}
          />
        )}
      </CanAccess>
    );
  };

  const originalFilingDate = momentUtc(
    processExtension?.process?.originalFilingDueDate ?? processExtension?.process?.filingDueDate,
  );
  const renderHeader = (): JSX.Element => <h1>Process Extensions</h1>;

  return (
    <>
      <FullWidthHeader title={renderHeader} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
            <Stack>
              <LoadingErrorMessage
                loading={loading}
                error={error}
                actionName="loading the process extension request data"
              />
            </Stack>
          </BodyHeaderWithCommandBar>
        </div>
      </div>
      <div className={`${bodyContentContainer}`}>
        <Card
          title="Process Extension Request"
          actionButton={renderActionButton('Process Extension Request')}
          headingLevel={2}
          isLoading={loading}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Company" value={processExtension?.companyName} />
              <SpanWithLabel
                labelText="Requested Date"
                value={momentUtc(processExtension?.requestedDate)}
              />
              <SpanWithLabel
                labelText="Reason for Extension"
                value={processExtension?.extensionReason.fieldName}
              />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Process Name">
                <Link
                  to={`/companies/${processExtension?.companyCode}/processes/${processExtension?.process?.processTypeUri}/${processExtension?.processId}`}
                >
                  {processExtension?.process.name}
                </Link>
              </SpanWithLabel>
              <SpanWithLabel labelText="Original Filing Due Date" value={originalFilingDate} />
              <SpanWithLabel labelText="Requestor Comments" value={processExtension?.comments} />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <ReviewComponent
          review={processExtension?.review}
          approveMutation={APPROVE_PROCESS_EXTENSION}
          rejectMutation={REJECT_PROCESS_EXTENSION}
          loading={loading}
          createCommentMutation={CREATE_PROCESS_EXTENSION_REVIEW_COMMENT}
          attachDocumentMutation={ATTACH_PROCESS_EXTENSION_REVIEW_DOCUMENT}
        />
      </div>
      {isEditPanelOpen && (
        <ProcessExtensionRequestPanel
          processExtension={processExtension}
          closePanel={togglePanel}
        />
      )}
    </>
  );
};

export default ProcessExtensionReview;
