import React from 'react';
import {
  IShimmeredDetailsListProps,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  IDetailsColumnStyles,
  FontWeights,
  IDetailsHeaderProps,
  IRenderFunction,
} from '@fluentui/react';
import { styledDetailsList } from '../../../app/common/styles/CommonStyleObjects';
import { IDetailsListColumnSortProps } from '../../../utils/types/IDetailsListColumnSortProps';

interface IShimmeredDetailsListWrapperProps extends IShimmeredDetailsListProps {
  pluralListLabel: string;
  singularListLabel: string;
  gridAriaLabelOverride?: string;
  detailsListColumnSortProps?: IDetailsListColumnSortProps;
  isColumnSortedList?: boolean;
}

const GetColumnStyle = (isSorted: boolean) => {
  const style: Partial<IDetailsColumnStyles> = {
    root: {
      height: 60,
      selectors: {
        '.ms-DetailsHeader-cellName': {
          fontWeight: isSorted ? FontWeights.semibold : FontWeights.regular,
        },
        '.ms-DetailsHeader-cell': {
          whiteSpace: 'normal',
          textOverflow: 'clip',
          lineHeight: 'normal',
        },
        '.ms-DetailsHeader-cellTitle': {
          height: 60,
          alignItems: 'center',
        },
        '.ms-DetailsHeader-cell:hover': {
          background: '#f3f2f1',
          height: '100%',
        },
        '.is-icon-visible': {
          fontWeight: FontWeights.semibold,
        },
      },
    },
  };
  return style;
};

export const GetUpdatedColumnState = (
  sortedFieldName: string,
  defaultColumnState: Array<IColumn>,
  isSortedDescending: string,
) => {
  // If there is a sorted field, we update the column style and props to indicate sorting
  if (!sortedFieldName) {
    return defaultColumnState;
  }
  defaultColumnState.forEach((column: IColumn) => {
    if (column.key === sortedFieldName) {
      column.isSorted = true;
      column.isSortedDescending = isSortedDescending === 'true';
      column.styles = GetColumnStyle(true);
    }
  });
  return defaultColumnState;
};

const ShimmeredDetailsListWrapper = (props: IShimmeredDetailsListWrapperProps): JSX.Element => {
  const {
    pluralListLabel,
    singularListLabel,
    gridAriaLabelOverride,
    detailsListColumnSortProps,
    isColumnSortedList,
  } = props;

  const {
    columns,
    sortedFieldName,
    searchState,
    isSortedDescending,
    setColumns,
    setIsSortedDescending,
    setSortedFieldName,
    updateStateResetPagination,
  } = detailsListColumnSortProps || {};

  const { onRenderDetailsHeader: onRenderDetailsHeaderDefault } = props;

  const onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn) => {
    if (isColumnSortedList && detailsListColumnSortProps) {
      const newColumns: IColumn[] = columns?.slice();
      const currentColumn: IColumn = newColumns?.filter((currCol) => column.key === currCol.key)[0];
      let newSortDirection = isSortedDescending;

      if (column.key === sortedFieldName) {
        setIsSortedDescending(!isSortedDescending);
        newSortDirection = !isSortedDescending;
      } else {
        setSortedFieldName(column.key);
        setIsSortedDescending(true);
        newSortDirection = true;
      }
      newColumns.forEach((newColumn: IColumn) => {
        if (newColumn === currentColumn) {
          newColumn.isSortedDescending = !currentColumn.isSortedDescending;
          newColumn.isSorted = true;
          newColumn.styles = GetColumnStyle(true);
        } else {
          newColumn.isSorted = false;
          newColumn.isSortedDescending = true;
          newColumn.styles = GetColumnStyle(false);
        }
      });
      const newState = {
        ...searchState,
        sortedFieldName: column.key,
        isSortedDescending: newSortDirection.toString(),
      };
      setColumns(newColumns);
      updateStateResetPagination(newState);
    }
  };

  const onRenderDetailsHeaderColumnSortedList = (
    headerProps: IDetailsHeaderProps,
    defaultRender: IRenderFunction<IDetailsHeaderProps>,
  ) => {
    if (!headerProps || !defaultRender) {
      return null;
    }
    return defaultRender({
      ...headerProps,
      styles: {
        root: {
          height: 60,
          selectors: {
            '.ms-DetailsHeader-cell': {
              whiteSpace: 'normal',
              textOverflow: 'clip',
              lineHeight: 'normal',
            },
            '.ms-DetailsHeader-cellTitle': {
              height: 60,
              alignItems: 'center',
            },
            '.ms-DetailsHeader-cell:hover': {
              background: '#f3f2f1',
              height: '100%',
            },
          },
        },
      },
    });
  };

  return (
    <>
      <ShimmeredDetailsList
        {...(props as IShimmeredDetailsListProps)}
        checkButtonAriaLabel="Row checkbox"
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        detailsListStyles={styledDetailsList}
        ariaLabelForShimmer={`${pluralListLabel} are being fetched`}
        ariaLabelForGrid={gridAriaLabelOverride || `${singularListLabel} details`}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        onRenderDetailsHeader={
          isColumnSortedList ? onRenderDetailsHeaderColumnSortedList : onRenderDetailsHeaderDefault
        }
        onColumnHeaderClick={(event, column) => {
          onColumnClick(event, column);
        }}
      />
    </>
  );
};
export default ShimmeredDetailsListWrapper;
