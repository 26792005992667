import React from 'react';
import { Stack, TextField } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Controller, UseFormMethods } from 'react-hook-form';
import { IProcessCorpReasonForLateFiling } from '../Interfaces';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import GET_CORP_REASON_FOR_LATE_FILING_QUERY from '../../../../utils/statApi/CorpReasonForLateFilingApi';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import CanAccess from '../../../common/canAccess/CanAccess';

export interface ICorpReasonForLateFilingFormProps {
  processCorpReasonForLateFiling: IProcessCorpReasonForLateFiling;
  form: UseFormMethods<IProcessCorpReasonForLateFiling>;
}

const CorpReasonForLateFilingDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, handleChange, valueKey, outputKey, errors } = props;
  const { loading, data } = useQuery(GET_CORP_REASON_FOR_LATE_FILING_QUERY);
  const { corpReasonsForLateFiling } = !loading && data ? data : [];

  return (
    <>
      <QueryBasedDropdown
        id="corpReasonForLateFilingDropdown"
        value={value as IDropdownReference}
        label={label}
        outputKey={outputKey}
        dropdownOptionsData={corpReasonsForLateFiling}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
        clearable
      />
    </>
  );
};

const CorpReasonForLateFilingForm = ({
  processCorpReasonForLateFiling,
  form,
}: ICorpReasonForLateFilingFormProps): JSX.Element => {
  const { control, errors, setValue } = form;

  const onReasonChange = (newValue: IDropdownReference, valueKey: string) => {
    setValue('corpReasonForLateFiling', newValue);
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <CanAccess requestedAction="CorpReasonForFilingViolation:Edit">
        <Controller
          control={control}
          name="corpReasonForLateFiling"
          value={processCorpReasonForLateFiling?.corpReasonForLateFiling}
          defaultValue={processCorpReasonForLateFiling?.corpReasonForLateFiling}
          render={({ value }) => (
            <CorpReasonForLateFilingDropdown
              label="Reason"
              valueKey="corpReasonForLateFiling"
              value={value}
              errors={errors}
              handleChange={onReasonChange}
            />
          )}
        />
      </CanAccess>
      <CanAccess requestedAction="CorpReasonForFilingViolation:Edit">
        <Controller
          as={TextField}
          id="corpReasonForLateFilingComment"
          name="corpReasonForLateFilingComment"
          label="Comments"
          control={control}
          multiline
          maxLength={500}
          defaultValue={processCorpReasonForLateFiling?.corpReasonForLateFilingComment || ''}
          required={false}
          disabled={false}
          rules={{
            maxLength: {
              value: 500,
              message: 'Comment cannot be longer than 500 characters',
            },
          }}
          errorMessage={errors?.corpReasonForLateFilingComment?.message}
        />
      </CanAccess>
    </Stack>
  );
};

export default CorpReasonForLateFilingForm;
