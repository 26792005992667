import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { bodyContentContainer } from '../../app/common/styles/CommonStyleObjects';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import ShimmeredDetailsListWrapper from '../common/DetailsLists/ShimmeredDetailsListWrapper';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { GET_INVALID_SERVICE_CONTACTS_QUERY } from '../../utils/statApi/ServiceContactsApi';
import getColumns from './InvalidServiceContactsList.config';
import { IServiceContactWithCompany } from '../companies/serviceContacts/interfaces';
import FilterCallout from '../common/callout/FilterCallout';
import InvalidServiceContactsFilter from './InvalidServiceContactsFilter';
import { IPaginationSearch } from '../processes/processSearch/IProcessSearchResult';
import DetailListPaginationBannerRefs from '../common/DetailsLists/pagination/DetailsListPaginationBannerRefs';
import { IPaginationMetadataRefs } from '../common/DetailsLists/pagination/IPaginationMetadata';
import { getTotalPageCount } from '../common/DetailsLists/pagination/DetailsListPaginationBanner';

export interface IInvalidServiceContactsSearchState {
  entityStatuses: string[];
}

const initialState: IInvalidServiceContactsSearchState = {
  entityStatuses: [],
};

const InvalidServiceContactsList = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [searchState, setSearchState] = useState<IInvalidServiceContactsSearchState>(initialState);
  const pageCount = React.useRef<number>(0);
  const selectedPage = React.useRef<number>(1);
  const defaultPageSize = React.useRef<number>(25);

  const [fetchInvalidServiceContacts, { data, loading, error }] = useLazyQuery(
    GET_INVALID_SERVICE_CONTACTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        invalidServiceContactSearchDto: {
          entityStatuses: searchState.entityStatuses,
        },
        paginationDto: {
          currentPage: selectedPage.current,
          pageSize: defaultPageSize.current,
        },
      },
    },
  );

  const invalidServiceContacts = data?.invalidServiceContacts?.items || {};
  const totalRecordCount: number = data?.invalidServiceContacts?.totalCount;

  const queryString = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const stateWithParsedQueryStrings = {
      entityStatuses: queryString.getAll('entityStatuses'),
    } as IInvalidServiceContactsSearchState;

    setSearchState(stateWithParsedQueryStrings);

    const pageSize = queryString.get('pageSize') ?? defaultPageSize.current;
    const currentPage = queryString.get('currentPage') ?? 1;

    selectedPage.current = parseInt(currentPage.toString(), 10);
    defaultPageSize.current = parseInt(pageSize.toString(), 10);

    fetchInvalidServiceContacts({
      variables: {
        invalidServiceContactSearchDto: {
          entityStatuses: stateWithParsedQueryStrings.entityStatuses,
        },
        paginationDto: {
          currentPage: selectedPage.current,
          pageSize: defaultPageSize.current,
        },
      },
    });
  }, [location]);

  const renderHeader = (): JSX.Element => <h1>Invalid Service Contacts</h1>;
  const renderInvalidServiceCompanyCodeLink = (item: IServiceContactWithCompany): JSX.Element => (
    <Link to={`/companies/${item.companyCode}/service-contacts`}>
      {item.companyCode} - {item.companySapName} ({item.companyPhysicalCountry})
    </Link>
  );
  const columns = getColumns({
    renderInvalidServiceCompanyCodeLink,
  });

  const resetPagination = (): void => {
    selectedPage.current = 1;
  };

  const onUpdateUrl = (
    newState: IInvalidServiceContactsSearchState,
    paginationData: IPaginationSearch,
  ) => {
    const newUrlObject = new URLSearchParams();
    // handle array parameters
    Object.entries(newState).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        if (v.length > 0) {
          v.forEach((value) => {
            newUrlObject.append(k as string, value);
          });
        }
      }
    });

    newUrlObject.append('pageSize', paginationData?.pageSize?.toString());
    newUrlObject.append('currentPage', paginationData?.currentPage?.toString());

    history.replace(location.pathname.concat(`?${newUrlObject.toString()}`));
  };

  const updateStateResetPagination = (
    propertyName: keyof IInvalidServiceContactsSearchState,
    value: string[],
  ) => {
    const newState = { ...searchState, [propertyName]: value };
    setSearchState(newState);
    resetPagination();
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(newState, paginationData);
  };

  const onResetFiltersClick = (): void => {
    const emptyState = initialState;
    setSearchState(emptyState);
    resetPagination();
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(emptyState, paginationData);
  };

  const onPageSizeChange = (newPageSize: string | number): void => {
    defaultPageSize.current = newPageSize as number;
    selectedPage.current = 1;

    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };

  const onPageChange = (
    startItemIndex: number,
    endItemIndex: number,
    newPageNumber: number,
  ): void => {
    selectedPage.current = newPageNumber;
    const paginationData = {
      currentPage: selectedPage.current,
      pageSize: defaultPageSize.current,
    };
    onUpdateUrl(searchState, paginationData);
  };

  pageCount.current = getTotalPageCount(totalRecordCount, defaultPageSize.current);

  const paginationMetadata: IPaginationMetadataRefs = {
    pageCountRef: pageCount,
    selectedPageRef: selectedPage,
    pageSizeRef: defaultPageSize,
    onPageChange,
    onPageSizeChange,
  };

  const NoResultsMessageBar = (): JSX.Element => {
    if (invalidServiceContacts?.length === 0 && !loading) {
      return (
        <MessageBar messageBarType={MessageBarType.info}>
          <span>No processes found for current filters</span>
        </MessageBar>
      );
    }
    return <></>;
  };
  return (
    <>
      <FullWidthHeader
        title={renderHeader}
        subtitle={() => {
          return (
            <Stack horizontalAlign="end">
              <FilterCallout
                triggerButtonId="InvalidServiceContactsFilter"
                triggerIconName="Filter"
              >
                <InvalidServiceContactsFilter
                  onResetFiltersClick={onResetFiltersClick}
                  filterState={searchState}
                  onUpdateState={updateStateResetPagination}
                />
              </FilterCallout>
            </Stack>
          );
        }}
      />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="loading invalid service contacts data"
        />
        <NoResultsMessageBar />
        <ShimmeredDetailsListWrapper
          columns={columns}
          items={invalidServiceContacts || []}
          enableShimmer={loading}
          singularListLabel="Invalid Service Contact"
          pluralListLabel="Invalid Service Contacts"
        />
        {paginationMetadata && <DetailListPaginationBannerRefs {...paginationMetadata} />}
      </div>
    </>
  );
};
export default InvalidServiceContactsList;
