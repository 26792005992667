import {
  DefaultPalette,
  FontSizes,
  Icon,
  IconButton,
  IIconProps,
  NeutralColors,
  Stack,
} from '@fluentui/react';
import React, { CSSProperties } from 'react';
import { MessagingColors } from '../../../../app/common/styles/CommonStyleObjects';

interface IProcessTemplateMilestoneCardHeaderProps {
  onHeaderClick: (collapsed: boolean) => void;
  title: string;
  contentIsCollapsed: boolean;
  isGoalMilestone: boolean;
}
const ProcessTemplateMilestoneCardHeader = (
  props: IProcessTemplateMilestoneCardHeaderProps,
): JSX.Element => {
  const { onHeaderClick, title, contentIsCollapsed, isGoalMilestone } = props;
  const cardHeaderStackStyles = {
    padding: 20,
    backgroundColor: isGoalMilestone ? MessagingColors.warning : NeutralColors.white,
    borderBottom: `1px solid ${NeutralColors.gray20}`,
  } as CSSProperties;
  const cardHeaderFontStyles = {
    margin: '0',
    fontSize: FontSizes.size14,
    fontWeight: 600,
  };
  const buttonWrapperStyles = { display: 'flex', alignItems: 'center' };
  const buttonStyles = {
    root: { marginRight: 10, color: DefaultPalette.blue },
    rootFocused: { background: NeutralColors.gray30, color: DefaultPalette.blue },
  };
  const chevronRight: IIconProps = {
    iconName: 'chevronRight',
  };

  const chevronDown: IIconProps = {
    iconName: 'chevronDown',
  };

  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
      style={cardHeaderStackStyles}
      aria-expanded={!contentIsCollapsed}
    >
      <div style={buttonWrapperStyles}>
        <IconButton
          styles={buttonStyles}
          iconProps={contentIsCollapsed ? chevronRight : chevronDown}
          title={contentIsCollapsed ? `Expand ${title}` : `Collapse ${title}`}
          ariaLabel={contentIsCollapsed ? `Expand ${title}` : `Collapse ${title}`}
          aria-expanded={!contentIsCollapsed}
          onClick={(): void => {
            onHeaderClick(!contentIsCollapsed);
          }}
        />
        <h3 style={cardHeaderFontStyles}>
          {title}
          {isGoalMilestone && (
            <>
              <span> - Goal Milestone </span>
              <Icon iconName="trophy" />
            </>
          )}
        </h3>
      </div>
    </Stack>
  );
};
export default ProcessTemplateMilestoneCardHeader;
