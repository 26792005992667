import React from 'react';
import { Switch } from 'react-router-dom';
import { CompanyGroupList, CompanyGroupDetail } from '..';
import Page from '../../common/page/Page';

const CompanyGroupRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Page path="/companygroups" exact component={CompanyGroupList} title="Company Groups" />
      <Page path="/companygroups/:id" component={CompanyGroupDetail} title="Company Group Detail" />
    </Switch>
  );
};

export default CompanyGroupRoute;
