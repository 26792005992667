import { ColumnActionsMode, IColumn } from '@fluentui/react';
import ICountry from '../../utils/types/ICountry';
import IDropdownReference from '../../utils/types/IDropdownReference';

interface ICountryConfigColumnParams {
  onRenderComplianceName: (complianceRequirement: IComplianceRequirement) => JSX.Element;
  onRenderUpdateDate: (complianceRequirement: IComplianceRequirement) => JSX.Element;
  onRenderFilingType: (complianceRequirement: IComplianceRequirement) => JSX.Element;
  onRenderFilingFrequency: (complianceRequirement: IComplianceRequirement) => JSX.Element;
  deleteButtonColumn: (complianceRequirement: IComplianceRequirement) => JSX.Element;
  canDelete: boolean;
}

export const getColumns = ({
  onRenderComplianceName,
  onRenderUpdateDate,
  onRenderFilingType,
  onRenderFilingFrequency,
  deleteButtonColumn,
  canDelete,
}: ICountryConfigColumnParams): IColumn[] => {
  const columns = [
    {
      key: 'name',
      name: 'Name',
      minWidth: 175,
      maxWidth: 175,
      isRowHeader: true,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderComplianceName,
    },
    {
      key: 'filingType',
      name: 'Filing Type',
      fieldName: 'filingType.fieldName',
      minWidth: 175,
      maxWidth: 175,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderFilingType,
    },
    {
      key: 'filingFrequency',
      name: 'Filing Frequency',
      fieldName: 'filingFrequency.fieldName',
      minWidth: 175,
      maxWidth: 175,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderFilingFrequency,
    },
    {
      key: 'updatedDate',
      name: 'Last Modified',
      minWidth: 175,
      maxWidth: 175,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: onRenderUpdateDate,
    },
  ];

  if (canDelete) {
    columns.push({
      key: 'deleteButton',
      name: 'Delete',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: false,
      isResizable: false,
      onRender: deleteButtonColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    });
  }

  return columns;
};

export interface ITaxRate {
  id?: number;
  taxDescription: string;
  rate: number;
  effectiveDate: Date;
  taxType: IDropdownReference;
}

export interface IComplianceRequirement {
  id?: number;
  name: string;
  comments?: string;
  updatedDate?: Date;
  filingDueDateDay: number;
  filingDueDateMonth: number;
  countryConfigurationId: number;
  filingType: IDropdownReference;
  filingFrequency: IDropdownReference;
  filingPreparationCompany: IDropdownReference;
  taxRates: ITaxRate[];
}

export interface ICountryConfiguration {
  id?: number;
  country: ICountry;
  monthsToFileFromReportingPeriodEnd: number;
  alignAgmWithFinancialStatement: boolean;
  electronicSignatureAcceptable: boolean;
  externalFilingRequired: boolean;
  taxReturnDueDateMonth: number;
  taxReturnDueDateDay: number;
  taxReportingPeriodDateMonth: number;
  taxReportingPeriodDateDay: number;
  workWeek: IDropdownReference;
  complianceRequirements?: IComplianceRequirement[];
}

export interface ICountryConfigurationQueryResult {
  countryConfigurationDetails: ICountryConfiguration;
}

export interface ICountryConfigurationsResult {
  countryConfigurations: ICountryConfiguration[];
}
