import React, { useContext } from 'react';
import { Stack, PrimaryButton } from '@fluentui/react';
import { verticalCenterStack, centeredWrapper } from '../../app/common/styles/CommonStyleObjects';
import SiteError from '../common/errorContent/SiteError';
import UserContext from '../../utils/authorization/UserContext';
import { StatUser } from '../../utils/statApi/UsersApi';

function renderActionButtons(): React.ReactElement {
  return (
    <>
      <PrimaryButton
        text="Finance Tool Support"
        href="https://aka.ms/finsup"
        target="_blank"
        rel="noopener noreferrer"
      />
    </>
  );
}

interface IUnauthorizedPageProps {
  useCardStyle?: boolean;
}

const UnauthorizedPage = (props: IUnauthorizedPageProps): JSX.Element => {
  const { useCardStyle } = props;
  const user: StatUser = useContext(UserContext);

  let headerMessage = 'Welcome! It looks like you don’t have access to STAT';

  if (user?.isActive) {
    headerMessage = 'You are not authorized to view this page in STAT';
  }

  const style = useCardStyle ? '' : verticalCenterStack;

  return (
    <div className={`ms-Grid ${style}`}>
      <Stack className={`ms-Grid-row ${centeredWrapper}`}>
        <SiteError
          className="ms-Grid-col ms-sm11 ms-l10 ms-xl8 ms-xxl6 ms-xxxl6"
          headerMessage={headerMessage}
          contentMessage="For access and other inquiries, please open a service request through the Finance Tool Support Portal."
          actionButtonsElement={renderActionButtons()}
        />
      </Stack>
    </div>
  );
};
export default UnauthorizedPage;
