import { DefaultButton, ITag, Separator, Stack, TextField } from '@fluentui/react';
import React, { useEffect, useRef } from 'react';
import { filterWrapperStyles } from '../../../app/common/styles/CommonStyleObjects';
import ProcessSearchCompanyPicker from '../../processes/processSearch/ProcessSearchCompanyPicker';
import ProcessSearchFiscalYearPicker from '../../processes/processSearch/ProcessSearchFiscalYearPicker';
import StatUserPicker from '../../companies/list/StatUserPicker';
import DateRangePicker from '../../processes/processSearch/DateRangePicker';
import useDebounce from '../../../utils/hooks/UseDebounce';

export interface IDocumentAnalysisFilter {
  companyCodes?: string[];
  reportingPeriodEndFromDate?: Date;
  reportingPeriodEndToDate?: Date;
  selectedPeriodEndDateOption?: string;
  uploaderIds?: number[];
  uploadedFromDate?: string;
  uploadedToDate?: string;
  documentName?: string;
}

interface IDocumentAnalysisFilterProps {
  onResetFiltersClick: () => void;
  filterState: IDocumentAnalysisFilter;
  onUpdateState: (newState: IDocumentAnalysisFilter) => void;
}
const DocumentAnalysisFilter = (props: IDocumentAnalysisFilterProps): JSX.Element => {
  const { onResetFiltersClick, filterState, onUpdateState } = props;
  const { companyCodes, uploaderIds, uploadedFromDate, uploadedToDate, documentName } = filterState;
  const [documentNameLocalState, setDocumentNameLocalState] = React.useState<string>(documentName);
  const isFirstRender = useRef(true);

  const debouncedSearchTerm = useDebounce(documentNameLocalState, 500);

  const onCompanyUpdate = (value: string[]) => {
    const newState = structuredClone(filterState);

    newState.companyCodes = value;
    onUpdateState(newState);
  };

  const onReportingPeriodDateRangeChange = (
    newStartDate: Date,
    newEndDate: Date,
    newSelectedOption: string,
  ) => {
    const newState = structuredClone(filterState);
    newState.reportingPeriodEndFromDate = newStartDate;
    newState.reportingPeriodEndToDate = newEndDate;
    newState.selectedPeriodEndDateOption = newSelectedOption;
    onUpdateState(newState);
  };

  const onUploaderChange = (value: number[]) => {
    const newState = structuredClone(filterState);

    newState.uploaderIds = value;
    onUpdateState(newState);
  };

  const onUploadDateRangeChange = (startDate: string, endDate: string) => {
    const newState = structuredClone(filterState);

    if (startDate && startDate !== newState.uploadedFromDate) {
      newState.uploadedFromDate = startDate;
    }

    if (endDate) {
      newState.uploadedToDate = endDate;
    }
    onUpdateState(newState);
  };

  const onDocumentNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setDocumentNameLocalState(newValue);
  };

  useEffect(() => {
    // this is to prevent a state update and extra api call on first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const newState = structuredClone(filterState);

    newState.documentName = debouncedSearchTerm;
    onUpdateState(newState);
  }, [debouncedSearchTerm]);

  return (
    <Stack.Item grow disableShrink styles={filterWrapperStyles}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <h2>Filter</h2>
        <DefaultButton text="Reset Filters" onClick={onResetFiltersClick} />
      </Stack>
      <ProcessSearchCompanyPicker
        initialSelectedCodes={companyCodes ?? []}
        onCompanyUpdate={(items: ITag[]) => {
          onCompanyUpdate(items.map((item) => item.name.split(' ')[0].toString()));
        }}
      />
      <Separator />
      <ProcessSearchFiscalYearPicker
        label="Reporting Period End Date"
        initialSelectedOption={filterState.selectedPeriodEndDateOption}
        initialEndDate={filterState.reportingPeriodEndToDate}
        initialStartDate={filterState.reportingPeriodEndFromDate}
        onUpdateDate={onReportingPeriodDateRangeChange}
      />
      <Separator />
      <StatUserPicker
        label="Uploaded By"
        initialSelectedItems={uploaderIds ?? []}
        onInputFieldUpdate={onUploaderChange}
      />
      <Separator />
      <DateRangePicker
        label="Uploaded Date Range"
        initialStartDate={uploadedFromDate}
        initialEndDate={uploadedToDate}
        onUpdateDate={onUploadDateRangeChange}
      />
      <Separator />
      <TextField
        ariaLabel="search documents by name"
        placeholder="Search documents"
        label="Document Name"
        onChange={onDocumentNameChange}
        value={documentNameLocalState}
      />
    </Stack.Item>
  );
};
export default DocumentAnalysisFilter;
