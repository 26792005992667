import React, { Dispatch, SetStateAction } from 'react';
import { ActionButton, FontIcon, Stack } from '@fluentui/react';
import { MilestoneRuleType, IMilestoneRule } from './Interfaces';
import DocumentGate from './milestoneRules/DocumentGate';
import DividendGate from './milestoneRules/DividendGate';
import AuditOpinionGate from './milestoneRules/AuditOpinionGate';
import CelaAgmStatusGate from './milestoneRules/CelaAgmStatusGate';
import ReasonForViolationGate from './milestoneRules/ReasonForViolationGate';
import { iconClass, paddedIconClass } from './milestoneRules/Gate.Styles';
import AgmDetailGate from './milestoneRules/AgmDetailGate';

interface IMilestoneRuleChecklistProps {
  milestoneRules: IMilestoneRule[];
  disabled: boolean;
  milestoneId: number;
  isMilestoneComplete: boolean;
  toggleDividendsPanel: () => void;
  commentsVisible: boolean;
  setCommentsVisible: Dispatch<SetStateAction<boolean>>;
}

const MilestoneRuleChecklist = ({
  milestoneRules,
  milestoneId,
  disabled,
  isMilestoneComplete,
  toggleDividendsPanel,
  commentsVisible,
  setCommentsVisible,
}: IMilestoneRuleChecklistProps): JSX.Element => {
  const toggleMilestoneDrilldownInfo = (): void => {
    if (commentsVisible) {
      setCommentsVisible(false);
    } else {
      setCommentsVisible(true);
    }
  };
  return (
    <Stack styles={{ root: { width: 260 } }}>
      {milestoneRules.map((rule) => {
        switch (rule.type) {
          case MilestoneRuleType.reasonForViolation:
            return (
              <ReasonForViolationGate
                key={rule.id}
                rule={rule}
                milestoneId={milestoneId}
                isMilestoneComplete={isMilestoneComplete}
                disabled={disabled}
              />
            );
          case MilestoneRuleType.documentUpload:
            return (
              <DocumentGate
                key={rule.id}
                rule={rule}
                milestoneId={milestoneId}
                isMilestoneComplete={isMilestoneComplete}
                disabled={disabled}
              />
            );
          case MilestoneRuleType.dividendGate:
            return (
              <DividendGate
                key={rule.id}
                rule={rule}
                milestoneId={milestoneId}
                disabled={disabled}
                toggleDividendsPanel={toggleDividendsPanel}
              />
            );
          case MilestoneRuleType.auditOpinion:
            return (
              <AuditOpinionGate
                key={rule.id}
                rule={rule}
                milestoneId={milestoneId}
                disabled={disabled}
              />
            );
          case MilestoneRuleType.celaAgmStatus:
            return (
              <CelaAgmStatusGate
                key={rule.id}
                rule={rule}
                milestoneId={milestoneId}
                disabled={disabled}
              />
            );
          case MilestoneRuleType.agmDetail:
            return <AgmDetailGate key={rule.id} rule={rule} />;
          default:
            return <span key={rule.type}>{rule.type}</span>;
        }
      })}
      <ActionButton onClick={toggleMilestoneDrilldownInfo}>
        <FontIcon className={`${iconClass} ${paddedIconClass}`} iconName="OpenInNewTab" />
        Milestone details and comments
      </ActionButton>
    </Stack>
  );
};

export default MilestoneRuleChecklist;
