import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { Stack } from '@fluentui/react';
import { useMutation } from '@apollo/client';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import {
  UPDATE_FINANCIAL_SYSTEMS_HEADCOUNT_MUTATION,
  UPDATE_FINANCIAL_SYSTEMS_MUTATION,
} from '../../../utils/statApi/AnnualFilingRequirementsApi';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import IAnnualFilingRequirements from '../../../utils/types/IAnnualFilingRequirements';
import { StatUser } from '../../../utils/statApi/UsersApi';
import UserContext from '../../../utils/authorization/UserContext';
import hasAccess from '../../../utils/authorization/authorizationCheck';
import FinancialSystemsForm from '../detail/forms/FinancialSystemsForm';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

interface IFinancialSystemsEditPanel {
  annualFilingRequirementsData: IAnnualFilingRequirements;
  closePanel: () => void;
}

const FinancialSystemsEditPanel = ({
  annualFilingRequirementsData,
  closePanel,
}: IFinancialSystemsEditPanel): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const financialSystemsForm = useForm();

  const { errors, handleSubmit } = financialSystemsForm;
  const user: StatUser = useContext(UserContext);
  const { permissions } = user;
  const showHeadCount = hasAccess(permissions, 'HeadCount:Edit');
  const [updateFinancialSystemsMutation, { loading, error }] = useMutation(
    UPDATE_FINANCIAL_SYSTEMS_MUTATION,
    {
      onCompleted: () => {
        if (!showHeadCount) {
          closePanel();
        }
      },
    },
  );
  const [updateFinancialSystemsHeadCountMutation, { loading: loadingHC, error: errorHC }] =
    useMutation(UPDATE_FINANCIAL_SYSTEMS_HEADCOUNT_MUTATION, {
      onCompleted: () => {
        closePanel();
      },
    });
  const saveFS = (formData: IAnnualFilingRequirements): void => {
    let headCountPayload = null as { id: number; headCount: boolean };
    const payload = {
      id: annualFilingRequirementsData.id,
      localAccountSystem: formData.localAccountSystem,
      calendarYearLedgerRequired: formData.calendarYearLedgerRequired,
      financialRecordLocation: formData.financialRecordLocation,
      localChartOfAccountsRequired: formData.localChartOfAccountsRequired,
      localLanguageRequirements: formData.localLanguageRequirements,
      isMsMarketUser: formData.isMsMarketUser,
    };
    if (showHeadCount) {
      headCountPayload = {
        id: annualFilingRequirementsData.id,
        headCount: formData.headCount,
      };
    }
    if (!Object.keys(errors).length) {
      updateFinancialSystemsMutation({
        variables: {
          companyCode,
          financialSystemsData: payload,
        },
      }).then(() => {
        if (showHeadCount) {
          updateFinancialSystemsHeadCountMutation({
            variables: {
              companyCode,
              financialSystemsData: headCountPayload,
            },
          });
        }
      });
    }
  };
  return (
    <CoherencePanel
      closeButtonAriaLabel="Close Financial Systems Edit Panel"
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Financial Systems"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          closePanel={closePanel}
          handleSubmit={handleSubmit(saveFS)}
          saveLabel="Save Finanacial Systems"
          saveTitle="Save Finanacial Systems"
          cancelLabel="Cancel Finanacial Systems Edit"
          cancelTitle="Cancel Finanacial Systems Edit"
          mutationLoading={loading}
        />
      )}
    >
      <form onSubmit={handleSubmit(saveFS)}>
        <Stack>
          <LoadingErrorMessage error={error} loading={loading} />
          <LoadingErrorMessage error={errorHC} loading={loadingHC} />
          <FinancialSystemsForm
            annualFilingRequirements={annualFilingRequirementsData}
            form={financialSystemsForm}
          />
        </Stack>
      </form>
    </CoherencePanel>
  );
};

export default FinancialSystemsEditPanel;
