import { ApolloError } from '@apollo/client';
import { Separator, Spinner, SpinnerSize, Stack, StackItem } from '@fluentui/react';
import React, { CSSProperties } from 'react';
import { IDocument } from '../../../utils/statApi/DocumentApi';
import FileSelectorShimmer from '../formFields/fileUpload/FileSelectorShimmer';
import DocumentView from './DocumentView';

interface IDocumentCardListProps {
  docType: string;
  documents: IDocument[];
  loading: boolean;
  showDeletedFiles: boolean;
  error?: ApolloError;
}

const DocumentCardList = ({
  docType,
  documents,
  loading,
  showDeletedFiles,
  error,
}: IDocumentCardListProps): JSX.Element => {
  if (error) {
    return <></>;
  }

  if (loading) {
    return <Spinner size={SpinnerSize.medium} />;
  }

  if (!documents || documents.length === 0) {
    return <p>No documents found. Try changing the filters or uploading documents.</p>;
  }

  const recycledDocs = documents.filter((doc: IDocument) => doc.isDeleted);

  const h4Style = { marginBottom: 0 } as CSSProperties;
  const h4TopStyle = { margin: 0 } as CSSProperties;

  return (
    <Stack>
      {showDeletedFiles && (
        <>
          <div>
            <h4 style={h4TopStyle}>Recycled Documents</h4>
            <Separator />
          </div>
          <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
            {recycledDocs.length === 0 && <span>No recycled documents found.</span>}
            {recycledDocs.map((document, index): JSX.Element => {
              return <DocumentView document={document} index={index} />;
            })}
          </Stack>
        </>
      )}
      {showDeletedFiles && (
        <div>
          <h4 style={h4Style}>{`${docType} Documents`}</h4>
          <Separator />
        </div>
      )}

      {loading && (
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          <StackItem>
            <FileSelectorShimmer />
          </StackItem>
          <StackItem>
            <FileSelectorShimmer />
          </StackItem>
        </Stack>
      )}

      {!loading && (
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          {documents
            .filter((doc: IDocument) => !doc.isDeleted)
            .map((document: IDocument, index: number): JSX.Element => {
              return <DocumentView document={document} index={index} />;
            })}
        </Stack>
      )}
    </Stack>
  );
};

export default DocumentCardList;
