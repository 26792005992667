import React from 'react';
import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { FieldValues, useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import IAgmFiscalYears from '../../../utils/types/IAgmFiscalYears';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import CanAccess from '../../common/canAccess/CanAccess';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import AgmStatusDueDateAndInfoForm from '../detail/forms/AgmStatusDueDateAndInfoForm';

export interface IAgmStatusDueDateEditPanelProps {
  agmStatus: IAgmFiscalYears;
  closePanel: () => void;
  agmMutation: DocumentNode;
  companyCode: string;
  refetch: () => void;
}

const FiscalYearAgmStatusDueDateEditPanel: React.FunctionComponent<
  IAgmStatusDueDateEditPanelProps
> = ({
  closePanel,
  agmStatus,
  agmMutation,
  companyCode,
  refetch,
}: IAgmStatusDueDateEditPanelProps): JSX.Element => {
  const [updateAGMInformation, { loading, error }] = useMutation(agmMutation, {
    onCompleted: () => {
      refetch();
      closePanel();
    },
  });
  const form = useForm<FieldValues>({
    defaultValues: {
      ...agmStatus,
    },
  });
  const { handleSubmit } = form;

  // Save AGM Due Date/Month & Information
  const handleOnSaveClick = (formData: IAgmFiscalYears): void => {
    const agmInfoData = {
      dueDateDay: formData?.agmDueDateDay,
      dueDateMonth: formData?.agmDueDateMonth,
      information: formData?.agmInformation,
    };

    const updateFiscalYearInformation = () =>
      updateAGMInformation({
        variables: {
          companyCode,
          agmUpdateDto: { ...agmInfoData },
        },
      });
    updateFiscalYearInformation();
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit AGM Status Due Date & Info"
      isOpen
      onDismiss={closePanel}
      hasCloseButton={false}
      styles={CoherencePanelStyles}
      backButton={{
        text: 'Back',
        ariaLabel: 'Back',
        onClick: () => {
          closePanel();
        },
      }}
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="CelaAgmStatus:Update">
          <ActionButtons
            mutationLoading={loading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveTitle="Save AGM Status Due Date & Info"
            saveLabel="Save AGM Status Due Date & Info"
            cancelTitle="Cancel AGM Status Due Date & Info Edit"
            cancelLabel="Cancel AGM Status Due Date & Info Edit"
          />
        </CanAccess>
      )}
    >
      <form>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="There was an error saving the AGM Information to the server"
        />
        <AgmStatusDueDateAndInfoForm agmStatus={agmStatus} form={form} />
      </form>
    </CoherencePanel>
  );
};
export default FiscalYearAgmStatusDueDateEditPanel;
