import { useQuery } from '@apollo/client';
import { Dropdown, IDropdownOption, ITag, Label, Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { GET_RULES_AND_ROLES } from '../../../../utils/statApi/MilestoneTemplateApi';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import { IMilestoneTemplate } from '../../../../utils/types/IMilestoneTemplate';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import TagPickerWrapper from '../../../common/formFields/tagPickerWrapper/TagPickerWrapper';
import { MilestoneRuleType } from '../../../companies/processes/Interfaces';

interface IMilestoneTemplateFormProps {
  milestoneTemplate?: IMilestoneTemplate;
  form: UseFormMethods<IMilestoneTemplate>;
  processTypeId: number;
}

const MilestoneTemplateForm = (props: IMilestoneTemplateFormProps): JSX.Element => {
  const { milestoneTemplate, form, processTypeId } = props;
  const { control, errors, setValue } = form;

  const [categories, setCategories] = useState([]);
  const { data, error, loading } = useQuery<{
    signOffRoles: IDropdownReference[];
    milestoneRules: IDropdownReference[];
    milestoneCategories: IDropdownOption[];
  }>(GET_RULES_AND_ROLES, {
    variables: { processTypeId },
    onCompleted: (returnData) => {
      const dbCategories = returnData?.milestoneCategories || [];
      const categoriesCopy = [...dbCategories];
      categoriesCopy.unshift({ key: null, text: '--' });
      setCategories(categoriesCopy);
    },
  });

  const [selectedRoleIds, setSelectedRoleIds] = useState(milestoneTemplate?.signoffRoleIds || []);
  const [selectedMilestoneRules, setSelectedMilestoneRules] = useState(
    milestoneTemplate?.milestoneRules || [],
  );

  const selectedRolesToTags = selectedRoleIds.map((item) => {
    const roleFromDb = data?.signOffRoles.find((role) => {
      return role.id === item;
    });
    return {
      key: roleFromDb?.id,
      name: roleFromDb?.fieldName,
    };
  });

  const selectedMilestoneRulesToTags = selectedMilestoneRules.map((item) => {
    const ruleFromDb = data?.milestoneRules.find((role) => {
      return role.id.toString().toLowerCase() === item.toString().toLowerCase();
    });
    return {
      key: ruleFromDb?.id,
      name: ruleFromDb?.fieldName,
    };
  });
  const validateSignoffRoles = (newVal: IDropdownReference[]): string => {
    if (newVal.length === 0) {
      return 'At least one signoff role must be selected';
    }
    return null;
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Controller
        as={TextField}
        label="Name"
        id="name"
        name="name"
        control={control}
        multiline
        resizable={false}
        defaultValue={milestoneTemplate?.name}
        errorMessage={errors?.name?.message}
        required
        rules={{
          maxLength: {
            value: 50,
            message: 'Input is greater than the maximum character limit of 50.',
          },
          required: 'Please enter a name',
        }}
      />
      <Controller
        as={TextField}
        label="Description"
        id="description"
        name="description"
        control={control}
        multiline
        resizable={false}
        defaultValue={milestoneTemplate?.description}
        errorMessage={errors?.description?.message}
        required
        rules={{
          maxLength: {
            value: 1000,
            message: 'Input is greater than the maximum character limit of 1000.',
          },
          required: 'Please enter a description',
        }}
      />
      <Controller
        as={TextField}
        label="Attestation Text"
        id="attestationText"
        name="attestationText"
        control={control}
        multiline
        resizable={false}
        defaultValue={milestoneTemplate?.attestationText}
        errorMessage={errors?.attestationText?.message}
        required
        rules={{
          maxLength: {
            value: 1000,
            message: 'Input is greater than the maximum character limit of 1000.',
          },
          required: 'Please enter attestation text',
        }}
      />
      <Stack>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="loading signoff roles and milestone rules"
        />
        <Label required>Signoff Roles </Label>
        {data?.signOffRoles && (
          <Controller
            render={(controllerProps) => {
              return (
                <>
                  <TagPickerWrapper
                    itemsFromSource={data.signOffRoles}
                    singularLabel="Signoff Role"
                    pluralLabel="Signoff Roles"
                    selectedItems={selectedRolesToTags}
                    setSelectedItems={(selectedItems: ITag[]) => {
                      const roleIds = selectedItems.map((item) => {
                        return item.key as number;
                      });
                      setValue('signoffRoleIds', roleIds, { shouldDirty: true });
                      setSelectedRoleIds(roleIds);
                    }}
                    /**
                     * Since signoffRoleIds are an array[] their validation has to return an array but returning an
                     * array in validation bypasses validate
                     */
                    errorMessage={(errors?.signoffRoleIds as unknown as FieldError)?.message}
                  />
                </>
              );
            }}
            rules={{ validate: validateSignoffRoles }}
            name="signoffRoleIds"
            control={control}
            defaultValue={selectedRoleIds}
          />
        )}
      </Stack>
      <Stack>
        <Label>Milestone Rules</Label>
        {data?.milestoneRules && (
          <Controller
            render={(controllerProps) => (
              <TagPickerWrapper
                itemsFromSource={data.milestoneRules}
                singularLabel="Milestone Rule"
                pluralLabel="Milestone Rules"
                selectedItems={selectedMilestoneRulesToTags}
                setSelectedItems={(selectedItems: ITag[]) => {
                  const ruleIds = selectedItems.map((item) => {
                    return item.key.toString().toUpperCase() as MilestoneRuleType;
                  });
                  setValue('milestoneRules', ruleIds, { shouldDirty: true });
                  setSelectedMilestoneRules(ruleIds);
                }}
              />
            )}
            name="milestoneRules"
            control={control}
            defaultValue={selectedMilestoneRules}
          />
        )}
      </Stack>
      <Controller
        name="milestoneCategoryId"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Dropdown
            label="Category"
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption,
              index?: number,
            ): void => {
              setValue('milestoneCategoryId', option.key, { shouldDirty: true });
            }}
            options={categories}
            defaultSelectedKey={milestoneTemplate?.milestoneCategoryId || null}
            placeholder="Select a category"
          />
        )}
        defaultValue={milestoneTemplate?.milestoneCategoryId || null}
      />
    </Stack>
  );
};
export default MilestoneTemplateForm;
