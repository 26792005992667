import React, { useState, useEffect } from 'react';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadTheme } from '@fluentui/react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { INotificationItem } from '@coherence-design-system/notification-center';
import config, { loadConfiguration } from './common/ConfigLoader';
import App from './App';
import graphApiRequest, { getAuthInstance, getClient, setAuthInstance } from '../utils/ApiProvider';
import { AuthModule } from '../utils/authentication/AuthModule';
import AuthorizationChecker from './authentication/AuthorizationChecker';
import StatHeader from './common/StatHeader';
import { IStatNotification, Toast } from './notifications/StatNotification';
import { NotificationProvider } from './notifications/NotificationContext';
import { graphConfig } from '../utils/AuthConfig';
import StatContactsNoAuthPage from './reports/StatContactsNoAuthPage';
import AuthCallback from './authentication/AuthCallback';
import Page from './common/page/Page';

/**
 * import the json file for config instead of fetching
 */
const AppLoader = (): JSX.Element => {
  const [client, setClient] = useState<ApolloClient<unknown>>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [graphProfileData, setGraphProfileData] = useState({
    userImageUrl: null,
    graphProfile: null,
  });
  const [notifications, setNotifications] = useState<INotificationItem[]>([]);
  const [statNotifications, setStatNotifications] = useState<IStatNotification[]>([]);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [environmentName, setEnvironmentName] = useState('');

  useEffect(() => {
    loadConfiguration().then(() => {
      const auth = new AuthModule();
      auth.loadAuthModule(setIsLoggedIn);
      setAuthInstance(auth);

      const clientConfiguration = getClient();
      setClient(clientConfiguration);
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: config?.settings?.appInsightsInstrumentationKey,
          enableAutoRouteTracking: true,
          enableCorsCorrelation: true,
        },
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    });
    loadTheme(CoherenceTheme);
  }, []);

  useEffect(() => {
    if (isLoggedIn && client) {
      graphApiRequest(graphConfig.graphMePhotoEndpoint, false)
        .then((graphPhotoResponse) => {
          return graphPhotoResponse.blob();
        })
        .then((photoBlob) => {
          return photoBlob ? URL.createObjectURL(photoBlob) : null;
        })
        .then((imageUrlFromBlob) => {
          return graphApiRequest(graphConfig.graphMeEndpoint).then((profileResponse) => {
            setGraphProfileData({ userImageUrl: imageUrlFromBlob, graphProfile: profileResponse });
          });
        });
    }
  }, [isLoggedIn, client]);

  useEffect(() => {
    const { hostname } = window.location;
    const hostnameParts = hostname.toLocaleLowerCase().replace('https://', '').split('.');

    if (hostnameParts[0] !== 'stat') {
      setEnvironmentName(hostnameParts[0]);
    }
  });

  const onLogOut = (): void => {
    getAuthInstance()?.logout();
  };

  return (
    <>
      {client && (
        <ApolloProvider client={client}>
          <NotificationProvider
            value={{
              statNotifications,
              notifications,
              toasts,
              setNotifications,
              setStatNotifications,
              setToasts,
            }}
          >
            <Router>
              <StatHeader
                onLogOut={onLogOut}
                graphProfileData={graphProfileData?.graphProfile}
                imageUrl={graphProfileData?.userImageUrl}
                environmentName={environmentName}
              />
              {isLoggedIn && graphProfileData?.graphProfile?.id ? (
                <Switch>
                  <Page
                    exact
                    path="/stat-contacts"
                    component={StatContactsNoAuthPage}
                    title="Stat Contacts"
                  />
                  <Route path="/authcallback" component={AuthCallback} />
                  <Route
                    path="/"
                    render={() => {
                      return (
                        <AuthorizationChecker graphId={graphProfileData?.graphProfile?.id}>
                          <App
                            setNotifications={setNotifications}
                            setStatNotifications={setStatNotifications}
                          />
                        </AuthorizationChecker>
                      );
                    }}
                  />
                </Switch>
              ) : (
                <div />
              )}
            </Router>
          </NotificationProvider>
        </ApolloProvider>
      )}
    </>
  );
};

export default AppLoader;
