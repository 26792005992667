import { useQuery } from '@apollo/client';
import React from 'react';
import { GET_OVERSIGHT_GROUPS } from '../../../utils/statApi/CompanyCreationApi';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../utils/types/IFieldsetProps';

import QueryBasedDropdown from '../../common/formFields/queryBasedDropdown/QueryBasedDropdown';

const OversightGroupDropdown = (props: IFieldsetProps): JSX.Element => {
  const {
    value,
    label,
    ariaLabel,
    required,
    errors: errorsOversightGroup,
    handleChange,
    valueKey,
    outputKey,
  } = props;
  const { loading, data: oversightGroupData } = useQuery(GET_OVERSIGHT_GROUPS);
  const { oversightGroups } = !loading && oversightGroupData ? oversightGroupData : [];
  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      outputKey={outputKey}
      label={label}
      ariaLabel={ariaLabel}
      required={required}
      errors={errorsOversightGroup}
      dropdownOptionsData={oversightGroups}
      handleChange={handleChange}
      valueKey={valueKey}
    />
  );
};

export default OversightGroupDropdown;
