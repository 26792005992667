import { Callout, IconButton, IIconProps } from '@fluentui/react';

import React, { useState } from 'react';

interface IFilterCalloutProps {
  children: JSX.Element;
  triggerButtonId: string;
  triggerIconName: string;
}
const FilterCallout = (props: IFilterCalloutProps): JSX.Element => {
  const { children, triggerIconName, triggerButtonId } = props;
  const triggerIcon: IIconProps = { iconName: triggerIconName || 'Filter' };
  const [isCalloutVisible, toggleIsCalloutVisible] = useState(false);
  return (
    <>
      <IconButton
        id={triggerButtonId}
        iconProps={triggerIcon}
        title="Filter"
        ariaLabel="Filter"
        onClick={() => {
          toggleIsCalloutVisible(!isCalloutVisible);
        }}
      />
      {isCalloutVisible && (
        <Callout
          styles={{ calloutMain: { padding: 30 } }}
          target={`#${triggerButtonId}`}
          onDismiss={() => {
            toggleIsCalloutVisible(!isCalloutVisible);
          }}
          role="status"
          aria-live="assertive"
          setInitialFocus
        >
          {children}
        </Callout>
      )}
    </>
  );
};
export default FilterCallout;
