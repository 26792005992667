import { ApolloError, useQuery } from '@apollo/client';
import {
  Dropdown,
  IDropdownOption,
  mergeStyles,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { GET_DIVIDEND_PAYMENT_TYPES_QUERY } from '../../../../utils/statApi/CompanyFiscalYearsApi';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import { IDividendPayment } from '../../serviceContacts/interfaces';

const DividendPaymentTypeDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_DIVIDEND_PAYMENT_TYPES_QUERY);
  const { dividendPaymentTypes } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={dividendPaymentTypes}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

interface IDividendPaymentFormProps {
  mutationLoading: boolean;
  mutationError: ApolloError;
  dividendPayment: IDividendPayment;
  onSubmit: (dividendPaymentInput: IDividendPayment) => void;
  theForm: UseFormMethods<Record<string, unknown>>;
}

const DividendPaymentForm = ({
  mutationLoading,
  mutationError,
  dividendPayment,
  onSubmit,
  theForm,
}: IDividendPaymentFormProps): JSX.Element => {
  const { errors, setValue, control, handleSubmit } = theForm;

  const dividendTypeOptions: IDropdownOption[] = [
    { key: 'ANNUAL', text: 'Annual' },
    { key: 'INTERIM', text: 'Interim' },
  ];

  const dividendAmountStyles = mergeStyles({
    width: '295px',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingErrorMessage error={mutationError} loading={mutationLoading} />
      <Controller
        name="dividendPaymentType"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <DividendPaymentTypeDropdown
            label="Dividend Payment Type"
            value={dividendPayment.dividendPaymentType}
            valueKey="dividendPaymentType"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('dividendPaymentType', newValue);
            }}
            errors={errors}
          />
        )}
        defaultValue={dividendPayment.dividendPaymentType}
        rules={{
          required: 'Please select a dividend payment type',
        }}
        required
      />
      <Controller
        name="dividendType"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Dropdown
            label="Dividend Type"
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption,
              index?: number,
            ): void => {
              setValue('dividendType', option.text);
            }}
            options={dividendTypeOptions}
            defaultSelectedKey={dividendPayment?.dividendType}
            required
            placeholder="Select an option"
            errorMessage={errors?.dividendType?.message}
          />
        )}
        defaultValue={dividendPayment?.dividendType}
        rules={{
          required: 'Please select a dividend type',
        }}
      />
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <Controller
          name="amount"
          control={control}
          as={TextField}
          type="number"
          label="Amount"
          defaultValue={dividendPayment?.amount?.toString()}
          className={dividendAmountStyles}
          rules={{
            required: 'Please enter an amount for the dividend payment',
            min: {
              value: 0,
              message: 'Please enter a positive number',
            },
          }}
          required
          errorMessage={errors?.amount?.message}
        />
        <StackItem>
          <Controller
            name="currencyCode"
            control={control}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <CurrencyCodeDropdown
                propertyName="currencyCode"
                handleChange={(newValue: string, valueKey: string): void => {
                  setValue('currencyCode', newValue);
                }}
                defaultValue={dividendPayment?.currencyCode}
                errorMessage={errors?.currencyCode?.message}
                required
              />
            )}
            defaultValue={dividendPayment.currencyCode}
            rules={{
              required: 'Please provide a Currency Code',
            }}
            required
          />
        </StackItem>
      </Stack>
      <Controller
        name="paymentDate"
        control={control}
        as={UtcDatePicker}
        label="Payment Date"
        allowTextInput
        textField={{
          name: 'paymentDate',
          errorMessage: errors?.paymentDate?.message,
        }}
        defaultValue={dividendPayment?.paymentDate}
        onSelectDate={(utcDateString: string): void => {
          setValue('paymentDate', utcDateString);
        }}
        rules={{
          required: 'Please enter a payment date',
        }}
        isRequired
      />
      <Controller
        name="fxRate"
        control={control}
        as={TextField}
        type="number"
        label="FX Rate"
        defaultValue={dividendPayment?.fxRate?.toString()}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ): void => {
          setValue('fxRate', newValue);
        }}
        rules={{
          min: { value: 0, message: 'Please enter a positive number' },
        }}
        errorMessage={errors?.fxRate?.message}
      />
      <Controller
        name="whRate"
        control={control}
        as={TextField}
        label="WH Rate"
        type="number"
        defaultValue={dividendPayment?.whRate?.toString()}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ): void => {
          setValue('whRate', newValue);
        }}
        rules={{
          min: { value: 0, message: 'Please enter a positive number' },
        }}
        errorMessage={errors?.whRate?.message}
      />
      <Controller
        name="whAmount"
        control={control}
        as={TextField}
        type="number"
        label="WH Amount"
        defaultValue={dividendPayment?.whAmount?.toString()}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ): void => {
          setValue('whAmount', newValue);
        }}
        rules={{
          min: { value: 0, message: 'Please enter a positive number' },
        }}
        errorMessage={errors?.whAmount?.message}
      />
    </form>
  );
};

export default DividendPaymentForm;
