import React, { useState, useContext } from 'react';
import { ActionButton, FontIcon } from '@fluentui/react';
import moment from 'moment';
import { IMilestoneRule, IFinancialStatementProcess } from '../Interfaces';
import { warningClass, iconClass, completeClass } from './Gate.Styles';
import ReasonForViolationEditPanel from '../panels/ReasonForViolationEditPanel';
import ProcessContext from '../ProcessContext';
import useIsMounted from '../../../common/IsMountedHook';

interface IReasonForViolationGateProps {
  rule: IMilestoneRule;
  milestoneId: number;
  disabled: boolean;
  isMilestoneComplete: boolean;
}

const ReasonForViolationGate = ({
  rule,
  milestoneId,
  disabled,
  isMilestoneComplete,
}: IReasonForViolationGateProps): JSX.Element => {
  const [panelIsOpen, setIsPanelOpen] = useState(false);
  const isMounted = useIsMounted();

  const data = useContext(ProcessContext);

  const processDetail: IFinancialStatementProcess = data || {};
  const isBeforeFilingDueDate = (): boolean => {
    if (isMilestoneComplete) return false;
    const isBeforeFilingDueDateUTC =
      moment.utc(new Date()).startOf('day') <= moment(processDetail.filingDueDate);
    return isBeforeFilingDueDateUTC;
  };
  const ruleIsComplete = (): boolean => {
    if (isMilestoneComplete) return true;
    return rule.isComplete;
  };
  const closePanel = (): void => {
    if (isMounted.current) {
      setIsPanelOpen(false);
    }
  };

  const getClassForIcon = (): string => {
    if (ruleIsComplete()) return '';
    return isBeforeFilingDueDate() ? completeClass : warningClass;
  };

  const getIconForButton = (): string => {
    if (ruleIsComplete()) return '';

    return isBeforeFilingDueDate() ? 'checkmark' : 'warning';
  };
  const buttonText = ruleIsComplete()
    ? 'View or edit reason for filing violation'
    : 'Enter a reason for filing violation';

  return (
    <>
      <ActionButton
        onClick={(): void => {
          setIsPanelOpen(true);
        }}
        ariaLabel={buttonText}
      >
        <FontIcon
          className={`${iconClass} ${getClassForIcon()}`}
          iconName={ruleIsComplete() ? 'checkmark' : getIconForButton()}
        />
        {buttonText}
      </ActionButton>
      {panelIsOpen && data && (
        <ReasonForViolationEditPanel processDetail={processDetail} closePanel={closePanel} />
      )}
    </>
  );
};

export default ReasonForViolationGate;
