import { IPersonaProps } from '@fluentui/react';
import IDropdownReference from '../../../utils/types/IDropdownReference';

export interface IServiceContact extends IPersonaProps {
  graphId: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  alias: string;
  contactType: string;
  isInvalidUser?: boolean;
  personnelNumber?: string;
}

export interface IServiceContactWithCompany extends IServiceContact {
  companyCode?: string;
  companySapName?: string;
  companyPhysicalCountry?: string;
}

export enum ServiceContactFormType {
  ContactGroups = 'Contact Groups',
  UserAssignment = 'User Assignment',
  OtherInfo = 'Other Contact Information',
}

export interface ICustomContactGroup {
  id: number;
  label: string;
  contacts: IServiceContact[];
}

export interface ICustomContactGroups {
  contactGroups: ICustomContactGroup[];
}

export interface IServiceContactsUserAssignment {
  isOneFinance: boolean;
  isMcaps: boolean;
  statutoryController: Array<IServiceContact>;
  regionalControllers: Array<IServiceContact>;
  generalManagers: Array<IServiceContact>;
  vpPresidentDirectors: Array<IServiceContact>;
  davaDealLeads: Array<IServiceContact>;
  bpoContacts: Array<IServiceContact>;
  finOpsContacts: Array<IServiceContact>;
  customContactGroups: ICustomContactGroups;
  treasurySupportReps: Array<IServiceContact>;
  taxSupportReps: Array<IServiceContact>;
  errors: IServiceContactsUserAssignmentErrors;
}

export interface IFilingInfo {
  oversightGroup: IDropdownReference;
  responsibleForStatFilingPreparation: string;
  responsibleForStatFilingPreparationThirdParty: string;
  responsibleForRecord: string;
  responsibleForRecordThirdParty: string;
  otherInformation: string;
  errors: IFilingInfoErrors;
  id: number;
  companyFiscalYearId: number;
}

export interface IDividendPaymentType {
  id: number;
  fieldName: string;
}

export interface IDividendPayment {
  id: number;
  dividendId: number;
  dividendPaymentType: IDividendPaymentType;
  dividendType: string;
  amount: number;
  currencyCode: string;
  paymentDate: Date;
  fxRate?: number;
  whRate?: number;
  whAmount?: number;
}

export interface IServiceContactsUserAssignmentErrors {
  statutoryController?: IErrorMessage;
  regionalControllers?: IErrorMessage;
  generalManagers?: IErrorMessage;
  vpPresidentDirectors?: IErrorMessage;
  davaDealLeads?: IErrorMessage;
  bpoContacts?: IErrorMessage;
  finOpsContacts?: IErrorMessage;
  treasurySupportReps?: IErrorMessage;
  taxSupportReps?: IErrorMessage;
}

export interface IFilingInfoErrors {
  oversightGroup?: IErrorMessage;
  responsibleForStatFilingPreparation?: IErrorMessage;
  responsibleForStatFilingPreparationThirdParty?: IErrorMessage;
  responsibleForRecord?: IErrorMessage;
  responsibleForRecordThirdParty?: IErrorMessage;
}

export interface IErrorMessage {
  message?: string;
}
