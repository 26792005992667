import React from 'react';
import {
  CoherencePagination,
  ICoherencePageSizeProps,
  ICoherencePaginationProps,
  IComboBoxNumberOption,
} from '@coherence-design-system/controls';

import { IPaginationMetadataRefs } from './IPaginationMetadata';
/**
 * Default system wide max records per paginated page
 */
export const DEFAULT_RECORDS_PER_PAGE = 25;

const DetailListPaginationBannerRefs = (props: IPaginationMetadataRefs): JSX.Element => {
  const { pageCountRef, selectedPageRef, pageSizeRef, onPageChange, onPageSizeChange } = props;

  const paginationProps: ICoherencePaginationProps = {
    pageCount: pageCountRef.current === 0 ? 1 : pageCountRef.current,
    selectedPage: selectedPageRef.current,
    previousPageAriaLabel: 'previous page',
    nextPageAriaLabel: 'next page',
    inputFieldAriaLabel: 'page number text box disabled',
    isIndexed: false,
    onPageChange,
    canJumpToPage: false,
  };
  const paginationPageSizeProps: ICoherencePageSizeProps = {
    pageSize: pageSizeRef.current,
    pageSizeList: [
      { key: 5, text: '5' },
      { key: 15, text: '15' },
      { key: 25, text: '25' },
      { key: 50, text: '50' },
    ] as IComboBoxNumberOption[],
    comboBoxAriaLabel: 'select page size',
    onPageSizeChange,
  };

  return <CoherencePagination {...paginationProps} pageSize={paginationPageSizeProps} />;
};

export default DetailListPaginationBannerRefs;
