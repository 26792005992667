import { useQuery } from '@apollo/client';
import { CoherenceTreeItem } from '@coherence-design-system/controls';
import { Spinner, SpinnerSize } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useDebounce from '../../utils/hooks/UseDebounce';
import { Action, GET_ACTIONS_QUERY } from '../../utils/statApi/RolesApi';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { IActionIdParams } from '../common/ParamTypes';
import TreeView from '../common/TreeView';
import ActionDetail from './ActionDetail';

const ActionDescriptionPage = (): JSX.Element => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(
    searchTerm === null || searchTerm === undefined ? '' : searchTerm,
    500,
  );
  const { actionId } = useParams<IActionIdParams>();

  const { data, loading, error } = useQuery(GET_ACTIONS_QUERY);

  const actions: Action[] = data?.actions;

  useEffect(() => {
    if (!actionId && actions && actions.length > 0) {
      history.replace(`/role-description/actions/${actions[0].id}`);
    }
  }, [data, loading]);

  const isValidSearchTerm = (value: string): boolean => value !== '' && value.length > 2;

  const getCurrentAction = () => {
    const currentAction = actions?.find((action) => {
      return action.id.toString() === actionId;
    });

    return currentAction;
  };

  const getTreeItems = (search: string): CoherenceTreeItem[] => {
    // Filter the actions based on search text
    const filteredActions = search
      ? actions.filter((i) => i.displayName.toLowerCase().indexOf(search.toLowerCase()) > -1)
      : actions;

    // Construct the coherence tree items array with categories of children actions
    const items: CoherenceTreeItem[] = [];
    if (filteredActions) {
      filteredActions?.forEach((action) => {
        // If the category doesn't exist, add it
        if (!items.some((i) => i.title === action.category)) {
          items.push({
            title: action.category,
            isExpanded: !!search, // If there is a search term, always expand the categories so all results are shown
            id: action.category,
            children: [],
          });
        }

        // Add a new action to the category as one of its children
        const index = items.findIndex((i) => i.title === action.category);
        items[index].children.push({
          title: action.displayName,
          id: action.id.toString(),
          onClick: (
            ev: React.MouseEvent<HTMLElement, MouseEvent>,
            item: CoherenceTreeItem,
          ): void => {
            ev.preventDefault();
            ev.stopPropagation();
            history.push(`/role-description/actions/${action.id}`);
          },
        });
      });
    }

    // Sort the categories alphabetically by title
    items.sort((a, b) => (a.title > b.title ? 1 : -1));

    // If there is a selected action in the filtered list, expand the category and mark that item as selected
    const selectedAction = filteredActions?.find((a) => {
      return a.id.toString() === actionId;
    });

    if (selectedAction) {
      const selectedCategory = items.find((c) => {
        return c.title === selectedAction.category;
      });
      const selectedItem = selectedCategory.children.find((a) => {
        return a.title === selectedAction.displayName;
      });

      selectedCategory.isExpanded = true;
      selectedItem.isSelected = true;
    }

    return items;
  };

  const handleOnChangeSearch = (
    event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string,
  ): void => {
    const searchText = newValue ? newValue.trim() : '';

    if (isValidSearchTerm(searchText)) {
      setSearchTerm(searchText);
    } else if (searchText.length === 0) {
      setSearchTerm('');
    }
  };

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} actionName="loading action details" />
      {loading && <Spinner label="Loading Action Details" size={SpinnerSize.medium} />}
      {!loading && actions && (
        <TreeView
          treeItems={getTreeItems(debouncedSearchTerm)}
          showSearchBox
          handleOnChangeSearch={handleOnChangeSearch}
        >
          <ActionDetail action={getCurrentAction()} />
        </TreeView>
      )}
    </>
  );
};

export default ActionDescriptionPage;
