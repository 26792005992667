import { useMutation } from '@apollo/client';
import { PanelType } from '@fluentui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { panelFooterStyle } from '../../../../app/common/styles/CommonStyleObjects';
import {
  AGM_REVIEW_QUERY,
  UPDATE_AGM_REVIEW_CONTACTS,
} from '../../../../utils/statApi/CompanyFiscalYearsApi';
import IAgmReview from '../../../../utils/types/IAgmReview';
import ActionButtons from '../../../common/ActionButtons';
import CanAccess from '../../../common/canAccess/CanAccess';
import CoherencePanelWithSubheader from '../../../common/CoherencePanelWithSubheader';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IAgmReviewParams } from '../../../common/ParamTypes';
import AgmReviewAssigneeForm from './AgmReviewContactsForm';

interface IAgmReviewContactsEditPanel {
  agmReview: IAgmReview;
  closePanel: () => void;
}

const AgmReviewContactsEditPanel: React.FunctionComponent<IAgmReviewContactsEditPanel> = ({
  agmReview,
  closePanel,
}: IAgmReviewContactsEditPanel): JSX.Element => {
  const form = useForm();
  const { agmReviewId } = useParams<IAgmReviewParams>();
  const { handleSubmit } = form;

  const [updateAgmReviewContacts, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_AGM_REVIEW_CONTACTS,
    {
      onCompleted: closePanel,
      refetchQueries: [
        {
          query: AGM_REVIEW_QUERY,
          variables: { agmReviewId: parseInt(agmReviewId, 10) },
        },
      ],
    },
  );

  const handleOnSaveClick = (formData: IAgmReview): void => {
    updateAgmReviewContacts({
      variables: {
        input: {
          agmReviewId: parseInt(agmReviewId, 10),
          contacts: formData.agmReviewContacts.map((contact) => {
            return {
              id: contact?.id ? parseInt(contact.id, 10) : null,
              graphId: contact.graphId,
              firstName: contact.firstName,
              lastName: contact.lastName,
              name: contact.name,
              alias: contact.alias,
              email: contact.email,
            };
          }),
        },
      },
    });
  };

  return (
    <CoherencePanelWithSubheader
      headerText="Edit Contacts"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      isFooterAtBottom
      isLightDismiss
      styles={panelFooterStyle}
      type={PanelType.medium}
      closeButtonAriaLabel="Close Contacts Edit"
      subheader={
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the contacts to the server"
        />
      }
      onRenderFooterContent={(): JSX.Element => (
        <CanAccess requestedAction="AgmReviewContacts:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveTitle="Save Contacts"
            saveLabel="Save Contacts"
            cancelTitle="Cancel Contacts Edit"
            cancelLabel="Cancel Contacts Edit"
            disabled={!form?.formState?.isDirty}
          />
        </CanAccess>
      )}
    >
      <form>
        <AgmReviewAssigneeForm form={form} agmReview={agmReview} />
      </form>
    </CoherencePanelWithSubheader>
  );
};

export default AgmReviewContactsEditPanel;
