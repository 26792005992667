import React from 'react';
import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IFinancialStatementProcess } from '../Interfaces';
import ProcessDetailForm from './ProcessDetailForm';
import { UPDATE_PROCESS_DETAIL_MUTATION } from '../../../../utils/statApi/ProcessApi';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';

interface IProcessDetailEditPanelProps {
  processDetails: IFinancialStatementProcess;
  closePanel: () => void;
}
const ProcessDetailEditPanel: React.FunctionComponent<IProcessDetailEditPanelProps> = ({
  processDetails,
  closePanel,
}: IProcessDetailEditPanelProps): JSX.Element => {
  const [updateProcessDetails, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_PROCESS_DETAIL_MUTATION,
    {
      onCompleted: closePanel,
    },
  );

  const handleOnSaveClick = (formData: IFinancialStatementProcess): void => {
    // GraphQL types don't like the typename property
    const processDetailsSaveData = {
      id: formData.id,
      auditOpinion: {
        id: formData.auditOpinion.id,
        fieldName: formData.auditOpinion.fieldName,
      },
      statutoryAuditFinding: formData.statutoryAuditFinding,
      remediationPlanInPlace: formData.remediationPlanInPlace,
      expectedClosureDate: formData.expectedClosureDate,
    };

    updateProcessDetails({
      variables: {
        processDetails: {
          ...processDetailsSaveData,
        },
      },
    });
  };

  return (
    <CoherencePanel
      closeButtonAriaLabel="close panel"
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Process Details"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
    >
      <form>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the process details to the server"
        />
        <ProcessDetailForm
          processDetails={processDetails}
          handleCancel={closePanel}
          handleSubmit={handleOnSaveClick}
          loading={mutationLoading}
        />
      </form>
    </CoherencePanel>
  );
};
export default ProcessDetailEditPanel;
