import React from 'react';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { useQuery } from '@apollo/client';
import {
  ExpandableFooterContent,
  IMetricItem,
  MetricsDetailsList,
  MetricsTile,
} from 'fcw-dashboard-components';
import { Spinner, Stack } from '@fluentui/react';
import { PROCESS_STATUS_SUMMARY_QUERY } from '../../../utils/statApi/ProcessApi';
import { IFCWMicroFrontendProps } from '../bootstrap/ExternalAppLoader';
import {
  convertFiscalPeriodToDate,
  getDate,
  getDetailContentColumns,
  getMetricItems,
} from './StatProcessTile.config';
import { IProcessStatusSummary, IProcessTypeSummary } from './IProcessStatusSummary';

const StatProcessTile = (): JSX.Element => {
  const context = React.useContext(Context as React.Context<IFCWMicroFrontendProps>);
  const { baseName, baseUrl, filters } = context;
  const {
    companyCodes,
    forMeOnly,
    filterByPeriod,
    dueDateRangeStart,
    dueDateRangeEnd,
    fiscalPeriod,
    fiscalYear,
    opsOrgDetailIds,
  } = filters;

  const { data, loading, error } = useQuery<{ processStatusSummary: IProcessStatusSummary }>(
    PROCESS_STATUS_SUMMARY_QUERY,
    {
      variables: {
        processStatusSummarySearchDto: {
          companyCodes: companyCodes || [],
          showMyItems: forMeOnly,
          milestoneDueDateStartDate: filterByPeriod
            ? convertFiscalPeriodToDate(fiscalPeriod, fiscalYear, false)
            : getDate(dueDateRangeStart),
          milestoneDueDateEndDate: filterByPeriod
            ? convertFiscalPeriodToDate(fiscalPeriod, fiscalYear, true)
            : getDate(dueDateRangeEnd),
          opsDetailIds: opsOrgDetailIds || [],
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const remainingProcesses =
    data?.processStatusSummary?.countOfProcessesWithUpcomingMilestones +
    data?.processStatusSummary?.countOfProcessesWithLateMilestones;

  const metricItems: IMetricItem[] = getMetricItems(data?.processStatusSummary);
  const total = metricItems
    .filter((item) => {
      return item.showInChart;
    })
    .reduce((acc, item) => acc + item.value, 0);

  const columns = getDetailContentColumns(baseName, filters);

  return (
    <>
      {loading && (
        <Stack grow verticalAlign="center">
          <Spinner label="Loading" labelPosition="right" />
        </Stack>
      )}
      {!loading && !error && (
        <>
          <MetricsTile
            metricItems={metricItems}
            actionItem={
              {
                label: 'Processes Remaining',
                value: remainingProcesses,
                showInChart: false,
                tooltipMessage: 'Processes that are overdue or upcoming',
              } as IMetricItem
            }
            donutChartLabel={`${Intl.NumberFormat('en-US').format(total)} Total Processes`}
          />

          <ExpandableFooterContent
            extendedContent={
              <MetricsDetailsList<IProcessTypeSummary>
                items={data?.processStatusSummary?.processTypeSummary || []}
                columns={columns}
              />
            }
            extendedContentLabel="Details"
            detailsLink={baseUrl}
            detailsLinkText="Go to Stat Tool"
          />
        </>
      )}
    </>
  );
};

export default StatProcessTile;
