import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import dayjs from 'dayjs';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import COMPANY_FISCAL_YEAR_LIST_QUERY from '../../../utils/statApi/CompanyFiscalYearsApi';
import { bodyContent } from '../../common/headers/Headers.styles';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import StatDateFormats from '../../../utils/types/DateFormats';
import getColumns from './FiscalYearList.config';
import formatCurrency from '../../../utils/CurrencyFormatter';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import momentUtc from '../../../utils/DateFormatter';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import IDividendPaymentTotal from '../../../utils/types/IDividendPaymentTotal';

const FiscalYearList = (): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { data, loading, error } = useQuery(COMPANY_FISCAL_YEAR_LIST_QUERY, {
    variables: { companyCode },
    fetchPolicy: 'cache-and-network',
  });

  const items = data?.companyFiscalYearList || [];

  const renderAfsNameColumn = (item: { afsProcessName: string; id: number }): JSX.Element => {
    return (
      <Link to={`/companies/${companyCode}/fiscal-years/${item.id}`}>{item.afsProcessName}</Link>
    );
  };

  const renderProcessCompletionDateColumn = (item: {
    lastMilestoneSignOffDate: Date;
  }): JSX.Element => {
    return (
      <span>
        {item.lastMilestoneSignOffDate
          ? dayjs(item.lastMilestoneSignOffDate).format(StatDateFormats.DayBreakout)
          : '--'}
      </span>
    );
  };

  const renderDividendsColumn = (item: {
    dividendAmount: number;
    currencyCode: string;
  }): JSX.Element => (
    <span>
      {item.dividendAmount ? formatCurrency(item.currencyCode, item.dividendAmount) : '--'}
    </span>
  );

  const renderAuditStatusColumn = (item: {
    auditStatus: IDropdownReference;
    auditStatusDate: Date;
  }): JSX.Element => {
    if (!item?.auditStatus?.fieldName) {
      return <span>--</span>;
    }
    return (
      <span>
        {`${item.auditStatus.fieldName} - ${momentUtc(
          item.auditStatusDate,
          StatDateFormats.DayBreakout,
        )}`}
      </span>
    );
  };

  const renderAgmStatusColumn = (item: {
    agmStatus: IDropdownReference;
    agmStatusDate: string;
  }): JSX.Element => {
    if (!item?.agmStatus?.fieldName) {
      return <span>--</span>;
    }
    return (
      <span>
        {`${item.agmStatus.fieldName} - ${momentUtc(
          item.agmStatusDate,
          StatDateFormats.DayBreakout,
        )}`}
      </span>
    );
  };

  const renderDividendPaymentsTotalColumn = (item: {
    dividendPaymentsTotals: IDividendPaymentTotal[];
  }): JSX.Element => {
    return (
      <span>
        {item.dividendPaymentsTotals.length > 0
          ? item.dividendPaymentsTotals.map((value: IDividendPaymentTotal, index: number) => {
              return (
                <span>
                  {formatCurrency(value.currencyCode, value.dividendPaymentTotal)}
                  {index !== item.dividendPaymentsTotals.length - 1 ? ' / ' : ''}
                </span>
              );
            })
          : '--'}
      </span>
    );
  };

  const columns = getColumns({
    renderAfsNameColumn,
    renderProcessCompletionDateColumn,
    renderDividendsColumn,
    renderAuditStatusColumn,
    renderAgmStatusColumn,
    renderDividendPaymentsTotalColumn,
  });

  const dataNotEmpty = items && items.length > 0;
  return (
    <div className={`${bodyContentContainer}  ms-depth-4`}>
      <div className={bodyContentWrapper}>
        <div className={bodyContent().bodyContentTitle}>
          <h2>Fiscal Years</h2>
        </div>
      </div>
      <LoadingErrorMessage error={error} loading={loading} />
      <ShimmeredDetailsListWrapper
        columns={columns}
        items={items || []}
        enableShimmer={loading}
        singularListLabel="Fiscal Year"
        pluralListLabel="Fiscal Years"
      />
      {!loading && !dataNotEmpty && (
        <div className={bodyContentWrapper}>
          This company does not have any Annual Financial Statement processes.
        </div>
      )}
    </div>
  );
};

export default FiscalYearList;
