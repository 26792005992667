import { useMutation, useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { MessageBar } from '@fluentui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  GET_COMPANY_CREATION_REQUEST_DETAILS,
  SEND_COMPANY_CODE_REQUEST_EMAIL,
} from '../../utils/statApi/CompanyCreationApi';
import ActionButtons from '../common/ActionButtons';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { ICompanyCreationParams } from '../common/ParamTypes';
import CompanyRequestInfoCards from './details/CompanyRequestInfoCards';

interface ISubmitToSapReviewPanel {
  closePanel: () => void;
}
const SubmitToSapReviewPanel = (props: ISubmitToSapReviewPanel): JSX.Element => {
  const { closePanel } = props;
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const messageBarStyle = { root: { marginBottom: '5px' } };

  const { data, error, loading } = useQuery(GET_COMPANY_CREATION_COMPANY_INFO, {
    variables: {
      companyCreationId,
    },
  });

  const [sendCompanyCodeRequestEmail, { loading: emailSending, error: emailError }] = useMutation(
    SEND_COMPANY_CODE_REQUEST_EMAIL,
    {
      onCompleted: closePanel,
      refetchQueries: [
        {
          query: GET_COMPANY_CREATION_PROGRESS,
          variables: {
            companyCreationId,
          },
        },
        {
          query: GET_COMPANY_CREATION_REQUEST_DETAILS,
          variables: {
            companyCreationId,
          },
        },
      ],
    },
  );

  const handleOnSaveClick = (): void => {
    sendCompanyCodeRequestEmail({ variables: { input: companyCreationId } });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText="Request Company Code"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      closeButtonAriaLabel="Close"
      styles={CoherencePanelStyles}
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={emailSending}
          closePanel={closePanel}
          handleSubmit={handleOnSaveClick}
          saveLabel="Request Company Code"
          saveTitle="Request Company Code"
          cancelLabel="Cancel Request Company Code"
          cancelTitle="Cancel Request Company Code"
        />
      )}
    >
      <LoadingErrorMessage error={error} loading={loading} />
      <LoadingErrorMessage error={emailError} loading={emailSending} />
      <MessageBar styles={messageBarStyle}>
        Please review the following information before requesting a new company code.
      </MessageBar>
      <br />
      <CompanyRequestInfoCards
        companyRequestInfo={data?.companyCreationCompanyInfo}
        loading={loading}
        error={error}
        showCurrencyDocument={false}
        showMsSalesLocation={false}
        showShadowCompanyCard={false}
        showStatContactsCard={false}
        showCompanyCreationContactsCard={false}
        showIhccSetup
        showSapSetup
        showFunctionalCurrency
        showSubledger={false}
      />
    </CoherencePanel>
  );
};
export default SubmitToSapReviewPanel;
