import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActionButton, Stack } from '@fluentui/react';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import ReviewComponent from '../../reviews/ReviewComponent';
import RejectButtonMode from '../../reviews/RejectButtonMode';
import IAgmDetail from '../../../utils/types/IAgmDetail';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import {
  AGM_REVIEW_QUERY,
  APPROVE_AGM_REVIEW,
  ATTACH_AGM_REVIEW_DOCUMENT,
  CREATE_AGM_REVIEW_COMMENT,
  REJECT_AGM_REVIEW,
} from '../../../utils/statApi/CompanyFiscalYearsApi';
import AgmDetailsCardContent from './AgmDetailsContentCard';
import ErrorMessage from '../../common/errorContent/ErrorMessage';
import CanAccess from '../../common/canAccess/CanAccess';
import AgmDetailEditPanel from './AgmDetailEditPanel';
import { IAgmReviewParams, ICompanyCodeParams } from '../../common/ParamTypes';
import AgmDetailsDividendsCard from './AgmDetailsDividendsCard';
import AgmReviewContactsCard from './agmReview/AgmReviewContactsCard';
import StackColumn from '../../common/StackColumn';
import AgmReviewContactsEditPanel from './agmReview/AgmReviewContactsEditPanel';

enum AgmReviewForm {
  AgmDetails,
  AgmContacts,
}

interface IAgmReviewPageState {
  isEditPanelOpen: boolean;
  currentForm?: AgmReviewForm;
}

const AgmReview = (): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { agmReviewId } = useParams<IAgmReviewParams>();
  const [panelState, setPanelState] = useState<IAgmReviewPageState>({
    isEditPanelOpen: false,
    currentForm: null,
  });
  const history = useHistory();

  const { data, error, loading } = useQuery(AGM_REVIEW_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { agmReviewId: parseInt(agmReviewId, 10) },
  });

  const agmDetail: IAgmDetail = data?.agmReview?.agmDetail || {};
  const paddingForDivder = { paddingTop: 10 };

  const closePanel = (): void => {
    setPanelState({ isEditPanelOpen: false, currentForm: null });
  };

  const renderActionButton = (): JSX.Element => {
    return (
      <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
        <ActionButton
          title="Go to Process"
          ariaLabel="Go to Process"
          iconProps={{ iconName: 'TimelineProgress' }}
          onClick={(): void => {
            history.push(
              `/companies/${companyCode}/processes/annual-financial-statements/${agmDetail.processId}`,
            );
          }}
        >
          Go To Process
        </ActionButton>
        <span style={paddingForDivder}>|</span>
        <CanAccess requestedAction="AgmDetail:Edit">
          <ActionButton
            title="Edit AGM Details"
            ariaLabel="Edit AGM Details"
            iconProps={{ iconName: 'Edit' }}
            onClick={(): void => {
              setPanelState({
                isEditPanelOpen: true,
                currentForm: AgmReviewForm.AgmDetails,
              });
            }}
          >
            Edit AGM Details
          </ActionButton>
        </CanAccess>
      </Stack>
    );
  };

  const renderDividendCardButtons = (): JSX.Element => {
    return (
      <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
        {agmDetail?.dividendsReviewId !== null && (
          <ActionButton
            title="Go to Dividends Review"
            ariaLabel="Go to Dividends Review"
            iconProps={{ iconName: 'openInNewTab' }}
            onClick={(): void => {
              history.push(
                `/companies/${companyCode}/dividends-review/${agmDetail.dividendsReviewId}`,
              );
            }}
          >
            Go to Dividends Review
          </ActionButton>
        )}
      </Stack>
    );
  };

  const renderContactsCardButtons = (): JSX.Element => {
    return (
      <CanAccess requestedAction="AgmReviewContacts:Edit">
        <ActionButton
          title="Edit Contacts"
          ariaLabel="Edit Contacts"
          iconProps={{ iconName: 'Edit' }}
          onClick={(): void => {
            setPanelState({
              isEditPanelOpen: true,
              currentForm: AgmReviewForm.AgmContacts,
            });
          }}
        >
          Edit Contacts
        </ActionButton>
      </CanAccess>
    );
  };

  return (
    <>
      <div className={`${bodyContentContainer} lightBackground`}>
        <LoadingErrorMessage error={error} loading={loading} />
        {data?.agmReview === null && (
          <ErrorMessage>
            <span>The AGM Review was not found</span>
          </ErrorMessage>
        )}

        <AgmDetailsCardContent
          agmDetail={agmDetail}
          isLoading={loading}
          actionButtons={renderActionButton()}
        />
        <br />
        <Stack horizontal wrap horizontalAlign="space-between" grow tokens={{ childrenGap: 20 }}>
          <StackColumn>
            <AgmDetailsDividendsCard
              agmDetail={agmDetail}
              isLoading={loading}
              actionButtons={renderDividendCardButtons()}
            />
          </StackColumn>
          <StackColumn>
            <AgmReviewContactsCard
              agmReview={data?.agmReview}
              isLoading={loading}
              actionButtons={renderContactsCardButtons()}
            />
          </StackColumn>
        </Stack>
        <br />
        <ReviewComponent
          review={data?.agmReview?.review}
          approveMutation={APPROVE_AGM_REVIEW}
          rejectMutation={REJECT_AGM_REVIEW}
          loading={loading}
          createCommentMutation={CREATE_AGM_REVIEW_COMMENT}
          attachDocumentMutation={ATTACH_AGM_REVIEW_DOCUMENT}
          rejectButtonMode={RejectButtonMode.RequestChanges}
          disableApproveButton={agmDetail.dividendsReviewStatus !== 'Accepted'}
          displayOnly
          approverWarningMessage={
            agmDetail.dividendsReviewStatus !== 'Accepted'
              ? 'The AGM review cannot be approved until the dividend review is approved'
              : null
          }
        />
        {panelState.isEditPanelOpen &&
          panelState.currentForm === AgmReviewForm.AgmDetails &&
          !loading && (
            <AgmDetailEditPanel
              agmDetail={agmDetail}
              closePanel={closePanel}
              refetchQueries={[
                {
                  query: AGM_REVIEW_QUERY,
                  variables: { agmReviewId: parseInt(agmReviewId, 10) },
                },
              ]}
            />
          )}
        {panelState.isEditPanelOpen &&
          panelState.currentForm === AgmReviewForm.AgmContacts &&
          !loading && (
            <AgmReviewContactsEditPanel agmReview={data?.agmReview} closePanel={closePanel} />
          )}
      </div>
    </>
  );
};

export default AgmReview;
