import { useQuery } from '@apollo/client';
import { Dropdown, IDropdownOption, ITag, Spinner } from '@fluentui/react';
import React, { FormEvent } from 'react';
import { GET_STATE_PROVINCE_REGION_FOR_COUNTRY } from '../../../utils/statApi/CompanyCreationApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';

interface IStateProvinceRegionDropDownProps {
  selectedCountry: ITag[];
  defaultValue: string;
  onChange: (event: FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void;
  disabled: boolean;
  label?: JSX.Element;
}

const StateProvinceRegionDropDown = ({
  selectedCountry,
  defaultValue,
  onChange,
  disabled,
  label,
}: IStateProvinceRegionDropDownProps): JSX.Element => {
  const countryCode =
    selectedCountry && selectedCountry.length > 0 ? selectedCountry[0].key.toString() : '--';

  const { data, loading, error } = useQuery(GET_STATE_PROVINCE_REGION_FOR_COUNTRY, {
    variables: {
      countryCode,
    },
  });

  const options = data?.regionsByCountry || [];

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      {loading && !error && (
        <>
          <br />
          <Spinner label="Loading Regions" />
        </>
      )}
      {!loading && !error && countryCode === '--' && options.length === 0 && (
        <p>Please select a country</p>
      )}
      {!loading && !error && options.length > 0 && (
        <>
          {label}
          <Dropdown
            label={label ? undefined : 'State/Province/Region'}
            defaultSelectedKey={defaultValue}
            required={false}
            options={options}
            onChange={onChange}
            disabled={disabled}
            calloutProps={{ calloutMaxHeight: 215 }}
          />
        </>
      )}
    </>
  );
};

export default StateProvinceRegionDropDown;
