import { useLazyQuery, useQuery } from '@apollo/client';
import { IGroup } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_STATE_PROVINCE_REGION_FOR_COUNTRY,
} from '../../../utils/statApi/CompanyCreationApi';
import { IAddress } from '../../../utils/types/IAddress';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import RequiredFieldsPanel from './RequiredFieldsPanel';
import { IRequiredItem, IStatProgressInfo } from './types';

interface IStatProgressPanelProps {
  closePanel: () => void;
  isNonTransactional: boolean;
  companyCode: number;
  shadowCompanyCode: number;
}

const StatProgressPanel = (props: IStatProgressPanelProps): JSX.Element => {
  const { closePanel, isNonTransactional, companyCode, shadowCompanyCode } = props;
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const [statProgressData, setStatProgressData] = useState<IStatProgressInfo>();

  const { data, loading, error } = useQuery(GET_COMPANY_CREATION_COMPANY_INFO, {
    variables: {
      companyCreationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getLegalRegionForCountry, { data: legalRegionData }] = useLazyQuery(
    GET_STATE_PROVINCE_REGION_FOR_COUNTRY,
  );

  const [getPhysicalRegionForCountry, { data: physicalRegionData }] = useLazyQuery(
    GET_STATE_PROVINCE_REGION_FOR_COUNTRY,
  );

  useEffect(() => {
    const progressData: IStatProgressInfo = {
      ...data?.companyCreationCompanyInfo?.legalDocumentInfo,
      ...data?.companyCreationCompanyInfo?.statutoryInformation,
      ...data?.companyCreationCompanyInfo?.statutoryContacts,
      ...data?.companyCreationCompanyInfo?.shadowCompanyInfo,
      companyCode,
      shadowCompanyCode,
    };
    setStatProgressData(progressData);

    const physicalAddressCountryCode = progressData?.physicalAddress?.country?.id;
    const legalAddressCountryCode = progressData?.legallyRegisteredAddress?.country?.id;

    if (legalAddressCountryCode) {
      getLegalRegionForCountry({ variables: { countryCode: legalAddressCountryCode } });
    }

    if (physicalAddressCountryCode) {
      getPhysicalRegionForCountry({ variables: { countryCode: physicalAddressCountryCode } });
    }
  }, [data]);

  const isAddressComplete = (addressKey: string): boolean => {
    const addr = statProgressData[addressKey as keyof IStatProgressInfo] as IAddress;

    let isRegionRequired = false;

    if (addressKey === 'physicalAddress') {
      isRegionRequired = physicalRegionData?.regionsByCountry?.length > 0;
    }

    if (addressKey === 'legallyRegisteredAddress') {
      isRegionRequired = legalRegionData?.regionsByCountry?.length > 0;
    }

    const isComplete: boolean =
      addr &&
      addr.city &&
      addr.city !== '' &&
      addr.country &&
      addr.postalZipCode &&
      addr.postalZipCode !== '' &&
      (isRegionRequired ? addr.stateProvinceRegion : true) &&
      addr.streetNumber &&
      addr.streetNumber !== '';

    return isComplete;
  };

  const handleCustomValidation = (key: string): boolean => {
    switch (key) {
      case 'statutoryYearEndMonth':
      case 'statutoryYearEndDay':
        return !!(statProgressData.statutoryYearEndMonth && statProgressData.statutoryYearEndDay);
      case 'legallyRegisteredAddress':
        return isAddressComplete('legallyRegisteredAddress');
      case 'physicalAddress':
        return isAddressComplete('physicalAddress');
      case 'statutoryController':
        return statProgressData.statutoryController.length > 0;
      case 'regionalControllers':
        return statProgressData.regionalControllers.length > 0;
      case 'fsTaxRepContacts':
        return statProgressData.fsTaxRepContacts.length > 0;
      case 'treasuryRepContacts':
        return statProgressData.treasuryRepContacts.length > 0;
      case 'bpoContacts':
        return statProgressData.bpoContacts.length > 0;
      default:
        return false;
    }
  };

  const doesItemHaveValue = (itemKey: string): boolean => {
    const value = statProgressData[itemKey as keyof IStatProgressInfo];
    const customValidationKeys = [
      'statutoryYearEndMonth',
      'statutoryYearEndDay',
      'legallyRegisteredAddress',
      'physicalAddress',
      'statutoryController',
      'regionalControllers',
      'fsTaxRepContacts',
      'treasuryRepContacts',
      'bpoContacts',
    ];

    if (customValidationKeys.includes(itemKey)) {
      return handleCustomValidation(itemKey);
    }

    return value !== '' && value !== null && value !== undefined;
  };

  const generalItems: IRequiredItem[] = [];
  const legalDocItems: IRequiredItem[] = [];
  const contactItems: IRequiredItem[] = [];
  const statItems: IRequiredItem[] = [];
  const shadowCompanyItems: IRequiredItem[] = [];

  const getItems = (): IRequiredItem[] => {
    const isAuditFirmOther = statProgressData?.auditingFirm?.fieldName === 'Other';
    const items: IRequiredItem[] = [];

    generalItems.push({ key: 'companyCode', value: 'Company Code' });

    if (isNonTransactional) {
      legalDocItems.push(
        { key: 'fullLegalName', value: 'Full Legal Name' },
        { key: 'sapLegalName', value: 'SAP Legal Name' },
        { key: 'abbreviatedSapName', value: 'SAP Name (Short Form)' },
        { key: 'statutoryYearEndMonth', value: 'Statutory Year End' },
        { key: 'legallyRegisteredAddress', value: 'Legally Registered Address' },
        { key: 'physicalAddress', value: 'Physical Address' },
      );
    }

    contactItems.push(
      { key: 'statutoryController', value: 'Statutory Controller' },
      { key: 'regionalControllers', value: 'Regional Controller' },
      { key: 'fsTaxRepContacts', value: 'F/S Tax Rep' },
      { key: 'treasuryRepContacts', value: 'Treasury Rep' },
    );

    if (statProgressData?.isOneFinance) {
      contactItems.push({ key: 'bpoContacts', value: 'BPO Contact' });
    }

    statItems.push(
      { key: 'oversightGroup', value: 'Oversight Group' },
      { key: 'isMcaps', value: 'MCAPS Flag' },
      { key: 'isOneFinance', value: 'One Finance Flag' },
      { key: 'isFsAuditLegallyRequired', value: 'F/S Audit Legally Required by Regulator' },
      { key: 'einTaxNumber', value: 'EIN Tax Number' },
      { key: 'controllerMustBeFte', value: 'Controller Must Be FTE' },
      { key: 'auditingFirm', value: 'Auditing Firm' },
    );

    if (isAuditFirmOther) {
      statItems.push({ key: 'otherAuditingFirm', value: 'Other Auditing Firm' });
    }

    if (statProgressData?.isShadowCompanyNeeded) {
      shadowCompanyItems.push({ key: 'shadowCompanyCode', value: 'Shadow Company Code' });
    }

    return items.concat(generalItems, legalDocItems, contactItems, statItems, shadowCompanyItems);
  };

  const getGroups = (): IGroup[] => {
    const groups: IGroup[] = [];
    groups.push({ key: 'general', name: 'General', startIndex: 0, count: generalItems.length });

    if (isNonTransactional) {
      groups.push({
        key: 'legalDocumentInfo',
        name: 'Legal Doc Info',
        startIndex: generalItems.length,
        count: legalDocItems.length,
      });
    }

    groups.push({
      key: 'statutoryContacts',
      name: 'Statutory Contacts',
      startIndex: isNonTransactional
        ? generalItems.length + legalDocItems.length
        : generalItems.length,
      count: contactItems.length,
    });

    groups.push({
      key: 'statutoryInformation',
      name: 'Statutory Information',
      startIndex: isNonTransactional
        ? generalItems.length + legalDocItems.length + contactItems.length
        : generalItems.length + contactItems.length,
      count: statItems.length,
    });

    if (shadowCompanyItems.length > 0) {
      groups.push({
        key: 'shadowCompanyInfo',
        name: 'Shadow Company Info',
        startIndex: isNonTransactional
          ? generalItems.length + legalDocItems.length + contactItems.length + statItems.length
          : generalItems.length + contactItems.length + statItems.length,
        count: shadowCompanyItems.length,
      });
    }

    return groups;
  };

  const items = getItems();
  const groups = getGroups();

  return (
    <RequiredFieldsPanel
      closePanel={closePanel}
      doesItemHaveValue={doesItemHaveValue}
      items={items}
      groups={groups}
      loading={loading}
      error={error}
    />
  );
};

export default StatProgressPanel;
