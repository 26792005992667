import React, { FormEvent } from 'react';
import { IDropdownOption, Label, Stack, TextField } from '@fluentui/react';
import { Controller, FieldValues, UseFormMethods } from 'react-hook-form';
import ICompanyFinancialInfo from '../../../../utils/types/ICompanyFinancialInfo';
import MonthDayPicker from '../../../common/formFields/monthDayPicker/MonthDayPicker';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import MsReportingSalesLocationDropDown from '../../../companyCreation/details/MsReportingSalesLocationDropDown';
import { RequiredMarker } from '../../../common/formFields/RequiredMarker';

interface ICompanyFinancialInfoFormFormProps {
  form: UseFormMethods<FieldValues>;
  data: ICompanyFinancialInfo;
}

const CompanyFinancialInfoForm = ({
  form,
  data,
}: ICompanyFinancialInfoFormFormProps): JSX.Element => {
  const { errors, control, setValue } = form;
  const customLabelStyles = { paddingBottom: 0 };

  const onMsReportingSalesLocationChange = (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    const value = { id: option.key, name: option.text };
    setValue('salesLocationForReporting', value);
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <MonthDayPicker
        form={form}
        dueDateDay={data?.statutoryYearEndDay}
        dueDateMonth={data?.statutoryYearEndMonth}
        dayFormName="statutoryYearEndDay"
        monthFormName="statutoryYearEndMonth"
        label="Statutory Year End"
        required
      />
      <Controller
        as={TextField}
        id="financialType"
        name="financialType"
        label="Financial Type"
        control={control}
        defaultValue={data?.financialType || ''}
        errorMessage={errors?.financialType?.message}
        readOnly
        disabled
      />
      <Controller
        name="statutoryCurrency"
        control={control}
        defaultValue={data?.statutoryCurrency}
        rules={{
          required: {
            value: true,
            message: 'Please enter a Statutory Reporting Currency',
          },
        }}
        render={(): React.ReactElement => (
          <CurrencyCodeDropdown
            label={
              <Label style={customLabelStyles}>
                Statutory Reporting Currency
                <RequiredMarker />
              </Label>
            }
            propertyName="statutoryCurrency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('statutoryCurrency', newValue);
            }}
            defaultValue={data?.statutoryCurrency}
            errorMessage={errors?.statutoryCurrency?.message}
            width={500}
            required
          />
        )}
      />
      <Controller
        name="functionalCurrency"
        control={control}
        defaultValue={data?.functionalCurrency}
        rules={{
          required: {
            value: true,
            message: 'Please enter a Functional Currency',
          },
        }}
        render={(): React.ReactElement => (
          <CurrencyCodeDropdown
            label={
              <Label style={customLabelStyles}>
                Functional Currency
                <RequiredMarker />
              </Label>
            }
            propertyName="functionalCurrency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('functionalCurrency', newValue);
            }}
            defaultValue={data?.functionalCurrency}
            errorMessage={errors?.functionalCurrency?.message}
            width={500}
            required
          />
        )}
      />
      <Controller
        name="salesLocationForReporting"
        control={control}
        defaultValue={data?.salesLocationForReporting}
        render={(): JSX.Element => {
          return (
            <MsReportingSalesLocationDropDown
              defaultValue={data?.salesLocationForReporting?.id}
              onChange={onMsReportingSalesLocationChange}
            />
          );
        }}
      />
      <Controller
        as={TextField}
        id="taxJurisdictionCode"
        name="taxJurisdictionCode"
        label="Tax Jurisdiction Code"
        control={control}
        defaultValue={data?.taxJurisdictionCode || ''}
        errorMessage={errors?.taxJurisdictionCode?.message}
        required
        rules={{
          required: {
            value: true,
            message: 'Please enter a Tax Jurisdiction Code',
          },
          maxLength: {
            value: 100,
            message: 'Tax Jurisdiction Code cannot have more than 100 characters',
          },
        }}
      />
    </Stack>
  );
};

export default CompanyFinancialInfoForm;
