import { gql } from '@apollo/client';

const GET_ACCOUNTING_STANDARDS_QUERY = gql`
  {
    accountingStandards {
      id
      fieldName
    }
  }
`;
export default GET_ACCOUNTING_STANDARDS_QUERY;
