import { gql } from '@apollo/client';
import { IReview } from '../types/IReview';
import { IDocument } from './DocumentApi';

export interface IReviewDocumentsInput {
  reviewId: number;
  reviewDocuments: IDocument[];
}

export interface IReviewDocumentInput extends IDocument {
  reviewDto: IReview;
}

export const REVIEW_DOCUMENT_FRAGMENT = gql`
  fragment ReviewDocument on ReviewDocumentDto {
    id
    uploader {
      id
      displayName
      firstName
      lastName
    }
    uploadDate
    title
    url
    isDeleted
  }
`;

export const TRACKER_FRAGMENT = gql`
  fragment AgmTracker on AgmTrackerDto {
    trackerId
    processId
    agmTrackerActions {
      id
      actionDate
      actionType
      actionValue
      changedBy {
        displayName
      }
      comments
    }
    companyCode
    companyName
    reportingPeriodEndDate
    filingDueDate
    regionalControllerSignoffDate
    agmReviewId
    agmDueDate
    celaAssignees {
      id
      text: name
      graphId
    }
    status {
      id
      fieldName
    }
    latestStatusChangeAction {
      actionDate
      actionValue
      comments
      changedBy {
        displayName
      }
    }
    statController
    aging
    totalAging
    wwArea
    country
    owner
    fiscalYearId
    latestOwnerChangeAction {
      actionDate
      actionValue
      comments
      changedBy {
        displayName
      }
    }
    latestCommentAction {
      actionDate
      actionValue
      comments
      changedBy {
        displayName
      }
    }
  }
`;
export const REVIEW_FRAGMENT = gql`
  fragment Review on ReviewDto {
    id
    companyId
    status
    reviewApprovals {
      id
      status {
        id
        fieldName
      }
      reviewId
      approvalGroupName
      approvalDate
      approvalEntityAction
      approver {
        id
        displayName
      }
    }
    reviewComments {
      id
      reviewId
      text
      author {
        id
        displayName
      }
      timeStamp
      document {
        ...ReviewDocument
      }
    }
  }
  ${REVIEW_DOCUMENT_FRAGMENT}
`;
