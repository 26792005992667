import { IPersonaProps, Stack } from '@fluentui/react';
import React from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import searchForUsers from '../../../../utils/microsoftGraphApi/userSearch';
import IAgmReview, { IAgmReviewContact } from '../../../../utils/types/IAgmReview';
import ErrorHandlingPeoplePicker from '../../../common/formFields/ErrorHandlingPeoplePicker';

interface IAgmReviewAssigneeForm {
  form: UseFormMethods;
  agmReview: IAgmReview;
}

const AgmReviewAssigneeForm = ({ form, agmReview }: IAgmReviewAssigneeForm): JSX.Element => {
  const { control, errors, setValue } = form;

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };
  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Controller
        id="agmReviewContacts"
        name="agmReviewContacts"
        label="CELA Assignee"
        control={control}
        defaultValue={agmReview?.agmReviewContacts}
        render={() => (
          <ErrorHandlingPeoplePicker
            onChange={(items: IAgmReviewContact[]) => {
              const newContacts = [] as IAgmReviewContact[];
              items.forEach((contact) => {
                newContacts.push(contact);
              });
              setValue('agmReviewContacts', newContacts, { shouldDirty: true });
            }}
            removeButtonAriaLabel="Remove CELA Assignees"
            onResolveSuggestions={onResolveSuggestions}
            controlName="agmReviewContacts"
            labelText="CELA Assignee"
            defaultSelectedItems={agmReview?.agmReviewContacts}
            errorMessage={(errors?.agmReviewContacts as unknown as FieldError)?.message}
          />
        )}
      />
    </Stack>
  );
};

export default AgmReviewAssigneeForm;
