import {
  IStackTokens,
  ICommandBarStyles,
  DefaultPalette,
  mergeStyles,
  IStackItemStyles,
  NeutralColors,
} from '@fluentui/react';

export const commandBarStyles: ICommandBarStyles = {
  root: {
    background: NeutralColors.gray10,
    margin: 0,
    padding: 0,
  },
  primarySet: { justifyContent: 'flex-end', alignItems: 'center' },
};

export const commandBarWrapper = mergeStyles({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  alignItems: 'flex-end',
});

export const personaDetailsWrapper = mergeStyles({
  backgroundColor: DefaultPalette.neutralLight,
  flexGrow: 1,
  padding: 8,
});

const sideBysideFormChildrenGap = 20;
const userDetailStackChildrenGap = 25;
export const sideBySideFormFields: IStackItemStyles = {
  root: {
    width: `calc(50% - ${sideBysideFormChildrenGap}px)`,
  },
};
export const sideBySideWrapper: IStackTokens = {
  childrenGap: sideBysideFormChildrenGap,
  padding: '20px 0',
};
export const userDetailsStack: IStackTokens = { childrenGap: userDetailStackChildrenGap };
