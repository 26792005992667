import { Stack } from '@fluentui/react';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import CompanyCodeInput from '../CompanyCodeInput';
import { ICompanyCreationRequestDetails } from '../types';
import { IShadowCompanyCode } from './types';

interface IShadowCompanyCodeFormProps {
  form: UseFormMethods<IShadowCompanyCode>;
  companyCreationRequestData?: ICompanyCreationRequestDetails;
}

const ShadowCompanyCodeForm = ({
  form,
  companyCreationRequestData,
}: IShadowCompanyCodeFormProps): JSX.Element => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <CompanyCodeInput
        defaultValue={companyCreationRequestData?.shadowCompanyCode}
        form={form}
        codeLength={4}
        fieldName="shadowCompanyCode"
        label="Shadow Company Code"
      />
    </Stack>
  );
};

export default ShadowCompanyCodeForm;
