import React, { useEffect, useState } from 'react';
import { ChoiceGroup, Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { Controller, UseFormMethods } from 'react-hook-form';
import InlineFormInputErrorMessage from '../../../common/errorContent/InlineFormInputErrorMessage';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import {
  paperSizeOptions,
  signatureInkColorOptions,
  yesNoOptions,
  additionalProcessingTimeOptions,
} from '../../../../utils/types/CommonDropdownOptions';
import ICompanyDefaultAgmDetails from '../../../../utils/types/ICompanyDefaultAgmDetails';

interface ICompanyDefaultAgmDetailsFormFormProps {
  form: UseFormMethods<ICompanyDefaultAgmDetails>;
  data: ICompanyDefaultAgmDetails;
}

const CompanyDefaultAgmDetailsForm = ({
  form,
  data,
}: ICompanyDefaultAgmDetailsFormFormProps): JSX.Element => {
  const { errors, control, setValue, watch } = form;

  const [selectedAdditionalTimeOptions, setSelectedAdditionalTime] = useState<string[]>(() => {
    const values: string[] = [];
    let isDirty = false;
    if (data?.additionalProcessingTimeApostille) {
      values.push('APOSTILLE');
      isDirty = true;
    }
    if (data?.additionalProcessingTimeLegalization) {
      values.push('LEGALIZATION');
      isDirty = true;
    }
    if (data?.additionalProcessingTimeLocalSigner) {
      values.push('LOCALSIGNER');
      isDirty = true;
    }
    if (isDirty) {
      setValue('additionalProcessingTime', values, { shouldDirty: true });
    }
    return values;
  });

  useEffect(() => {
    setValue('additionalProcessingTime', selectedAdditionalTimeOptions);
  }, [selectedAdditionalTimeOptions]);

  const watchTaxDeadlineBeforeAfsDeadline = watch(
    'taxFilingDeadlineBeforeAfsDeadline',
    data?.taxFilingDeadlineBeforeAfsDeadline,
  );

  const watchSignatureRequiredDeadline = watch(
    'physicalSignatureRequired',
    data?.physicalSignatureRequired,
  );

  const watchInkColorRequired = watch('inkColorRequired', data?.inkColorRequired);

  const watchPaperSize = watch('paperSize', data?.paperSize);

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Controller
        name="agmDeadlineBeforeAfsDeadline"
        defaultValue={data?.agmDeadlineBeforeAfsDeadline}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="agmDeadlineBeforeAfsDeadline"
                label="Is the AGM deadline earlier than the AFS deadline?"
                name="agmDeadlineBeforeAfsDeadline"
                defaultSelectedKey={data?.agmDeadlineBeforeAfsDeadline?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('agmDeadlineBeforeAfsDeadline', newValue.key === 'true', {
                    shouldDirty: true,
                  });
                  if (newValue.key === 'false') {
                    setValue('agmDeadline', null, { shouldDirty: true });
                  }
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={errors?.agmDeadlineBeforeAfsDeadline?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="taxFilingDeadlineBeforeAfsDeadline"
        defaultValue={data?.taxFilingDeadlineBeforeAfsDeadline}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="taxFilingDeadlineBeforeAfsDeadline"
                label="Is the tax filing deadline earlier than the AFS deadline?"
                name="taxFilingDeadlineBeforeAfsDeadline"
                defaultSelectedKey={data?.taxFilingDeadlineBeforeAfsDeadline
                  ?.toString()
                  .toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('taxFilingDeadlineBeforeAfsDeadline', newValue.key === 'true', {
                    shouldDirty: true,
                  });
                  if (newValue.key === 'false') {
                    setValue('taxFilingDeadline', null, { shouldDirty: true });
                  }
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={errors?.taxFilingDeadlineBeforeAfsDeadline?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="taxFilingDeadline"
        control={control}
        defaultValue={data?.taxFilingDeadline}
        render={({ value }): React.ReactElement => (
          <div role="form">
            <UtcDatePicker
              label="Tax Filing Deadline"
              disabled={watchTaxDeadlineBeforeAfsDeadline !== true}
              allowTextInput
              disableAutoFocus={false}
              value={value}
              textField={{
                name: 'taxFilingDeadline',
                errorMessage: errors?.taxFilingDeadline?.message,
              }}
              onSelectDate={(utcDateString: string): void => {
                setValue('taxFilingDeadline', utcDateString, {
                  shouldDirty: value !== utcDateString ?? true,
                });
              }}
            />
          </div>
        )}
      />
      <Controller
        name="externalAfsFilingRequired"
        defaultValue={data?.externalAfsFilingRequired}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="externalAfsFilingRequired"
                label="Is there a formal AFS external filing required?"
                name="externalAfsFilingRequired"
                defaultSelectedKey={data?.externalAfsFilingRequired?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('externalAfsFilingRequired', newValue.key === 'true', {
                    shouldDirty: true,
                  });
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={errors?.externalAfsFilingRequired?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="requiredToBeFiledWithAfs"
        defaultValue={data?.requiredToBeFiledWithAfs}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="requiredToBeFiledWithAfs"
                label="Do the AGM date and the FS date have to be aligned?"
                name="requiredToBeFiledWithAfs"
                defaultSelectedKey={data?.requiredToBeFiledWithAfs?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('requiredToBeFiledWithAfs', newValue.key === 'true', {
                    shouldDirty: true,
                  });
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={errors?.requiredToBeFiledWithAfs?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="canAfsBeExtended"
        defaultValue={data?.canAfsBeExtended}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="canAfsBeExtended"
                label="Can the AFS deadline be extended?"
                name="canAfsBeExtended"
                defaultSelectedKey={data?.canAfsBeExtended?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('canAfsBeExtended', newValue.key === 'true', { shouldDirty: true });
                }}
              />
              <InlineFormInputErrorMessage errorMessage={errors?.canAfsBeExtended?.message} />
            </>
          );
        }}
      />
      <Controller
        name="physicalSignatureRequired"
        defaultValue={data?.physicalSignatureRequired}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="physicalSignatureRequired"
                label="Is a hard copy signature required for filing?"
                name="physicalSignatureRequired"
                defaultSelectedKey={data?.physicalSignatureRequired?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('physicalSignatureRequired', newValue.key === 'true', {
                    shouldDirty: true,
                  });
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={errors?.physicalSignatureRequired?.message}
              />
            </>
          );
        }}
      />
      <Controller
        id="hardCopyShippingAddress"
        name="hardCopyShippingAddress"
        control={control}
        defaultValue={data?.hardCopyShippingAddress}
        render={({ onChange, value, name }): React.ReactElement => (
          <TextField
            label="Address for Hard Copies"
            disabled={watchSignatureRequiredDeadline !== true}
            value={value}
            type="string"
            multiline
            resizable={false}
            onChange={onChange}
            name={name}
            errorMessage={errors?.hardCopyShippingAddress?.message}
          />
        )}
      />
      <Controller
        name="inkColorRequired"
        defaultValue={data?.inkColorRequired}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="inkColorRequired"
                label="Is there a required ink color for the signatures?"
                name="inkColorRequired"
                defaultSelectedKey={data?.inkColorRequired?.toString().toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('inkColorRequired', newValue.key === 'true', { shouldDirty: true });
                  if (newValue.key === 'false') {
                    setValue('signatureInkColor', null);
                  }
                }}
              />
              <InlineFormInputErrorMessage errorMessage={errors?.inkColorRequired?.message} />
            </>
          );
        }}
      />
      <Controller
        name="signatureInkColor"
        control={control}
        defaultValue={data?.signatureInkColor}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Dropdown
            label="Signature Ink Color"
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption,
              index?: number,
            ): void => {
              setValue('signatureInkColor', option.key, { shouldDirty: true });
            }}
            options={signatureInkColorOptions}
            defaultSelectedKey={data?.signatureInkColor}
            disabled={watchInkColorRequired !== true}
            placeholder="Select an option"
            errorMessage={errors?.signatureInkColor?.message}
          />
        )}
      />
      <Controller
        name="originalsCount"
        control={control}
        as={TextField}
        type="number"
        label="How many originals are required"
        defaultValue={data?.originalsCount?.toString()}
        rules={{
          min: {
            value: 0,
            message: 'Please enter a positive number',
          },
        }}
        errorMessage={errors?.originalsCount?.message}
      />
      <Controller
        name="paperSize"
        control={control}
        defaultValue={data?.paperSize}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <Dropdown
            label="Paper Size"
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption,
              index?: number,
            ): void => {
              setValue('paperSize', option.key, { shouldDirty: true });
              if (option.key !== 'OTHER') {
                setValue('otherPaperSize', '');
              }
            }}
            options={paperSizeOptions}
            defaultSelectedKey={data?.paperSize}
            placeholder="Select an option"
            errorMessage={errors?.paperSize?.message}
          />
        )}
      />
      <Controller
        id="otherPaperSize"
        name="otherPaperSize"
        control={control}
        defaultValue={data?.otherPaperSize}
        render={({ onChange, value, name }): React.ReactElement => (
          <TextField
            label="Other Paper Size"
            disabled={watchPaperSize !== 'OTHER'}
            value={value}
            type="string"
            multiline
            resizable={false}
            onChange={onChange}
            name={name}
            errorMessage={errors?.otherPaperSize?.message}
          />
        )}
      />
      <Controller
        name="managementRepresentationLetterRequired"
        defaultValue={data?.managementRepresentationLetterRequired}
        control={control}
        render={() => {
          return (
            <>
              <ChoiceGroup
                id="managementRepresentationLetterRequired"
                label="Is a Management Representation Letter Required?"
                name="managementRepresentationLetterRequired"
                defaultSelectedKey={data?.managementRepresentationLetterRequired
                  ?.toString()
                  .toLowerCase()}
                options={yesNoOptions}
                onChange={(e, newValue): void => {
                  setValue('managementRepresentationLetterRequired', newValue.key === 'true', {
                    shouldDirty: true,
                  });
                }}
              />
              <InlineFormInputErrorMessage
                errorMessage={errors?.managementRepresentationLetterRequired?.message}
              />
            </>
          );
        }}
      />
      <Controller
        name="auditorFeesCurrencyCode"
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <CurrencyCodeDropdown
            propertyName="auditorFeesCurrencyCode"
            label="Auditor Currency"
            handleChange={(newValue: string, valueKey: string): void => {
              setValue('auditorFeesCurrencyCode', newValue, { shouldDirty: true });
            }}
            defaultValue={data?.auditorFeesCurrencyCode}
            errorMessage={errors?.auditorFeesCurrencyCode?.message}
          />
        )}
        defaultValue={data?.auditorFeesCurrencyCode}
      />
      <Controller
        name="additionalProcessingTime"
        control={control}
        render={() => {
          return (
            <>
              <Dropdown
                label="Additional Processing Time"
                onChange={(event, item): void => {
                  setSelectedAdditionalTime(
                    item.selected
                      ? [...selectedAdditionalTimeOptions, item.key.toString()]
                      : selectedAdditionalTimeOptions.filter((key) => key !== item.key),
                  );
                  setValue('additionalProcessingTime', item, { shouldDirty: true });
                }}
                multiSelect
                options={additionalProcessingTimeOptions}
                defaultSelectedKeys={selectedAdditionalTimeOptions}
                placeholder="Select an option"
                errorMessage={errors?.additionalProcessingTimeApostille?.message}
              />
            </>
          );
        }}
      />
    </Stack>
  );
};

export default CompanyDefaultAgmDetailsForm;
