import React, { useState } from 'react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { useLazyQuery } from '@apollo/client';
import { ProgressIndicator } from '@fluentui/react';
import UserDetailsForm from './UserDetailsForm';
import graphApiRequest from '../../utils/ApiProvider';

import {
  StatUser,
  GetUserQuery,
  EditUserMutation,
  CreateUserMutation,
} from '../../utils/statApi/UsersApi';
import UserDetailsGraphSearch from './UserDetailsGraphSearch';
import { IGraphUserObject } from '../../utils/types/Users';
import { IServiceContact } from '../companies/serviceContacts/interfaces';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';

interface UserDetailPanelProps {
  onDismiss: () => void;
  selectedUser: StatUser;
}

const UserDetailsPanel = (props: UserDetailPanelProps): JSX.Element => {
  const { onDismiss, selectedUser } = props;
  const [graphUserSearchErrors, setGraphUserSearchErrors] = useState(null);
  const [userAlias, setUserAlias] = useState(null);
  const [graphUser, setGraphUser] = useState<IGraphUserObject>(null);
  const [userImage, setUserImage] = useState(null);
  const [statUser, setStatUser] = useState(null);
  const [getStatUser, { data, loading }] = useLazyQuery(GetUserQuery, {
    fetchPolicy: 'no-cache',
  });
  const getUserFromGraph = (graphGUID: string): void => {
    const graphRequest = graphApiRequest(
      `https://graph.microsoft.com/v1.0/users/${graphGUID}?$select=id,displayName,givenName,surname,mail,onPremisesExtensionAttributes`,
    );
    const graphImageRequest = graphApiRequest(
      `https://graph.microsoft.com/v1.0/users/${graphGUID}/photo/$value`,
      false,
    );

    graphRequest.then((graphUserResult: IGraphUserObject) => {
      const userError = graphUserResult.error;
      if (userError) {
        setGraphUserSearchErrors(userError);
      } else {
        setGraphUserSearchErrors(false);
        setGraphUser(graphUserResult);
        if (!selectedUser) {
          getStatUser({ variables: { graphGUID: graphUserResult.id } });
        }
      }
    });
    graphImageRequest
      .then((graphImageResult: Response) => {
        return graphImageResult.blob();
      })
      .then((blob: Blob) => {
        const imageURL = URL.createObjectURL(blob);
        setUserImage(imageURL);
      });
  };

  React.useEffect(() => {
    if (!loading && data && data.statUser) {
      setStatUser(data.statUser);
    }
  }, [data]);

  React.useEffect(() => {
    if (selectedUser) {
      getStatUser({ variables: { graphGUID: selectedUser.graphGUID } });
      getUserFromGraph(selectedUser.graphGUID);
    }
    return function cleanup(): void {
      setGraphUser(null);
      setUserImage('');
    };
  }, [selectedUser]);

  const onUserSearch = (person: IServiceContact): void => {
    setUserAlias(person.alias);
    getUserFromGraph(person.graphId);
  };
  const onResetPanel = (): void => {
    setStatUser(null);
    setGraphUser(null);
    setGraphUserSearchErrors(false);
    onDismiss();
  };
  const onAddMutationSuccessUpdate = (): void => {
    onResetPanel();
  };
  const onEditMutationSuccessUpdate = (): void => {
    /**
     * Apollo client has its own cache update strategy on edit based on key
     */
    onResetPanel();
  };

  const renderAddUserForm = (): JSX.Element => {
    if (loading) {
      return (
        <ProgressIndicator
          label="Checking if user exists in Stat"
          description="Loading user from STAT"
        />
      );
    }
    const { id, displayName, givenName, surname, mail, onPremisesExtensionAttributes } = graphUser;
    const newStatUser: StatUser = {
      graphGUID: id,
      displayName,
      alias: userAlias,
      emailAddress: mail,
      roleId: 2,
      firstName: givenName,
      lastName: surname,
      isActive: true,
      userRoles: null,
      personnelNumber: onPremisesExtensionAttributes?.extensionAttribute4,
    };
    return (
      <UserDetailsForm
        cameFromAddFlow={false}
        alias={userAlias}
        graphUser={graphUser}
        userImage={userImage}
        statUser={newStatUser}
        graphUserSearchErrors={graphUserSearchErrors}
        mutationKey="createStatUser"
        mutationQuery={CreateUserMutation}
        onMutationSuccessUpdate={onAddMutationSuccessUpdate}
      />
    );
  };
  const renderUserEditForm = (): JSX.Element => {
    const isSearchedUserInStat = !selectedUser;

    return (
      <UserDetailsForm
        cameFromAddFlow={isSearchedUserInStat}
        alias={statUser.alias}
        graphUser={graphUser}
        graphUserSearchErrors={graphUserSearchErrors}
        userImage={userImage}
        statUser={statUser}
        mutationKey="updateStatUser"
        mutationQuery={EditUserMutation}
        onMutationSuccessUpdate={onEditMutationSuccessUpdate}
      />
    );
  };
  const renderUserStep = (): JSX.Element => {
    if (statUser) {
      return renderUserEditForm();
    }
    if (selectedUser && loading) {
      return (
        <ProgressIndicator label="Loading user from STAT" description="Loading user from STAT" />
      );
    }
    if (!graphUser) {
      return <UserDetailsGraphSearch onUserSearch={onUserSearch} />;
    }
    return renderAddUserForm();
  };
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={statUser ? 'Edit User' : 'Add User'}
      isOpen
      onDismiss={onResetPanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close User Add/Edit"
    >
      <div data-testid="user-detail-panel">{renderUserStep()}</div>
    </CoherencePanel>
  );
};
export default UserDetailsPanel;
