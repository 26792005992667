import { gql } from '@apollo/client';

export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on NotificationDto {
    id
    header
    content
    ordinal
    startDate
    endDate
    createdDate
    notificationType {
      id
      fieldName
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    notifications {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($notification: NotificationDtoInput!) {
    updateNotification(notification: $notification) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const GET_NOTIFICATION_TYPES_QUERY = gql`
  query getNotificationTypes {
    notificationTypes {
      id
      fieldName
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation createNotification($notification: NotificationDtoInput!) {
    createNotification(notification: $notification) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const UPDATE_NOTIFICATION_ORDINAL = gql`
  mutation updateNotificationOrdinal($notificationId: Int!, $swappedNotificationId: Int!) {
    updateNotificationOrdinal(
      notificationId: $notificationId
      swappedNotificationId: $swappedNotificationId
    ) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($notificationId: Int!) {
    deleteNotification(notificationId: $notificationId) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
