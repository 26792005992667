enum StatDateFormats {
  Default = 'MM/D/YYYY', // 10/2/2019
  TwoDigitDate = 'MM/DD/YYYY', // 10/02/2019
  TimeZone = 'MM/D/YYYY ZZ', // 10/2/2019
  DaySimple = 'MMM D, YYYY',
  DaySimpleNoComma = 'MMM D (YYYY)',
  DaySimpleNoYear = 'MMM D', // Jun 30
  DayBreakout = 'MMM D, YYYY (ddd)', // Aug 31, 2019 (Sat)
  NoYear = 'MMM D',
  DayWithTime = 'MMM D, YYYY h:mm:ss a', // Aug 31, 2019 12:00:00 pm
}
export default StatDateFormats;
