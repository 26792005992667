import React from 'react';
import { Stack } from '@fluentui/react';
import SiteError from '../common/errorContent/SiteError';
import { verticalCenterStack, centeredWrapper } from '../../app/common/styles/CommonStyleObjects';

interface IErrorPageProps {
  message: string;
  useCardStyle?: boolean;
}

const ErrorPage: React.FunctionComponent<IErrorPageProps> = (
  props: IErrorPageProps,
): JSX.Element => {
  const { message, useCardStyle } = props;
  const style = useCardStyle ? '' : verticalCenterStack;

  return (
    <div className={`ms-Grid  ${style}`}>
      <Stack className={`ms-Grid-row ${centeredWrapper}`}>
        <SiteError
          className="ms-Grid-col ms-sm11 ms-l10 ms-xl8 ms-xxl6 ms-xxxl6"
          headerMessage="Looks like we have some problems :("
          contentMessage={`An error has occurred in the STAT tool.  ${message}`}
          actionButtonsElement={<></>}
        />
      </Stack>
    </div>
  );
};

export default ErrorPage;
