import { gql } from '@apollo/client';

const PROCESS_TYPE_LIST_QUERY = gql`
  query processTypesForCompany($companyCode: String!) {
    processTypesForCompany(companyCode: $companyCode) {
      name
      uri
    }
  }
`;
export const PROCESS_TYPE_BY_URI = gql`
  query ProcessTypeByUri($processTypeUri: String!) {
    processTypeByUri(processTypeUri: $processTypeUri) {
      id
      name
      description
      uri
    }
  }
`;
export const ALL_PROCESS_TYPE_LIST_QUERY = gql`
  query allProcessTypes {
    allProcessTypes {
      id
      fieldName: name
    }
  }
`;

export const PROCESS_TYPE_WITH_TEMPLATES_LIST_QUERY = gql`
  query processTypesWithTemplates {
    processTypesWithTemplates {
      id
      fieldName: name
    }
  }
`;

export const PROCESS_TYPES = gql`
  query ProcessTypes {
    allProcessTypes {
      key: id
      text: name
    }
  }
`;
export const GET_PROCESS_TYPE_WITH_MILESTONE_POOL = gql`
  query ProcessTypeWithMilestonePool($processTypeId: Int!) {
    processTypeWithMilestonePool(processTypeId: $processTypeId) {
      id
      name
      milestoneTemplates {
        id
        name
        description
        attestationText
        milestoneRules
        signoffRoleIds
        processTypePoolId
        milestoneCategoryId
        milestoneCategoryName
      }
    }
  }
`;
export default PROCESS_TYPE_LIST_QUERY;
