import { IPanelProps, IPanelHeaderRenderer, FontSizes, Panel } from '@fluentui/react';
import React from 'react';

const subheaderContainerStyles: React.CSSProperties = {
  marginLeft: 48,
  marginRight: 48,
  marginBottom: 15,
};

const headerStyles: React.CSSProperties = {
  fontSize: FontSizes.size24,
  padding: '0 40px',
};

const subHeaderStyles: React.CSSProperties = {
  width: '100%',
};

interface ICoherencePanelWithSubheader extends IPanelProps {
  subheader: JSX.Element;
}

const CoherencePanelWithSubheader = (props: ICoherencePanelWithSubheader): JSX.Element => {
  const { children, subheader, headerText } = props;

  const headerRenderer = (
    _headerProps?: IPanelProps,
    _defaultRender?: IPanelHeaderRenderer,
    headerTextId?: string | undefined,
  ): JSX.Element => {
    const header: JSX.Element = (
      <h1 id={headerTextId} style={headerStyles}>
        {headerText}
      </h1>
    );
    const subHeader: JSX.Element = <div style={subheaderContainerStyles}>{subheader}</div>;
    return (
      <div style={subHeaderStyles}>
        {header}
        {subHeader}
      </div>
    );
  };

  return (
    <Panel {...props} onRenderHeader={headerRenderer}>
      {children}
    </Panel>
  );
};
export default CoherencePanelWithSubheader;
