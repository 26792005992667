import { IStackStyles, mergeStyles, IStyle } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import {
  FOOTER_SMALL_BREAKPOINT,
  FOOTER_X_SMALL_BREAKPOINT,
  MOBILE_NAV_MAX_WIDTH_BREAKPOINT,
} from '../../app/common/constants/SiteConstants';

export const FooterStyles: IStackStyles = {
  root: {
    height: '48px',
    marginLeft: '48px',
    display: 'flex',
    alignContent: 'flex-end',
    borderTop: `1px solid ${CoherenceTheme.palette.neutralLight}`,
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        marginLeft: 0,
      },
    },
  },
};

export const copyrightStyle = mergeStyles({
  marginRight: 56,
  marginLeft: 86,
  selectors: {
    [FOOTER_X_SMALL_BREAKPOINT]: {
      display: 'none',
    },
  },
});

export const privacyLinkStyle = {
  root: {
    selectors: {
      [FOOTER_SMALL_BREAKPOINT]: {
        display: 'none',
      },
    },
  } as IStyle,
};
