import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Stack } from '@fluentui/react';
import moment from 'moment';
import FileSelectorItem from '../../../common/formFields/fileUpload/fileSelectorItem';
import { DELETE_DOCUMENT_MILESTONE } from '../../../../utils/statApi/MilestoneApi';
import StatDateFormats from '../../../../utils/types/DateFormats';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IDocument } from '../../../../utils/statApi/DocumentApi';

interface IMilestoneDocumentsProps {
  documents: IDocument[];
  isMilestoneComplete: boolean;
}

const MilestoneDocuments = ({
  documents,
  isMilestoneComplete,
}: IMilestoneDocumentsProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [documentList, setDocumentList] = useState(documents);

  useEffect(() => {
    setDocumentList([...documents]);
  }, [documents]);

  const [deleteMilestoneDocumentMutation, { loading: mutationLoading, error: mutationError }] =
    useMutation(DELETE_DOCUMENT_MILESTONE, {
      onCompleted: () => {
        setIsDeleting(false);
      },
      onError() {
        setIsDeleting(false);
      },
    });

  const deleteFileFromDatabase = (documentId: number): void => {
    setIsDeleting(true);
    deleteMilestoneDocumentMutation({
      variables: {
        milestoneDocumentId: documentId,
      },
    });
    setDocumentList(documentList.filter((doc) => doc.id !== documentId));
  };

  const uploadedDocuments = documentList
    .filter((doc: IDocument) => !doc.isDeleted)
    .map((document: IDocument) => {
      return (
        <Stack key={document.id}>
          <FileSelectorItem
            url={document.url}
            index={document.id}
            user={document.uploader}
            fileName={document.title}
            allowDownload
            allowViewInTab
            key={document.id}
            uiDisabled={isDeleting}
            isMilestoneComplete={isMilestoneComplete}
            onRemoveFromSelected={deleteFileFromDatabase}
            activity={`Uploaded ${moment(document.uploadDate).format(StatDateFormats.DayBreakout)}`}
          />
        </Stack>
      );
    });

  return (
    <>
      <LoadingErrorMessage loading={mutationLoading} error={mutationError} />
      <h2>Files Uploaded:</h2>
      {uploadedDocuments.length === 0 && (
        <span>There have not been any documents uploaded yet.</span>
      )}
      <Stack tokens={{ childrenGap: 10 }}>{uploadedDocuments}</Stack>
    </>
  );
};

export default MilestoneDocuments;
