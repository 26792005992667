import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_PROCESS_TEMPLATE_MUTATION } from '../../../utils/statApi/ProcessTemplateApi';

import { IProcessTemplate, IProcessTemplateInput } from '../../../utils/types/IProcessTemplate';
import ActionButtons from '../../common/ActionButtons';
import ProcessTemplateEditForm from '../ProcessTemplateEditForm';

interface IProcessTemplateEditPanelProps {
  closePanel: () => void;
  processTemplate: IProcessTemplate;
}

const ProcessTemplateEditPanel = ({
  closePanel,
  processTemplate,
}: IProcessTemplateEditPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit } = form;

  const [saveProcessTemplateEdits, { loading, error }] = useMutation(
    UPDATE_PROCESS_TEMPLATE_MUTATION,
    {
      onCompleted: (): void => {
        closePanel();
      },
    },
  );

  const onSubmit = (processTemplateInput: IProcessTemplateInput): void => {
    const processTemplateInputDto: IProcessTemplateInput = {
      id: processTemplate.id,
      name: processTemplateInput.name,
      description: processTemplateInput.description,
      processTypeId: processTemplate.processType.id,
    };

    saveProcessTemplateEdits({
      variables: {
        processTemplateInput: processTemplateInputDto,
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Process Template"
      isOpen
      hasCloseButton
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Edit Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(onSubmit)}
          saveTitle="Save Process Template"
          saveLabel="Save Process Template"
          cancelTitle="Cancel Process Template Edit"
          cancelLabel="Cancel Process Template Edit"
        />
      )}
    >
      <ProcessTemplateEditForm
        mutationLoading={loading}
        mutationError={error}
        processTemplate={processTemplate}
        onSubmit={onSubmit}
        form={form}
      />
    </CoherencePanel>
  );
};

export default ProcessTemplateEditPanel;
