import { useQuery } from '@apollo/client';
import { Spinner } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addDurationToMilestones } from '../../../utils/MilestoneHelpers';
import { PROCESS_TEMPLATE_DETAIL_QUERY } from '../../../utils/statApi/ProcessTemplateApi';
import { IProcessTemplate, IProcessTemplateMilestone } from '../../../utils/types/IProcessTemplate';
import { IProcessTemplateTypeParams } from '../../common/ParamTypes';
import ProcessTemplateMilestoneContent from './milestoneList/ProcessTemplateMilestoneContent';
import ProcessTemplateMilestoneListCard from './milestoneList/ProcessTemplateMilestoneListCard';

interface IProcessTemplateMilestoneList {
  processTemplateVersionId: number;
}

const ProcessTemplateMilestoneList = ({
  processTemplateVersionId,
}: IProcessTemplateMilestoneList): JSX.Element => {
  const { processTemplateId } = useParams<IProcessTemplateTypeParams>();
  const { data, loading, error } = useQuery(PROCESS_TEMPLATE_DETAIL_QUERY, {
    variables: {
      processTemplateId: parseInt(processTemplateId, 10),
      processTemplateVersionId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [processTemplateMilestonesWithDurations, setProcessTemplateMilestonesWithDurations] =
    useState([]);

  const { processTemplateDetail } = data || { processTemplateDetail: {} as IProcessTemplate };

  useEffect(() => {
    if (processTemplateDetail && processTemplateDetail.templateMilestones) {
      const defaultMilestones = addDurationToMilestones(processTemplateDetail.templateMilestones);
      setProcessTemplateMilestonesWithDurations(defaultMilestones);
    }
  }, [processTemplateDetail]);

  return (
    <>
      {loading && !error && <Spinner label="Loading Milestones" />}
      {!loading && processTemplateMilestonesWithDurations.length === 0 && (
        <span>
          There are no milestones for this process template. You can add milestones using the Manage
          Milestones button.
        </span>
      )}
      {!loading &&
        processTemplateMilestonesWithDurations.map(
          (milestone: IProcessTemplateMilestone): JSX.Element => {
            return (
              <ProcessTemplateMilestoneListCard key={milestone.id} milestone={milestone}>
                <ProcessTemplateMilestoneContent milestone={milestone} />
              </ProcessTemplateMilestoneListCard>
            );
          },
        )}
    </>
  );
};

export default ProcessTemplateMilestoneList;
