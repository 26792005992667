import React from 'react';
import { useMutation, DocumentNode } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { IContextualMenuProps } from '@fluentui/react';

import IDividends from '../../../utils/types/IDividends';
import DividendForm from '../detail/forms/DividendForm';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ActionButtons from '../../common/ActionButtons';
import CanAccess from '../../common/canAccess/CanAccess';
import { IFiscalYearParams, IProcessIdParams } from '../../common/ParamTypes';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

export interface IFiscalYearDividendsEditPanelProps {
  dividends: IDividends;
  closePanel: () => void;
  mutation: DocumentNode;
}
const FiscalYearDividendsEditPanel: React.FunctionComponent<IFiscalYearDividendsEditPanelProps> = ({
  closePanel,
  dividends,
  mutation,
}: IFiscalYearDividendsEditPanelProps): JSX.Element => {
  const { companyCode } = useParams<IFiscalYearParams>();
  const dividendsForm = useForm();
  const history = useHistory();
  const location = useLocation();
  const onReviewPage = location.pathname.indexOf('/dividends-review/') !== -1;
  const shouldStartReview = onReviewPage || dividends.dividendReviewId > 0;

  const { errors, setError, handleSubmit } = dividendsForm;

  const { processId } = useParams<IProcessIdParams>();

  const [updateDividends, { loading: mutationLoading, error: mutationError }] = useMutation(
    mutation,
    {
      onCompleted: (data) => {
        const dividendReviewId =
          data?.updateDividends?.dividendReviewId ||
          data?.updateDividendsFromProcess?.dividends?.dividendReviewId;
        if (dividendReviewId && !onReviewPage) {
          history.push(`/companies/${companyCode}/dividends-review/${dividendReviewId}`);
        } else {
          closePanel();
        }
      },
    },
  );

  const handleOnSaveClick = (formData: IDividends, addReview: boolean): void => {
    // GraphQL types don't like the typename property

    const dividendsSaveData = {
      id: dividends.id,
      companyId: dividends.companyId,
      currencyCode: formData.currencyCode,
      comments: formData.comments,
      declaredDate: dividends.declaredDate,
      lessDividendReason: {
        id: formData.lessDividendReason?.id,
        fieldName: formData.lessDividendReason?.fieldName,
      },
      maxDividendAllowed: parseInt(formData.maxDividendAllowed.toString(), 10),
      noDividendDeclared: formData.noDividendDeclared,
      proposedDividendAllowed: parseInt(formData.proposedDividendAllowed.toString(), 10),
      dividendsFromCurrentYear: parseInt(formData.dividendsFromCurrentYear.toString(), 10),
      dividendsFromRetainedEarnings: parseInt(
        formData.dividendsFromRetainedEarnings.toString(),
        10,
      ),
      addReview,
    };

    if (dividendsSaveData.proposedDividendAllowed === 0 && !dividendsSaveData.noDividendDeclared) {
      setError('proposedDividendAllowed', {
        type: 'required',
        message: 'No Dividend Declared must be selected if Proposed Dividend Amount is zero',
      });
      return;
    }

    if (!Object.keys(errors).length) {
      updateDividends({
        variables: {
          dividends: {
            ...dividendsSaveData,
          },
          processId: parseInt(processId, 10),
        },
      });
    }
  };

  const handleSaveOnlyClick = (formData: IDividends): void => {
    handleOnSaveClick(formData, false);
  };

  const handleSaveAndReviewClick = (formData: IDividends): void => {
    handleOnSaveClick(formData, true);
  };

  const saveButtonMenuProps = (): IContextualMenuProps => {
    if (shouldStartReview) {
      return null;
    }

    const saveProps: IContextualMenuProps = {
      items: [
        {
          key: 'review',
          text: 'Save And Start Review',
          iconProps: { iconName: 'ReviewSolid' },
          onClick: () => {
            handleSubmit(handleSaveAndReviewClick)();
          },
        },
        {
          key: 'save',
          text: 'Save Only',
          iconProps: { iconName: 'Save' },
          onClick: () => {
            handleSubmit(handleSaveOnlyClick)();
          },
        },
      ],
    };

    return saveProps;
  };

  const saveText = (): string => {
    if (shouldStartReview) {
      return 'Save';
    }
    return 'Save And Start Review';
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Dividends"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      closeButtonAriaLabel="Close Dividends Edit"
      styles={CoherencePanelStyles}
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="DividendInfo:Update">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleSaveAndReviewClick)}
            saveLabel={saveText()}
            saveTitle={saveText()}
            cancelLabel="Cancel Dividends Edit"
            cancelTitle="Cancel Dividends Edit"
            split
            menuProps={saveButtonMenuProps()}
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleSaveAndReviewClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the dividends information to the server"
        />
        <DividendForm dividends={dividends} form={dividendsForm} />
      </form>
    </CoherencePanel>
  );
};

export default FiscalYearDividendsEditPanel;
