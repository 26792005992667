import React, { useState } from 'react';
import { Stack, Label, TooltipHost, IconButton, mergeStyles } from '@fluentui/react';
import { IMilestone } from '../Interfaces';
import {
  labelPrimaryStyles,
  datePickerFocused,
} from '../../../../app/common/styles/CommonStyleObjects';
import {
  CanEditDueDate,
  DueDateOffsetTooltipMessage,
  offsetDateIconStyles,
  offsetTooltipHostStyles,
} from './milestoneDueDateUtils';
import momentUtc from '../../../../utils/DateFormatter';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';

interface IMilestoneDueDatePicker {
  index: number;
  milestone: IMilestone;
  changeDueDate: (dueDateToSave: string, milestoneId: number) => void;
  focusedMilestoneId: number;
  minMilestoneDateChange: Date;
  maxMilestoneDateChange: Date;
}

const MilestoneDueDatePicker: React.FunctionComponent<IMilestoneDueDatePicker> = ({
  index,
  milestone,
  changeDueDate,
  focusedMilestoneId,
  minMilestoneDateChange,
  maxMilestoneDateChange,
}: IMilestoneDueDatePicker): JSX.Element => {
  const [dueDate, setDueDate] = useState(milestone.dueDate?.toString());

  const formatDate = (date: Date): Date => {
    return new Date(momentUtc(date));
  };

  const handleSelectDate = (selectedDateString: string): void => {
    changeDueDate(selectedDateString, milestone.id);
    setDueDate(selectedDateString);
  };

  const disabled = (): boolean => {
    return !CanEditDueDate(milestone);
  };

  const utcDatePickerStackItemStyle = mergeStyles({
    width: '100%',
  });

  return (
    <Stack className={utcDatePickerStackItemStyle}>
      <Stack.Item>
        <Stack horizontal verticalAlign="center">
          <Label
            htmlFor={`milestone-${milestone.id}`}
            styles={milestone.id === focusedMilestoneId ? labelPrimaryStyles : null}
          >
            {milestone.title}
          </Label>
          <TooltipHost
            content={DueDateOffsetTooltipMessage(milestone.minimumDuration)}
            id="tooltip"
            styles={offsetTooltipHostStyles}
          >
            <IconButton
              aria-describedby="tooltip"
              ariaLabel={`Due date information for ${milestone.title}`}
              iconProps={{ iconName: 'Info' }}
              styles={offsetDateIconStyles}
            />
          </TooltipHost>
        </Stack>
      </Stack.Item>
      <Stack.Item className={utcDatePickerStackItemStyle}>
        <UtcDatePicker
          id={`milestone-${milestone.id}`}
          // Adds primary focus to milestone due date that was clicked on in milestone list
          className={milestone.id === focusedMilestoneId ? datePickerFocused : ''}
          textField={{
            name: 'dueDate',
          }}
          ariaLabel={`${milestone.title}`}
          disabled={disabled()}
          minDate={minMilestoneDateChange !== null ? formatDate(minMilestoneDateChange) : null}
          maxDate={maxMilestoneDateChange !== null ? formatDate(maxMilestoneDateChange) : null}
          value={dueDate}
          onSelectDate={handleSelectDate}
        />
      </Stack.Item>
    </Stack>
  );
};

export default MilestoneDueDatePicker;
