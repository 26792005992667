import { Label, Stack } from '@fluentui/react';
import React, { useContext } from 'react';
import UserContext from '../../../utils/authorization/UserContext';
import momentUtc from '../../../utils/DateFormatter';
import { IDocument } from '../../../utils/statApi/DocumentApi';
import { StatUser } from '../../../utils/statApi/UsersApi';
import StatDateFormats from '../../../utils/types/DateFormats';
import FileListUpload from '../formFields/fileUpload/fileSelector';
import FileSelectorItem from '../formFields/fileUpload/fileSelectorItem';

interface IDocumentsFormProps {
  documents: IDocument[];
  attachDocument: (files: IDocument[]) => Promise<void>;
  uploadInProgress: (isUploading: boolean) => void;
  deleteDocument: (index: number) => void;
  maxOfOne?: boolean;
}

const DocumentsForm = ({
  documents,
  attachDocument,
  deleteDocument,
  maxOfOne = false,
  uploadInProgress,
}: IDocumentsFormProps): JSX.Element => {
  const user: StatUser = useContext(UserContext);

  const addDocument = async (files: IDocument[]): Promise<void> => {
    attachDocument(files);
  };

  const renderDocumentList = (): JSX.Element => {
    const list = documents || [];
    return list.length ? (
      <Stack tokens={{ childrenGap: 5 }}>
        {list.map((doc: IDocument, index: number) => {
          return (
            <FileSelectorItem
              key={doc.url}
              url={doc.url}
              index={index}
              user={doc.uploader ? doc.uploader : user}
              fileName={doc.title}
              allowDownload
              allowViewInTab
              uiDisabled={false}
              allowDelete
              onRemoveFromSelected={deleteDocument}
              activity={`Uploaded ${momentUtc(doc.uploadDate, StatDateFormats.DayBreakout, true)}`}
            />
          );
        })}
      </Stack>
    ) : (
      <span>A document has not been uploaded yet.</span>
    );
  };

  const headerStyles = { marginTop: 15, marginBottom: 5 };

  return (
    <>
      <Label>Documents</Label>
      <FileListUpload
        onFileUploading={(): void => uploadInProgress(true)}
        onFileUploadError={(): void => uploadInProgress(false)}
        onFileUploadComplete={addDocument}
        autoUpload
        allowMultiple={!maxOfOne}
      />
      <h3 style={headerStyles}>Files Uploaded:</h3>
      <Stack tokens={{ childrenGap: 10 }}>{renderDocumentList()}</Stack>
    </>
  );
};

export default DocumentsForm;
