import React, { useState } from 'react';
import { ActionButton, FontIcon } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { IMilestoneRule } from '../Interfaces';
import FiscalYearAgmStatusEditPanel from '../../fiscalYears/FiscalYearAgmStatusEditPanel';
import {
  GET_PROCESS_AGM_STATUS,
  EDIT_AGM_STATUS_FROM_PROCESS_MUTATION,
} from '../../../../utils/statApi/ProcessApi';
import { warningClass, iconClass, completeClass } from './Gate.Styles';
import useIsMounted from '../../../common/IsMountedHook';
import { ICompanyCodeParams, IProcessIdParams } from '../../../common/ParamTypes';
import { UPDATE_ANNUAL_GENERAL_MEETING } from '../../../../utils/statApi/AnnualFilingRequirementsApi';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';

interface IDocumentGateProps {
  rule: IMilestoneRule;
  milestoneId: number;
  disabled: boolean;
}

const CelaAgmStatusGate = ({ rule, milestoneId, disabled }: IDocumentGateProps): JSX.Element => {
  const [panelIsOpen, setIsPanelOpen] = useState(false);
  const isMounted = useIsMounted();
  const { processId } = useParams<IProcessIdParams>();
  const intProcessId = parseInt(processId, 10);
  const { data, loading, error, refetch } = useQuery(GET_PROCESS_AGM_STATUS, {
    variables: { processId: intProcessId },
    fetchPolicy: 'network-only',
  });

  const { companyCode } = useParams<ICompanyCodeParams>();

  const closePanel = (): void => {
    if (isMounted.current) {
      setIsPanelOpen(false);
    }
  };

  const buttonText = rule.isComplete ? 'View AGM Status' : 'Update AGM Status';

  return (
    <>
      <ActionButton
        onClick={(): void => {
          setIsPanelOpen(true);
        }}
      >
        <FontIcon
          className={`${iconClass} ${rule.isComplete ? completeClass : warningClass}`}
          iconName={rule.isComplete ? 'checkmark' : 'warning'}
        />
        {buttonText}
      </ActionButton>
      <LoadingErrorMessage loading={loading} error={error} />
      {panelIsOpen && !loading && !error && (
        <FiscalYearAgmStatusEditPanel
          agmStatus={data.companyFiscalYearAgmStatus}
          closePanel={closePanel}
          fiscalYearMutation={EDIT_AGM_STATUS_FROM_PROCESS_MUTATION}
          agmMutation={UPDATE_ANNUAL_GENERAL_MEETING}
          companyCode={companyCode}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default CelaAgmStatusGate;
