import { IColumn, ColumnActionsMode } from '@fluentui/react';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';

interface IProcessListColumnParams {
  renderProcessTitleLink: (item: { processConfigTitle: React.ReactNode }) => JSX.Element;
}

const getColumns = ({ renderProcessTitleLink }: IProcessListColumnParams): Array<IColumn> => {
  return [
    {
      key: 'processConfigTitle',
      name: 'Process Title',
      fieldName: 'processConfigTitle',
      minWidth: 250,
      maxWidth: 600,
      isRowHeader: false,
      className: rowClasses.medFont,
      isResizable: false,
      onRender: renderProcessTitleLink,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processStartDate',
      name: 'Reporting Period End Date',
      fieldName: 'processStartDate',
      minWidth: 250,
      maxWidth: 300,
      isRowHeader: false,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processEndDate',
      name: 'Filing Due Date',
      fieldName: 'processEndDate',
      minWidth: 250,
      maxWidth: 300,
      isRowHeader: false,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export default getColumns;
