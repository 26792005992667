import React from 'react';
import { Dropdown, IDropdownOption, IPersonaProps, Stack, TextField } from '@fluentui/react';
import { Controller, FieldError, FieldValues, UseFormMethods, useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import ICompanyLegalEntityTermination, {
  ITerminationContact,
} from '../../../../utils/types/ICompanyLegalEntityTermination';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import {
  GET_INTAKE_METHODS_QUERY,
  GET_LEGO_CATEGORIES_QUERY,
  GET_TERMINATION_LIFECYCLE_STATUSES_QUERY,
} from '../../../../utils/statApi/EntityStatusApi';
import ErrorHandlingPeoplePicker from '../../../common/formFields/ErrorHandlingPeoplePicker';
import searchForUsers from '../../../../utils/microsoftGraphApi/userSearch';

interface ICompanyLegalEntityTerminationFormProps {
  form: UseFormMethods<FieldValues>;
  data: ICompanyLegalEntityTermination;
}

export interface ILegoCategory {
  id: number;
  fieldName: string;
  terminationLifecycleStatusId: number;
  isSelectable: boolean;
}

const CompanyLegalEntityTerminationInfoForm = ({
  form,
  data,
}: ICompanyLegalEntityTerminationFormProps): JSX.Element => {
  const { errors: formErrors, control, setValue, getValues } = form;

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  const currentYear = new Date().getFullYear();
  const nextFiveYears = Array.from({ length: 5 }, (_, i) => currentYear + i);

  const fiscalYears = nextFiveYears.map((year, index) => ({
    key: `FY${(year % 100).toString().padStart(2, '0')}`,
    text: `FY${(year % 100).toString().padStart(2, '0')}`, // Format as FYXX
  }));

  const Quarters = [
    { key: 'Q1', text: 'Q1' },
    { key: 'Q2', text: 'Q2' },
    { key: 'Q3', text: 'Q3' },
    { key: 'Q4', text: 'Q4' },
  ];

  const watchTerminationLifecycleStatus = useWatch({
    control,
    name: 'terminationLifecycleStatus',
    defaultValue: data?.terminationLifecycleStatus,
  });

  const onTerminationLifecycleStatusChange = (value: IDropdownOption): void => {
    setValue('terminationLifecycleStatus', value);
    setValue('legoCategory', null);
  };

  const validateTerminationLifecycleStatus = (): string => {
    if (
      !getValues('terminationLifecycleStatus')?.fieldName?.trim() &&
      data?.isTargetForTermination
    ) {
      return 'Please select a Termination Lifecycle Status.';
    }
    return null;
  };

  const onQuarterChange = (e: React.FormEvent<HTMLDivElement>, value: IDropdownOption) => {
    setValue('anticipatedTerminationQuarter', value?.text);
  };

  const onFYChange = (e: React.FormEvent<HTMLDivElement>, value: IDropdownOption) => {
    setValue('anticipatedTerminationFY', value?.text);
  };

  const onLegoCategoryChange = (value: IDropdownOption): void => {
    setValue('legoCategory', value);
  };

  const onIntakeMethodChange = (value: IDropdownOption): void => {
    setValue('intakeMethod', value);
  };

  const TerminationLifecycleStateDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors, handleChange, valueKey, outputKey } = props;
    const { loading, data: terminationLifecycleData } = useQuery(
      GET_TERMINATION_LIFECYCLE_STATUSES_QUERY,
    );
    const { terminationLifecycleStatuses } =
      !loading && terminationLifecycleData ? terminationLifecycleData : [];
    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        label={label}
        required={required}
        outputKey={outputKey}
        dropdownOptionsData={terminationLifecycleStatuses}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  const LegoCategoryDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors, handleChange, valueKey, outputKey } = props;
    const { loading, data: legoCategoriesData } = useQuery(GET_LEGO_CATEGORIES_QUERY);
    const { legoCategories } = !loading && legoCategoriesData ? legoCategoriesData : [];

    const terminationLifecycleStatusId = watchTerminationLifecycleStatus?.id;
    const updatedLegoCategories = legoCategories?.map((legoCategory: ILegoCategory) => ({
      ...legoCategory,
      isSelectable: legoCategory.terminationLifecycleStatusId === terminationLifecycleStatusId,
    }));

    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        label={label}
        required={required}
        outputKey={outputKey}
        dropdownOptionsData={updatedLegoCategories}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  const IntakeMethodDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors, handleChange, valueKey, outputKey } = props;
    const { loading, data: intakeMethodsData } = useQuery(GET_INTAKE_METHODS_QUERY);
    const { intakeMethods } = !loading && intakeMethodsData ? intakeMethodsData : [];
    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        label={label}
        required={required}
        outputKey={outputKey}
        dropdownOptionsData={intakeMethods}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Controller
        name="terminationLifecycleStatus"
        label="Termination Lifecycle Status"
        valueKey="terminationLifecycleStatus"
        control={control}
        defaultValue={data?.terminationLifecycleStatus}
        render={(props: { value: IDropdownReference }) => {
          return (
            <TerminationLifecycleStateDropdown
              label="Termination Lifecycle Status"
              valueKey="terminationLifecycleStatus"
              required={data?.isTargetForTermination}
              errors={formErrors}
              handleChange={onTerminationLifecycleStatusChange}
              value={props.value}
            />
          );
        }}
        rules={{
          validate: validateTerminationLifecycleStatus,
        }}
      />
      <Controller
        name="legoCategory"
        label="LEGO Category"
        valueKey="legoCategory"
        control={control}
        defaultValue={data?.legoCategory}
        render={(props: { value: IDropdownReference }) => {
          return (
            <LegoCategoryDropdown
              label="LEGO Category"
              valueKey="legoCategory"
              errors={formErrors}
              handleChange={onLegoCategoryChange}
              value={props.value}
            />
          );
        }}
      />
      <Controller
        name="intakeMethod"
        label="Intake Method"
        valueKey="intakeMethod"
        control={control}
        defaultValue={data?.intakeMethod}
        render={(props: { value: IDropdownReference }) => {
          return (
            <IntakeMethodDropdown
              label="Intake Method"
              valueKey="intakeMethod"
              required
              errors={formErrors}
              handleChange={onIntakeMethodChange}
              value={props.value}
            />
          );
        }}
        rules={{
          required: 'Please select an intake method',
        }}
      />
      <Controller
        id="anticipatedTerminationFY"
        name="anticipatedTerminationFY"
        control={control}
        defaultValue={data?.anticipatedTerminationFY}
        render={() => (
          <Dropdown
            id="anticipatedTerminationFY"
            label="Anticipated Termination FY"
            placeholder="Select One"
            options={fiscalYears}
            defaultSelectedKey={data?.anticipatedTerminationFY}
            onChange={onFYChange}
          />
        )}
      />
      <Controller
        id="anticipatedTerminationQuarter"
        name="anticipatedTerminationQuarter"
        control={control}
        defaultValue={data?.anticipatedTerminationQuarter}
        render={() => (
          <Dropdown
            id="anticipatedTerminationQuarter"
            label="Anticipated Termination Quarter"
            placeholder="Select One"
            options={Quarters}
            defaultSelectedKey={data?.anticipatedTerminationQuarter}
            onChange={onQuarterChange}
          />
        )}
      />
      <Controller
        as={TextField}
        id="accountingPlanStarted"
        name="accountingPlanStarted"
        label="Accounting Plan Started"
        control={control}
        defaultValue={data?.accountingPlanStarted || ''}
        errorMessage={formErrors?.accountingPlanStarted?.message}
      />
      <Controller
        id="celaTermContact"
        name="celaTermContact"
        control={control}
        defaultValue={data?.celaTermContact}
        render={() => (
          <ErrorHandlingPeoplePicker
            controlName="celaTermContact"
            labelText="CELA Termination Contact"
            onChange={(items: ITerminationContact[]) => {
              setValue('celaTermContact', items[0], { shouldDirty: true });
            }}
            itemLimit={1}
            defaultSelectedItems={data?.celaTermContact != null ? [data?.celaTermContact] : []}
            onResolveSuggestions={onResolveSuggestions}
            errorMessage={(data?.celaTermContact as unknown as FieldError)?.message}
          />
        )}
      />
      <Controller
        id="terminationContacts"
        name="terminationContacts"
        control={control}
        defaultValue={data?.terminationContacts}
        render={() => (
          <ErrorHandlingPeoplePicker
            controlName="terminationContacts"
            labelText="Termination Contacts"
            onChange={(items: ITerminationContact[]) => {
              setValue('terminationContacts', items, { shouldDirty: true });
            }}
            removeButtonAriaLabel="Remove Termination Contacts"
            onResolveSuggestions={onResolveSuggestions}
            defaultSelectedItems={data?.terminationContacts}
            errorMessage={(data?.terminationContacts as unknown as FieldError)?.message}
          />
        )}
      />
      <Controller
        as={TextField}
        id="legalEntityTerminationNotes"
        name="legalEntityTerminationNotes"
        label="Notes"
        control={control}
        defaultValue={data?.legalEntityTerminationNotes || ''}
        errorMessage={formErrors?.legalEntityTerminationNotes?.message}
        multiline
      />
    </Stack>
  );
};

export default CompanyLegalEntityTerminationInfoForm;
