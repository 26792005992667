import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Stack, IconButton } from '@fluentui/react';
import dayjs from 'dayjs';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import BackButton from '../../common/BackButton';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import Card from '../../common/card/Card';
import {
  COMPANY_FISCAL_YEAR_DETAIL_QUERY,
  EDIT_DIVIDENDS_MUTATION,
  EDIT_AGM_STATUS_MUTATION,
  UPDATE_FILING_INFO_MUTATION,
} from '../../../utils/statApi/CompanyFiscalYearsApi';
import StatDateFormats from '../../../utils/types/DateFormats';
import formatCurrency from '../../../utils/CurrencyFormatter';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import StackColumn from '../../common/StackColumn';
import SpanWithLabel from '../../common/SpanWithLabel';
import FiscalYearSummaryFormType from '../detail/forms/FiscalYearSummaryFormTypeEnum';
import FiscalYearDividendsEditPanel from './FiscalYearDividendsEditPanel';
import IDividends from '../../../utils/types/IDividends';
import FiscalYearAgmStatusEditPanel from './FiscalYearAgmStatusEditPanel';
import IAgmFiscalYears from '../../../utils/types/IAgmFiscalYears';
import FiscalYearAuditStatusEditPanel from './FiscalYearAuditStatusEditPanel';
import IAuditFiscalYears from '../../../utils/types/IAuditFiscalYear';
import momentUtc from '../../../utils/DateFormatter';
import { ITaxDividends } from '../../../utils/types/ITaxDividends';
import FiscalYearTaxDividendsEditPanel from './FiscalYearTaxDividendsEditPanel';
import CanAccess from '../../common/canAccess/CanAccess';
import { IFiscalYearParams } from '../../common/ParamTypes';
import { IDividendPayment, IFilingInfo, IServiceContact } from '../serviceContacts/interfaces';
import FilingInfoForm from '../detail/forms/FilingInfoForm';
import DividendPaymentCard from './DividendPaymentCard';
import DividendsCardContent from './dividendsReview/DividendsCardContent';
import DividendsLink from '../../common/DividendsLink';
import IAfsProcessSummary from '../../../utils/types/IAfsProcessSummary';
import FilingInfoCard from '../../common/FilingInfoCard';
import monthDayFormat from '../../../utils/MonthDayFormatter';
import { UPDATE_ANNUAL_GENERAL_MEETING } from '../../../utils/statApi/AnnualFilingRequirementsApi';
import ServiceContactList from '../serviceContacts/ServiceContactList';
import FeatureFlagged from '../../common/featureFlagged/featureFlagged';
import IAgmDetail from '../../../utils/types/IAgmDetail';
import { paperSizeDisplay } from '../../../utils/Helpers';
import { additionalTimeFormat, externalSubmitterDisplay } from './AgmDetailsContentCard';
import AgmDetailEditPanel from './AgmDetailEditPanel';

const FiscalYearDetail = (): JSX.Element => {
  const { fiscalYearId, companyCode } = useParams<IFiscalYearParams>();
  const intFiscalYear = parseInt(fiscalYearId, 10);
  const { data, loading, error, refetch } = useQuery(COMPANY_FISCAL_YEAR_DETAIL_QUERY, {
    variables: { companyCode, fiscalYearId: intFiscalYear },
  });
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [editForm, setCurrentEditForm] = useState<FiscalYearSummaryFormType>();

  const fiscalYearDetail = data?.companyFiscalYearDetail || {};
  const agmStatus: IAgmFiscalYears = fiscalYearDetail?.agmFiscalYear || {};
  const auditStatus: IAuditFiscalYears = fiscalYearDetail?.auditFiscalYear || {};
  const dividends: IDividends = fiscalYearDetail?.companyDividends || {};
  const taxDividends: ITaxDividends = fiscalYearDetail?.taxDividends || {};
  const filingInfo: IFilingInfo = fiscalYearDetail?.filingInfo || {};
  const dividendPayments: Array<IDividendPayment> = fiscalYearDetail?.dividendPayments || [];
  const afsProcess: IAfsProcessSummary = fiscalYearDetail?.afsProcess || {};
  const agmDetails: IAgmDetail = fiscalYearDetail?.agmDetail;

  const agmCelaContacts: IServiceContact[] = agmStatus?.agmCelaContacts?.map((contact) => {
    return {
      graphId: contact.graphId,
      text: contact.name,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      alias: contact.alias,
      contactType: 'AgmReviewContact',
      isInvalidUser: false,
    } as IServiceContact;
  });

  const togglePanel = (): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  const getBackButton = (): JSX.Element => {
    const loadingText = 'loading...';
    return (
      <BackButton ariaLabel="Back to fiscal year list">
        <h2>{`${loading ? loadingText : afsProcess.name} Summary`}</h2>
      </BackButton>
    );
  };

  const renderActionButton = (
    label: string,
    formType: FiscalYearSummaryFormType,
    requestedAction?: string,
  ): JSX.Element => {
    return (
      <>
        {requestedAction && (
          <CanAccess requestedAction={requestedAction}>
            <IconButton
              title={`Edit ${label}`}
              ariaLabel={label}
              iconProps={{ iconName: 'Edit' }}
              onClick={(): void => {
                setCurrentEditForm(formType);
                togglePanel();
              }}
            />
          </CanAccess>
        )}
        {!requestedAction && (
          <IconButton
            title={`Edit ${label}`}
            ariaLabel={label}
            iconProps={{ iconName: 'Edit' }}
            onClick={(): void => {
              setCurrentEditForm(formType);
              togglePanel();
            }}
          />
        )}
      </>
    );
  };

  const getDividendsLink = (): JSX.Element => {
    return (
      <DividendsLink
        dividends={dividends}
        companyCode={companyCode}
        toggleDividendsPanel={() => {
          setCurrentEditForm(FiscalYearSummaryFormType.Dividends);
          togglePanel();
        }}
      />
    );
  };

  const getTaxDividendsValue = () => {
    if (taxDividends.taxCurrencyCode) {
      return formatCurrency(taxDividends.taxCurrencyCode, taxDividends.taxProposedDividendAmount);
    }
    return '--';
  };

  return (
    <>
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBackButton}>
            <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
              <>
                <span>
                  <span style={{ fontWeight: 600 }}>Process Completion Date:</span>{' '}
                  {`${
                    afsProcess.lastMilestoneSignOffDate
                      ? dayjs(afsProcess.lastMilestoneSignOffDate).format(
                          StatDateFormats.DayBreakout,
                        )
                      : '--'
                  }`}
                </span>
                <span>|</span>
              </>
              <Link
                to={`/companies/${companyCode}/processes/${afsProcess.processTypeUri}/${afsProcess.id}`}
              >
                Go To Process
              </Link>
            </Stack>
          </BodyHeaderWithCommandBar>
        </div>
      </div>
      <div className={bodyContentContainer}>
        <LoadingErrorMessage error={error} loading={loading} />
        <Card
          title="AGM Status"
          isLoading={loading}
          actionButton={renderActionButton(
            'AGM Status',
            FiscalYearSummaryFormType.AgmStatus,
            'CelaAgmStatus:Update',
          )}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="CELA AGM Status" value={agmStatus.agmStatus?.fieldName} />
              <SpanWithLabel
                labelText="AGM Due Date"
                value={monthDayFormat(agmStatus.agmDueDateMonth, agmStatus.agmDueDateDay)}
              />
              <FeatureFlagged flagName="AgmTracker">
                <ServiceContactList
                  horizontal
                  labelText="CELA Assignee"
                  contacts={agmCelaContacts || []}
                />
              </FeatureFlagged>
            </StackColumn>
            <StackColumn>
              <SpanWithLabel
                labelText="CELA AGM Meeting Date"
                value={
                  agmStatus.celaAgmStatusDate
                    ? momentUtc(agmStatus.celaAgmStatusDate, StatDateFormats.DayBreakout)
                    : '--'
                }
              />
              <SpanWithLabel labelText="AGM Information" value={agmStatus.agmInformation} />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card
          title="AGM Details"
          headingLevel={2}
          actionButton={renderActionButton(
            'AGM Details',
            FiscalYearSummaryFormType.AgmDetails,
            'AnnualFilingRequirements:Edit',
          )}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel
                labelText="Is the AGM deadline earlier than the AFS deadline:"
                value={agmDetails?.agmDeadlineBeforeAfsDeadline}
              />
              <SpanWithLabel
                labelText="Is the tax filing deadline earlier than the AFS deadline:"
                value={agmDetails?.taxFilingDeadlineBeforeAfsDeadline}
              />
              <SpanWithLabel
                labelText="Tax Filing Deadline:"
                value={momentUtc(agmDetails?.taxFilingDeadline, StatDateFormats.DayBreakout, true)}
              />
              <SpanWithLabel
                labelText="Is there a formal AFS external filing required:"
                value={agmDetails?.externalAfsFilingRequired}
              />
              <SpanWithLabel
                labelText="Do the AGM date and the FS date have to be aligned:"
                value={agmDetails?.requiredToBeFiledWithAfs}
              />
              <SpanWithLabel
                labelText="Can the AFS deadline be extended:"
                value={agmDetails?.canAfsBeExtended}
              />
              <SpanWithLabel
                labelText="Is the hard copy signature required for filing:"
                value={agmDetails?.physicalSignatureRequired}
              />
              <SpanWithLabel labelText="Address:" value={agmDetails?.hardCopyShippingAddress} />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel
                labelText="Is there a required ink color for the signature:"
                value={agmDetails?.inkColorRequired}
              />
              <SpanWithLabel
                labelText="Signature Ink color:"
                value={agmDetails?.signatureInkColor}
              />
              <SpanWithLabel
                labelText="How many originals are required:"
                value={agmDetails?.originalsCount}
              />
              <SpanWithLabel
                labelText="Paper Size:"
                value={
                  agmDetails?.otherPaperSize
                    ? `${paperSizeDisplay(agmDetails?.paperSize)} - ${agmDetails?.otherPaperSize}`
                    : paperSizeDisplay(agmDetails?.paperSize)
                }
              />
              <SpanWithLabel
                labelText="Is a Management Representation Letter Required:"
                value={agmDetails?.managementRepresentationLetterRequired}
              />
              <SpanWithLabel
                labelText="Final external filing submitter"
                value={
                  agmDetails?.otherFinalExternalFilingSubmitter
                    ? `${externalSubmitterDisplay(agmDetails?.finalExternalFilingSubmitter)} - ${
                        agmDetails?.otherFinalExternalFilingSubmitter
                      }`
                    : `${externalSubmitterDisplay(agmDetails?.finalExternalFilingSubmitter)}`
                }
              />
              <SpanWithLabel
                labelText="Auditing Firm"
                value={
                  agmDetails?.otherAuditingFirm
                    ? `${agmDetails?.auditingFirm?.fieldName} - ${agmDetails?.otherAuditingFirm}`
                    : agmDetails?.auditingFirm?.fieldName
                }
              />
              <SpanWithLabel
                labelText="Period of Appointment"
                value={agmDetails?.auditorAppointmentPeriod}
              />
              <SpanWithLabel
                labelText="Additional Processing Time:"
                value={additionalTimeFormat(agmDetails)}
              />
              <SpanWithLabel
                labelText="Auditor Fees"
                value={
                  agmDetails?.auditorFeesCurrencyCode
                    ? formatCurrency(agmDetails.auditorFeesCurrencyCode, agmDetails.auditorFees)
                    : '--'
                }
              />
              <SpanWithLabel
                labelText="Auditor Currency:"
                value={agmDetails?.auditorFeesCurrencyCode}
              />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card
          title="Audit Status"
          isLoading={loading}
          actionButton={renderActionButton(
            'Audit Status',
            FiscalYearSummaryFormType.AuditStatus,
            'AuditStatus:Edit',
          )}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Audit Status" value={auditStatus.auditStatus?.fieldName} />
              <SpanWithLabel
                labelText="Auditing Firm"
                value={`${auditStatus.auditingFirm?.fieldName || '--'} ${
                  auditStatus.otherAuditingFirm || ''
                }`}
              />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel
                labelText="Audit Status Date"
                value={momentUtc(auditStatus.statusDate, StatDateFormats.DayBreakout, true)}
              />
              <SpanWithLabel
                labelText="Audit Legally Required"
                value={auditStatus.auditLegallyRequired}
              />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <DividendsCardContent
          dividends={dividends}
          isLoading={loading}
          actionButtons={getDividendsLink()}
        />
        <br />
        <DividendPaymentCard
          dividendPayments={dividendPayments}
          parentLoading={loading}
          dividendsId={dividends?.id}
        />
        <br />
        <Card
          title="Tax Dividends"
          isLoading={loading}
          actionButton={renderActionButton(
            'Tax Dividends',
            FiscalYearSummaryFormType.TaxDividends,
            'TaxDividendInfo:Update',
          )}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel
                labelText="Tax Proposed Dividend Amount"
                value={getTaxDividendsValue()}
              />
              <SpanWithLabel labelText="Comments" value={taxDividends.taxComments} />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Tax Currency" value={taxDividends.taxCurrencyCode} />
            </StackColumn>
          </Stack>
        </Card>

        <br />

        <FilingInfoCard
          loading={loading}
          filingInfo={filingInfo}
          editAction={renderActionButton(
            'Filing Information',
            FiscalYearSummaryFormType.OtherInfo,
            'FilingInfo:Edit',
          )}
        />

        {isEditPanelOpen && editForm === FiscalYearSummaryFormType.AgmStatus && (
          <FiscalYearAgmStatusEditPanel
            agmStatus={agmStatus}
            closePanel={togglePanel}
            fiscalYearMutation={EDIT_AGM_STATUS_MUTATION}
            agmMutation={UPDATE_ANNUAL_GENERAL_MEETING}
            companyCode={companyCode}
            refetch={refetch}
          />
        )}
        {isEditPanelOpen && editForm === FiscalYearSummaryFormType.AgmDetails && (
          <AgmDetailEditPanel
            agmDetail={agmDetails}
            closePanel={togglePanel}
            refetchQueries={[
              {
                query: COMPANY_FISCAL_YEAR_DETAIL_QUERY,
                variables: { companyCode, fiscalYearId: intFiscalYear },
              },
            ]}
          />
        )}
        {isEditPanelOpen && editForm === FiscalYearSummaryFormType.Dividends && (
          <FiscalYearDividendsEditPanel
            dividends={dividends}
            closePanel={togglePanel}
            mutation={EDIT_DIVIDENDS_MUTATION}
          />
        )}
        {isEditPanelOpen && editForm === FiscalYearSummaryFormType.AuditStatus && (
          <FiscalYearAuditStatusEditPanel auditStatus={auditStatus} closePanel={togglePanel} />
        )}
        {isEditPanelOpen && editForm === FiscalYearSummaryFormType.TaxDividends && (
          <FiscalYearTaxDividendsEditPanel
            taxDividends={taxDividends}
            companyId={dividends?.companyId}
            closePanel={togglePanel}
          />
        )}

        {isEditPanelOpen && editForm === FiscalYearSummaryFormType.OtherInfo && (
          <FilingInfoForm
            filingInfoData={filingInfo}
            closePanel={togglePanel}
            mutation={UPDATE_FILING_INFO_MUTATION}
            parentId={fiscalYearDetail?.id}
            parentIdName="companyFiscalYearId"
          />
        )}
      </div>
    </>
  );
};

export default FiscalYearDetail;
