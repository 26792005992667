import { gql } from '@apollo/client';

const VALIDATION_DATA_QUERY = gql`
  query companyGroupValidation($entityType: String!) {
    validationData(entityType: $entityType) {
      formKey
      message
      type
      value
    }
  }
`;

export default VALIDATION_DATA_QUERY;
