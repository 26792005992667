import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Stack, ITag } from '@fluentui/react';
import { mapPersonasToCompanyGroupUser } from '../../../utils/PersonaHelpers';
import IPersonaWithGUID from '../../../utils/types/IPersonaWithGUIDs';
import ICompanyGroupStatUsers from '../../../utils/types/ICompanyGroupsStatUsers';
import CompanyPicker from '../detail/CompanyGroupCompanyPicker';
import ICompanyGroupCompany from '../../../utils/types/ICompanyGroupCompany';
import CompanyGroupUsersPicker from '../detail/CompanyGroupUsersPicker';
import ICompanyGroupFormProps from './CompanyGroupFormProps';

const CompanyGroupForm: React.FunctionComponent<ICompanyGroupFormProps> = ({
  companyGroup,
  form,
}: ICompanyGroupFormProps): JSX.Element => {
  const companyGroupFormState = { ...companyGroup };
  const [, setCompanyGroupAdmins] = useState([]);
  const [companyGroupCompanies, setCompanyGroupCompanies] = useState(
    companyGroupFormState.companyGroupCompanies.map((companyGroupCompany) => {
      const newObject: ITag = {
        key: companyGroupCompany.companyId.toString(),
        name: `${companyGroupCompany.companyCode} - ${companyGroupCompany.entityLegalName}`,
      };
      return newObject;
    }),
  );
  const { control, errors, setValue, trigger, formState } = form;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newValue: any, valueKey: string): void => {
    setValue(valueKey, newValue);
    if (formState.isSubmitted) {
      trigger();
    }
  };

  React.useEffect(() => {
    if (companyGroupFormState.companyGroupsStatUsers) {
      const statCompanyGroupUsers: ICompanyGroupStatUsers[] =
        companyGroupFormState.companyGroupsStatUsers.map(
          (user: ICompanyGroupStatUsers): ICompanyGroupStatUsers => {
            return {
              graphId: user.graphId,
            };
          },
        );
      setCompanyGroupAdmins(statCompanyGroupUsers);
    }
  }, [companyGroupFormState.companyGroupsStatUsers]);

  const onAdminPickerUpdate = (inputFieldPersonas: IPersonaWithGUID[]): void => {
    const companyGroupUsers: ICompanyGroupStatUsers[] = mapPersonasToCompanyGroupUser(
      inputFieldPersonas,
      companyGroup.id,
    );
    setCompanyGroupAdmins(companyGroupUsers);
    handleChange(companyGroupUsers, 'companyGroupsStatUsers');
  };

  const onCompanyUpdate = (items: ITag[]): void => {
    const companies = items.map((item) => {
      const intId = typeof item.key === 'string' ? parseInt(item.key, 10) : item.key;
      const newObject: ICompanyGroupCompany = {
        companyId: intId,
        entityLegalName: item.name,
        companyCode: item.name,
      };
      return newObject;
    });
    setCompanyGroupCompanies(items);
    handleChange(companies, 'companyGroupCompanies');
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 25 }}>
        <Controller
          as={TextField}
          label="Name"
          id="name"
          name="name"
          control={control}
          multiline
          required
          resizable={false}
          defaultValue={companyGroupFormState.name}
          errorMessage={errors?.name?.message}
          rules={{
            required: 'Name is required',
            maxLength: {
              value: 150,
              message: 'Input is greater than the maximum character limit of 150.',
            },
            minLength: {
              value: 2,
              message: 'Input is less than the minimum character limit of 2.',
            },
          }}
        />
        <Controller
          as={TextField}
          label="Description"
          id="description"
          name="description"
          control={control}
          multiline
          resizable={false}
          defaultValue={companyGroupFormState.description}
        />
        <div>
          <Controller
            name="companyGroupsStatUsers"
            control={control}
            defaultValue={companyGroupFormState.companyGroupsStatUsers}
            render={(): React.ReactElement => (
              <CompanyGroupUsersPicker
                selectedItems={companyGroupFormState.companyGroupsStatUsers}
                onInputFieldUpdate={onAdminPickerUpdate}
                ariaLabelId="companyGroupsStatUsers"
              />
            )}
          />
        </div>
        <Controller
          name="companyGroupCompanies"
          control={control}
          defaultValue={companyGroupFormState.companyGroupCompanies}
          render={(): React.ReactElement => (
            <CompanyPicker
              selectedItems={companyGroupCompanies}
              setSelectedItems={onCompanyUpdate}
              label="Companies"
            />
          )}
        />
      </Stack>
    </>
  );
};

export default CompanyGroupForm;
