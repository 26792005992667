import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IPersonaProps, Stack, Checkbox, ChoiceGroup } from '@fluentui/react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { useParams } from 'react-router-dom';
import { IServiceContactsUserAssignment, IServiceContact } from './interfaces';
import searchForUsers from '../../../utils/microsoftGraphApi/userSearch';
import {
  addErrorMessageUserAssignment,
  validateServiceContactsState,
} from './serviceContactValidator';
import ErrorHandlingPeoplePicker from '../../common/formFields/ErrorHandlingPeoplePicker';
import { UPDATE_SERVICE_CONTACTS_MUTATION } from '../../../utils/statApi/ServiceContactsApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ActionButtons from '../../common/ActionButtons';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { ContactType } from '../../companyCreation/details/types';
import CompanyContext from '../CompanyContext';
import { yesNoOptions } from '../../../utils/types/CommonDropdownOptions';

const PICKER_RESOLVE_DELAY = 450;

interface IServiceContactUserAssignmentForm {
  contacts: IServiceContactsUserAssignment;
  closePanel: () => void;
}

const ServiceContactUserAssignmentForm = ({
  contacts,
  closePanel,
}: IServiceContactUserAssignmentForm): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { company } = useContext(CompanyContext);
  const [pageState, setPageState] = useState({ ...contacts });
  const [updateServiceContacts, { loading, error }] = useMutation(
    UPDATE_SERVICE_CONTACTS_MUTATION,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const onResolveSuggestionsController = (
    filterText: string,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText, company.controllerMustBeFte);
    }
    return [];
  };

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  const oneFinanceCheckedChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean,
  ): void => {
    if (!checked) {
      const pageErrors = { ...pageState.errors };
      pageErrors.bpoContacts = {};
      setPageState({ ...pageState, isOneFinance: checked, bpoContacts: [], errors: pageErrors });
    } else {
      setPageState({ ...pageState, isOneFinance: checked });
    }
  };

  const onPickerChange = (items: IPersonaProps[], property: string): void => {
    const serviceContacts = items as IServiceContact[];
    setPageState({ ...pageState, [property]: serviceContacts });
  };

  const onBlurSetErrorMessage = (stateKey: string, errorMessage: string): void => {
    const pageErrors = { ...pageState.errors };

    setPageState({
      ...pageState,
      errors: addErrorMessageUserAssignment(stateKey, errorMessage, pageErrors),
    });
  };

  const reselectServiceContacts = (
    serviceContacts: Array<IServiceContact>,
    contactType: ContactType,
  ): Array<IServiceContact> => {
    if (!serviceContacts || serviceContacts.length === 0) {
      return [];
    }

    const result = serviceContacts.map((serviceContact) => {
      return {
        graphId: serviceContact.graphId,
        name: serviceContact.name || serviceContact.text,
        firstName: serviceContact.firstName,
        lastName: serviceContact.lastName,
        email: serviceContact.email,
        alias: serviceContact.alias,
        contactType,
        personnelNumber: serviceContact.personnelNumber,
      };
    });

    return result;
  };

  const validateForm = (): void => {
    const errors = validateServiceContactsState(pageState);
    if (!Object.keys(errors).length) {
      const payload = {
        id: companyCode,
        isOneFinance: pageState.isOneFinance,
        isMcaps: pageState.isMcaps,
        statutoryController: reselectServiceContacts(
          pageState.statutoryController,
          ContactType.StatutoryController,
        )[0],
        regionalControllers: reselectServiceContacts(
          pageState.regionalControllers,
          ContactType.RegionalController,
        ),
        generalManagers: reselectServiceContacts(
          pageState.generalManagers,
          ContactType.GeneralManager,
        ),
        vpPresidentDirectors: reselectServiceContacts(
          pageState.vpPresidentDirectors,
          ContactType.VpPresidentDirector,
        ),
        davaDealLeads: reselectServiceContacts(pageState.davaDealLeads, ContactType.DavaDealLead),
        bpoContacts: reselectServiceContacts(pageState.bpoContacts, ContactType.BpoContact),
        finOpsContacts: reselectServiceContacts(
          pageState.finOpsContacts,
          ContactType.FinOpsContact,
        ),
        treasurySupportReps: reselectServiceContacts(
          pageState.treasurySupportReps,
          ContactType.TreasurySupportRep,
        ),
        taxSupportReps: reselectServiceContacts(
          pageState.taxSupportReps,
          ContactType.TaxSupportRep,
        ),
      };

      updateServiceContacts({
        variables: {
          companyCode,
          serviceContacts: payload,
        },
      });
    }

    setPageState({ ...pageState, errors });
  };

  return (
    <CoherencePanel
      closeButtonAriaLabel="Close User Assignment Edit Panel"
      panelSize={CoherencePanelSize.medium}
      titleText="Edit User Assignment"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          closePanel={closePanel}
          handleSubmit={validateForm}
          saveLabel="Save User Assignment"
          saveTitle="Save User Assignment"
          cancelLabel="Cancel User Assignment Edit"
          cancelTitle="Cancel User Assignment Edit"
          mutationLoading={loading}
        />
      )}
    >
      <form>
        <Stack>
          <LoadingErrorMessage error={error} loading={loading} />
          <ErrorHandlingPeoplePicker
            controlName="statutoryController"
            labelText="Statutory Controller"
            required
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'statutoryController');
            }}
            removeButtonAriaLabel="Remove Statutory Controller"
            selectedItems={pageState?.statutoryController}
            onResolveSuggestions={onResolveSuggestionsController}
            errorMessage={pageState?.errors?.statutoryController?.message}
            itemLimit={1}
            resolveDelay={PICKER_RESOLVE_DELAY}
            setErrorMessage={onBlurSetErrorMessage}
          />
          <ErrorHandlingPeoplePicker
            controlName="regionalControllers"
            labelText="Regional Controllers"
            required
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'regionalControllers');
            }}
            removeButtonAriaLabel="Remove Regional Controller"
            selectedItems={pageState.regionalControllers}
            onResolveSuggestions={onResolveSuggestionsController}
            errorMessage={pageState?.errors?.regionalControllers?.message}
            resolveDelay={PICKER_RESOLVE_DELAY}
            setErrorMessage={onBlurSetErrorMessage}
          />
          <ErrorHandlingPeoplePicker
            controlName="generalManagers"
            labelText="General Managers"
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'generalManagers');
            }}
            removeButtonAriaLabel="Remove General Manager"
            selectedItems={pageState.generalManagers}
            onResolveSuggestions={onResolveSuggestions}
            errorMessage={pageState?.errors?.generalManagers?.message}
            resolveDelay={PICKER_RESOLVE_DELAY}
            setErrorMessage={onBlurSetErrorMessage}
          />
          <ErrorHandlingPeoplePicker
            controlName="vpPresidentDirectors"
            labelText="VP/President/Directors"
            onResolveSuggestions={onResolveSuggestions}
            resolveDelay={PICKER_RESOLVE_DELAY}
            selectedItems={pageState.vpPresidentDirectors}
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'vpPresidentDirectors');
            }}
            removeButtonAriaLabel="Remove VP/President/Director"
            errorMessage={pageState?.errors?.vpPresidentDirectors?.message}
          />
          <ErrorHandlingPeoplePicker
            controlName="davaDealLeads"
            labelText="DAVA Deal Leads"
            onResolveSuggestions={onResolveSuggestions}
            resolveDelay={PICKER_RESOLVE_DELAY}
            selectedItems={pageState.davaDealLeads}
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'davaDealLeads');
            }}
            removeButtonAriaLabel="Remove DAVA Deal Lead"
            errorMessage={pageState?.errors?.davaDealLeads?.message}
          />
          <Stack horizontal verticalAlign="start">
            <Stack.Item grow={1}>
              <Checkbox
                label="One Finance"
                checked={pageState.isOneFinance}
                onChange={oneFinanceCheckedChange}
                styles={{ root: { paddingTop: '35px' } }}
              />
            </Stack.Item>
            <Stack.Item grow={3} styles={{ root: { width: '60%' } }}>
              <ErrorHandlingPeoplePicker
                controlName="bpoContacts"
                labelText="BPO Contacts"
                required={pageState.isOneFinance}
                onChange={(items?: IPersonaProps[]): void => {
                  onPickerChange(items, 'bpoContacts');
                }}
                removeButtonAriaLabel="Remove BPO Contact"
                selectedItems={pageState.bpoContacts}
                onResolveSuggestions={onResolveSuggestions}
                errorMessage={pageState?.errors?.bpoContacts?.message}
                disabled={!pageState.isOneFinance}
                resolveDelay={PICKER_RESOLVE_DELAY}
                setErrorMessage={onBlurSetErrorMessage}
              />
            </Stack.Item>
          </Stack>
          <ErrorHandlingPeoplePicker
            controlName="finOpsContacts"
            labelText="Fin Ops Contacts"
            onResolveSuggestions={onResolveSuggestions}
            resolveDelay={PICKER_RESOLVE_DELAY}
            selectedItems={pageState.finOpsContacts}
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'finOpsContacts');
            }}
            removeButtonAriaLabel="Remove Fin Ops Contact"
            errorMessage={pageState?.errors?.finOpsContacts?.message}
          />
          <ChoiceGroup
            id="isMcaps"
            name="isMcaps"
            label="Is MCAPS?"
            options={yesNoOptions}
            defaultSelectedKey={pageState?.isMcaps?.toString().toLowerCase()}
            onChange={(e, newValue): void => {
              setPageState({ ...pageState, isMcaps: newValue.key === 'true' });
            }}
          />
          <ErrorHandlingPeoplePicker
            controlName="treasurySupportReps"
            labelText="Treasury Support Reps"
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'treasurySupportReps');
            }}
            removeButtonAriaLabel="Remove Treasury Support Rep"
            selectedItems={pageState.treasurySupportReps}
            onResolveSuggestions={onResolveSuggestions}
            resolveDelay={PICKER_RESOLVE_DELAY}
          />
          <ErrorHandlingPeoplePicker
            controlName="taxSupportReps"
            labelText="Tax Support Reps"
            onChange={(items?: IPersonaProps[]): void => {
              onPickerChange(items, 'taxSupportReps');
            }}
            removeButtonAriaLabel="Remove Tax Support Reps"
            selectedItems={pageState.taxSupportReps}
            onResolveSuggestions={onResolveSuggestions}
            resolveDelay={PICKER_RESOLVE_DELAY}
          />
        </Stack>
      </form>
    </CoherencePanel>
  );
};

export default ServiceContactUserAssignmentForm;
