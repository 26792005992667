import { Label, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import InlineFormInputErrorMessage from '../../common/errorContent/InlineFormInputErrorMessage';
import MonthDayPicker from '../../common/formFields/monthDayPicker/MonthDayPicker';
import { DoubleRequiredMarker, RequiredMarker } from '../../common/formFields/RequiredMarker';
import UtcDatePicker from '../../common/utcDatePicker/UtcDatePicker';
import RequiredFieldsNotification from '../RequiredNotification';
import LegalDocumentInfoAddress from './LegalDocumentInfoAddress';
import { ILegalDocumentInfo, RequiredFieldsType } from './types';

interface ILegalDocumentInfoFormProps {
  form: UseFormMethods<ILegalDocumentInfo>;
  data: ILegalDocumentInfo;
  isNonTransactional: boolean;
}

const LegalDocumentInfoForm = ({
  form,
  data,
  isNonTransactional,
}: ILegalDocumentInfoFormProps): JSX.Element => {
  const { control, errors, setValue } = form;

  const customLabelStyles = { paddingBottom: 0 };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <RequiredFieldsNotification
        type={isNonTransactional ? RequiredFieldsType.StatSetup : RequiredFieldsType.SapSetup}
      />
      <Label style={customLabelStyles}>
        Full Legal Name {isNonTransactional ? <DoubleRequiredMarker /> : <RequiredMarker />}
      </Label>
      <Controller
        as={TextField}
        id="fullLegalName"
        name="fullLegalName"
        control={control}
        defaultValue={data?.fullLegalName || ''}
        errorMessage={errors?.fullLegalName?.message}
        rules={{
          maxLength: {
            value: 200,
            message: 'Full Legal Name can only be a max of 200 characters',
          },
        }}
      />
      <Label style={customLabelStyles}>
        SAP Legal Name {isNonTransactional ? <DoubleRequiredMarker /> : <RequiredMarker />}
      </Label>
      <Controller
        as={TextField}
        id="sapLegalName"
        name="sapLegalName"
        control={control}
        defaultValue={data?.sapLegalName || ''}
        errorMessage={errors?.sapLegalName?.message}
        rules={{
          maxLength: {
            value: 50,
            message: 'SAP Legal Name can only be a max of 50 characters',
          },
        }}
      />
      <Label style={customLabelStyles}>
        Abbreviated SAP Name {isNonTransactional ? <DoubleRequiredMarker /> : <RequiredMarker />}
      </Label>
      <Controller
        as={TextField}
        id="abbreviatedSapName"
        name="abbreviatedSapName"
        control={control}
        defaultValue={data?.abbreviatedSapName || ''}
        errorMessage={errors?.abbreviatedSapName?.message}
        rules={{
          maxLength: {
            value: 25,
            message: 'Abbreviated SAP Name can only be a max of 25 characters',
          },
        }}
      />
      <MonthDayPicker
        form={form}
        dueDateDay={data?.statutoryYearEndDay}
        dueDateMonth={data?.statutoryYearEndMonth}
        dayFormName="statutoryYearEndDay"
        monthFormName="statutoryYearEndMonth"
        label={
          <Label style={customLabelStyles}>
            Statutory Year End Month
            {isNonTransactional ? <DoubleRequiredMarker /> : <RequiredMarker />}
          </Label>
        }
        dayLabel={
          <Label style={customLabelStyles}>
            Day
            {isNonTransactional ? <DoubleRequiredMarker /> : <RequiredMarker />}
          </Label>
        }
        required={false}
      />
      <Controller
        name="incorporatedDate"
        control={control}
        defaultValue={data?.incorporatedDate}
        render={({ value }): React.ReactElement => (
          <div role="form">
            <UtcDatePicker
              label="Incorporated Date"
              allowTextInput
              disableAutoFocus={false}
              value={value}
              onSelectDate={(utcDateString: string): void => {
                setValue('incorporatedDate', utcDateString);
              }}
            />
            <InlineFormInputErrorMessage errorMessage={errors?.incorporatedDate?.message} />
          </div>
        )}
      />
      <LegalDocumentInfoAddress
        form={form}
        legallyRegisteredAddress={data?.legallyRegisteredAddress}
        physicalAddress={data?.physicalAddress}
        isNonTransactional={isNonTransactional}
      />
    </Stack>
  );
};

export default LegalDocumentInfoForm;
