import { ColumnActionsMode, IColumn } from '@fluentui/react';

const getColumns = (): Array<IColumn> => {
  return [
    {
      key: 'ordinal',
      name: 'Order',
      fieldName: 'ordinal',
      minWidth: 75,
      maxWidth: 100,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'milestoneName',
      name: 'Milestone Name',
      fieldName: 'milestoneTemplateName',
      minWidth: 175,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'assignee',
      name: 'Assignee',
      fieldName: 'assignee',
      minWidth: 175,
      maxWidth: 275,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      onRender: (item): string => {
        if (item.assignee?.assigneeSlot && !item.assignee?.assigneeName) {
          return item.assignee.assigneeSlot;
        }

        return `${item.assignee.assigneeSlot} - ${item.assignee.assigneeName}`;
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'duration',
      name: 'Duration',
      fieldName: 'duration',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: false,
      data: 'int',
      isPadded: true,
      onRender: (item): string => {
        return item.duration || '--';
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'defaultDaysOffset',
      name: 'Days from Due Date',
      fieldName: 'defaultDaysOffset',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: false,
      data: 'int',
      isPadded: true,
      onRender: (item): string => {
        return item.defaultDaysOffset === 0 ? '--' : Math.abs(item.defaultDaysOffset).toString();
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export default getColumns;
