import { IPersona, IPersonaSharedProps, Label } from '@fluentui/react';
import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { suggestionProps } from '../../../utils/PersonaHelpers';
import { StatUser } from '../../../utils/statApi/UsersApi';
import ErrorHandlingPeoplePicker from '../../common/formFields/ErrorHandlingPeoplePicker';
import {
  GetStatUsersByIdsForStatControllerFilterQuery,
  GetUsersForStatControllerFilterQuery,
} from '../../../utils/statApi/CompaniesApi';

interface IStatUserPickerProps {
  initialSelectedItems: number[];
  onInputFieldUpdate?: (userIds: number[]) => void;
  label: string;
}
interface IPersonaWithKey extends IPersonaSharedProps {
  key: number;
}
const StatUserPicker = (props: IStatUserPickerProps): JSX.Element => {
  const { initialSelectedItems, onInputFieldUpdate, label } = props;
  const [currentSelectedItems, setCurrentSelectedItems] = React.useState([]);

  const { data, loading, error } = useQuery(GetStatUsersByIdsForStatControllerFilterQuery, {
    fetchPolicy: 'no-cache',
    variables: { statUserIds: initialSelectedItems },
  });
  React.useEffect(() => {
    if (!loading && data && data.statUsersByIdForStatControllerFilter) {
      setCurrentSelectedItems(
        data.statUsersByIdForStatControllerFilter.map((item: StatUser): IPersona => {
          const persona: IPersona = {
            key: item.id,
            text: item.displayName,
            secondaryText: item.emailAddress,
          };
          return persona;
        }),
      );
    }
  }, [data]);
  const [loadStatUsers] = useLazyQuery(GetUsersForStatControllerFilterQuery);
  const onResolveSuggestions = async (filterText: string): Promise<IPersonaWithKey[]> => {
    return loadStatUsers({
      variables: {
        first: 10,
        after: null,
        last: null,
        before: null,
        keyword: filterText,
      },
    }).then((apolloResult) => {
      const result = apolloResult.data;
      const statUsers: { node: StatUser }[] = result?.statUsersForStatControllerFilter?.edges;
      const returnUsers = statUsers?.map((item: { node: StatUser }): IPersonaWithKey => {
        return {
          key: item.node.id,
          text: item.node.displayName,
          secondaryText: item.node.emailAddress,
        };
      });
      return returnUsers;
    });
  };
  return (
    <>
      <Label htmlFor="statUserPicker">{label}</Label>
      <ErrorHandlingPeoplePicker
        onResolveSuggestions={onResolveSuggestions}
        onEmptyResolveSuggestions={(): [] => []}
        errorMessage={error?.message}
        removeButtonAriaLabel={`Remove ${label}`}
        className="ms-PeoplePicker"
        key="statUserPicker"
        onChange={(items: IPersonaWithKey[]): void => {
          setCurrentSelectedItems(items);
          onInputFieldUpdate(items.map((item) => item.key));
        }}
        selectedItems={currentSelectedItems}
        pickerSuggestionsProps={suggestionProps}
        resolveDelay={200}
        controlName={label}
      />
    </>
  );
};
export default StatUserPicker;
