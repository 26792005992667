import { ColumnActionsMode } from '@fluentui/react';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';

export interface IProcessWithDeactivateStatus {
  id: number;
  processStartDate: string;
  processEndDate: string;
  willDelete?: boolean;
}
export const deactivateProcessListColumns = [
  {
    key: 'processStartDate',
    name: 'Process Start Date',
    fieldName: 'processStartDate',
    minWidth: 35,
    maxWidth: 120,
    isRowHeader: false,
    className: rowClasses.medFont,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
    isPadded: false,
  },
  {
    key: 'processEndDate',
    name: 'Filing Due Date',
    fieldName: 'processEndDate',
    minWidth: 35,
    maxWidth: 120,
    isRowHeader: false,
    className: rowClasses.medFont,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
    isPadded: false,
  },
  {
    key: 'willDelete',
    name: 'Status',
    fieldName: 'willDelete',
    minWidth: 35,
    maxWidth: 180,
    isRowHeader: false,
    className: rowClasses.medFont,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
    isPadded: false,
  },
];
