import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import {
  Stack,
  IStackProps,
  IconButton,
  IIconProps,
  NeutralColors,
  FontSizes,
  DefaultPalette,
  FontWeights,
  mergeStyleSets,
  Text,
  ActionButton,
  FontIcon,
  Callout,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { MilestoneStatus } from './Interfaces';
import MilestoneCardActions from './MilestoneCardActions';
import { iconClass, paddedIconClass } from './milestoneRules/Gate.Styles';

export interface IMilestoneCardHeaderProps {
  isCurrent: boolean;
  isNotStarted: boolean;
  isComplete?: boolean;
  isDisabled: boolean;
  onHeaderClick: (isCollpased: boolean) => void;
  iconClick: () => void;
  contentIsCollapsed: boolean;
  icon: string;
  status: MilestoneStatus;
  actionText: string;
  commentText: string;
  title: string;
  showProcessExtensionWarning: boolean;
  setShowComments: Dispatch<SetStateAction<boolean>>;
}
const cardHeaderFontStyles = {
  padding: '5px 0px 5px 0px',
  margin: '0',
  fontSize: FontSizes.size14,
  fontWeight: 500,
};
const headerDividerStyle = `1px solid ${NeutralColors.gray20}`;

const MilestoneCardHeader = (props: IMilestoneCardHeaderProps): JSX.Element => {
  const {
    isCurrent,
    isNotStarted,
    isComplete,
    onHeaderClick,
    contentIsCollapsed,
    title,
    commentText,
    setShowComments,
  } = props;

  const [isCalloutVisible, { setFalse: closeCallout, toggle: toggleCallout }] = useBoolean(false);
  const commentId = useId('callout-icon');

  const getHeaderTextColor = (): string => {
    return !isCurrent ? 'inherit' : NeutralColors.white;
  };

  const getHeaderBgColor = (): string => {
    if (isComplete) {
      return NeutralColors.gray40;
    }
    return isNotStarted ? NeutralColors.white : DefaultPalette.blue;
  };

  const getBorderBottom = (): string => {
    return isNotStarted ? headerDividerStyle : '';
  };

  const getExpandIconColor = (): string => {
    return isCurrent ? NeutralColors.white : DefaultPalette.blue;
  };

  const rightAlignedHeaderProps: IStackProps = {
    horizontal: true,
    verticalAlign: 'center',
    tokens: { childrenGap: 5 },
  };

  const cardHeaderStackStyles = {
    padding: 10,
    color: getHeaderTextColor(),
    backgroundColor: getHeaderBgColor(),
    borderBottom: getBorderBottom(),
  } as CSSProperties;

  const chevronRight: IIconProps = {
    iconName: 'chevronRight',
  };

  const chevronDown: IIconProps = {
    iconName: 'chevronDown',
  };

  const buttonWrapperStyles = { display: 'flex', alignItems: 'center' };
  const buttonStyles = {
    root: { marginRight: 10, color: getExpandIconColor() },
    rootFocused: { background: NeutralColors.gray30, color: DefaultPalette.blue },
  };

  const callOutStyle = mergeStyleSets({
    callout: {
      width: 320,
      maxWidth: '90%',
      padding: '20px 24px',
    },
    title: {
      marginBottom: 12,
      fontWeight: FontWeights.semilight,
    },
    link: {
      display: 'block',
      marginTop: 40,
    },
  });

  const handlecommentDetailsClick = () => {
    closeCallout();
    setShowComments(true);
  };

  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
      style={cardHeaderStackStyles}
    >
      <div style={buttonWrapperStyles}>
        <IconButton
          styles={buttonStyles}
          iconProps={contentIsCollapsed ? chevronRight : chevronDown}
          title={contentIsCollapsed ? `Expand ${title}` : `Collapse ${title}`}
          ariaLabel={contentIsCollapsed ? `Expand ${title}` : `Collapse ${title}`}
          aria-expanded={!contentIsCollapsed}
          onClick={(): void => {
            onHeaderClick(!contentIsCollapsed);
          }}
        />
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <h3 style={cardHeaderFontStyles}>{title}</h3>
          {commentText && (
            <>
              <IconButton
                id={commentId}
                iconProps={{ iconName: 'message' }}
                onClick={toggleCallout}
                styles={buttonStyles}
                ariaLabel="Last Milestone Comment"
              />

              {isCalloutVisible && (
                <Callout
                  className={callOutStyle.callout}
                  target={`#${commentId}`}
                  onDismiss={closeCallout}
                  setInitialFocus
                >
                  <Stack>
                    <Text as="h1" block variant="xLarge" className={callOutStyle.title}>
                      Last Milestone Comment
                    </Text>
                    <Text block variant="medium">
                      {commentText}
                    </Text>

                    <ActionButton onClick={handlecommentDetailsClick}>
                      <FontIcon
                        className={`${iconClass} ${paddedIconClass}`}
                        iconName="OpenInNewTab"
                      />
                      Milestone details and comments
                    </ActionButton>
                  </Stack>
                </Callout>
              )}
            </>
          )}
        </Stack>
      </div>
      <Stack className="ms-hiddenMdDown" {...rightAlignedHeaderProps}>
        <MilestoneCardActions {...props} />
      </Stack>
    </Stack>
  );
};

export default MilestoneCardHeader;
