import { gql } from '@apollo/client';

export const UPDATE_MILESTONE_TEMPLATE = gql`
  mutation updateMilestoneTemplate($milestoneTemplateDto: MilestoneTemplateDtoInput!) {
    updateMilestoneTemplate(milestoneTemplateDto: $milestoneTemplateDto) {
      id
      name
      description
      attestationText
      milestoneRules
      signoffRoleIds
      processTypePoolId
      milestoneCategoryId
      milestoneCategoryName
    }
  }
`;
export const CREATE_MILESTONE_TEMPLATE = gql`
  mutation createMilestoneTemplate($milestoneTemplateDto: MilestoneTemplateDtoInput!) {
    createMilestoneTemplate(milestoneTemplateDto: $milestoneTemplateDto) {
      id
      name
      description
      attestationText
      milestoneRules
      signoffRoleIds
      processTypePoolId
      milestoneCategoryId
      milestoneCategoryName
    }
  }
`;
export const GET_RULES_AND_ROLES = gql`
  query rulesAndRoles($processTypeId: Int!) {
    signOffRoles {
      id
      fieldName: name
    }
    milestoneRules(processTypeId: $processTypeId) {
      id
      fieldName: name
    }
    milestoneCategories(processTypeId: $processTypeId) {
      key: id
      text: name
    }
  }
`;

export const GET_PROCESS_TYPE_CATEGORIES = gql`
  query processTypeCategories($processTypeId: Int!) {
    milestoneCategories(processTypeId: $processTypeId) {
      id
      name
      processTypeId
    }
  }
`;

export const CREATE_MILESTONE_CATEGORY = gql`
  mutation createMilestoneTemplateCategory($milestoneCategoryDto: MilestoneCategoryDtoInput!) {
    createMilestoneTemplateCategory(milestoneCategoryDto: $milestoneCategoryDto) {
      id
      name
      processTypeId
    }
  }
`;

export const UPDATE_MILESTONE_CATEGORY = gql`
  mutation editMilestoneTemplateCategory($milestoneCategoryDto: MilestoneCategoryDtoInput!) {
    editMilestoneTemplateCategory(milestoneCategoryDto: $milestoneCategoryDto) {
      id
      name
      processTypeId
    }
  }
`;

export const DELETE_MILESTONE_CATEGORY = gql`
  mutation deleteMilestoneTemplateCategory($milestoneCategoryId: Int!) {
    deleteMilestoneTemplateCategory(milestoneCategoryId: $milestoneCategoryId) {
      id
      name
      processTypeId
    }
  }
`;
