import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_AGM_TRACKER_STATUSES_QUERY } from '../../../../utils/statApi/CompanyFiscalYearsApi';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import { IAgmTrackerStatus } from '../../annualFilingRequirements/IAgmListPageSearchResult';

export interface IAgmTrackerStatusDropdownProps {
  value: IDropdownReference;
  valueKey: string;
  label: string;
  handleChange: (newValue: IDropdownReference, valueKey: string) => void;
  isBulkEdit?: boolean;
  errors?: string;
}

const AgmTrackerStatusDropdown = (props: IAgmTrackerStatusDropdownProps): JSX.Element => {
  const { value, valueKey, label, handleChange, isBulkEdit, errors: formError } = props;
  const { loading, data, error } = useQuery(GET_AGM_TRACKER_STATUSES_QUERY);
  const { agmTrackerStatuses } = !loading && data ? data : [];

  const dropdownOptionsData: IDropdownReference[] = agmTrackerStatuses?.map(
    (status: IAgmTrackerStatus) => {
      return {
        id: status.id,
        fieldName: `${status.trackerIndex} - ${status.fieldName}`,
      };
    },
  );

  if (isBulkEdit) {
    dropdownOptionsData?.unshift({ id: 0, fieldName: '--' });
  }

  const handleStatusChange = (newValue: IDropdownReference): void => {
    const selectedStatus = agmTrackerStatuses.find(
      (status: IAgmTrackerStatus) => status.id === newValue.id,
    );

    const entityToSave: IAgmTrackerStatus = {
      id: newValue.id,
      fieldName: selectedStatus?.fieldName,
      trackerIndex: selectedStatus?.trackerIndex,
    };

    handleChange(entityToSave, valueKey);
  };

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      width="100%"
      label={label}
      dropdownOptionsData={dropdownOptionsData}
      errors={error}
      errorMessage={formError}
      handleChange={handleStatusChange}
      valueKey={valueKey}
      wrapOptionText
    />
  );
};

export default AgmTrackerStatusDropdown;
