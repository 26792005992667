import React from 'react';
import { Label, Stack, TextField } from '@fluentui/react';
import { Controller, UseFormMethods } from 'react-hook-form';
import IIncorporationHierarchyInfo from '../../../../utils/types/IIncorporationHierarchyInfo';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';

interface IIncorporationHierarchyFormProps {
  form: UseFormMethods<IIncorporationHierarchyInfo>;
  data: IIncorporationHierarchyInfo;
}

const IncorporationHierarchyForm = ({
  form,
  data,
}: IIncorporationHierarchyFormProps): JSX.Element => {
  const { control, errors, setValue } = form;
  const customLabelStyles = { paddingBottom: 0 };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Controller
        as={UtcDatePicker}
        label="Incorporated Date"
        id="incorporatedDate"
        name="incorporatedDate"
        control={control}
        allowTextInput
        defaultValue={data?.incorporatedDate}
        textField={{
          name: 'incorporatedDate',
          errorMessage: errors?.incorporatedDate?.message,
        }}
        onSelectDate={(utcDateString: string): void => {
          setValue('incorporatedDate', utcDateString);
        }}
      />
      <Label style={customLabelStyles}>WW Area</Label>
      <Controller
        as={TextField}
        id="wwArea"
        name="wwArea"
        control={control}
        defaultValue={data?.wwArea || ''}
        errorMessage={errors?.wwArea?.message}
        readOnly
        disabled
      />
      <Label style={customLabelStyles}>Region</Label>
      <Controller
        as={TextField}
        id="region"
        name="region"
        control={control}
        defaultValue={data?.region || ''}
        errorMessage={errors?.region?.message}
        readOnly
        disabled
      />
    </Stack>
  );
};

export default IncorporationHierarchyForm;
