import React from 'react';
import { ColumnActionsMode, Icon } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { toTitleCase } from '../../../utils/Helpers';
import DocumentView from '../../common/documents/DocumentView';
import { IDocument } from '../../../utils/statApi/DocumentApi';
import StatDateFormats from '../../../utils/types/DateFormats';
import momentUtc from '../../../utils/DateFormatter';

const documentsToAnalyzeColumns = [
  {
    key: 'anaylyzebutton',
    name: 'Analyze',
    minWidth: 120,
    maxWidth: 120,
    onRender: (item: { document: { id: number } }) => {
      return <Link to={`/document/analysis/${item.document.id}`}>View Details</Link>;
      // return <AnalyzeButton documentId={item.document.id} />;
    },
    isResizable: false,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'document.title',
    name: 'Document',
    fieldName: 'document.title',
    minWidth: 200,
    isResizable: true,
    isHeaderRow: true,
    onRender: (item: { document: IDocument }) => {
      return <DocumentView document={item.document} index={0} />;
    },
  },
  {
    key: 'timesAnalyzed',
    name: 'Previous Analyses',
    fieldName: 'timesAnalyzed',
    minWidth: 150,
    isResizable: true,
    isHeaderRow: false,
    onRender: (item: { document: { id: number }; timesAnalyzed: number; hasAnomaly?: boolean }) => {
      return (
        <div>
          {item?.timesAnalyzed} time(s)
          {item?.hasAnomaly && <Icon iconName="AlertSolid" style={{ marginLeft: 5 }} />}
        </div>
      );
    },
  },
  {
    key: 'companyCode',
    name: 'Company',
    minWidth: 180,
    isResizable: true,
    onRender: (item: { companyCode: string; companyName: string }) => {
      return (
        <div>
          {item?.companyCode} - {item?.companyName}
        </div>
      );
    },
  },
  {
    key: 'document.uploadDate',
    name: 'Upload Date',
    fieldName: 'document.uploadDate',
    minWidth: 200,
    isResizable: true,
    isHeaderRow: true,
    onRender: (item: { document: IDocument }) => {
      return <div>{momentUtc(item.document.uploadDate, StatDateFormats.DayBreakout, true)}</div>;
    },
  },
  {
    key: 'fiscalYear',
    name: 'Fiscal Year',
    fieldName: 'fiscalYear',
    minWidth: 100,
    isResizable: false,
  },
  {
    key: 'documentType',
    name: 'Document Type',
    fieldName: 'documentType',
    minWidth: 150,
    isResizable: true,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: { documentType: string }) => {
      return <span>{toTitleCase(item?.documentType)}</span>;
    },
  },
];

export default documentsToAnalyzeColumns;
