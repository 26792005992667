import React from 'react';

interface IMilestoneCalendarSvg {
  mainColor: string;
  accentColor: string;
}

const MilestoneCalendarSvg = ({ mainColor, accentColor }: IMilestoneCalendarSvg): JSX.Element => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 273 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip${mainColor})`}>
        <path
          d="M170.182 36.9091L170 35.8182L169.273 28.1819L168.182 17.4546C167.636 11.2728 162.182 6.72731 156 7.45459H155.818L10.1819 21.6364C4.00012 22.1819 -0.545331 27.6364 0.181941 33.8182L1.27285 44.5455L11.8183 149.091C12.1819 152.727 15.4547 155.455 19.2729 155.091L108.546 146.364L128.546 144.364L174 139.818C177.273 139.455 179.818 136.909 180 133.818C180 133.455 180 132.909 180 132.364L170.182 36.9091Z"
          fill={`url(#paint${mainColor}_radial)`}
        />
        <path
          d="M169.315 26.4789L1.03613 42.9997L1.94211 52.2279L170.221 35.7072L169.315 26.4789Z"
          fill={accentColor}
        />
        <path
          d="M10.1822 21.6364L155.819 7.27274H156C162.182 6.72729 167.637 11.0909 168.182 17.2727L169.273 28L1.09134 44.7273L0.000432011 33.8182C-0.545023 27.8182 4.00043 22.1818 10.1822 21.6364Z"
          fill={mainColor}
        />
        <path
          d="M20.7276 88C20.1822 88 19.8185 87.6364 19.6367 87.0909C19.6367 86.5455 20.0004 86 20.5458 86L157.273 72.5455C157.819 72.5455 158.364 72.9091 158.364 73.4546C158.364 74 158 74.5455 157.455 74.5455L20.7276 88C20.9094 88 20.9094 88 20.7276 88Z"
          fill="#D6D6D6"
        />
        <path
          d="M93.637 125.818C93.0915 125.818 92.7279 125.455 92.5461 124.909L86.3643 62.3636C86.3643 61.8182 86.7279 61.2727 87.2733 61.2727C87.8188 61.2727 88.3643 61.6364 88.3643 62.1818L94.5461 124.545C94.7279 125.273 94.1824 125.636 93.637 125.818Z"
          fill="#D6D6D6"
        />
        <path
          d="M66.9094 128.364C66.364 128.364 66.0004 128 65.8185 127.455L59.6367 64.9091C59.6367 64.3637 60.0004 63.8182 60.5458 63.8182C61.0913 63.8182 61.6367 64.1818 61.6367 64.7273L67.8185 127.273C68.0004 127.818 67.4549 128.364 66.9094 128.364Z"
          fill="#D6D6D6"
        />
        <path
          d="M40.1819 131.091C39.6365 131.091 39.2728 130.727 39.091 130.182L32.9092 67.6364C32.9092 67.0909 33.2728 66.5455 33.8183 66.5455C34.3637 66.5455 34.9092 66.9091 34.9092 67.4546L41.091 130C41.2728 130.364 40.7274 130.909 40.1819 131.091Z"
          fill="#D6D6D6"
        />
        <path
          d="M145.819 120.909C145.273 120.909 144.91 120.545 144.728 120L138.364 56.1819C138.364 55.6364 138.728 55.0909 139.273 55.0909C139.819 55.0909 140.364 55.4546 140.364 56L146.728 119.818C146.91 120.364 146.364 120.909 145.819 120.909Z"
          fill="#D6D6D6"
        />
        <path
          d="M120.365 123.091C119.819 123.091 119.455 122.727 119.274 122.182L113.092 59.6364C113.092 59.0909 113.455 58.5455 114.001 58.5455C114.546 58.5455 115.092 58.9091 115.092 59.4546L121.274 122C121.455 122.545 120.91 123.091 120.365 123.091Z"
          fill="#D6D6D6"
        />
        <path
          d="M23.2735 112.727C22.7281 112.727 22.3644 112.364 22.1826 111.818C22.1826 111.273 22.5463 110.727 23.0917 110.727L160.001 97.2728C160.546 97.2728 161.092 97.6364 161.092 98.1819C161.092 98.7273 160.728 99.2728 160.183 99.2728L23.2735 112.727Z"
          fill="#D6D6D6"
        />
        <path
          d="M50.3641 104.364C54.6819 104.364 58.1823 100.863 58.1823 96.5455C58.1823 92.2276 54.6819 88.7273 50.3641 88.7273C46.0462 88.7273 42.5459 92.2276 42.5459 96.5455C42.5459 100.863 46.0462 104.364 50.3641 104.364Z"
          fill="#6BAC00"
        />
        <path
          d="M10.0008 18.5454C9.81898 16 11.6372 13.6364 14.1826 13.4545C16.7281 13.2727 19.0917 15.0909 19.2735 17.6364L19.6372 20.7273L10.3644 21.6364L10.0008 18.5454Z"
          fill="#FAE969"
        />
        <path
          d="M15.8188 28.9091C13.2733 29.2727 10.9097 27.2727 10.7279 24.7273L10.3643 21.6364L19.637 20.7273L20.0006 23.8182C20.1824 26.3637 18.3643 28.7273 15.8188 28.9091Z"
          fill="#FED402"
        />
        <path
          d="M24.1824 17.0909C24.0006 14.5455 25.8188 12.1818 28.3643 12C30.9097 11.8182 33.2733 13.6364 33.4552 16.1818L33.8188 19.2727L24.5461 20.1818L24.1824 17.0909Z"
          fill="#FAE969"
        />
        <path
          d="M30.0004 27.6363C27.455 27.8182 25.0914 26 24.9095 23.4545L24.5459 20.3636L33.8186 19.4545L34.1823 22.5454C34.3641 25.0909 32.5459 27.2727 30.0004 27.6363Z"
          fill="#FED402"
        />
        <path
          d="M132.001 6.54544C131.819 3.99999 133.637 1.63635 136.183 1.45454C138.728 1.27272 141.092 3.0909 141.274 5.63635L141.637 8.72726L132.364 9.63635L132.001 6.54544Z"
          fill="#FAE969"
        />
        <path
          d="M137.819 17.0909C135.273 17.2727 132.91 15.4546 132.728 12.7273L132.364 9.63639L141.637 8.72729L142.001 11.8182C142.182 14.5455 140.364 16.7273 137.819 17.0909Z"
          fill="#FED402"
        />
        <path
          d="M146.182 5.09092C146.001 2.54546 147.819 0.181825 150.364 6.6869e-06C152.91 -0.181812 155.273 1.63637 155.455 4.18183L155.819 7.27274L146.546 8.18183L146.182 5.09092Z"
          fill="#FAE969"
        />
        <path
          d="M152 15.6364C149.455 15.8182 147.091 14 146.91 11.4546L146.546 8.36367L155.819 7.27277L156.182 10.3637C156.364 13.0909 154.546 15.4546 152 15.6364Z"
          fill="#FED402"
        />
        <path
          d="M200.546 200C240.31 200 272.546 167.765 272.546 128C272.546 88.2355 240.31 56 200.546 56C160.781 56 128.546 88.2355 128.546 128C128.546 167.765 160.781 200 200.546 200Z"
          fill="#80C50E"
        />
        <path
          d="M200.546 188C233.683 188 260.546 161.137 260.546 128C260.546 94.8629 233.683 68 200.546 68C167.409 68 140.546 94.8629 140.546 128C140.546 161.137 167.409 188 200.546 188Z"
          fill="#F9F8F7"
        />
        <path
          d="M229.637 158.545C228.728 158.545 227.637 158.182 226.91 157.455L197.819 130.727C196.91 130 196.546 128.909 196.546 127.636V96.7273C196.546 94.5454 198.364 92.5454 200.728 92.5454C202.91 92.5454 204.91 94.3636 204.91 96.7273V126L232.728 151.455C234.364 152.909 234.546 155.636 232.91 157.273C231.819 158.182 230.728 158.545 229.637 158.545Z"
          fill="#A0A0A0"
        />
      </g>
      <defs>
        <radialGradient
          id={`paint${mainColor}_radial`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(212.216 98.3795) scale(80.8153)"
        >
          <stop stopColor="#BABCBE" />
          <stop offset="0.5478" stopColor="#CCCECF" />
          <stop offset="1" stopColor="#F9F8F7" />
        </radialGradient>
        <clipPath id={`clip${mainColor}`}>
          <rect width="272.364" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MilestoneCalendarSvg;
