import { useQuery } from '@apollo/client';
import { Dropdown, Stack, IDropdownOption, DefaultButton } from '@fluentui/react';
import React from 'react';
import { GET_COMPANY_CREATION_STATUSES } from '../../utils/statApi/CompanyCreationApi';
import { ICompanyCreationRequestListPageState } from './CompanyCreationListPage';
import AcquisitionFilter from './AcquisitionFilter';
import { CompanyCreationStatus } from './types';

const dropDownStyles = { root: { width: '200px' } };

interface ICompanyCreationFilterDropdownProps {
  initialSelection?: number[];
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ) => void;
}

const CompanyCreationStatusDropDown = ({
  initialSelection,
  onChange,
}: ICompanyCreationFilterDropdownProps): JSX.Element => {
  const { loading, data } = useQuery(GET_COMPANY_CREATION_STATUSES);
  const { companyCreationStatuses } = !loading && data ? data : [];

  const userFriendlyStatusNames: IDropdownOption[] =
    companyCreationStatuses?.map((ccs: IDropdownOption) => ({
      key: ccs.key,
      text: ccs.text.replace(/([A-Z])/g, ' $1').trim(), // Add spaces between words
    })) || [];

  return (
    <Dropdown
      label="Status"
      multiSelect
      options={userFriendlyStatusNames}
      placeholder="No Filter Selected"
      selectedKeys={initialSelection}
      onChange={onChange}
      styles={dropDownStyles}
    />
  );
};

interface IListFilterProps {
  requestorOptions: IDropdownOption[];
  acquisitionOptions: IDropdownOption[];
  filterState: ICompanyCreationRequestListPageState;
  setFilterState: React.Dispatch<React.SetStateAction<ICompanyCreationRequestListPageState>>;
}

const CompanyCreationListFilter = ({
  requestorOptions,
  acquisitionOptions,
  filterState,
  setFilterState,
}: IListFilterProps): JSX.Element => {
  const onReviewStatusChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    if (option.selected) {
      const selectedKeys = [...filterState.statusIds, option.key as number];
      setFilterState({ ...filterState, statusIds: selectedKeys });
    } else {
      const selectedKeys = [...filterState.statusIds].filter((key) => key !== option.key);
      setFilterState({ ...filterState, statusIds: selectedKeys });
    }
  };

  const onAssignmentFilterChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    if (option.selected) {
      const selectedKeys = [...filterState.requestorIds, option.key as number];
      setFilterState({ ...filterState, requestorIds: selectedKeys });
    } else {
      const selectedKeys = [...filterState.requestorIds].filter((key) => key !== option.key);
      setFilterState({ ...filterState, requestorIds: selectedKeys });
    }
  };

  const onResetFiltersClick = (): void => {
    setFilterState({
      statusIds: [
        CompanyCreationStatus.RequestStarted,
        CompanyCreationStatus.CompanyCodeRequested,
        CompanyCreationStatus.PendingSubledgerInfo,
        CompanyCreationStatus.PendingStatToolSetup,
      ],
      requestorIds: [],
      acquisitionIds: [],
    });
  };

  return (
    <Stack>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <h2>Filter</h2>
        <DefaultButton text="Reset Filters" onClick={onResetFiltersClick} />
      </Stack>
      <Stack tokens={{ childrenGap: 10 }} wrap>
        <Dropdown
          label="Requestor"
          multiSelect
          options={requestorOptions}
          styles={dropDownStyles}
          placeholder="No Filter Selected"
          onChange={onAssignmentFilterChange}
          selectedKeys={filterState?.requestorIds}
        />
        <CompanyCreationStatusDropDown
          initialSelection={filterState?.statusIds}
          onChange={onReviewStatusChange}
        />
        <AcquisitionFilter
          filterState={filterState}
          setFilterState={setFilterState}
          acquisitionOptions={acquisitionOptions}
        />
      </Stack>
    </Stack>
  );
};

export default CompanyCreationListFilter;
