import dayjs from 'dayjs';
import { INotificationItem } from '@coherence-design-system/notification-center';
import IDropdownReference from '../../utils/types/IDropdownReference';

export interface IStatNotification {
  id?: number;
  header: string;
  content: string;
  ordinal?: number;
  startDate?: Date;
  endDate?: Date;
  createdDate?: Date;
  notificationType: IDropdownReference;
}

export interface Toast {
  className?: string;
  id: number;
  header?: string;
  content: string;
  iconName?: string;
}

export enum NotificationType {
  Panel = 'Panel',
  Dashboard = 'Dashboard',
  Banner = 'Banner',
  DismissibleBanner = 'Banner (dismissible)',
}

export const convertNotificationToCoherence = (
  notifications: IStatNotification[],
): INotificationItem[] => {
  const notificationItems: INotificationItem[] = notifications
    .filter(
      (notification) =>
        new Date() >= new Date(notification.startDate) &&
        new Date() <= new Date(notification.endDate),
    )
    .map((notification: IStatNotification) => {
      return {
        itemKey: notification?.id.toString(),
        notificationStatus: 'Unread',
        timestamp: dayjs(notification.createdDate).toDate(),
        description: notification.content,
        title: notification.header,
        iconProps: { iconName: 'Ringer' },
      };
    });

  return notificationItems;
};
