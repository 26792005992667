import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IPivotHeaderItems } from '../../utils/types/IPivotHeaderItems';
import PivotHeader from '../common/headers/PivotHeader';
import Page from '../common/page/Page';
import ActionDescriptionPage from './ActionDescriptionPage';
import RoleDescriptionPage from './RoleDescriptionPage';

const RolesAndActionsPage = (): JSX.Element => {
  const HeaderItems: IPivotHeaderItems = {
    roles: {
      headerText: 'Roles',
      iconName: 'ServerProcesses',
    },
    actions: {
      headerText: 'Actions',
      iconName: 'ServerProcesses',
    },
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PivotHeader
          getHeaderTitle={() => {
            return <h1>Role Details</h1>;
          }}
          pivotHeaderItems={HeaderItems}
          baseUri="/role-description"
          ariaLabel="Navigation for Role Description"
          activeSegmentIndex={2}
        />
      </div>
      <Switch>
        <Page
          exact
          path="/role-description/roles"
          component={RoleDescriptionPage}
          title="Role Detail"
        />
        <Route exact path="/role-description/roles/:roleId" component={RoleDescriptionPage} />
        <Page
          exact
          path="/role-description/actions"
          component={ActionDescriptionPage}
          title="Action Detail"
        />
        <Route exact path="/role-description/actions/:actionId" component={ActionDescriptionPage} />
      </Switch>
    </>
  );
};

export default RolesAndActionsPage;
