import { IColumn } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Role } from '../../utils/statApi/RolesApi';

const getColumns = (): Array<IColumn> => {
  return [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
      onRender: (item: Role): JSX.Element => {
        return <Link to={`/role-description/roles/${item.id}`}>{item.name}</Link>;
      },
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 200,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
    },
  ];
};

export default getColumns;
