import { Stack } from '@fluentui/react';
import React from 'react';
import formatCurrency from '../../../utils/CurrencyFormatter';
import momentUtc from '../../../utils/DateFormatter';
import { paperSizeDisplay } from '../../../utils/Helpers';
import monthDayFormat from '../../../utils/MonthDayFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';
import IAgmDetail from '../../../utils/types/IAgmDetail';
import Card from '../../common/card/Card';
import SpanWithLabel from '../../common/SpanWithLabel';
import StackColumn from '../../common/StackColumn';

interface IAgmDetailsCardContentProps {
  agmDetail: IAgmDetail;
  isLoading: boolean;
  actionButtons?: React.ReactNode;
}
export const additionalTimeFormat = (detail: IAgmDetail): string => {
  let displayString = '';
  if (detail?.additionalProcessingTimeApostille) {
    displayString += 'Apostille, ';
  }
  if (detail?.additionalProcessingTimeLegalization) {
    displayString += 'Legalization, ';
  }
  if (detail?.additionalProcessingTimeLocalSigner) {
    displayString += 'Local Signer';
  }
  return displayString.replace(/,\s*$/, '');
};

export const externalSubmitterDisplay = (submitter: string): string => {
  switch (submitter) {
    case 'AUDITOR':
      return 'Auditor';
    case 'COUNSEL':
      return 'Counsel';
    case 'LOCAL':
      return 'Local Finance Team';
    case 'OTHER':
      return 'Other';
    default:
      return submitter;
  }
};

const AgmDetailsCardContent = ({
  agmDetail,
  isLoading,
  actionButtons,
}: IAgmDetailsCardContentProps): JSX.Element => {
  return (
    <Card title="AGM Details" isLoading={isLoading} actionButton={actionButtons}>
      <Stack horizontal wrap horizontalAlign="space-between">
        <StackColumn>
          <SpanWithLabel
            labelText="AFS Filing Deadline"
            value={momentUtc(agmDetail?.filingDueDate, StatDateFormats.DayBreakout)}
          />
          <SpanWithLabel
            labelText="Tax Filing deadline"
            value={
              agmDetail?.taxFilingDeadline
                ? momentUtc(agmDetail?.taxFilingDeadline, StatDateFormats.DayBreakout)
                : null
            }
          />

          <SpanWithLabel
            labelText="Do the AGM date and the FS date have to be aligned?"
            value={agmDetail?.requiredToBeFiledWithAfs}
          />
          <SpanWithLabel
            labelText="Is a hard copy signature required for filing?"
            value={agmDetail?.physicalSignatureRequired}
          />
          <SpanWithLabel
            labelText="Required ink color for the signatures"
            value={agmDetail.inkColorRequired ? `Yes - ${agmDetail?.signatureInkColor}` : `No`}
          />
          <SpanWithLabel labelText="Originals required" value={agmDetail?.originalsCount} />
          <SpanWithLabel
            labelText="Paper Size"
            value={
              agmDetail?.otherPaperSize
                ? `${paperSizeDisplay(agmDetail?.paperSize)} - ${agmDetail?.otherPaperSize}`
                : `${paperSizeDisplay(agmDetail?.paperSize)}`
            }
          />
          <SpanWithLabel
            labelText="Final external filing submitter"
            value={
              agmDetail?.otherFinalExternalFilingSubmitter
                ? `${externalSubmitterDisplay(agmDetail?.finalExternalFilingSubmitter)} - ${
                    agmDetail?.otherFinalExternalFilingSubmitter
                  }`
                : `${externalSubmitterDisplay(agmDetail?.finalExternalFilingSubmitter)}`
            }
          />

          <SpanWithLabel
            labelText="Period of Appointment"
            value={agmDetail?.auditorAppointmentPeriod}
          />

          <SpanWithLabel
            labelText="Auditor Fees"
            value={
              agmDetail?.auditorFeesCurrencyCode
                ? formatCurrency(agmDetail.auditorFeesCurrencyCode, agmDetail.auditorFees)
                : '--'
            }
          />
        </StackColumn>
        <StackColumn>
          <SpanWithLabel
            labelText="AGM Deadline"
            value={monthDayFormat(
              agmDetail?.annualGeneralMeetingDueDateMonth,
              agmDetail?.annualGeneralMeetingDueDateDay,
            )}
          />
          <SpanWithLabel
            labelText="Is there a formal AFS external filing required?"
            value={agmDetail?.externalAfsFilingRequired}
          />
          <SpanWithLabel
            labelText="Can the AFS deadline be extended?"
            value={agmDetail?.canAfsBeExtended}
          />

          <SpanWithLabel
            labelText="Address for hard copy"
            value={agmDetail?.hardCopyShippingAddress}
          />
          <SpanWithLabel
            labelText="Address contact name"
            value={agmDetail?.hardCopyShippingContactName}
          />
          <SpanWithLabel
            labelText="Address phone number"
            value={agmDetail?.hardCopyShippingPhoneNumber}
          />
          <SpanWithLabel
            labelText="Management Representation Letter Required?"
            value={agmDetail?.managementRepresentationLetterRequired}
          />
          <SpanWithLabel
            labelText="Auditing Firm"
            value={
              agmDetail?.otherAuditingFirm
                ? `${agmDetail.auditingFirm?.fieldName} - ${agmDetail?.otherAuditingFirm}`
                : `${agmDetail.auditingFirm?.fieldName}`
            }
          />
          <SpanWithLabel
            labelText="Additional Processing Time"
            value={additionalTimeFormat(agmDetail)}
          />
        </StackColumn>
      </Stack>
    </Card>
  );
};

export default AgmDetailsCardContent;
