import { gql } from '@apollo/client';

const OPS_DETAILS = gql`
  query OpsDetails {
    opsDetails {
      key: id
      text: name
    }
  }
`;

export default OPS_DETAILS;
