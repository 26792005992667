import * as React from 'react';
import { MessageBar, Stack, MessageBarType } from '@fluentui/react';

interface IMultiLineErrorMessage {
  message: string;
  isMultiline?: boolean;
  showError: boolean | unknown;
  type: 'error' | 'warning';
}
const MultiLineErrorMessage = (props: IMultiLineErrorMessage): JSX.Element => {
  const { showError, message, isMultiline, type } = props;
  return (
    <>
      {showError && (
        <MessageBar
          messageBarType={MessageBarType[type] as unknown as MessageBarType}
          isMultiline={isMultiline}
        >
          <Stack>
            <span>{message}</span>
          </Stack>
        </MessageBar>
      )}
    </>
  );
};

export default MultiLineErrorMessage;
