import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

import ActionButtons from '../../common/ActionButtons';
import SubledgerInfoForm from './SubledgerInfoForm';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ISubledgerInfo } from './types';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  UPDATE_SUBLEDGER_INFO,
} from '../../../utils/statApi/CompanyCreationApi';

interface ISubledgerInfoPanelProps {
  closePanel: () => void;
  data: ISubledgerInfo;
  companyCreationId: number;
}

const InHouseCashCenterSetupPanel = ({
  closePanel,
  data,
  companyCreationId,
}: ISubledgerInfoPanelProps): JSX.Element => {
  const form = useForm<ISubledgerInfo>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateInHouseCashCenterSetup, { loading, error }] = useMutation(UPDATE_SUBLEDGER_INFO, {
    onCompleted: closePanel,
    refetchQueries: [
      {
        query: GET_COMPANY_CREATION_COMPANY_INFO,
        variables: {
          companyCreationId,
        },
      },
      {
        query: GET_COMPANY_CREATION_PROGRESS,
        variables: {
          companyCreationId,
        },
      },
    ],
  });

  const handleOnSaveClick = (formData: ISubledgerInfo): void => {
    updateInHouseCashCenterSetup({
      variables: {
        input: {
          id: formData.id || -1,
          companyCreationRequestId: companyCreationId,
          isDomesticEntity: formData.isDomesticEntity,
          willHaveIntercompanyActivity: formData.willHaveIntercompanyActivity,
          willRequireMscPremierServices: formData.willRequireMscPremierServices,
          willHaveHeadcount: formData.willHaveHeadcount,
          personalSubareaCities: formData.personalSubareaCities,
          weeklyWorkingHours: formData.weeklyWorkingHours,
          willNeedFaModule: formData.willNeedFaModule,
          willNeedApMmModule: formData.willNeedApMmModule,
          willTransmitToMsSales: formData.willTransmitToMsSales,
          bsReconOwner: formData?.bsReconOwner?.alias
            ? {
                id: formData.bsReconOwner?.id ? parseInt(formData.bsReconOwner?.id, 10) : null,
                graphGUID: formData.bsReconOwner.graphId,
                firstName: formData.bsReconOwner.firstName,
                lastName: formData.bsReconOwner.lastName,
                displayName: formData.bsReconOwner.name,
                alias: formData.bsReconOwner.alias,
                emailAddress: formData.bsReconOwner.email,
                contactType: formData.bsReconOwner.contactType,
                personnelNumber: formData.bsReconOwner.personnelNumber,
              }
            : null,
          bsReconReviewer: formData?.bsReconReviewer?.alias
            ? {
                id: formData.bsReconReviewer?.id
                  ? parseInt(formData.bsReconReviewer?.id, 10)
                  : null,
                graphGUID: formData.bsReconReviewer.graphId,
                firstName: formData.bsReconReviewer.firstName,
                lastName: formData.bsReconReviewer.lastName,
                displayName: formData.bsReconReviewer.name,
                alias: formData.bsReconReviewer.alias,
                emailAddress: formData.bsReconReviewer.email,
                contactType: formData.bsReconReviewer.contactType,
                personnelNumber: formData.bsReconReviewer.personnelNumber,
              }
            : null,
          bsReconController: formData?.bsReconController?.alias
            ? {
                id: formData.bsReconController?.id
                  ? parseInt(formData.bsReconController?.id, 10)
                  : null,
                graphGUID: formData.bsReconController.graphId,
                firstName: formData.bsReconController.firstName,
                lastName: formData.bsReconController.lastName,
                displayName: formData.bsReconController.name,
                alias: formData.bsReconController.alias,
                emailAddress: formData.bsReconController.email,
                contactType: formData.bsReconController.contactType,
                personnelNumber: formData.bsReconController.personnelNumber,
              }
            : null,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Subledger Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit Subledger Info Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Subledger Info"
          saveTitle="Save Subledger Info"
          cancelLabel="Cancel Subledger Info"
          cancelTitle="Cancel Subledger Info"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <SubledgerInfoForm form={form} data={data} />
      </form>
    </CoherencePanel>
  );
};

export default InHouseCashCenterSetupPanel;
