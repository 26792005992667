import { FontIcon, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import MilestoneDetails, { IMilestoneDetailsProps } from './MilestoneDetails';

interface IMilestoneDetailsWrapperProps extends IMilestoneDetailsProps {
  iconName: string;
  color: string;
}

const MilestoneDetailsWrapper = (props: IMilestoneDetailsWrapperProps): JSX.Element => {
  const { id, status, iconName, color } = props;
  const [isVisible, setIsVisible] = useState(false);

  const tooltipContentStyle = { minWidth: '200px' };

  return (
    <TooltipHost
      id={id.toString()}
      key={id}
      calloutProps={{
        isBeakVisible: false,
        gapSpace: 0,
        setInitialFocus: true,
        doNotLayer: false,
      }}
      onTooltipToggle={setIsVisible}
      tooltipProps={{
        onRenderContent: () => {
          return (
            <div style={tooltipContentStyle}>
              {isVisible && <MilestoneDetails id={id} status={status} />}
            </div>
          );
        },
      }}
    >
      <div style={{ height: 15, width: 15, color }}>
        <FontIcon iconName={iconName} />
      </div>
    </TooltipHost>
  );
};

export default MilestoneDetailsWrapper;
