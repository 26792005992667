import React from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { Label, TagPicker, ITag, IInputProps, IBasePickerSuggestionsProps } from '@fluentui/react';
import { GET_SEC_REGION_AREAS_BY_SEARCH_INPUT } from '../../../utils/statApi/CompaniesApi';
import InlineFormInputErrorMessage from '../../common/errorContent/InlineFormInputErrorMessage';
import { ISecRegionArea } from '../../../utils/types/ISecRegionArea';
import {
  countryPickerStyles,
  pickerErrorMessage,
} from '../../../app/common/styles/CommonStyleObjects';
import { convertSecRegionAreaToTag } from '../../../utils/Helpers';

interface IWwAreaPickerProps {
  label?: JSX.Element;
  itemLimit?: number;
  isDisabled: boolean;
  selectedItems: ITag[];
  errorMessage: string;
  setSelectedItems: (secRegion: ITag[]) => void;
}

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested Areas',
  noResultsFoundText: 'No areas found',
};

const getTextFromItem = (item: ITag) => item.name;

const WwAreaPicker = ({
  setSelectedItems,
  selectedItems,
  isDisabled,
  errorMessage,
  label,
  itemLimit,
}: IWwAreaPickerProps): JSX.Element => {
  const [loadSecRegionAreas] = useLazyQuery(GET_SEC_REGION_AREAS_BY_SEARCH_INPUT);
  // - retrieve secRegions from server based on keyword input
  // - create array of returned secRegions
  const onResolveSuggestions = async (filterText: string): Promise<ITag[]> => {
    return loadSecRegionAreas({ variables: { keyword: filterText } }).then(
      (result: QueryResult<{ secRegionAreas: ISecRegionArea[] }, OperationVariables>) => {
        const secRegionAreaList: ISecRegionArea[] = result?.data?.secRegionAreas;
        return secRegionAreaList?.map((secRegionArea): ITag => {
          return convertSecRegionAreaToTag(secRegionArea);
        });
      },
    );
  };

  const addSelectedItem = (items: ITag[]): void => {
    setSelectedItems(items);
  };

  const labelText = 'Area';
  const controlName = 'secRegions';
  const inputProps: IInputProps = {
    title: labelText,
    'aria-label': `${labelText} Region Area Picker`,
    name: controlName,
    id: `${controlName}Picker`,
    required: true,
    placeholder: 'Search Areas',
  };

  const onItemSelected = (item?: ITag): ITag | PromiseLike<ITag> => {
    const secRegion: ITag = {
      key: item.key,
      name: item.name,
    };
    return secRegion;
  };

  return (
    <>
      {label || <Label htmlFor={`${controlName}Picker`}>{labelText}</Label>}
      <TagPicker
        itemLimit={itemLimit ?? undefined}
        resolveDelay={300}
        selectedItems={selectedItems}
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Area"
        onItemSelected={onItemSelected}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={inputProps}
        onChange={addSelectedItem}
        disabled={isDisabled}
        styles={countryPickerStyles}
        onEmptyResolveSuggestions={(): ITag[] => {
          return [];
        }}
      />
      <InlineFormInputErrorMessage
        errorMessage={errorMessage}
        errorWrapperStyle={pickerErrorMessage}
      />
    </>
  );
};
export default WwAreaPicker;
