import config from '../../components/common/ConfigLoader';

export interface IFileValidationError {
  title: string;
  value: string;
}
const validateMaxSize = (filesize: number): IFileValidationError[] => {
  const errors = Array<IFileValidationError>();
  const maxSize = parseInt(config?.settings?.maxSizeMb, 10);
  if (filesize > maxSize * 1048576) {
    errors.push({
      title: `File is larger than ${maxSize} MB`,
      value: `${filesize}`,
    });
  }
  return errors;
};

const validateMinSize = (filesize: number): IFileValidationError[] => {
  const errors = Array<IFileValidationError>();
  if (filesize === 0) {
    errors.push({
      title: `File is empty`,
      value: `${filesize}`,
    });
  }
  return errors;
};

const validateFileExtension = (filename: string): IFileValidationError[] => {
  const errors = Array<IFileValidationError>();
  const extension = filename.split('.').pop().toLowerCase();
  const allowedExtensions = new RegExp(config?.settings?.validUploadExtensionsRegex);
  if (!allowedExtensions.exec(extension)) {
    errors.push({
      title: `File contains invalid extension`,
      value: extension,
    });
  }
  return errors;
};

const validateFilenameLength = (filename: string): IFileValidationError[] => {
  const errors = Array<IFileValidationError>();
  if (filename.length > 100) {
    errors.push({
      title: `Filename cannot be greater than 100 characters`,
      value: filename,
    });
  }
  return errors;
};

const validateFile = (file: File): IFileValidationError[] => {
  const errors = Array<IFileValidationError>();
  const filenameError = validateFilenameLength(file.name);
  const sizeError = validateMaxSize(file.size);
  const emptyError = validateMinSize(file.size);
  const extensionError = validateFileExtension(file.name);

  return errors.concat(sizeError, extensionError, filenameError, emptyError);
};
export default validateFile;
