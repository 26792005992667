import React from 'react';
import { Stack, Separator, Label, Shimmer, ActivityItem, Icon } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { labelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_ALL_MILESTONE_HISTORY,
  MilestoneHistory,
  MilestoneHistoryActionType,
} from '../../../utils/statApi/MilestoneApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IMilestone } from './Interfaces';
import momentUtc from '../../../utils/DateFormatter';

interface IMilestoneHistoryProps {
  milestone: IMilestone;
}

const MilestoneHistoryList = ({ milestone }: IMilestoneHistoryProps): JSX.Element => {
  const milestoneId = milestone.id;
  const { error, loading, data } = useQuery(GET_ALL_MILESTONE_HISTORY, {
    variables: { milestoneId },
    fetchPolicy: 'network-only',
  });

  const mapActionType = (actionType: MilestoneHistoryActionType, comments: string): string => {
    const rescindString = 'rescinded this milestone';
    const commentString = 'commented';
    switch (actionType) {
      case MilestoneHistoryActionType.Signoff:
        return 'signed off on this milestone';
      case MilestoneHistoryActionType.Rescind:
        if (comments) {
          return `${rescindString} - ${comments}`;
        }
        return rescindString;
      case MilestoneHistoryActionType.Update:
        return `updated the due date on this milestone`;
      case MilestoneHistoryActionType.Comment:
        return `${commentString} - ${comments}`;
      default:
        return 'Unknown action!';
    }
  };

  const getActionIconName = (actionType: MilestoneHistoryActionType) => {
    switch (actionType) {
      case MilestoneHistoryActionType.Comment:
        return 'message';
      case MilestoneHistoryActionType.Rescind:
        return 'returnToSession';
      case MilestoneHistoryActionType.Signoff:
        return 'insertSignatureLine';
      case MilestoneHistoryActionType.Update:
        return 'edit';
      default:
        return '';
    }
  };
  return (
    <Stack.Item>
      <Separator />
      <div>
        <Label styles={labelStyles}>Milestone History</Label>
        <Shimmer isDataLoaded={!loading}>
          <LoadingErrorMessage
            error={error}
            loading={loading}
            actionName="while loading the milestone history"
          />
          <>
            {data &&
              data.milestoneHistory.map((ma: MilestoneHistory) => {
                return (
                  <ActivityItem
                    key={ma.id}
                    timeStamp={momentUtc(ma.actionDate)}
                    activityIcon={<Icon iconName={getActionIconName(ma.actionType)} />}
                    activityDescription={
                      <div>
                        {` - ${ma.statUserName} ${mapActionType(ma.actionType, ma.comments)}`}
                      </div>
                    }
                  />
                );
              })}
          </>
        </Shimmer>
      </div>
    </Stack.Item>
  );
};

export default MilestoneHistoryList;
