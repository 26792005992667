import { CoherenceTheme } from '@coherence-design-system/styles';
import React from 'react';
import { FontIcon, IColumn, mergeStyles, TooltipHost } from '@fluentui/react';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';
import { getIconName } from '../formFields/fileUpload/fileSelector.config';
import { openBlob } from './documentDownload';
import ICompanyMilestoneDocument from '../../../utils/types/ICompanyMilestoneDocument';
import { IDocument } from '../../../utils/statApi/DocumentApi';

const linkButtonStyle = mergeStyles({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  margin: 0,
  padding: 0,
  textDecoration: 'none',
  color: CoherenceTheme.palette.themePrimary,
  selectors: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const getDocumentListColumns = (onError: (errorMessage?: string) => void): IColumn[] => {
  return [
    {
      key: 'column1',
      name: 'File Type',
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'name',
      minWidth: 16,
      maxWidth: 16,
      onRender: (item: IDocument) => (
        <FontIcon
          aria-label={`${getIconName(item.title)} file`}
          iconName={`${getIconName(item.title)}`}
        />
      ),
    },
    {
      key: 'column2',
      name: 'Title',
      fieldName: 'title',
      minWidth: 210,
      maxWidth: 350,
      onRender: (item: IDocument) => {
        return (
          <TooltipHost content={item.title}>
            <button
              type="button"
              aria-label="Open Document"
              onClick={async () => {
                return openBlob(item.url, onError);
              }}
              className={linkButtonStyle}
            >
              {item.title}
            </button>
          </TooltipHost>
        );
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Date Uploaded',
      fieldName: 'dateUploadedValue',
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      data: 'number',
      onRender: (item: IDocument) => {
        return <span>{momentUtc(item.uploadDate, StatDateFormats.DayBreakout)}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Uploaded By',
      fieldName: 'uploadedBy',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item: IDocument) => {
        return (
          <TooltipHost content={item.uploader?.displayName}>
            <span>{item.uploader?.displayName}</span>
          </TooltipHost>
        );
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Process Type',
      fieldName: 'processType',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
    },
    {
      key: 'column6',
      name: 'Milestone Name',
      fieldName: 'milestoneName',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item: ICompanyMilestoneDocument) => {
        return (
          <TooltipHost content={item.milestoneName}>
            <span>{item.milestoneName}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: 'column7',
      name: 'Process Name',
      fieldName: 'processName',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item: ICompanyMilestoneDocument) => {
        return (
          <TooltipHost content={item.processName}>
            <span>{item.processName}</span>
          </TooltipHost>
        );
      },
    },
  ];
};

export default getDocumentListColumns;
