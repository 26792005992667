import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import hasAccess from '../../utils/authorization/authorizationCheck';
import UnauthorizedPage from './UnauthorizedPage';
import { StatUser } from '../../utils/statApi/UsersApi';
import UserContext from '../../utils/authorization/UserContext';

export interface ProtectedRouteProps extends RouteProps {
  requestedPermission: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requestedPermission,
  ...props
}: ProtectedRouteProps) => {
  const user: StatUser = React.useContext(UserContext);

  return hasAccess(user.permissions, requestedPermission) ? (
    <Route {...props} />
  ) : (
    <UnauthorizedPage />
  );
};

export default ProtectedRoute;
