import React from 'react';
import { TextField, Stack } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import CurrencyCodeDropdown from '../../../common/formFields/CurrencyCodeDropdown';
import ITaxDividendFormProps from './ITaxDividendFormProps';

const TaxDividendForm = ({ taxDividends, form, companyId }: ITaxDividendFormProps): JSX.Element => {
  const { control, errors, setValue } = form;
  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <Controller
          as={TextField}
          style={{ width: '295px' }}
          label="Tax Proposed Dividend Amount"
          required
          type="number"
          min={0}
          defaultValue={taxDividends.taxProposedDividendAmount}
          name="taxProposedDividendAmount"
          id="taxProposedDividendAmount"
          errorMessage={errors?.taxProposedDividendAmount?.message}
          control={control}
          rules={{
            min: {
              value: 0,
              message: 'Tax Proposed Dividend Amount cannot be less than zero',
            },
            max: {
              value: 99999999999999,
              message: 'Tax Proposed Dividend Amount cannot be that larger than 99,999,999,999,999',
            },
            required: 'Please provide a Tax Proposed Dividend Amount',
          }}
        />
        <Controller
          name="taxCurrencyCode"
          control={control}
          render={() => (
            <CurrencyCodeDropdown
              propertyName="taxCurrencyCode"
              useDefaultCurrenciesForCompanyId={companyId}
              required
              defaultValue={taxDividends.taxCurrencyCode}
              errorMessage={errors?.taxCurrencyCode?.message}
              handleChange={(newValue: string, valueKey: string): void => {
                setValue('taxCurrencyCode', newValue);
              }}
            />
          )}
          defaultValue={taxDividends.taxCurrencyCode}
          rules={{
            required: 'Please provide a Currency Code',
          }}
          required
        />
      </Stack>
      <Controller
        as={TextField}
        label="Comments"
        control={control}
        id="taxComments"
        name="taxComments"
        defaultValue={taxDividends.taxComments}
        errorMessage={errors?.taxComments?.message}
      />
    </Stack>
  );
};

export default TaxDividendForm;
