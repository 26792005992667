import React, { useContext } from 'react';
import { Nav, INavLink } from '@m365-admin/nav';
import { IconButton, IIconProps } from '@fluentui/react';
import { useLocation, useHistory } from 'react-router-dom';
import navLinkGroups from './Navigation.config';
import {
  mobileStickyNav,
  mobileNavTriggerWrapper,
  mobileNavTrigger,
  defaultNavWrapper,
  leftNavContainer,
  mobileNavWrapper,
} from '../../app/common/styles/CommonStyleObjects';
import OutsideAlerter from '../../utils/OutsideAlerter';
import { MOBILE_NAV_MAX_WIDTH } from '../../app/common/constants/SiteConstants';
import { StatUser } from '../../utils/statApi/UsersApi';
import UserContext from '../../utils/authorization/UserContext';

interface INavigationProps {
  navIsHidden: boolean;
  setNavIsHidden: (navIsHidden: boolean) => void;
}

const Navigation = ({ navIsHidden, setNavIsHidden }: INavigationProps): JSX.Element => {
  const history = useHistory();

  const user: StatUser = useContext(UserContext);
  const location = useLocation();

  const collapseNavProps: Partial<INavLink> = {
    onClick: () => {
      setNavIsHidden(!navIsHidden);
    },
    'aria-label': 'Toggle side navigation',
  };
  const hamburgerIcon: IIconProps = {
    iconName: 'GlobalNavButton',
  };
  const closeMenuOnMobileOutsideClick = (): void => {
    const windowWidth = window.innerWidth;
    if (windowWidth < MOBILE_NAV_MAX_WIDTH) {
      setNavIsHidden(true);
    }
  };

  const handleLinkClick = (ev: React.MouseEvent<HTMLElement>, item: INavLink): void => {
    ev.preventDefault();
    ev.stopPropagation();
    history.push(item.url || item.href);
  };

  return (
    <div className={leftNavContainer}>
      <div className={mobileNavTriggerWrapper}>
        <IconButton
          ariaLabel="Open mobile navigation"
          iconProps={hamburgerIcon}
          className={mobileNavTrigger}
          onClick={(): void => setNavIsHidden(false)}
        />
      </div>
      <OutsideAlerter onClickOutside={(): void => closeMenuOnMobileOutsideClick()}>
        <div className={navIsHidden ? defaultNavWrapper : mobileNavWrapper}>
          <Nav
            collapseNavLinkProps={collapseNavProps}
            styles={mobileStickyNav}
            groups={navLinkGroups(handleLinkClick, location.pathname, user)}
            onNavCollapsed={(): void => setNavIsHidden(true)}
            isNavCollapsed={navIsHidden}
          />
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default Navigation;
