import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  UPDATE_SAP_SETUP_INFO,
} from '../../../utils/statApi/CompanyCreationApi';

import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import SapSetupForm from './SapSetupForm';
import { ISapSetupInfo } from './types';

interface ISapSetupInfoPanelProps {
  closePanel: () => void;
  data: ISapSetupInfo;
  companyCreationId: number;
}

const SapSetupPanel = ({
  closePanel,
  data,
  companyCreationId,
}: ISapSetupInfoPanelProps): JSX.Element => {
  const form = useForm<ISapSetupInfo>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateSapSetupInfo, { loading, error }] = useMutation(UPDATE_SAP_SETUP_INFO, {
    onCompleted: closePanel,
    refetchQueries: [
      {
        query: GET_COMPANY_CREATION_COMPANY_INFO,
        variables: {
          companyCreationId,
        },
      },
      {
        query: GET_COMPANY_CREATION_PROGRESS,
        variables: {
          companyCreationId,
        },
      },
    ],
  });

  const handleOnSaveClick = (formData: ISapSetupInfo): void => {
    const location = formData.msReportingSalesLocation?.id
      ? {
          id: formData.msReportingSalesLocation?.id,
          name: formData.msReportingSalesLocation?.name,
        }
      : null;

    updateSapSetupInfo({
      variables: {
        input: {
          id: formData.id || -1,
          companyCreationRequestId: companyCreationId,
          taxJurisdictionCode: formData.taxJurisdictionCode,
          region: formData.region,
          msReportingSalesYear: formData.msReportingSalesYear,
          msReportingSalesLocation: location,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit SAP Setup Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit SAP Setup Info Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save SAP Setup Info"
          saveTitle="Save SAP Setup Info"
          cancelLabel="Cancel SAP Setup Info Edit"
          cancelTitle="Cancel SAP Setup Info Edit"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <SapSetupForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default SapSetupPanel;
