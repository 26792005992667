import { useQuery } from '@apollo/client';
import { IconButton } from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_PARENT_COMPANY_INFO,
} from '../../../utils/statApi/CompanyCreationApi';
import CanAccess from '../../common/canAccess/CanAccess';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import {
  CompanyCreationStatus,
  CompanyInformationForm,
  FinancialType,
  ICompanyCreationRequestDetails,
} from '../types';
import CompanyRequestInfoCards from './CompanyRequestInfoCards';
import FunctionalCurrencyEditPanel from './functionalCurrencyForms/FunctionalCurrencyEditPanel';
import InHouseCashCenterSetupPanel from './InHouseCashCenterSetupPanel';
import LegalDocumentInfoPanel from './LegalDocumentInfoPanel';
import SapSetupPanel from './SapSetupPanel';
import ShadowCompanyPanel from './ShadowCompanyPanel';
import StatutoryContactsPanel from './StatutoryContactsPanel';
import StatutoryInformationPanel from './StatutoryInformationPanel';
import SubledgerInfoPanel from './SubledgerInfoPanel';

interface ICompanyInformationPageState {
  isEditPanelOpen: boolean;
  currentForm?: CompanyInformationForm;
}

interface ICompanyInformationPageProps {
  companyRequestInitialInfo: ICompanyCreationRequestDetails;
}

const CompanyInformationPage: React.FunctionComponent<ICompanyInformationPageProps> = ({
  companyRequestInitialInfo,
}: ICompanyInformationPageProps): JSX.Element => {
  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const [panelState, setPanelState] = useState<ICompanyInformationPageState>({
    isEditPanelOpen: false,
    currentForm: null,
  });

  const isNonTransactional =
    companyRequestInitialInfo?.financialType?.id === FinancialType.NonTransactional;

  const isShadowRequest =
    companyRequestInitialInfo?.financialType?.id === FinancialType.ShadowCompany;

  const query = isShadowRequest ? GET_PARENT_COMPANY_INFO : GET_COMPANY_CREATION_COMPANY_INFO;

  const { data, loading, error } = useQuery(query, {
    variables: {
      companyCreationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const requestInfo = isShadowRequest ? data?.parentCompanyInfo : data?.companyCreationCompanyInfo;

  const statusId = requestInfo?.statusId;

  const isRequestReadOnly =
    statusId === CompanyCreationStatus.Canceled || statusId === CompanyCreationStatus.Complete;

  const isRequestAssigned =
    statusId === CompanyCreationStatus.PendingSubledgerInfo ||
    statusId === CompanyCreationStatus.PendingStatToolSetup;

  const closePanel = (): void => {
    setPanelState({ isEditPanelOpen: false, currentForm: null });
  };

  const renderActionButton = (
    label: string,
    formType: CompanyInformationForm,
    requestedAction?: string,
    visible = true,
  ): JSX.Element => {
    return (
      <>
        {!isRequestReadOnly && visible && (
          <CanAccess requestedAction={requestedAction}>
            <IconButton
              title={`Edit ${label}`}
              ariaLabel={label}
              iconProps={{ iconName: 'Edit' }}
              onClick={(): void => {
                setPanelState({
                  isEditPanelOpen: true,
                  currentForm: formType,
                });
              }}
            />
          </CanAccess>
        )}
      </>
    );
  };

  const functionalCurrency = requestInfo?.functionalCurrency;
  const legalDocumentInfo = requestInfo?.legalDocumentInfo;
  const sapSetupInfo = requestInfo?.sapSetupInfo;
  const statutoryInformation = requestInfo?.statutoryInformation;
  const shadowCompanyInfo = requestInfo?.shadowCompanyInfo;
  const inHouseCashCenterSetup = requestInfo?.inHouseCashCenterSetup;
  const companyContacts = requestInfo?.statutoryContacts;
  const subledgerInfo = requestInfo?.subledgerInfo;

  return (
    <div className={`${bodyContentContainer} lightBackground`}>
      <CompanyRequestInfoCards
        companyRequestInfo={requestInfo}
        loading={loading}
        error={error}
        showCurrencyDocument={!isNonTransactional && !isShadowRequest}
        showMsSalesLocation={!isNonTransactional}
        showShadowCompanyCard={!isNonTransactional && !isShadowRequest}
        showStatContactsCard={!isShadowRequest}
        showCompanyCreationContactsCard={!isShadowRequest}
        showSapSetup={!isNonTransactional}
        showIhccSetup={!isNonTransactional && !isShadowRequest}
        showSubledger={!isNonTransactional && !isShadowRequest}
        showFunctionalCurrency={!isNonTransactional}
        currencyActionButton={
          !isShadowRequest
            ? renderActionButton(
                'Functional Currency',
                CompanyInformationForm.FunctionalCurrency,
                'CompanyCreation:Edit',
                !isRequestAssigned,
              )
            : null
        }
        legalDocumentActionButton={
          !isShadowRequest
            ? renderActionButton(
                'Legal Document Info',
                CompanyInformationForm.LegalDocumentInfo,
                'CompanyCreation:Edit',
                !isRequestAssigned || isNonTransactional,
              )
            : null
        }
        sapSetupActionButton={
          !isShadowRequest
            ? renderActionButton(
                'SAP Setup',
                CompanyInformationForm.SapSetup,
                'CompanyCreation:Edit',
                !isRequestAssigned,
              )
            : null
        }
        ihccActionButton={renderActionButton(
          'In House Cash Center',
          CompanyInformationForm.InHouseCashCenter,
          'CompanyCreation:Edit',
          !isRequestAssigned,
        )}
        shadowCompanyActionButton={renderActionButton(
          'Shadow Company',
          CompanyInformationForm.ShadowCompany,
          'CompanyCreation:Edit',
          !isRequestAssigned,
        )}
        subledgerActionButton={renderActionButton(
          'Subledger Info',
          CompanyInformationForm.SubledgerInfo,
          'CompanyCreation:Edit',
        )}
        statutoryInfoActionButton={renderActionButton(
          'Statutory Information',
          CompanyInformationForm.StatutoryInformation,
          'CompanyCreation:Edit',
        )}
        companyCreationContactsButton={renderActionButton(
          'Company Creation Contacts',
          CompanyInformationForm.CompanyCreationContacts,
          'CompanyCreation:Edit',
        )}
        isShadowRequest={isShadowRequest}
      />
      {panelState.isEditPanelOpen &&
        panelState.currentForm === CompanyInformationForm.FunctionalCurrency && (
          <FunctionalCurrencyEditPanel
            closePanel={closePanel}
            data={functionalCurrency}
            companyCreationId={companyCreationId}
          />
        )}
      {panelState.isEditPanelOpen &&
        panelState.currentForm === CompanyInformationForm.LegalDocumentInfo && (
          <LegalDocumentInfoPanel
            closePanel={closePanel}
            data={legalDocumentInfo}
            companyCreationId={companyCreationId}
            isNonTransactional={isNonTransactional}
          />
        )}
      {panelState.isEditPanelOpen &&
        panelState.currentForm === CompanyInformationForm.InHouseCashCenter && (
          <InHouseCashCenterSetupPanel
            closePanel={closePanel}
            data={inHouseCashCenterSetup}
            companyCreationId={companyCreationId}
          />
        )}
      {panelState.isEditPanelOpen && panelState.currentForm === CompanyInformationForm.SapSetup && (
        <SapSetupPanel
          closePanel={closePanel}
          data={sapSetupInfo}
          companyCreationId={companyCreationId}
        />
      )}
      {panelState.isEditPanelOpen &&
        panelState.currentForm === CompanyInformationForm.StatutoryInformation && (
          <StatutoryInformationPanel
            closePanel={closePanel}
            data={statutoryInformation}
            companyCreationId={companyCreationId}
          />
        )}
      {panelState.isEditPanelOpen &&
        panelState.currentForm === CompanyInformationForm.ShadowCompany && (
          <ShadowCompanyPanel
            closePanel={closePanel}
            data={shadowCompanyInfo}
            companyCreationId={companyCreationId}
          />
        )}
      {panelState.isEditPanelOpen &&
        panelState.currentForm === CompanyInformationForm.CompanyCreationContacts && (
          <StatutoryContactsPanel
            closePanel={closePanel}
            data={companyContacts}
            companyCreationId={companyCreationId}
            isBpoRequired={statutoryInformation?.isOneFinance}
          />
        )}
      {panelState.isEditPanelOpen &&
        panelState.currentForm === CompanyInformationForm.SubledgerInfo && (
          <SubledgerInfoPanel
            closePanel={closePanel}
            data={subledgerInfo}
            companyCreationId={companyCreationId}
          />
        )}
    </div>
  );
};

export default CompanyInformationPage;
