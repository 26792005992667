import React from 'react';
import { ActionButton, IColumn } from '@fluentui/react';
import { StatUser } from '../../utils/statApi/UsersApi';
import ShimmeredDetailsListWrapper from '../common/DetailsLists/ShimmeredDetailsListWrapper';
import IItemListProps from '../../utils/types/IItemListProps';
import { columns } from './UsersListConfig';

const UsersList = (props: IItemListProps<StatUser>): JSX.Element => {
  const { items, isLoading, onRowClick } = props;

  const renderItemColumn = (item: StatUser, index: number, column: IColumn): JSX.Element => {
    const fieldContent = item[column.fieldName] as string;

    switch (column.key) {
      case 'name': {
        return (
          <ActionButton
            className="user-link"
            onClick={() => {
              onRowClick(item);
            }}
          >
            {fieldContent}
          </ActionButton>
        );
      }
      case 'role': {
        return <span>{item.roleName}</span>;
      }
      case 'status': {
        const status = item.isActive ? 'Active' : 'Deactivated';
        return <span>{status}</span>;
      }
      default:
        return <span>{fieldContent}</span>;
    }
  };

  return (
    <ShimmeredDetailsListWrapper
      columns={columns}
      items={items || []}
      enableShimmer={isLoading}
      onRenderItemColumn={renderItemColumn}
      singularListLabel="User"
      pluralListLabel="Users"
    />
  );
};
export default UsersList;
