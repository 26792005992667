import React, { useState } from 'react';
import { Stack, Dropdown, Checkbox, NeutralColors } from '@fluentui/react';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import { multiDropdownLargeWrapper } from './CompanyDocumentStyles';
import { bodyContent } from '../../common/headers/Headers.styles';
import MilestoneDocuments from './MilestoneDocuments';
import ProcessExtensionDocuments from './ProcessExtensionDocuments';
import CompanyDocuments from './CompanyDocuments';

const CompanyDocumentsPage = (): JSX.Element => {
  const [documentType, setDocumentType] = useState('Milestone');
  const [showDeletedFiles, setShowDeletedFiles] = useState(false);

  const checkboxPadding = { paddingTop: 15 };
  const cardWrapperStyles = { padding: '15px 0', background: NeutralColors.white };

  return (
    <>
      <div className={bodyContentContainer}>
        <div className="ms-depth-4" style={cardWrapperStyles}>
          <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <div className={bodyContent().bodyContentTitle} style={{ padding: '0 25px' }}>
              <Dropdown
                label="Document Type"
                className={multiDropdownLargeWrapper}
                onChange={(event, item): void => {
                  setDocumentType(item.key as string);
                }}
                selectedKey={documentType}
                placeholder="Select an option"
                options={[
                  { key: 'Milestone', text: 'Milestone Documents' },
                  { key: 'ProcessExtension', text: 'Process Extension Documents' },
                  { key: 'Company', text: 'Company Documents' },
                ]}
              />
              <Stack style={checkboxPadding} horizontal>
                <Checkbox
                  ariaLabel="Show recycled documents"
                  label="Show recycled documents"
                  onChange={(ev, isChecked): void => {
                    setShowDeletedFiles(isChecked);
                  }}
                />
              </Stack>
            </div>
          </Stack>
          {documentType === 'Milestone' && (
            <MilestoneDocuments showDeletedFiles={showDeletedFiles} />
          )}
          {documentType === 'ProcessExtension' && (
            <ProcessExtensionDocuments showDeletedFiles={showDeletedFiles} />
          )}
          {documentType === 'Company' && <CompanyDocuments showDeletedFiles={showDeletedFiles} />}
        </div>
      </div>
    </>
  );
};
export default CompanyDocumentsPage;
