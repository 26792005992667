import { INotificationItem } from '@coherence-design-system/notification-center';
import React from 'react';
import { IStatNotification, Toast } from './StatNotification';

interface INotificationsContext {
  notifications: INotificationItem[];
  statNotifications: IStatNotification[];
  toasts: Toast[];
  setNotifications: (notifications: INotificationItem[]) => void;
  setStatNotifications: (statNotifications: IStatNotification[]) => void;
  setToasts: (toasts: Toast[]) => void;
}

const NotificationContext = React.createContext<INotificationsContext>({
  statNotifications: [],
  notifications: [],
  toasts: [],
  setNotifications: (_notifications: INotificationItem[]) => {},
  setStatNotifications: (_statNotifications: IStatNotification[]) => {},
  setToasts: (_toasts: Toast[]) => {},
});

export const NotificationProvider = NotificationContext.Provider;
export const NotificationConsumer = NotificationContext.Consumer;

export default NotificationContext;
