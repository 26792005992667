import React, { useState } from 'react';
import { Checkbox, Stack, TextField } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { OTHER } from '../../../../app/common/constants/SiteConstants';
import IAuditStatusFormProps from './IAuditStatusFormProps';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import GET_AUDIT_STATUSES_QUERY from '../../../../utils/statApi/AuditStatusApi';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import AuditingFirmsDropdown from './AuditingFirmsDropdown';

const AuditStatusDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_AUDIT_STATUSES_QUERY);
  const { auditStatuses } = !loading && data ? data : [];

  return (
    <>
      <QueryBasedDropdown
        value={value as IDropdownReference}
        label={label}
        outputKey={outputKey}
        dropdownOptionsData={auditStatuses}
        errors={errors}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    </>
  );
};

const AuditStatusForm = ({
  auditStatus,
  errors,
  handleClose,
  handleChange,
}: IAuditStatusFormProps): JSX.Element => {
  const {
    auditStatus: auditStatusOption,
    statusDate,
    auditingFirm,
    otherAuditingFirm,
    auditLegallyRequired,
  } = auditStatus;
  const [auditFirmState, setAuditFirmState] = useState(auditingFirm?.fieldName);
  const [otherAuditFirmState, setOtherAuditFirmState] = useState(otherAuditingFirm);
  const [selectedDate, setSelectedDate] = useState(statusDate?.toString());

  const handleSelectDate = (dateString: string): void => {
    handleChange(dateString, 'statusDate');
    setSelectedDate(dateString);
  };
  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <AuditStatusDropdown
        label="Audit Status"
        valueKey="auditStatus"
        errors={errors}
        handleChange={handleChange}
        value={auditStatusOption}
      />
      <UtcDatePicker
        label="Status Date"
        isRequired
        allowTextInput
        textField={{
          name: 'statusDate',
          errorMessage: errors?.statusDate?.message,
        }}
        value={selectedDate}
        onSelectDate={handleSelectDate}
      />
      <AuditingFirmsDropdown
        label="Auditing Firm"
        valueKey="auditingFirm"
        errors={errors}
        value={auditingFirm}
        handleChange={(newValue): void => {
          setAuditFirmState(newValue.fieldName);
          if (newValue.fieldName !== OTHER) {
            setOtherAuditFirmState(null);
            handleChange(null, 'otherAuditingFirm');
          }
          handleChange(newValue, 'auditingFirm');
        }}
      />
      <TextField
        ariaLabel="Other Auditing Firm"
        label="Other Auditing Firm"
        disabled={auditFirmState !== OTHER}
        resizable={false}
        value={otherAuditFirmState}
        onChange={(_, data): void => {
          setOtherAuditFirmState(data);
          handleChange(data, 'otherAuditingFirm');
        }}
      />
      <Checkbox
        ariaLabel="Audit Legally Required"
        label="Audit Legally Required"
        defaultChecked={auditLegallyRequired}
        onChange={(e, newValue: boolean): void => {
          handleChange(newValue, 'auditLegallyRequired');
        }}
      />
    </Stack>
  );
};

export default AuditStatusForm;
