import { getTheme, mergeStyles, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import * as React from 'react';

import InsightCard from './InsightCard';
import ListItem from './ListItem';

import { PROCESS_COUNT_BY_TYPE_QUERY } from '../../utils/statApi/ProcessApi';
import ProcessStatus from '../../utils/types/ProcessStatus';
import OpenProcessesSVG from '../common/svgs/OpenProcessesSvg';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import { milestoneLiStyle } from '../../app/common/styles/CommonStyleObjects';

const OpenProcessesCard = () => {
  const { loading, data, error } = useQuery(PROCESS_COUNT_BY_TYPE_QUERY, {
    variables: { status: ProcessStatus.Active },
    fetchPolicy: 'cache-and-network',
  });

  const openProcessesCardTitle = 'Open Processes';

  const listStyle = mergeStyles({
    paddingLeft: 0,
  });

  const theme = getTheme();

  const getProcessesListItems = () => {
    if (!loading && !error) {
      return (
        <ul className={listStyle}>
          {data?.processCountByType.map(
            (processType: { name: string; id: number; count: number }) => {
              return (
                <ListItem
                  key={processType.id}
                  title={processType.name}
                  subText={
                    <Link to={`/processes?processTypeIds=${processType.id}&processStatuses=ACTIVE`}>
                      Processes
                    </Link>
                  }
                  sideCount={loading || error ? 0 : processType.count}
                  sideColor={theme.palette.blue}
                />
              );
            },
          )}
        </ul>
      );
    }
    if (error) {
      let errorObj = (
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="loading open processes count from the server"
        />
      );
      if (error?.graphQLErrors?.some((e) => e?.extensions?.code === 'AUTH_NOT_AUTHORIZED')) {
        errorObj = (
          <Stack>
            <ul className={listStyle}>
              <li className={milestoneLiStyle}>You have no current open processes</li>
            </ul>
          </Stack>
        );
      }
      return errorObj;
    }
    return <Spinner size={SpinnerSize.medium} />;
  };

  return (
    <InsightCard
      mainText={getProcessesListItems()}
      caption={openProcessesCardTitle}
      svgComponent={<OpenProcessesSVG />}
      footerLink={<Link to="/processes?processStatuses=ACTIVE">All Open Processes</Link>}
    />
  );
};

export default OpenProcessesCard;
