import { ApolloCache, useMutation, useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  DetailsListLayoutMode,
  SelectionMode,
  ShimmeredDetailsList,
  Selection,
  IColumn,
  FontIcon,
  Checkbox,
  CheckboxVisibility,
  TooltipHost,
  IconButton,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  CoherencePanelStyles,
  MessagingColors,
} from '../../../app/common/styles/CommonStyleObjects';
import momentUtc from '../../../utils/DateFormatter';
import { PROCESS_CONFIG_DEACTIVATE_QUERY } from '../../../utils/statApi/ProcessApi';
import PROCESS_CONFIG_LIST_QUERY, {
  DEACTIVATE_PROCESS_CONFIG,
} from '../../../utils/statApi/ProcessConfigApi';
import PROCESS_TYPE_LIST_QUERY from '../../../utils/statApi/ProcessTypesApi';
import StatDateFormats from '../../../utils/types/DateFormats';
import IProcessConfig from '../../../utils/types/IProcessConfig';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IProcessConfigIdCompanyCodeParams } from '../../common/ParamTypes';

import {
  deactivateProcessListColumns,
  IProcessWithDeactivateStatus,
} from './DeactivateProcessConfigPanel.config';

interface IDeactivateProcessConfigPanelProps {
  closePanel: () => void;
}
const DeactivateProcessConfigPanel = ({
  closePanel,
}: IDeactivateProcessConfigPanelProps): JSX.Element => {
  const { processConfigId, companyCode } = useParams<IProcessConfigIdCompanyCodeParams>();
  const [selectedProcess, setSelectedProcess] = useState<IProcessWithDeactivateStatus>();
  const [allowDeleteProcessConfig, setAllowProcessConfig] = useState(false);
  const history = useHistory();
  const [processes, setProcesses] = useState<IProcessWithDeactivateStatus[]>([]);
  const { loading, data, error } = useQuery(PROCESS_CONFIG_DEACTIVATE_QUERY, {
    variables: { processConfigId: parseInt(processConfigId, 10) },
    fetchPolicy: 'cache-and-network',
  });

  const [deactivateProcessConfig, { loading: mutationLoading, error: mutationError }] = useMutation(
    DEACTIVATE_PROCESS_CONFIG,
    {
      onCompleted: () => {
        if (allowDeleteProcessConfig) {
          history.push(`/companies/${companyCode}/processes/configs`);
        } else {
          closePanel();
        }
      },
      refetchQueries: [
        {
          query: PROCESS_TYPE_LIST_QUERY,
          variables: { companyCode },
        },
      ],
    },
  );

  const handleSubmit = (): void => {
    deactivateProcessConfig({
      variables: {
        processConfigDeactivateDto: {
          processConfigId: parseInt(processConfigId, 10),
          processId: selectedProcess.id,
          shouldDelete: false,
        },
      },
    });
  };
  const handleDelete = (): void => {
    deactivateProcessConfig({
      variables: {
        processConfigDeactivateDto: {
          processConfigId: parseInt(processConfigId, 10),
          shouldDelete: true,
        },
      },
      update(
        cache: ApolloCache<[]>,
        { data: deleteData }: { data: { deactivateProcessConfig: IProcessConfig } },
      ) {
        const deactivateProcessConfigItem = deleteData?.deactivateProcessConfig;
        const queryData = cache.readQuery<{ processConfigList: IProcessConfig[] }>({
          query: PROCESS_CONFIG_LIST_QUERY,
          variables: { companyCode },
        });
        const processConfigList = queryData?.processConfigList;
        if (processConfigList && deactivateProcessConfigItem) {
          const listWithDeletedGone = processConfigList.filter(
            (item) => item.id !== deactivateProcessConfigItem.id,
          );
          cache.writeQuery({
            query: PROCESS_CONFIG_LIST_QUERY,
            variables: { companyCode },
            data: {
              processConfigList: {
                listWithDeletedGone,
              },
            },
          });
        }
      },
    });
  };
  const { processListEligibleForDeactivate } = data || {
    processListEligibleForDeactivate: { processes: [], eligibleForDelete: false },
  };
  const getDeactivateStatusForProcesses = (): IProcessWithDeactivateStatus[] => {
    let dbProcesses = processListEligibleForDeactivate.processes;
    let afterSelected = false;
    dbProcesses = processListEligibleForDeactivate.processes.map(
      (process: IProcessWithDeactivateStatus) => {
        const returnProcess = { ...process };
        returnProcess.processEndDate = momentUtc(process.processEndDate, StatDateFormats.DaySimple);
        returnProcess.processStartDate = momentUtc(
          process.processStartDate,
          StatDateFormats.DaySimple,
        );
        if (selectedProcess?.id) {
          returnProcess.willDelete = afterSelected;
          afterSelected = afterSelected || returnProcess.id === selectedProcess.id;
        }
        if (allowDeleteProcessConfig) {
          returnProcess.willDelete = true;
        }
        return returnProcess;
      },
    );
    return dbProcesses;
  };
  useEffect(() => {
    setProcesses(getDeactivateStatusForProcesses());
  }, [data, selectedProcess, allowDeleteProcessConfig]);

  const selection = new Selection({
    onSelectionChanged: () => {
      const selectedItems = selection.getSelection();
      if (selectedItems.length) {
        setSelectedProcess(selectedItems[0] as IProcessWithDeactivateStatus);
      } else {
        setSelectedProcess(null);
      }
    },
  });
  const onSelectDeleteConfig = (ev: React.FormEvent<HTMLElement>, checked: boolean) => {
    setAllowProcessConfig(!allowDeleteProcessConfig);
  };
  const generateDeactivateLabel = (): string => {
    let deactivateLabel = 'Deactivate';
    if (selectedProcess) {
      deactivateLabel = `Deactivate as of ${momentUtc(selectedProcess.processStartDate)}`;
    }
    if (allowDeleteProcessConfig) {
      deactivateLabel = 'Delete All Processes';
    }
    return deactivateLabel;
  };
  const onRenderItemColumn = (
    item: IProcessWithDeactivateStatus,
    index: number,
    column: IColumn,
  ) => {
    const fieldContent = item[column.fieldName as keyof IProcessWithDeactivateStatus];

    switch (column.key) {
      case 'willDelete':
        return (
          <span>
            {fieldContent && (
              <span>
                <FontIcon
                  title="Delete"
                  iconName="Delete"
                  style={{ color: MessagingColors.errorBlockIcon, paddingRight: 10 }}
                />
                Will Delete
              </span>
            )}
            {!fieldContent && (
              <span>
                <FontIcon
                  title="Completed"
                  iconName="CompletedSolid"
                  style={{ color: MessagingColors.successIcon, paddingRight: 10 }}
                />
                Will Keep Open
              </span>
            )}
          </span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Deactivate Process Configuration"
      isOpen
      hasCloseButton
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Deactivate Panel"
      onRenderFooter={(): JSX.Element => (
        <div>
          {!allowDeleteProcessConfig && (
            <ActionButtons
              mutationLoading={mutationLoading}
              closePanel={closePanel}
              handleSubmit={handleSubmit}
              saveTitle={generateDeactivateLabel()}
              saveLabel={generateDeactivateLabel()}
              cancelTitle="Cancel"
              cancelLabel="Cancel"
              disabled={!selectedProcess || mutationLoading}
            />
          )}
          {allowDeleteProcessConfig && (
            <ActionButtons
              mutationLoading={mutationLoading}
              closePanel={closePanel}
              handleSubmit={handleDelete}
              saveTitle="Delete Process Configuration"
              saveLabel="Delete Process Configuration"
              cancelTitle="Cancel"
              cancelLabel="Cancel"
              disabled={mutationLoading}
            />
          )}
        </div>
      )}
    >
      <LoadingErrorMessage error={error} loading={loading} />
      <LoadingErrorMessage error={mutationError} loading={mutationLoading} />
      <form onSubmit={handleSubmit}>
        <p>
          Please select the last process that you wish to complete prior to deactivating or select
          delete process config to delete all processes
        </p>
        <Checkbox
          label="Delete Process Config and All Processes"
          checked={allowDeleteProcessConfig}
          onChange={onSelectDeleteConfig}
          disabled={!processListEligibleForDeactivate.eligibleForDelete}
          onRenderLabel={() => {
            return (
              <div>
                <span>Delete Process Config and All Processes</span>
                {!processListEligibleForDeactivate.eligibleForDelete && (
                  <TooltipHost
                    content="You cannot delete this process configuration because there are complete or partially complete processes"
                    id="tooltip"
                  >
                    <IconButton
                      aria-describedby="tooltip"
                      ariaLabel="Reason for disabling delete process config checkbox"
                      iconProps={{ iconName: 'Info' }}
                    />
                  </TooltipHost>
                )}
              </div>
            );
          }}
        />

        {processes && (
          <ShimmeredDetailsList
            checkButtonAriaLabel="Row checkbox"
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={allowDeleteProcessConfig ? SelectionMode.none : SelectionMode.single}
            ariaLabelForShimmer="Processes are being fetched"
            ariaLabelForGrid="Process details"
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            selection={selection}
            items={processes}
            enableShimmer={loading}
            selectionPreservedOnEmptyClick
            checkboxVisibility={CheckboxVisibility.always}
            compact
            columns={deactivateProcessListColumns}
            onRenderItemColumn={onRenderItemColumn}
          />
        )}
      </form>
    </CoherencePanel>
  );
};

export default DeactivateProcessConfigPanel;
