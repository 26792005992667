import { DownloadDocument } from '../../../utils/statApi/DocumentApi';
import config from '../ConfigLoader';

export function convertBlobToUrl(blob: Blob): string {
  return window.URL.createObjectURL(blob);
}

export function openBlobInNewTab(blob: Blob, fileName?: string): void {
  const file: File = new File([blob], fileName, { type: blob.type });
  const url = window.URL.createObjectURL(file);

  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = fileName;
  anchor.click();

  window.URL.revokeObjectURL(url);
}

const retrieveBlobFromStorage = async (
  fileName: string,
  onError: (errorMessage?: string) => void,
): Promise<Blob> => {
  return DownloadDocument(fileName, onError);
};

export const downloadBlob = async (
  fileName: string,
  onError: (errorMessage?: string) => void,
): Promise<void> => {
  const blob = await DownloadDocument(fileName, onError);

  openBlobInNewTab(blob, fileName);
};

export const openBlob = async (
  fileName: string,
  onError: (errorMessage?: string) => void,
): Promise<void> => {
  const extension = fileName.split('.').pop();
  const nonViewableExtensions = new RegExp(config?.settings?.downloadOnlyExtensionsRegex);

  if (nonViewableExtensions.exec(extension)) {
    downloadBlob(fileName, onError);
  } else {
    const blob = await retrieveBlobFromStorage(fileName, onError);

    const blobUrl = convertBlobToUrl(blob);

    window.open(blobUrl);
  }
};
