import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { ITaxDividends } from '../../../utils/types/ITaxDividends';
import CanAccess from '../../common/canAccess/CanAccess';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { EDIT_TAX_DIVIDENDS_MUTATION } from '../../../utils/statApi/CompanyFiscalYearsApi';
import TaxDividendForm from '../detail/forms/TaxDividendForm';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

export interface IFiscalYearTaxDividendsEditPanelProps {
  taxDividends: ITaxDividends;
  closePanel: () => void;
  companyId: number;
}
const FiscalYearTaxDividendsEditPanel: React.FunctionComponent<
  IFiscalYearTaxDividendsEditPanelProps
> = ({
  closePanel,
  taxDividends,
  companyId,
}: IFiscalYearTaxDividendsEditPanelProps): JSX.Element => {
  const taxDividendForm = useForm();
  const { errors, handleSubmit } = taxDividendForm;
  const [updateTaxDividends, { loading: mutationLoading, error: mutationError }] = useMutation(
    EDIT_TAX_DIVIDENDS_MUTATION,
    {
      onCompleted: closePanel,
    },
  );

  const handleOnSaveClick = (formData: ITaxDividends): void => {
    // GraphQL types don't like the typename property
    const taxDividendsSaveData = {
      id: taxDividends.id,
      taxProposedDividendAmount: parseFloat(formData.taxProposedDividendAmount?.toString()),
      taxCurrencyCode: formData.taxCurrencyCode,
      taxComments: formData.taxComments,
    };

    if (!Object.keys(errors).length) {
      updateTaxDividends({
        variables: {
          dividends: taxDividendsSaveData,
        },
      });
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Tax Dividends"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Tax Dividends Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="TaxDividendInfo:Update">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveLabel="Save Tax Dividends"
            saveTitle="Save Tax Dividends"
            cancelLabel="Cancel Tax Dividends Edit"
            cancelTitle="Cancel Tax Dividends Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the tax dividends information to the server"
        />
        <TaxDividendForm taxDividends={taxDividends} form={taxDividendForm} companyId={companyId} />
      </form>
    </CoherencePanel>
  );
};

export default FiscalYearTaxDividendsEditPanel;
