import {
  Stack,
  ActionButton,
  SpinButton,
  DefaultButton,
  mergeStyles,
  Toggle,
  Separator,
} from '@fluentui/react';
import React from 'react';
import {
  cardWrapperStyles,
  cardContentWrapperStyles,
  separatorStyles,
  MessagingColors,
} from '../../../../app/common/styles/CommonStyleObjects';
import SpanWithLabel from '../../../common/SpanWithLabel';
import { IProcessTemplateMilestone } from '../../../../utils/types/IProcessTemplate';
import { IPanelState } from './ProcessTemplateMilestoneEdit';

interface ProcessTemplateMilestoneEditItemProps {
  milestone: IProcessTemplateMilestone;
  setIsAddMilestonePanelOpen: (item: IPanelState) => void;
  onDurationChange: (duration: number, id: number) => string;
  onDeleteMilestone: (id: number) => void;
  index: number;
  setGoalMilestone: (index: number) => void;
  onEnforceMinDurationChange: (checked: boolean, id: number) => void;
}
const ProcessTemplateMilestoneEditItem = (
  props: ProcessTemplateMilestoneEditItemProps,
): JSX.Element => {
  const {
    milestone,
    setIsAddMilestonePanelOpen,
    onDurationChange,
    onDeleteMilestone,
    index,
    setGoalMilestone,
    onEnforceMinDurationChange,
  } = props;
  const getSignoffMilestoneStyles = (): string => {
    if (milestone.defaultDaysOffset === 0) {
      return mergeStyles({ background: MessagingColors.warning });
    }
    return '';
  };

  const buttonSeparatorStackStyles = { root: { margin: '25px 15px' } };
  const durationWidth = { width: 80 };

  const getDurationInput = (): JSX.Element => {
    if (index !== 1)
      return (
        <SpinButton
          styles={{ root: durationWidth }}
          ariaLabel="Duration"
          value={(milestone.duration as number).toString()}
          step={1}
          incrementButtonAriaLabel="Increase value by 1"
          decrementButtonAriaLabel="Decrease value by 1"
          onValidate={(value: string) => {
            return onDurationChange(parseInt(value, 10), milestone.id);
          }}
          onIncrement={(value: string) => {
            return onDurationChange(parseInt(value, 10) + 1, milestone.id);
          }}
          onDecrement={(value: string) => {
            return onDurationChange(parseInt(value, 10) - 1, milestone.id);
          }}
        />
      );
    return <div style={durationWidth}>--</div>;
  };

  const stackColumnStyles = { root: { flexBasis: 0, minWidth: '100px' } };

  const getEnforceMinDurationInput = (): JSX.Element => {
    if (index !== 1)
      return (
        <Toggle
          styles={{ root: { marginBottom: '0' } }}
          onText="On"
          offText="Off"
          ariaLabel="Enforce duration as minimum"
          checked={milestone.enforceDefaultAsMinDuration}
          onChange={(
            e: React.FormEvent<HTMLInputElement | HTMLElement>,
            checked: boolean,
          ): void => {
            onEnforceMinDurationChange(checked, milestone.id);
          }}
        />
      );
    return <div>--</div>;
  };

  return (
    <>
      <div className={`ms-depth-4 ${cardWrapperStyles} ${getSignoffMilestoneStyles()}`}>
        <div className={cardContentWrapperStyles}>
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 30 }} wrap>
            <Stack.Item styles={{ root: { flex: '0 0 20%' } }}>
              <SpanWithLabel labelText="Milestone Name" value={milestone.name} />
            </Stack.Item>
            {milestone.milestoneCategoryName && (
              <Stack.Item grow={2} styles={stackColumnStyles}>
                <SpanWithLabel labelText="Category" value={milestone.milestoneCategoryName} />
              </Stack.Item>
            )}
            <Stack.Item grow={1} styles={stackColumnStyles}>
              <SpanWithLabel labelText="Duration">{getDurationInput()}</SpanWithLabel>
            </Stack.Item>
            <Stack.Item grow={2} styles={stackColumnStyles}>
              <SpanWithLabel labelText="Enforce duration as minimum">
                {getEnforceMinDurationInput()}
              </SpanWithLabel>
            </Stack.Item>
            <Stack.Item grow={2} styles={stackColumnStyles}>
              <SpanWithLabel
                labelText="Days from Filing Date"
                value={Math.abs(milestone.defaultDaysOffset)}
              />
            </Stack.Item>
            <Stack.Item grow={4} styles={stackColumnStyles}>
              <Stack horizontal verticalAlign="center">
                <ActionButton
                  iconProps={{ iconName: 'Delete' }}
                  title="Delete Milestone"
                  ariaLabel="Delete Milestone"
                  disabled={milestone.defaultDaysOffset === 0}
                  onClick={() => onDeleteMilestone(milestone.id)}
                >
                  Delete Milestone
                </ActionButton>
                <div>|</div>
                <ActionButton
                  iconProps={{ iconName: 'Trophy' }}
                  title="Set Goal Milestone"
                  ariaLabel="Set Goal Milestone"
                  disabled={milestone.defaultDaysOffset === 0}
                  onClick={() => {
                    setGoalMilestone(index);
                  }}
                >
                  Set As Goal Milestone
                </ActionButton>
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
      </div>

      <Stack
        styles={buttonSeparatorStackStyles}
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
      >
        <DefaultButton
          iconProps={{ iconName: 'Add' }}
          title="Add Milestone to Template"
          ariaLabel="Add Milestone to Template"
          onClick={() => setIsAddMilestonePanelOpen({ panelIsOpen: true, index })}
        >
          Add Milestone
        </DefaultButton>
        <Stack.Item grow>
          <Separator styles={separatorStyles} />
        </Stack.Item>
      </Stack>
    </>
  );
};
export default ProcessTemplateMilestoneEditItem;
