import React from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ActionButtons from '../../common/ActionButtons';
import CanAccess from '../../common/canAccess/CanAccess';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import IAnnualFilingRequirements from '../../../utils/types/IAnnualFilingRequirements';
import AccountingStandardsForm from '../detail/forms/AccountingStandardsForm';
import { UPDATE_ACCOUNTING_STANDARDS_MUTATION } from '../../../utils/statApi/AnnualFilingRequirementsApi';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

export interface IAccountingStandardsEditPanelProps {
  annualFilingRequirements: IAnnualFilingRequirements;
  closePanel: () => void;
}
const AccountingStandardsEditPanel: React.FunctionComponent<IAccountingStandardsEditPanelProps> = ({
  closePanel,
  annualFilingRequirements,
}: IAccountingStandardsEditPanelProps): JSX.Element => {
  const accountingStandardsForm = useForm();

  const { errors, handleSubmit } = accountingStandardsForm;

  const { companyCode } = useParams<ICompanyCodeParams>();

  const [updateAnnualFilingRequirements, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_ACCOUNTING_STANDARDS_MUTATION, {
      onCompleted: closePanel,
    });

  const handleOnSaveClick = (formData: IAnnualFilingRequirements): void => {
    const accountingStandardsUsed = formData?.accountingStandardsUsed?.id
      ? {
          id: formData.accountingStandardsUsed.id,
          fieldName: formData.accountingStandardsUsed.fieldName,
        }
      : null;

    const annualFilingRequirementsSaveData = {
      id: annualFilingRequirements.id,
      accountingStandardsUsed,
      accountingStandardsComments: formData.accountingStandardsComments,
      ifrsType: formData.ifrsType,
      ifrsSpecialElections: formData.ifrsSpecialElections,
    };

    if (!Object.keys(errors).length) {
      updateAnnualFilingRequirements({
        variables: {
          companyCode,
          data: annualFilingRequirementsSaveData,
        },
      });
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Accounting Standards"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Accounting Standards Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="AnnualFilingRequirements:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveLabel="Save Accounting Standards"
            saveTitle="Save Accounting Standards"
            cancelLabel="Cancel Accounting Standards Edit"
            cancelTitle="Cancel Accounting Standards Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="saving the accounting standards information to the server"
        />
        <AccountingStandardsForm
          annualFilingRequirements={annualFilingRequirements}
          form={accountingStandardsForm}
        />
      </form>
    </CoherencePanel>
  );
};
export default AccountingStandardsEditPanel;
