import { gql } from '@apollo/client';

const FUNCTIONAL_CURRENCY_FRAGMENT = gql`
  fragment FunctionalCurrency on FunctionalCurrencyDto {
    id
    cashFlowCurrency
    cashFlowCurrencyNotes
    cashFlowAffectsParent
    cashFlowAffectsParentNotes
  }
`;

const COMPANY_CREATION_REQUEST_LIST_FRAGMENT = gql`
  fragment CompanyCreationRequest on CompanyCreationRequestListItemDto {
    id
    companyName
    requestedDate
    requestor
    requestorId
    status
    acquisition
    acquisitionId
  }
`;

const COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT = gql`
  fragment CompanyCreationDetailRequest on CompanyCreationRequestDetailDto {
    id
    companyName
    companyCode
    shadowCompanyCode
    requestedDate
    requestor {
      id
      displayName
      graphId: graphGUID
    }
    davaDealLead {
      id
      graphGUID
      displayName
      firstName
      lastName
      alias
      emailAddress
    }
    entityType {
      id
      fieldName
    }
    financialType {
      id
      fieldName: name
    }
    businessNatureOfEntity
    isConfidential
    isAcquisition
    acquisition {
      key: id
      text: fieldName
    }
    dateOfAcquisition
    status
    statusId
    parentCompanyCode
  }
`;

export const COMPANY_DOCUMENT_FRAGMENT = gql`
  fragment CompanyDocument on CompanyDocumentDto {
    id
    uploader {
      id
      displayName
      firstName
      lastName
    }
    uploadDate
    title
    url
    isDeleted
  }
`;

const COMPANY_CONTACT_FRAGMENT = gql`
  fragment CompanyContact on CompanyCreationContactDto {
    id
    graphId: graphGUID
    name: displayName
    firstName
    lastName
    alias
    email: emailAddress
    contactType
    text: displayName
  }
`;

const COMPANY_INFO_FRAGMENT = gql`
  fragment CompanyInfo on CompanyCreationCompanyInfoDto {
    companyCreationRequestId
    statusId
    functionalCurrency {
      id
      functionalCurrency
      statutoryCurrency
      functionalCurrencyDocument {
        id
        uploader {
          id
          displayName
          firstName
          lastName
        }
        uploadDate
        title
        url
        isDeleted
      }
    }
    legalDocumentInfo {
      id
      fullLegalName
      sapLegalName
      abbreviatedSapName
      statutoryYearEndMonth
      statutoryYearEndDay
      incorporatedDate
      legallyRegisteredAddress {
        streetNumber
        streetNumber2
        city
        postalZipCode
        country {
          id
          shortName
        }
        stateProvinceRegion {
          id
          name
        }
      }
      physicalAddress {
        streetNumber
        streetNumber2
        city
        postalZipCode
        country {
          id
          shortName
        }
        stateProvinceRegion {
          id
          name
        }
      }
    }
    sapSetupInfo {
      id
      region
      taxJurisdictionCode
      msReportingSalesYear
      msReportingSalesLocation {
        id
        name
      }
    }
    inHouseCashCenterSetup {
      id
      inHouseCashCenterTier {
        id
        name
      }
      isIncludedInInHouseCashCenter
      inHouseCashCenterAccountCurrency
      inHouseCashCenterPostingMode
    }
    shadowCompanyInfo {
      id
      name
      isShadowCompanyNeeded
    }
    statutoryInformation {
      id
      oversightGroup {
        id
        fieldName
      }
      isMcaps
      isOneFinance
      auditingFirm {
        id
        fieldName
      }
      otherAuditingFirm
      isFsAuditLegallyRequired
      einTaxNumber
      controllerMustBeFte
    }
    statutoryContacts {
      statutoryController {
        ...CompanyContact
      }
      regionalControllers {
        ...CompanyContact
      }
      fsTaxRepContacts {
        ...CompanyContact
      }
      treasuryRepContacts {
        ...CompanyContact
      }
      bpoContacts {
        ...CompanyContact
      }
      alternativeContacts {
        ...CompanyContact
      }
    }
    subledgerInfo {
      id
      isDomesticEntity
      willHaveIntercompanyActivity
      willRequireMscPremierServices
      willHaveHeadcount
      personalSubareaCities
      weeklyWorkingHours
      willNeedFaModule
      willNeedApMmModule
      willTransmitToMsSales
      bsReconOwner {
        ...CompanyContact
      }
      bsReconReviewer {
        ...CompanyContact
      }
      bsReconController {
        ...CompanyContact
      }
    }
  }
  ${COMPANY_CONTACT_FRAGMENT}
`;

const GET_COMPANY_CREATION_REQUESTS = gql`
  query getCompanyCreationRequests(
    $statusIds: [Int!]
    $requestorIds: [Int!]
    $acquisitionIds: [Int!]
  ) {
    companyCreationRequests(
      statusIds: $statusIds
      requestorIds: $requestorIds
      acquisitionIds: $acquisitionIds
    ) {
      ...CompanyCreationRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_LIST_FRAGMENT}
`;

export const GET_COMPANY_CREATION_REQUEST_DETAILS = gql`
  query CompanyCreationRequestDetails($companyCreationId: Int!) {
    companyCreationRequestDetails(companyCreationId: $companyCreationId) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const NEW_COMPANY_CREATION_REQUEST = gql`
  mutation createCompanyCreationRequest(
    $companyCreationRequestInput: CompanyCreationRequestInputDtoInput!
  ) {
    createCompanyCreationRequest(companyCreationRequestInput: $companyCreationRequestInput) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const EDIT_COMPANY_CREATION_REQUEST = gql`
  mutation updateCompanyCreationRequest(
    $companyCreationRequestInput: CompanyCreationRequestInputDtoInput!
  ) {
    updateCompanyCreationRequest(companyCreationRequestInput: $companyCreationRequestInput) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const CANCEL_COMPANY_CREATION_REQUEST = gql`
  mutation cancelCompanyCreationRequest($requestId: Int!) {
    cancelCompanyCreationRequest(requestId: $requestId) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const GET_COMPANY_CREATION_DOCUMENTS = gql`
  query companyCreationDocuments($parentId: Int!) {
    companyCreationDocuments(companyCreationId: $parentId) {
      id
      functionalCurrencyDocument {
        ...CompanyDocument
      }
      incorporationDocuments {
        ...CompanyDocument
      }
      otherDocuments {
        ...CompanyDocument
      }
    }
  }
  ${COMPANY_DOCUMENT_FRAGMENT}
`;

export const UPDATE_COMPANY_CREATION_DOCUMENTS = gql`
  mutation updateCompanyCreationDocuments($input: DocumentsInputDtoInput!) {
    updateCompanyCreationDocuments(input: $input) {
      id
      functionalCurrencyDocument {
        ...CompanyDocument
      }
      incorporationDocuments {
        ...CompanyDocument
      }
      otherDocuments {
        ...CompanyDocument
      }
    }
  }
  ${COMPANY_DOCUMENT_FRAGMENT}
`;

export const GET_COMPANY_CREATION_COMPANY_INFO = gql`
  query companyCreationCompanyInfo($companyCreationId: Int!) {
    companyCreationCompanyInfo(companyCreationId: $companyCreationId) {
      ...CompanyInfo
    }
  }
  ${COMPANY_INFO_FRAGMENT}
`;

export const GET_PARENT_COMPANY_INFO = gql`
  query parentCompanyInfo($companyCreationId: Int!) {
    parentCompanyInfo(companyCreationId: $companyCreationId) {
      ...CompanyInfo
    }
  }
  ${COMPANY_INFO_FRAGMENT}
`;

export const GET_STATE_PROVINCE_REGION_FOR_COUNTRY = gql`
  query regionsByCountry($countryCode: String!) {
    regionsByCountry(countryCode: $countryCode) {
      key: id
      countryCode
      text: name
    }
  }
`;

export const GET_FUNCTIONAL_CURRENCY = gql`
  query functionalCurrency($companyCreationId: Int!) {
    functionalCurrency(companyCreationId: $companyCreationId) {
      ...FunctionalCurrency
    }
  }
  ${FUNCTIONAL_CURRENCY_FRAGMENT}
`;

export const UPDATE_FUNCTIONAL_CURRENCY_CASHFLOW = gql`
  mutation updateFunctionalCurrencyCashFlow($input: FunctionalCurrencyCashFlowInputDtoInput!) {
    updateFunctionalCurrencyCashFlow(input: $input) {
      ...FunctionalCurrency
    }
  }
  ${FUNCTIONAL_CURRENCY_FRAGMENT}
`;

export const SEND_COMPANY_CODE_REQUEST_EMAIL = gql`
  mutation sendCompanyCodeRequestEmail($input: Int!) {
    sendCompanyCodeRequestEmail(companyRequestId: $input) {
      companyCreationRequestId
    }
  }
`;

export const UPDATE_FUNCTIONAL_CURRENCY = gql`
  mutation updateFunctionalCurrency($input: CompanyCreationCurrencyDtoInput!) {
    updateFunctionalCurrency(input: $input) {
      companyCreationRequestId
      functionalCurrency {
        id
        functionalCurrency
        statutoryCurrency
        functionalCurrencyDocument {
          id
          uploader {
            id
            displayName
            firstName
            lastName
          }
          uploadDate
          title
          url
          isDeleted
        }
      }
    }
  }
`;

export const UPDATE_LEGAL_DOCUMENT_INFO = gql`
  mutation updateLegalDocumentInfo($input: LegalDocumentInfoDtoInput!) {
    updateLegalDocumentInfo(input: $input) {
      companyCreationRequestId
      legalDocumentInfo {
        id
        fullLegalName
        sapLegalName
        abbreviatedSapName
        statutoryYearEndMonth
        statutoryYearEndDay
        incorporatedDate
        legallyRegisteredAddress {
          streetNumber
          streetNumber2
          city
          postalZipCode
        }
        physicalAddress {
          streetNumber
          streetNumber2
          city
          postalZipCode
        }
      }
    }
  }
`;

export const UPDATE_SAP_SETUP_INFO = gql`
  mutation updateSapSetupInfo($input: SapSetupInfoDtoInput!) {
    updateSapSetupInfo(input: $input) {
      companyCreationRequestId
      sapSetupInfo {
        id
        region
        taxJurisdictionCode
        msReportingSalesYear
        msReportingSalesLocation {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_IN_HOUSE_CASH_CENTER_SETUP = gql`
  mutation updateInHouseCashCenterSetup($input: InHouseCashCenterSetupDtoInput!) {
    updateInHouseCashCenterSetup(input: $input) {
      companyCreationRequestId
      inHouseCashCenterSetup {
        id
        inHouseCashCenterTier {
          id
          name
        }
        isIncludedInInHouseCashCenter
        inHouseCashCenterAccountCurrency
        inHouseCashCenterPostingMode
      }
    }
  }
`;

export const GET_MS_REPORTING_SALES_LOCATIONS = gql`
  query msReportingSalesLocations {
    msReportingSalesLocations {
      key: id
      text: name
    }
  }
`;

export const GET_IN_HOUSE_CASH_CENTER_TIERS = gql`
  {
    inHouseCashCenterTiers {
      id
      fieldName: name
    }
  }
`;

export const UPDATE_STATUTORY_INFORMATION = gql`
  mutation updateStatutoryInformation($input: StatutoryInformationDtoInput!) {
    updateStatutoryInformation(input: $input) {
      companyCreationRequestId
      statutoryInformation {
        id
        oversightGroup {
          id
          fieldName
        }
        isMcaps
        isOneFinance
        auditingFirm {
          id
          fieldName
        }
        otherAuditingFirm
        isFsAuditLegallyRequired
        einTaxNumber
        controllerMustBeFte
      }
    }
  }
`;

export const UPDATE_SHADOW_COMPANY = gql`
  mutation updateShadowCompany($input: ShadowCompanyInfoDtoInput!) {
    updateShadowCompanyInfo(input: $input) {
      companyCreationRequestId
      shadowCompanyInfo {
        id
        name
        isShadowCompanyNeeded
      }
    }
  }
`;

export const GET_OVERSIGHT_GROUPS = gql`
  {
    oversightGroups {
      id
      fieldName
    }
  }
`;

export const ADD_COMPANY_CREATION_REQUEST_COMPANY_CODE = gql`
  mutation addCompanyRequestCompanyCode($companyRequestId: Int!, $companyCode: Int!) {
    addCompanyRequestCompanyCode(companyRequestId: $companyRequestId, companyCode: $companyCode) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const UPDATE_COMPANY_CREATION_REQUEST_COMPANY_CODE = gql`
  mutation updateCompanyRequestCompanyCode($companyRequestId: Int!, $companyCode: Int!) {
    updateCompanyRequestCompanyCode(
      companyRequestId: $companyRequestId
      companyCode: $companyCode
    ) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const GET_COMPANY_CREATION_STATUSES = gql`
  {
    companyCreationStatuses {
      key: id
      text: fieldName
    }
  }
`;

export const GET_COMPANY_CREATION_PROGRESS = gql`
  query companyCreationProgress($companyCreationId: Int!) {
    companyCreationProgress(companyCreationId: $companyCreationId) {
      id
      overallPercentComplete
      sapPercentComplete
      subledgerPercentComplete
      statPercentComplete
      lastCompanyCodeRequest {
        id
        sentTime
        sender {
          id
          displayName
        }
        type
      }
      lastSystemSetup {
        id
        sentTime
        sender {
          id
          displayName
        }
        type
      }
      dateActivated {
        id
        sentTime
        sender {
          id
          displayName
        }
        type
      }
    }
  }
`;

export const UPDATE_COMPANY_CREATION_CONTACTS = gql`
  mutation updateCompanyCreationContacts(
    $statutoryContactsInputDto: StatutoryContactsInputDtoInput
  ) {
    updateCompanyCreationContacts(input: $statutoryContactsInputDto) {
      companyCreationRequestId
      contacts {
        id
        graphGUID
        displayName
        firstName
        lastName
        alias
        emailAddress
        contactType
        personnelNumber
      }
    }
  }
`;

export const UPDATE_SUBLEDGER_INFO = gql`
  mutation updateSubledgerInfo($input: SubledgerInfoDtoInput!) {
    updateSubledgerInfo(input: $input) {
      companyCreationRequestId
      subledgerInfo {
        id
        isDomesticEntity
        willHaveIntercompanyActivity
        willRequireMscPremierServices
        willHaveHeadcount
        personalSubareaCities
        weeklyWorkingHours
        willNeedFaModule
        willNeedApMmModule
        willTransmitToMsSales
        bsReconOwner {
          id
          graphGUID
          displayName
          firstName
          lastName
          alias
          emailAddress
          contactType
          personnelNumber
        }
        bsReconReviewer {
          id
          graphGUID
          displayName
          firstName
          lastName
          alias
          emailAddress
          contactType
          personnelNumber
        }
        bsReconController {
          id
          graphGUID
          displayName
          firstName
          lastName
          alias
          emailAddress
          contactType
          personnelNumber
        }
      }
    }
  }
`;

export const ACTIVATE_COMPANY = gql`
  mutation activateCompany($companyRequestId: Int!) {
    activateCompany(companyRequestId: $companyRequestId) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const COMPLETE_SUBLEDGER_INFO = gql`
  mutation completeSubledgerInfo($input: Int!) {
    completeSubledgerInfo(companyRequestId: $input) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const UPDATE_SHADOW_COMPANY_CODE = gql`
  mutation updateShadowCompanyCode($companyRequestId: Int!, $shadowCompanyCode: Int!) {
    updateShadowCompanyCode(
      companyRequestId: $companyRequestId
      shadowCompanyCode: $shadowCompanyCode
    ) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const CREATE_NEW_SHADOW_COMPANY = gql`
  mutation createNewShadowCompany($shadowCompanyRequestInput: ShadowCompanyRequestInputDtoInput!) {
    createNewShadowCompany(shadowCompanyRequestInput: $shadowCompanyRequestInput) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export const COMPLETE_SHADOW_COMPANY_REQUEST = gql`
  mutation completeShadowCompanyRequest($companyRequestId: Int!, $shadowCompanyCode: Int!) {
    completeShadowCompanyRequest(
      companyRequestId: $companyRequestId
      shadowCompanyCode: $shadowCompanyCode
    ) {
      ...CompanyCreationDetailRequest
    }
  }
  ${COMPANY_CREATION_REQUEST_DETAIL_FRAGMENT}
`;

export default GET_COMPANY_CREATION_REQUESTS;
