import React from 'react';
import { Controller } from 'react-hook-form';
import { ChoiceGroup, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import IFinancialStatementFilingInfoFormProps from './IFinancialStatementFilingInfoFormProps';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import GET_FINANCIAL_STATEMENT_TYPE from '../../../../utils/statApi/CompanyFinancialStatementTypesApi';
import { yesNoOptions } from '../../../../utils/types/CommonDropdownOptions';

const FinancialStatementFilingInfoForm = ({
  annualFilingRequirements,
  form,
}: IFinancialStatementFilingInfoFormProps): JSX.Element => {
  const { control, errors, setValue } = form;

  const FinancialStatementTypeDropdown = (props: IFieldsetProps): JSX.Element => {
    const { value, label, required, errors: accErrors, handleChange, valueKey, outputKey } = props;
    const { loading, data } = useQuery(GET_FINANCIAL_STATEMENT_TYPE);
    const { financialStatementTypes } = !loading && data ? data : [];

    return (
      <QueryBasedDropdown
        value={value as IDropdownReference}
        outputKey={outputKey}
        label={label}
        required={required}
        errors={accErrors}
        dropdownOptionsData={financialStatementTypes}
        handleChange={handleChange}
        valueKey={valueKey}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Controller
        render={(props) => (
          <FinancialStatementTypeDropdown
            label="Financial Statement Type"
            valueKey="financialStatementType"
            value={props.value}
            handleChange={(newValue: IDropdownOption, valueKey: string): void => {
              setValue('financialStatementType', newValue);
            }}
            errors={errors}
          />
        )}
        name="financialStatementType"
        control={control}
        defaultValue={annualFilingRequirements.financialStatementType}
        value={annualFilingRequirements.financialStatementType}
      />
      <Controller
        as={TextField}
        label="FS Signer"
        id="fsSigner"
        name="fsSigner"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.fsSigner}
        errorMessage={errors?.fsSigner?.message}
        rules={{
          maxLength: {
            value: 250,
            message: 'Input is greater than the maximum character limit of 250.',
          },
        }}
      />
      <Controller
        as={TextField}
        label="Other Financial Statement Info"
        id="otherFinancialStatementInfo"
        name="otherFinancialStatementInfo"
        control={control}
        multiline
        resizable={false}
        defaultValue={annualFilingRequirements.otherFinancialStatementInfo}
        errorMessage={errors?.otherFinancialStatementInfo?.message}
        rules={{
          maxLength: {
            value: 1000,
            message: 'Input is greater than the maximum character limit of 1000.',
          },
        }}
      />
      <Controller
        as={TextField}
        label="Tax ID"
        id="taxId"
        name="taxId"
        control={control}
        defaultValue={annualFilingRequirements?.taxId || ''}
        errorMessage={errors?.taxId?.message}
        required
        rules={{
          maxLength: {
            value: 75,
            message: 'Input is greater than the maximum character limit of 75',
          },
          required: {
            value: true,
            message: 'Please enter a Tax ID',
          },
        }}
      />
      <Controller
        name="physicalSignatureRequired"
        control={control}
        defaultValue={annualFilingRequirements.physicalSignatureRequired}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <ChoiceGroup
            id="physicalSignatureRequired"
            label="Physical Signature Required"
            name={name}
            options={yesNoOptions}
            onBlur={onBlur}
            defaultSelectedKey={value?.toString()}
            onChange={(e, newValue): void => {
              onChange(newValue.key === 'true');
            }}
          />
        )}
      />
    </Stack>
  );
};

export default FinancialStatementFilingInfoForm;
