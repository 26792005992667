import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_INCORPORATION_HIERARCHY_INFO } from '../../../utils/statApi/CompaniesApi';
import IIncorporationHierarchyInfo from '../../../utils/types/IIncorporationHierarchyInfo';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import IncorporationHierarchyForm from './forms/IncorporationHierarchyForm';

interface IIncorporationHierarchyInfoProps {
  closePanel: () => void;
  data: IIncorporationHierarchyInfo;
}

const CompanyIncorporationHierarchyInfoPanel = ({
  closePanel,
  data,
}: IIncorporationHierarchyInfoProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const form = useForm<IIncorporationHierarchyInfo>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateIncorpHierarchyInfo, { loading, error }] = useMutation(
    UPDATE_INCORPORATION_HIERARCHY_INFO,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: IIncorporationHierarchyInfo): void => {
    updateIncorpHierarchyInfo({
      variables: {
        companyCode,
        input: {
          id: data.id,
          incorporatedDate: formData.incorporatedDate,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Incorporation/Hierarchy Information"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Incorporation/Hierarchy Information Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Incorporation/Hierarchy Information"
          saveTitle="Save Incorporation/Hierarchy Information"
          cancelLabel="Cancel Incorporation/Hierarchy Information"
          cancelTitle="Cancel Incorporation/Hierarchy Information"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <IncorporationHierarchyForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyIncorporationHierarchyInfoPanel;
