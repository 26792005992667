import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';

export const yesNoOptions: IChoiceGroupOption[] = [
  { key: 'true', text: 'Yes' },
  { key: 'false', text: 'No' },
];

export const signatureInkColorOptions: IDropdownOption[] = [
  { key: 'BLUE', text: 'Blue' },
  { key: 'BLACK', text: 'Black' },
  { key: 'OTHER', text: 'Other' },
];

export const paperSizeOptions: IDropdownOption[] = [
  { key: 'NOTSPECIFIED', text: 'Not Specified' },
  { key: 'SIZE8X11', text: '8 1/2 x 11' },
  { key: 'A4', text: 'A4' },
  { key: 'OTHER', text: 'Other' },
];

export const additionalProcessingTimeOptions: IDropdownOption[] = [
  { key: 'APOSTILLE', text: 'Apostille' },
  { key: 'LEGALIZATION', text: 'Legalization' },
  { key: 'LOCALSIGNER', text: 'Local Signer' },
];
