import React, { useState, CSSProperties } from 'react';
import {
  Stack,
  Shimmer,
  DefaultButton,
  ActionButton,
  FontIcon,
  mergeStyles,
  TeachingBubble,
} from '@fluentui/react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Link, useParams, Redirect } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import BackButton from '../../common/BackButton';
import {
  EDIT_DIVIDENDS_FROM_PROCESS_MUTATION,
  GET_PROCESS_QUERY,
} from '../../../utils/statApi/ProcessApi';
import {
  IMilestone,
  IFinancialStatementProcess,
  ProcessType,
  IProcessCorpReasonForLateFiling,
  IRecentProcess,
} from './Interfaces';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ProcessDetailEditPanel from './panels/ProcessDetailEditPanel';
import MilestoneDueDateEditPanel from './panels/MilestoneDueDateEditPanel';
import MilestoneListItem from './milestoneList/MilestoneListItem';
import CorpReasonForLateFilingEditPanel from './panels/CorpReasonForLateFilingEditPanel';
import ReasonForViolationEditPanel from './panels/ReasonForViolationEditPanel';
import ReasonForFilingViolationHistoryPanel from './panels/ReasonForFilingViolationHistoryPanel';
import ProcessExtensionRequestPanel from './panels/ProcessExtensionRequestPanel';
import ProcessDetailSideColumn from './ProcessDetailSideColumn';
import { ProcessProvider } from './ProcessContext';
import { IProcessDetailParams } from '../../common/ParamTypes';
import CanAccess from '../../common/canAccess/CanAccess';
import ProcessStatus from '../../../utils/types/ProcessStatus';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/UseLocalStorage';
import FiscalYearDividendsEditPanel from '../fiscalYears/FiscalYearDividendsEditPanel';
import FiscalYearAgmStatusEditPanel from '../fiscalYears/FiscalYearAgmStatusEditPanel';
import { EDIT_AGM_STATUS_MUTATION } from '../../../utils/statApi/CompanyFiscalYearsApi';
import IAgmFiscalYears from '../../../utils/types/IAgmFiscalYears';
import { CanEditDueDate } from './panels/milestoneDueDateUtils';
import { UPDATE_ANNUAL_GENERAL_MEETING } from '../../../utils/statApi/AnnualFilingRequirementsApi';
import ReportingExclusionEditPanel from './panels/ReportingExclusionEditPanel';

export enum CollapseAllStatus {
  DEFAULT,
  EXPAND,
  COLLAPSE,
}
const ProcessDetail = (): JSX.Element => {
  const { processId, companyCode, processTypeUri } = useParams<IProcessDetailParams>();
  const [isProcessDetailsExpanded, setIsProcessDetailsExpanded] = useState(false);
  const [isProcessEditPanelOpen, setIsProcessEditPanelOpen] = useState(false);
  const [isCorpReasonEditPanelOpen, setIsCorpReasonEditPanelOpen] = useState(false);
  const [isReportingExclusionPanelOpen, setIsReportingExclusionPanelOpen] = useState(false);
  const [isDueDatePanelOpen, setIsDueDatePanelOpen] = useState(false);
  const [isEditFilingViolationPanelOpen, setIsEditFilingViolationPanelOpen] = useState(false);
  const [isHistoryFilingViolationPanelOpen, setIsHistoryFilingViolationPanelOpen] = useState(false);
  const [isProcessExtensionPanelOpen, setIsProcessExtensionPanelOpen] = useState(false);
  const [isDividendsPanelOpen, setIsDividendsPanelOpen] = useState(false);
  const [isAGMPanelOpen, setIsAGMPanelOpen] = useState(false);
  const [showTeachingBubble, setShowTeachingBubble] = useState<boolean>(false);
  const [focusedMilestoneId, setFocusedMilestoneId] = useState(1);
  const [recentProcesses, setRecentProcesses] = useLocalStorage<IRecentProcess[]>(
    LocalStorageKeys.recentProcesses,
    [],
  );
  const [isExpanded, setIsExpanded] = useState<CollapseAllStatus>(CollapseAllStatus.DEFAULT);
  const checkIsCollapsed = () => {
    return isExpanded === (CollapseAllStatus.DEFAULT || CollapseAllStatus.EXPAND);
  };
  const toggleCollapseAll = () => {
    const collapseAll = checkIsCollapsed() ? CollapseAllStatus.COLLAPSE : CollapseAllStatus.EXPAND;

    setIsExpanded(collapseAll);
  };
  const { data, loading, error, refetch } = useQuery(GET_PROCESS_QUERY, {
    variables: { processId: parseInt(processId, 10) },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (data?.processDetail) {
        setRecentProcesses([
          { ...data?.processDetail, companyCode },
          ...recentProcesses.filter((pro) => pro.id !== data.processDetail.id).slice(0, 7),
        ]);
      }
    },
  });

  if (!loading && !data?.processDetail) {
    return <Redirect to={`/companies/${companyCode}/processes/${processTypeUri}`} />;
  }

  const processDetail: IFinancialStatementProcess = data?.processDetail || {};

  const companyFiscalYearDetail: IAgmFiscalYears = processDetail.agmFiscalYear;

  const processCorpReasonForLateFiling: IProcessCorpReasonForLateFiling = processDetail.id
    ? {
        corpReasonForLateFiling: processDetail.corpReasonForLateFiling,
        corpReasonForLateFilingComment: processDetail.corpReasonForLateFilingComment,
        processId: processDetail.id,
      }
    : null;
  const milestones: IMilestone[] = processDetail?.milestones || [];
  const processTemplateVersionId =
    milestones?.length > 0 ? milestones[0].processTemplateVersionId : null;
  const milestoneLength = milestones?.length;
  const isFinancialProcess: boolean =
    processDetail.processType === ProcessType.AnnualFinancialStatements;

  const toggleProcessEditPanel = (): void => {
    setIsProcessEditPanelOpen(!isProcessEditPanelOpen);
  };

  const toggleCorpReasonForLateFilingEditPanel = (): void => {
    setIsCorpReasonEditPanelOpen(!isCorpReasonEditPanelOpen);
  };

  const toggleDueDateEditPanel = (): void => {
    setIsDueDatePanelOpen(!isDueDatePanelOpen);
  };

  const toggleMilestoneDueDateEditPanel = (milestoneId: number): void => {
    setFocusedMilestoneId(milestoneId);
    toggleDueDateEditPanel();
  };

  const toggleMilestoneDueDateEditPanelFromQuickLink = (): void => {
    setFocusedMilestoneId(0);
    toggleDueDateEditPanel();
  };

  const toggleFilingViolationPanel = (): void => {
    setIsEditFilingViolationPanelOpen(!isEditFilingViolationPanelOpen);
  };

  const toggleHistoryFilingViolationPanel = (): void => {
    setIsHistoryFilingViolationPanelOpen(!isHistoryFilingViolationPanelOpen);
  };

  const toggleProcessExtensionRequestPanel = (): void => {
    setIsProcessExtensionPanelOpen(!isProcessExtensionPanelOpen);
  };

  const toggleDividendsPanel = (): void => {
    setIsDividendsPanelOpen(!isDividendsPanelOpen);
  };

  const toggleAGMEditPanel = (): void => {
    setIsAGMPanelOpen(!isAGMPanelOpen);
  };

  const toggleReportingExclusion = (): void => {
    setIsReportingExclusionPanelOpen(!isReportingExclusionPanelOpen);
  };

  const getBodyHeaderTitle = (): JSX.Element => {
    return (
      <BackButton ariaLabel="Back to processes list">
        <h2>{processDetail.name}</h2>
      </BackButton>
    );
  };
  const topBarStyles = {
    padding: ' 0 30px',
    marginBottom: 30,
  } as CSSProperties;

  const rightBarStyles = {
    padding: '10px 20px',
    position: 'sticky',
    top: 30,
    minWidth: 340,
    width: 340,
  } as CSSProperties;
  const milestoneListWrapperStyles = {
    root: { width: '100%' },
  };
  const settingsIconStyle = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
  });

  const renderMilestonesList = (): JSX.Element => {
    return (
      <>
        {isProcessDetailsExpanded && (
          <Stack className="ms-hiddenXlUp">
            <div style={topBarStyles}>
              <ProcessDetailSideColumn
                processDetail={processDetail}
                toggleProcessEditPanel={toggleProcessEditPanel}
                toggleCorpReasonForLateFilingEditPanel={toggleCorpReasonForLateFilingEditPanel}
                toggleFilingViolationPanel={toggleFilingViolationPanel}
                toggleHistoryFilingViolationPanel={toggleHistoryFilingViolationPanel}
                toggleDividendsPanel={toggleDividendsPanel}
                toggleAGMEditPanel={toggleAGMEditPanel}
                isFinancialProcess={isFinancialProcess}
                toggleReportingExclusion={toggleReportingExclusion}
              />
            </div>
          </Stack>
        )}
        <Stack horizontal tokens={{ childrenGap: 50 }}>
          <Stack grow styles={milestoneListWrapperStyles}>
            <Stack.Item align="start">
              <DefaultButton
                styles={{ root: { margin: '0 0 30px 53px' } }}
                onClick={() => toggleCollapseAll()}
                iconProps={{ iconName: checkIsCollapsed() ? 'ChevronRight' : 'ChevronDown' }}
                label={checkIsCollapsed() ? 'Expand All Milestones' : 'Collapse All Milestones'}
                text={checkIsCollapsed() ? 'Expand All Milestones' : 'Collapse All Milestones'}
              />
            </Stack.Item>
            {milestones?.map((milestone: IMilestone, index: number) => {
              const isLast = milestone.id === milestones[milestoneLength - 1].id;
              return (
                <MilestoneListItem
                  key={milestone.id}
                  milestone={milestone}
                  process={processDetail}
                  openEditPanel={toggleMilestoneDueDateEditPanel}
                  toggleDividendsPanel={toggleDividendsPanel}
                  allCollapsed={isExpanded}
                  isLast={isLast}
                />
              );
            })}
          </Stack>
          <Stack className="ms-hiddenLgDown">
            <div style={rightBarStyles}>
              <ProcessDetailSideColumn
                processDetail={processDetail}
                toggleProcessEditPanel={toggleProcessEditPanel}
                toggleCorpReasonForLateFilingEditPanel={toggleCorpReasonForLateFilingEditPanel}
                toggleFilingViolationPanel={toggleFilingViolationPanel}
                toggleHistoryFilingViolationPanel={toggleHistoryFilingViolationPanel}
                toggleDividendsPanel={toggleDividendsPanel}
                toggleAGMEditPanel={toggleAGMEditPanel}
                toggleReportingExclusion={toggleReportingExclusion}
                isFinancialProcess={isFinancialProcess}
              />
            </div>
          </Stack>
        </Stack>
      </>
    );
  };

  const paddingForDivder = { paddingTop: 8 };

  const renderProcessExtensionButton = (): JSX.Element => {
    const isDueDatePast = moment(processDetail.filingDueDate)
      .utc()
      .endOf('day')
      .isBefore(moment().utc());
    const isProcessComplete = processDetail.processStatus === ProcessStatus.Complete;

    const actionButton = () => {
      if (isProcessComplete && !processDetail.hasProcessExtensionRequest) {
        return (
          <div>
            <ActionButton
              id="DisabledNewExtensionButton"
              iconProps={{ iconName: 'timeline' }}
              allowDisabledFocus
              onClick={() => {
                setShowTeachingBubble(!showTeachingBubble);
              }}
            >
              Add/View Extensions
            </ActionButton>
            {showTeachingBubble && (
              <TeachingBubble
                target="#DisabledNewExtensionButton"
                primaryButtonProps={{
                  children: 'Close',
                  onClick: () => {
                    setShowTeachingBubble(!showTeachingBubble);
                  },
                }}
                headline="Process Complete"
              >
                Processes that are complete cannot be extended.
              </TeachingBubble>
            )}

            <span style={paddingForDivder}>|</span>
          </div>
        );
      }

      return (
        <>
          <ActionButton
            iconProps={{ iconName: 'timeline' }}
            allowDisabledFocus
            onClick={toggleProcessExtensionRequestPanel}
          >
            Add/View Extensions
          </ActionButton>

          <span style={paddingForDivder}>|</span>
        </>
      );
    };

    if (isDueDatePast && !processDetail.hasProcessExtensionRequest) {
      return (
        <CanAccess requestedAction="ProcessExtensionRequest:AdminAdd">{actionButton()}</CanAccess>
      );
    }

    if ((isDueDatePast && processDetail.hasProcessExtensionRequest) || !isDueDatePast) {
      const action = processDetail.hasProcessExtensionRequest
        ? 'ProcessExtensionRequest:View'
        : 'ProcessExtensionRequest:Add';

      return <CanAccess requestedAction={action}>{actionButton()}</CanAccess>;
    }

    return <></>;
  };

  const EditMilestoneDueDatesQuickLink = () => {
    return (
      <CanAccess requestedAction="MilestoneDueDates:Update">
        {CanEditDueDate(milestones[milestoneLength - 1]) && (
          <>
            <ActionButton
              iconProps={{ iconName: 'calendarSettings' }}
              allowDisabledFocus
              onClick={toggleMilestoneDueDateEditPanelFromQuickLink}
            >
              Edit Milestone Due Dates
            </ActionButton>

            <span style={paddingForDivder}>|</span>
          </>
        )}
      </CanAccess>
    );
  };

  return (
    <>
      {!data?.processDetail && <Shimmer ariaLabel="Loading Content" />}
      {data?.processDetail && (
        <ProcessProvider value={data?.processDetail}>
          <LoadingErrorMessage
            loading={loading}
            error={error}
            actionName="loading the process data"
          />
          <div className={`${bodyContentContainer}  ms-depth-4`}>
            <div className={bodyContentWrapper}>
              <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
                <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
                  <DefaultButton
                    className="ms-hiddenXlUp"
                    onClick={(): void => {
                      setIsProcessDetailsExpanded(!isProcessDetailsExpanded);
                    }}
                  >
                    {isProcessDetailsExpanded ? 'Hide Process Details' : 'Show Process Details'}
                  </DefaultButton>
                  {renderProcessExtensionButton()}
                  {EditMilestoneDueDatesQuickLink()}
                  <CanAccess requestedAction="ProcessConfiguration:View">
                    <Link
                      to={`/companies/${companyCode}/processes/configs/${data?.processDetail.processConfig.id}?processTemplateVersionId=${processTemplateVersionId}`}
                    >
                      <Stack
                        horizontal
                        horizontalAlign="end"
                        verticalAlign="center"
                        tokens={{ childrenGap: 5 }}
                        styles={{ root: { paddingTop: 8 } }}
                      >
                        <FontIcon
                          iconName="settings"
                          className={settingsIconStyle}
                          aria-label="Process Config Link"
                        />
                        <span>Process Config</span>
                      </Stack>
                    </Link>
                  </CanAccess>
                </Stack>
              </BodyHeaderWithCommandBar>
            </div>
          </div>

          {isProcessEditPanelOpen && (
            <ProcessDetailEditPanel
              processDetails={processDetail}
              closePanel={toggleProcessEditPanel}
            />
          )}

          {isCorpReasonEditPanelOpen && (
            <CorpReasonForLateFilingEditPanel
              processCorpReasonForLateFiling={processCorpReasonForLateFiling}
              closePanel={toggleCorpReasonForLateFilingEditPanel}
            />
          )}

          {isDueDatePanelOpen && (
            <MilestoneDueDateEditPanel
              focusedMilestoneId={focusedMilestoneId}
              closePanel={toggleDueDateEditPanel}
            />
          )}

          {isEditFilingViolationPanelOpen && !loading && (
            <ReasonForViolationEditPanel
              processDetail={processDetail}
              closePanel={toggleFilingViolationPanel}
            />
          )}

          {isReportingExclusionPanelOpen && !loading && (
            <ReportingExclusionEditPanel
              processReasonForReportExclusion={{
                processId: processDetail?.id,
                reasonForReportExclusionComments: processDetail?.reasonForReportExclusionComments,
                reasonForReportExclusion: processDetail?.reasonForReportExclusion,
                excludeFromMetrics: processDetail?.excludeFromMetrics,
                processExceptionApproved: processDetail?.processExceptionApproved,
                approvedReasonComments: processDetail?.reportExceptionApprovalReasonComments,
                processExceptionApprovedBy: {
                  text: processDetail?.reportExceptionApprovedByDisplayName,
                  graphId: processDetail?.reportExceptionApprovedByGraphGUID,
                },
                processExceptionApprovedReason: processDetail?.reasonForReportingExclusionApproval,
              }}
              closePanel={toggleReportingExclusion}
            />
          )}

          {isHistoryFilingViolationPanelOpen && !loading && (
            <ReasonForFilingViolationHistoryPanel closePanel={toggleHistoryFilingViolationPanel} />
          )}

          {isProcessExtensionPanelOpen && (
            <ProcessExtensionRequestPanel
              processExtension={{
                id: -1,
                comments: '',
                requestedDate: moment(new Date(processDetail?.filingDueDate))
                  .add(1, 'days')
                  .toDate(),
                extensionReason: null,
                process: processDetail,
              }}
              closePanel={toggleProcessExtensionRequestPanel}
              currentFilingDueDate={processDetail.filingDueDate}
            />
          )}

          {isDividendsPanelOpen && (
            <FiscalYearDividendsEditPanel
              dividends={processDetail?.dividends}
              closePanel={toggleDividendsPanel}
              mutation={EDIT_DIVIDENDS_FROM_PROCESS_MUTATION}
            />
          )}

          {isAGMPanelOpen && (
            <FiscalYearAgmStatusEditPanel
              agmStatus={companyFiscalYearDetail}
              agmMutation={UPDATE_ANNUAL_GENERAL_MEETING}
              closePanel={toggleAGMEditPanel}
              fiscalYearMutation={EDIT_AGM_STATUS_MUTATION}
              companyCode={companyCode}
              refetch={refetch}
            />
          )}

          <div className={`${bodyContentContainer}`}>{renderMilestonesList()}</div>
        </ProcessProvider>
      )}
    </>
  );
};
export default ProcessDetail;
