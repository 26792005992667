import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { calculateSkip } from '../../components/common/DetailsLists/pagination/DetailsListPaginationBanner';

interface IPaginationState {
  pageSize: number;
  currentPage: number;
  skip: number;
}

interface IUrlPaginationDefaults {
  pageSize?: number;
}

const useUrlPagination = (defaults?: IUrlPaginationDefaults) => {
  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const initialState: IPaginationState = {
    pageSize: Number(urlParams.get('pageSize')) || (defaults?.pageSize ?? 25),
    currentPage: Number(urlParams.get('currentPage')) || 1,
    skip:
      calculateSkip(Number(urlParams.get('pageSize')), Number(urlParams.get('currentPage'))) || 0,
  };

  const [paginationState, setPaginationState] = useState(initialState);

  useEffect(() => {
    urlParams.set('pageSize', paginationState.pageSize.toString());
    urlParams.set('currentPage', paginationState.currentPage.toString());

    history.replace(location.pathname.concat(`?${urlParams.toString()}`));
  }, [paginationState]);

  return [paginationState, setPaginationState] as const;
};

export default useUrlPagination;
