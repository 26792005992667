import { OperationVariables, useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React, { useState, useEffect } from 'react';
import { DeepPartial, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  CREATE_COMPANY_DEFAULT_AGM_DETAILS,
  UPDATE_COMPANY_DEFAULT_AGM_DETAILS,
} from '../../../utils/statApi/AnnualFilingRequirementsApi';
import ICompanyDefaultAgmDetails from '../../../utils/types/ICompanyDefaultAgmDetails';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CompanyDefaultAgmDetailsForm from './forms/CompanyDefaultAgmDetailsForm';

interface ICompanyDefaultAgmDetailsPanelProps {
  closePanel: () => void;
  data: ICompanyDefaultAgmDetails;
}

const CompanyDefaultAgmDetailsPanel = ({
  closePanel,
  data,
}: ICompanyDefaultAgmDetailsPanelProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  const form = useForm<ICompanyDefaultAgmDetails>();
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
    control,
  } = form;

  const watchForm: DeepPartial<ICompanyDefaultAgmDetails> = useWatch({
    control,
    defaultValue: data as ICompanyDefaultAgmDetails,
  });

  useEffect(() => {
    if (isDirty) {
      setHasBeenEdited(true);
    } else {
      setHasBeenEdited(false);
    }
  }, [dirtyFields, watchForm]);

  const [
    updateCompanyDefaultAgmDetails,
    { loading: updateAgmDetailsLoading, error: updateAgmDetailsError },
  ] = useMutation(UPDATE_COMPANY_DEFAULT_AGM_DETAILS, {
    onCompleted: () => {
      closePanel();
    },
  });

  const [
    createCompanyDefaultAgmDetails,
    { loading: createAgmDetailsLoading, error: createAgmDetailsError },
  ] = useMutation(CREATE_COMPANY_DEFAULT_AGM_DETAILS, {
    onCompleted: () => {
      closePanel();
    },
  });

  const handleSaveClick = (formData: ICompanyDefaultAgmDetails): void => {
    const agmDetails: OperationVariables = {
      variables: {
        companyCode,
        input: {
          id: data?.id,
          agmDeadlineBeforeAfsDeadline: formData.agmDeadlineBeforeAfsDeadline,
          taxFilingDeadlineBeforeAfsDeadline: formData.taxFilingDeadlineBeforeAfsDeadline,
          taxFilingDeadline: formData.taxFilingDeadline,
          externalAfsFilingRequired: formData.externalAfsFilingRequired,
          requiredToBeFiledWithAfs: formData.requiredToBeFiledWithAfs,
          canAfsBeExtended: formData.canAfsBeExtended,
          physicalSignatureRequired: formData.physicalSignatureRequired,
          hardCopyShippingAddress: formData.hardCopyShippingAddress,
          inkColorRequired: formData.inkColorRequired,
          signatureInkColor: formData.signatureInkColor,
          originalsCount: formData.originalsCount ? parseInt(formData.originalsCount, 10) : null,
          paperSize: formData.paperSize,
          otherPaperSize: formData.otherPaperSize,
          managementRepresentationLetterRequired: formData.managementRepresentationLetterRequired,
          auditorFeesCurrencyCode: formData.auditorFeesCurrencyCode,
          additionalProcessingTimeApostille: formData.additionalProcessingTime?.some(
            (at) => at === 'APOSTILLE',
          ),
          additionalProcessingTimeLegalization: formData.additionalProcessingTime?.some(
            (at) => at === 'LEGALIZATION',
          ),
          additionalProcessingTimeLocalSigner: formData.additionalProcessingTime?.some(
            (at) => at === 'LOCALSIGNER',
          ),
        },
      },
    };

    if (!data?.id) {
      createCompanyDefaultAgmDetails(agmDetails);
    } else {
      updateCompanyDefaultAgmDetails(agmDetails);
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Default AGM Details"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Default AGM Details"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={updateAgmDetailsLoading || createAgmDetailsLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Default AGM Details"
          saveTitle="Save Default AGM Details"
          cancelLabel="Cancel Default AGM Details"
          cancelTitle="Cancel Default AGM Details"
          disabled={!hasBeenEdited}
        />
      )}
    >
      <form>
        <LoadingErrorMessage
          error={updateAgmDetailsError || createAgmDetailsError}
          loading={updateAgmDetailsLoading || createAgmDetailsLoading}
        />
        <CompanyDefaultAgmDetailsForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyDefaultAgmDetailsPanel;
