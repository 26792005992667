import { BaseButton, Button, MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';

interface IOutageBannerProps {
  message: string;
  messageType?: MessageBarType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDismiss?: (ev?: React.MouseEvent<HTMLElement | BaseButton | Button, MouseEvent>) => any;
}

const OutageBanner = ({ message, messageType, onDismiss }: IOutageBannerProps): JSX.Element => {
  return (
    <MessageBar
      messageBarType={messageType || MessageBarType.warning}
      onDismiss={onDismiss}
      dismissButtonAriaLabel="Dismiss banner"
    >
      {message}
    </MessageBar>
  );
};

export default OutageBanner;
