const formatCurrency = (currency: string, value: number): string => {
  if (currency === '-- ') {
    return value?.toString();
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });

  return formatter.format(value);
};

export default formatCurrency;
