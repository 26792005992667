import React from 'react';
import { SelectionMode, DetailsList, IColumn } from '@fluentui/react';
import ICompanyGroupCompany from '../../../utils/types/ICompanyGroupCompany';
import { styledDetailsList } from '../../../app/common/styles/CommonStyleObjects';

const columns: IColumn[] = [
  {
    key: 'name',
    fieldName: 'entityLegalName',
    name: 'Company Name',
    data: 'string',
    minWidth: 200,
    maxWidth: 600,
    isRowHeader: true,
  },
  {
    key: 'code',
    fieldName: 'companyCode',
    name: 'Company Code',
    data: 'string',
    minWidth: 150,
  },
];

interface ICompanyGroupCompanyListProps {
  companies: ICompanyGroupCompany[];
}

const CompanyGroupCompanyList = ({ companies }: ICompanyGroupCompanyListProps): JSX.Element => {
  return (
    <DetailsList
      ariaLabelForGrid="Company List for Company Group"
      items={companies}
      selectionMode={SelectionMode.none}
      columns={columns}
      styles={styledDetailsList}
    />
  );
};

export default CompanyGroupCompanyList;
