import { IColumn, ColumnActionsMode } from '@fluentui/react';
import moment from 'moment';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';
import IDividendPaymentsTotals from '../../../utils/types/IDividendPaymentTotal';
import IDropdownReference from '../../../utils/types/IDropdownReference';

interface IFiscalYearListColumnParams {
  renderAfsNameColumn: (item: { afsProcessName: React.ReactNode }) => JSX.Element;
  renderProcessCompletionDateColumn: (item: { lastMilestoneSignOffDate: Date }) => JSX.Element;
  renderDividendsColumn: (item: { dividendAmount: number; currencyCode: string }) => JSX.Element;
  renderAuditStatusColumn: (item: {
    auditStatus: IDropdownReference;
    auditStatusDate: Date;
  }) => JSX.Element;
  renderAgmStatusColumn: (item: {
    agmStatus: IDropdownReference;
    agmStatusDate: moment.MomentInput;
  }) => JSX.Element;
  renderDividendPaymentsTotalColumn: (item: {
    dividendPaymentsTotals: IDividendPaymentsTotals[];
  }) => JSX.Element;
}

const getColumns = ({
  renderAfsNameColumn,
  renderProcessCompletionDateColumn,
  renderDividendsColumn,
  renderAuditStatusColumn,
  renderAgmStatusColumn,
  renderDividendPaymentsTotalColumn,
}: IFiscalYearListColumnParams): Array<IColumn> => {
  return [
    {
      key: 'afsProcessName',
      name: 'AFS Process Name',
      minWidth: 175,
      maxWidth: 375,
      className: rowClasses.medFont,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      onRender: renderAfsNameColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processCompletionDate',
      name: 'Process Completion Date',
      fieldName: 'lastMilestoneSignOffDate',
      minWidth: 150,
      maxWidth: 200,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      onRender: renderProcessCompletionDateColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'dividendAmount',
      name: 'Proposed Dividend',
      fieldName: 'dividendAmount',
      minWidth: 175,
      maxWidth: 175,
      isRowHeader: false,
      data: 'number',
      columnActionsMode: ColumnActionsMode.disabled,
      isPadded: true,
      onRender: renderDividendsColumn,
    },
    {
      key: 'dividendPaymentsTotal',
      name: 'Dividend Payments Total',
      fieldName: 'dividendPaymentsTotal',
      minWidth: 175,
      maxWidth: 175,
      isRowHeader: false,
      data: 'number',
      isPadded: true,
      onRender: renderDividendPaymentsTotalColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'auditStatus',
      name: 'Audit Status',
      fieldName: 'auditStatus',
      minWidth: 175,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      onRender: renderAuditStatusColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'agmStatus',
      name: 'AGM Status',
      fieldName: 'agmStatus',
      minWidth: 175,
      maxWidth: 300,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      onRender: renderAgmStatusColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export default getColumns;
