import React, { useContext, useState } from 'react';
import { DetailsListLayoutMode, MessageBar, MessageBarType } from '@fluentui/react';
import { ApolloError } from '@apollo/client';
import DetailListPaginationBanner from '../../../common/DetailsLists/pagination/DetailsListPaginationBanner';
import IPaginationMetadata from '../../../common/DetailsLists/pagination/IPaginationMetadata';
import GeneralEntityList from '../../../common/lists/GeneralEntityList';
import AgmTrackerItemEditPanel from './AgmTrackerItemEditPanel';
import { IAgmTrackerPageTrackerSearchResult } from '../../annualFilingRequirements/IAgmListPageSearchResult';
import { IGeneralEntityListColumnSortProps } from '../../../common/lists/IGeneralEntityListColumnSortProps';
import hasAccess from '../../../../utils/authorization/authorizationCheck';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import UserContext from '../../../../utils/authorization/UserContext';
import AgmTrackerCommentsPanel from './AgmTrackerCommentsPanel';

interface IAgmTrackerCardContentProps {
  agmTrackerList: IAgmTrackerPageTrackerSearchResult[];
  error: ApolloError;
  loading: boolean;
  refetch: () => void;
  entityListColumnSortProps: IGeneralEntityListColumnSortProps;
  paginationMetadata: IPaginationMetadata;
  selectedEditPanelItem: IAgmTrackerPageTrackerSearchResult;
  setSelectedBulkItems: (items: IAgmTrackerPageTrackerSearchResult[]) => void;
  selectedBulkItems: IAgmTrackerPageTrackerSearchResult[];
  toggleEditPanel: (item?: IAgmTrackerPageTrackerSearchResult) => void;
  toggleCommentsPanel: (item?: IAgmTrackerPageTrackerSearchResult) => void;
  isEditPanelOpen: boolean;
  isCommentsPanelOpen: boolean;
  ownerFilter: string;
}

const AgmTrackerCardContent = (props: IAgmTrackerCardContentProps): JSX.Element => {
  const {
    agmTrackerList,
    error,
    loading,
    refetch,
    entityListColumnSortProps,
    paginationMetadata,
    selectedEditPanelItem,
    setSelectedBulkItems,
    selectedBulkItems,
    toggleEditPanel,
    toggleCommentsPanel,
    isEditPanelOpen,
    isCommentsPanelOpen,
    ownerFilter,
  } = props;
  const [isBulkEdit, setIsBulkEdit] = useState(false);

  const { columns, setColumns, sortKey, sortDir, onColumnSort } = entityListColumnSortProps;

  const user: StatUser = useContext(UserContext);

  const NoResultsMessageBar = (): JSX.Element => {
    if (agmTrackerList?.length === 0 && !loading) {
      return (
        <MessageBar messageBarType={MessageBarType.info}>
          <span>No items found for current filters</span>
        </MessageBar>
      );
    }
    return <></>;
  };

  return (
    <div>
      <NoResultsMessageBar />
      <GeneralEntityList
        data={agmTrackerList}
        loading={loading}
        listColumns={columns}
        error={error}
        onChangeSelectedItems={(selection) => {
          setSelectedBulkItems(selection as unknown as IAgmTrackerPageTrackerSearchResult[]);
        }}
        setListColumns={setColumns}
        hideSelectedCount={false}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        sortDefinition={{
          sortDir,
          sortKey,
        }}
        onSort={onColumnSort}
        commandBarItems={[
          {
            key: 'editBulkMain',
            text: 'Bulk edit',
            cacheKey: 'editBulkMain', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Edit' },
            disabled:
              selectedBulkItems?.length <= 1 || !hasAccess(user.permissions, 'AgmTracker:Edit'),
            onClick: (ev) => {
              toggleEditPanel();
              return setIsBulkEdit(true);
            },
          },
          {
            key: 'commentBulkMain',
            text: 'Bulk comment',
            cacheKey: 'commentBulkMain',
            iconProps: { iconName: 'CannedChat' },
            disabled:
              selectedBulkItems?.length <= 1 || !hasAccess(user.permissions, 'AgmTracker:Edit'),
            onClick: (ev) => {
              toggleCommentsPanel();
              return setIsBulkEdit(true);
            },
          },
        ]}
      />
      {paginationMetadata && <DetailListPaginationBanner {...paginationMetadata} />}
      {isEditPanelOpen && (
        <AgmTrackerItemEditPanel
          agmTrackerItem={selectedEditPanelItem}
          bulkAgmTrackerItems={isBulkEdit ? selectedBulkItems : []}
          closePanel={() => {
            setIsBulkEdit(false);
            toggleEditPanel();
          }}
          refetchTrackerList={refetch}
          ownerFilter={ownerFilter}
        />
      )}
      {isCommentsPanelOpen && (
        <AgmTrackerCommentsPanel
          agmTrackerItems={isBulkEdit ? selectedBulkItems : [selectedEditPanelItem]}
          closePanel={() => {
            setIsBulkEdit(false);
            toggleCommentsPanel();
          }}
          refetchTrackerList={refetch}
          ownerFilter={ownerFilter}
        />
      )}
    </div>
  );
};

export default AgmTrackerCardContent;
