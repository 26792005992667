import React, { CSSProperties } from 'react';
import { MessagingColors } from '../../../app/common/styles/CommonStyleObjects';

interface IInlineFormInputErrorMessageProps {
  errorMessage?: string;
  errorWrapperStyle?: CSSProperties;
}

const errorStyles = { color: MessagingColors.errorBlockIcon, fontWeight: 400, fontSize: '12px' };

const InlineFormInputErrorMessage = ({
  errorMessage,
  errorWrapperStyle,
}: IInlineFormInputErrorMessageProps): JSX.Element => {
  return (
    <>
      {errorMessage && (
        <div role="alert" style={errorWrapperStyle}>
          <p style={errorStyles}>
            <span>{errorMessage}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default InlineFormInputErrorMessage;
