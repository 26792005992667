import { mergeStyles } from '@fluentui/react';
import React from 'react';

interface IVirticalScrollbarWrapper {
  children: JSX.Element;
}

const verticalScrollbarWrapperStyle = mergeStyles({
  height: '330px',
  overflow: 'auto',
});

const VerticalScrollbarWrapper = ({ children }: IVirticalScrollbarWrapper): JSX.Element => {
  return <div className={verticalScrollbarWrapperStyle}>{children}</div>;
};
export default VerticalScrollbarWrapper;
