import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Stack, PrimaryButton, Spinner } from '@fluentui/react';
import { DocumentNode } from 'graphql';
import { StatUser, DeleteUserMutation } from '../../utils/statApi/UsersApi';
import { userDetailsStack } from './UsersStyles';
import MultiLineErrorMessage from '../../utils/errorMessages/MultiLineErrorMessage';
import SecondaryFormFields from './SecondaryFormFields';
import PrimaryFormFields from './PrimaryFormFields';
import GraphUserBlock from './GraphUserBlock';
import { IGraphErrorObject, IGraphUserObject } from '../../utils/types/Users';
import CanAccess from '../common/canAccess/CanAccess';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';

interface UserDetailProps {
  cameFromAddFlow?: boolean;
  graphUserSearchErrors: IGraphErrorObject;
  alias: string;
  statUser: StatUser;
  graphUser?: IGraphUserObject;
  userImage?: string;
  mutationQuery: DocumentNode;
  mutationKey: string;
  // Linting error ignored because we are using params whose types are
  // defined in 3rd party libs (ie: cache)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMutationSuccessUpdate: (cache: any, modifiedUser: StatUser) => void;
}

const UserDetailsForm = (props: UserDetailProps): JSX.Element => {
  const {
    statUser,
    userImage,
    graphUser,
    alias,
    cameFromAddFlow,
    graphUserSearchErrors,
    mutationQuery,
    onMutationSuccessUpdate,
    mutationKey,
  } = props;

  const globalRole = statUser?.userRoles?.find((role) => role.groupIdentifier === 'GLOBAL')?.roleId;

  const [userRole, setUserRole] = useState(globalRole);

  const [
    hasActiveTaxAndTreasuryServiceContactsError,
    sethasActiveTaxAndTreasuryServiceContactsError,
  ] = useState(false);

  const [createUser, { loading: mutationLoading, error: mutationError }] = useMutation(
    mutationQuery,
    {
      update(cache, { data }) {
        onMutationSuccessUpdate(cache, data[mutationKey]);
      },
      refetchQueries: ['GetUsersQuery'],
    },
  );

  const [deleteUser, { loading: deactivateLoading, error: deactivateError }] = useMutation(
    DeleteUserMutation,
    {
      update(cache, { data }) {
        onMutationSuccessUpdate(cache, statUser);
      },
      refetchQueries: ['GetUsersQuery'],
    },
  );

  const saveUser = (): void => {
    const newUserObject: StatUser = {
      graphGUID: statUser.graphGUID,
      displayName: statUser.displayName,
      roleId: userRole,
      emailAddress: statUser.emailAddress,
      alias,
      firstName: statUser.firstName,
      lastName: statUser.lastName,
      isActive: true,
      personnelNumber: statUser.personnelNumber,
    };

    newUserObject.id = statUser.id || -1;
    createUser({
      variables: {
        statUser: newUserObject,
      },
    });
  };

  const deactivateUser = (): void => {
    deleteUser({
      variables: {
        graphGUID: statUser.graphGUID,
      },
    });
  };

  React.useEffect(() => {
    if (statUser && globalRole) {
      setUserRole(globalRole);
    }
    return function cleanup(): void {
      setUserRole(0);
    };
  }, [statUser]);

  const validateServiceContacts = (role: number) => {
    if (
      statUser.userRoles?.some((ur) => ur.groupIdentifier === 'COMPANY' && ur.roleId === globalRole)
    ) {
      sethasActiveTaxAndTreasuryServiceContactsError(true);
    } else {
      setUserRole(role);
    }
  };

  const renderGraphFieldsForm = (): JSX.Element => {
    return <SecondaryFormFields statUser={statUser} />;
  };
  const renderDetailsForm = (): JSX.Element => {
    return (
      <PrimaryFormFields
        alias={alias}
        statUser={statUser}
        userRole={userRole}
        onSetUserRole={(role: number): void => {
          validateServiceContacts(role);
        }}
      />
    );
  };
  const renderUserFromGraph = (): JSX.Element => {
    if (graphUserSearchErrors) {
      return (
        <MultiLineErrorMessage
          showError={graphUserSearchErrors}
          message="This STAT user is not in the Microsoft Graph directory. Consider deleting this user."
          type="error"
        />
      );
    }
    return <GraphUserBlock graphUser={graphUser} userImage={userImage} />;
  };
  const renderDeactivateButton = (): JSX.Element => {
    if (statUser?.id !== undefined && statUser?.isActive) {
      return (
        <CanAccess requestedAction="GlobalUser:Deactivate">
          <PrimaryButton
            text="Deactivate User"
            ariaLabel="Deactivate User"
            onClick={deactivateUser}
            disabled={mutationLoading || deactivateLoading}
          />
        </CanAccess>
      );
    }
    return <></>;
  };

  return (
    <Stack tokens={userDetailsStack}>
      <Stack.Item>
        <MultiLineErrorMessage
          showError={cameFromAddFlow || false}
          message="The user you want to add already exists. You may edit it here."
          type="warning"
        />
        <MultiLineErrorMessage
          showError={!statUser.isActive || false}
          message="This user is currently deactivated. Assign a role here to reactivate this user."
          type="warning"
        />
        <LoadingErrorMessage
          loading={deactivateLoading}
          error={deactivateError}
          actionName="There was an error when attempting to deactivate this user"
        />
        <MultiLineErrorMessage
          showError={hasActiveTaxAndTreasuryServiceContactsError || false}
          message="This user is currently assigned as a service contact for the following companies. Remove the users from service contacts and then change the role."
          type="warning"
        />
      </Stack.Item>
      <Stack.Item>{renderUserFromGraph()}</Stack.Item>
      <Stack.Item>
        {renderDetailsForm()}
        {renderGraphFieldsForm()}
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={userDetailsStack}>
          <CanAccess requestedAction="GlobalUser:Edit">
            <PrimaryButton
              text="Save User"
              ariaLabel="Save User"
              onClick={saveUser}
              disabled={
                (!userRole && cameFromAddFlow) ||
                mutationLoading ||
                deactivateLoading ||
                hasActiveTaxAndTreasuryServiceContactsError ||
                (!userRole && !globalRole)
              }
            />
          </CanAccess>
          {renderDeactivateButton()}
        </Stack>
      </Stack.Item>

      <Stack.Item>
        {mutationLoading && <Spinner label="Saving User...." ariaLive="assertive" />}
        <MultiLineErrorMessage
          showError={mutationError}
          message="There was an error saving to the database."
          type="error"
        />
      </Stack.Item>
    </Stack>
  );
};
export default UserDetailsForm;
