import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { multiDropdownMediumWrapper } from '../../companies/documents/CompanyDocumentStyles';

interface IReviewStatusDropdownProps {
  onStatusUpdate: (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
  selectedStatuses: string[];
}

const ReviewStatusDropdown = (props: IReviewStatusDropdownProps): JSX.Element => {
  const { onStatusUpdate, selectedStatuses } = props;

  const reviewStatusOptions: IDropdownOption[] = [
    { key: 'PENDING', text: 'Pending' },
    { key: 'ACCEPTED', text: 'Accepted' },
    { key: 'CHANGESREQUESTED', text: 'Changes Requested' },
  ];

  return (
    <Dropdown
      label="Status"
      className={multiDropdownMediumWrapper}
      onChange={onStatusUpdate}
      selectedKeys={selectedStatuses}
      multiSelect
      placeholder="Select an option"
      options={reviewStatusOptions}
    />
  );
};

export default ReviewStatusDropdown;
