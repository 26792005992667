import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, UseFormMethods, useWatch } from 'react-hook-form';
import { getDayDropdownOptions, monthDropdownOptions } from '../../../../utils/Helpers';

interface IMonthDayPickerProps {
  dueDateMonth: number;
  dueDateDay: number;
  form: UseFormMethods<FieldValues>;
  dayFormName: string;
  monthFormName: string;
  label: string | JSX.Element;
  dayLabel?: JSX.Element;
  required: boolean;
  disabled?: boolean;
}

const MonthDayPicker = ({
  dueDateMonth,
  dueDateDay,
  form,
  dayFormName,
  monthFormName,
  label,
  dayLabel,
  required,
  disabled,
}: IMonthDayPickerProps): JSX.Element => {
  const { errors, control, setValue, getValues } = form;
  const isCustomLabel = label && typeof label !== 'string';

  const watchDueDateMonth = useWatch({
    control,
    name: monthFormName,
    defaultValue: monthDropdownOptions.find((month) => month.key === Number(dueDateMonth))?.key,
  });

  const [daysDropDownValues, setDaysDropDownValues] = useState<IDropdownOption[]>(
    getDayDropdownOptions(watchDueDateMonth as number),
  );

  const currentDay = daysDropDownValues.find((day) => day.key === Number(dueDateDay));

  useEffect(() => {
    const days = getDayDropdownOptions(watchDueDateMonth as number);

    if (getValues(dayFormName) && !days.find((day) => day.key === getValues(dayFormName))) {
      setValue(dayFormName, null);
    }

    setDaysDropDownValues(days);
  }, [watchDueDateMonth]);

  const validateMonth = (): string => {
    if (!getValues(monthFormName) && required) {
      return `${label} Month is required`;
    }
    return null;
  };

  const validateDay = (): string => {
    if (!getValues(dayFormName) && required) {
      return 'Day is required';
    }
    return null;
  };

  const dayDropDownStyles = { root: { width: 100 } };
  const monthDropDownStyles = { root: { width: 300 } };

  return (
    <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
      <Controller
        render={() => (
          <div>
            {isCustomLabel && label}
            <Dropdown
              label={isCustomLabel ? undefined : `${label} Month`}
              defaultSelectedKey={dueDateMonth}
              required={required}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                index?: number,
              ) => {
                setValue(monthFormName, option.key);
              }}
              errorMessage={errors[monthFormName]?.message}
              options={monthDropdownOptions}
              calloutProps={{ calloutMaxHeight: 215 }}
              styles={monthDropDownStyles}
              disabled={disabled}
            />
          </div>
        )}
        name={monthFormName}
        control={control}
        defaultValue={watchDueDateMonth}
        value={watchDueDateMonth}
        rules={{
          validate: validateMonth,
        }}
      />
      <Controller
        render={() => (
          <div>
            {dayLabel && dayLabel}
            <Dropdown
              label={dayLabel ? undefined : 'Day'}
              selectedKey={getValues(dayFormName)}
              required={required}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                index?: number,
              ) => {
                setValue(dayFormName, option.key);
              }}
              errorMessage={errors[dayFormName]?.message}
              options={daysDropDownValues}
              calloutProps={{ calloutMaxHeight: 215 }}
              styles={dayDropDownStyles}
              disabled={disabled}
            />
          </div>
        )}
        name={dayFormName}
        control={control}
        defaultValue={currentDay?.key}
        value={currentDay?.key}
        rules={{
          validate: validateDay,
        }}
      />
    </Stack>
  );
};

export default MonthDayPicker;
