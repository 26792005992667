import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ActionButtons from '../../common/ActionButtons';
import CanAccess from '../../common/canAccess/CanAccess';
import IAnnualFilingRequirements from '../../../utils/types/IAnnualFilingRequirements';
import FinancialStatementFilingInfoForm from '../detail/forms/FinancialStatementFilingInfoForm';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import IFinancialStatementFilingInformation from '../../../utils/types/IFinancialStatementFilingInformation';
import { UPDATE_FINANCIAL_STATEMENT_FILING_INFO_MUTATION } from '../../../utils/statApi/AnnualFilingRequirementsApi';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

export interface IFinancialStatementInformationEditPanelProps {
  annualFilingRequirements: IAnnualFilingRequirements;
  closePanel: () => void;
}
const FinancialStatementInformationEditPanel: React.FunctionComponent<
  IFinancialStatementInformationEditPanelProps
> = ({
  annualFilingRequirements,
  closePanel,
}: IFinancialStatementInformationEditPanelProps): JSX.Element => {
  const financialStatementFilingInfoForm = useForm();
  const { errors, handleSubmit } = financialStatementFilingInfoForm;
  const { companyCode } = useParams<ICompanyCodeParams>();

  const [
    updateFinancialStatementFilingDetails,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_FINANCIAL_STATEMENT_FILING_INFO_MUTATION, {
    onCompleted: closePanel,
  });

  const handleOnSaveClick = (formData: IFinancialStatementFilingInformation): void => {
    const financialStatementType = formData?.financialStatementType?.id
      ? {
          id: formData.financialStatementType.id,
          fieldName: formData.financialStatementType.fieldName,
        }
      : null;

    const financialStatementFilingInfoSaveData = {
      id: annualFilingRequirements.id,
      financialStatementType,
      physicalSignatureRequired: formData.physicalSignatureRequired,
      fsSigner: formData.fsSigner,
      otherFinancialStatementInfo: formData.otherFinancialStatementInfo,
      taxId: formData.taxId,
    };
    if (!Object.keys(errors).length) {
      updateFinancialStatementFilingDetails({
        variables: {
          companyCode,
          financialStatementFilingInformationData: {
            ...financialStatementFilingInfoSaveData,
          },
        },
      });
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Financial Statement Filing Information"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Financial Statement Filing Information Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="AnnualFilingRequirements:Edit">
          <ActionButtons
            mutationLoading={mutationLoading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveLabel="Save Financial Statement Filing Info"
            saveTitle="Save Financial Statement Filing Info"
            cancelLabel="Cancel Financial Statement Filing Info Edit"
            cancelTitle="Cancel Financial Statement Filing Info Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="saving the financial statement filing information to the server"
        />
        <FinancialStatementFilingInfoForm
          annualFilingRequirements={annualFilingRequirements}
          form={financialStatementFilingInfoForm}
        />
      </form>
    </CoherencePanel>
  );
};
export default FinancialStatementInformationEditPanel;
