import React from 'react';
import ICompany from '../../utils/types/ICompany';

interface ICompanyContext {
  company: ICompany;
  setCompany: (company: ICompany) => void;
}

const CompanyContext = React.createContext<ICompanyContext>({
  company: null,
  setCompany: (_company: ICompany) => [],
});

export const CompanyProvider = CompanyContext.Provider;
export const CompanyConsumer = CompanyContext.Consumer;

export default CompanyContext;
