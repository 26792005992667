import React, { useState } from 'react';
import { PrimaryButton, Stack, Spinner, StackItem, TextField } from '@fluentui/react';
import { useMutation } from '@apollo/client';
import { IMilestoneListActionItemProps, MilestoneActionType } from '../Interfaces';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { RESCIND_MILESTONE_MUTATION } from '../../../../utils/statApi/MilestoneApi';
import { milestoneListItemActionWrapper, buttonSpinnerStyles } from '../processStyles';

const rescindRequiredText = 'A reason for rescinding is required';
const isRescindingText = 'Rescinding the milestone';
const rescindTextFieldLabel = 'Please provide a reason for rescinding this milestone:';
const ariaReasonForRescindLabel = 'Reason for Rescind';

const MilestoneSignoffAction = ({
  milestone,
  submit,
}: IMilestoneListActionItemProps): JSX.Element => {
  const [missingRescindError, setMissingRescindError] = useState('');
  const [comments, setComments] = useState('');
  const isMissingComments = !comments || comments.length === 0;

  const [updateMilestone, { loading, error }] = useMutation(RESCIND_MILESTONE_MUTATION, {
    onCompleted: () => {
      submit(MilestoneActionType.normal);
    },
  });

  const submitMilestoneAction = (): void => {
    updateMilestone({ variables: { milestoneId: milestone.id, comments } });
  };

  return (
    <Stack horizontalAlign="center">
      <LoadingErrorMessage error={error} loading={loading} actionName={isRescindingText} />
      <StackItem className={milestoneListItemActionWrapper}>
        <TextField
          ariaLabel={ariaReasonForRescindLabel}
          label={rescindTextFieldLabel}
          multiline
          required
          resizable={false}
          value={comments}
          onChange={(event, newValue): void => {
            setComments(newValue);
          }}
          onBlur={(): void => {
            setMissingRescindError(isMissingComments && rescindRequiredText);
          }}
          errorMessage={missingRescindError}
        />
      </StackItem>
      <PrimaryButton
        text="Submit"
        type="submit"
        onClick={submitMilestoneAction}
        disabled={loading || isMissingComments}
        onRenderIcon={(): JSX.Element => {
          return loading && <Spinner styles={buttonSpinnerStyles} />;
        }}
      />
    </Stack>
  );
};

export default MilestoneSignoffAction;
