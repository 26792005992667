import { ApolloError } from '@apollo/client';
import {
  DetailsList,
  SelectionMode,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from '@fluentui/react';
import React, { CSSProperties } from 'react';
import useToast from '../../../utils/hooks/useToast';
import ICompanyMilestoneDocument from '../../../utils/types/ICompanyMilestoneDocument';
import { Toast } from '../../notifications/StatNotification';
import DetailListPaginationBanner from '../DetailsLists/pagination/DetailsListPaginationBanner';
import IPaginationMetadata from '../DetailsLists/pagination/IPaginationMetadata';
import FileSelectorShimmer from '../formFields/fileUpload/FileSelectorShimmer';
import getDocumentListColumns from './DocumentList.config';

interface IDocumentsListProps {
  docType: string;
  documents: ICompanyMilestoneDocument[];
  loading: boolean;
  showDeletedFiles: boolean;
  error?: ApolloError;
  paginationData: IPaginationMetadata;
}

const DocumentsList = ({
  docType,
  documents,
  loading,
  showDeletedFiles,
  error,
  paginationData,
}: IDocumentsListProps): JSX.Element => {
  if (error) {
    return <></>;
  }

  if (loading) {
    return <Spinner size={SpinnerSize.medium} />;
  }

  if (!documents || documents.length === 0) {
    return <p>No documents found. Try changing the filters or uploading documents.</p>;
  }

  const h4Style = { marginBottom: 0 } as CSSProperties;

  const { createToast } = useToast();

  const onDocumentError = (message: string): void => {
    const toast: Toast = {
      id: Math.random(),
      content: message,
      header: 'Document Download Error',
      iconName: 'ErrorBadge',
    };
    createToast(toast);
  };

  const columns = getDocumentListColumns(onDocumentError);
  const title = showDeletedFiles ? 'Recycled' : docType;

  return (
    <Stack>
      <div>
        <h4 style={h4Style}>{`${title} Documents`}</h4>
        <Separator />
      </div>

      {loading && (
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          <StackItem>
            <FileSelectorShimmer />
          </StackItem>
          <StackItem>
            <FileSelectorShimmer />
          </StackItem>
        </Stack>
      )}

      {!loading && (
        <>
          <DetailsList items={documents} columns={columns} selectionMode={SelectionMode.none} />
          <DetailListPaginationBanner {...paginationData} />
        </>
      )}
    </Stack>
  );
};

export default DocumentsList;
