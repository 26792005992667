import React, { useState } from 'react';
import moment from 'moment';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { IMilestoneDueDates, IMilestone } from '../Interfaces';
import MilestoneDueDatePicker from './MilestoneDueDatePicker';
import GetMinMaxDueDates from './milestoneDueDateUtils';

interface IMilestoneDueDatesForm {
  milestones?: IMilestone[];
  focusedMilestoneId: number;
  setDueDatesToSave: React.Dispatch<React.SetStateAction<IMilestoneDueDates[]>>;
  dueDatesToSave: IMilestoneDueDates[];
}

const MilestoneDueDatesForm: React.FunctionComponent<IMilestoneDueDatesForm> = ({
  milestones,
  focusedMilestoneId,
  setDueDatesToSave,
  dueDatesToSave,
}: IMilestoneDueDatesForm): JSX.Element => {
  const [editedMilestones, setEditedMilestones] = useState(milestones);

  const messageBarStyle = { root: { marginBottom: '5px' } };

  const changeDueDate = (dueDateString: string, milestoneId: number) => {
    const newMilestones = [...editedMilestones];
    const targetMilestone = newMilestones.find((milestone) => milestone.id === milestoneId);
    const targetMilestoneIndex = newMilestones.findIndex(
      (milestone) => milestone.id === milestoneId,
    );
    const editedTargetMilestone = { ...targetMilestone };
    editedTargetMilestone.dueDate = moment(dueDateString).toDate();
    newMilestones[targetMilestoneIndex] = editedTargetMilestone;
    setEditedMilestones(newMilestones);

    const milestoneDueDateItem: IMilestoneDueDates = {
      id: milestoneId,
      dueDate: dueDateString,
    };
    const otherDueDates = dueDatesToSave.filter(
      (mdd: IMilestoneDueDates) => mdd.id !== milestoneId,
    );
    setDueDatesToSave([...otherDueDates, milestoneDueDateItem]);
  };

  return (
    <Stack>
      <MessageBar messageBarType={MessageBarType.info} styles={messageBarStyle}>
        <span>Milestone due dates in the past cannot be edited</span>
      </MessageBar>

      {editedMilestones?.map((milestone: IMilestone, index: number) => {
        const minMaxDates = GetMinMaxDueDates(editedMilestones, index);
        return (
          <Stack horizontal key={milestone.id}>
            <MilestoneDueDatePicker
              index={index}
              milestone={milestone}
              changeDueDate={changeDueDate}
              focusedMilestoneId={focusedMilestoneId}
              minMilestoneDateChange={minMaxDates.minMilestoneDateChange}
              maxMilestoneDateChange={minMaxDates.maxMilestoneDateChange}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MilestoneDueDatesForm;
