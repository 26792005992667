import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import { UPDATE_LEGAL_ENTITY_TERMINATION_INFO } from '../../../utils/statApi/CompaniesApi';
import ICompanyLegalEntityTermination from '../../../utils/types/ICompanyLegalEntityTermination';
import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CompanyLegalEntityTerminationInfoForm from './forms/CompanyLegalEntityTerminationInfoForm';

interface ICompanyLegalEntityTerminationPanelProps {
  closePanel: () => void;
  data: ICompanyLegalEntityTermination;
}

const CompanyLegalEntityTerminationPanel = ({
  closePanel,
  data,
}: ICompanyLegalEntityTerminationPanelProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const form = useForm<FieldValues>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateCompanyLegalEntityTerminationInfo, { loading, error }] = useMutation(
    UPDATE_LEGAL_ENTITY_TERMINATION_INFO,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleSaveClick = (formData: ICompanyLegalEntityTermination): void => {
    updateCompanyLegalEntityTerminationInfo({
      variables: {
        companyCode,
        input: {
          id: data.id,
          terminationContacts:
            formData.terminationContacts?.map((contact) => {
              return {
                id: contact?.id ? parseInt(contact.id, 10) : null,
                graphId: contact.graphId,
                name: contact.text,
              };
            }) ?? null,
          celaTermContact: formData.celaTermContact
            ? {
                id: formData.celaTermContact?.id,
                graphId: formData.celaTermContact?.graphId,
                name: formData.celaTermContact?.text,
              }
            : null,
          anticipatedTerminationFY: formData.anticipatedTerminationFY,
          anticipatedTerminationQuarter: formData.anticipatedTerminationQuarter,
          accountingPlanStarted: formData.accountingPlanStarted,
          intakeMethodId: formData.intakeMethod?.id,
          legoCategoryId: formData.legoCategory?.id,
          terminationLifecycleStatusId: formData.terminationLifecycleStatus?.id,
          legalEntityTerminationNotes: formData.legalEntityTerminationNotes,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Legal Entity Termination Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Legal Entity Termination Info"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleSaveClick)}
          saveLabel="Save Legal Entity Termination Info"
          saveTitle="Save Legal Entity Termination Info"
          cancelLabel="Cancel Legal Entity Termination Info"
          cancelTitle="Cancel Legal Entity Termination Info"
        />
      )}
    >
      <form>
        <LoadingErrorMessage error={error} loading={loading} />
        <CompanyLegalEntityTerminationInfoForm data={data} form={form} />
      </form>
    </CoherencePanel>
  );
};

export default CompanyLegalEntityTerminationPanel;
