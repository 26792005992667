import React, { useEffect, useState } from 'react';
import { IColumn, ITag, Pivot, PivotItem, Stack, Toggle } from '@fluentui/react';
import { useLazyQuery } from '@apollo/client';
import { bodyContentContainer } from '../../../../app/common/styles/CommonStyleObjects';
import AgmTrackerCardContent from './AgmTrackerCardContent';
import GenericFilterTagHandler from '../../../common/lists/GenericFilterTagHandler';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import AgmTrackerFilters from './AgmTrackerFilters';
import { SortDirection } from '../../../../utils/types/IListSortDefinition';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import { GET_AGM_TRACKER_LIST_QUERY } from '../../../../utils/statApi/CompanyFiscalYearsApi';
import useUrlPagination from '../../../../utils/hooks/useUrlPagination';
import useUrlSorting, { getSortObject } from '../../../../utils/hooks/useUrlSorting';
import useUrlFiltering from '../../../../utils/hooks/useUrlFiltering';
import {
  calculateSkip,
  getTotalPageCount,
} from '../../../common/DetailsLists/pagination/DetailsListPaginationBanner';
import IPaginationMetadata from '../../../common/DetailsLists/pagination/IPaginationMetadata';
import getAgmTrackerColumns from './AgmTrackerList.config';
import { IAgmTrackerPageTrackerSearchResult } from '../../annualFilingRequirements/IAgmListPageSearchResult';
import { IGeneralEntityListColumnSortProps } from '../../../common/lists/IGeneralEntityListColumnSortProps';
import { IAgmPageSearchState } from './IAgmPageSearchState';
import AgmTrackerCategoryPushPane from './AgmTrackerCategoryPushPane';

const initialState: IAgmPageSearchState = {
  aging: undefined,
  statuses: undefined,
  wwAreas: undefined,
  countries: undefined,
  companyIds: undefined,
  celaAssigneeIds: undefined,
  controllerIds: undefined,
  agmDueDateStart: undefined,
  agmDueDateEnd: undefined,
  filingDueDateStart: undefined,
  filingDueDateEnd: undefined,
  reportEndDateStart: undefined,
  reportEndDateEnd: undefined,
  owner: 'CELA', // default to CELA
  openItemsOnly: true,
  filterTags: undefined,
};

const dateKeys = [
  'filingDueDateStart',
  'filingDueDateEnd',
  'reportEndDateStart',
  'reportEndDateEnd',
  'agmDueDateStart',
  'agmDueDateEnd',
];
const arrayKeys = [
  'celaAssigneeIds',
  'controllerIds',
  'statuses',
  'wwAreas',
  'countries',
  'filterTags',
];
const numberKeys = ['companyIds'];
const openItemsOnly = ['openItemsOnly'];

const AgmTrackerListPage = (): JSX.Element => {
  const [celaCount, setCelaCount] = useState<number>(0);
  const [controllerCount, setControllerCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedEditPanelItem, setSelectedItem] = useState<IAgmTrackerPageTrackerSearchResult>();
  const [selectedBulkItems, setSelectedBulkItems] = useState<IAgmTrackerPageTrackerSearchResult[]>(
    [],
  );
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false);
  const [filterTagState, setFilterTagState] = useState<IGenericFilterTag[]>([]);
  const [paginationState, setPaginationState] = useUrlPagination({ pageSize: 15 });
  const [sortedState, setSortedState] = useUrlSorting('companyName');
  const [filterState, setFilterState] = useUrlFiltering<IAgmPageSearchState>({
    defaultFilterObject: initialState,
    dateParameterKeys: dateKeys,
    arrayParameterKeys: arrayKeys,
    numberParameterKeys: numberKeys,
    boolParameterKeys: openItemsOnly,
  });
  const firstLoad = React.useRef(true);

  const agmTrackerSearchDto = filterState.filter;
  const sortDir = sortedState.sortOrder === 'DESC' ? SortDirection.DESC : SortDirection.ASC;

  const toggleEditPanel = (item?: IAgmTrackerPageTrackerSearchResult): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
    setSelectedItem(item);
  };

  const toggleCommentsPanel = (item?: IAgmTrackerPageTrackerSearchResult): void => {
    setIsCommentsPanelOpen(!isCommentsPanelOpen);
    setSelectedItem(item);
  };

  const [columns, setColumns] = useState<IColumn[]>(
    getAgmTrackerColumns(toggleEditPanel, toggleCommentsPanel),
  );

  const [refetch, { data, loading, error }] = useLazyQuery(GET_AGM_TRACKER_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: 0,
      take: paginationState.pageSize,
      order: sortedState.sortObject,
      agmTrackerSearchDto: filterState.filter,
    },
    onCompleted: () => {
      setCelaCount(data?.agmTrackerOwnerFilteredTotals?.celaOwnerCount ?? 0);
      setControllerCount(data?.agmTrackerOwnerFilteredTotals?.controllerOwnerCount ?? 0);
      setTotalCount(data?.agmTrackerOwnerFilteredTotals?.totalOwnerCount ?? 0);
    },
  });

  useEffect(() => {
    let skip = 0;
    if (firstLoad.current) {
      firstLoad.current = false;
      skip = paginationState.skip;
    } else {
      setPaginationState({ ...paginationState, currentPage: 1 });
    }

    refetch({
      variables: {
        skip,
        take: paginationState.pageSize,
        order: sortedState.sortObject,
        agmTrackerSearchDto,
      },
    });
  }, [filterState, sortedState]);

  const onPageSizeChange = (newPageSize: string | number): void => {
    const paginationData = {
      currentPage: 1,
      pageSize: newPageSize as number,
      skip: 0,
    };

    setPaginationState(paginationData);

    refetch({
      variables: {
        skip: paginationData.skip,
        take: paginationData.pageSize,
        order: sortedState.sortObject,
        agmTrackerSearchDto,
      },
    });
  };

  const agmTrackerList: IAgmTrackerPageTrackerSearchResult[] = data?.agmTrackerList?.items || [];

  const onPageChange = (
    startItemIndex: number,
    endItemIndex: number,
    newPageNumber: number,
  ): void => {
    const paginationData = structuredClone(paginationState);
    paginationData.currentPage = newPageNumber;
    paginationData.skip = calculateSkip(paginationData.pageSize, newPageNumber);
    refetch({
      variables: {
        skip: paginationData.skip,
        take: paginationData.pageSize,
        order: sortedState.sortObject,
        agmTrackerSearchDto,
      },
    });
    setPaginationState(paginationData);
  };

  const totalRecordCount: number = data?.agmTrackerList?.totalCount || 0;

  const paginationMetadata: IPaginationMetadata = {
    loadingData: loading,
    pageCount: getTotalPageCount(totalRecordCount, paginationState.pageSize),
    selectedPage: paginationState.currentPage,
    pageSize: paginationState.pageSize,
    onPageChange,
    onPageSizeChange,
  };

  const updateStateResetPagination = (newState: IAgmPageSearchState) => {
    const paginationData = structuredClone(paginationState);
    paginationData.currentPage = 1;
    paginationData.skip = 0;

    setFilterState({ filter: newState });
    setPaginationState(paginationData);
  };

  const refetchAfterEdit = (): void => {
    refetch({
      variables: {
        skip: paginationState.skip,
        take: paginationState.pageSize,
        order: sortedState.sortObject,
        agmTrackerSearchDto,
      },
    });
    updateStateResetPagination(filterState.filter);
  };

  const onColumnSort = (col: IColumn): void => {
    const reverseSortDirection = (direction: SortDirection) => {
      return direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    };
    const calculatedSortDir =
      col.key === sortedState.sortedFieldName ? reverseSortDirection(sortDir) : SortDirection.ASC;

    const newSortedState = structuredClone(sortedState);
    const order = getSortObject(col.key, calculatedSortDir);

    newSortedState.sortedFieldName = col.key;
    newSortedState.sortObject = order;
    newSortedState.sortOrder = calculatedSortDir;

    setSortedState(newSortedState);
  };

  const entityListColumnSortProps: IGeneralEntityListColumnSortProps = {
    columns,
    setColumns,
    sortKey: sortedState.sortedFieldName,
    sortDir,
    onColumnSort,
  };

  const renderHeader = (): JSX.Element => <h1>AGM Tracker</h1>;

  const onPivotChange = (item?: PivotItem): void => {
    if (item) {
      const newState = {
        ...filterState.filter,
      };
      const newStructuredState = structuredClone(newState);
      newStructuredState.owner = item.props.itemKey;

      updateStateResetPagination(newStructuredState);
    }
  };

  const pivotItems = [
    {
      headerText: `CELA (${celaCount})`,
      itemKey: 'CELA',
    },
    {
      headerText: `Controller (${controllerCount})`,
      itemKey: 'CONTROLLER',
    },
    {
      headerText: `All (${totalCount})`,
      itemKey: 'NONE',
    },
  ];

  const onClosedItemsToggle = (checked: boolean): void => {
    const newState = {
      ...filterState.filter,
    };
    const newStructuredState = structuredClone(newState);
    newStructuredState.openItemsOnly = !checked;

    updateStateResetPagination(newStructuredState);
  };
  const onFilterChange = (filterTags: IGenericFilterTag[]): void => {
    const newFilteredState = {
      ...filterState.filter,
      statuses:
        filterTags.find((f) => f.dataKey === 'status')?.values.map((value) => value.name) ??
        undefined,
      companyIds:
        filterTags
          .find((f) => f.dataKey === 'company')
          ?.values.map((value) => value.key as number) ?? undefined,
      countries:
        filterTags.find((f) => f.dataKey === 'country')?.values.map((value) => value.name) ??
        undefined,
      celaAssigneeIds:
        filterTags
          .find((f) => f.dataKey === 'assignee')
          ?.values.map((value) => value.key as string) ?? undefined,
      controllerIds:
        filterTags
          .find((f) => f.dataKey === 'controller')
          ?.values.map((value) => value.key as string) ?? undefined,
      agmDueDateStart:
        filterTags
          .find((f) => f.dataKey === 'agmDueDate')
          ?.values.map((value) =>
            value.name.split('-')[1].trim() !== '' ? value.name.split('-')[1].trim() : undefined,
          )[0] ?? undefined,
      agmDueDateEnd:
        filterTags
          .find((f) => f.dataKey === 'agmDueDate')
          ?.values.map((value) =>
            value.name.split('-')[2].trim() !== '' ? value.name.split('-')[2].trim() : undefined,
          )[0] ?? undefined,
      filingDueDateStart:
        filterTags
          .find((f) => f.dataKey === 'filingDueDate')
          ?.values.map((value) =>
            value.name.split('-')[1].trim() !== '' ? value.name.split('-')[1].trim() : undefined,
          )[0] ?? undefined,
      filingDueDateEnd:
        filterTags
          .find((f) => f.dataKey === 'filingDueDate')
          ?.values.map((value) =>
            value.name.split('-')[2].trim() !== '' ? value.name.split('-')[2].trim() : undefined,
          )[0] ?? undefined,
      reportEndDateStart:
        filterTags
          .find((f) => f.dataKey === 'reportingEndDate')
          ?.values.map((value) =>
            value.name.split('-')[1].trim() !== '' ? value.name.split('-')[1].trim() : undefined,
          )[0] ?? undefined,
      reportEndDateEnd:
        filterTags
          .find((f) => f.dataKey === 'reportingEndDate')
          ?.values.map((value) =>
            value.name.split('-')[2].trim() !== '' ? value.name.split('-')[2].trim() : undefined,
          )[0] ?? undefined,
      aging:
        filterTags
          .find((f) => f.dataKey === 'aging')
          ?.values.map((value) => value.key as string)[0] ?? undefined,
    };
    setFilterTagState(filterTags);
    updateStateResetPagination(newFilteredState);
  };

  const AgmTrackerSubheader = (): JSX.Element => {
    return (
      <>
        <Stack
          horizontalAlign="space-between"
          horizontal
          wrap
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
        >
          <Stack>
            <Pivot
              aria-label="Summarize AGM Tracker results by owner type"
              selectedKey={filterState.filter?.owner}
              onLinkClick={onPivotChange}
              headersOnly
            >
              {pivotItems.map((item) => (
                <PivotItem headerText={item.headerText} itemKey={item.itemKey} key={item.itemKey} />
              ))}
            </Pivot>
          </Stack>
          <Stack horizontalAlign="end" horizontal verticalAlign="center">
            <Toggle
              label="Show Completed Items"
              inlineLabel
              checked={!filterState?.filter?.openItemsOnly ?? false}
              onChange={(_, checked) => onClosedItemsToggle(checked)}
              onText="On"
              offText="Off"
              styles={{ root: { marginBottom: 0 } }}
            />
            <GenericFilterTagHandler onChange={onFilterChange} allFilterTags={filterTagState ?? []}>
              <AgmTrackerFilters />
            </GenericFilterTagHandler>
          </Stack>
        </Stack>
      </>
    );
  };

  const onPushPaneSelectionChange = (tags: ITag[], key: string, title: string) => {
    const filterTagsWithoutCurrentKey = filterTagState.filter((f) => f.dataKey !== key);
    onFilterChange([...filterTagsWithoutCurrentKey, { dataKey: key, values: tags, title }]);
  };

  return (
    <>
      <AgmTrackerCategoryPushPane
        onPushPaneChange={onPushPaneSelectionChange}
        agmOwner={filterState.filter?.owner}
        statusSelectedItems={filterTagState?.find((f) => f?.dataKey === 'status')?.values}
        agingSelectedItems={filterTagState?.find((f) => f?.dataKey === 'aging')?.values}
      >
        <>
          <FullWidthHeader title={renderHeader} subtitle={AgmTrackerSubheader} />
          <div className={`${bodyContentContainer}`}>
            <AgmTrackerCardContent
              agmTrackerList={agmTrackerList}
              error={error}
              loading={loading}
              refetch={refetchAfterEdit}
              ownerFilter={filterState?.filter?.owner}
              entityListColumnSortProps={entityListColumnSortProps}
              paginationMetadata={paginationMetadata}
              selectedEditPanelItem={selectedEditPanelItem}
              setSelectedBulkItems={setSelectedBulkItems}
              selectedBulkItems={selectedBulkItems}
              toggleEditPanel={toggleEditPanel}
              toggleCommentsPanel={toggleCommentsPanel}
              isEditPanelOpen={isEditPanelOpen}
              isCommentsPanelOpen={isCommentsPanelOpen}
            />
          </div>
        </>
      </AgmTrackerCategoryPushPane>
    </>
  );
};

export default AgmTrackerListPage;
