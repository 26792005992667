import { useQuery } from '@apollo/client';
import {
  IconButton,
  Persona,
  PersonaSize,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import momentUtc from '../../../utils/DateFormatter';
import { GET_COMPANY_CREATION_PROGRESS } from '../../../utils/statApi/CompanyCreationApi';
import StatDateFormats from '../../../utils/types/DateFormats';
import CanAccess from '../../common/canAccess/CanAccess';
import Card from '../../common/card/Card';
import { ICompanyCreationParams } from '../../common/ParamTypes';
import SpanWithLabel from '../../common/SpanWithLabel';
import StackColumn from '../../common/StackColumn';
import { IServiceContact } from '../../companies/serviceContacts/interfaces';
import ServiceContactList from '../../companies/serviceContacts/ServiceContactList';
import ActivateCompanyReviewPanel from '../ActivateCompanyReviewPanel';
import CompanyCodePanel from '../CompanyCodePanel';
import CompleteSubledgerReviewPanel from '../CompleteSubledgerReviewPanel';
import NewCompanyPanel from '../NewCompanyPanel';
import SubmitToSapReviewPanel from '../SubmitToSapReviewPanel';
import {
  CompanyCreationStatus,
  FinancialType,
  ICompanyCreationRequestDetails,
  OverviewForm,
} from '../types';
import SapProgressPanel from './SapProgressPanel';
import ShadowCompanyCodePanel from './ShadowCompanyCodePanel';
import StatProgressPanel from './StatProgressPanel';
import SubledgerProgressPanel from './SubledgerProgressPanel';

interface ICompanyInformationPageState {
  isEditPanelOpen: boolean;
  currentForm?: OverviewForm;
}

interface ICompanyRequestDetailPageProps {
  loading: boolean;
  companyRequestInitialInfo: ICompanyCreationRequestDetails;
}

const OverviewPage = ({
  companyRequestInitialInfo,
  loading,
}: ICompanyRequestDetailPageProps): JSX.Element => {
  const [panelState, setPanelState] = useState<ICompanyInformationPageState>({
    isEditPanelOpen: false,
    currentForm: null,
  });

  const { id } = useParams<ICompanyCreationParams>();
  const companyCreationId = parseInt(id, 10);
  const isRequestReadOnly =
    companyRequestInitialInfo?.statusId === CompanyCreationStatus.Canceled ||
    companyRequestInitialInfo?.statusId === CompanyCreationStatus.Complete;

  const isNonTransactional =
    companyRequestInitialInfo?.financialType?.id === FinancialType.NonTransactional;

  const isShadowCompanyRequest =
    companyRequestInitialInfo?.financialType?.id === FinancialType.ShadowCompany;

  const { data: progressData, loading: progressLoading } = useQuery(GET_COMPANY_CREATION_PROGRESS, {
    variables: {
      companyCreationId,
    },
  });

  const closePanel = (): void => {
    setPanelState({ isEditPanelOpen: false, currentForm: null });
  };

  const davaDealLead: IServiceContact[] = companyRequestInitialInfo?.davaDealLead?.displayName
    ? [
        {
          ...companyRequestInitialInfo?.davaDealLead,
          id: companyRequestInitialInfo?.davaDealLead.id.toString(),
          name: companyRequestInitialInfo?.davaDealLead.displayName,
          text: companyRequestInitialInfo?.davaDealLead.displayName,
          graphId: companyRequestInitialInfo?.davaDealLead.graphGUID.toString(),
          email: '',
          contactType: 'Dava Deal Lead',
        },
      ]
    : [];

  const requestor: IServiceContact[] = [
    {
      ...companyRequestInitialInfo?.requestor,
      id: companyRequestInitialInfo?.requestor.id.toString(),
      name: companyRequestInitialInfo?.requestor.displayName,
      text: companyRequestInitialInfo?.requestor.displayName,
      graphId: companyRequestInitialInfo?.requestor.graphId.toString(),
      email: '',
      contactType: 'Requestor',
    },
  ];

  const companyCodeRequestor = {
    id: progressData?.companyCreationProgress?.lastCompanyCodeRequest?.sender.id.toString(),
    name: progressData?.companyCreationProgress?.lastCompanyCodeRequest?.sender.displayName,
    graphId: progressData?.companyCreationProgress?.lastCompanyCodeRequest?.sender.toString(),
  };

  const buttonStyle = {
    root: { marginRight: 0, minWidth: '200px' },
    rootHovered: { border: 'none' },
  };

  const progressBarStyle = {
    root: { maxWidth: '50%' },
  };

  const renderActionButton = (label: string, formType: OverviewForm): JSX.Element => {
    return (
      <>
        {!isRequestReadOnly && !isShadowCompanyRequest && (
          <CanAccess requestedAction="CompanyCreation:Edit">
            <IconButton
              title={`Edit ${label}`}
              ariaLabel={label}
              iconProps={{ iconName: 'Edit' }}
              onClick={(): void => {
                setPanelState({
                  isEditPanelOpen: true,
                  currentForm: formType,
                });
              }}
            />
          </CanAccess>
        )}
      </>
    );
  };

  const renderEditCompanyCodeActionButton = (requestedAction: string): JSX.Element => {
    return (
      <>
        {!isRequestReadOnly && (
          <CanAccess requestedAction={requestedAction}>
            <IconButton
              title="Edit Company Code"
              ariaLabel="Company Code"
              iconProps={{ iconName: 'Edit' }}
              onClick={(): void => {
                setPanelState({
                  isEditPanelOpen: true,
                  currentForm: OverviewForm.CompanyCode,
                });
              }}
            />
          </CanAccess>
        )}
      </>
    );
  };

  const getSAPSetupLabel = (): JSX.Element => {
    return (
      <>
        <span>SAP Configuration Requirements Progress</span>
        <IconButton
          id="sapProgressButton"
          aria-describedby="tooltip"
          ariaLabel="SAP required fields"
          iconProps={{ iconName: 'Info' }}
          onClick={() => {
            setPanelState({
              isEditPanelOpen: true,
              currentForm: OverviewForm.SubmitSAPProgress,
            });
          }}
        />
      </>
    );
  };

  const getSubledgerSetupLabel = (): JSX.Element => {
    return (
      <>
        <span>Subledger Configuration Requirements Progress</span>
        <IconButton
          id="subledgerProgressButton"
          aria-describedby="tooltip"
          ariaLabel="Subledger required fields"
          iconProps={{ iconName: 'Info' }}
          onClick={() => {
            setPanelState({
              isEditPanelOpen: true,
              currentForm: OverviewForm.SubmitSubledgerProgress,
            });
          }}
        />
      </>
    );
  };

  const getStatSetupLabel = (): JSX.Element => {
    return (
      <>
        <span>Stat Tool Setup Progress</span>
        <IconButton
          id="setupProgressButton"
          aria-describedby="tooltip"
          ariaLabel="Stat Progress required fields"
          iconProps={{ iconName: 'Info' }}
          onClick={() => {
            setPanelState({
              isEditPanelOpen: true,
              currentForm: OverviewForm.SubmitStatProgress,
            });
          }}
        />
      </>
    );
  };

  const renderCompanyCodeEditButton = (): JSX.Element => {
    // Adding new company code
    if (
      !companyRequestInitialInfo?.companyCode &&
      companyRequestInitialInfo?.statusId === CompanyCreationStatus.CompanyCodeRequested
    ) {
      return renderEditCompanyCodeActionButton('CompanyCreation:Edit');
    }
    // Updating existing company code
    if (
      companyRequestInitialInfo?.companyCode &&
      companyRequestInitialInfo?.statusId !== CompanyCreationStatus.Canceled &&
      companyRequestInitialInfo?.statusId !== CompanyCreationStatus.Complete
    ) {
      return renderEditCompanyCodeActionButton('CompanyCreation:EditCompanyCode');
    }
    return <></>;
  };

  const renderShadowCompanyCodeActionButton = (): JSX.Element => {
    return (
      <>
        {!isRequestReadOnly &&
          (isShadowCompanyRequest ||
            progressData?.companyCreationProgress?.lastCompanyCodeRequest?.sentTime) && (
            <CanAccess
              requestedAction={
                isShadowCompanyRequest
                  ? 'CompanyCreation:CompleteShadowCompany'
                  : 'CompanyCreation:Edit'
              }
            >
              <IconButton
                title="Edit Shadow Company Code"
                ariaLabel="Shadow Company Code"
                iconProps={{ iconName: 'Edit' }}
                onClick={(): void => {
                  setPanelState({
                    isEditPanelOpen: true,
                    currentForm: OverviewForm.ShadowCompanyCode,
                  });
                }}
              />
            </CanAccess>
          )}
      </>
    );
  };

  return (
    <div className={`${bodyContentContainer} lightBackground`}>
      <Stack horizontal wrap horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
        {!isShadowCompanyRequest && (
          <StackColumn>
            <Card
              title="Company Code"
              headingLevel={2}
              isLoading={loading}
              actionButton={renderCompanyCodeEditButton()}
            >
              <Stack horizontal wrap horizontalAlign="space-between">
                <StackColumn>
                  <SpanWithLabel
                    labelText="Company Code"
                    value={companyRequestInitialInfo?.companyCode}
                  />
                </StackColumn>
              </Stack>
            </Card>
          </StackColumn>
        )}
        {!isNonTransactional && (
          <StackColumn>
            <Card
              title="Shadow Company Code"
              headingLevel={2}
              isLoading={loading}
              actionButton={renderShadowCompanyCodeActionButton()}
            >
              <StackColumn>
                <SpanWithLabel
                  labelText="Shadow Company Code"
                  value={companyRequestInitialInfo?.shadowCompanyCode}
                />
              </StackColumn>
            </Card>
          </StackColumn>
        )}
      </Stack>
      <br />
      <Card
        title="Initial Company Creation Info"
        headingLevel={2}
        isLoading={loading}
        actionButton={renderActionButton('Initial Company Creation Info', OverviewForm.InitialInfo)}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            {!isShadowCompanyRequest && (
              <>
                <SpanWithLabel
                  labelText="Confidential"
                  value={companyRequestInitialInfo?.isConfidential}
                />
                <SpanWithLabel
                  labelText="Acquisition Name"
                  value={companyRequestInitialInfo?.acquisition.text}
                />
                <SpanWithLabel
                  labelText="Acquisition Date"
                  value={momentUtc(
                    companyRequestInitialInfo?.dateOfAcquisition,
                    StatDateFormats.DayBreakout,
                    true,
                  )}
                />
              </>
            )}
            <SpanWithLabel
              labelText="Financial Type"
              value={companyRequestInitialInfo?.financialType.fieldName}
            />
            <SpanWithLabel
              labelText="Entity Type"
              value={companyRequestInitialInfo?.entityType.fieldName}
            />
            <SpanWithLabel
              labelText="Business Nature of Entity"
              value={companyRequestInitialInfo?.businessNatureOfEntity}
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel
              labelText="Status"
              value={companyRequestInitialInfo?.status.replace(/([A-Z])/g, ' $1').trim()}
            />
            <ServiceContactList labelText="Requestor" contacts={requestor} />
            {!isShadowCompanyRequest && (
              <ServiceContactList labelText="DAVA Deal Lead" contacts={davaDealLead} />
            )}
          </StackColumn>
        </Stack>
      </Card>
      <br />
      {!isShadowCompanyRequest && (
        <Card title="Company Creation Progress" headingLevel={2} isLoading={progressLoading}>
          <Stack wrap horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={progressBarStyle}>
              <ProgressIndicator
                label="Overall Progress"
                description={`${progressData?.companyCreationProgress?.overallPercentComplete}% Complete`}
                percentComplete={
                  progressData?.companyCreationProgress?.overallPercentComplete / 100 || 0
                }
              />
            </Stack.Item>
            {!isNonTransactional && (
              <>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                  tokens={{ childrenGap: 30 }}
                  wrap
                >
                  <Stack.Item grow styles={progressBarStyle}>
                    <ProgressIndicator
                      label={getSAPSetupLabel()}
                      description={`${progressData?.companyCreationProgress?.sapPercentComplete}% Complete`}
                      percentComplete={
                        progressData?.companyCreationProgress?.sapPercentComplete / 100 || 0
                      }
                    />
                  </Stack.Item>
                  <CanAccess requestedAction="CompanyCreation:RequestCompanyCode">
                    <Stack.Item>
                      <PrimaryButton
                        text="Request Company Code"
                        ariaLabel="Request Company Code"
                        styles={buttonStyle}
                        onClick={(): void => {
                          setPanelState({
                            isEditPanelOpen: true,
                            currentForm: OverviewForm.SubmitSAP,
                          });
                        }}
                        disabled={
                          companyRequestInitialInfo?.companyCode > 0 ||
                          progressData?.companyCreationProgress?.sapPercentComplete !== 100 ||
                          isRequestReadOnly
                        }
                      />
                    </Stack.Item>
                  </CanAccess>
                  <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                      <SpanWithLabel
                        labelText="Company Code Last Requested"
                        value={momentUtc(
                          progressData?.companyCreationProgress?.lastCompanyCodeRequest?.sentTime ||
                            '',
                          StatDateFormats.DayBreakout,
                          true,
                        )}
                      />
                      {progressData?.companyCreationProgress?.lastCompanyCodeRequest?.sentTime && (
                        <>
                          <span>By</span>
                          <Persona
                            key={companyCodeRequestor.id}
                            text={companyCodeRequestor.name}
                            size={PersonaSize.size40}
                          />
                        </>
                      )}
                    </Stack>
                  </Stack.Item>
                </Stack>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                  tokens={{ childrenGap: 30 }}
                  wrap
                >
                  <Stack.Item grow styles={progressBarStyle}>
                    <ProgressIndicator
                      label={getSubledgerSetupLabel()}
                      description={`${progressData?.companyCreationProgress?.subledgerPercentComplete}% Complete`}
                      percentComplete={
                        progressData?.companyCreationProgress?.subledgerPercentComplete / 100 || 0
                      }
                    />
                  </Stack.Item>
                  <CanAccess requestedAction="CompanyCreation:CompleteSubledger">
                    <Stack.Item>
                      <PrimaryButton
                        text="Complete Subledger Info"
                        ariaLabel="Complete Subledger Info"
                        styles={buttonStyle}
                        onClick={(): void => {
                          setPanelState({
                            isEditPanelOpen: true,
                            currentForm: OverviewForm.CompleteSubledger,
                          });
                        }}
                        disabled={
                          companyRequestInitialInfo?.statusId !==
                            CompanyCreationStatus.PendingSubledgerInfo ||
                          progressData?.companyCreationProgress?.subledgerPercentComplete !== 100 ||
                          isRequestReadOnly
                        }
                      />
                    </Stack.Item>
                  </CanAccess>
                  <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                    <SpanWithLabel
                      labelText="System Setup Sent"
                      value={momentUtc(
                        progressData?.companyCreationProgress?.lastSystemSetup?.sentTime || '',
                        StatDateFormats.DayBreakout,
                        true,
                      )}
                    />
                    {progressData?.companyCreationProgress?.lastSystemSetup?.sentTime && (
                      <>
                        <span>By</span>
                        <Persona
                          key={progressData?.companyCreationProgress?.lastSystemSetup?.sender?.id}
                          text={
                            progressData?.companyCreationProgress?.lastSystemSetup?.sender
                              ?.displayName
                          }
                          size={PersonaSize.size40}
                        />
                      </>
                    )}
                  </Stack>
                </Stack>
              </>
            )}
            <Stack
              horizontal
              horizontalAlign="start"
              verticalAlign="center"
              tokens={{ childrenGap: 30 }}
              wrap
            >
              <Stack.Item grow styles={progressBarStyle}>
                <ProgressIndicator
                  label={getStatSetupLabel()}
                  description={`${progressData?.companyCreationProgress?.statPercentComplete}% Complete`}
                  percentComplete={
                    progressData?.companyCreationProgress?.statPercentComplete / 100 || 0
                  }
                />
              </Stack.Item>
              <CanAccess requestedAction="CompanyCreation:Activate">
                <Stack.Item>
                  <PrimaryButton
                    text="Activate in Stat"
                    ariaLabel="Activate in Stat"
                    styles={buttonStyle}
                    onClick={(): void => {
                      setPanelState({
                        isEditPanelOpen: true,
                        currentForm: OverviewForm.ActivateCompany,
                      });
                    }}
                    disabled={
                      companyRequestInitialInfo?.statusId !==
                        CompanyCreationStatus.PendingStatToolSetup ||
                      progressData?.companyCreationProgress?.statPercentComplete !== 100 ||
                      isRequestReadOnly
                    }
                  />
                </Stack.Item>
              </CanAccess>
              <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                <SpanWithLabel
                  labelText="Date Activated in STAT"
                  value={momentUtc(
                    progressData?.companyCreationProgress?.dateActivated?.sentTime || '',
                    StatDateFormats.DayBreakout,
                    true,
                  )}
                />
                {progressData?.companyCreationProgress?.dateActivated?.sentTime && (
                  <>
                    <span>By</span>
                    <Persona
                      key={progressData?.companyCreationProgress?.dateActivated?.sender?.id}
                      text={
                        progressData?.companyCreationProgress?.dateActivated?.sender?.displayName
                      }
                      size={PersonaSize.size40}
                    />
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Card>
      )}
      {isShadowCompanyRequest && (
        <Card title="Shadow Company Info" headingLevel={2} isLoading={loading}>
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel
                labelText="Parent Company Code"
                value={companyRequestInitialInfo?.parentCompanyCode}
              />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel
                labelText="Shadow Company Name"
                value={companyRequestInitialInfo?.companyName}
              />
            </StackColumn>
          </Stack>
        </Card>
      )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.InitialInfo && (
        <NewCompanyPanel
          closePanel={closePanel}
          companyCreationRequest={companyRequestInitialInfo}
        />
      )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.SubmitSAP && (
        <SubmitToSapReviewPanel closePanel={closePanel} />
      )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.CompleteSubledger && (
        <CompleteSubledgerReviewPanel closePanel={closePanel} />
      )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.ActivateCompany && (
        <ActivateCompanyReviewPanel closePanel={closePanel} />
      )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.SubmitSAPProgress && (
        <SapProgressPanel closePanel={closePanel} />
      )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.CompanyCode && (
        <CompanyCodePanel
          closePanel={closePanel}
          companyCreationRequestData={companyRequestInitialInfo}
        />
      )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.SubmitStatProgress && (
        <StatProgressPanel
          closePanel={closePanel}
          companyCode={companyRequestInitialInfo?.companyCode}
          shadowCompanyCode={companyRequestInitialInfo?.shadowCompanyCode}
          isNonTransactional={isNonTransactional}
        />
      )}
      {panelState.isEditPanelOpen &&
        panelState.currentForm === OverviewForm.SubmitSubledgerProgress && (
          <SubledgerProgressPanel
            closePanel={closePanel}
            companyCode={companyRequestInitialInfo?.companyCode}
          />
        )}
      {panelState.isEditPanelOpen && panelState.currentForm === OverviewForm.ShadowCompanyCode && (
        <ShadowCompanyCodePanel
          closePanel={closePanel}
          companyCreationRequestData={companyRequestInitialInfo}
        />
      )}
    </div>
  );
};

export default OverviewPage;
