import { DocumentNode, useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';
import { GET_PROCESS_TYPE_CATEGORIES } from '../../../../utils/statApi/MilestoneTemplateApi';

import { IMilestoneCategory } from '../../../../utils/types/IProcessTemplate';
import ActionButtons from '../../../common/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IProcessTemplateTypeParams } from '../../../common/ParamTypes';
import MilestoneCategoriesForm from './MilestoneCategoriesForm';

interface IMilestoneCategoriesAddEditPanelProps {
  closePanel: () => void;
  category?: IMilestoneCategory;
  mutation: DocumentNode;
}

const MilestoneCategoriesAddEditPanel = (
  props: IMilestoneCategoriesAddEditPanelProps,
): JSX.Element => {
  const { closePanel, category, mutation } = props;
  const { processTypeId } = useParams<IProcessTemplateTypeParams>();
  const form = useForm<IMilestoneCategory>();
  const { handleSubmit } = form;
  const processTypeIdInt = parseInt(processTypeId, 10);
  const [saveCategory, { loading, error }] = useMutation(mutation, {
    onCompleted: () => {
      closePanel();
    },
    refetchQueries: [
      {
        query: GET_PROCESS_TYPE_CATEGORIES,
        variables: { processTypeId: processTypeIdInt },
      },
    ],
  });

  const handleOnSaveClick = (formData: IMilestoneCategory): void => {
    const { name } = formData;

    const milestoneCategoryDto = {
      id: category?.id || 0,
      name,
      processTypeId: processTypeIdInt,
    };

    saveCategory({
      variables: {
        milestoneCategoryDto,
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={category ? 'Edit Category' : 'Create Category'}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      closeButtonAriaLabel="Close"
      styles={CoherencePanelStyles}
      onRenderFooter={() => (
        <ActionButtons
          closePanel={closePanel}
          mutationLoading={loading}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Milestone Category"
          saveTitle="Save Milestone Category"
          cancelLabel="Cancel Milestone Category Save"
          cancelTitle="Cancel Milestone Category Save"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage loading={loading} error={error} actionName="saving the category" />
        <MilestoneCategoriesForm form={form} category={category} />
      </form>
    </CoherencePanel>
  );
};

export default MilestoneCategoriesAddEditPanel;
