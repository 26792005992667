import { gql } from '@apollo/client';

const GET_FEATURE_FLAGS = gql`
  query FeatureFlags {
    featureFlags {
      flags
      outageBannerMessage
      currentUiVersion
    }
  }
`;
export default GET_FEATURE_FLAGS;
