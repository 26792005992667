import React from 'react';
import { IconButton, Shimmer } from '@fluentui/react';
import { useHistory, useParams } from 'react-router-dom';
import CompanyHeaderItems from '../../../utils/types/CompanyHeaderItems';
import ICompany from '../../../utils/types/ICompany';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import PivotHeader from '../../common/headers/PivotHeader';

interface ICompanyHeaderProps {
  company: ICompany;
  showBackToListButton?: boolean;
}

const CompanyHeader = (props: ICompanyHeaderProps): JSX.Element => {
  const { company, showBackToListButton } = props;
  const { sapName, physicalCountry } = company || {};
  const { companyCode } = useParams<ICompanyCodeParams>();
  const history = useHistory();

  // mobile menu onClick
  // render header title
  // We need to have some sort of shimmer on load
  const renderHeaderTitle = (): JSX.Element => {
    if (companyCode && sapName) {
      return (
        <h1>
          {showBackToListButton && (
            <IconButton
              iconProps={{ iconName: 'Back' }}
              styles={{ root: { marginRight: 10 } }}
              title="Back to companies list"
              ariaLabel="Back to companies list"
              onClick={(): void => {
                history.push(`/companies/`);
              }}
            />
          )}

          {`${companyCode} - ${sapName} (${physicalCountry})`}
        </h1>
      );
    }
    return (
      <Shimmer
        styles={{
          root: { marginBottom: '10px' },
        }}
      />
    );
  };

  return (
    <PivotHeader
      getHeaderTitle={renderHeaderTitle}
      pivotHeaderItems={CompanyHeaderItems}
      baseUri={`/companies/${companyCode}`}
      ariaLabel="Navigation for Company Details"
    />
  );
};
export default CompanyHeader;
