import { ChoiceGroup, Dropdown, IDropdownOption, Label, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { RequiredMarker } from '../../common/formFields/RequiredMarker';
import RequiredFieldsNotification from '../RequiredNotification';
import CompanyContactsPicker from './CompanyContactsPicker';
import {
  CompanyCreationContactType,
  ICompanyCreationContact,
  ISubledgerInfo,
  RequiredFieldsType,
} from './types';
import { yesNoOptions } from '../../../utils/types/CommonDropdownOptions';

interface ISubledgerInfoFormProps {
  form: UseFormMethods<ISubledgerInfo>;
  data: ISubledgerInfo;
}

const requiredLabelStyles = { paddingBottom: 0 };

const workSchedule: IDropdownOption[] = [
  { key: null, text: '--' },
  { key: 48, text: '48' },
  { key: 44, text: '44' },
  { key: 42.5, text: '42.5' },
  { key: 42, text: '42' },
  { key: 40, text: '40' },
  { key: 39, text: '39' },
  { key: 38.75, text: '38.75' },
  { key: 38.5, text: '38.5' },
  { key: 37.5, text: '37.5' },
  { key: 37, text: '37' },
];

const SubledgerInfoForm = ({ form, data }: ISubledgerInfoFormProps): JSX.Element => {
  const { control, errors, setValue, watch } = form;

  const willHaveHeadcount = watch('willHaveHeadcount', data?.willHaveHeadcount === true);

  const updateForm = (
    formKey: keyof ISubledgerInfo,
    contacts: ICompanyCreationContact[],
    contactType: CompanyCreationContactType,
  ): void => {
    const newContacts = [] as ICompanyCreationContact[];
    contacts.forEach((contact) => {
      contact.contactType = contactType;
      newContacts.push(contact);
    });
    setValue(formKey, newContacts[0]);
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <RequiredFieldsNotification type={RequiredFieldsType.SubledgerSetup} />
      <Controller
        name="isDomesticEntity"
        defaultValue={data?.isDomesticEntity?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Is this a new domestic entity? <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="isDomesticEntity"
                name="isDomesticEntity"
                options={yesNoOptions}
                defaultSelectedKey={data?.isDomesticEntity?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('isDomesticEntity', newValue.key === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="willHaveIntercompanyActivity"
        defaultValue={data?.willHaveIntercompanyActivity?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Will the company have inter-company transactions with other Microsoft entities?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="willHaveIntercompanyActivity"
                name="willHaveIntercompanyActivity"
                options={yesNoOptions}
                defaultSelectedKey={data?.willHaveIntercompanyActivity?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('willHaveIntercompanyActivity', newValue.key === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="willRequireMscPremierServices"
        defaultValue={data?.willRequireMscPremierServices?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Will the company require billing for MSC/Premier Services?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="willRequireMscPremierServices"
                name="willRequireMscPremierServices"
                options={yesNoOptions}
                defaultSelectedKey={data?.willRequireMscPremierServices?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('willRequireMscPremierServices', newValue.key === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="willHaveHeadcount"
        defaultValue={data?.willHaveHeadcount?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Will the company have headcount? <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="willHaveHeadcount"
                name="willHaveHeadcount"
                options={yesNoOptions}
                defaultSelectedKey={data?.willHaveHeadcount?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('willHaveHeadcount', newValue.key === 'true');
                  if (newValue.key === 'false') {
                    setValue('personalSubareaCities', '');
                    setValue('weeklyWorkingHours', null);
                  }
                }}
              />
            </>
          );
        }}
      />
      <Label style={requiredLabelStyles}>
        Personnel Subarea {willHaveHeadcount ? <RequiredMarker /> : null}
      </Label>
      <Controller
        as={TextField}
        id="personalSubareaCities"
        name="personalSubareaCities"
        control={control}
        defaultValue={data?.personalSubareaCities || ''}
        disabled={willHaveHeadcount === false}
        errorMessage={errors?.personalSubareaCities?.message}
        ariaLabel="Personnel Subarea Cities"
      />
      <Controller
        name="weeklyWorkingHours"
        control={control}
        defaultValue={data?.weeklyWorkingHours}
        render={({ value }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Work Schedule (# of hours per week) {willHaveHeadcount ? <RequiredMarker /> : null}
            </Label>
            <Dropdown
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
              ): void => {
                setValue('weeklyWorkingHours', option.key);
              }}
              ariaLabel="Work Schedule"
              options={workSchedule}
              disabled={willHaveHeadcount === false}
              selectedKey={value}
              placeholder="Select an option"
            />
          </>
        )}
      />
      <Controller
        name="willNeedFaModule"
        defaultValue={data?.willNeedFaModule?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Will the company need the FA module?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="willNeedFaModule"
                name="willNeedFaModule"
                options={yesNoOptions}
                defaultSelectedKey={data?.willNeedFaModule?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('willNeedFaModule', newValue.key === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="willNeedApMmModule"
        defaultValue={data?.willNeedApMmModule?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Will the company need the AP/MM module?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="willNeedApMmModule"
                name="willNeedApMmModule"
                options={yesNoOptions}
                defaultSelectedKey={data?.willNeedApMmModule?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('willNeedApMmModule', newValue.key === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="willTransmitToMsSales"
        defaultValue={data?.willTransmitToMsSales?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Will the new company transmit sales to MS Sales?
                <RequiredMarker />
              </Label>
              <ChoiceGroup
                id="willTransmitToMsSales"
                name="willTransmitToMsSales"
                options={yesNoOptions}
                defaultSelectedKey={data?.willTransmitToMsSales?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('willTransmitToMsSales', newValue.key === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="bsReconOwner"
        valueKey="bsReconOwner"
        control={control}
        defaultValue={data?.bsReconOwner}
        render={() => {
          return (
            <>
              <Label htmlFor="bsReconOwnerPicker" style={requiredLabelStyles}>
                Balance Sheet Recon Owner <RequiredMarker />
              </Label>
              <CompanyContactsPicker
                controlName="bsReconOwner"
                removeButtonAriaLabel="Remove Balance Sheet Recon Owner"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm('bsReconOwner', contacts, CompanyCreationContactType.BsReconOwner);
                }}
                itemLimit={1}
                errorMessage={(errors?.bsReconOwner as unknown as FieldError)?.message}
                defaultValue={data?.bsReconOwner ? [{ ...data?.bsReconOwner }] : []}
              />
            </>
          );
        }}
      />
      <Controller
        name="bsReconReviewer"
        valueKey="bsReconReviewer"
        control={control}
        defaultValue={data?.bsReconReviewer}
        render={() => {
          return (
            <>
              <Label htmlFor="bsReconReviewerPicker" style={requiredLabelStyles}>
                Balance Sheet Recon Reviewer <RequiredMarker />
              </Label>
              <CompanyContactsPicker
                controlName="bsReconReviewer"
                removeButtonAriaLabel="Remove Balance Sheet Recon Reviewer"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm(
                    'bsReconReviewer',
                    contacts,
                    CompanyCreationContactType.BsReconReviewer,
                  );
                }}
                itemLimit={1}
                errorMessage={(errors?.bsReconReviewer as unknown as FieldError)?.message}
                defaultValue={data?.bsReconReviewer ? [{ ...data?.bsReconReviewer }] : []}
              />
            </>
          );
        }}
      />
      <Controller
        name="bsReconController"
        valueKey="bsReconController"
        control={control}
        defaultValue={data?.bsReconController}
        render={() => {
          return (
            <>
              <Label htmlFor="bsReconControllerPicker" style={requiredLabelStyles}>
                Balance Sheet Recon Controller <RequiredMarker />
              </Label>
              <CompanyContactsPicker
                controlName="bsReconController"
                removeButtonAriaLabel="Remove Balance Sheet Recon Controller"
                onChange={(contacts: ICompanyCreationContact[]): void => {
                  updateForm(
                    'bsReconController',
                    contacts,
                    CompanyCreationContactType.BsReconController,
                  );
                }}
                itemLimit={1}
                errorMessage={(errors?.bsReconController as unknown as FieldError)?.message}
                defaultValue={data?.bsReconController ? [{ ...data?.bsReconController }] : []}
              />
            </>
          );
        }}
      />
    </Stack>
  );
};

export default SubledgerInfoForm;
