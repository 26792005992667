import { useQuery } from '@apollo/client';
import React from 'react';
import GET_AUDITING_FIRMS_QUERY from '../../../../utils/statApi/AuditingFirmApi';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';

const AuditingFirmsDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey, ariaLabel } = props;
  const { loading, data } = useQuery(GET_AUDITING_FIRMS_QUERY);
  const { auditingFirms } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      ariaLabel={ariaLabel}
      outputKey={outputKey}
      dropdownOptionsData={auditingFirms}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
    />
  );
};

export default AuditingFirmsDropdown;
