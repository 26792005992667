import { ActionButton } from '@fluentui/react';
import React, { useState } from 'react';
import { UPDATE_MILESTONE_CATEGORY } from '../../../../utils/statApi/MilestoneTemplateApi';
import { IMilestoneCategory } from '../../../../utils/types/IProcessTemplate';
import MilestoneCategoriesAddEditPanel from './MilestoneCategoriesAddEditPanel';

interface IMilestoneCategoriesEditButtonProps {
  item: IMilestoneCategory;
}

const MilestoneCategoriesEditButton = (props: IMilestoneCategoriesEditButtonProps): JSX.Element => {
  const { item } = props;

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = (): void => {
    setIsPanelOpen(!isPanelOpen);
  };

  const editMutation = UPDATE_MILESTONE_CATEGORY;

  return (
    <>
      <ActionButton iconProps={{ iconName: 'Edit' }} onClick={togglePanel} label="Edit Category">
        Edit Category
      </ActionButton>
      {isPanelOpen && (
        <MilestoneCategoriesAddEditPanel
          closePanel={togglePanel}
          mutation={editMutation}
          category={item}
        />
      )}
    </>
  );
};

export default MilestoneCategoriesEditButton;
