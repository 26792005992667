import moment from 'moment';
import StatDateFormats from './types/DateFormats';

const momentUtc = (
  date: Date | string | number,
  format = StatDateFormats.DaySimple,
  isDisplayOnly = false,
): string => {
  if (!date && isDisplayOnly) {
    return '--';
  }
  const parsedDate = moment(date).utc().format(format);
  return parsedDate === 'Invalid date' && isDisplayOnly ? '--' : parsedDate;
};

export const nextMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return date;
};

export const datediff = (date1: string, date2: string) => {
  if (!date1 || !date2) return '';
  const a = new Date(date1);
  const b = new Date(date2);

  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  const diffInDays = Math.floor((utc2 - utc1) / MS_PER_DAY);
  if (diffInDays === 0) return '(1 day)';
  return `(${diffInDays + 1} days)`;
};
export const formatDate = (date: string) => {
  if (!date) return '';
  const asDate = new Date(date);
  return asDate.toLocaleDateString();
};
export const formatDateReadable = (date: string) => {
  if (!date) return '';
  const asDate = new Date(date);
  return asDate.toLocaleString();
};

export default momentUtc;
