import React from 'react';
import { siteHeroContent } from '../../../app/common/styles/CommonStyleObjects';

interface ErrorComponentProps {
  className: string;
  headerMessage: string;
  contentMessage: string;
  actionButtonsElement: React.ReactElement;
}

const SiteError: React.FunctionComponent<ErrorComponentProps> = (props: ErrorComponentProps) => {
  const { className, headerMessage, contentMessage, actionButtonsElement } = props;
  const Styles = siteHeroContent();
  return (
    <div className={className}>
      <h1 className={Styles.largeHeroHeader}>{headerMessage}</h1>
      <p className={Styles.content}>{contentMessage}</p>
      <div className={Styles.actionItemsWrapper}>{actionButtonsElement}</div>
    </div>
  );
};
export default SiteError;
