import { gql } from '@apollo/client';
import { COMPANY_DOCUMENT_FRAGMENT } from './CompanyCreationApi';

export const GET_DOCUMENT_ANALYSIS_LIST = gql`
  query getDocumentAnalysisRuns($searchDto: DocumentAnalysisRunSearchDtoInput) {
    documentAnalysisRuns(ssearchDto: $searchDto) {
      id
      document {
        ...CompanyDocument
      }
      runStatus
      created
      completed
    }
  }
  ${COMPANY_DOCUMENT_FRAGMENT}
`;

export const GET_DOCUMENTS_TO_ANALYZE = gql`
  query getDocumentsForAnalysis(
    $skip: Int
    $take: Int
    $searchDto: DocumentSearchDtoInput
    $order: [DocumentAnalysisLinkDtoSortInput!]
  ) {
    documentsForAnalysis(skip: $skip, take: $take, searchDto: $searchDto, order: $order) {
      items {
        id
        document {
          ...CompanyDocument
        }
        fiscalYear
        documentType
        companyId
        companyName
        companyCode
        processId
        processTypeUri
        timesAnalyzed
        hasAnomaly
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }

  ${COMPANY_DOCUMENT_FRAGMENT}
`;

export const ANALYZE_DOCUMENTS = gql`
  mutation createDocumentAnalysisRuns($createAnalysisRunsDto: CreateAnalysisRunsDtoInput!) {
    createDocumentAnalysisRuns(createAnalysisRunsDto: $createAnalysisRunsDto) {
      id
    }
  }
`;

export const GET_DOCUMENT_ANALYSIS_RUN = gql`
  query documentAnalysisRun($id: Int!) {
    documentAnalysisRun(id: $id) {
      id
      status
      created
      completed
      document {
        ...CompanyDocument
      }
      analysisOutputJson
      steps {
        id
        name
        created
        status
      }
    }
  }

  ${COMPANY_DOCUMENT_FRAGMENT}
`;

export const GET_RUNS_FOR_DOCUMENT = gql`
  query documentAnalysisRuns($id: Int!) {
    documentAnalysisRuns(id: $id) {
      id
      document {
        ...CompanyDocument
      }
      runs {
        id
        document {
          ...CompanyDocument
        }
        runStatus
        created
        completed
        steps {
          id
          name
          created
          status
        }
      }
    }
  }

  ${COMPANY_DOCUMENT_FRAGMENT}
`;
