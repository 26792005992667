import React, { useState } from 'react';
import { Stack, IconButton, Link } from '@fluentui/react';
import ICompany from '../../../utils/types/ICompany';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import StatDateFormats from '../../../utils/types/DateFormats';
import Card from '../../common/card/Card';
import CompanyFormType from './forms/CompanyFormTypeEnum';
import StackColumn from '../../common/StackColumn';
import SpanWithLabel from '../../common/SpanWithLabel';
import CanAccess from '../../common/canAccess/CanAccess';
import momentUtc from '../../../utils/DateFormatter';
import monthDayFormat from '../../../utils/MonthDayFormatter';
import CompanyBusinessNature from './CompanyBusinessNaturePanel';
import { ICompanyBusinessNature } from '../../../utils/types/ICompanyBusinessNature';
import CompanyAddressPanel from './CompanyAddressPanel';
import { ICompanyLegalAddress } from '../../../utils/types/ICompanyLegalAddress';
import IIncorporationHierarchyInfo from '../../../utils/types/IIncorporationHierarchyInfo';
import CompanyIncorporationHierarchyInfoPanel from './CompanyIncorporationHierarchyInfoPanel';
import { ICompanyNameSapInfo } from '../../../utils/types/ICompanyNameSapInfo';
import CompanyNameSapInfoPanel from './CompanyNameSapInfoPanel';
import { ICompanySapFinancialInfo } from '../../../utils/types/ICompanySapFinancialInfo';
import CompanySapFinancialInfoPanel from './CompanySapFinancialInfoPanel';
import ICompanyFinancialInfo from '../../../utils/types/ICompanyFinancialInfo';
import CompanyFinancialInfoPanel from './CompanyFinancialInfoPanel';
import CompanyLegalEntityTerminationPanel from './CompanyLegalEntityTerminationPanel';
import ICompanyLegalEntityTermination from '../../../utils/types/ICompanyLegalEntityTermination';
import ServiceContactList from '../serviceContacts/ServiceContactList';
import { TARGETFORTERMINATION } from '../../../app/common/constants/SiteConstants';

export interface ICompanyDetailGeneralProps {
  company: ICompany;
}

const toolTipOverflowStyle: React.CSSProperties = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  width: '80%',
};

const CompanyDetailGeneral = (props: ICompanyDetailGeneralProps): JSX.Element => {
  const { company } = props;
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [editForm, setCurrentEditForm] = useState<CompanyFormType>();
  const togglePanel = (): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  const businessNature: ICompanyBusinessNature = {
    id: company.id,
    controllerMustBeFte: company.controllerMustBeFte,
    businessNatureOfEntity: company.businessNatureOfEntity,
    entityType: {
      id: company.entityType?.id,
      fieldName: company.entityType?.fieldName,
    },
    isAcquisition: company.acquisition !== null,
    acquisition: {
      key: company.acquisition?.id,
      text: company.acquisition?.fieldName,
    },
    dateOfAcquisition: company.dateOfAcquisition,
    entityStatus: {
      id: company.entityStatus?.id,
      fieldName: company.entityStatus?.fieldName,
    },
    lifecycleStatus: {
      id: company.lifecycleStatus?.id,
      fieldName: company.lifecycleStatus?.fieldName,
    },
    effectiveDateOfStatusChange: company.effectiveDateOfStatusChange,
  };

  const address: ICompanyLegalAddress = {
    id: company.id,
    legallyRegisteredAddress: {
      streetNumber: company.streetNumber,
      streetNumber2: company.streetNumber2,
      city: company.city,
      postalZipCode: company.zipCode,
      stateProvinceRegion: {
        id: company.stateProvinceCode,
        name: company.state,
      },
      country: {
        id: company.incorporatedCountryCode,
        shortName: company.incorporatedCountry,
      },
    },
  };

  const incorporationHierarchyInfo: IIncorporationHierarchyInfo = {
    id: company.id,
    companyCode: company.companyCode,
    incorporatedDate: company.incorporatedDate,
    wwArea: company.wwArea,
    region: company.region,
  };

  const nameAndSapInfo: ICompanyNameSapInfo = {
    id: company.id,
    companyCode: company.companyCode,
    legalFullName: company.entityLegalName,
    sapLegalName: company.sapLegalName,
    sapNameShortForm: company.sapNameShortForm,
    sapRegion: {
      id: company.sapRegionCode,
      name: company.sapRegion,
    },
    physicalCountry: {
      id: company.physicalCountryCode,
      shortName: company.physicalCountry,
    },
  };

  const sapFinancialInfo: ICompanySapFinancialInfo = {
    id: company.id,
    shadowCompany: company.shadowCompany,
    sapLevel: company.sapLevel,
  };

  const financialInfo: ICompanyFinancialInfo = {
    id: company.id,
    statutoryYearEndDay: company.statutoryYearEndDay,
    statutoryYearEndMonth: company.statutoryYearEndMonth,
    financialType: company.financialType,
    statutoryCurrency: company.statutoryCurrency,
    functionalCurrency: company.functionalCurrency,
    salesLocationForReporting: {
      id: company?.salesLocationForReporting?.id,
      name: company?.salesLocationForReporting?.name,
    },
    taxJurisdictionCode: company.taxJurisdictionCode,
  };

  const legalEntityTerminationInfo: ICompanyLegalEntityTermination = {
    id: company.id,
    isTargetForTermination: company.lifecycleStatus?.fieldName === TARGETFORTERMINATION,
    legalEntityTerminationNotes: company.legalEntityTerminationNotes,
    terminationLifecycleStatus: company?.terminationLifecycleStatus,
    legoCategory: company?.legoCategory,
    intakeMethod: company?.intakeMethod,
    terminationContacts: company.terminationContacts,
    celaTermContact: company.celaTermContact,
    anticipatedTerminationFY: company.anticipatedTerminationFY,
    anticipatedTerminationQuarter: company.anticipatedTerminationQuarter,
    accountingPlanStarted: company.accountingPlanStarted,
  };

  const renderActionButton = (label: string, formType: CompanyFormType): JSX.Element => {
    return (
      <CanAccess requestedAction="CompanyInformation:Edit">
        <IconButton
          title={`Edit ${label}`}
          ariaLabel={label}
          iconProps={{ iconName: 'Edit' }}
          onClick={(): void => {
            setCurrentEditForm(formType);
            togglePanel();
          }}
        />
      </CanAccess>
    );
  };

  return (
    <div className={`${bodyContentContainer} lightBackground`}>
      <Card
        title="Name & SAP Info"
        headingLevel={2}
        actionButton={renderActionButton('Name & SAP Info', CompanyFormType.NameAndSapInfo)}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Company Code:" value={company.companyCode} />
            <SpanWithLabel labelText="Legal Full Name:" value={company.entityLegalName} />
            <SpanWithLabel labelText="SAP Legal Name:" value={company.sapLegalName} />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="SAP Name (Short Form):" value={company.sapNameShortForm} />
            <SpanWithLabel labelText="SAP Region:" value={company.sapRegion} />
            <SpanWithLabel
              labelText="Country of Physical Location:"
              value={company.physicalCountry}
            />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card
        title="Legal Address"
        actionButton={renderActionButton('Legal Address', CompanyFormType.Address)}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Street:" value={company.streetNumber || company.poBox} />
            <SpanWithLabel labelText="Street 2:" value={company.streetNumber2} />
            <SpanWithLabel labelText="City:" value={company.city} />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel
              labelText="State/Province/Region:"
              value={company.state ? `${company.state} - ${company.stateProvinceCode}` : null}
            />
            <SpanWithLabel labelText="Postal/Zip Code:" value={company.zipCode} />
            <SpanWithLabel labelText="Country:" value={company.incorporatedCountry} />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card
        title="Incorporation / Hierarchy Information"
        actionButton={renderActionButton(
          'Incorporation / Hierarchy Information',
          CompanyFormType.IncorpHierarchy,
        )}
        headingLevel={2}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="Incorporated Date:"
              value={momentUtc(company.incorporatedDate, StatDateFormats.DayBreakout, true)}
            />
            <SpanWithLabel labelText="WW Area:" value={company.wwArea} />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Region:" value={company.region} />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card
        title="Business Nature & Status"
        actionButton={renderActionButton(
          'Business Nature & Status',
          CompanyFormType.BusinessStatus,
        )}
        headingLevel={2}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Entity Type:" value={company.entityType?.fieldName} />
            <SpanWithLabel labelText="Legal Status:" value={company.entityStatus?.fieldName} />
            <SpanWithLabel
              labelText="Lifecycle Status:"
              value={company.lifecycleStatus?.fieldName}
            />
            <SpanWithLabel
              labelText="Effective Date of Status Change:"
              value={momentUtc(
                company?.effectiveDateOfStatusChange,
                StatDateFormats.DayBreakout,
                true,
              )}
            />
            <SpanWithLabel
              labelText="Entity Status Modified Date:"
              value={momentUtc(
                company?.entityStatusModifiedDate,
                StatDateFormats.DayBreakout,
                true,
              )}
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Acquisition:" value={company.acquisition?.fieldName} />
            <SpanWithLabel
              labelText="Acquisition Date:"
              value={momentUtc(company?.dateOfAcquisition, StatDateFormats.DayBreakout, true)}
            />
            <SpanWithLabel
              labelText="Business Nature of Entity:"
              value={company.businessNatureOfEntity}
            />
            <SpanWithLabel
              labelText="Controller Must Be FTE:"
              value={company.controllerMustBeFte}
            />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card
        title="Financial Information"
        headingLevel={2}
        actionButton={renderActionButton('Financial Information', CompanyFormType.FinancialInfo)}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel
              labelText="Statutory Year End:"
              value={monthDayFormat(company?.statutoryYearEndMonth, company?.statutoryYearEndDay)}
            />
            <SpanWithLabel labelText="Financial Type:" value={company.financialType} />
            <SpanWithLabel
              labelText="Statutory Reporting Currency:"
              value={company.statutoryCurrency}
            />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="Functional Currency:" value={company.functionalCurrency} />
            <SpanWithLabel
              labelText="Sales Location for MS Reporting:"
              value={
                company?.salesLocationForReporting?.id
                  ? `${company?.salesLocationForReporting?.name} - ${company?.salesLocationForReporting?.id}`
                  : null
              }
            />
            <SpanWithLabel labelText="Tax Jurisdiction Code:" value={company.taxJurisdictionCode} />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <Card
        title="SAP Financial Information"
        headingLevel={2}
        actionButton={renderActionButton(
          'SAP Financial Information',
          CompanyFormType.SapFinanicalInfo,
        )}
      >
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <SpanWithLabel labelText="Shadow Company:" value={company.shadowCompany} />
          </StackColumn>
          <StackColumn>
            <SpanWithLabel labelText="SAP Level:" value={company.sapLevel?.fieldName} />
          </StackColumn>
        </Stack>
      </Card>
      <br />
      <CanAccess requestedAction="EntityTermination:Edit">
        <Card
          title="Legal Entity Termination Details"
          actionButton={renderActionButton(
            'Legal Entity Termination Details',
            CompanyFormType.EntityTermination,
          )}
          headingLevel={2}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel
                labelText="Termination Lifecycle Status:"
                value={company.terminationLifecycleStatus?.fieldName}
              />
              <SpanWithLabel labelText="LEGO Category:" value={company.legoCategory?.fieldName} />
              <SpanWithLabel
                labelText="Anticipated Termination FY:"
                value={company.anticipatedTerminationFY}
              />
              <SpanWithLabel
                labelText="Anticipated Termination Quarter:"
                value={company.anticipatedTerminationQuarter}
              />
              <SpanWithLabel labelText="Accounting Plan Started:">
                <>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={company.accountingPlanStarted}
                    style={toolTipOverflowStyle}
                  >
                    {company.accountingPlanStarted}
                  </Link>
                </>
              </SpanWithLabel>
            </StackColumn>
            <StackColumn>
              <ServiceContactList
                labelText="Termination Contacts:"
                contacts={company.terminationContacts ? company.terminationContacts : []}
              />
              <ServiceContactList
                labelText="CELA Term Contact:"
                contacts={company.celaTermContact ? [company.celaTermContact] : []}
              />
              <SpanWithLabel labelText="Intake Method:" value={company.intakeMethod?.fieldName} />
              <SpanWithLabel labelText="Notes:" value={company.legalEntityTerminationNotes} />
            </StackColumn>
          </Stack>
        </Card>
      </CanAccess>
      {isEditPanelOpen && editForm === CompanyFormType.BusinessStatus && (
        <CompanyBusinessNature data={businessNature} closePanel={togglePanel} />
      )}
      {isEditPanelOpen && editForm === CompanyFormType.Address && (
        <CompanyAddressPanel data={address} closePanel={togglePanel} />
      )}
      {isEditPanelOpen && editForm === CompanyFormType.IncorpHierarchy && (
        <CompanyIncorporationHierarchyInfoPanel
          data={incorporationHierarchyInfo}
          closePanel={togglePanel}
        />
      )}
      {isEditPanelOpen && editForm === CompanyFormType.NameAndSapInfo && (
        <CompanyNameSapInfoPanel data={nameAndSapInfo} closePanel={togglePanel} />
      )}
      {isEditPanelOpen && editForm === CompanyFormType.SapFinanicalInfo && (
        <CompanySapFinancialInfoPanel data={sapFinancialInfo} closePanel={togglePanel} />
      )}
      {isEditPanelOpen && editForm === CompanyFormType.FinancialInfo && (
        <CompanyFinancialInfoPanel data={financialInfo} closePanel={togglePanel} />
      )}
      {isEditPanelOpen && editForm === CompanyFormType.EntityTermination && (
        <CompanyLegalEntityTerminationPanel
          data={legalEntityTerminationInfo}
          closePanel={togglePanel}
        />
      )}
    </div>
  );
};
export default CompanyDetailGeneral;
