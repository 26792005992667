import { ColumnActionsMode, IColumn } from '@fluentui/react';
import { rowClasses } from '../../../app/common/styles/CommonStyleObjects';

interface IProcessConfigListColumns {
  renderNameColumn: (item: { id: number; name: string }) => JSX.Element;
  renderDateColumn: (item: { id: number; recurrenceEndDate: string }) => JSX.Element;
}

const getColumns = ({
  renderNameColumn,
  renderDateColumn,
}: IProcessConfigListColumns): Array<IColumn> => {
  return [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 175,
      maxWidth: 350,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      isMultiline: true,
      className: rowClasses.medFont,
      onRender: renderNameColumn,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processType',
      name: 'Process Type',
      fieldName: 'processType',
      minWidth: 175,
      maxWidth: 225,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processTemplateName',
      name: 'Template Name',
      fieldName: 'processTemplateName',
      minWidth: 175,
      maxWidth: 275,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      isMultiline: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'recurrence',
      name: 'Recurrence',
      fieldName: 'recurrence',
      minWidth: 175,
      maxWidth: 175,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'deactivateDate',
      name: 'Deactivate Date',
      fieldName: 'recurrenceEndDate',
      minWidth: 175,
      maxWidth: 175,
      isRowHeader: false,
      data: 'number',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: renderDateColumn,
    },
  ];
};

export default getColumns;
