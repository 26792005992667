import { IColumn, ColumnActionsMode, ActionButton } from '@fluentui/react';
import React from 'react';
import { rowClasses } from '../../../../app/common/styles/CommonStyleObjects';
import { IMilestoneTemplate } from '../../../../utils/types/IMilestoneTemplate';
import CanAccess from '../../../common/canAccess/CanAccess';

const getMilestoneTemplateColumns = (
  setCurrentMilestone: React.Dispatch<React.SetStateAction<IMilestoneTemplate>>,
  setIsEditPanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDetailsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
): IColumn[] => {
  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 175,
      maxWidth: 350,
      isRowHeader: false,
      data: 'string',
      isPadded: true,
      isMultiline: true,
      className: rowClasses.medFont,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];

  columns.push({
    key: 'milestoneCategoryName',
    name: 'Category',
    fieldName: 'milestoneCategoryName',
    minWidth: 175,
    maxWidth: 350,
    isRowHeader: false,
    data: 'string',
    isPadded: true,
    isMultiline: true,
    className: rowClasses.medFont,
    columnActionsMode: ColumnActionsMode.disabled,
  });

  columns.push({
    key: 'id',
    name: 'Actions',
    fieldName: 'id',
    minWidth: 175,
    maxWidth: 350,
    isRowHeader: false,
    data: 'string',
    isPadded: true,
    isMultiline: true,
    className: rowClasses.medFont,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: IMilestoneTemplate): JSX.Element => (
      <>
        <ActionButton
          iconProps={{ iconName: 'KnowledgeArticle' }}
          onClick={() => {
            setCurrentMilestone(item);
            setIsDetailsPanelOpen(true);
          }}
          label="Milestone Details"
        >
          Milestone Details
        </ActionButton>
        <CanAccess requestedAction="MilestoneTemplate:Edit">
          <ActionButton
            iconProps={{ iconName: 'Edit' }}
            onClick={() => {
              setCurrentMilestone(item);
              setIsEditPanelOpen(true);
            }}
            label="Edit Milestone"
          >
            Edit Milestone
          </ActionButton>
        </CanAccess>
      </>
    ),
  });

  return columns;
};

export default getMilestoneTemplateColumns;
