import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { PrimaryButton, Stack, Spinner, StackItem, Checkbox } from '@fluentui/react';
import { IMilestoneListActionItemProps, MilestoneActionType } from '../Interfaces';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import SIGNOFF_MILESTONE_MUTATION from '../../../../utils/statApi/MilestoneApi';
import { buttonSpinnerStyles, milestoneListItemActionWrapper } from '../processStyles';

const isAttestingText = 'Saving the attestation';

const MilestoneSignoffAction = ({
  milestone,
  submit,
}: IMilestoneListActionItemProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(false);

  const [updateMilestone, { loading, error }] = useMutation(SIGNOFF_MILESTONE_MUTATION, {
    onCompleted: () => {
      submit(MilestoneActionType.normal);
    },
  });

  const submitMilestoneAction = (): void => {
    updateMilestone({ variables: { milestoneId: milestone.id } });
  };

  return (
    <Stack horizontalAlign="center">
      <LoadingErrorMessage error={error} loading={loading} actionName={isAttestingText} />
      <StackItem className={milestoneListItemActionWrapper}>
        <Checkbox
          checked={isChecked}
          label={milestone.attestationText}
          onChange={(): void => {
            setIsChecked(!isChecked);
          }}
        />
      </StackItem>
      <PrimaryButton
        text="Submit"
        type="submit"
        onClick={submitMilestoneAction}
        disabled={loading || !isChecked}
        onRenderIcon={(): JSX.Element => {
          return loading && <Spinner styles={buttonSpinnerStyles} />;
        }}
      />
    </Stack>
  );
};

export default MilestoneSignoffAction;
