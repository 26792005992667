import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import Collapsible from './Collapsible';

import ProcessSearchFiscalYearPicker from '../../processes/processSearch/ProcessSearchFiscalYearPicker';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { formatDate } from '../../../utils/DateFormatter';

interface IComponentProps {
  dataKey: string;
  label: string;
  initialValues?: IGenericFilterTag;
  onSelectedItems?: (items: IGenericFilterTag) => void;
}

const FiscalYearCollapsiblePicker: React.FC<IComponentProps> = ({
  initialValues,
  dataKey,
  label,
  onSelectedItems,
}) => {
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedMode, setSelectionMode] = useState('customRange');

  useEffect(() => {
    if (initialValues && initialValues?.values?.length > 0) {
      const splitDate = initialValues?.values[0].name.split('-');
      if (splitDate[0].trim() === 'Date Range') {
        setSelectionMode('customRange');
      } else {
        setSelectionMode(splitDate[0].trim());
      }
      setSelectedFromDate(splitDate[1].trim());
      setSelectedToDate(splitDate[2].trim());
    }
  }, []);

  const onDateUpdated = (startDate: Date, endDate: Date, selectedDateRangeOption: string) => {
    setSelectionMode(selectedDateRangeOption);
    setSelectedFromDate(startDate?.toISOString());
    setSelectedToDate(endDate?.toISOString());

    const dateRangeString = `${
      selectedDateRangeOption === 'customRange' ? 'Date Range' : selectedDateRangeOption
    } - ${startDate ? formatDate(startDate?.toISOString()) : ''} - ${
      endDate ? formatDate(endDate?.toISOString()) : ''
    }`;

    const dateRanges = [
      {
        key: dateRangeString,
        name: dateRangeString,
      },
    ];

    onSelectedItems({ dataKey, title: label, values: dateRanges });
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Collapsible text={label}>
        <ProcessSearchFiscalYearPicker
          label=""
          onUpdateDate={onDateUpdated}
          initialSelectedOption={selectedMode}
          initialStartDate={selectedFromDate ? new Date(selectedFromDate) : null}
          initialEndDate={selectedToDate ? new Date(selectedToDate) : null}
        />
      </Collapsible>
    </Stack>
  );
};

export default FiscalYearCollapsiblePicker;
