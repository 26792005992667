import { useQuery } from '@apollo/client';
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IDropdownProps,
  ITag,
  IconButton,
  Label,
  Separator,
  Stack,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { filterWrapperStyles } from '../../../app/common/styles/CommonStyleObjects';
import { PROCESS_TYPES } from '../../../utils/statApi/ProcessTypesApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { multiDropdownMediumWrapper } from '../../companies/documents/CompanyDocumentStyles';
import FilterWwAreaPicker from '../../companies/list/FilterWwAreaPicker';
import { IProcessSearchState } from './IProcessSearchResult';
import ProcessSearchCompanyPicker from './ProcessSearchCompanyPicker';
import ProcessSearchCountryPicker from './ProcessSearchCountryPicker';
import ProcessSearchFiscalYearPicker from './ProcessSearchFiscalYearPicker';
import DateRangePicker from './DateRangePicker';
import MessageWithTooltip from '../../common/MessageWithToolTip';
import OPS_DETAILS from '../../../utils/statApi/OpsHierarchyApi';

interface IProcessSearchFilterProps {
  onResetFiltersClick: () => void;
  filterState: IProcessSearchState;
  onUpdateState: (newState: IProcessSearchState) => void;
  showFcwFilters?: boolean;
}
const ProcessSearchFilter = (props: IProcessSearchFilterProps): JSX.Element => {
  const { onResetFiltersClick, filterState, onUpdateState, showFcwFilters } = props;

  const {
    companyCodes,
    processTypeIds,
    processStatuses,
    readyMilestoneStatuses,
    reportingPeriodEndFromDate,
    reportingPeriodEndToDate,
    filingDueFromDate,
    filingDueToDate,
    countryCodes,
    selectedFilingDueDateOption,
    selectedPeriodEndDateOption,
    companyAreaCodes,
    showMyItems,
    milestoneDueDateStartDate,
    milestoneDueDateEndDate,
    opsDetailIds,
  } = filterState;
  const {
    data: processTypes,
    loading: processTypesLoading,
    error: processTypesError,
  } = useQuery(PROCESS_TYPES);

  const {
    data: opsDetailsData,
    loading: opsDetailsLoading,
    error: opsDetailsError,
  } = useQuery(OPS_DETAILS);

  const onFieldChange = (
    propertyName: keyof IProcessSearchState,
    value: number[] | string[] | Date | boolean,
  ) => {
    const newState = { ...filterState, [propertyName]: value };
    onUpdateState(newState);
  };

  const onProcessTypeUpdate = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ): void => {
    const oldProcessTypeIds = processTypeIds;
    const newProcessTypeIds = item.selected
      ? [...oldProcessTypeIds, item.key as number]
      : oldProcessTypeIds.filter((id) => id !== item.key);
    onFieldChange('processTypeIds', newProcessTypeIds);
  };

  const onMilestoneReadyStatusUpdate = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ): void => {
    const readyMilestoneStatusesFromState = item.selected
      ? [...readyMilestoneStatuses, item.key as string]
      : readyMilestoneStatuses.filter((id) => id !== item.key);
    onFieldChange('readyMilestoneStatuses', readyMilestoneStatusesFromState);
  };

  const onProcessStatusUpdate = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ): void => {
    const processStatusesFromState = item.selected
      ? [...processStatuses, item.key as string]
      : processStatuses.filter((id) => id !== item.key);
    onFieldChange('processStatuses', processStatusesFromState);
  };

  const onOpsDetailsUpdate = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
  ): void => {
    const opsDetailIdsFromState = item.selected
      ? [...opsDetailIds, item.key as string]
      : opsDetailIds?.filter((id) => id !== item.key);
    onFieldChange('opsDetailIds', opsDetailIdsFromState as number[]);
  };

  const onReportingPeriodDateRangeChange = (
    newStartDate: Date,
    newEndDate: Date,
    newSelectedOption: string,
  ) => {
    const newReportingEndDateRange = {
      reportingPeriodEndFromDate: newStartDate,
      reportingPeriodEndToDate: newEndDate,
      selectedPeriodEndDateOption: newSelectedOption,
    };
    const newState = { ...filterState, ...newReportingEndDateRange };
    onUpdateState(newState);
  };
  const onFilingDueDateRangeChange = (
    newStartDate: Date,
    newEndDate: Date,
    newSelectedOption: string,
  ) => {
    const newReportingEndDateRange = {
      filingDueFromDate: newStartDate,
      filingDueToDate: newEndDate,
      selectedFilingDueDateOption: newSelectedOption,
    };
    const newState = { ...filterState, ...newReportingEndDateRange };
    onUpdateState(newState);
  };

  const onMilestoneDueDateRangeChange = (newStartDate: string, newEndDate: string) => {
    const newMilestoneDateRange = {
      // This filter always needs to have both a start and end to the range
      milestoneDueDateStartDate: newStartDate === null ? newEndDate : newStartDate,
      milestoneDueDateEndDate: newEndDate === null ? newStartDate : newEndDate,
    };
    const newState = { ...filterState, ...newMilestoneDateRange };
    onUpdateState(newState);
  };

  const onMyItemsChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
    onFieldChange('showMyItems', value);
  };

  const processStatusOptions = [
    { key: 'COMPLETE', text: 'Complete' },
    { key: 'ACTIVE', text: 'Active ' },
  ];
  const readyMilestoneStatusOptions = [
    { key: 'OVERDUE', text: 'Overdue' },
    { key: 'UPCOMING', text: 'Upcoming' },
    { key: 'ONTRACK', text: 'On-Track' },
  ];

  const onRenderLabel = (dropDownProps: IDropdownProps): JSX.Element => {
    const tooltipMessage =
      'Shows any process where the ready milestone has the following status(es).';

    return (
      <TooltipHost content={tooltipMessage} id={dropDownProps.label}>
        <Stack horizontal verticalAlign="center">
          <Label>{dropDownProps.label}</Label>
          <IconButton
            aria-describedby={dropDownProps.label}
            ariaLabel={tooltipMessage}
            iconProps={{ iconName: 'Info' }}
          />
        </Stack>
      </TooltipHost>
    );
  };

  return (
    <Stack.Item grow disableShrink styles={filterWrapperStyles}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <h2>Filter</h2>
        <DefaultButton text="Reset Filters" onClick={onResetFiltersClick} />
      </Stack>
      <ProcessSearchCompanyPicker
        initialSelectedCodes={companyCodes}
        onCompanyUpdate={(items: ITag[]) => {
          onFieldChange(
            'companyCodes',
            items.map((item) => item.name.split(' ')[0].toString()),
          );
        }}
      />
      <Separator />
      <ProcessSearchCountryPicker
        initialSelectedCodes={countryCodes}
        onCountryUpdate={(items: ITag[]) => {
          onFieldChange(
            'countryCodes',
            items.map((item) => item.key.toString()),
          );
        }}
      />
      <Separator />
      <FilterWwAreaPicker
        initialSelectedCodes={companyAreaCodes}
        onRegionAreaUpdate={(items: ITag[]) => {
          onFieldChange(
            'companyAreaCodes',
            items.map((item) => item.key.toString()),
          );
        }}
      />
      <Separator />
      <ProcessSearchFiscalYearPicker
        label="Reporting Period End Date"
        initialStartDate={reportingPeriodEndFromDate}
        initialEndDate={reportingPeriodEndToDate}
        onUpdateDate={(startDate: Date, endDate: Date, initialSelectedOption: string) => {
          onReportingPeriodDateRangeChange(startDate, endDate, initialSelectedOption);
        }}
        initialSelectedOption={selectedPeriodEndDateOption}
      />
      <Separator />
      <ProcessSearchFiscalYearPicker
        label="Filing Due Date"
        initialStartDate={filingDueFromDate}
        initialEndDate={filingDueToDate}
        onUpdateDate={(startDate: Date, endDate: Date, initialSelectedOption: string) => {
          onFilingDueDateRangeChange(startDate, endDate, initialSelectedOption);
        }}
        initialSelectedOption={selectedFilingDueDateOption}
      />
      <Separator />
      <Dropdown
        label="Process Status"
        className={multiDropdownMediumWrapper}
        onChange={onProcessStatusUpdate}
        selectedKeys={processStatuses}
        multiSelect
        placeholder="Select an option"
        options={processStatusOptions}
      />
      <Separator />
      <LoadingErrorMessage loading={processTypesLoading} error={processTypesError} />
      <Dropdown
        label="Process Type"
        className={multiDropdownMediumWrapper}
        onChange={onProcessTypeUpdate}
        selectedKeys={processTypeIds}
        multiSelect
        placeholder="Select an option"
        options={processTypes?.allProcessTypes}
      />
      {showFcwFilters && (
        <>
          <Separator />
          <LoadingErrorMessage loading={opsDetailsLoading} error={opsDetailsError} />
          <Dropdown
            label="Ops Org Detail"
            className={multiDropdownMediumWrapper}
            onChange={onOpsDetailsUpdate}
            selectedKeys={opsDetailIds}
            multiSelect
            placeholder="Select an option"
            options={opsDetailsData?.opsDetails}
          />
          <Separator />
          <Toggle
            label={
              <MessageWithTooltip
                label="Show My Items"
                tooltipMessage="Shows any process with at least one milestone where you are an assignee"
              />
            }
            checked={showMyItems}
            onChange={onMyItemsChange}
          />
          <Separator />
          <DateRangePicker
            label={
              <MessageWithTooltip
                label="Milestone Due Date"
                tooltipMessage="Show any process with at least one milestone's due date within the selected range"
              />
            }
            initialStartDate={milestoneDueDateStartDate}
            initialEndDate={milestoneDueDateEndDate}
            onUpdateDate={(startDate: string, endDate: string) => {
              onMilestoneDueDateRangeChange(startDate, endDate);
            }}
          />
          <Separator />
          <Dropdown
            label="Ready Milestone Status"
            onRenderLabel={onRenderLabel}
            className={multiDropdownMediumWrapper}
            onChange={onMilestoneReadyStatusUpdate}
            selectedKeys={readyMilestoneStatuses}
            multiSelect
            placeholder="Select an option"
            options={readyMilestoneStatusOptions}
          />
        </>
      )}
    </Stack.Item>
  );
};
export default ProcessSearchFilter;
