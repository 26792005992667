import React, { useState } from 'react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Spinner } from '@fluentui/react';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { IMilestoneDueDates } from '../Interfaces';
import { UPDATE_MILESTONE_DUEDATES_MUTATION } from '../../../../utils/statApi/MilestoneApi';
import MilestoneDueDatesForm from './MilestoneDueDatesForm';
import ActionButtons from '../../../common/ActionButtons';
import { IProcessIdParams } from '../../../common/ParamTypes';
import { GET_PROCESS_MILESTONE_DUE_DATES_EDIT } from '../../../../utils/statApi/ProcessApi';
import { CoherencePanelStyles } from '../../../../app/common/styles/CommonStyleObjects';

interface IMilestoneDueDateEditPanelProps {
  closePanel: () => void;
  focusedMilestoneId: number;
}

const MilestoneDueDateEditPanel: React.FunctionComponent<IMilestoneDueDateEditPanelProps> = ({
  closePanel,
  focusedMilestoneId,
}: IMilestoneDueDateEditPanelProps): JSX.Element => {
  const { processId } = useParams<IProcessIdParams>();
  const [dueDatesToSave, setDueDatesToSave] = useState<IMilestoneDueDates[]>([]);

  const { data, error, loading } = useQuery(GET_PROCESS_MILESTONE_DUE_DATES_EDIT, {
    variables: { processId: parseInt(processId, 10) },
    fetchPolicy: 'network-only',
  });
  const [updateMilestoneDueDates, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_MILESTONE_DUEDATES_MUTATION,
    {
      onCompleted: closePanel,
    },
  );

  const handleOnSaveClick = (): void => {
    updateMilestoneDueDates({
      variables: {
        milestoneDueDatesInput: {
          processId: parseInt(processId, 10),
          milestoneDueDates: dueDatesToSave,
        },
      },
    });
  };
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Milestone Due Dates"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={closePanel}
          handleSubmit={handleOnSaveClick}
          saveTitle="Save Dates"
          saveLabel="Save Dates"
          cancelTitle="Cancel Date Edit"
          cancelLabel="Cancel Date Edit"
        />
      )}
    >
      <form onSubmit={handleOnSaveClick}>
        {loading && <Spinner label="Loading Milestone Due Dates" />}
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          actionName="There was an error saving the milestone due date edits to the server"
        />
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="There was an error loading the milestone due dates from the server"
        />
        {!loading && !error && (
          <MilestoneDueDatesForm
            milestones={data?.processMilestoneDueDatesEdit}
            focusedMilestoneId={focusedMilestoneId}
            dueDatesToSave={dueDatesToSave}
            setDueDatesToSave={setDueDatesToSave}
          />
        )}
      </form>
    </CoherencePanel>
  );
};

export default MilestoneDueDateEditPanel;
