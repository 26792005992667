import React, { CSSProperties, useEffect, useState } from 'react';

import {
  Dropdown,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  ITooltipHostStyles,
  TooltipHost,
} from '@fluentui/react';
import { ISelectFieldsetProps } from '../../../../utils/types/IFieldsetProps';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import { offsetDateIconStyles } from '../../../companies/processes/panels/milestoneDueDateUtils';

const QueryBasedDropdown: React.FunctionComponent<ISelectFieldsetProps> = (
  props: ISelectFieldsetProps,
): JSX.Element => {
  const {
    id,
    label,
    ariaLabel,
    required,
    value,
    handleChange,
    valueKey,
    dropdownOptionsData,
    errors,
    errorMessage,
    width,
    disabled,
    tooltipProps,
    clearable,
    wrapOptionText,
  } = props;
  const [defaultKey, setDefaultKey] = useState<number>(value?.id);
  const noOptions = dropdownOptionsData && dropdownOptionsData.length === 0;
  let dropdownOptions: IDropdownOption[] = [];
  if (dropdownOptionsData) {
    dropdownOptions = dropdownOptionsData.map(
      (dropdownOption: IDropdownReference): IDropdownOption => {
        const isSelectable = dropdownOption.hasOwnProperty('isSelectable')
          ? dropdownOption.isSelectable
          : true;
        return {
          key: dropdownOption.id,
          text: dropdownOption.fieldName,
          ariaLabel: dropdownOption.fieldName === '--' ? 'None' : dropdownOption.fieldName,
          disabled: !isSelectable,
        };
      },
    );
    if (clearable) {
      dropdownOptions.unshift({ key: null, text: '--' });
    }
  }

  const getErrors = (): string => {
    if (errors && errors[valueKey]) {
      return errors[valueKey].message;
    }
    return '';
  };

  const styles: Partial<IDropdownStyles> = {
    root: {
      width: width || 300,
    },
    dropdownOptionText: wrapOptionText
      ? {
          overflow: 'visible',
          whiteSpace: 'normal',
        }
      : {},
  };

  const dropdownWrapperStyle: CSSProperties = {
    display: 'flex',
  };

  const offsetTooltipHostStyles: Partial<ITooltipHostStyles> = {
    root: { alignSelf: 'flex-end' },
  };

  useEffect(() => {
    setDefaultKey(value?.id || null);
  }, [value]);

  return (
    <div style={dropdownWrapperStyle}>
      <Dropdown
        id={id}
        label={label}
        ariaLabel={ariaLabel}
        required={required}
        selectedKey={defaultKey}
        onChange={(event, item): void => {
          if (!item?.key) {
            setDefaultKey(null);
            const entityToSave: IDropdownReference = {
              id: null,
              fieldName: item.text,
            };
            handleChange(entityToSave, valueKey.toString());
          } else {
            setDefaultKey(parseInt(item.key.toString(), 10));
            const entityToSave: IDropdownReference = {
              id: parseInt(item.key.toString(), 10),
              fieldName: item.text,
            };
            handleChange(entityToSave, valueKey.toString());
          }
        }}
        disabled={disabled || noOptions}
        placeholder={noOptions ? 'There are no options' : 'Select an option'}
        options={dropdownOptions}
        styles={styles}
        errorMessage={errorMessage || getErrors()}
        calloutProps={{ calloutMaxHeight: 215 }}
      />
      {tooltipProps?.showTooltip && (
        <TooltipHost
          content={tooltipProps.tooltipContent}
          id="tooltip"
          styles={offsetTooltipHostStyles}
        >
          <IconButton
            aria-describedby="tooltip"
            ariaLabel={tooltipProps.tooltipContent}
            iconProps={{ iconName: 'Info' }}
            styles={offsetDateIconStyles}
          />
        </TooltipHost>
      )}
    </div>
  );
};
export default QueryBasedDropdown;
