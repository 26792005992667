import { gql } from '@apollo/client';

const GET_COMPANY_CREATION_FINANCIAL_TYPES_QUERY = gql`
  {
    companyCreationFinancialTypes {
      id
      fieldName: name
    }
  }
`;

export default GET_COMPANY_CREATION_FINANCIAL_TYPES_QUERY;
