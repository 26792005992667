enum AnnualFilingRequirementsFormTypeEnum {
  FinancialStatementFilingInformation,
  FinancialSystems,
  FinancialStatementAuditInformation,
  AccountingStandards,
  XBRL,
  AnnualGeneralMeeting,
  FilingInfo,
  AGMDetails,
}

export default AnnualFilingRequirementsFormTypeEnum;
