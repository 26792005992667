import { gql } from '@apollo/client';

export const COMPANY_GROUP_LIST_QUERY = gql`
  {
    companyGroups {
      id
      name
      description
    }
  }
`;

const COMPANY_GROUP_DETAIL_FRAGMENT = gql`
  fragment CompanyGroup on CompanyGroupDto {
    id
    name
    description
    companyGroupsStatUsers {
      graphId
      name
      email
      firstName
      lastName
      alias
    }
    companyGroupCompanies {
      entityLegalName
      companyCode
      companyId
    }
  }
`;

export const COMPANY_GROUP_DETAILS_QUERY = gql`
  query CompanyGroupDetails($id: Int!) {
    companyGroupDetails(id: $id) {
      ...CompanyGroup
    }
  }
  ${COMPANY_GROUP_DETAIL_FRAGMENT}
`;

export const CREATE_COMPANY_GROUP_MUTATION = gql`
  mutation createCompanyGroup($companyGroup: CompanyGroupDtoInput!) {
    createCompanyGroup(companyGroup: $companyGroup) {
      ...CompanyGroup
    }
  }
  ${COMPANY_GROUP_DETAIL_FRAGMENT}
`;

export const EDIT_COMPANY_GROUP_MUTATION = gql`
  mutation updateCompanyGroup($companyGroup: CompanyGroupDtoInput!) {
    updateCompanyGroup(companyGroup: $companyGroup) {
      ...CompanyGroup
    }
  }
  ${COMPANY_GROUP_DETAIL_FRAGMENT}
`;
