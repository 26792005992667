import React from 'react';
import { ColumnActionsMode, IColumn, Stack } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { bodyContentContainer, rowClasses } from '../../../app/common/styles/CommonStyleObjects';
import { ALL_PROCESS_TYPE_LIST_QUERY } from '../../../utils/statApi/ProcessTypesApi';
import ProcessTypeList from '../processTypes/ProcessTypeList';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import Subheader from '../../common/headers/Subheader';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import { IProcessType } from '../../../utils/types/IProcessType';

const ProcessTemplatesTypesListPage = (): JSX.Element => {
  const { loading, data, error } = useQuery(ALL_PROCESS_TYPE_LIST_QUERY);
  const { allProcessTypes } = data || {};
  const renderProcessTypeNameLink = (item: IDropdownReference): JSX.Element => {
    return <span>{item.fieldName}</span>;
  };

  const getHeaderTitle = (): JSX.Element => {
    return <h1>Template Management</h1>;
  };
  const getBodyHeaderTitle = (): JSX.Element => {
    return <Subheader title="All Process Types" />;
  };
  const additionalColumns: Array<IColumn> = [
    {
      key: 'id',
      name: 'Links',
      fieldName: 'id',
      minWidth: 250,
      maxWidth: 1000,
      isRowHeader: false,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IProcessType): JSX.Element => {
        return (
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Link to={`/process-templates/${item.id}`}>Process Templates</Link>
            <span>{' | '}</span>
            <Link to={`/process-templates/${item.id}/pool`}>Milestone Pool</Link>
            <>
              <span>{' | '}</span>
              <Link to={`/process-templates/${item.id}/categories`}>Milestone Categories</Link>
            </>
          </Stack>
        );
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
  return (
    <>
      <FullWidthHeader title={getHeaderTitle} subtitle={getBodyHeaderTitle} />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <ProcessTypeList
          processTypes={allProcessTypes}
          renderProcessTypeNameLink={renderProcessTypeNameLink}
          additionalColumns={additionalColumns}
          loading={loading}
          error={error}
          emptyStateMessage="There are no process types"
        />
      </div>
    </>
  );
};

export default ProcessTemplatesTypesListPage;
