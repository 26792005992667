import { DocumentNode, OperationVariables, useQuery } from '@apollo/client';
import { MessageBar, MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../../utils/hooks/UseDebounce';
import { IPageInfo } from '../../../../utils/types/IPageInfo';
import DetailListPaginationBanner, {
  getInitialPageSize,
  getPaginationInfo,
  getTotalPageCount,
} from './DetailsListPaginationBanner';
import IPaginationMetadata from './IPaginationMetadata';
import LoadingErrorMessage from '../../errorContent/LoadingErrorMessage';
import IItemListProps from '../../../../utils/types/IItemListProps';
import { IEdge } from '../../../../utils/types/IEdge';

export interface IPaginatedListPageProps<O> {
  actionName: string;
  query: DocumentNode;
  dataMapperKey: string;
  searchTerm?: string;
  filters?: OperationVariables;
  isInputError?: boolean;
  setIsInputError?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: React.Dispatch<React.SetStateAction<O[]>>;
  onRowClick?: (item: O) => void;
  emptyResultMessage?: string;
}

export interface PaginatedQueryResults<Type> {
  [key: string]: {
    edges: IEdge<Type>[];
    pageInfo: IPageInfo;
    totalCount: number;
  };
}
export interface PaginatedData<Type> {
  data: Type[];
  pageInfo: IPageInfo;
  totalCount: number;
}

const WrappedListPage = <O,>(
  ListPage: (props: IItemListProps<O>) => JSX.Element,
  ListPageProps: IPaginatedListPageProps<O>,
): JSX.Element => {
  const {
    searchTerm,
    filters,
    query,
    dataMapperKey,
    setIsInputError,
    actionName,
    isInputError,
    onRowClick,
    emptyResultMessage,
  } = ListPageProps;

  const debouncedSearchTerm = useDebounce(
    searchTerm === null || searchTerm === undefined ? '' : searchTerm,
    500,
  );
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [defaultPageSize, setDefaultPageSize] = useState<number>(getInitialPageSize());
  const [paginationRefetchLoading, setPaginationRefetchLoading] = useState<boolean>(false);

  const { data, loading, error, refetch } = useQuery(query, {
    variables: {
      first: defaultPageSize,
      after: null,
      last: null,
      before: null,
      keyword: debouncedSearchTerm,
      ...filters,
    },
    onCompleted: () => {
      if (setIsInputError) {
        setIsInputError(false);
      }
    },
    fetchPolicy: 'cache-and-network',
  });
  const dataMapper = (queryResults: PaginatedQueryResults<O>): PaginatedData<O> => {
    const totalCount: number = queryResults?.[dataMapperKey]?.totalCount;
    const pageInfo: IPageInfo = queryResults?.[dataMapperKey]?.pageInfo;
    const queryResultEdges: IEdge<O>[] = queryResults?.[dataMapperKey]?.edges;

    const itemsList: O[] = queryResultEdges.map(({ node }) => {
      return { ...node };
    });
    return {
      data: itemsList,
      pageInfo,
      totalCount,
    } as PaginatedData<O>;
  };

  useEffect(() => {
    setSelectedPage(1);
  }, [debouncedSearchTerm, filters]);

  const dataResult =
    !loading && !error
      ? dataMapper(data)
      : {
          totalCount: 1,
          pageInfo: {} as IPageInfo,
          data: [],
        };

  const pageCount =
    !loading && !error ? getTotalPageCount(dataResult.totalCount, defaultPageSize) : 1;

  const { pageInfo } = dataResult;
  const paginationMetadata: IPaginationMetadata = {
    pageCount,
    selectedPage,
    pageSize: defaultPageSize,
    loadingData: paginationRefetchLoading,
    onPageChange: (startItemIndex: number, endItemIndex: number, newPageNumber: number): void => {
      setPaginationRefetchLoading(true);

      const paginationInfo = getPaginationInfo(
        pageInfo,
        selectedPage,
        newPageNumber,
        defaultPageSize,
      );

      if (pageInfo != null) {
        refetch({
          ...paginationInfo,
          keyword: debouncedSearchTerm ?? '',
          ...filters,
        }).then(() => {
          setPaginationRefetchLoading(false);
        });
        setSelectedPage(newPageNumber);
      }
    },
    onPageSizeChange: (newPageSize: string | number): void => {
      setDefaultPageSize(newPageSize as number);
      setSelectedPage(1);
      refetch({
        first: defaultPageSize,
        keyword: debouncedSearchTerm,
        after: null,
        before: null,
        last: null,
        ...filters,
      });
    },
  };

  const messageBarText = (): string => {
    if (isInputError) {
      return 'Please include at least 3 characters in search input.';
    }
    return emptyResultMessage || 'No results found. Please try again.';
  };
  const NoResultsMessageBar = (): JSX.Element => {
    if ((dataResult?.data?.length === 0 && !loading) || isInputError) {
      return (
        <MessageBar messageBarType={isInputError ? MessageBarType.warning : MessageBarType.info}>
          <span>{messageBarText()}</span>
        </MessageBar>
      );
    }
    return <></>;
  };
  return (
    <>
      <>
        <LoadingErrorMessage loading={loading} error={error} actionName={actionName} />
        <NoResultsMessageBar />

        <ListPage
          items={dataResult?.data}
          isLoading={loading || paginationRefetchLoading}
          onRowClick={onRowClick}
        />

        {paginationMetadata && <DetailListPaginationBanner {...paginationMetadata} />}
      </>
    </>
  );
};

export default WrappedListPage;
