import { DocumentNode, useQuery } from '@apollo/client';
import { IconButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { bodyContentContainer } from '../../../app/common/styles/CommonStyleObjects';
import CanAccess from '../canAccess/CanAccess';
import DocumentsPanel from './DocumentsPanel';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';
import Card from '../card/Card';
import DocumentCardList from './DocumentCardList';
import { IDocument } from '../../../utils/statApi/DocumentApi';

enum PanelType {
  Incorporation,
  Functional,
  Other,
}

interface IDocumentsPageState {
  isPanelOpen: boolean;
  currentForm: PanelType;
}

interface IDocumentsPageProps {
  query: DocumentNode;
  mutation: DocumentNode;
  parentId: number;
  dataProperty: string;
  showDeletedFiles?: boolean;
  editPolicy: string;
  isReadOnly?: boolean;
}

const DocumentsPage = ({
  query,
  mutation,
  parentId,
  dataProperty,
  showDeletedFiles = false,
  editPolicy,
  isReadOnly = true,
}: IDocumentsPageProps): JSX.Element => {
  const { data, loading, error } = useQuery(query, {
    variables: {
      parentId,
      showDeletedFiles,
    },
    fetchPolicy: 'network-only',
  });

  const [panelState, setPanelState] = useState<IDocumentsPageState>({
    isPanelOpen: false,
    currentForm: null,
  });

  const closePanel = (): void => {
    setPanelState({ isPanelOpen: false, currentForm: null });
  };

  const renderActionButton = (
    label: string,
    formType: PanelType,
    requestedAction?: string,
  ): JSX.Element => {
    return (
      <>
        {!isReadOnly && (
          <CanAccess requestedAction={requestedAction}>
            <IconButton
              title={`Edit ${label}`}
              ariaLabel={label}
              iconProps={{ iconName: 'Edit' }}
              onClick={(): void => {
                setPanelState({
                  isPanelOpen: true,
                  currentForm: formType,
                });
              }}
            />
          </CanAccess>
        )}
      </>
    );
  };

  const getNonDeletedDocuments = (documents: IDocument[]): IDocument[] => {
    return documents?.filter((doc: IDocument) => !doc.isDeleted);
  };

  const documents = data && data[dataProperty] ? data[dataProperty] : null;

  const functionalCurrencyDocument = documents?.functionalCurrencyDocument || [];
  const incorporationDocuments = documents?.incorporationDocuments || [];
  const otherDocuments = documents?.otherDocuments || [];

  return (
    <div className={`${bodyContentContainer} lightBackground`}>
      <LoadingErrorMessage loading={loading} error={error} />
      <Card
        title="Incorporation Documents"
        headingLevel={2}
        isLoading={loading}
        actionButton={renderActionButton(
          'Incorporation Documents',
          PanelType.Incorporation,
          editPolicy,
        )}
      >
        <Stack horizontal wrap tokens={{ childrenGap: 60 }}>
          <DocumentCardList
            loading={loading}
            error={error}
            docType="Incorporation"
            documents={incorporationDocuments}
            showDeletedFiles={showDeletedFiles}
          />
        </Stack>
      </Card>
      <br />
      <Card
        title="Functional Currency"
        headingLevel={2}
        isLoading={loading}
        actionButton={renderActionButton(
          'Functional Currency Document',
          PanelType.Functional,
          editPolicy,
        )}
      >
        <Stack horizontal wrap tokens={{ childrenGap: 60 }}>
          <DocumentCardList
            loading={loading}
            error={error}
            docType="Functional Currency"
            documents={functionalCurrencyDocument}
            showDeletedFiles={showDeletedFiles}
          />
        </Stack>
      </Card>
      <br />
      <Card
        title="Other"
        headingLevel={2}
        isLoading={loading}
        actionButton={renderActionButton('Other Documents', PanelType.Other, editPolicy)}
      >
        <Stack horizontal wrap tokens={{ childrenGap: 60 }}>
          <DocumentCardList
            loading={loading}
            error={error}
            docType="Other"
            documents={otherDocuments}
            showDeletedFiles={showDeletedFiles}
          />
        </Stack>
      </Card>
      {panelState.isPanelOpen && panelState.currentForm === PanelType.Incorporation && (
        <DocumentsPanel
          closePanel={closePanel}
          initialDocuments={getNonDeletedDocuments(incorporationDocuments)}
          parentId={parentId}
          documentType="Incorporation"
          mutation={mutation}
          showDeletedFiles={showDeletedFiles}
        />
      )}
      {panelState.isPanelOpen && panelState.currentForm === PanelType.Functional && (
        <DocumentsPanel
          closePanel={closePanel}
          initialDocuments={getNonDeletedDocuments(functionalCurrencyDocument)}
          parentId={parentId}
          documentType="Functional"
          maxOfOne
          mutation={mutation}
          showDeletedFiles={showDeletedFiles}
        />
      )}
      {panelState.isPanelOpen && panelState.currentForm === PanelType.Other && (
        <DocumentsPanel
          closePanel={closePanel}
          initialDocuments={getNonDeletedDocuments(otherDocuments)}
          parentId={parentId}
          documentType="Other"
          mutation={mutation}
          showDeletedFiles={showDeletedFiles}
        />
      )}
    </div>
  );
};

export default DocumentsPage;
