export interface IProcessStatusSummary {
  countOfProcessesWithFutureMilestones: number;
  countOfProcessesWithUpcomingMilestones: number;
  countOfProcessesWithLateMilestones: number;
  countOfCompletedProcesses: number;
  processTypeSummary: IProcessTypeSummary[];
}

export interface IProcessTypeSummary {
  processTypeName: string;
  processTypeId: number;
  countOfProcessesWithFutureMilestones: number;
  countOfProcessesWithUpcomingMilestones: number;
  countOfProcessesWithLateMilestones: number;
  countOfCompletedProcesses: number;
}

export enum ProcessStatusSummaryCategory {
  Overdue = 'OVERDUE',
  Upcoming = 'UPCOMING',
  OnTrack = 'ONTRACK',
  Complete = 'COMPLETE',
}
