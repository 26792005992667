import { Stack } from '@fluentui/react';
import React from 'react';
import IAgmReview from '../../../../utils/types/IAgmReview';
import Card from '../../../common/card/Card';
import StackColumn from '../../../common/StackColumn';
import { IServiceContact } from '../../serviceContacts/interfaces';
import ServiceContactList from '../../serviceContacts/ServiceContactList';

interface IAgmReviewContactsCardProps {
  agmReview: IAgmReview;
  isLoading: boolean;
  actionButtons?: React.ReactNode;
}

const AgmReviewContactsCard = ({
  agmReview,
  isLoading,
  actionButtons,
}: IAgmReviewContactsCardProps): JSX.Element => {
  const contacts: IServiceContact[] = agmReview?.agmReviewContacts?.map((contact) => {
    return {
      graphId: contact.graphId,
      text: contact.name,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      alias: contact.alias,
      contactType: 'AgmReviewContact',
      isInvalidUser: false,
    } as IServiceContact;
  });

  return (
    <Stack grow>
      <Card title="Contacts" isLoading={isLoading} actionButton={actionButtons}>
        <Stack horizontal wrap horizontalAlign="space-between">
          <StackColumn>
            <ServiceContactList horizontal labelText="CELA Assignee" contacts={contacts || []} />
          </StackColumn>
        </Stack>
      </Card>
    </Stack>
  );
};

export default AgmReviewContactsCard;
