import { useQuery } from '@apollo/client';
import { mergeStyles, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React from 'react';
import { GET_COMING_DUE_MILESTONE_DASHBOARD_CARD_LIST } from '../../utils/statApi/MilestoneApi';
import { IMilestoneDashboardCardItem } from '../../utils/types/IMilestoneDashboardCardItem';
import InsightCard from './InsightCard';
import momentUtc from '../../utils/DateFormatter';
import StatDateFormats from '../../utils/types/DateFormats';
import {
  upcomingMilestoneDateStyle,
  milestoneLiStyle,
  SvgColor,
} from '../../app/common/styles/CommonStyleObjects';
import MilestoneCardList from './MilestoneCardList';
import MilestoneCalendarSvg from '../common/svgs/MilestoneCalendarSvg';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';

const listStyle = mergeStyles({
  paddingLeft: 0,
});

const ComingDueMilestonesCard = (): JSX.Element => {
  const { data, loading, error } = useQuery(GET_COMING_DUE_MILESTONE_DASHBOARD_CARD_LIST, {
    fetchPolicy: 'cache-and-network',
  });
  let comingDueMilestonesCardContent: JSX.Element = <></>;
  const comingDueMilestonesCardTitle = 'My Upcoming Milestones';

  if (!error && !loading) {
    if (
      data?.comingdueMilestoneDashboardCardList !== null &&
      data?.comingdueMilestoneDashboardCardList?.length > 0
    ) {
      const milestones: IMilestoneDashboardCardItem[] =
        data?.comingdueMilestoneDashboardCardList?.map(
          (upcomingdueMilestone: IMilestoneDashboardCardItem) => {
            return {
              id: upcomingdueMilestone?.id,
              title: upcomingdueMilestone?.title,
              companyCode: upcomingdueMilestone?.companyCode,
              sapName: upcomingdueMilestone?.sapName,
              processId: upcomingdueMilestone?.processId,
              processTypeUri: upcomingdueMilestone?.processTypeUri,
              dueDate: momentUtc(
                upcomingdueMilestone?.dueDate,
                StatDateFormats.DaySimpleNoComma,
                true,
              ),
            };
          },
        );
      comingDueMilestonesCardContent = (
        <MilestoneCardList
          milestones={milestones}
          milestoneDateStyle={upcomingMilestoneDateStyle}
        />
      );
    } else {
      comingDueMilestonesCardContent = (
        <Stack>
          <ul className={listStyle}>
            <li className={milestoneLiStyle}>You have no current upcoming milestones</li>
          </ul>
        </Stack>
      );
    }
  } else if (loading) {
    comingDueMilestonesCardContent = <Spinner size={SpinnerSize.medium} />;
  } else if (error) {
    comingDueMilestonesCardContent = (
      <LoadingErrorMessage
        loading={loading}
        error={error}
        actionName="loading Upcoming Milestones from the server"
      />
    );
  }

  return (
    <InsightCard
      caption={comingDueMilestonesCardTitle}
      mainText={comingDueMilestonesCardContent}
      svgComponent={
        <MilestoneCalendarSvg mainColor={SvgColor.lightBlue} accentColor={SvgColor.darkBlue} />
      }
      footerLink={<>Only the top 20 milestones are included in these results</>}
    />
  );
};

export default ComingDueMilestonesCard;
