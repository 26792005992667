import IAgmFiscalYears from '../../../utils/types/IAgmFiscalYears';
import IDividends from '../../../utils/types/IDividends';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import { IReview } from '../../../utils/types/IReview';
import ProcessStatus from '../../../utils/types/ProcessStatus';

export enum MilestoneType {
  upload,
  signoff,
  tax,
}

export enum MilestoneStatus {
  complete = 'COMPLETE',
  notStarted = 'NOTSTARTED',
  ready = 'READY',
}
export enum MilestoneRuleType {
  dividendGate = 'DIVIDENDGATE',
  celaAgmStatus = 'CELAAGMSTATUS',
  documentUpload = 'DOCUMENTUPLOAD',
  auditOpinion = 'AUDITOPINION',
  reasonForViolation = 'REASONFORVIOLATION',
  dividendLockdownMode = 'DIVIDENDLOCKDOWNMODE',
  celaAgmStatusLockdownMode = 'CELAAGMSTATUSLOCKDOWNMODE',
  auditOpinionLockdownMode = 'AUDITOPINIONLOCKDOWNMODE',
  agmDetail = 'AGMDETAIL',
  agmDetailLockdownMode = 'AGMDETAILLOCKDOWNMODE',
}
export enum MilestoneActionType {
  attestation,
  rescind,
  upload,
  normal,
}
export enum MilestoneRuleStatus {
  required,
  completed,
}
export interface IAssignee {
  name: string;
  statRole: string;
}

export enum ProcessType {
  AnnualFinancialStatements = 'Annual Financial Statements',
  DirectTax = 'Direct Tax',
  IndirectTax = 'Indirect Tax',
  OtherTax = 'Other Tax',
  StatisticalReporting = 'Statistical Reporting',
  RegulatoryUpdate = 'Regulatory Update',
  EntityTerminationProcess = 'Entity Termination Process',
  QuarterlyMaintenance = 'Quarterly Maintenance',
}
export interface IMilestoneDueDates {
  id: number;
  dueDate: string;
}

export interface IMilestoneRule {
  id?: number;
  type: MilestoneRuleType;
  isComplete: boolean;
  data: string;
}

export interface IMilestone {
  id: number;
  title: string;
  description: string;
  assignees: Array<IAssignee>;
  dueDate: Date;
  minimumDuration: number;
  maximumDuration?: number;
  maxDaysOffset: number;
  duration: number;
  type: MilestoneType;
  actionText: string;
  commentText: string;
  status: MilestoneStatus;
  attestationText: string;
  milestoneRules?: Array<IMilestoneRule>;
  ordinal: number;
  category?: string;
  processFilingDueDate?: Date;
  processTemplateVersionId?: number;
}
export interface IProcess {
  id: number;
  name: string;
  processType: string;
  processTypeUri?: string;
  processStatus: ProcessStatus;
  reportingPeriodEndDate: Date;
  filingDueDate: Date;
  originalFilingDueDate: Date;
  milestones: Array<IMilestone>;
}

export interface IFinancialStatementProcess extends IProcess {
  agmFiscalYear: IAgmFiscalYears;
  companyFiscalYearId: number;
  auditOpinion?: IDropdownReference;
  statutoryAuditFinding?: boolean;
  remediationPlanInPlace?: boolean;
  expectedClosureDate?: string;
  hasProcessExtensionRequest: boolean;
  hasPendingProcessExtensionRequest: boolean;
  reasonForFilingViolation?: IDropdownReference;
  reasonForFilingViolationComments?: string;
  corpReasonForLateFiling?: IDropdownReference;
  corpReasonForLateFilingComment?: string;
  reasonForReportExclusionComments?: string;
  reasonForReportExclusion?: IDropdownReference;
  excludeFromMetrics?: boolean;
  processExceptionApproved?: boolean;
  reportExceptionApprovalReasonComments: string;
  reasonForReportingExclusionApproval?: IDropdownReference;
  reportExceptionApprovedByDisplayName: string;
  reportExceptionApprovedByGraphGUID: string;
  dividends?: IDividends;
}

export interface IRecentProcess extends IFinancialStatementProcess {
  companyCode: string;
}

export interface IProcessCorpReasonForLateFiling {
  corpReasonForLateFiling?: IDropdownReference;
  corpReasonForLateFilingComment: string;
  processId: number;
}

export interface IProcessReportingReasonForExclusion {
  reasonForReportExclusion?: IDropdownReference;
  reasonForReportExclusionComments: string;
  processId: number;
  excludeFromMetrics: boolean;
  processExceptionApproved: boolean;
  processExceptionApprovedBy: { text: string; graphId: string };
  processExceptionApprovedReason?: IDropdownReference;
  approvedReasonComments: string;
}

export interface IMilestoneListActionItemProps {
  milestone: IMilestone;
  submit: (done: MilestoneActionType) => void;
}

export interface IProcessExtensionRequest {
  id?: number;
  process?: IProcess;
  processId?: number;
  extensionReason: IDropdownReference;
  comments?: string;
  requestedDate: Date;
  review?: IReview;
  companyCode?: string;
  companyName?: string;
}
