import { gql } from '@apollo/client';

const FILING_INFO_FRAGMENT = gql`
  fragment FilingInfo on FilingInfoDto {
    id
    responsibleForStatFilingPreparation
    responsibleForStatFilingPreparationThirdParty
    responsibleForRecord
    responsibleForRecordThirdParty
    oversightGroup {
      id
      fieldName
    }
    otherInformation
  }
`;

export default FILING_INFO_FRAGMENT;
