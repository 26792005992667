import React from 'react';
import { useQuery } from '@apollo/client';
import GET_AGM_STATUSES_QUERY from '../../../utils/statApi/AgmStatusApi';
import IDropdownReference from '../../../utils/types/IDropdownReference';
import IFieldsetProps from '../../../utils/types/IFieldsetProps';
import QueryBasedDropdown from '../../common/formFields/queryBasedDropdown/QueryBasedDropdown';

const AgmStatusDropdown = (props: IFieldsetProps): JSX.Element => {
  const { value, label, errors, handleChange, valueKey, outputKey } = props;
  const { loading, data } = useQuery(GET_AGM_STATUSES_QUERY);
  const { agmStatuses } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      value={value as IDropdownReference}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={agmStatuses}
      errors={errors}
      handleChange={handleChange}
      valueKey={valueKey}
      required
    />
  );
};

export default AgmStatusDropdown;
