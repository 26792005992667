import { useHistory } from 'react-router-dom';
import { Category, EnhancedSearch, IResult, Tags } from '@coherence-design-system/enhanced-search';
import { Template } from '@coherence-design-system/slot';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import useDebounce from '../../utils/hooks/UseDebounce';
import { GetCompaniesByFilter } from '../../utils/statApi/CompaniesApi';
import { LocalStorageKeys } from '../../utils/hooks/UseLocalStorage';
import { IRecentCompaniesCardItem } from '../../utils/types/IRecentCompaniesCardItem';

interface IStatHeaderSearchProps {
  isSearchOverlayOpen: boolean;
  setIsSearchOverlayOpen?: (isOpen: boolean) => void;
}

const StatHeaderSearch = ({
  isSearchOverlayOpen,
  setIsSearchOverlayOpen,
}: IStatHeaderSearchProps) => {
  const history = useHistory();

  const getRecentCompanies = (): string[] => {
    const companiesString: string = localStorage.getItem(LocalStorageKeys.recentCompanies);
    const recentCompanies: IRecentCompaniesCardItem[] = JSON.parse(companiesString);
    return recentCompanies?.map((company) => company.companyCode) || [];
  };

  const [searchState, setSearchState] = useState({ searchTerm: '' });
  const [recentCompanyTags, setRecentCompanyTags] = useState(getRecentCompanies());
  const debouncedSearchTerm = useDebounce(searchState.searchTerm, 500);

  const [getCompanies, { data }] = useLazyQuery(GetCompaniesByFilter, {
    variables: {
      companySearchDto: {
        keyword: searchState.searchTerm,
      },
      paginationDto: {
        currentPage: 1,
        pageSize: 10,
      },
    },
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      getCompanies({
        variables: {
          companySearchDto: {
            keyword: debouncedSearchTerm,
          },
          paginationDto: {
            currentPage: 1,
            pageSize: 10,
          },
        },
      });
    }
  }, [debouncedSearchTerm]);

  const companiesList = data?.companies?.items || [];
  const companies: IResult[] = companiesList?.map(
    (company: { id: number; companyCode: string; entityLegalName: string }) => {
      return {
        itemKey: company.id.toString(),
        title: company.companyCode,
        description: company.entityLegalName,
        iconProps: { iconName: 'CityNext' },
      };
    },
  );

  /** There is a bug in coherence globalsearch.base.tsx which refocuses the searchbox
   * wrapping div and reopens results list even when you rerender the component tree
   * after navgating to another page. Hence we've got this fancy shmanzy hacky hack that
   * bends space and time
   */
  const clearSearchAfterNavigation = () => {
    setTimeout(() => {
      const clearButton = document.querySelector(
        '.ms-SearchBox-clearButton > .ms-Button',
      ) as HTMLElement;

      const backButton = document.querySelector(
        'div[class^="backDividerContainer"] .ms-Button',
      ) as HTMLElement;

      if (clearButton) {
        clearButton.click();
      }

      if (isSearchOverlayOpen && backButton) {
        backButton.click();
      }
    }, 100);
  };

  const onChange = (_event?: React.ChangeEvent<HTMLInputElement>, newSearchText?: string) => {
    setSearchState({ searchTerm: newSearchText || '' });
  };

  const onClear = () => {
    setSearchState({ searchTerm: '' });
  };

  const onSearch = (newVal: string) => {
    clearSearchAfterNavigation();
    if ((newVal.length === 4 || newVal.length === 9) && parseInt(newVal, 10) > 0) {
      history.push(`/companies/${newVal}/processes`);
      return;
    }
    if (companies.length === 0) {
      history.push('/companies?NotFound=true');
      return;
    }
    history.push(`/companies/${companies[0].title}/processes`);
  };

  const onFocusHandler = () => {
    setRecentCompanyTags(getRecentCompanies());
  };

  return (
    <EnhancedSearch
      minHighlightThreshold={1}
      id="statHeaderSearch"
      onSearch={onSearch}
      onChange={onChange}
      onClear={onClear}
      onFocusHandler={onFocusHandler}
      value={searchState.searchTerm}
      isSearchInHeader
      placeholder="Search Companies"
      isOverlay={isSearchOverlayOpen}
      onEscape={() => {
        setIsSearchOverlayOpen(false);
      }}
      onOverlaySearchClose={() => {
        setIsSearchOverlayOpen(false);
      }}
    >
      {searchState.searchTerm.length <= 0 ? (
        <Template slot="result-items">
          <Tags tags={recentCompanyTags} title="Recent Companies" />
        </Template>
      ) : (
        <Template slot="result-items">
          <Category category="Companies" items={companies} resultsPerCategory={10} />
        </Template>
      )}
    </EnhancedSearch>
  );
};

export default StatHeaderSearch;
