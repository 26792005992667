import { gql } from '@apollo/client';
import { MILESTONE_FRAGMENT, PROCESS_DETAIL_FRAGMENT } from './ProcessApi';

export interface MilestoneHistory {
  id: number;
  actionType: MilestoneHistoryActionType;
  actionDate: Date;
  statUserName: string;
  comments: string;
}

export enum MilestoneHistoryActionType {
  Signoff = 'Signoff',
  Rescind = 'Rescind',
  Update = 'Update',
  Comment = 'Comment',
}

export const MILESTONE_DOCUMENT_FRAGMENT = gql`
  fragment MilestoneDocument on MilestoneDocumentDto {
    id
    uploader {
      id
      displayName
      firstName
      lastName
    }
    uploadDate
    title
    url
    isDeleted
  }
`;

const MILESTONE_DASHBOARD_CARD_ITEM_FRAGMENT = gql`
  fragment MilestoneDashboardCardItem on MilestoneDashboardCardItemDto {
    id
    title
    dueDate
    processId
    processTypeUri
    companyCode
    sapName
  }
`;

const SIGNOFF_MILESTONE_MUTATION = gql`
  mutation signoffMilestone($milestoneId: Int!) {
    signoffMilestone(milestoneId: $milestoneId) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const COMMENT_MILESTONE_MUTATION = gql`
  mutation commentMilestone($milestoneId: Int!, $comment: String!) {
    commentMilestone(milestoneId: $milestoneId, comment: $comment) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const GET_OVERDUE_MILESTONE_DASHBOARD_CARD_LIST = gql`
  query GetOverdueMilestoneDashboardCardList {
    overdueMilestoneDashboardCardList {
      ...MilestoneDashboardCardItem
    }
  }
  ${MILESTONE_DASHBOARD_CARD_ITEM_FRAGMENT}
`;

export const GET_COMING_DUE_MILESTONE_DASHBOARD_CARD_LIST = gql`
  query GetComingdueMilestoneDashboardCardList {
    comingdueMilestoneDashboardCardList {
      ...MilestoneDashboardCardItem
    }
  }
  ${MILESTONE_DASHBOARD_CARD_ITEM_FRAGMENT}
`;

export const RESCIND_MILESTONE_MUTATION = gql`
  mutation rescindMilestone($milestoneId: Int!, $comments: String!) {
    rescindMilestone(milestoneId: $milestoneId, comments: $comments) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const UPDATE_MILESTONE_DUEDATES_MUTATION = gql`
  mutation updateMilestoneDueDates($milestoneDueDatesInput: MilestoneDueDatesDtoInput!) {
    updateMilestoneDueDates(milestoneDueDatesInput: $milestoneDueDatesInput) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const GET_ALL_MILESTONE_HISTORY = gql`
  query MilestoneHistory($milestoneId: Int!) {
    milestoneHistory(milestoneId: $milestoneId) {
      id
      actionType
      actionDate
      statUserName
      comments
    }
  }
`;

export const GET_MILESTONE_DOCUMENTS = gql`
  query MilestoneDocuments($milestoneId: Int!) {
    milestoneDocumentList(milestoneId: $milestoneId) {
      ...MilestoneDocument
    }
  }
  ${MILESTONE_DOCUMENT_FRAGMENT}
`;

export const ATTACH_DOCUMENT_MILESTONE = gql`
  mutation attachMilestoneDocument($milestoneDocumentsInput: MilestoneDocumentsDtoInput!) {
    attachMilestoneDocument(milestoneDocumentsInput: $milestoneDocumentsInput) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const DELETE_DOCUMENT_MILESTONE = gql`
  mutation deleteMilestoneDocument($milestoneDocumentId: Int!) {
    deleteMilestoneDocument(milestoneDocumentId: $milestoneDocumentId) {
      ...ProcessDetail
    }
  }
  ${PROCESS_DETAIL_FRAGMENT}
`;

export const MILESTONE_DETAILS = gql`
  query milestoneDetails($milestoneId: Int!) {
    milestoneDetails(milestoneId: $milestoneId) {
      ...Milestone
    }
  }
  ${MILESTONE_FRAGMENT}
`;

export default SIGNOFF_MILESTONE_MUTATION;
