import { Stack, mergeStyles } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { milestoneLiStyle } from '../../app/common/styles/CommonStyleObjects';
import { IMilestoneDashboardCardItem } from '../../utils/types/IMilestoneDashboardCardItem';
import VerticalScrollbarWrapper from '../common/DetailsLists/VerticalScrollbarWrapper';

interface IMilestoneCardList {
  milestones: IMilestoneDashboardCardItem[];
  milestoneDateStyle: string;
}

const listStyle = mergeStyles({
  paddingLeft: 0,
});

const MilestoneCardList = ({ milestones, milestoneDateStyle }: IMilestoneCardList): JSX.Element => {
  return (
    <VerticalScrollbarWrapper>
      <ul className={listStyle}>
        {milestones.map((milestone: IMilestoneDashboardCardItem) => {
          return (
            <li key={milestone.id} className={milestoneLiStyle}>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <Stack style={{ width: '50px' }}>
                  <strong>
                    <span>{`${milestone.dueDate}`}</span>
                  </strong>
                </Stack>
                <Stack className={milestoneDateStyle}>
                  <strong>{`${milestone.companyCode} - ${milestone.sapName}`}</strong>
                  <Link
                    to={`/companies/${milestone.companyCode}/processes/${milestone.processTypeUri}/${milestone.processId}`}
                  >
                    {milestone.title}
                  </Link>
                </Stack>
              </Stack>
            </li>
          );
        })}
      </ul>
    </VerticalScrollbarWrapper>
  );
};
export default MilestoneCardList;
