import React, { useRef, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: React.RefObject<HTMLInputElement>,
  onClickOutside: () => void,
): void {
  function handleClickOutside(event: MouseEvent, handledOnClickOutside: () => void): void {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      handledOnClickOutside();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      handleClickOutside(e, onClickOutside);
    });
    return (): void => {
      document.removeEventListener('mousedown', (e) => {
        handleClickOutside.bind(e, onClickOutside);
      });
    };
  });
}
interface IOutsideAlerterProps {
  onClickOutside?: () => void;
  children: React.ReactElement;
}
export default function OutsideAlerter(props: IOutsideAlerterProps): JSX.Element {
  const { children, onClickOutside } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickOutside);
  return (
    <div style={{ flex: 1, display: 'flex' }} ref={wrapperRef}>
      {children}
    </div>
  );
}
