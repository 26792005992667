import { FontIcon, Stack } from '@fluentui/react';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import formatCurrency from '../../../../utils/CurrencyFormatter';
import momentUtc from '../../../../utils/DateFormatter';
import StatDateFormats from '../../../../utils/types/DateFormats';
import IDividends from '../../../../utils/types/IDividends';
import Card from '../../../common/card/Card';
import { ICompanyCodeParams } from '../../../common/ParamTypes';
import SpanWithLabel from '../../../common/SpanWithLabel';
import StackColumn from '../../../common/StackColumn';

interface IDividendsCardContentProps {
  dividends: IDividends;
  isLoading: boolean;
  actionButtons?: React.ReactNode;
}

const DividendsCardContent = ({
  dividends,
  isLoading,
  actionButtons,
}: IDividendsCardContentProps): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const location = useLocation();
  const atReviewPage = location.pathname.indexOf('/dividends-review/') !== -1;

  const getReviewLink = (): JSX.Element => {
    if (dividends.dividendReviewId && atReviewPage) {
      return <>{dividends.reviewStatus}</>;
    }
    if (dividends.dividendReviewId) {
      return (
        <Link to={`/companies/${companyCode}/dividends-review/${dividends.dividendReviewId}`}>
          {dividends.reviewStatus}
          {'  '}
          <FontIcon iconName="openInNewTab" />
        </Link>
      );
    }

    return <>No Review</>;
  };

  return (
    <Card title="Dividends" isLoading={isLoading} actionButton={actionButtons}>
      <Stack horizontal wrap horizontalAlign="space-between">
        <StackColumn>
          <SpanWithLabel
            labelText="Maximum Dividend Allowed"
            value={
              dividends.currencyCode
                ? formatCurrency(dividends.currencyCode, dividends.maxDividendAllowed)
                : '--'
            }
          />
          <SpanWithLabel
            labelText="Dividends from Current Year"
            value={
              dividends.currencyCode
                ? formatCurrency(dividends.currencyCode, dividends.dividendsFromCurrentYear)
                : '--'
            }
          />
          <SpanWithLabel
            labelText="Declared Date"
            value={momentUtc(dividends.declaredDate, StatDateFormats.DayBreakout, true)}
          />
          <SpanWithLabel labelText="Review Status">
            <>{getReviewLink()}</>
          </SpanWithLabel>
          <SpanWithLabel labelText="Comments" value={dividends.comments} />
        </StackColumn>
        <StackColumn>
          <SpanWithLabel
            labelText="Proposed Dividend Amount"
            value={
              dividends.currencyCode
                ? formatCurrency(dividends.currencyCode, dividends.proposedDividendAllowed)
                : '--'
            }
          />
          <SpanWithLabel
            labelText="Dividends from Retained Earnings"
            value={
              dividends.currencyCode
                ? formatCurrency(dividends.currencyCode, dividends.dividendsFromRetainedEarnings)
                : '--'
            }
          />
          <SpanWithLabel
            labelText="Reason Proposed Is Less Than Max"
            value={dividends.lessDividendReason?.fieldName}
          />
          <SpanWithLabel labelText="Currency Code" value={dividends.currencyCode} />
        </StackColumn>
      </Stack>
    </Card>
  );
};

export default DividendsCardContent;
