import React, { useState } from 'react';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CompanyDetailGeneral } from '..';
import CompanyDetailServiceContacts from '../serviceContacts/CompanyDetailServiceContacts';
import CompanyDetailProcessesTypeList from './CompanyDetailProcessesTypeList';
import ProcessDetail from '../processes/ProcessDetail';
import CompanyDetailProcessesInstanceList from './CompanyDetailProcessesInstanceList';
import { GetCompanyByCompanyCode } from '../../../utils/statApi/CompaniesApi';
import ICompany from '../../../utils/types/ICompany';
import CompanyHeader from '../header/CompanyHeader';
import AnnualFilingRequirements from '../annualFilingRequirements/AnnualFilingRequirements';
import FiscalYearList from '../fiscalYears/FiscalYearList';
import FiscalYearDetail from '../fiscalYears/FiscalYearDetail';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ProcessConfigListPage from '../processConfigs/ProcessConfigListPage';
import CompanyDocumentsPage from '../documents/CompanyDocumentsPage';
import ProcessConfigDetail from '../processConfigs/ProcessConfigDetail';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import DividendsReview from '../fiscalYears/dividendsReview/DividendsReview';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/UseLocalStorage';
import { IRecentCompaniesCardItem } from '../../../utils/types/IRecentCompaniesCardItem';
import { CompanyProvider } from '../CompanyContext';
import AgmReview from '../fiscalYears/AgmReview';
import Page from '../../common/page/Page';

const CompanyDetail: React.FunctionComponent = () => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const [company, setCompany] = useState<ICompany>();
  const [recentCompanies, setRecentCompanies] = useLocalStorage(
    LocalStorageKeys.recentCompanies,
    [] as IRecentCompaniesCardItem[],
  );
  const { data, loading, error } = useQuery(GetCompanyByCompanyCode, {
    variables: { companyCode },
    onCompleted: (freshData) => {
      setRecentCompanies([
        { ...freshData?.company },
        ...recentCompanies
          .filter((comp) => comp.companyCode !== freshData.company.companyCode)
          .slice(0, 7),
      ]);
      setCompany(freshData?.company);
    },
  });

  if (error && error.message.indexOf('The company was not found with that id') !== -1) {
    return <Redirect to="/companies?NotFound=true" />;
  }

  const companyData = data?.company || {};

  return (
    <CompanyProvider value={{ company, setCompany }}>
      <CompanyHeader company={company} />
      <LoadingErrorMessage loading={loading} error={error} />
      <Switch>
        <Route
          path="/companies/:companyCode"
          exact
          render={(props): JSX.Element => <CompanyDetailGeneral {...props} company={companyData} />}
        />
        <Page
          path="/companies/:companyCode/company-information"
          exact
          render={(props): JSX.Element => <CompanyDetailGeneral {...props} company={companyData} />}
          title={`${companyCode} | Company Info`}
        />
        <Page
          path="/companies/:companyCode/annual-filing-requirements"
          exact
          component={AnnualFilingRequirements}
          title={`${companyCode} | Annual Filing Requirements`}
        />
        <Page
          path="/companies/:companyCode/processes"
          exact
          component={CompanyDetailProcessesTypeList}
          title={`${companyCode} | Process Types`}
        />
        <Page
          path="/companies/:companyCode/processes/configs"
          exact
          component={ProcessConfigListPage}
          requestedPermission="ProcessConfiguration:List"
          title={`${companyCode} | Process Configs`}
        />
        <Page
          path="/companies/:companyCode/processes/configs/:processConfigId"
          exact
          component={ProcessConfigDetail}
          requestedPermission="ProcessConfiguration:View"
          title={`${companyCode} | Process Config Detail`}
        />
        <Page
          path="/companies/:companyCode/processes/:processTypeUri"
          exact
          component={CompanyDetailProcessesInstanceList}
          title={`${companyCode} | Processes`}
        />
        <Page
          path="/companies/:companyCode/processes/:processTypeUri/:processId"
          exact
          component={ProcessDetail}
          title={`${companyCode} | Process Detail`}
        />
        <Page
          path="/companies/:companyCode/service-contacts"
          exact
          component={CompanyDetailServiceContacts}
          title={`${companyCode} | Service Contacts`}
        />
        <Page
          path="/companies/:companyCode/fiscal-years"
          exact
          component={FiscalYearList}
          title={`${companyCode} | Fiscal Years`}
        />
        <Page
          path="/companies/:companyCode/fiscal-years/:fiscalYearId"
          exact
          component={FiscalYearDetail}
          title={`${companyCode} | Fiscal Year Detail`}
        />
        <Page
          path="/companies/:companyCode/dividends-review/:dividendReviewId"
          exact
          component={DividendsReview}
          title={`${companyCode} | Dividends Review`}
        />
        <Page
          path="/companies/:companyCode/documents"
          exact
          component={CompanyDocumentsPage}
          title={`${companyCode} | Documents`}
        />
        <Page
          path="/companies/:companyCode/agm-review/:agmReviewId"
          exact
          component={AgmReview}
          title={`${companyCode} | AGM Review`}
        />
      </Switch>
    </CompanyProvider>
  );
};

export default CompanyDetail;
