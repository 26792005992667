import React, { useContext, useState, useEffect } from 'react';
import { INotificationItem } from '@coherence-design-system/notification-center';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Switch } from 'react-router-dom';
import { MessageBarType } from '@fluentui/react';
import {
  applicationWrapper,
  mainContentContainer,
  mainContentContainerExtended,
} from '../app/common/styles/CommonStyleObjects';
import Navigation from './common/Navigation';
import UsersListPage from './users/UsersListPage';
import { CompanyGroupRoute } from './companyGroups';
import CompaniesRoute from './companies/routes/CompaniesRoute';
import useLocalStorage, { LocalStorageKeys } from '../utils/hooks/UseLocalStorage';
import DashboardPage from './dashboard/DashboardPage';
import OutageBanner from './common/OutageBanner';
import GET_FEATURE_FLAGS from '../utils/statApi/FeatureFlagApi';
import { FeatureFlagProvider } from '../utils/featureFlags/FeatureFlagContext';
import FeatureFlags from '../utils/featureFlags/FeatureFlags';
import ProcessExtensionRoute from './processes/processExtensions/ProcessExtensionRoute';
import StatFooter from './common/StatFooter';
import NotificationsPage from './notifications/NotificationsPage';
import NotificationContext from './notifications/NotificationContext';
import RolesPage from './roles/RolesPage';
import ProcessSearch from './processes/ProcessSearch';
import CountriesRoute from './countries/CountriesRoute';
import {
  convertNotificationToCoherence,
  IStatNotification,
  NotificationType,
} from './notifications/StatNotification';
import ProcessTemplateRoute from './processes/processTemplates/ProcessTemplateRoute';
import CompanyCreationRoute from './companyCreation/CompanyCreationRoute';
import { GET_NOTIFICATIONS } from '../utils/statApi/NotificationsApi';
import ReportsRoute from './reports/ReportsRoute';
import InvalidServiceContactsList from './invalidServiceContacts/InvalidServiceContactsList';
import DividendsReviewListPage from './companies/fiscalYears/dividendsReview/DividendsReviewListPage';
import DocumentAnalysisRoutes from './documentAnalysis/DocumentAnalysisRoutes';
import RoleDescriptionRoute from './roles/RoleDescriptionRoute';
import OutdatedUiBanner from './common/headers/OutdatedUiBanner';
import Page from './common/page/Page';
import ProtectedRoute from './authentication/ProtectedRoute';
import TilePage from './externalApps/tiles/TilePage';
import FeatureFlagged from './common/featureFlagged/featureFlagged';
import AgmTrackerListPage from './companies/fiscalYears/agm/AgmTrackerListPage';

interface IStatAppProps {
  setNotifications: (notifications: INotificationItem[]) => void;
  setStatNotifications: (statNotifications: IStatNotification[]) => void;
}

const App = ({ setNotifications, setStatNotifications }: IStatAppProps): JSX.Element => {
  const [navIsHidden, setNavIsHidden] = useLocalStorage(LocalStorageKeys.navIsHidden, true);
  const [dismissedNotifications, setDismissedNotifications] = useState([]);
  const {
    data: featureFlagData,
    loading: featureFlagsLoading,
    error: featureFlagsError,
  } = useQuery(GET_FEATURE_FLAGS, {
    fetchPolicy: 'network-only',
    pollInterval: 300000, // 5 minutes
  });

  const [getNotifications] = useLazyQuery(GET_NOTIFICATIONS, {
    onCompleted: (data) => {
      const notificationItems: INotificationItem[] = convertNotificationToCoherence(
        data?.notifications,
      );
      setStatNotifications(data?.notifications);
      setNotifications(notificationItems);
    },
  });

  useEffect(() => {
    getNotifications();
  }, []);

  const activeNotifications = useContext(NotificationContext).statNotifications.filter(
    (no) =>
      (no.notificationType?.fieldName === NotificationType.Banner ||
        no.notificationType?.fieldName === NotificationType.DismissibleBanner) &&
      new Date(no.startDate) <= new Date() &&
      new Date(no.endDate) >= new Date() &&
      !dismissedNotifications.find((dn) => dn.id === no.id),
  );

  const featureFlags: FeatureFlags = new FeatureFlags(featureFlagData?.featureFlags);

  return (
    <FeatureFlagProvider value={featureFlags}>
      <div className={applicationWrapper}>
        <Navigation navIsHidden={navIsHidden} setNavIsHidden={setNavIsHidden} />
        <main
          tabIndex={-1}
          className={navIsHidden ? mainContentContainer : mainContentContainerExtended}
        >
          {!featureFlagsLoading && !featureFlagsError && featureFlags?.flags.OutageBanner && (
            <OutageBanner
              message={featureFlags?.outageBannerMessage}
              messageType={MessageBarType.severeWarning}
            />
          )}
          {activeNotifications.map((criticalNotification) => {
            return (
              <OutageBanner
                key={criticalNotification.id}
                message={criticalNotification?.content}
                onDismiss={
                  criticalNotification.notificationType.fieldName ===
                  NotificationType.DismissibleBanner
                    ? () => {
                        setDismissedNotifications([
                          ...dismissedNotifications,
                          criticalNotification,
                        ]);
                      }
                    : null
                }
              />
            );
          })}
          <OutdatedUiBanner />
          <Switch>
            <Page
              path="/countries"
              component={CountriesRoute}
              requestedPermission="CountryConfiguration:AddEdit"
              title="Countries"
            />
            <Page
              path="/reports-dashboard"
              component={ReportsRoute}
              requestedPermission="Reports:View"
              title="Reports"
            />
            <ProtectedRoute
              path="/companies"
              component={CompaniesRoute}
              requestedPermission="CompaniesList:View"
            />
            <Page
              path="/users"
              component={UsersListPage}
              requestedPermission="UsersList:View"
              title="Security"
            />
            <ProtectedRoute
              path="/companyGroups"
              component={CompanyGroupRoute}
              requestedPermission="CompanyGroupList:View"
            />
            <Page
              path="/process-extensions"
              component={ProcessExtensionRoute}
              requestedPermission="ProcessesList:View"
              title="Process Extensions"
            />
            <ProtectedRoute
              path="/process-templates"
              component={ProcessTemplateRoute}
              requestedPermission="ProcessTemplate:View"
            />
            <Page
              path="/processes"
              component={ProcessSearch}
              requestedPermission="ProcessesList:View"
              title="Processes"
            />
            <Page
              path="/notifications"
              component={NotificationsPage}
              requestedPermission="Notification:AddEdit"
              title="Notifications"
            />
            <Page
              path="/role-actions"
              component={RolesPage}
              requestedPermission="Role:AddEdit"
              title="Role Actions"
            />
            <ProtectedRoute
              path="/company-creation"
              component={CompanyCreationRoute}
              requestedPermission="CompanyCreation:View"
            />
            <Page
              path="/invalid-contacts"
              component={InvalidServiceContactsList}
              requestedPermission="InvalidServiceContacts:View"
              title="Invalid Contacts"
            />
            <Page
              path="/agm-tracker"
              component={AgmTrackerListPage}
              requestedPermission="AgmTracker:View"
              title="AGM"
            />
            <Page
              path="/dividends-reviews"
              component={DividendsReviewListPage}
              requestedPermission="DividendsReviewList:View"
              title="Dividends Reviews"
            />
            <Page
              path="/document/analysis"
              component={DocumentAnalysisRoutes}
              requestedPermission="DocumentAnalysis:View"
              title="Document Analysis"
            />
            <ProtectedRoute
              path="/role-description"
              component={RoleDescriptionRoute}
              requestedPermission="RoleDescription:View"
            />
            <Page exact path="/" component={DashboardPage} title="Home" />
            <FeatureFlagged flagName="TilePage">
              <Page path="/tile" title="Tile" component={TilePage} />
            </FeatureFlagged>
          </Switch>
        </main>
      </div>
      <StatFooter />
    </FeatureFlagProvider>
  );
};

export default App;
