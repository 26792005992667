import React from 'react';
import { Label, NormalPeoplePicker, IPeoplePickerProps, IPersonaProps } from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import { IServiceContact } from '../../companies/serviceContacts/interfaces';
import { MessagingColors } from '../../../app/common/styles/CommonStyleObjects';

export interface IErrorHandlingPeoplePickerProps extends IPeoplePickerProps {
  controlName: string;
  labelText?: string;
  errorMessage?: string;
  required?: boolean;
  setErrorMessage?: (stateKey: string, errorMessage: string) => void;
}

const errorStyles = {
  text: {
    borderColor: MessagingColors.errorBlockIcon,
  },
};

export const addLabelToInvalidPeople = (people: IPersonaProps[]) => {
  return people?.map((person: IServiceContact) => {
    const persona: IServiceContact = {
      key: person.key,
      graphId: person.graphId,
      name: person.name,
      text: person.isInvalidUser ? `${person.text} (Invalid User)` : person.text,
      firstName: person.firstName,
      lastName: person.lastName,
      email: person.email,
      alias: person.alias,
      contactType: person.contactType,
    };
    return persona;
  });
};

const ErrorHandlingPeoplePicker = (props: IErrorHandlingPeoplePickerProps): JSX.Element => {
  const {
    controlName,
    labelText,
    errorMessage,
    required: isRequired,
    setErrorMessage,
    selectedItems,
    defaultSelectedItems,
    disabled,
  } = props;

  const pickerErrorStyles = errorMessage ? errorStyles : {};

  const validate = (): void => {
    if (setErrorMessage) {
      if (isRequired && (!selectedItems || selectedItems.length < 1)) {
        setErrorMessage(controlName, `You must add at least one ${labelText}`);
      } else {
        setErrorMessage(controlName, '');
      }
    }
  };

  // TODO: We should look to eliminate two separate maps here when we refactor ContactGroupsEditPanel to not use defaultSelectedItems
  const updatedSelectedItems = addLabelToInvalidPeople(selectedItems);
  const updateDefaultSelectedItems = addLabelToInvalidPeople(defaultSelectedItems);

  return (
    <>
      {labelText && (
        <Label disabled={disabled} htmlFor={`${controlName}Picker`} required={isRequired}>
          {labelText}
        </Label>
      )}
      <NormalPeoplePicker
        inputProps={{
          title: labelText,
          'aria-label': `${labelText} People Picker`,
          name: controlName,
          id: `${controlName}Picker`,
          required: isRequired,
        }}
        styles={pickerErrorStyles}
        {...props}
        onBlur={validate}
        selectedItems={updatedSelectedItems}
        defaultSelectedItems={updateDefaultSelectedItems}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </>
  );
};

export default ErrorHandlingPeoplePicker;
