import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ActionButton, IColumn, PrimaryButton } from '@fluentui/react';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import FullWidthHeader from '../common/headers/FullWidthHeader';
import Subheader from '../common/headers/Subheader';
import { bodyContentContainer } from '../../app/common/styles/CommonStyleObjects';
import CanAccess from '../common/canAccess/CanAccess';
import PageHeaderWithActionButtons from '../common/headers/PageHeaderWithActionButtons';
import { GET_ROLE_ACTIONS_QUERY, RoleActions } from '../../utils/statApi/RolesApi';
import ShimmeredDetailsListWrapper from '../common/DetailsLists/ShimmeredDetailsListWrapper';
import RoleActionsPanel from './RoleActionsPanel';

const RolesPage = (): JSX.Element => {
  const [isAddPanelOpen, setIsAddPanelOpen] = useState<boolean>(false);
  const [selectedRoleAction, setSelectedRoleAction] = useState(null);
  const { data, loading, error } = useQuery(GET_ROLE_ACTIONS_QUERY);

  const roleActions: RoleActions[] = data?.roleActions;

  const renderHeader = (): JSX.Element => {
    const button = (
      <CanAccess requestedAction="Role:AddEdit">
        <PrimaryButton
          text="Add New Role"
          onClick={() => {
            setIsAddPanelOpen(true);
          }}
        />
      </CanAccess>
    );

    return <PageHeaderWithActionButtons title="Role Actions" actionButtons={button} />;
  };

  const renderSubtitle = (): JSX.Element => <Subheader title="Action Assignment" />;

  const onRenderNotificationHeader = (roleAction: RoleActions): JSX.Element => {
    return (
      <ActionButton
        className="user-link"
        onClick={() => {
          setSelectedRoleAction(roleAction);
          setIsAddPanelOpen(true);
        }}
      >
        {roleAction?.role?.name}
      </ActionButton>
    );
  };

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Role Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: false,
      data: 'string',
      onRender: onRenderNotificationHeader,
    },
  ];

  const onPanelClose = () => {
    setIsAddPanelOpen(false);
    setSelectedRoleAction(null);
  };

  return (
    <>
      <FullWidthHeader title={renderHeader} subtitle={renderSubtitle} />
      <LoadingErrorMessage loading={loading} error={error} actionName="loading the roles" />
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <ShimmeredDetailsListWrapper
          columns={columns}
          items={roleActions || []}
          enableShimmer={loading}
          singularListLabel="Role"
          pluralListLabel="Roles"
        />
      </div>
      {isAddPanelOpen && (
        <RoleActionsPanel roleActions={selectedRoleAction} closePanel={onPanelClose} />
      )}
    </>
  );
};

export default RolesPage;
