import React from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import ActionButtons from '../../common/ActionButtons';
import CanAccess from '../../common/canAccess/CanAccess';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import IAnnualFilingRequirements from '../../../utils/types/IAnnualFilingRequirements';
import { UPDATE_AUDIT_INFORMATION_MUTATION } from '../../../utils/statApi/AnnualFilingRequirementsApi';
import AuditInformationForm from '../detail/forms/AuditInformationForm';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';

export interface IAuditInformationEditPanelProps {
  annualFilingRequirements: IAnnualFilingRequirements;
  closePanel: () => void;
}
const AuditInformationEditPanel: React.FunctionComponent<IAuditInformationEditPanelProps> = ({
  closePanel,
  annualFilingRequirements,
}: IAuditInformationEditPanelProps): JSX.Element => {
  const auditInformationForm = useForm();

  const { errors, handleSubmit } = auditInformationForm;

  const { companyCode } = useParams<ICompanyCodeParams>();

  const [updateAuditInformation, { loading, error }] = useMutation(
    UPDATE_AUDIT_INFORMATION_MUTATION,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const handleOnSaveClick = (formData: IAnnualFilingRequirements): void => {
    // GraphQL types don't like the typename property

    const payload = {
      id: annualFilingRequirements.id,
      auditFilingDateMonth: formData.auditFilingDateMonth,
      auditFilingDateDay: formData.auditFilingDateDay,
      auditFrequency: {
        id: formData.auditFrequency?.id,
        fieldName: formData.auditFrequency?.fieldName,
      },
      auditingFirm: {
        id: formData.auditingFirm?.id,
        fieldName: formData.auditingFirm?.fieldName,
      },
      otherAuditingFirm: formData.otherAuditingFirm,
    };

    if (!Object.keys(errors).length) {
      updateAuditInformation({
        variables: {
          companyCode,
          auditInformation: payload,
        },
      });
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Financial Statement Audit Information"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Financial Statement Audit Information Edit"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="AnnualFilingRequirements:Edit">
          <ActionButtons
            mutationLoading={loading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSaveClick)}
            saveLabel="Save Financial Statement Audit Information"
            saveTitle="Save Financial Statement Audit Information"
            cancelLabel="Cancel Financial Statement Audit Information Edit"
            cancelTitle="Cancel Financial Statement Audit Information Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={loading}
          error={error}
          actionName="Saving the Financial Statement Audit Information"
        />
        <AuditInformationForm
          annualFilingRequirements={annualFilingRequirements}
          form={auditInformationForm}
        />
      </form>
    </CoherencePanel>
  );
};
export default AuditInformationEditPanel;
