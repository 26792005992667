import { useQuery } from '@apollo/client';
import { Dropdown, IDropdownOption, Spinner } from '@fluentui/react';
import React, { FormEvent } from 'react';
import { GET_MS_REPORTING_SALES_LOCATIONS } from '../../../utils/statApi/CompanyCreationApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';

interface IMsReportingSalesLocationDropDownProps {
  defaultValue: string;
  onChange: (event: FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void;
}

const MsReportingSalesLocationDropDown = ({
  defaultValue,
  onChange,
}: IMsReportingSalesLocationDropDownProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_MS_REPORTING_SALES_LOCATIONS);

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      {loading && !error && (
        <>
          <br />
          <Spinner label="Loading Reporting Locations" />
        </>
      )}
      {!loading && !error && (
        <Dropdown
          defaultSelectedKey={defaultValue}
          label="Sales Location for MS Reporting Region"
          options={data?.msReportingSalesLocations}
          calloutProps={{ calloutMaxHeight: 215 }}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default MsReportingSalesLocationDropDown;
