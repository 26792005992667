import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import getColumns from './ProcessExtensionsList.config';
import ShimmeredDetailsListWrapper from '../../common/DetailsLists/ShimmeredDetailsListWrapper';
import IItemListProps from '../../../utils/types/IItemListProps';
import momentUtc from '../../../utils/DateFormatter';
import StatDateFormats from '../../../utils/types/DateFormats';
import IProcessExtensionsListItem from '../../../utils/types/IProcessExtensionsListItem';
import ErrorMessage from '../../common/errorContent/ErrorMessage';

const ProcessExtensionsList = (props: IItemListProps<IProcessExtensionsListItem>): JSX.Element => {
  const { items, isLoading } = props;
  const location = useLocation();

  const onRenderProcessName = (item: { id: number; processName: string }): JSX.Element => {
    return <Link to={`/process-extensions/${item.id}/review`}>{item.processName}</Link>;
  };

  const onRenderRequestedDate = (item: { requestedDate: Date }): JSX.Element => {
    return <span>{`${momentUtc(item?.requestedDate, StatDateFormats.DayBreakout)}`}</span>;
  };

  const onRenderOldFilingDueDate = (item: { oldFilingDueDate: Date }): JSX.Element => {
    return <span>{`${momentUtc(item.oldFilingDueDate, StatDateFormats.DayBreakout)}`}</span>;
  };

  const onRenderReportingPeriodEndDate = (item: { reportingPeriodEndDate: Date }): JSX.Element => {
    return <span>{`${momentUtc(item.reportingPeriodEndDate, StatDateFormats.DayBreakout)}`}</span>;
  };

  const columns = getColumns({
    onRenderProcessName,
    onRenderRequestedDate,
    onRenderOldFilingDueDate,
    onRenderReportingPeriodEndDate,
  });

  const getNotFoundMessage = (): boolean => {
    const result = new URLSearchParams(location.search).get('NotFound');
    return result === 'true';
  };

  return (
    <>
      {getNotFoundMessage() && (
        <ErrorMessage>
          <span>The process extension was not found or you do not have access.</span>
        </ErrorMessage>
      )}
      <ShimmeredDetailsListWrapper
        columns={columns}
        items={items || []}
        enableShimmer={isLoading}
        singularListLabel="Process Extension"
        pluralListLabel="Process Extensions"
      />
    </>
  );
};
export default ProcessExtensionsList;
