import React, { useRef, useState, useContext, useEffect } from 'react';

import {
  ProgressIndicator,
  MessageBar,
  MessageBarType,
  StackItem,
  Stack,
  PrimaryButton,
  DefaultButton,
  Announced,
} from '@fluentui/react';
import { useParams } from 'react-router-dom';

import FileSelectorItem from './fileSelectorItem';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import UserContext from '../../../../utils/authorization/UserContext';
import { addTimestampBeforeExtension } from '../../../../utils/Helpers';
import validateFile from '../../../../utils/validation/validateFiles';
import { ICompanyCodeParams } from '../../ParamTypes';
import { UploadDocument, IDocument } from '../../../../utils/statApi/DocumentApi';

enum UploadStatus {
  Ready,
  Uploading,
  Error,
  Success,
}

interface IFileSelectorProps {
  onFileUploadComplete: (files: IDocument[]) => void;
  onFileUploading: () => void;
  onFileUploadError: () => void;
  errorMessage?: string;
  autoUpload?: boolean;
  allowMultiple?: boolean;
  uploadMutationLoading?: boolean;
  mutationSuccessMessage?: string;
}

const FileListUpload: React.FC<IFileSelectorProps> = (props: IFileSelectorProps) => {
  const {
    onFileUploadComplete,
    onFileUploading,
    onFileUploadError,
    errorMessage,
    autoUpload = false,
    allowMultiple = true,
    uploadMutationLoading,
    mutationSuccessMessage,
  } = props;
  const user: StatUser = useContext(UserContext);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { companyCode } = useParams<ICompanyCodeParams>();

  const [fileUploadState, setFileUploadState] = useState({
    uploadStatus: UploadStatus.Ready,
    errorMessage: null,
    uploadProgress: 0,
    selectedFiles: [],
    payloadSize: 0,
  });
  const [announcedMessage, setAnnouncedMessage] = useState('');
  const showFileDialog = (): void => inputFileRef.current && inputFileRef.current.click();

  const errorTextStyles = { fontSize: 12, fontWeight: 400, color: '#A4262C' };
  const onProgress = (percentage: number): void => {
    setFileUploadState({
      uploadStatus: UploadStatus.Uploading,
      uploadProgress: percentage,
      errorMessage: null,
      selectedFiles: fileUploadState.selectedFiles,
      payloadSize: fileUploadState.payloadSize,
    });
  };
  const onError = (serverErrorMessage: string): void => {
    setFileUploadState({
      uploadProgress: 0,
      uploadStatus: UploadStatus.Error,
      errorMessage: serverErrorMessage,
      selectedFiles: fileUploadState.selectedFiles,
      payloadSize: fileUploadState.payloadSize,
    });
    onFileUploadError();
    inputFileRef.current.value = null;
  };
  const onSuccess = (files: IDocument[]): void => {
    setFileUploadState({
      uploadProgress: 0,
      uploadStatus: UploadStatus.Success,
      errorMessage: null,
      selectedFiles: [],
      payloadSize: 0,
    });
    onFileUploadComplete(files);

    inputFileRef.current.value = null;
  };

  const getFilesMessage = (filesToUpload: number, filesWithErrors: number): string => {
    let message = '';
    if (filesToUpload - filesWithErrors > 0) {
      message = `${filesToUpload - filesWithErrors} ${
        filesToUpload - filesWithErrors === 1 ? 'file' : 'files'
      } ready to upload. `;
    }
    if (filesWithErrors > 0) {
      message = `${message}${filesWithErrors} ${
        filesWithErrors === 1 ? 'file has' : 'files have'
      } errors.`;
    }
    return message;
  };

  const onFileUpload = (): void => {
    if (fileUploadState.selectedFiles === null) {
      return;
    }
    setFileUploadState({
      uploadProgress: 0,
      errorMessage: null,
      uploadStatus: UploadStatus.Uploading,
      selectedFiles: fileUploadState.selectedFiles,
      payloadSize: fileUploadState.payloadSize,
    });
    onFileUploading();

    UploadDocument(
      fileUploadState.selectedFiles,
      fileUploadState.payloadSize,

      onProgress,
      onError,
      onSuccess,
      companyCode,
    );
  };

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files || !event.target.files.length || event.target.files.length === 0) {
      return;
    }
    let filesToUpload = fileUploadState.selectedFiles;

    let { payloadSize } = fileUploadState;

    for (let index = 0; index < event.target.files.length; index += 1) {
      const file = event.target.files.item(index);
      const fileNameWithTimestamp = addTimestampBeforeExtension(file.name);
      const newFileObject = {
        file,
        fileNameWithTimeStamp: fileNameWithTimestamp,
        fileName: file.name,
        fileType: file.type,
        validationErrors: validateFile(file),
      };
      filesToUpload = filesToUpload.concat([newFileObject]);
      payloadSize += file.size;
    }
    const filesWithErrors = filesToUpload.filter(
      (file) => file.validationErrors && file.validationErrors.length > 0,
    );
    const uploadStatus =
      filesWithErrors && filesWithErrors.length > 0 ? UploadStatus.Error : UploadStatus.Ready;

    const message = getFilesMessage(filesToUpload.length, filesWithErrors.length);
    setAnnouncedMessage(message);

    setFileUploadState({
      uploadStatus,
      errorMessage: null,
      uploadProgress: 0,
      selectedFiles: filesToUpload,
      payloadSize,
    });
  };

  useEffect(() => {
    if (
      autoUpload &&
      fileUploadState.selectedFiles &&
      fileUploadState.selectedFiles?.length &&
      fileUploadState.uploadStatus !== UploadStatus.Error
    ) {
      onFileUpload();
    }
  }, [fileUploadState.selectedFiles]);

  const removeFileFromSelected = (index: number): void => {
    const allFiles = fileUploadState.selectedFiles;
    const removedItem = allFiles.splice(index, 1);
    const payloadSize = fileUploadState.payloadSize - removedItem[0].file.size;
    const filesWithErrors = allFiles.filter(
      (file) => file.validationErrors && file.validationErrors.length > 0,
    );
    const uploadStatus =
      filesWithErrors && filesWithErrors.length > 0 ? UploadStatus.Error : UploadStatus.Ready;
    setAnnouncedMessage('');
    setFileUploadState({
      uploadProgress: 0,
      errorMessage: null,
      uploadStatus,
      selectedFiles: allFiles,
      payloadSize,
    });
    inputFileRef.current.value = null;
  };
  const uiDisabled = fileUploadState.uploadStatus === UploadStatus.Uploading;
  const renderFilesList = (): JSX.Element => {
    const files = fileUploadState.selectedFiles;
    if (!files || files.length === 0) {
      return <></>;
    }

    return (
      <Stack tokens={{ childrenGap: 20 }}>
        {files.map((file, index): JSX.Element => {
          return (
            <StackItem key={file.fileName}>
              <FileSelectorItem
                index={index}
                user={user}
                fileName={file.fileName}
                validationErrors={file.validationErrors}
                onRemoveFromSelected={removeFileFromSelected}
                uiDisabled={uiDisabled}
                activity="Ready to upload"
              />
            </StackItem>
          );
        })}
      </Stack>
    );
  };

  return (
    <Stack tokens={{ childrenGap: 20 }} styles={{ root: { flexBasis: 0, width: '100%' } }}>
      <span style={errorTextStyles}>{errorMessage}</span>
      {renderFilesList()}
      <Announced message={announcedMessage} style={{ marginTop: 0 }} />
      <Stack tokens={{ childrenGap: 20 }} horizontal horizontalAlign="center">
        <DefaultButton
          type="button"
          onClick={(): void => showFileDialog()}
          disabled={uiDisabled}
          text="Select file(s) to upload"
        />
        {fileUploadState.selectedFiles && fileUploadState.selectedFiles.length > 0 && (
          <PrimaryButton
            onClick={(): void => onFileUpload()}
            disabled={uiDisabled || fileUploadState.uploadStatus === UploadStatus.Error}
            text="Upload"
          />
        )}
      </Stack>
      {fileUploadState.uploadStatus === UploadStatus.Uploading && (
        <ProgressIndicator
          label="Uploading"
          description="Uploading files to storage"
          percentComplete={fileUploadState.uploadProgress}
        />
      )}
      {fileUploadState.uploadStatus === UploadStatus.Success &&
        uploadMutationLoading === false &&
        mutationSuccessMessage !== null &&
        !errorMessage && (
          <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
            {mutationSuccessMessage}
          </MessageBar>
        )}
      {fileUploadState.uploadStatus === UploadStatus.Error && fileUploadState.errorMessage && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline>
          {fileUploadState.errorMessage}
        </MessageBar>
      )}
      <input
        style={{ display: 'none' }}
        ref={inputFileRef}
        type="file"
        multiple={allowMultiple}
        onChange={onFileInputChange}
      />
    </Stack>
  );
};
export default FileListUpload;
