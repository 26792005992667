import { ChoiceGroup, Label, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { OTHER } from '../../../app/common/constants/SiteConstants';
import { DoubleRequiredMarker } from '../../common/formFields/RequiredMarker';
import AuditingFirmsDropdown from '../../companies/detail/forms/AuditingFirmsDropdown';
import OversightGroupDropdown from '../../companies/fiscalYears/OversightGroupDropdown';
import RequiredFieldsNotification from '../RequiredNotification';
import { IStatutoryInformation, RequiredFieldsType } from './types';
import { yesNoOptions } from '../../../utils/types/CommonDropdownOptions';

interface IStatutoryInformationFormProps {
  form: UseFormMethods<IStatutoryInformation>;
  data: IStatutoryInformation;
}

const requiredLabelStyles = { paddingBottom: 0 };

const StatutoryInformationForm = ({ data, form }: IStatutoryInformationFormProps): JSX.Element => {
  const { control, errors, setValue, watch } = form;

  const auditingFirm = watch('auditingFirm');

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <RequiredFieldsNotification type={RequiredFieldsType.StatSetup} />
      <Controller
        name="oversightGroup"
        control={control}
        defaultValue={data?.oversightGroup}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Oversight Group <DoubleRequiredMarker />
            </Label>
            <OversightGroupDropdown
              valueKey="oversightGroup"
              ariaLabel="Oversight Group Dropdown"
              errors={errors}
              handleChange={(newValue: string, valueKey: string): void => {
                setValue('oversightGroup', newValue);
              }}
              value={value}
            />
          </>
        )}
      />
      <Controller
        name="isMcaps"
        defaultValue={data?.isMcaps?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                MCAPS Flag? <DoubleRequiredMarker />
              </Label>
              <ChoiceGroup
                id="isMcaps"
                name="isMcaps"
                options={yesNoOptions}
                defaultSelectedKey={data?.isMcaps?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('isMcaps', newValue.key.toString() === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="isOneFinance"
        defaultValue={data?.isOneFinance?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                One Finance Flag? <DoubleRequiredMarker />
              </Label>
              <ChoiceGroup
                id="isOneFinance"
                name="isOneFinance"
                options={yesNoOptions}
                defaultSelectedKey={data?.isOneFinance?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('isOneFinance', newValue.key.toString() === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="isFsAuditLegallyRequired"
        defaultValue={data?.isFsAuditLegallyRequired?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                F/S Audit Legally Required by Regulator? <DoubleRequiredMarker />
              </Label>
              <ChoiceGroup
                id="isFsAuditLegallyRequired"
                name="isFsAuditLegallyRequired"
                options={yesNoOptions}
                defaultSelectedKey={data?.isFsAuditLegallyRequired?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('isFsAuditLegallyRequired', newValue.key.toString() === 'true');
                }}
              />
            </>
          );
        }}
      />
      <Controller
        name="auditingFirm"
        defaultValue={data?.auditingFirm?.toString().toLowerCase()}
        control={control}
        render={({ onChange, onBlur, value, name }): React.ReactElement => (
          <>
            <Label style={requiredLabelStyles}>
              Auditing Firm <DoubleRequiredMarker />
            </Label>
            <AuditingFirmsDropdown
              valueKey="auditingFirm"
              ariaLabel="Auditing Firm Dropdown"
              errors={errors}
              value={value}
              handleChange={(newValue: string, valueKey: string): void => {
                setValue('auditingFirm', newValue);
                if (newValue !== OTHER) {
                  setValue('otherAuditingFirm', null);
                }
              }}
            />
          </>
        )}
      />
      <Label style={requiredLabelStyles}>
        Other Auditing Firm
        {auditingFirm?.fieldName === OTHER ? <DoubleRequiredMarker /> : null}
      </Label>
      <Controller
        as={TextField}
        id="otherAuditingFirm"
        name="otherAuditingFirm"
        control={control}
        disabled={auditingFirm ? auditingFirm.fieldName !== OTHER : true}
        defaultValue={data?.otherAuditingFirm || ''}
        errorMessage={errors?.otherAuditingFirm?.message}
        ariaLabel="Other Auditing Firm input"
        rules={{
          maxLength: {
            value: 75,
            message: 'Other Auditing Firm can only be a max of 75 characters',
          },
        }}
      />
      <Label style={requiredLabelStyles}>
        EIN Tax Number
        <DoubleRequiredMarker />
      </Label>
      <Controller
        as={TextField}
        id="einTaxNumber"
        name="einTaxNumber"
        control={control}
        defaultValue={data?.einTaxNumber || ''}
        errorMessage={errors?.einTaxNumber?.message}
        ariaLabel="EIN Tax Number input"
      />
      <Controller
        name="controllerMustBeFte"
        defaultValue={data?.controllerMustBeFte?.toString().toLowerCase()}
        control={control}
        render={() => {
          return (
            <>
              <Label style={requiredLabelStyles}>
                Controller Must Be FTE? <DoubleRequiredMarker />
              </Label>
              <ChoiceGroup
                id="controllerMustBeFte"
                name="controllerMustBeFte"
                options={yesNoOptions}
                defaultSelectedKey={data?.controllerMustBeFte?.toString().toLowerCase()}
                onChange={(e, newValue): void => {
                  setValue('controllerMustBeFte', newValue.key.toString() === 'true');
                }}
              />
            </>
          );
        }}
      />
    </Stack>
  );
};

export default StatutoryInformationForm;
