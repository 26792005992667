import React from 'react';
import { useQuery } from '@apollo/client';
import { FontIcon, mergeStyles, Stack } from '@fluentui/react';
import { Link, useParams } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import { ICompanyCodeParams } from '../../common/ParamTypes';
import CanAccess from '../../common/canAccess/CanAccess';
import PROCESS_TYPE_LIST_QUERY from '../../../utils/statApi/ProcessTypesApi';
import { IProcessType } from '../../../utils/types/IProcessType';
import ProcessTypeList from '../../processes/processTypes/ProcessTypeList';

const CompanyDetailProcessesTypeList = (): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const { loading, data, error } = useQuery(PROCESS_TYPE_LIST_QUERY, {
    variables: {
      companyCode,
    },
  });
  const { processTypesForCompany } = data || {};
  const renderProcessTypeNameLink = (item: IProcessType): JSX.Element => {
    return <Link to={`/companies/${companyCode}/processes/${item.uri}`}>{item.name}</Link>;
  };

  const settingsIconStyle = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
  });

  const getBodyHeaderTitle = (): JSX.Element => {
    return <h2>Active Process Types</h2>;
  };
  return (
    <>
      <div className={`${bodyContentContainer}  ms-depth-4`}>
        <div className={bodyContentWrapper}>
          <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
            <CanAccess requestedAction="ProcessConfiguration:List">
              <Link to={`/companies/${companyCode}/processes/configs`}>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 5 }}>
                  <FontIcon
                    iconName="settings"
                    className={settingsIconStyle}
                    aria-label="Process Config Link"
                  />
                  <span>Process Configs</span>
                </Stack>
              </Link>
            </CanAccess>
          </BodyHeaderWithCommandBar>
        </div>
        <ProcessTypeList
          processTypes={processTypesForCompany}
          renderProcessTypeNameLink={renderProcessTypeNameLink}
          loading={loading}
          error={error}
          emptyStateMessage="There are no process types for this company"
        />
      </div>
    </>
  );
};

export default CompanyDetailProcessesTypeList;
