import React, { useState, useContext } from 'react';
import { ActionButton, FontIcon } from '@fluentui/react';
import { IMilestoneRule, IFinancialStatementProcess } from '../Interfaces';
import { warningClass, iconClass, completeClass } from './Gate.Styles';
import ProcessDetailEditPanel from '../panels/ProcessDetailEditPanel';
import ProcessContext from '../ProcessContext';
import useIsMounted from '../../../common/IsMountedHook';

interface IAuditOpinionGateProps {
  rule: IMilestoneRule;
  milestoneId: number;
  disabled: boolean;
}

const AuditOpinionGate = ({ rule, milestoneId, disabled }: IAuditOpinionGateProps): JSX.Element => {
  const [panelIsOpen, setIsPanelOpen] = useState(false);
  const data = useContext(ProcessContext);
  const isMounted = useIsMounted();

  const processDetail: IFinancialStatementProcess = data || {};

  const closePanel = (): void => {
    if (isMounted.current) {
      setIsPanelOpen(false);
    }
  };

  const buttonText = rule.isComplete ? 'View audit opinion ' : 'Update the audit opinion ';

  return (
    <>
      <ActionButton
        onClick={(): void => {
          setIsPanelOpen(true);
        }}
      >
        <FontIcon
          className={`${iconClass} ${rule.isComplete ? completeClass : warningClass}`}
          iconName={rule.isComplete ? 'checkmark' : 'warning'}
        />
        {buttonText}
      </ActionButton>
      {panelIsOpen && (
        <ProcessDetailEditPanel processDetails={processDetail} closePanel={closePanel} />
      )}
    </>
  );
};

export default AuditOpinionGate;
