import { useQuery } from '@apollo/client';
import { Dropdown, Stack, IDropdownOption } from '@fluentui/react';
import React from 'react';
import { GET_PROCESS_TYPES, GET_REVIEW_STATUSES } from '../../../utils/statApi/ProcessExtensionApi';
import { IProcessExtensionsListPageState } from './ProcessExtensionsListPage';

const dropDownStyles = { root: { width: '200px' } };

interface IRReviewStatusDropDownProps {
  initialSelection?: number[];
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ) => void;
}

const ReviewStatusDropDown = ({
  initialSelection,
  onChange,
}: IRReviewStatusDropDownProps): JSX.Element => {
  const { loading, data } = useQuery(GET_REVIEW_STATUSES);
  const { reviewStatuses } = !loading && data ? data : [];

  return (
    <Dropdown
      label="Status"
      multiSelect
      options={reviewStatuses}
      placeholder="No Filter Selected"
      selectedKeys={initialSelection}
      onChange={onChange}
      styles={dropDownStyles}
    />
  );
};

interface IProcessTypeDropDownProps {
  initialSelection?: number[];
  onChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ) => void;
}

const ProcessTypeDropDown = ({
  initialSelection,
  onChange,
}: IProcessTypeDropDownProps): JSX.Element => {
  const { loading, data } = useQuery(GET_PROCESS_TYPES);
  const { processTypes } = !loading && data ? data : [];

  return (
    <Dropdown
      label="Process Type"
      multiSelect
      options={processTypes}
      placeholder="No Filter Selected"
      selectedKeys={initialSelection}
      onChange={onChange}
      styles={dropDownStyles}
    />
  );
};

interface IListFilterProps {
  filterState: IProcessExtensionsListPageState;
  setFilterState: React.Dispatch<React.SetStateAction<IProcessExtensionsListPageState>>;
}

const ListFilter = ({ filterState, setFilterState }: IListFilterProps): JSX.Element => {
  const onReviewStatusChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    if (option.selected) {
      const selectedKeys = [...filterState.statusIds, option.key as number];
      setFilterState({ ...filterState, statusIds: selectedKeys });
    } else {
      const selectedKeys = [...filterState.statusIds].filter((key) => key !== option.key);
      setFilterState({ ...filterState, statusIds: selectedKeys });
    }
  };

  const onProcessTypeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    if (option.selected) {
      const selectedKeys = [...filterState.processTypeIds, option.key as number];
      setFilterState({ ...filterState, processTypeIds: selectedKeys });
    } else {
      const selectedKeys = [...filterState.processTypeIds].filter((key) => key !== option.key);
      setFilterState({ ...filterState, processTypeIds: selectedKeys });
    }
  };

  const onAssignmentFilterChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number,
  ): void => {
    if (option.selected) {
      const selectedKeys = [...filterState.assignments, option.key as string];
      setFilterState({ ...filterState, assignments: selectedKeys });
    } else {
      const selectedKeys = [...filterState.assignments].filter((key) => key !== option.key);
      setFilterState({ ...filterState, assignments: selectedKeys });
    }
  };

  const assignmentOptions: IDropdownOption[] = [
    { key: 'OWNER', text: 'Owner', selected: true },
    { key: 'APPROVER', text: 'Approver', selected: true },
  ];

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
        <Dropdown
          label="Assignment"
          multiSelect
          options={assignmentOptions}
          styles={dropDownStyles}
          placeholder="No Filter Selected"
          onChange={onAssignmentFilterChange}
        />
        <ReviewStatusDropDown
          initialSelection={filterState?.statusIds}
          onChange={onReviewStatusChange}
        />
        <ProcessTypeDropDown
          initialSelection={filterState?.processTypeIds}
          onChange={onProcessTypeChange}
        />
      </Stack>
    </Stack>
  );
};

export default ListFilter;
