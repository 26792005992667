import React, { useState } from 'react';
import { ActionButton, FontIcon } from '@fluentui/react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { IMilestoneRule } from '../Interfaces';
import { warningClass, iconClass, completeClass } from './Gate.Styles';
import useIsMounted from '../../../common/IsMountedHook';
import AgmDetailEditPanel from '../../fiscalYears/AgmDetailEditPanel';
import { ICompanyCodeParams, IProcessIdParams } from '../../../common/ParamTypes';
import { GET_PROCESS_AGM_DETAIL } from '../../../../utils/statApi/CompanyFiscalYearsApi';
import { GET_PROCESS_QUERY } from '../../../../utils/statApi/ProcessApi';

interface IAgmDetailGateProps {
  rule: IMilestoneRule;
}

const AgmDetailGate = ({ rule }: IAgmDetailGateProps): JSX.Element => {
  const [panelIsOpen, setIsPanelOpen] = useState(false);
  const isMounted = useIsMounted();
  const { processId } = useParams<IProcessIdParams>();
  const { companyCode } = useParams<ICompanyCodeParams>();

  const { data, loading } = useQuery(GET_PROCESS_AGM_DETAIL, {
    variables: { processId: parseInt(processId, 10) },
    fetchPolicy: 'network-only',
  });

  const closePanel = (): void => {
    if (isMounted.current) {
      setIsPanelOpen(false);
    }
  };

  const linkButtonStyles = { padding: '0 4px', height: 40, display: 'flex', alignItems: 'center' };

  const buttonText = rule.isComplete ? 'View AGM Details' : 'Update AGM Details';
  return (
    <>
      {data?.processAgmDetail?.agmReviewId && (
        <Link
          to={`/companies/${companyCode}/agm-review/${data?.processAgmDetail?.agmReviewId}`}
          style={linkButtonStyles}
        >
          <FontIcon
            className={`${iconClass} ${rule.isComplete ? completeClass : warningClass}`}
            iconName={rule.isComplete ? 'checkmark' : 'warning'}
          />
          {buttonText}
        </Link>
      )}

      {!data?.processAgmDetail?.agmReviewId && (
        <ActionButton
          onClick={(): void => {
            setIsPanelOpen(true);
          }}
        >
          <FontIcon
            className={`${iconClass} ${rule.isComplete ? completeClass : warningClass}`}
            iconName={rule.isComplete ? 'checkmark' : 'warning'}
          />
          {buttonText}
        </ActionButton>
      )}
      {panelIsOpen && !loading && (
        <AgmDetailEditPanel
          agmDetail={data?.processAgmDetail}
          closePanel={closePanel}
          refetchQueries={[
            {
              query: GET_PROCESS_AGM_DETAIL,
              variables: { processId: parseInt(processId, 10) },
            },
            {
              query: GET_PROCESS_QUERY,
              variables: { processId: parseInt(processId, 10) },
            },
          ]}
        />
      )}
    </>
  );
};

export default AgmDetailGate;
