import React, { useState } from 'react';
import { NeutralColors } from '@fluentui/react';
import MilestoneCardActionButton from './MilestoneCardActionButton';
import { IMilestoneCardHeaderProps } from './MilestoneCardHeader';
import { TeachingBubbleProps } from '../../common/ActionButtons';

const MilestoneCardActions = (props: IMilestoneCardHeaderProps): JSX.Element => {
  const {
    onHeaderClick,
    iconClick,
    isDisabled,
    isCurrent,
    icon,
    title,
    actionText,
    showProcessExtensionWarning,
  } = props;
  const [showTeachingBubble, setShowTeachingBubble] = useState(false);
  const getHeaderTextColor = (): string => {
    return !isCurrent ? 'inherit' : NeutralColors.white;
  };

  const labelStyles = {
    fontWeight: 400,
    color: getHeaderTextColor(),
    marginRight: 20,
  };
  const getButtonText = (): string => {
    if (icon === 'undo') return 'Rescind';
    if (icon === 'cancel') return 'Cancel';
    return 'Signoff';
  };

  const toggleTeachingBubble = (): void => {
    setShowTeachingBubble(!showTeachingBubble);
  };

  const teachingBubbleProps: TeachingBubbleProps = {
    header: 'Extension Currently In Progress',
    content:
      'This process has an extension in progress and cannot be signed off until this request is completed.',
    showTeachingBubble,
    toggleTeachingBubble,
  };

  return (
    <>
      <div className="ms-hiddenMdDown" style={labelStyles}>
        {actionText}
      </div>
      <MilestoneCardActionButton
        isCurrent={isCurrent}
        iconProps={{
          iconName: icon,
        }}
        title={getButtonText()}
        ariaLabel={` ${getButtonText()} ${title}`}
        disabled={isDisabled}
        teachingBubbleProps={teachingBubbleProps}
        onClick={(ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
          ev.stopPropagation();
          onHeaderClick(false);
          if (showProcessExtensionWarning) {
            toggleTeachingBubble();
            return;
          }
          iconClick();
        }}
        onKeyUp={(ev: React.KeyboardEvent<HTMLDivElement>): void => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
      />
    </>
  );
};
export default MilestoneCardActions;
