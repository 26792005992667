import { useMutation } from '@apollo/client/react';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../app/common/styles/CommonStyleObjects';
import {
  CREATE_COUNTRY_CONFIGURATION,
  GET_COUNTRY_CONFIGS,
  UPDATE_COUNTRY_CONFIGURATION,
} from '../../utils/statApi/CountryApi';

import ActionButtons from '../common/ActionButtons';
import CanAccess from '../common/canAccess/CanAccess';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';
import CountryConfigForm from './CountryConfigForm';
import { ICountryConfiguration, ICountryConfigurationsResult } from './CountryConfiguration.config';

interface CountryConfigEditPanelProps {
  countryData: ICountryConfiguration;
  closePanel: () => void;
}

const CountryConfigEditPanel = ({
  countryData,
  closePanel,
}: CountryConfigEditPanelProps): JSX.Element => {
  const countryConfigForm = useForm();
  const { errors, handleSubmit } = countryConfigForm;

  const [createCountryConfiguration, { loading: createLoading, error: createError }] = useMutation(
    CREATE_COUNTRY_CONFIGURATION,
    {
      update(cache, { data }) {
        // get returned country config after save
        const newCountryConfiguration = data?.createCountryConfiguration;

        const queryData = cache.readQuery<ICountryConfigurationsResult>({
          query: GET_COUNTRY_CONFIGS,
        });

        const countryConfigurationsList: ICountryConfiguration[] = queryData?.countryConfigurations;

        // append new country config to list
        const allCountryConfigurations = [...countryConfigurationsList, newCountryConfiguration];
        allCountryConfigurations.sort((a: ICountryConfiguration, b: ICountryConfiguration) =>
          a.country.shortName.localeCompare(b.country.shortName),
        );

        cache.writeQuery({
          query: GET_COUNTRY_CONFIGS,
          data: {
            countryConfigurations: allCountryConfigurations,
          },
        });
      },
      onCompleted: () => {
        closePanel();
      },
    },
  );

  const [updateCountryConfiguration, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_COUNTRY_CONFIGURATION,
    {
      onCompleted: () => {
        closePanel();
      },
    },
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSaveClick = (formData: any): void => {
    const saveData: ICountryConfiguration = {
      id: parseInt(formData.id.toString(), 10) || -1,
      country: {
        id: formData.countries[0]?.id.toString(),
        shortName: formData.countries[0]?.shortName,
      },
      monthsToFileFromReportingPeriodEnd: parseInt(
        formData.monthsToFileFromReportingPeriodEnd?.toString(),
        10,
      ),
      workWeek: {
        id: formData.workWeek.id,
        fieldName: formData.workWeek.fieldName,
      },
      alignAgmWithFinancialStatement: formData.alignAgmWithFinancialStatement,
      electronicSignatureAcceptable: formData.electronicSignatureAcceptable,
      taxReturnDueDateMonth: formData.taxReturnDueDateMonth,
      taxReturnDueDateDay: formData.taxReturnDueDateDay,
      taxReportingPeriodDateMonth: formData.taxReportingPeriodDateMonth,
      taxReportingPeriodDateDay: formData.taxReportingPeriodDateDay,
      externalFilingRequired: formData.externalFilingRequired,
    };

    if (!Object.keys(errors).length) {
      if (saveData.id > 0) {
        updateCountryConfiguration({
          variables: {
            countryConfiguration: saveData,
          },
        });
      } else {
        createCountryConfiguration({
          variables: {
            countryConfiguration: saveData,
          },
        });
      }
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Country Configuration"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Country Configuration Panel"
      onRenderFooter={(): JSX.Element => (
        <CanAccess requestedAction="CountryConfiguration:AddEdit">
          <ActionButtons
            mutationLoading={false}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleSaveClick)}
            saveTitle="Save Country Configuration"
            saveLabel="Save Country Configuration"
            cancelTitle="Cancel Country Configuration Edit"
            cancelLabel="Cancel Country Configuration Edit"
          />
        </CanAccess>
      )}
    >
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <LoadingErrorMessage
          loading={createLoading}
          error={createError}
          actionName="creating the country configuration"
        />
        <LoadingErrorMessage
          loading={updateLoading}
          error={updateError}
          actionName="updating the country configuration"
        />
        <CountryConfigForm countryData={countryData} form={countryConfigForm} />
      </form>
    </CoherencePanel>
  );
};

export default CountryConfigEditPanel;
