import { useQuery } from '@apollo/client';
import { ITag } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { convertCountryToTag } from '../../../utils/Helpers';
import { GET_COUNTRIES_BY_CODE } from '../../../utils/statApi/CountryApi';
import ICountry from '../../../utils/types/ICountry';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import CountryPicker from '../../countries/CountryPicker';

interface IProcessSearchCountryPickerProps {
  initialSelectedCodes: Array<string>;
  onCountryUpdate: (items: ITag[]) => void;
}
const ProcessSearchCountryPicker = (props: IProcessSearchCountryPickerProps): JSX.Element => {
  const [initialSelectedItems, setInitialSelectedItems] = useState<ITag[]>();
  const { initialSelectedCodes, onCountryUpdate } = props;
  // no chache needed so that browsing back to this page rerenders the selected company
  const { data, loading, error } = useQuery(GET_COUNTRIES_BY_CODE, {
    fetchPolicy: 'no-cache',
    variables: { countryCodes: initialSelectedCodes },
  });
  const mapCountriesToTags = (countries: ICountry[]): ITag[] => {
    const countryTags = countries.map((country) => {
      return convertCountryToTag(country);
    });
    return countryTags;
  };
  useEffect(() => {
    if (!loading && data && data.countriesByCode) {
      setInitialSelectedItems(mapCountriesToTags(data.countriesByCode));
    }
  }, [data]);
  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      <CountryPicker
        selectedItems={initialSelectedItems}
        setSelectedItems={onCountryUpdate}
        isDisabled={false}
        errorMessage={null}
      />
    </>
  );
};
export default ProcessSearchCountryPicker;
