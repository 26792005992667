import { ITooltipHostStyles, IIconStyles } from '@fluentui/react';
import { IMilestone, MilestoneStatus } from '../Interfaces';
import { addBusinessDays } from '../../../../utils/Helpers';

interface IMinMaxDueDates {
  minMilestoneDateChange: Date;
  maxMilestoneDateChange: Date;
}

export const offsetTooltipHostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' },
};

export const offsetDateIconStyles: Partial<IIconStyles> = {
  root: { marginLeft: 5 },
};
const GetMinMaxDueDates = (milestones: IMilestone[], index: number): IMinMaxDueDates => {
  let minDate = null;
  let maxDate = null;

  const today = new Date();
  const currentMilestone = milestones[index];
  // Zero offset milestone's due date can be changed. The range is one business day after the previous milestone's due date and filing due date (inclusive)
  if (currentMilestone.maxDaysOffset === 0) {
    const filingDueDate = new Date(currentMilestone.processFilingDueDate);
    // The max due date should be the previous milestone's due date + the current milestone's max duration
    const nextMilestone = milestones[index + 1];
    const nextDueDate = nextMilestone
      ? addBusinessDays(new Date(nextMilestone.dueDate), -nextMilestone.minimumDuration)
      : filingDueDate;

    maxDate = nextDueDate < filingDueDate ? nextDueDate : filingDueDate;

    const dates: IMinMaxDueDates = {
      minMilestoneDateChange:
        index === 0
          ? today
          : addBusinessDays(
              new Date(milestones[index - 1].dueDate),
              milestones[index].minimumDuration,
            ),
      maxMilestoneDateChange: maxDate,
    };
    return dates;
  }
  // For milestones before the zero offset
  if (currentMilestone.maxDaysOffset < 0) {
    // The max due date should be the next milestone's due date - the next milestone's min duration
    maxDate = addBusinessDays(
      new Date(milestones[index + 1].dueDate),
      -milestones[index + 1].minimumDuration,
    );

    // The min due date should be the previous milestone's due date + the current milestone's min duration
    if (index > 0) {
      minDate = addBusinessDays(
        new Date(milestones[index - 1].dueDate),
        milestones[index].minimumDuration,
      );
    }
  }

  // For milestones after the zero offset
  if (currentMilestone.maxDaysOffset > 0) {
    // The max due date should be the filing due date + the current milestone's maximum days offset
    const maxDatePerTemplate = addBusinessDays(
      new Date(currentMilestone.processFilingDueDate),
      milestones[index].maxDaysOffset,
    );
    const nextMilestone = milestones[index + 1];
    const nextDueDate = nextMilestone
      ? addBusinessDays(new Date(nextMilestone.dueDate), -nextMilestone.minimumDuration)
      : maxDatePerTemplate;

    maxDate = nextDueDate < maxDatePerTemplate ? nextDueDate : maxDatePerTemplate;
    // The min due date should be the previous milestone's due date - the current milestone's min duration
    minDate = addBusinessDays(
      new Date(milestones[index - 1].dueDate),
      milestones[index].minimumDuration,
    );
  }

  const minMaxDueDates: IMinMaxDueDates = {
    minMilestoneDateChange: today > minDate ? today : minDate,
    maxMilestoneDateChange: maxDate,
  };
  return minMaxDueDates;
};

export const DueDateOffsetTooltipMessage = (minimumDuration: number): string => {
  const daysString = minimumDuration === 1 ? 'day' : 'days';
  return `This milestone has a minimum duration of ${Math.abs(
    minimumDuration,
  )} business ${daysString}. This will determine how far you can move the previous milestone due dates.`;
};

export const CanEditDueDate = (milestone: IMilestone): boolean => {
  return milestone.status !== MilestoneStatus.complete && new Date(milestone.dueDate) >= new Date();
};

export default GetMinMaxDueDates;
