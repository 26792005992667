import { ApolloError } from '@apollo/client';
import { Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { IProcessTemplate, IProcessTemplateInput } from '../../utils/types/IProcessTemplate';
import LoadingErrorMessage from '../common/errorContent/LoadingErrorMessage';

interface IProcessTemplateFormProps {
  mutationLoading: boolean;
  mutationError: ApolloError;
  processTemplate: IProcessTemplate;
  onSubmit: (processTemplateInput: IProcessTemplateInput) => void;
  form: UseFormMethods<Record<string, unknown>>;
}

const ProcessTemplateEditForm = (props: IProcessTemplateFormProps): JSX.Element => {
  const { processTemplate, form, mutationError, mutationLoading, onSubmit } = props;
  const { control, errors, setValue, handleSubmit } = form;

  return (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoadingErrorMessage error={mutationError} loading={mutationLoading} />
          <Controller
            as={TextField}
            label="Name"
            id="name"
            name="name"
            control={control}
            multiline
            resizable={false}
            defaultValue={processTemplate?.name}
            errorMessage={errors?.name?.message}
            required
            onChange={(event: React.FormEvent<HTMLInputElement>, newValue?: string) => {
              setValue('name', newValue);
            }}
            rules={{
              maxLength: {
                value: 50,
                message: 'Input is greater than the maximum character limit of 50.',
              },
              required: 'Please enter a name',
            }}
          />
          <Controller
            as={TextField}
            label="Description"
            id="description"
            name="description"
            control={control}
            multiline
            resizable={false}
            defaultValue={processTemplate?.description}
            errorMessage={errors?.description?.message}
            required
            onChange={(event: React.FormEvent<HTMLInputElement>, newValue?: string) => {
              setValue('description', newValue);
            }}
            rules={{
              maxLength: {
                value: 1000,
                message: 'Input is greater than the maximum character limit of 1000.',
              },
              required: 'Please enter a description',
            }}
          />
        </form>
      </Stack>
    </>
  );
};

export default ProcessTemplateEditForm;
