import { useQuery } from '@apollo/client';
import {
  Checkbox,
  Dropdown,
  IconButton,
  IDropdownOption,
  IIconStyles,
  ITag,
  ITooltipHostStyles,
  Stack,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Controller, DeepPartial, useForm, useWatch } from 'react-hook-form';
import { PROCESS_TYPE_WITH_TEMPLATES_LIST_QUERY } from '../../../../utils/statApi/ProcessTypesApi';
import IDropdownReference from '../../../../utils/types/IDropdownReference';
import IProcessConfig from '../../../../utils/types/IProcessConfig';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import QueryBasedDropdown from '../../../common/formFields/queryBasedDropdown/QueryBasedDropdown';
import UtcDatePicker from '../../../common/utcDatePicker/UtcDatePicker';
import {
  filingDueDateDefaultDate,
  recurrenceOptions,
  reportingPeriodEndDateDefaultDate,
} from './CreateProcessConfigUtils';
import ProcessTemplateDropdown from './ProcessTemplateDropdown';

import InitialProcessDates from './InitialProcessDates';
import { ProcessType } from '../../processes/Interfaces';
import { GET_PROCESS_CONFIG_COMPANY_DEFAULT_DATES } from '../../../../utils/statApi/ProcessConfigApi';
import hasAccess from '../../../../utils/authorization/authorizationCheck';
import UserContext from '../../../../utils/authorization/UserContext';
import { StatUser } from '../../../../utils/statApi/UsersApi';
import ComplianceRequirementPicker from '../../../countries/ComplianceRequirementPicker';
import { IComplianceRequirement } from '../../../countries/CountryConfiguration.config';
import FeatureFlagged from '../../../common/featureFlagged/featureFlagged';

interface ICreateProcessConfigDetailsFormProps {
  onValidated: (isValid: boolean) => void;
  setData: Dispatch<SetStateAction<IProcessConfig>>;
  data: IProcessConfig;
  onTemplateChange: (newTemplateId: number) => void;
  companyCode: string;
}
export const offsetTooltipHostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' },
};

export const offsetDateIconStyles: Partial<IIconStyles> = {
  root: { marginLeft: 5 },
};

const dropdownStyles = { dropdown: { width: 300 } };

const CreateProcessConfigDetailsForm = (
  props: ICreateProcessConfigDetailsFormProps,
): JSX.Element => {
  const { data, setData, onValidated, onTemplateChange, companyCode } = props;
  const [selectedComplianceRequirement, setSelectedComplianceRequirement] = useState(null);
  const user: StatUser = useContext(UserContext);
  const [reportingPeriodYear, setReportingPeriodYear] = useState(data.reportingPeriodYear);
  const [disableUseLastDayOfMonth, setDisableUseLastDayOfMonth] = useState(
    data.recurrence === 'ONETIME' || data.recurrence === 'WEEKLY',
  );

  const form = useForm<IProcessConfig>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: data as IProcessConfig,
  });

  const { errors, setValue, control, formState, getValues, trigger } = form;
  const { isValid, dirtyFields } = formState;

  const {
    loading: loadingProcessType,
    data: dataProcessType,
    error: errorProcessTypeQuery,
  } = useQuery(PROCESS_TYPE_WITH_TEMPLATES_LIST_QUERY);

  const { data: dataDefaultDates, loading: loadingDefaultDates } = useQuery(
    GET_PROCESS_CONFIG_COMPANY_DEFAULT_DATES,
    {
      fetchPolicy: 'no-cache',
      variables: { companyCode },
    },
  );

  const defaultDates = {
    processConfigCompanyDefaultDates: {
      statutoryYearEnd: null as string,
      auditFilingDueDate: null as string,
      hasDefaultCompanyAFSTemplate: false,
    },
  };

  const { processConfigCompanyDefaultDates } =
    !loadingDefaultDates && dataDefaultDates ? dataDefaultDates : defaultDates;

  const { hasDefaultCompanyAFSTemplate, statutoryYearEnd, auditFilingDueDate } =
    processConfigCompanyDefaultDates;

  const watchForm: DeepPartial<IProcessConfig> = useWatch({
    control,
    defaultValue: data as IProcessConfig,
  });

  const isAnnualFinancialStatements =
    getValues('processType')?.fieldName === ProcessType.AnnualFinancialStatements;

  const { permissions } = user;
  const hasAdminAccessToChangeDates =
    hasAccess(permissions, 'ProcessConfiguration:EditReportingPeriodEndDateAFS') &&
    hasAccess(permissions, 'ProcessConfiguration:EditFilingDueDateAFS');
  const { processTypesWithTemplates } =
    !loadingProcessType && dataProcessType ? dataProcessType : [];
  useEffect(() => {
    const reportingPeriodEndDate = getValues('reportingPeriodEndDate');
    const filingDueDate = getValues('filingDueDate');

    if (
      isAnnualFinancialStatements &&
      auditFilingDueDate &&
      statutoryYearEnd &&
      !reportingPeriodEndDate &&
      !filingDueDate
    ) {
      setValue('reportingPeriodEndDate', reportingPeriodEndDateDefaultDate(statutoryYearEnd), {
        shouldDirty: true,
      });
      setValue('filingDueDate', filingDueDateDefaultDate(auditFilingDueDate, statutoryYearEnd), {
        shouldDirty: true,
      });
      trigger();
    }
    onValidated(isValid);

    if (isValid) {
      setData({
        ...data,
        name: getValues('name'),
        processType: getValues('processType'),
        processTemplate: getValues('processTemplate'),
        recurrence: getValues('recurrence'),
        filingDueDate: getValues('filingDueDate'),
        reportingPeriodEndDate: getValues('reportingPeriodEndDate'),
        recurrenceEndDate: getValues('recurrenceEndDate'),
        useLastDayOfMonth: getValues('useLastDayOfMonth'),
        reportingPeriodYear,
        complianceRequirement: getValues('complianceRequirement'),
      });
    }
  }, [isValid, watchForm, reportingPeriodYear, dataDefaultDates]);

  const onComplianceRequirementUpdate = (items: ITag[]): void => {
    const complianceRequirement = items.map((item) => {
      const intId = typeof item.key === 'string' ? parseInt(item.key, 10) : item.key;
      const newObject: Partial<IComplianceRequirement> = {
        id: intId,
        name: item.name,
      };
      return newObject;
    });

    setSelectedComplianceRequirement(complianceRequirement);

    setValue('complianceRequirement', complianceRequirement[0], { shouldDirty: true });
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <FeatureFlagged flagName="CountryConfiguration">
        <Stack horizontal>
          <Controller
            name="complianceRequirement"
            control={control}
            defaultValue={[data?.complianceRequirement]}
            render={(): React.ReactElement => (
              <ComplianceRequirementPicker
                selectedItem={selectedComplianceRequirement}
                setSelectedItem={onComplianceRequirementUpdate}
              />
            )}
          />
          <TooltipHost
            content="Compliance Requirement that this process satisfies"
            id="tooltip"
            styles={offsetTooltipHostStyles}
          >
            <IconButton
              aria-describedby="tooltip"
              ariaLabel="Compliance Requirement Info"
              iconProps={{ iconName: 'Info' }}
              styles={offsetDateIconStyles}
            />
          </TooltipHost>
        </Stack>
      </FeatureFlagged>

      <Controller
        name="processType"
        label="Process Type"
        valueKey="processType"
        control={control}
        render={({ onChange }): React.ReactElement => (
          <>
            <LoadingErrorMessage
              loading={loadingProcessType}
              error={errorProcessTypeQuery}
              actionName="loading the process type"
            />
            <QueryBasedDropdown
              label="Process Type"
              value={getValues('processType')}
              valueKey="processType"
              dropdownOptionsData={processTypesWithTemplates}
              handleChange={(newValue: IDropdownReference, valueKey: string): void => {
                setValue('processType', newValue, { shouldDirty: true });
                setValue('processTemplate', null);
                setValue('reportingPeriodEndDate', null, { shouldDirty: true });
                setValue('filingDueDate', null, { shouldDirty: true });
                setValue('name', '', { shouldDirty: true });
                trigger();
                const resetYear = new Date().getFullYear();
                setReportingPeriodYear(resetYear);
              }}
              width="100%"
              errors={dirtyFields?.processType && errors}
              required
            />
          </>
        )}
        rules={{
          required: 'Please select a process type',
        }}
      />
      <Controller
        name="processTemplate"
        label="Process Template"
        valueKey="processTemplate"
        control={control}
        render={(): React.ReactElement => (
          <ProcessTemplateDropdown
            processTypeId={getValues('processType')?.id}
            selectedKey={getValues('processTemplate')}
            handleChange={(newValue: IDropdownReference) => {
              setValue('name', newValue.fieldName, { shouldDirty: true });
              onTemplateChange(newValue.id);
              setValue('processTemplate', newValue, { shouldDirty: true });
              trigger();
            }}
            errors={dirtyFields?.processTemplate && errors}
          />
        )}
        rules={{
          required: 'Please select a process template',
        }}
      />
      <Controller
        as={TextField}
        label="Name"
        id="name"
        name="name"
        control={control}
        resizable={false}
        required
        errorMessage={dirtyFields?.name && errors?.name?.message}
        value={getValues('name')?.toString()}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ): void => {
          setValue('name', newValue, { shouldDirty: true });
        }}
        rules={{
          required: 'Name is required',
          maxLength: {
            value: 200,
            message: 'Input is greater than the maximum character limit of 200.',
          },
        }}
      />
      <InitialProcessDates
        companyCode={companyCode}
        showDateRangeSelector={
          !hasAdminAccessToChangeDates &&
          !hasDefaultCompanyAFSTemplate &&
          isAnnualFinancialStatements
        }
        reportingPeriodYear={reportingPeriodYear}
        filingDueDate={getValues('filingDueDate')}
        reportingPeriodEndDate={getValues('reportingPeriodEndDate')}
        auditFilingDueDate={auditFilingDueDate}
        statutoryYearEnd={statutoryYearEnd}
        setFilingDueDate={(newValue: string) => {
          setValue('filingDueDate', newValue, { shouldDirty: true });
          trigger();
        }}
        setReportingPeriodEndDate={(newValue: string) => {
          setValue('reportingPeriodEndDate', newValue, { shouldDirty: true });
          trigger();
        }}
        setReportingPeriodYear={setReportingPeriodYear}
        control={control}
        dirtyFields={dirtyFields}
        errors={errors}
        isAnnualFinancialStatements={isAnnualFinancialStatements}
      />
      <Controller
        name="useLastDayOfMonth"
        control={control}
        defaultValue={data?.useLastDayOfMonth}
        render={({ onBlur, value, name }): React.ReactElement => (
          <Checkbox
            label="Use Last Day of Month"
            onChange={(
              e: React.FormEvent<HTMLInputElement | HTMLElement>,
              checked: boolean,
            ): void => {
              setValue('useLastDayOfMonth', checked, { shouldDirty: true });
              trigger();
            }}
            checked={value}
            name={name}
            disabled={disableUseLastDayOfMonth}
          />
        )}
      />
      <Controller
        name="recurrence"
        control={control}
        defaultValue={data?.recurrence}
        value={getValues('recurrence')}
        render={({ value }): React.ReactElement => (
          <Dropdown
            label="Recurrence"
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
              if (option.key.toString() === 'WEEKLY' || option.key.toString() === 'ONETIME') {
                setValue('useLastDayOfMonth', false, { shouldDirty: true });
                setDisableUseLastDayOfMonth(true);
              } else {
                setDisableUseLastDayOfMonth(false);
              }

              setValue('recurrence', option.key.toString(), { shouldDirty: true });
              trigger();
            }}
            options={recurrenceOptions}
            selectedKey={value}
            required
            styles={dropdownStyles}
            placeholder="Select an option"
            errorMessage={dirtyFields?.recurrence && errors?.recurrence?.message}
          />
        )}
        rules={{
          required: 'Please select a recurrence',
        }}
      />
      <Stack horizontal>
        <Controller
          name="recurrenceEndDate"
          control={control}
          defaultValue={data?.recurrenceEndDate}
          render={({ value }): React.ReactElement => (
            <UtcDatePicker
              label="Deactivate Date"
              value={value}
              onSelectDate={(utcDateString: string): void => {
                setValue('recurrenceEndDate', utcDateString, { shouldDirty: true });
                trigger();
              }}
            />
          )}
        />
        <TooltipHost
          content="Date when processes will no longer be generated. Set this value if there is a date when processes should no longer be generated."
          id="tooltip"
          styles={offsetTooltipHostStyles}
        >
          <IconButton
            aria-describedby="tooltip"
            ariaLabel="Deactivate date information"
            iconProps={{ iconName: 'Info' }}
            styles={offsetDateIconStyles}
          />
        </TooltipHost>
      </Stack>
    </Stack>
  );
};
export default CreateProcessConfigDetailsForm;
