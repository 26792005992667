import { ICommandBarStyles, CommandBar, NeutralColors } from '@fluentui/react';
import React from 'react';

interface IProcessTemplateDetailsCommandProps {
  onEditProcessTemplateClick: () => void;
  onManageMilestoneClick: () => void;
  onDeactivateProcessTemplateClick: () => void;

  disableCommandButtons: boolean;
}
const ProcessTemplateDetailsCommand = (props: IProcessTemplateDetailsCommandProps): JSX.Element => {
  const {
    onEditProcessTemplateClick,
    onManageMilestoneClick,
    disableCommandButtons,
    onDeactivateProcessTemplateClick,
  } = props;
  const buttonStyles = { root: { padding: '0 10px' } };
  const commandBarItems = [];

  commandBarItems.push({
    key: 'editProcessTemplate',
    text: 'Edit Name & Description',
    iconProps: { iconName: 'Edit' },
    buttonStyles,
    onClick: onEditProcessTemplateClick,
    disabled: disableCommandButtons,
  });
  commandBarItems.push({
    key: 'manageMilestone',
    text: 'Manage Current Milestones',
    iconProps: { iconName: 'Timeline' },
    buttonStyles,
    disabled: disableCommandButtons,
    onClick: onManageMilestoneClick,
  });
  commandBarItems.push({
    key: 'deactivateProcessTemplate',
    text: 'Deactivate Process Template',
    iconProps: { iconName: 'DeactivateOrders' },
    buttonStyles,
    disabled: disableCommandButtons,
    onClick: onDeactivateProcessTemplateClick,
  });

  const commandBarStyles: ICommandBarStyles = {
    root: {
      padding: '0px',
      margin: '-30px -30px 0 -30px',
      borderBottom: `1px solid ${NeutralColors.gray40}`,
    },
  };
  return (
    <>
      {commandBarItems.length > 0 && (
        <CommandBar
          styles={commandBarStyles}
          items={commandBarItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
        />
      )}
    </>
  );
};
export default ProcessTemplateDetailsCommand;
