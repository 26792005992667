import { useRef, useEffect, MutableRefObject } from 'react';

function useIsMounted(): MutableRefObject<boolean> {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return (): void => {
      isMountedRef.current = false;
    };
  });

  return isMountedRef;
}

export default useIsMounted;
