import { useMutation } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanelStyles } from '../../../app/common/styles/CommonStyleObjects';
import {
  GET_COMPANY_CREATION_COMPANY_INFO,
  GET_COMPANY_CREATION_PROGRESS,
  UPDATE_LEGAL_DOCUMENT_INFO,
} from '../../../utils/statApi/CompanyCreationApi';

import ActionButtons from '../../common/ActionButtons';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import LegalDocumentInfoForm from './LegalDocumentInfoForm';
import { ILegalDocumentInfo } from './types';

interface ILegalDocumentInfoPanelProps {
  closePanel: () => void;
  data: ILegalDocumentInfo;
  companyCreationId: number;
  isNonTransactional: boolean;
}

const LegalDocumentInfoPanel = ({
  closePanel,
  data,
  companyCreationId,
  isNonTransactional,
}: ILegalDocumentInfoPanelProps): JSX.Element => {
  const form = useForm<ILegalDocumentInfo>({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit } = form;

  const [updateLegalDocumentInfo, { loading, error }] = useMutation(UPDATE_LEGAL_DOCUMENT_INFO, {
    onCompleted: closePanel,
    refetchQueries: [
      {
        query: GET_COMPANY_CREATION_COMPANY_INFO,
        variables: {
          companyCreationId,
        },
      },
      {
        query: GET_COMPANY_CREATION_PROGRESS,
        variables: {
          companyCreationId,
        },
      },
    ],
  });

  const handleOnSaveClick = (formData: ILegalDocumentInfo): void => {
    const legalCountry = formData.legallyRegisteredAddress?.country?.id
      ? {
          id: formData.legallyRegisteredAddress?.country?.id,
          shortName: formData.legallyRegisteredAddress?.country?.shortName,
        }
      : null;

    const physicalCountry = formData.physicalAddress?.country?.id
      ? {
          id: formData.physicalAddress?.country?.id,
          shortName: formData.physicalAddress?.country?.shortName,
        }
      : null;

    const legalAddress = {
      streetNumber: formData.legallyRegisteredAddress.streetNumber,
      streetNumber2: formData.legallyRegisteredAddress.streetNumber2,
      city: formData.legallyRegisteredAddress.city,
      postalZipCode: formData.legallyRegisteredAddress.postalZipCode,
      country: legalCountry,
      stateProvinceRegion: {
        id: formData.legallyRegisteredAddress?.stateProvinceRegion?.id,
      },
    };

    const physicalAddress = formData.physicalAddress
      ? {
          streetNumber: formData.physicalAddress.streetNumber,
          streetNumber2: formData.physicalAddress.streetNumber2,
          city: formData.physicalAddress.city,
          postalZipCode: formData.physicalAddress.postalZipCode,
          country: physicalCountry,
          stateProvinceRegion: {
            id: formData.physicalAddress?.stateProvinceRegion?.id,
          },
        }
      : legalAddress;

    updateLegalDocumentInfo({
      variables: {
        input: {
          id: formData.id || -1,
          companyCreationRequestId: companyCreationId,
          fullLegalName: formData.fullLegalName
            ? formData.fullLegalName.trim().replace(/\s\s+/g, ' ')
            : null,
          sapLegalName: formData.sapLegalName
            ? formData.sapLegalName.trim().replace(/\s\s+/g, ' ')
            : null,
          abbreviatedSapName: formData.abbreviatedSapName
            ? formData.abbreviatedSapName.trim().replace(/\s\s+/g, ' ')
            : null,
          statutoryYearEndMonth: formData.statutoryYearEndMonth,
          statutoryYearEndDay: formData.statutoryYearEndDay,
          incorporatedDate: formData.incorporatedDate,
          legallyRegisteredAddress: legalAddress,
          physicalAddress,
        },
      },
    });
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText="Edit Legal Document Info"
      isOpen
      onDismiss={closePanel}
      styles={CoherencePanelStyles}
      hasCloseButton
      closeButtonAriaLabel="Close Edit Legal Document Info Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={loading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save Legal Document Info"
          saveTitle="Save Legal Document Info"
          cancelLabel="Cancel Legal Document Info Edit"
          cancelTitle="Cancel Legal Document Info Edit"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage error={error} loading={loading} />
        <LegalDocumentInfoForm form={form} data={data} isNonTransactional={isNonTransactional} />
      </form>
    </CoherencePanel>
  );
};

export default LegalDocumentInfoPanel;
