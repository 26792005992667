import { IPersonaProps, IBasePickerSuggestionsProps } from '@fluentui/react';
import { StatUser } from './statApi/UsersApi';
import ICompanyGroupStatUsers from './types/ICompanyGroupsStatUsers';
import { doesTextInclude } from './Helpers';
import IPersonaWithGUID from './types/IPersonaWithGUIDs';

export const suggestionProps: IBasePickerSuggestionsProps = {
  loadingText: 'Loading',
  searchingText: 'Searching...',
};

const listContainsPersona = (
  persona: IPersonaProps,
  possibleDupesList: IPersonaProps[],
): boolean => {
  if (!possibleDupesList || !possibleDupesList.length || possibleDupesList.length === 0) {
    return false;
  }
  return possibleDupesList.filter((item) => item.text === persona.text).length > 0;
};

export const filterPersonasByText = (
  personasList: { filter: (arg0: (item: IPersonaProps) => boolean) => IPersonaProps[] },
  filterText: string,
): IPersonaProps[] => {
  return personasList.filter((item) => doesTextInclude(item.text as string, filterText));
};

export const removeDuplicatePersonasFromList = (
  list: { filter: (arg0: (persona: IPersonaProps) => boolean) => IPersonaProps[] },
  possibleDupesList: IPersonaProps[],
): IPersonaProps[] => {
  return list.filter((persona) => !listContainsPersona(persona, possibleDupesList));
};

export const mapCompanyGroupsStatUserToStatPersonas = (
  cgsus: ICompanyGroupStatUsers[],
): IPersonaWithGUID[] => {
  return cgsus.map((cgsu: ICompanyGroupStatUsers) => {
    const persona: ICompanyGroupStatUsers = {
      text: cgsu.name,
      secondaryText: cgsu.email,
      graphId: cgsu.graphId,
      firstName: cgsu.firstName,
      lastName: cgsu.lastName,
      alias: cgsu.alias,
      personnelNumber: cgsu.personnelNumber,
    };
    return persona;
  });
};
export const mapStatUsersToPersonas = (people: StatUser[]): IPersonaWithGUID[] => {
  return people.map((person: StatUser): IPersonaWithGUID => {
    return {
      imageUrl: '',
      imageInitials: '',
      text: person.name.toString(),
      secondaryText: person.emailAddress,
      graphId: person.graphGUID,
      statUserId: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      alias: person.alias,
      personnelNumber: person.personnelNumber,
    };
  });
};
export const mapPersonasToCompanyGroupUser = (
  personas: IPersonaWithGUID[],
  companyGroupId: number,
): ICompanyGroupStatUsers[] =>
  personas.map((persona) => {
    return {
      graphId: persona.graphId,
      name: persona.text,
      email: persona.secondaryText,
      firstName: persona.firstName,
      lastName: persona.lastName,
      alias: persona.alias,
      personnelNumber: persona.personnelNumber,
    };
  });
