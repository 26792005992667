import React from 'react';
import { FontIcon, Stack, Separator, NeutralColors, DefaultPalette } from '@fluentui/react';
import { MilestoneStatus } from '../Interfaces';
import { MessagingColors } from '../../../../app/common/styles/CommonStyleObjects';

interface ITimelineNavItemProps {
  status: MilestoneStatus;
  isLast?: boolean;
  isGoalMilestone: boolean;
}

const TimelineNavItem = ({
  status,
  isLast,
  isGoalMilestone,
}: ITimelineNavItemProps): JSX.Element => {
  const progressPercentage = status === MilestoneStatus.complete ? '100%' : '0%';
  return (
    <Stack
      verticalFill
      style={{
        marginTop: 15,
        background: NeutralColors.gray10,
      }}
    >
      <Stack.Item grow={0} styles={{ root: { fontSize: 25 } }}>
        {isGoalMilestone && (
          <div style={{ position: 'relative' }}>
            <FontIcon
              title="Goal Milestone"
              iconName="Trophy2Solid"
              style={{
                border: `6px solid transparent`,
                color:
                  status === MilestoneStatus.notStarted
                    ? DefaultPalette.blue
                    : DefaultPalette.white,
                position: 'absolute',
                top: 'initial',
                fontSize: '18px',
              }}
            />
          </div>
        )}
        {status === MilestoneStatus.complete && !isGoalMilestone && (
          <FontIcon
            title="Completed"
            iconName="CompletedSolid"
            style={{
              border: `2px solid transparent`,
              color: MessagingColors.successIcon,
            }}
          />
        )}
        {status === MilestoneStatus.complete && isGoalMilestone && (
          <FontIcon
            title="Completed"
            iconName="CircleFill"
            style={{
              border: `2px solid transparent`,
              color: MessagingColors.successIcon,
            }}
          />
        )}
        {status === MilestoneStatus.ready && (
          <FontIcon
            title="Ready"
            iconName="CircleFill"
            style={{
              border: `2px solid transparent`,
              color: DefaultPalette.blue,
            }}
          />
        )}
        {status === MilestoneStatus.notStarted && (
          <FontIcon
            title="Not Started"
            iconName="CircleFill"
            style={{
              color: NeutralColors.gray20,
              border: `2px solid ${NeutralColors.gray60}`,
              borderRadius: 30,
            }}
          />
        )}
      </Stack.Item>
      {!isLast && (
        <Stack.Item grow={1} styles={{ root: { display: 'block', position: 'relative' } }}>
          <Separator
            styles={{
              root: {
                height: 'calc(100% - 10px)',
                selectors: {
                  '&:before': {
                    position: 'absolute',
                    left: 'calc(50%)',
                    height: '100%',
                    width: 2,
                    top: 0,
                    background: NeutralColors.gray30,
                  },
                },
              },
            }}
          />

          <Separator
            styles={{
              root: {
                position: 'absolute',
                top: 0,
                height: 'calc(100% - 10px)',
                selectors: {
                  '&:before': {
                    transition: 'all 350ms cubic-bezier(0, 0, 0.58, 1)',
                    position: 'absolute',
                    left: 'calc(50%)',
                    height: progressPercentage,
                    width: 2,
                    top: 0,
                    background: MessagingColors.successIcon,
                  },
                },
              },
            }}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default TimelineNavItem;
