import React, { useState } from 'react';
import { ActionButton } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import {
  bodyContentContainer,
  bodyContentWrapper,
} from '../../../app/common/styles/CommonStyleObjects';
import { GetProcessConfigsWithPaginationQuery } from '../../../utils/statApi/ProcessConfigApi';
import CanAccess from '../../common/canAccess/CanAccess';
import BodyHeaderWithCommandBar from '../../common/headers/BodyHeaderWithCommandBar';
import ProcessConfigWizard from './processConfigWizard/ProcessConfigWizard';
import { processConfigWizardSteps } from './processConfigWizard/CreateProcessConfigUtils';
import { IProcessConfigListItem } from '../../../utils/types/IProcessConfig';
import WrappedListPage, {
  IPaginatedListPageProps,
} from '../../common/DetailsLists/pagination/PaginatedListPage';
import ProcessConfigList from './ProcessConfigList';
import { ICompanyCodeParams } from '../../common/ParamTypes';

const ProcessConfigListPage = (): JSX.Element => {
  const { companyCode } = useParams<ICompanyCodeParams>();
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);

  const getBodyHeaderTitle = (): JSX.Element => {
    return <h2>Process Configurations</h2>;
  };

  const togglePanel = (): void => {
    setIsEditPanelOpen(!isEditPanelOpen);
  };

  const wrappedListPageParams: IPaginatedListPageProps<IProcessConfigListItem> = {
    dataMapperKey: 'processConfigList',
    actionName: 'loading the process extensions list',
    query: GetProcessConfigsWithPaginationQuery,
    searchTerm: companyCode,
    emptyResultMessage: 'There are no process configurations for the selected company.',
  };

  return (
    <div className={`${bodyContentContainer} ms-depth-4`}>
      <div className={bodyContentWrapper}>
        <BodyHeaderWithCommandBar title={getBodyHeaderTitle}>
          <CanAccess requestedAction="ProcessConfiguration:Add">
            <ActionButton
              iconProps={{ iconName: 'Add' }}
              allowDisabledFocus
              onClick={(): void => {
                togglePanel();
              }}
            >
              Add Process Config
            </ActionButton>
          </CanAccess>
        </BodyHeaderWithCommandBar>
      </div>
      {WrappedListPage(ProcessConfigList, wrappedListPageParams)}
      {isEditPanelOpen && (
        <ProcessConfigWizard
          processConfigWizardSteps={processConfigWizardSteps}
          closePanel={togglePanel}
        />
      )}
    </div>
  );
};

export default ProcessConfigListPage;
